import { FormCheckboxGroupOptions, FormSelectOption } from '@latitude/form';

export enum TradingFrequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
}

export enum PreferredTradingMethods {
  CHAT = 'Chat',
  WEB_INTERFACE = 'Web Interface',
  API = 'API',
}

export const TRADING_FREQUENCY_OPTIONS: FormSelectOption[] = [
  {
    label: 'Daily',
    value: TradingFrequency.DAILY,
  },
  {
    label: 'Weekly',
    value: TradingFrequency.WEEKLY,
  },
  {
    label: 'Monthly',
    value: TradingFrequency.MONTHLY,
  },
  {
    label: 'Quarterly',
    value: TradingFrequency.QUARTERLY,
  },
];

export const PREFERRED_TRADING_METHODS_OPTIONS: FormCheckboxGroupOptions[] = [
  {
    label: 'Chat',
    value: PreferredTradingMethods.CHAT,
  },
  {
    label: 'Web Interface',
    value: PreferredTradingMethods.WEB_INTERFACE,
  },
  {
    label: 'API',
    value: PreferredTradingMethods.API,
  },
];
