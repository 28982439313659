import { Controller, FieldValues } from 'react-hook-form';

import { FormComponentWrapper } from 'components/FormComponentWrapper';

import { FormSelectProps } from './types';

import InternalFormMultiSelect from './InternalFormMultiSelect';
import InternalFormSingleSelect from './InternalFormSingleSelect';

const FormSelect = <T extends FieldValues = FieldValues>({
  name,
  control,
  options,
  description,
  label,
  wrapperClassname,
  isMulti = false,
  message,
  ...selectProps
}: FormSelectProps<T>) => (
  <Controller
    name={name}
    control={control}
    // Can't implement onBlur or value with the current select
    render={({
      field: {
        // onBlur,
        onChange,
        value,
      },
      fieldState: { error },
    }) => (
      <FormComponentWrapper
        name={name}
        description={description}
        label={label}
        error={error}
        className={wrapperClassname}
        message={message}
      >
        {isMulti ? (
          <InternalFormMultiSelect
            onChange={onChange}
            value={value}
            options={options}
            {...selectProps}
          />
        ) : (
          <InternalFormSingleSelect
            onChange={onChange}
            value={value}
            options={options}
            {...selectProps}
          />
        )}
      </FormComponentWrapper>
    )}
  />
);

FormSelect.displayName = 'FormSelect';

export default FormSelect;
