import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import cn from 'classnames';
import { ElementRef, forwardRef } from 'react';
import { radioButtonItemClasses } from 'variants';

import { RadioGroupItemProps } from 'types';

const RadioGroupItem = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioGroupItemProps
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(radioButtonItemClasses, className)}
      {...props}
    />
  );
});

RadioGroupItem.displayName = 'RadioGroupItem';

export default RadioGroupItem;
