// Default date formats for different modes
export const DEFAULT_DATE_FORMATS: { [key: string]: string } = {
  single: 'MM/dd/yyyy',
  range: 'MM/dd/yyyy',
  time: 'hh:mm a',
  'single-time': 'MM/dd/yyyy hh:mm a',
};

// Default minimum and maximum dates
export const DEFAULT_MIN_DATE = new Date(
  new Date().setFullYear(new Date().getFullYear() - 100),
);
export const DEFAULT_MAX_DATE = new Date(
  new Date().setFullYear(new Date().getFullYear() + 100),
);
