import { CircleErrorFilledIcon } from '@latitude/icons';
import { Text } from '@latitude/text';
import cn from 'classnames';
import { HTMLAttributes, forwardRef } from 'react';

import { messageVariants } from '../../variants/message-variants';

/**
 * Represents a form message component.
 *
 * @component
 * @example
 * ```tsx
 * <FormMessage className="my-form-message" error={true}>
 *   This field is required.
 * </FormMessage>
 * ```
 */
export interface FieldErrorProps extends HTMLAttributes<HTMLDivElement> {
  errorMessage?: string;
}

// comp-text-field-web-sub-text-font-family
const FieldError = forwardRef<HTMLDivElement, FieldErrorProps>(
  ({ className, id, errorMessage, ...props }, ref) => (
    <div
      ref={ref}
      id={id}
      className={cn(messageVariants({ error: !!errorMessage }), className)}
      {...props}
    >
      <Text
        className={cn([
          'flex',
          'items-center',
          'gap-spacing-comp-form-medium-gap',
          'font-comp-form-sub-text-font-family',
          'font-comp-form-sub-text-font-size',
          'font-comp-form-sub-text-font-weight',
          'leading-comp-form-sub-text-line-height',
        ])}
      >
        <CircleErrorFilledIcon
          style={{
            position: 'relative',
            top: '-1px',
          }}
        />
        {errorMessage}
      </Text>
    </div>
  ),
);

FieldError.displayName = 'FieldError';

export default FieldError;
