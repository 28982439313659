import cn from 'classnames';
import {
  ComponentProps,
  ElementRef,
  PropsWithChildren,
  forwardRef,
} from 'react';

/**
 * Wrapper component for the trigger element.
 *
 * @component
 * @example
 * ```tsx
 * <TriggerWrapper className="my-wrapper">
 *   <button>Click me</button>
 * </TriggerWrapper>
 * ```
 */
export const TriggerWrapper = forwardRef<
  ElementRef<'div'>,
  PropsWithChildren<ComponentProps<'div'>>
>(({ children, className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(['w-full', 'flex', 'items-center', 'flex-wrap', className])}
    {...props}
  >
    {children}
  </div>
));

TriggerWrapper.displayName = 'TriggerWrapper';
