import { useDayPicker } from 'react-day-picker';

import { CustomClassNames } from 'components/ComponentsTypes';

import { CaptionLabel } from './CaptionLabel';
import { CaptionMonthAndYearPicker } from './CaptionMonthAndYearPicker';
import { CaptionNavigation } from './CaptionNavigation';

/**
 * The layout of the caption:
 *
 * - `dropdown`: display dropdowns for choosing the month and the year.
 * - `buttons`: display previous month / next month buttons.
 * - `dropdown-buttons`: display both month / year dropdowns and previous month / next month buttons.
 */
export type CaptionLayout = 'dropdown' | 'buttons' | 'dropdown-buttons';

/**
 * Render the caption of a month. The caption has a different layout when
 * setting the {@link DayPickerBase.captionLayout} prop.
 */
export function Caption(): JSX.Element {
  const { disableNavigation, captionLayout, ...dayPicker } = useDayPicker();

  const classNames = dayPicker.classNames as CustomClassNames;

  let caption: JSX.Element;
  if (disableNavigation) {
    caption = <CaptionLabel />;
  } else if (captionLayout === 'dropdown') {
    caption = <CaptionMonthAndYearPicker />;
  } else if (captionLayout === 'buttons') {
    caption = (
      <>
        <CaptionLabel />
        <CaptionNavigation />
      </>
    );
  } else {
    caption = (
      <>
        <CaptionNavigation />
        <CaptionMonthAndYearPicker />
      </>
    );
  }

  return <div className={classNames.header}>{caption}</div>;
}
