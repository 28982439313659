import { Button } from '@latitude/button';
import { isSameMonth, isSameYear, setMonth, startOfMonth } from 'date-fns';
import { DateRange, useDayPicker, useNavigation } from 'react-day-picker';

import { CustomClassNames } from 'components/ComponentsTypes';

import useDatepickerContext from 'hooks/useDatepickerContext';

import { DatePickerMode } from 'types';

const isSameMonthAndYear = (
  date1: Date | undefined,
  date2: Date | undefined,
): boolean =>
  (date1 && date2 && isSameMonth(date1, date2) && isSameYear(date1, date2)) ||
  false;

// Check if the given month is the same as the selected single month
const isSelectedSingleMonth = (month: Date, selected: Date): boolean =>
  isSameMonthAndYear(month, selected);

// Check if the given month is part of the selected range
const isSelectedRangeMonth = (month: Date, range: DateRange): boolean =>
  isSameMonthAndYear(month, range?.from) ||
  isSameMonthAndYear(month, range?.to);

const isSelectedMonth = (
  month: Date,
  selected: Date | DateRange,
  mode: DatePickerMode,
): boolean => {
  if (!selected) return false;

  if (mode !== 'range') {
    // Check if the month is the same as the selected month
    return isSelectedSingleMonth(month, selected as Date);
  } else {
    // Check if the month is part of the selected range
    return isSelectedRangeMonth(month, selected as DateRange);
  }
};

/** Render the dropdown to navigate between months. */
export function MonthPicker(): JSX.Element {
  const {
    fromDate,
    toDate,
    locale,
    formatters: { formatMonthCaption },
    labels: { labelMonthDropdown },
    today,
    selected,
    mode,
    ...daypicker
  } = useDayPicker();

  const classNames = daypicker.classNames as CustomClassNames;

  const { setChangingMonth } = useDatepickerContext();
  const { goToMonth, currentMonth } = useNavigation();

  // Dropdown should appear only when both from/toDate is set
  if (!fromDate) return <></>;
  if (!toDate) return <></>;

  const dropdownMonths: Date[] = [];

  const date = startOfMonth(new Date(currentMonth.getFullYear(), 0, 1));
  for (let month = 0; month <= 11; month++) {
    dropdownMonths.push(setMonth(date, month));
  }

  const handleChange = (selectedMonth: number) => {
    const newMonth = setMonth(startOfMonth(currentMonth), selectedMonth);
    goToMonth(newMonth);
    setChangingMonth(false);
  };

  return (
    <div
      aria-label={labelMonthDropdown()}
      className={`${classNames.month_picker_container}`}
    >
      {dropdownMonths.map((month) => {
        const isCurrentMonth = isSameMonth(month, today);
        const currentMonthSelected = isSelectedMonth(
          month,
          selected as Date,
          mode as DatePickerMode,
        );

        const {
          selectedYear,
          selectedMonth,
          minYear,
          minMonth,
          maxYear,
          maxMonth,
        } = {
          selectedYear: month.getFullYear(),
          selectedMonth: month.getMonth(),
          minYear: fromDate.getFullYear(),
          minMonth: fromDate.getMonth(),
          maxYear: toDate.getFullYear(),
          maxMonth: toDate.getMonth(),
        };

        let isDisabled = false;

        if (selectedYear < minYear) isDisabled = true;
        if (selectedYear > maxYear) isDisabled = true;
        if (selectedYear >= minYear && selectedYear <= maxYear) {
          if (selectedYear === minYear && selectedMonth < minMonth)
            isDisabled = true;
          if (selectedYear === maxYear && selectedMonth > maxMonth)
            isDisabled = true;
        }

        // x |   - selected month
        //   | x - current month
        // O | O ---> variant: strong , type: ghost
        // O | x ---> variant: primary, type: ghost
        // x | O ---> variant: primary, type: filled
        // x | x ---> variant: primary, type: filled
        return (
          <div
            key={month.getMonth()}
            className={`${classNames.month_picker_row}`}
          >
            <Button
              onClick={() => {
                !isDisabled && handleChange(month.getMonth());
              }}
              htmlType="button"
              disabled={isDisabled}
              type={currentMonthSelected ? 'filled' : 'ghost'}
              size="small"
              variant={
                isCurrentMonth || currentMonthSelected ? 'primary' : 'strong'
              }
              className={`${classNames.month_picker_row_item}`}
            >
              {formatMonthCaption(month, { locale })}
            </Button>
          </div>
        );
      })}
    </div>
  );
}
