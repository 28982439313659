import { cva } from 'class-variance-authority';

/**
 * Variants for the label component.
 */
export const labelVariants = cva('', {
  variants: {
    error: {
      false: ['text-color-comp-form-label-strong'],
      true: ['text-color-comp-form-label-danger'],
    },
  },
  defaultVariants: {
    error: false,
  },
});
