import { ChevronIcon } from '@latitude/icons';
import { DebouncedInput } from '@latitude/input';
import { ComponentProps, ElementRef, forwardRef } from 'react';

type InputProps = ComponentProps<typeof DebouncedInput> & {
  direction: ComponentProps<typeof ChevronIcon>['direction'];
};

/**
 * A component that represents a trigger input.
 *
 * @component
 * @example
 * ```tsx
 * <TriggerInput
 *   className="my-input"
 *   trailingElement="Search"
 *   direction="down"
 *   onChange={handleInputChange}
 * />
 * ```
 */
export const TriggerInput = forwardRef<
  ElementRef<typeof DebouncedInput>,
  InputProps
>(({ classNames, trailingElement, direction, ...props }, ref) => {
  return (
    <DebouncedInput
      ref={ref}
      inputSize="small"
      classNames={classNames}
      trailingElement={
        <>
          {trailingElement && (
            <span className="text-color-comp-select-on-container-entered font-comp-select-web-small-text-font-family leading-comp-select-web-small-text-line-height font-comp-select-web-small-text-font-weight text-comp-select-web-small-text-font-size leading-font-comp-select-web-small-text-letter-spacing">
              {trailingElement}
            </span>
          )}
          <ChevronIcon
            className="w-sizing-sys-size-24 h-sizing-sys-size-24"
            direction={direction}
          />
        </>
      }
      {...props}
    />
  );
});

TriggerInput.displayName = 'Input';
