/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  ButtonItem,
  DateItem,
  SelectItem,
  ToggleItemTransformers,
} from '../types';

export const internalTranformersButton: ToggleItemTransformers<ButtonItem> = {
  value: (value) => {
    return value.id;
  },
  formattedValue: (item) => {
    return item.label;
  },
};

export const internalTranformersSelect: ToggleItemTransformers<SelectItem> = {
  value: (value) => {
    return value.id;
  },
  formattedValue: (item) => {
    return item.label;
  },
};

export const internalTranformersDatepicker: ToggleItemTransformers<DateItem> = {
  value: (item) => {
    return item.value;
  },
  formattedValue: (item) => {
    return item.formatedValue;
  },
};
