/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from '@latitude/button';
import { ChevronIcon } from '@latitude/icons';
import { Input } from '@latitude/input';
import { Tag } from '@latitude/tag';
import cn from 'classnames';
import { ChangeEvent, useState } from 'react';
import { useDayPicker } from 'react-day-picker';

import { CustomClassNames } from 'components/ComponentsTypes';

import useDatepickerContext from 'hooks/useDatepickerContext';

const padWithZero = (number: number) => String(number).padStart(2, '0');

export function TimePicker(): JSX.Element {
  const { onDayClick, onOpenChange, value } = useDatepickerContext();
  const { selected, ...daypicker } = useDayPicker();
  const classNames = daypicker.classNames as CustomClassNames;

  const [hour, setHour] = useState(
    selected instanceof Date ? selected.getHours() % 12 || 12 : 12,
  );
  const [minute, setMinute] = useState(
    selected instanceof Date ? selected.getMinutes() : 0,
  );
  const [period, setPeriod] = useState(
    selected instanceof Date ? (selected.getHours() >= 12 ? 'PM' : 'AM') : 'AM',
  );

  const handleNewTimeChange = (
    newHour: number,
    minute: number,
    ampm: string,
  ) => {
    const newDate =
      selected && selected instanceof Date ? new Date(selected) : new Date();
    newDate.setHours(ampm === 'AM' ? newHour : newHour + 12);
    newDate.setMinutes(minute);
    newDate.setSeconds(0);

    onDayClick(newDate);
  };

  const handleInputChange = (value: string, type: 'hour' | 'minute') => {
    let parsedValue = parseInt(value, 10);

    // If parsing fails, default to 0
    if (isNaN(parsedValue)) {
      parsedValue = 0;
    }

    if (type === 'hour') {
      // Clamp the hour value between 0 and 12
      if (parsedValue < 0) {
        parsedValue = 0;
      } else if (parsedValue > 12) {
        parsedValue = 12;
      }
      setHour(parsedValue);
      handleNewTimeChange(parsedValue, minute, period);
    } else if (type === 'minute') {
      // Clamp the minute value between 0 and 59
      if (parsedValue < 0) {
        parsedValue = 0;
      } else if (parsedValue > 59) {
        parsedValue = 59;
      }
      setMinute(parsedValue);
      handleNewTimeChange(hour, parsedValue, period);
    }
  };

  const increaseHour = () => {
    const newHour = hour === 12 ? 1 : hour + 1;
    setHour(newHour);
    handleNewTimeChange(newHour, minute, period);
  };
  const decreaseHour = () => {
    const newHour = hour === 1 ? 12 : hour - 1;
    setHour(newHour);
    handleNewTimeChange(newHour, minute, period);
  };
  const increaseMinute = () => {
    const newMinute = minute === 59 ? 0 : minute + 1;
    setMinute(newMinute);
    handleNewTimeChange(hour, newMinute, period);
  };
  const decreaseMinute = () => {
    const newMinute = minute === 0 ? 59 : minute - 1;
    setMinute(newMinute);
    handleNewTimeChange(hour, newMinute, period);
  };
  const togglePeriod = () => {
    const newPeriod = period === 'AM' ? 'PM' : 'AM';
    setPeriod(newPeriod);
    handleNewTimeChange(hour, minute, newPeriod);
  };

  const rowHeight = classNames.time_picker_row_height;

  return (
    <div className={classNames.time_picker_container}>
      <div className={classNames.time_picker_row}>
        <div className={`${rowHeight} w-[112px]`}>
          <Input
            value={padWithZero(hour)}
            classNames={{ wrapper: cn([rowHeight]) }}
            type="number"
            clearable={false}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.value, 'hour')
            }
            trailingElement={
              <div className="ml-[-25px] mt-[-4px] flex size-[32px] flex-col justify-between gap-0">
                <Button
                  size="tiny"
                  htmlType="button"
                  type="ghost"
                  variant="neutral"
                  onClick={increaseHour}
                  className="!flex !h-3/6 w-full"
                >
                  <ChevronIcon direction="up" />
                </Button>
                <Button
                  size="tiny"
                  htmlType="button"
                  type="ghost"
                  variant="neutral"
                  onClick={decreaseHour}
                  className="!flex !h-3/6 w-full"
                >
                  <ChevronIcon direction="down" />
                </Button>
              </div>
            }
          />
        </div>
        <div className={`${rowHeight} w-[112px] `}>
          <Input
            value={padWithZero(minute)}
            classNames={{ wrapper: cn([rowHeight]) }}
            type="number"
            clearable={false}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.value, 'minute')
            }
            trailingElement={
              <div className="ml-[-25px] mt-[-4px] flex size-[32px] flex-col justify-between gap-0">
                <Button
                  size="tiny"
                  htmlType="button"
                  type="ghost"
                  variant="neutral"
                  onClick={increaseMinute}
                  className="!flex !h-3/6 w-full"
                >
                  <ChevronIcon direction="up" />
                </Button>
                <Button
                  size="tiny"
                  htmlType="button"
                  type="ghost"
                  variant="neutral"
                  onClick={decreaseMinute}
                  className="!flex !h-3/6 w-full"
                >
                  <ChevronIcon direction="down" />
                </Button>
              </div>
            }
          />
        </div>
        <Tag
          className={`${rowHeight} -ml-px !w-[40px] cursor-pointer p-0 [&>div]:justify-center`}
          variant="strong"
          fill="outline"
          selected={period === 'AM'}
          onClick={togglePeriod}
        >
          AM
        </Tag>
        <Tag
          className={`${rowHeight} -ml-px !w-[40px] cursor-pointer p-0 [&>div]:justify-center`}
          variant="strong"
          fill="outline"
          selected={period === 'PM'}
          onClick={togglePeriod}
        >
          PM
        </Tag>
      </div>
      <div className={`${classNames.time_picker_row} justify-end`}>
        <Button
          className={rowHeight}
          variant="neutral"
          htmlType="button"
          onClick={() => {
            onOpenChange(false);
            onDayClick(value as Date);
          }}
        >
          Cancel
        </Button>
        <Button
          className={rowHeight}
          variant="primary"
          htmlType="button"
          onClick={() => {
            onOpenChange(false);
          }}
        >
          Select
        </Button>
      </div>
    </div>
  );
}
