import { A1Chart } from '@latitude/icons';
import { Text } from '@latitude/text';

import css from './styles.module.scss';

export const InfoSection = () => {
  return (
    <>
      <A1Chart className={css.image} />
      <Text variant="headingTiny" className="text-center">
        World-class crypto trading for institutions
      </Text>
      <ul className={css.bulletList}>
        <li>
          <Text>Trade 400+ assets with 24/7/365 white glove support</Text>
        </li>
        <li>
          <Text>Access diverse, global liquidity from one location</Text>
        </li>
        <li>
          <Text>Utilize algorithmic orders to reduce market impact costs</Text>
        </li>
        <li>
          <Text>Activate trading in days with streamlined onboarding</Text>
        </li>
      </ul>
      <Text className="flex gap-1">
        Read more about{' '}
        <Text variant="link">
          <a
            target="_blank"
            href={`https://docs.anchorage.com/docs/getting-started`}
          >
            Anchorage Digital Trading
          </a>
        </Text>
      </Text>
    </>
  );
};
