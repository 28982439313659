import { RadioGroup } from '@latitude/radio-button';
import { Controller, FieldValues } from 'react-hook-form';

import { FormComponentWrapper } from 'components/FormComponentWrapper';

import { FormRadioButtonGroupProps } from './types';

const FormRadioButtonGroup = <T extends FieldValues = FieldValues>({
  name,
  control,
  description,
  label,
  wrapperClassname,
  options,
  disabled,
  ...formRadioGroupProps
}: FormRadioButtonGroupProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
      <FormComponentWrapper
        name={name}
        description={description}
        label={label}
        error={error}
        className={wrapperClassname}
      >
        <RadioGroup
          rawProps={{
            onBlur: onBlur,
          }}
          onChange={onChange}
          value={value}
          invalid={!!error}
          disabled={disabled}
          options={options}
          {...formRadioGroupProps}
        />
      </FormComponentWrapper>
    )}
  />
);

FormRadioButtonGroup.displayName = 'FormRadioButtonGroup';

export default FormRadioButtonGroup;
