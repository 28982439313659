import { cva } from 'class-variance-authority';

/**
 * Represents the message variants for the form.
 */
export const messageVariants = cva(
  [
    'font-comp-form-sub-text-font-family',
    'font-comp-form-sub-text-font-size',
    'font-comp-form-sub-text-font-weight',
    'leading-comp-form-sub-text-line-height',
  ],
  {
    variants: {
      error: {
        false: ['text-color-comp-form-label-subtext'],
        true: ['text-color-comp-form-label-danger'],
      },
    },
    defaultVariants: {
      error: false,
    },
  },
);
