import { Controller, FieldValues } from 'react-hook-form';

import { FormComponentWrapper } from 'components/FormComponentWrapper';

import { FormDatepickerProps } from './types';

import InternalFormDatepicker from './InternalFormDatepicker';

const FormDatepicker = <T extends FieldValues = FieldValues>({
  description,
  label,
  wrapperClassname = '',
  name,
  control,
  mode,
  disabled,
  message,
  ...dateHookProps
}: FormDatepickerProps<T>) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
      <FormComponentWrapper
        name={name}
        description={description}
        error={error}
        label={label}
        className={wrapperClassname}
        message={message}
      >
        <InternalFormDatepicker
          {...dateHookProps}
          disabled={disabled}
          mode={mode}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        />
      </FormComponentWrapper>
    )}
  />
);

FormDatepicker.displayName = 'FormDatepicker';

export default FormDatepicker;
