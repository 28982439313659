import { useCallback } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import { ReferralType, ReferralWorkflowQueryParams } from './constants';

export const useReferralWorkflowModals = () => {
  const [, setQueryParams] = useQueryParams({
    [ReferralWorkflowQueryParams.REFFERAL_TYPE]: StringParam,
  });

  const openA1Modal = useCallback(() => {
    setQueryParams({
      [ReferralWorkflowQueryParams.REFFERAL_TYPE]: ReferralType.A1,
    });
  }, [setQueryParams]);

  const closeModal = useCallback(() => {
    setQueryParams({
      [ReferralWorkflowQueryParams.REFFERAL_TYPE]: undefined,
    });
  }, [setQueryParams]);

  return {
    openA1Modal,
    closeModal,
  };
};
