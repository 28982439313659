import { Button } from '@latitude/button';
import { ChevronIcon } from '@latitude/icons';
import { useDayPicker, useNavigation } from 'react-day-picker';

import { CustomClassNames, NavigationProps } from 'components/ComponentsTypes';

import useDatepickerContext from 'hooks/useDatepickerContext';

/** A component rendering the navigation buttons or the drop-downs. */
export function Navigation({
  displayMonth,
  previousMonth,
  nextMonth,
  hidePrevious,
  hideNext,
  onNextClick,
  onPreviousClick,
}: NavigationProps): JSX.Element {
  const {
    locale,
    labels: { labelPrevious, labelNext },
    components,
    ...dayPicker
  } = useDayPicker();

  const classNames = dayPicker.classNames as CustomClassNames;

  const { goToDate } = useNavigation();
  const { changingMonth, changingYear, yearPage, setYearPage } =
    useDatepickerContext();

  if (!nextMonth && !previousMonth) {
    return <></>;
  }

  const previousLabel = labelPrevious(previousMonth, { locale });
  const previousClassName = [
    classNames.header_nav_button,
    classNames.header_nav_button_prev,
  ].join(' ');

  const nextLabel = labelNext(nextMonth, { locale });
  const nextClassName = [
    classNames.header_nav_button,
    classNames.header_nav_button_next,
  ].join(' ');

  const previousClickHandler = () => {
    if (!changingMonth && !changingYear) {
      onPreviousClick();
    }
    if (changingMonth) {
      goToDate(
        new Date(displayMonth.getFullYear() - 1, displayMonth.getMonth()),
      );
    }
    if (changingYear) {
      setYearPage(yearPage - 1);
    }
  };
  const nextClickHandler = () => {
    if (!changingMonth && !changingYear) {
      onNextClick();
    }
    if (changingMonth) {
      goToDate(
        new Date(displayMonth.getFullYear() + 1, displayMonth.getMonth()),
      );
    }
    if (changingYear) {
      setYearPage(yearPage + 1);
    }
  };

  const IconRightComponent = components?.IconRight ?? ChevronIcon;
  const IconLeftComponent = components?.IconLeft ?? ChevronIcon;
  return (
    <div className={classNames.header_nav}>
      {!hidePrevious && (
        <Button
          variant="neutral"
          type="ghost"
          size="small"
          htmlType="button"
          rawProps={{
            name: 'previous-month',
          }}
          aria-label={previousLabel}
          className={previousClassName}
          disabled={!previousMonth}
          onClick={previousClickHandler}
        >
          <IconLeftComponent
            direction="left"
            className={classNames.header_nav_button_icon}
          />
        </Button>
      )}
      {!hideNext && (
        <Button
          variant="neutral"
          type="ghost"
          size="small"
          htmlType="button"
          rawProps={{
            name: 'next-month',
          }}
          aria-label={nextLabel}
          className={nextClassName}
          disabled={!nextMonth}
          onClick={nextClickHandler}
        >
          <IconRightComponent
            direction="right"
            className={classNames.header_nav_button_icon}
          />
        </Button>
      )}
    </div>
  );
}
