import { Button } from '@latitude/button';
import { useDayPicker, useNavigation } from 'react-day-picker';

import { CustomClassNames } from 'components/ComponentsTypes';

import useDatepickerContext from 'hooks/useDatepickerContext';

/**
 * Render a caption with the dropdowns to navigate between months and years.
 */
export function CaptionMonthAndYearPicker(): JSX.Element {
  const { changingMonth, changingYear, setChangingMonth, setChangingYear } =
    useDatepickerContext();
  const { currentMonth: displayMonth } = useNavigation();

  const {
    locale,
    formatters: { formatMonthCaption, formatYearCaption },
    ...dayPicker
  } = useDayPicker();

  const classNames = dayPicker.classNames as CustomClassNames;

  return (
    <div className={classNames.header_m_y_nav_container}>
      <span className={classNames.header_month_button}>
        <Button
          variant="strong"
          type="outline"
          size="medium"
          htmlType="button"
          className="w-full"
          selected={changingMonth}
          onClick={() => {
            setChangingMonth(!changingMonth);
            changingYear && setChangingYear(false);
          }}
        >
          {formatMonthCaption(displayMonth, { locale })}
        </Button>
      </span>
      <span className={classNames.header_year_button}>
        <Button
          variant="strong"
          type="outline"
          htmlType="button"
          size="medium"
          className="w-full"
          selected={changingYear}
          onClick={() => {
            setChangingYear(!changingYear);
            changingMonth && setChangingMonth(false);
          }}
        >
          {formatYearCaption(displayMonth, { locale })}
        </Button>
      </span>
    </div>
  );
}
