import { Button } from '@latitude/button';
import { isSameYear, startOfYear } from 'date-fns';
import { useDayPicker, useNavigation } from 'react-day-picker';

import { CustomClassNames } from 'components/ComponentsTypes';

import useDatepickerContext from 'hooks/useDatepickerContext';

/** Render the picker to navigate between years. */
export function YearPicker(): JSX.Element {
  const {
    fromDate,
    toDate,
    formatters: { formatYearCaption },
    labels: { labelYearDropdown },
    today,
    selected,
    mode,
    ...daypicker
  } = useDayPicker();

  const classNames = daypicker.classNames as CustomClassNames;

  const { yearPage, setChangingYear, setChangingMonth } =
    useDatepickerContext();
  const { currentMonth, goToDate } = useNavigation();

  // Dropdown should appear only when both from/toDate is set
  if (!fromDate || !toDate) return <></>;

  // Calculate the minimum year for the picker
  const minYear = new Date('1970-01-01');
  // Calculate the start year for the picker
  // const minYear = new Date(undefined); // 8 years before the current year
  const startYear = minYear.getFullYear();

  // Calculate the number of years per page
  const yearsPerPage = 16;

  // Calculate the index of the page where the current year is located
  const currentPageIndex =
    Math.floor((currentMonth.getFullYear() - startYear) / yearsPerPage) +
    yearPage;

  const fixedYear = new Date(startYear + currentPageIndex * yearsPerPage, 0, 1);

  // Generate the list of years for the current page
  const dropdownYears: Date[] = [];
  for (let i = 0; i < yearsPerPage; i++) {
    dropdownYears.push(
      startOfYear(new Date(fixedYear.getFullYear() + i, 0, 1)),
    );
  }

  const handleChange = (selectedYear: number) => {
    const newDate = new Date(selectedYear, currentMonth.getMonth(), 1);
    setChangingMonth(true);
    setChangingYear(false);
    // Navigate to the first day of the selected year
    goToDate(newDate);
  };

  return (
    <div
      aria-label={labelYearDropdown()}
      className={`${classNames.year_picker_container}`}
    >
      {dropdownYears.map((year) => {
        // x |   - selected year
        //   | x - current year
        // O | O ---> variant: strong , type: ghost
        // O | x ---> variant: primary, type: ghost
        // x | O ---> variant: primary, type: filled
        // x | x ---> variant: primary, type: filled
        const isCurrentYear = isSameYear(year, today);
        const isSelectedYear =
          selected && mode !== 'range' && isSameYear(year, selected as Date);

        const isDisabled =
          year.getFullYear() < fromDate.getFullYear() ||
          year.getFullYear() > toDate.getFullYear();

        return (
          <div
            key={year.getFullYear()}
            className={`${classNames.year_picker_row}`}
          >
            <Button
              htmlType="button"
              onClick={() => {
                !isDisabled && handleChange(year.getFullYear());
              }}
              disabled={isDisabled}
              type={isSelectedYear ? 'filled' : 'ghost'}
              size="small"
              variant={isCurrentYear || isSelectedYear ? 'primary' : 'strong'}
              className={`${classNames.year_picker_row_item}`}
            >
              {formatYearCaption(year)}
            </Button>
          </div>
        );
      })}
    </div>
  );
}
