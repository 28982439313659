import { yup } from '@latitude/form';

import { A1ReferralFormValues } from './types';

export const formSchema: yup.ObjectSchema<A1ReferralFormValues> = yup
  .object()
  .shape({
    name: yup.string().required('Name is required'),
    company: yup.string().required('Company is required'),
    institutionType: yup.string().required('Institution type is required'),
    reasonForTrading: yup.string().required('Reason for trading is required'),
    monthlyTradingVolumes: yup
      .number()
      .required('Monthly trading volumes is required'),
    tradingFrequency: yup.string().required('Trading frequency is required'),
    preferredTradingMethod: yup
      .string()
      .required('Preferred trading method is required'),
  });
