import { FormSelectOption } from '@latitude/form';

export enum ReferralWorkflowQueryParams {
  REFFERAL_TYPE = 'referralType',
}

export enum ReferralType {
  A1 = 'A1',
  STABLECOIN_REWARDS = 'stablecoinRewards',
  PORTO = 'porto',
}

export enum InstitutionType {
  ASSET_MANAGER = 'Asset manager',
  CRYPTO_PROTOCOL = 'Crypto protocol',
  REGISTERED_INVESTMENT_ADVISOR = 'Registered investment advisor',
  VENTURE_CAPITAL_FIRM = 'Venture capital firm',
  OTHER = 'Other',
}

export const INSTITUTION_TYPE_OPTIONS: FormSelectOption[] = [
  {
    label: 'Asset manager',
    value: InstitutionType.ASSET_MANAGER,
  },
  {
    label: 'Crypto protocol',
    value: InstitutionType.CRYPTO_PROTOCOL,
  },
  {
    label: 'Registered investment advisor',
    value: InstitutionType.REGISTERED_INVESTMENT_ADVISOR,
  },
  {
    label: 'Venture capital firm',
    value: InstitutionType.VENTURE_CAPITAL_FIRM,
  },
  {
    label: 'Other',
    value: InstitutionType.OTHER,
  },
];
