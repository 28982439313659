import { DEFAULT_DATE_FORMATS } from 'constants/dateConstants';
import { createContext, useContext, useMemo } from 'react';

import {
  DatepickerContextProps,
  DatepickerProviderProps,
} from 'hooks/HookTypes';
import { useDatepickerFormatting } from 'hooks/useDatepickerFormatting';
import { useDatepickerHandlers } from 'hooks/useDatepickerHandlers';
import { useDatepickerState } from 'hooks/useDatepickerState';

import { DatePickerMode } from 'types';

export const DatepickerContext = createContext<
  DatepickerContextProps<DatePickerMode> | undefined
>(undefined);

export const DatepickerProvider = ({
  controlledOpen,
  controlledSetOpen,
  controlledOnOpenChange,
  mode,
  controlledFormat,
  value: initialValue,
  controlledMinDate,
  controlledMaxDate,
  disabled,
  onClear,
  onBlur,
  onChange,
  required,
  children,
  ...rest
}: DatepickerProviderProps) => {
  const format = useMemo(
    () =>
      controlledFormat ||
      DEFAULT_DATE_FORMATS[mode] ||
      DEFAULT_DATE_FORMATS['single']!,
    [controlledFormat, mode],
  );

  // State management
  const state = useDatepickerState({
    controlledOpen,
    controlledSetOpen,
    initialValue,
    mode,
    required,
    format,
    controlledMinDate,
    controlledMaxDate,
  });

  // Date formatting
  const formatting = useDatepickerFormatting({
    mode,
    format,
    calendarFromDateSelected: state.calendarFromDateSelected,
    calendarToDateSelected: state.calendarToDateSelected,
  });

  // Handlers
  const handlers = useDatepickerHandlers({
    mode,
    format,
    required,
    onChange,
    controlledOnOpenChange,

    open: state.open,
    setOpen: state.setOpen,
    exportedValue: state.exportedValue,
    setExportedValue: state.setExportedValue,
    tempValue: state.tempValue,
    setTempValue: state.setTempValue,
    rangeDirection: state.rangeDirection,
    setRangeDirection: state.setRangeDirection,
    calendarFromDateSelected: state.calendarFromDateSelected,
    calendarOnFromDateChange: state.calendarOnFromDateChange,
    calendarToDateSelected: state.calendarToDateSelected,
    calendarOnToDateChange: state.calendarOnToDateChange,
    minDate: state.minDate,
    maxDate: state.maxDate,
    disabled,
    onClear,
    onBlur,
    formattedValue: formatting.formattedValue,
    ...rest,
  });

  // Construct context value
  const contextValue: DatepickerContextProps<typeof mode> = {
    mode,
    format,
    required: !!required,

    open: state.open,
    setOpen: state.setOpen,
    onOpenChange: handlers.onOpenChange,
    changingMonth: state.changingMonth,
    setChangingMonth: state.setChangingMonth,
    changingYear: state.changingYear,
    setChangingYear: state.setChangingYear,
    yearPage: state.yearPage,
    setYearPage: state.setYearPage,
    value: state.exportedValue,
    minDate: state.minDate,
    maxDate: state.maxDate,

    onDayClick: handlers.onDayClickHandler,
    inputProps: handlers.inputProps,
  };

  return (
    <DatepickerContext.Provider value={contextValue}>
      {children}
    </DatepickerContext.Provider>
  );
};

const useDatepickerContext = () => {
  const context = useContext(DatepickerContext);
  if (!context) {
    throw new Error(
      'useDatepickerContext must be used within a DatepickerProvider',
    );
  }
  return context;
};

export default useDatepickerContext;
