import { DateRange } from 'react-day-picker';

import { DatePickerMode, DatePickerValue } from '../types';

import {
  DEFAULT_DATE_FORMATS,
  DEFAULT_MAX_DATE,
  DEFAULT_MIN_DATE,
} from '../constants/dateConstants';

/**
 * Get the default date format based on the mode.
 */
export const getDefaultFormat = (mode: DatePickerMode): string => {
  return DEFAULT_DATE_FORMATS[mode] || DEFAULT_DATE_FORMATS['single']!;
};

/**
 * Get the default value for 'from' or 'to' based on the provided defaultValue.
 */
export const getDefaultValue = (
  field: 'from' | 'to',
  defaultValue?: Date | DateRange,
): Date | undefined => {
  if (!defaultValue) {
    return undefined;
  }

  if (typeof defaultValue === 'object') {
    const isFromValidDate =
      'from' in defaultValue && defaultValue.from instanceof Date;

    const isToValidDate =
      'to' in defaultValue && defaultValue.to instanceof Date;

    // If defaultValue is an object with 'from' and 'to' keys and BOTH are valid dates
    if (isFromValidDate) {
      if (!isToValidDate && field === 'to') return undefined; // Default case

      return defaultValue[field];
    }

    if (defaultValue instanceof Date) {
      // If defaultValue is a valid date
      return defaultValue;
    }
  }

  return undefined; // Default case
};

/**
 * Get the default minimum date.
 */
export const getDefaultMinDate = (date?: Date) => date || DEFAULT_MIN_DATE;

/**
 * Get the default maximum date.
 */
export const getDefaultMaxDate = (date?: Date) => date || DEFAULT_MAX_DATE;

/**
 * Get the exported value based on the mode and selected dates.
 */
export const getExportedValue = <DateMode extends DatePickerMode>(
  mode: DateMode,
  from?: Date,
  to?: Date,
): DatePickerValue<DateMode> | undefined => {
  if (mode !== 'range' && !from) return undefined;

  if (mode === 'range') return { from, to } as DatePickerValue<DateMode>;

  return from as DatePickerValue<DateMode>;
};

/**
 * Helper function to check if a value is a valid Date object.
 */
export const isValidDate = (date: unknown): date is Date => {
  return date instanceof Date && !isNaN(date.getTime());
};
