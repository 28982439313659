import { useActionList, useGroupActionList } from '@latitude/action-list';
import { useMemo } from 'react';

/**
 * Custom hook that provides the functionality of a select component.
 * It uses the `useActionList` hook internally.
 *
 * @returns {ActionList} The action list object with select functionality.
 */
export const useSelect = useActionList;

/**
 * Custom hook for multi-select functionality.
 *
 * @param options - The options for the multi-select.
 * @returns The result of the useGroupActionList hook.
 */
export const useMultiSelect = (
  options: Parameters<typeof useGroupActionList>[0],
) => {
  const memoizedOptions = useMemo(
    () => ({
      enableMultiRowSelection: true,
      ...options,
    }),
    [options],
  );
  return useGroupActionList(memoizedOptions);
};
