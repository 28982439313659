import { Option, RowSelectionState, Select, useSelect } from '@latitude/select';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { InternalFormSelectProps } from './types';

// TODO: ATTENTION default values/reset does not work for this component. If you change your "selection" after intial render the input doesn't update. Same goes for default, if you programmatically change whats selected the input doesn't update.
const InternalFormSingleSelect = <T extends FieldValues = FieldValues>({
  onChange,
  options,
  value,
  ...selectProps
}: InternalFormSelectProps<T>) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  // Can't pass a value, need to find the index manually
  useEffect(() => {
    const selectedIndex = options.findIndex((opt) => opt.value === value);
    setRowSelection(
      selectedIndex >= 0
        ? {
            [selectedIndex]: true,
          }
        : {},
    );
  }, [value, options]);

  const useSelectProps = useMemo(
    () => ({
      data: options,
      state: {
        rowSelection,
      },
      columns: [{ accessorKey: 'label' }],
      onRowSelectionChange: setRowSelection,
    }),
    [options, rowSelection, setRowSelection],
  );

  const mediator = useSelect(useSelectProps);

  return (
    <Select
      mediator={mediator}
      onSelect={(selectedItems: Option<{ label: string; value: string }>[]) =>
        onChange(
          selectedItems && selectedItems[0]
            ? selectedItems[0].value
            : undefined,
        )
      }
      {...selectProps}
    />
  );
};

InternalFormSingleSelect.displayName = 'InternalFormSingleSelect';

export default InternalFormSingleSelect;
