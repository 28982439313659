import { Input } from '@latitude/input';
import { Controller, FieldValues } from 'react-hook-form';

import { FormComponentWrapper } from 'components/FormComponentWrapper';

import { FormInputProps } from './types';

const FormInput = <T extends FieldValues = FieldValues>({
  name,
  control,
  description,
  label,
  wrapperClassname,
  disabled,
  message,
  optional,
  ...inputProps
}: FormInputProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
      <FormComponentWrapper
        name={name}
        description={description}
        label={label}
        error={error}
        className={wrapperClassname}
        message={message}
        optional={optional}
      >
        {/* Value needs to be set as an empty string instead of undefined since if it is it will be treated as an uncontrolled input */}
        <Input
          disabled={disabled}
          value={value ?? ''}
          onBlur={onBlur}
          onChange={onChange}
          onClear={onChange}
          {...inputProps}
        />
      </FormComponentWrapper>
    )}
  />
);

FormInput.displayName = 'FormInput';

export default FormInput;
