import { Button } from '@latitude/button';
import { Tag } from '@latitude/tag';

import { ToggleGroupComponentItem } from 'types';

export const groupTypes: ToggleGroupComponentItem = {
  button: {
    component: Button,
    variant: 'neutral',
    type: 'ghost',
    size: 'tiny',
  },
  tag: {
    component: Tag,
    variant: 'danger',
    fill: 'filled',
    size: 'tiny',
  },
};
