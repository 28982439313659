/* eslint-disable */
// @ts-nocheck
// @generated
// This file was automatically generated and should not be edited.
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export enum AssetTypeID {
    AAVE = 'AAVE',
    AAVE_R = 'AAVE_R',
    ACH = 'ACH',
    AJNA = 'AJNA',
    ALI = 'ALI',
    ALICE = 'ALICE',
    ALLO= 'ALLO',
    ALLO_R= 'ALLO_R',
    ALLO_T= 'ALLO_T',
    ALPHA = 'ALPHA',
    ALUSD = 'ALUSD',
    AMP = 'AMP',
    ANC1 = 'ANC1',
    ANC1_R = 'ANC1_R',
    ANC_2_NFT_721 = 'ANC_2_NFT_721',
    ANC_NFT_721 = 'ANC_NFT_721',
    ANGLE = 'ANGLE',
    ANKR = 'ANKR',
    ANT = 'ANT',
    ANY = 'ANY',
    APE = 'APE',
    APT = 'APT',
    APT_R = 'APT_R',
    APT_T = 'APT_T',
    ARCA = 'ARCA',
    ARCA_T = 'ARCA_T',
    ARDN = 'ARDN',
    ASTO = 'ASTO',
    ATOM = 'ATOM',
    ATOM_R = 'ATOM_R',
    ATOM_SR = 'ATOM_SR',
    ATOM_ST = 'ATOM_ST',
    ATOM_T = 'ATOM_T',
    AUCTION = 'AUCTION',
    AUDIO = 'AUDIO',
    AURORA = 'AURORA',
    AUTOGLYPHS = 'AUTOGLYPHS',
    AVA = 'AVA',
    AVG = 'AVG',
    AXL = 'AXL',
    AXL_R = 'AXL_R',
    AXL_T = 'AXL_T',
    AXS = 'AXS',
    AZUKI = 'AZUKI',
    BADGER = 'BADGER',
    BAL = 'BAL',
    BAND = 'BAND',
    BAT = 'BAT',
    BAYC = 'BAYC',
    BCC = 'BCC',
    BCH = 'BCH',
    BCH_R = 'BCH_R',
    BCH_T = 'BCH_T',
    BETA = 'BETA',
    BGB = 'BGB',
    BICO = 'BICO',
    BIT = 'BIT',
    BLD = 'BLD',
    BLD_R = 'BLD_R',
    BLD_T = 'BLD_T',
    BLOCKS = 'BLOCKS',
    BLUR = 'BLUR',
    BNB = 'BNB',
    BNT = 'BNT',
    BOND = 'BOND',
    BREED = 'BREED',
    BSV = 'BSV',
    BSV_R = 'BSV_R',
    BSV_T = 'BSV_T',
    BTC = 'BTC',
    BTC_R = 'BTC_R',
    BTC_T = 'BTC_T',
    BTRST = 'BTRST',
    BTSE = 'BTSE',
    BUSD = 'BUSD',
    C3 = 'C3',
    CARDS = 'CARDS',
    CBAT = 'CBAT',
    CBETH = 'CBETH',
    CCOMP = 'CCOMP',
    CDAI = 'CDAI',
    CDT = 'CDT',
    CEEK = 'CEEK',
    CEL = 'CEL',
    CELR = 'CELR',
    CETH = 'CETH',
    CEUR = 'CEUR',
    CEUR_R = 'CEUR_R',
    CEUR_TA = 'CEUR_TA',
    CEUR_TB = 'CEUR_TB',
    CGLD = 'CGLD',
    CGLD_R = 'CGLD_R',
    CGLD_TA = 'CGLD_TA',
    CGLD_TB = 'CGLD_TB',
    CHR = 'CHR',
    CHSB = 'CHSB',
    CHZ = 'CHZ',
    CLONEX = 'CLONEX',
    COMBO = 'COMBO',
    COMP = 'COMP',
    COMP_DEV = 'COMP_DEV',
    COMP_R = 'COMP_R',
    COMP_T = 'COMP_T',
    COMP_TG = 'COMP_TG',
    COOL = 'COOL',
    CPOOL = 'CPOOL',
    CQT = 'CQT',
    CREAM = 'CREAM',
    CREP = 'CREP',
    CRO = 'CRO',
    CRV = 'CRV',
    CS = 'CS',
    CSO = 'CSO',
    CTSI = 'CTSI',
    CUNI = 'CUNI',
    CUSD = 'CUSD',
    CUSDC = 'CUSDC',
    CUSD_R = 'CUSD_R',
    CUSD_TA = 'CUSD_TA',
    CUSD_TB = 'CUSD_TB',
    CVC = 'CVC',
    CVX = 'CVX',
    CVXCRV = 'CVXCRV',
    CWBTC2 = 'CWBTC2',
    CZRX = 'CZRX',
    DAI = 'DAI',
    DAO = 'DAO',
    DAR = 'DAR',
    DENT = 'DENT',
    DHT = 'DHT',
    DIDA = 'DIDA',
    DKA = 'DKA',
    DODO = 'DODO',
    DOODLE = 'DOODLE',
    DYDX = 'DYDX',
    DYDX_CHAIN = 'DYDX_CHAIN',
    DYDX_CHAIN_R = 'DYDX_CHAIN_R',
    DYDX_CHAIN_T = 'DYDX_CHAIN_T',
    ECO = 'ECO',
    ECOX = 'ECOX',
    ELF = 'ELF',
    ELFI = 'ELFI',
    ELFI_R = 'ELFI_R',
    ELG = 'ELG',
    ELON = 'ELON',
    ENJ = 'ENJ',
    ENS = 'ENS',
    ENV = 'ENV',
    ETH = 'ETH',
    ETHDEV = 'ETHDEV',
    ETHHOL = 'ETHHOL',
    ETHSEP = 'ETHSEP',
    ETHTEST = 'ETHTEST',
    ETH_R = 'ETH_R',
    ETH_TG = 'ETH_TG',
    ETH_TR = 'ETH_TR',
    EUL = 'EUL',
    EUROC = 'EUROC',
    EURS = 'EURS',
    EVMOS = 'EVMOS',
    EVMOS_R = 'EVMOS_R',
    EVMOS_T = 'EVMOS_T',
    FEI = 'FEI',
    FET = 'FET',
    FF = 'FF',
    FIL = 'FIL',
    FIL_R = 'FIL_R',
    FIL_T = 'FIL_T',
    FIRE = 'FIRE',
    FLOKI = 'FLOKI',
    FLOW = 'FLOW',
    FLOW_R = 'FLOW_R',
    FLOW_T = 'FLOW_T',
    FLX = 'FLX',
    FLY = 'FLY',
    FOAM = 'FOAM',
    FORT = 'FORT',
    FORT_R = 'FORT_R',
    FOX = 'FOX',
    FRAX = 'FRAX',
    FST = 'FST',
    FTM = 'FTM',
    FTT = 'FTT',
    FUEL = 'FUEL',
    FUN = 'FUN',
    FWB = 'FWB',
    FX = 'FX',
    FXS = 'FXS',
    GAL = 'GAL',
    GALA = 'GALA',
    GERO = 'GERO',
    GF = 'GF',
    GFI = 'GFI',
    GMT = 'GMT',
    GNO = 'GNO',
    GNT = 'GNT',
    GOHM = 'GOHM',
    GRT = 'GRT',
    GT = 'GT',
    GUSD = 'GUSD',
    GYEN = 'GYEN',
    HASH = 'HASH',
    HASH_R = 'HASH_R',
    HASH_T = 'HASH_T',
    HBOT = 'HBOT',
    HBTC = 'HBTC',
    HEGIC = 'HEGIC',
    HFT = 'HFT',
    HMT = 'HMT',
    HOT = 'HOT',
    HOVERBOARD = 'HOVERBOARD',
    HT = 'HT',
    HUSD = 'HUSD',
    HXRO = 'HXRO',
    IAG = 'IAG',
    IBEUR = 'IBEUR',
    ICH = 'ICH',
    ICHI = 'ICHI',
    IDK = 'IDK',
    ILV = 'ILV',
    IMX = 'IMX',
    INDEX = 'INDEX',
    INDI = 'INDI',
    INJ = 'INJ',
    INSUR = 'INSUR',
    INX = 'INX',
    INX_DEV = 'INX_DEV',
    INX_R = 'INX_R',
    INX_T = 'INX_T',
    INX_TG = 'INX_TG',
    JAVATH = 'JAVATH',
    JETPACK = 'JETPACK',
    KARATE = 'KARATE',
    KEEP = 'KEEP',
    KEEP_T = 'KEEP_T',
    KNC = 'KNC',
    KNCV2 = 'KNCV2',
    KONGZ = 'KONGZ',
    LAND = 'LAND',
    LDO = 'LDO',
    LENS = 'LENS',
    LEO = 'LEO',
    LINK = 'LINK',
    LINKSEP = 'LINKSEP',
    LINK_T = 'LINK_T',
    LMWR = 'LMWR',
    LOKA = 'LOKA',
    LOOKS = 'LOOKS',
    LOOT = 'LOOT',
    LPT = 'LPT',
    LRC = 'LRC',
    LTC = 'LTC',
    LTC_R = 'LTC_R',
    LTC_T = 'LTC_T',
    LUSD = 'LUSD',
    MAGIC = 'MAGIC',
    MANA = 'MANA',
    MANATEST = 'MANATEST',
    MATIC = 'MATIC',
    MATIC_POLYGON = 'MATIC_POLYGON',
    MATIC_POLYGON_R = 'MATIC_POLYGON_R',
    MATIC_POLYGON_T = 'MATIC_POLYGON_T',
    MAYC = 'MAYC',
    MC = 'MC',
    MCB = 'MCB',
    MEEBITS = 'MEEBITS',
    MEM = 'MEM',
    MEM_B = 'MEM_B',
    METRIC = 'METRIC',
    MIR = 'MIR',
    MKR = 'MKR',
    MKRTEST = 'MKRTEST',
    MKR_DEV = 'MKR_DEV',
    MKR_R = 'MKR_R',
    MKR_TG = 'MKR_TG',
    MLBCB = 'MLBCB',
    MLN = 'MLN',
    MOONBIRD = 'MOONBIRD',
    MORPHO = 'MORPHO',
    MPL = 'MPL',
    MPOND = 'MPOND',
    MTA = 'MTA',
    MTL = 'MTL',
    MTS = 'MTS',
    MULTI = 'MULTI',
    MUSE = 'MUSE',
    MVL = 'MVL',
    MX = 'MX',
    MXC = 'MXC',
    MYC = 'MYC',
    MYT = 'MYT',
    MYTH = 'MYTH',
    NEST = 'NEST',
    NEXO = 'NEXO',
    NFTCOM = 'NFTCOM',
    NFTX = 'NFTX',
    NII = 'NII',
    NMR = 'NMR',
    NOTE = 'NOTE',
    NOUN = 'NOUN',
    NU = 'NU',
    NYM = 'NYM',
    OCEAN = 'OCEAN',
    OCMONK = 'OCMONK',
    OGN = 'OGN',
    OHM = 'OHM',
    OKB = 'OKB',
    OLDBLOCKS = 'OLDBLOCKS',
    OMG = 'OMG',
    OMI = 'OMI',
    ONDO = 'ONDO',
    ONE_INCH = 'ONE_INCH',
    OP = 'OP',
    ORBS = 'ORBS',
    OSMO = 'OSMO',
    OSMO_R = 'OSMO_R',
    OSMO_T = 'OSMO_T',
    OTHR = 'OTHR',
    OXT = 'OXT',
    PARTY = 'PARTY',
    PATH = 'PATH',
    PAX = 'PAX',
    PAXG = 'PAXG',
    PDT = 'PDT',
    PEEPS = 'PEEPS',
    PEOPLE = 'PEOPLE',
    PERP = 'PERP',
    PLA = 'PLA',
    PLTC = 'PLTC',
    POLY = 'POLY',
    POND = 'POND',
    POP = 'POP',
    PPT = 'PPT',
    PRIME = 'PRIME',
    PRINTS = 'PRINTS',
    PROM = 'PROM',
    PSP = 'PSP',
    PSTAKE = 'PSTAKE',
    PYR = 'PYR',
    PYUSD = 'PYUSD',
    QF = 'QF',
    QNT = 'QNT',
    QRKS = 'QRKS',
    RAD = 'RAD',
    RARE = 'RARE',
    RARI = 'RARI',
    RBN = 'RBN',
    RCOIN = 'RCOIN',
    REN = 'REN',
    RENBTC = 'RENBTC',
    REP = 'REP',
    REQ = 'REQ',
    REV = 'REV',
    RLY = 'RLY',
    RMO='RMO',
    RMO_R='RMO_R',
    RMO_T='RMO_T',
    RNDR = 'RNDR',
    ROSE = 'ROSE',
    ROSE_R = 'ROSE_R',
    ROSE_T = 'ROSE_T',
    RPL = 'RPL',
    RSR = 'RSR',
    RST = 'RST',
    RSV = 'RSV',
    RUNE = 'RUNE',
    SAFE = 'SAFE',
    SAND = 'SAND',
    SARCO = 'SARCO',
    SBTC = 'SBTC',
    SD = 'SD',
    SDL = 'SDL',
    SEI = 'SEI',
    SEI_R = 'SEI_R',
    SEI_T = 'SEI_T',
    SETH2 = 'SETH2',
    SHIB = 'SHIB',
    SIPHER = 'SIPHER',
    SKL = 'SKL',
    SNT = 'SNT',
    SNX = 'SNX',
    SNXTEST = 'SNXTEST',
    SOL = 'SOL',
    SOL_R = 'SOL_R',
    SOL_TD = 'SOL_TD',
    SPELL = 'SPELL',
    SRM = 'SRM',
    STETH = 'STETH',
    STG = 'STG',
    STKAAVE = 'STKAAVE',
    STORJ = 'STORJ',
    STRK = 'STRK',
    STRP = 'STRP',
    SUDO = 'SUDO',
    SUI = 'SUI',
    SUI_R = 'SUI_R',
    SUI_T = 'SUI_T',
    SUSHI = 'SUSHI',
    SWISE = 'SWISE',
    SXP = 'SXP',
    SYN = 'SYN',
    T = 'T',
    TBTC = 'TBTC',
    TELLER = 'TELLER',
    TEN = 'TEN',
    TIA = 'TIA',
    TIA_R = 'TIA_R',
    TIA_T = 'TIA_T',
    TITAN = 'TITAN',
    TKX = 'TKX',
    TLC = 'TLC',
    TLM = 'TLM',
    TOADZ = 'TOADZ',
    TOKE = 'TOKE',
    TON = 'TON',
    TONCOIN = 'TONCOIN',
    TRAC = 'TRAC',
    TRIBE = 'TRIBE',
    TRIBL = 'TRIBL',
    TRU = 'TRU',
    TUSD = 'TUSD',
    UMA = 'UMA',
    UNI = 'UNI',
    UOS = 'UOS',
    USD = 'USD',
    USD_R = 'USD_R',
    USDC = 'USDC',
    USDC_T = 'USDC_T',
    USDC_TG = 'USDC_TG',
    USDN = 'USDN',
    USDT = 'USDT',
    VFT = 'VFT',
    VGX = 'VGX',
    VLX = 'VLX',
    VVPDT = 'VVPDT',
    WAVES = 'WAVES',
    WAXL = 'WAXL',
    WBTC = 'WBTC',
    WCELO = 'WCELO',
    WCFG = 'WCFG',
    WCUSD = 'WCUSD',
    WETH = 'WETH',
    WEVMOS = 'WEVMOS',
    WEXIT = 'WEXIT',
    WEXOD = 'WEXOD',
    WFIL = 'WFIL',
    WFLOW = 'WFLOW',
    WLUNA = 'WLUNA',
    WOO = 'WOO',
    WOW = 'WOW',
    WPG = 'WPG',
    WPUNKS = 'WPUNKS',
    WRITE = 'WRITE',
    WSTETH = 'WSTETH',
    WSTR = 'WSTR',
    WSTRAX = 'WSTRAX',
    WZEC = 'WZEC',
    XAUT = 'XAUT',
    XAV = 'XAV',
    XCM = 'XCM',
    XCN = 'XCN',
    XDEFI = 'XDEFI',
    XDX = 'XDX',
    XDX_R = 'XDX_R',
    XDX_T = 'XDX_T',
    XDX_TPM = 'XDX_TPM',
    XIDO = 'XIDO',
    XLM = 'XLM',
    XLM_R = 'XLM_R',
    XLM_T = 'XLM_T',
    XMPL = 'XMPL',
    XRP = 'XRP',
    XRP_R = 'XRP_R',
    XRP_T = 'XRP_T',
    XSUSHI = 'XSUSHI',
    XTZ = 'XTZ',
    XTZ_R = 'XTZ_R',
    XTZ_T = 'XTZ_T',
    XTZ_TB = 'XTZ_TB',
    XUS = 'XUS',
    XUS_R = 'XUS_R',
    XUS_T = 'XUS_T',
    XUS_TPM = 'XUS_TPM',
    XYLB = 'XYLB',
    XYO = 'XYO',
    YFI = 'YFI',
    YGG = 'YGG',
    ZEC = 'ZEC',
    ZEC_R = 'ZEC_R',
    ZEC_T = 'ZEC_T',
    ZIG = 'ZIG',
    ZORA = 'ZORA',
    ZRX = 'ZRX',
    ZRXTEST = 'ZRXTEST'
  }
  
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** AssetTypeID represents one of the asset types we support */
  AssetTypeID: { input: AssetTypeID; output: AssetTypeID; }
  /**
   * Define a Relay CursorType type:
   * https://relay.dev/graphql/connections.htm#sec-CursorType
   */
  CursorType: { input: string; output: string; }
  /**
   * YYYY-MM-DD
   * 2006-02-01
   * UTC business day
   */
  Date: { input: string; output: string; }
  /** An ISO-8601 encoded UTC date string */
  DateTime: { input: string; output: string; }
  /**
   * range [0,MAX_VALUE decimal type golang]
   * decimal separator `.`
   * eg: 1.11312123
   */
  Decimal: { input: string; output: string; }
  /**
   * DisplayPage is an ID for the page where the widget should be displayed on:
   *   - dashboard
   *   - vault
   *   - wallet_list
   *   - wallet_detail
   */
  DisplayPage: { input: string; output: string; }
  /** NetworkID represents a network we support, like BITCOIN_MAINNET, and BITCOIN_TESTNET */
  NetworkID: { input: string; output: string; }
  /** OperationID is a unique ID for an operation */
  OperationID: { input: string; output: string; }
  /**
   * range [0, 100]
   * decimal separator `.`
   * Precision up to 2 decimal places
   * eg: 0, 1.2, 2.53, 35.67, 100
   */
  Percent: { input: string; output: string; }
  /** PublicKeyID is the public key id for public key. This is usually a user's public key id */
  PublicKeyID: { input: string; output: string; }
  /** The builtin Time type */
  Time: { input: string; output: string; }
  /**
   * Timestamp is serialized as a unix nanoseconds string.
   * Ex: {"Timestamp": "1257894021098046017"}
   */
  Timestamp: { input: string; output: string; }
};

/** AMLBlockchainAddress contains a BlockchainAddress provided by a user with AML data */
export type AMLBlockchainAddress = {
  __typename: 'AMLBlockchainAddress';
  /** address which was provided */
  address: BlockchainAddress;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
};

/** A possible anwser used for anti-money laundering questions when creating a withdrawal or a trusted destination */
export type AMLChoice = {
  __typename: 'AMLChoice';
  /** The ID for this choice */
  choiceID: Scalars['String']['output'];
  /** If this answer allows a free form response, then display this in the free form text box as the default answer */
  freeFormInitialValue: Maybe<Scalars['String']['output']>;
  /** If not nil, this answer allows a free form response and display this in the free form text box as a placeholder or a subtitle */
  freeFormPlaceholder: Maybe<Scalars['String']['output']>;
  /** The text for this possible answer choice displayed in a list of possible answers */
  value: Scalars['String']['output'];
};

/** Previously submitted AML answers for prefilling new questionnaire flow */
export type AMLPrefillAnswers = {
  __typename: 'AMLPrefillAnswers';
  /** List of answers to use for prefilling */
  answers: Array<QuestionnaireAnswer>;
  /**
   * If a banner should be shown to the user, given on first new questionnaire
   * when the org has previously submitted AML records on any address,
   * for the first 6 months after launching the new questionnaire
   */
  showBanner: Scalars['Boolean']['output'];
};

/** A question used for anti-money laundering when creating a withdrawal or a trusted destination. */
export type AMLQuestion = {
  __typename: 'AMLQuestion';
  /**
   * This question must be confirmed on every withdrawal, so don't provide a default choice and
   * don't ask in add trusted destination
   */
  askOnEveryWithdrawal: Scalars['Boolean']['output'];
  /**
   * A list of possible answer choices for this question. If this list has exactly one item then
   * only a free form response is required.
   */
  choices: Array<AMLChoice>;
  /**
   * An optional default answer in the case this question was already
   * answered previously. For example, when creating a withdrawal to a trusted destination.
   */
  defaultChoice: Maybe<AMLChoice>;
  /** The text for this question */
  question: Scalars['String']['output'];
  /** The ID for this question */
  questionID: Scalars['String']['output'];
  /** Short label for the question (shown in review) */
  questionLabel: Scalars['String']['output'];
  /** The subtitle for the question (if any) */
  questionSubtitle: Scalars['String']['output'];
};

/** AMLRecord stores responses to AMLQuestions for an operation */
export type AMLRecord = {
  __typename: 'AMLRecord';
  /** The list of responses */
  entries: Array<AMLRecordEntry>;
  /** Submission on the questionnaire flow */
  submission: Maybe<QuestionnaireSubmission>;
};

/** AMLRecordEntry stores a single AMLQuestion and its response */
export type AMLRecordEntry = {
  __typename: 'AMLRecordEntry';
  /** The text for this question */
  question: Scalars['String']['output'];
  /** The ID for this question */
  questionID: Scalars['String']['output'];
  /** Short label for the question (shown in review) */
  questionLabel: Scalars['String']['output'];
  /** The subtitle for this question (if any) */
  questionSubtitle: Scalars['String']['output'];
  /** The response for this question */
  response: Maybe<AMLRecordEntryResponse>;
};

/** AMLRecordEntryResponse contains the response to a single AMLQuestion */
export type AMLRecordEntryResponse = {
  __typename: 'AMLRecordEntryResponse';
  /** The ID for this choice */
  choiceID: Scalars['String']['output'];
  /** Combined value and/or freeFormValue for display in a single field */
  combinedDisplayValue: Scalars['String']['output'];
  /** The value, if present, of a freeform response */
  freeFormValue: Maybe<Scalars['String']['output']>;
  /** The text for this possible answer choice displayed in a list of possible answers */
  value: Scalars['String']['output'];
};

/**
 * Type that holds metadata referring to a REST API key.
 * The key itself should never be shared through this type,
 * since it is effectively a password.
 */
export type APIKey = {
  __typename: 'APIKey';
  /**
   * Indicates if this API key can still be used.
   * @deprecated Use status because it includes more detailed information.
   */
  active: Scalars['Boolean']['output'];
  /** Version of API key is valid for */
  apiVersion: Scalars['String']['output'];
  /** Creation date with nano precision. */
  createdAt: Scalars['String']['output'];
  /** The anchorage user that created this key */
  creator: User;
  /** Description given to the key at the time of creation. */
  description: Scalars['String']['output'];
  /** Unique key ID */
  keyID: Scalars['String']['output'];
  /**
   * Last used date with nano precision.
   * If it is not present it means the key was never used.
   */
  lastUsed: Maybe<Scalars['String']['output']>;
  /** Organization of the API key */
  organization: Organization;
  /**
   * The email of the individual associated with this key.
   * This serves as the point of contact if there are any issues
   * related to this key, such as, but not limited to, API abuse,
   * schema updates, maintenance windows, etc.
   */
  ownerEmail: Scalars['String']['output'];
  /** Public key ID. */
  publicKeyID: Scalars['String']['output'];
  /** Public key */
  publicKeyString: Scalars['String']['output'];
  /** Indicates if the key can be revoked. */
  revocable: Scalars['Boolean']['output'];
  /**
   * Role is present only for APIv2 keys where the key's role has
   * not been deleted
   */
  role: Maybe<Role>;
  /**
   * The permission details of the role associated with the API key.
   *
   * The data is present only for APIv2 keys where the key's role has
   * not been deleted
   *
   * The changeType will be UNCHANGED.
   */
  rolePermissionDetails: Maybe<RolePermissionDetails>;
  /** Indicates the status of this API key */
  status: APIKeyStatus;
};

/** Enumeration of the possible statuses of an API key. */
export enum APIKeyStatus {
  /** Active indicates the API key is active and can be used. */
  ACTIVE = 'ACTIVE',
  /** Pending indicates the API key will become active after some required action. It cannot be used while pending. */
  PENDING = 'PENDING',
  /** Revoked indicates the API key has been revoked and cannot be used. */
  REVOKED = 'REVOKED',
  /** Unused indicates the API key is active but has not been used. */
  UNUSED = 'UNUSED'
}

/** Details about an API key. */
export type APIKeyV2 = Actor & Key & Node & {
  __typename: 'APIKeyV2';
  /** Actor information. */
  actorInfo: ActorInfo;
  /** Unique identifier of this API key. */
  id: Scalars['ID']['output'];
  /** Key details. */
  keyInfo: KeyInfo;
  /**
   * Detail of a permission group (role) associated with this key. Details are
   * composed for the user interface.
   */
  permissionGroupDetails: Maybe<RolePermissionDetails>;
  /** Subtitle for the associated permission group (role) associated with this key. */
  permissionGroupSubtitle: Maybe<Scalars['String']['output']>;
  /** Name of the associated permission group (role) associated with this key. */
  permissionGroupTitle: Maybe<Scalars['String']['output']>;
  /**
   * Role is present only for APIv2 keys where the key's role has
   * not been deleted.
   */
  role: Maybe<Role>;
  /**
   * The permission details of the role associated with the API key.
   *
   * The data is present only for APIv2 keys where the key's role has
   * not been deleted.
   *
   * The changeType will be UNCHANGED.
   */
  rolePermissionDetails: Maybe<RolePermissionDetails>;
  /** Version of API this key is valid for. */
  supportedAPIVersion: Scalars['String']['output'];
};

/** TODO: add a description */
export type APIMetrics = {
  __typename: 'APIMetrics';
  /** The amount per asset that has been transferred */
  assetTransferVolume: Array<Amount>;
  /** the number of clients with V2 API Keys */
  clientsWithKeys: Scalars['Int']['output'];
  /** the number of transfers that have been successfully completed */
  completedTransfers: Scalars['Int']['output'];
  /** The amount per asset that has been transferred cross org */
  crossOrgAssetTransferVolume: Array<Amount>;
  /** The amount per asset that has been transferred externally */
  externalAssetTransferVolume: Array<Amount>;
  /** The amount per asset that has been transferred intra org */
  intraOrgAssetTransferVolume: Array<Amount>;
  /** the number of roles that have been created */
  rolesCreated: Scalars['Int']['output'];
  /** the number of roles that have transfer permissions for all vaults */
  rolesWithTransferPermissionsOnAllVaults: Scalars['Int']['output'];
  /** The total transfer volume for all assets in USD */
  totalTransferVolume: Amount;
};

/** Represents a user that is in the process of being added to an organization */
export type APIStagedUser = StagedUser & {
  __typename: 'APIStagedUser';
  /**
   * The algorithm for the user's public key. This should match the value in the organization change.
   *
   * For backward compatibility with older iOS releases, nil is returned for the default algorithm (ecdsap256). In this case, an algorithm field is not included in the endorsement.
   */
  algorithm: Maybe<Scalars['String']['output']>;
  /** Email of the staged user, if known */
  email: Maybe<Scalars['String']['output']>;
  /** The only possible value for now is eciesEncryptionCofactorSHA512IVAESGCM or null */
  encryptionAlgorithm: Maybe<Scalars['String']['output']>;
  /** Set iff publicKeyID is set */
  encryptionPublicKey: Maybe<Scalars['String']['output']>;
  /**
   * Same as publicKeyID but used for encryption
   * This is set iff encryptionAlgorithm is set
   */
  encryptionPublicKeyID: Maybe<Scalars['PublicKeyID']['output']>;
  /** Enroll token, which is a field of StagedUser. API keys don't have enroll tokens, so the result will be empty. */
  enrollToken: Scalars['String']['output'];
  /** exemptPiis returns list of PII types that the user is exempt from providing. Not applicable to APIStagedUser but required by risk dashboard. */
  exemptPiis: Array<IndividualInformationType>;
  /** keyID contains the API key unique ID */
  keyID: Maybe<Scalars['String']['output']>;
  /** PII information known. Not applicable to APIStagedUser but required by risk dashboard. */
  knownInformation: Array<KnownInformation>;
  /** Last login information. Will be empty if never logged in. */
  lastLogin: Maybe<Login>;
  /** Name of the API key */
  name: Maybe<Scalars['String']['output']>;
  /** permissionGroupDetails contains detail of a permission group (role) associated with this user. Details are composed for the user interface. */
  permissionGroupDetails: Maybe<RolePermissionDetails>;
  /** permissionGroupSubtitle contains a subtitle for the associated permission group (role) associated with this user. */
  permissionGroupSubtitle: Maybe<Scalars['String']['output']>;
  /** permissionGroupTitle contains the name of the associated permission group (role) associated with this user. */
  permissionGroupTitle: Maybe<Scalars['String']['output']>;
  /** Hex encoded representation of the public key. Always present for API users which are added to an organization, but not necessarily for read-only API users. */
  publicKey: Maybe<Scalars['String']['output']>;
  /** Public key ID of the user. Always present for API users which are added to an organization, but not necessarily for read-only API users. */
  publicKeyID: Maybe<Scalars['PublicKeyID']['output']>;
  /** The type of user that the enrollment will create in the organization */
  userType: UserType;
};

/** Account represents the relationship between one fund and one anchorage entity */
export type Account = {
  __typename: 'Account';
  /** ID of the account */
  accountID: Scalars['String']['output'];
  /** AssetsBalance returns a list of amount objects representing the balance breakdown per asset for this account */
  assetsBalance: Array<Amount>;
  /** Display name for this account */
  displayName: Scalars['String']['output'];
  /** Anchorage entity associated with the account */
  entity: AnchorageEntity;
  /** Name of the account */
  name: Scalars['String']['output'];
  /** Organization the account belongs to (first one) */
  organization: Organization;
  /** Organizations the account belongs to */
  organizations: Array<Organization>;
  /** restictions of the account */
  restrictions: Array<Restriction>;
  /** Settlement network participant, if one has been created for this account */
  snParticipant: Maybe<SNParticipant>;
  /** TotalBalance returns an amount object representing the total usd value of assets in this account */
  totalBalance: Amount;
  /** List of vaults associated with this account */
  vaults: Array<Vault>;
};


/** Account represents the relationship between one fund and one anchorage entity */
export type AccounttotalBalanceArgs = {
  forceRunBlockedQuery?: Scalars['Boolean']['input'];
};


/** Account represents the relationship between one fund and one anchorage entity */
export type AccountvaultsArgs = {
  filterCurrentUserIsVaultMember?: InputMaybe<Scalars['Boolean']['input']>;
  filterStates?: InputMaybe<Array<VaultState>>;
  forceRunBlockedQuery?: Scalars['Boolean']['input'];
};

/** AccountFreezeWithdrawalInput to be updated */
export type AccountClosure = {
  /** Account ID */
  accountID: Scalars['String']['input'];
  /** BSA recommended date account has to be closed by */
  bsaRecommendedDate?: InputMaybe<Scalars['Date']['input']>;
  /** Date account was closed */
  closureDate: Scalars['Date']['input'];
  /** Iniated by - e.g. Compliance */
  initiatedBy: Scalars['String']['input'];
  /** MSA Signed */
  msaSigned?: InputMaybe<Scalars['Boolean']['input']>;
  /** MSA termination date */
  msaTerminationDate?: InputMaybe<Scalars['Date']['input']>;
  /** Reasoning */
  reason: Scalars['String']['input'];
};

export type AccountConstraint = Node & {
  __typename: 'AccountConstraint';
  accountConstraintGroups: AccountConstraintGroupConnection;
  accountConstraintStates: AccountConstraintStateConnection;
  createdAt: Scalars['Time']['output'];
  deletedAt: Maybe<Scalars['Time']['output']>;
  deletedBy: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};


export type AccountConstraintaccountConstraintGroupsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccountConstraintGroupWhereInput>;
};


export type AccountConstraintaccountConstraintStatesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccountConstraintStateWhereInput>;
};

/** A connection to a list of items. */
export type AccountConstraintConnection = {
  __typename: 'AccountConstraintConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AccountConstraintEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AccountConstraintEdge = {
  __typename: 'AccountConstraintEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<AccountConstraint>;
};

export type AccountConstraintGroup = Node & {
  __typename: 'AccountConstraintGroup';
  accountConstraints: AccountConstraintConnection;
  createdAt: Scalars['Time']['output'];
  deletedAt: Maybe<Scalars['Time']['output']>;
  deletedBy: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};


export type AccountConstraintGroupaccountConstraintsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccountConstraintWhereInput>;
};

/** A connection to a list of items. */
export type AccountConstraintGroupConnection = {
  __typename: 'AccountConstraintGroupConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AccountConstraintGroupEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AccountConstraintGroupEdge = {
  __typename: 'AccountConstraintGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<AccountConstraintGroup>;
};

/**
 * AccountConstraintGroupWhereInput is used for filtering AccountConstraintGroup objects.
 * Input was generated by ent.
 */
export type AccountConstraintGroupWhereInput = {
  and?: InputMaybe<Array<AccountConstraintGroupWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** deleted_by field predicates */
  deletedBy?: InputMaybe<Scalars['String']['input']>;
  deletedByContains?: InputMaybe<Scalars['String']['input']>;
  deletedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  deletedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  deletedByGT?: InputMaybe<Scalars['String']['input']>;
  deletedByGTE?: InputMaybe<Scalars['String']['input']>;
  deletedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  deletedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  deletedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedByLT?: InputMaybe<Scalars['String']['input']>;
  deletedByLTE?: InputMaybe<Scalars['String']['input']>;
  deletedByNEQ?: InputMaybe<Scalars['String']['input']>;
  deletedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** account_constraints edge predicates */
  hasAccountConstraints?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountConstraintsWith?: InputMaybe<Array<AccountConstraintWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idContainsFold?: InputMaybe<Scalars['ID']['input']>;
  idEqualFold?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<AccountConstraintGroupWhereInput>;
  or?: InputMaybe<Array<AccountConstraintGroupWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type AccountConstraintState = Node & {
  __typename: 'AccountConstraintState';
  accountConstraints: Maybe<AccountConstraint>;
  accounts: Maybe<AccountV2>;
  createdAt: Scalars['Time']['output'];
  deletedAt: Maybe<Scalars['Time']['output']>;
  deletedBy: Maybe<Scalars['String']['output']>;
  fulfilled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type AccountConstraintStateConnection = {
  __typename: 'AccountConstraintStateConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AccountConstraintStateEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AccountConstraintStateEdge = {
  __typename: 'AccountConstraintStateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<AccountConstraintState>;
};

/**
 * AccountConstraintStateWhereInput is used for filtering AccountConstraintState objects.
 * Input was generated by ent.
 */
export type AccountConstraintStateWhereInput = {
  and?: InputMaybe<Array<AccountConstraintStateWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** deleted_by field predicates */
  deletedBy?: InputMaybe<Scalars['String']['input']>;
  deletedByContains?: InputMaybe<Scalars['String']['input']>;
  deletedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  deletedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  deletedByGT?: InputMaybe<Scalars['String']['input']>;
  deletedByGTE?: InputMaybe<Scalars['String']['input']>;
  deletedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  deletedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  deletedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedByLT?: InputMaybe<Scalars['String']['input']>;
  deletedByLTE?: InputMaybe<Scalars['String']['input']>;
  deletedByNEQ?: InputMaybe<Scalars['String']['input']>;
  deletedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** fulfilled field predicates */
  fulfilled?: InputMaybe<Scalars['Boolean']['input']>;
  fulfilledNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** account_constraints edge predicates */
  hasAccountConstraints?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountConstraintsWith?: InputMaybe<Array<AccountConstraintWhereInput>>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountV2WhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<AccountConstraintStateWhereInput>;
  or?: InputMaybe<Array<AccountConstraintStateWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * AccountConstraintWhereInput is used for filtering AccountConstraint objects.
 * Input was generated by ent.
 */
export type AccountConstraintWhereInput = {
  and?: InputMaybe<Array<AccountConstraintWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** deleted_by field predicates */
  deletedBy?: InputMaybe<Scalars['String']['input']>;
  deletedByContains?: InputMaybe<Scalars['String']['input']>;
  deletedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  deletedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  deletedByGT?: InputMaybe<Scalars['String']['input']>;
  deletedByGTE?: InputMaybe<Scalars['String']['input']>;
  deletedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  deletedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  deletedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedByLT?: InputMaybe<Scalars['String']['input']>;
  deletedByLTE?: InputMaybe<Scalars['String']['input']>;
  deletedByNEQ?: InputMaybe<Scalars['String']['input']>;
  deletedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** account_constraint_groups edge predicates */
  hasAccountConstraintGroups?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountConstraintGroupsWith?: InputMaybe<Array<AccountConstraintGroupWhereInput>>;
  /** account_constraint_states edge predicates */
  hasAccountConstraintStates?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountConstraintStatesWith?: InputMaybe<Array<AccountConstraintStateWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idContainsFold?: InputMaybe<Scalars['ID']['input']>;
  idEqualFold?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<AccountConstraintWhereInput>;
  or?: InputMaybe<Array<AccountConstraintWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** AccountFreezeWithdrawalInput to be updated */
export type AccountFreezeWithdrawalInput = {
  /** account ID */
  accountID: Scalars['ID']['input'];
  /** Indicates if we are turning the restriction on or off */
  enabled: Scalars['Boolean']['input'];
  /** Indicates why restriction is being updated */
  reason: Scalars['String']['input'];
};

/** AccountMetadata maintains additional fields for the collateral account */
export type AccountMetadata = {
  __typename: 'AccountMetadata';
  digitalAssets: Maybe<DigitalAssetAccountMetadata>;
  miningEquipments: Maybe<MiningEquipmentAccountMetadata>;
};

/** A PDF download response including the base64 encoded PDF as well as the filename */
export type AccountStatementPdf = {
  __typename: 'AccountStatementPdf';
  /** The filename */
  filename: Scalars['String']['output'];
  /** The base64 encoded PDF */
  pdf: Scalars['String']['output'];
};

export type AccountV2 = Node & {
  __typename: 'AccountV2';
  accountConstraintStates: AccountConstraintStateConnection;
  affiliateAnchorageRelationships: Maybe<AffiliateAnchorageRelationship>;
  createdAt: Scalars['Time']['output'];
  deletedAt: Maybe<Scalars['Time']['output']>;
  deletedBy: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};


export type AccountV2accountConstraintStatesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccountConstraintStateWhereInput>;
};

/** A connection to a list of items. */
export type AccountV2Connection = {
  __typename: 'AccountV2Connection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AccountV2Edge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AccountV2Edge = {
  __typename: 'AccountV2Edge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<AccountV2>;
};

/**
 * AccountV2WhereInput is used for filtering AccountV2 objects.
 * Input was generated by ent.
 */
export type AccountV2WhereInput = {
  and?: InputMaybe<Array<AccountV2WhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** deleted_by field predicates */
  deletedBy?: InputMaybe<Scalars['String']['input']>;
  deletedByContains?: InputMaybe<Scalars['String']['input']>;
  deletedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  deletedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  deletedByGT?: InputMaybe<Scalars['String']['input']>;
  deletedByGTE?: InputMaybe<Scalars['String']['input']>;
  deletedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  deletedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  deletedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedByLT?: InputMaybe<Scalars['String']['input']>;
  deletedByLTE?: InputMaybe<Scalars['String']['input']>;
  deletedByNEQ?: InputMaybe<Scalars['String']['input']>;
  deletedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** account_constraint_states edge predicates */
  hasAccountConstraintStates?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountConstraintStatesWith?: InputMaybe<Array<AccountConstraintStateWhereInput>>;
  /** affiliate_anchorage_relationships edge predicates */
  hasAffiliateAnchorageRelationships?: InputMaybe<Scalars['Boolean']['input']>;
  hasAffiliateAnchorageRelationshipsWith?: InputMaybe<Array<AffiliateAnchorageRelationshipWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<AccountV2WhereInput>;
  or?: InputMaybe<Array<AccountV2WhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * The Actor interface should be implemented by entities that can change some
 * state in our platform, initiate operations or actions (e.g., organization change,
 * transactions, trades, etc).
 */
export type Actor = {
  /** Basic information about the actor. */
  actorInfo: ActorInfo;
};

/**
 * ActorInfo contains basic display information about an actor in our platform.
 * An actor is someone or something that may endorse, initiate operations, perform
 * actions, change settings, and fiddle with knobs.
 */
export type ActorInfo = {
  __typename: 'ActorInfo';
  /** Optional description about the actor in a format suitable for displaying in UIs. */
  displayDescription: Maybe<Scalars['String']['output']>;
  /** Name of the actor in a format suitable for displaying in UIs. */
  displayName: Scalars['String']['output'];
};

/**
 * AddAssetSupportInput represents the input needed to add support for a new asset
 * like an ERC-20 or Solana SPL token.
 */
export type AddAssetSupportInput = {
  /**
   * The abbreviation of the asset to add support for.
   * Example: "MKR" as a short form of the token name
   */
  abbrev: Scalars['String']['input'];
  /**
   * The desired asset type ID of the asset to add support for.
   * Example: "MKR" the Maker assetTypeID
   */
  assetTypeID: Scalars['String']['input'];
  /**
   * The divisibility of the asset to add support for.
   * Example: "18" indicating MKR's divisibility
   */
  decimals: Scalars['String']['input'];
  /**
   * The name of asset to add support for.
   * Example: "Maker"
   */
  name: Scalars['String']['input'];
  /**
   * The network ID of the asset to add support for.
   * Example: "ETHEREUM_MAINNET" for Ethereum Mainnet
   */
  networkID: Scalars['NetworkID']['input'];
  /**
   * The on chain identifier for the asset.
   * Example: "0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2" for MKR's contract address on Ethereum
   * Example: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v" for an SPL token mint address
   */
  onChainIdentifier: Scalars['String']['input'];
  /**
   * The symbol of the asset to add support for.
   * Example: "MKR" as the token symbol
   */
  symbol: Scalars['String']['input'];
  /**
   * The token type to add support for.
   * Example: "ERC-20" representing Ethereum tokens aka ERC-20
   * Example: "SPL" representing a Solana program library
   */
  tokenType: Scalars['String']['input'];
};

/** AddOrganizationOperation represents an operation that bootstraps a brand new organization */
export type AddOrganizationOperation = Operation & {
  __typename: 'AddOrganizationOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** Organization that is being added */
  newOrganization: StagedOrganization;
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * This will always return an empty organization for this operation,
   * since it is not related to any one organization.
   * @deprecated use operationInfo
   */
  organization: Maybe<Organization>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
};


/** AddOrganizationOperation represents an operation that bootstraps a brand new organization */
export type AddOrganizationOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/**
 * AddQuorumRecoveryInput represents the input needed to add a quorum recovery
 * entry.
 */
export type AddQuorumRecoveryInput = {
  /**
   * The IDs of the lost user keys. These keys will be able to be replaced by the
   * super user.
   */
  lostUserKeyIDs: Array<Scalars['String']['input']>;
  /** The ID of the organization for which the quorum recovery is being added. */
  organizationKeyID: Scalars['String']['input'];
  /**
   * The ID of the user key that will be considered the super user. The super user
   * will be able to replace lost user keys with only one endorsement.
   */
  superUserKeyID: Scalars['String']['input'];
};

/** AddTrustedDestinationOperation represents an operation where a user is added to the organization */
export type AddTrustedDestinationOperation = Operation & {
  __typename: 'AddTrustedDestinationOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** Trusted destination being added to the organization */
  destination: TrustedDestination;
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** the expected digest of the new organization after applying this change */
  newOrganizationDigest: Scalars['String']['output'];
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * policy that applies to this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /** riskAnalysis is the collected risk information for this address if we have any */
  riskAnalysis: Maybe<TransactionRiskAnalysis>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
};


/** AddTrustedDestinationOperation represents an operation where a user is added to the organization */
export type AddTrustedDestinationOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** AddTrustedDestinationOperation represents an operation where a user is added to the organization */
export type AddTrustedDestinationOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/** AddTrustedDestinationsInput represents the input needed to add a new trusted destination */
export type AddTrustedDestinationsInput = {
  /** amlRecord returns the associated AML record */
  amlQuestionnaire?: InputMaybe<InputQuestionnaireSubmission>;
  /** Further information regarding this asset type */
  assetTypeID: Scalars['AssetTypeID']['input'];
  /** Description of the trusted address */
  description: Scalars['String']['input'];
  /** The address */
  destination: Scalars['String']['input'];
  /**
   * isAtNetworkLevel denotes whether the trusted destination is used as network level or asset level
   * true: network level, false: asset level
   */
  isAtNetworkLevel: Scalars['Boolean']['input'];
  /** Name of the trusted address */
  name: Scalars['String']['input'];
  /** ExtraParams for for trusted destinations, depending on the asset being used */
  trustedDestinationExtraParams?: InputMaybe<InputTrustedDestinationExtraParams>;
};

/**
 * AddTrustedSmartContractInput represents the input needed to add a new trusted
 * smart contract.
 */
export type AddTrustedSmartContractInput = {
  /** The contract address. */
  address: Scalars['String']['input'];
  /**
   * Asset type ID of the contract address.
   * @deprecated Use networkID instead
   */
  assetTypeID?: InputMaybe<Scalars['AssetTypeID']['input']>;
  /** Description of the trusted smart contract. */
  description: Scalars['String']['input'];
  /** Name of the trusted smart contract. */
  name: Scalars['String']['input'];
  /** Network ID of the contract address. */
  networkID?: Scalars['NetworkID']['input'];
  /** List of vault sub IDs that the smart contract is trusted for. */
  vaultSubIDs: Array<Scalars['String']['input']>;
};

/**
 * AddUserAndPoliciesOperation represents an operation where a user is added to the organization
 * and its policies
 */
export type AddUserAndPoliciesOperation = Operation & {
  __typename: 'AddUserAndPoliciesOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** The key that will be added to the organization */
  key: Key;
  /** the expected digest of the new organization after applying this change */
  newOrganizationDigest: Scalars['String']['output'];
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * policy that applies to this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /**
   * policyContainerUpdate represents the bindings that will be applied
   * to the org policy container
   */
  policyContainerUpdate: Maybe<PolicyContainerUpdate>;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /**
   * role enables adding an API public key id to the matching role
   * Role is only used when creating an API Key where we need the role to be updated with the new public key id
   */
  role: Maybe<Role>;
  /** roleChanges represents the IAM role changes that will be applied with this operation. */
  roleChanges: Maybe<IAMRoleChanges>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /** Policies the user is being added to */
  updatedOrgPolicies: Array<PolicyUpdate>;
  /** Vault policies the user is being added to */
  updatedVaultPolicies: Array<VaultPolicyUpdate>;
  /** The user being added to the organization */
  user: StagedUser;
  /**
   * vaultPolicyContainerUpdate represents the bindings that will be applied
   * to the vault policy container
   */
  vaultPolicyContainerUpdate: Maybe<VaultPolicyContainerUpdate>;
};


/**
 * AddUserAndPoliciesOperation represents an operation where a user is added to the organization
 * and its policies
 */
export type AddUserAndPoliciesOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/**
 * AddUserAndPoliciesOperation represents an operation where a user is added to the organization
 * and its policies
 */
export type AddUserAndPoliciesOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/** AddressBalance represents a single balance of a particular assetType held by a particular blockchainAddress */
export type AddressBalance = Node & {
  __typename: 'AddressBalance';
  /** Balance returns an amount corresponding to the complete balance of funds of a particular asset type held by this address */
  balance: Amount;
  /** BlockchainAddress returns an object of type BlockchainAddress representing the address associated with this addressBalance */
  blockchainAddress: BlockchainAddress;
  /** The ID of the AddressBalance. Combination of the address string and the assetTypeID */
  id: Scalars['ID']['output'];
};

/** AddressBalanceConnection represents a page of AddressBalances based on the parameters of pagination used, defined by a pageInfo object. Implements the connections spec for pagination */
export type AddressBalanceConnection = {
  __typename: 'AddressBalanceConnection';
  /** Edges contains a list of AddressBalanceEdge objects representing a page of AddressBalance data. Implements the connections spec for pagination. */
  edges: Array<AddressBalanceEdge>;
  /** pageInfo returns information about the page returned above. implements the connections spec for pagination */
  pageInfo: PageInfo;
};

/** AddressBalanceEdge contains an AddressBalance and the cursor representing the same AddressBalance - implements the connections spec for pagination */
export type AddressBalanceEdge = {
  __typename: 'AddressBalanceEdge';
  /** Cursor implements the connections spec for pagination */
  cursor: Scalars['ID']['output'];
  /** Node implements the connections spec for pagination */
  node: AddressBalance;
};

/** IP Address family */
export enum AddressFamily {
  /** IPv4 family */
  IPV4 = 'IPV4',
  /** IPv6 family */
  IPV6 = 'IPV6'
}

/** An AddressID uniquely identifies an address we control */
export type AddressID = {
  __typename: 'AddressID';
  /** addressSubID is the id of the address within the context of this asset */
  addressSubID: Scalars['String']['output'];
  /** assetID is the AssetID this address belongs to */
  assetID: AssetID;
};

/** AddressInputStep is a step that allows the user to input a plain network address. */
export type AddressInputStep = StakingStep & {
  __typename: 'AddressInputStep';
  /** Description is the label shown on the UI. */
  description: Scalars['String']['output'];
  /** Ignore this field */
  ignoreMe: Maybe<Scalars['Boolean']['output']>;
  /** Rules that change presentation of the step */
  rules: Maybe<Array<PresentationRule>>;
};

/** AdvancedTradingConfig holds the configuration for advanced trading */
export type AdvancedTradingConfig = {
  __typename: 'AdvancedTradingConfig';
  /** URL to enter the advanced trading dashboard */
  advancedTradingURL: Maybe<Scalars['String']['output']>;
};

export type AffiliateAnchorageRelationship = Node & {
  __typename: 'AffiliateAnchorageRelationship';
  accounts: AccountV2Connection;
  affiliates: AffiliateV2;
  anchorageLegalEntities: AnchorageLegalEntity;
  createdAt: Scalars['Time']['output'];
  deletedAt: Maybe<Scalars['Time']['output']>;
  deletedBy: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};


export type AffiliateAnchorageRelationshipaccountsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccountV2WhereInput>;
};

/** A connection to a list of items. */
export type AffiliateAnchorageRelationshipConnection = {
  __typename: 'AffiliateAnchorageRelationshipConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AffiliateAnchorageRelationshipEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AffiliateAnchorageRelationshipEdge = {
  __typename: 'AffiliateAnchorageRelationshipEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<AffiliateAnchorageRelationship>;
};

/**
 * AffiliateAnchorageRelationshipWhereInput is used for filtering AffiliateAnchorageRelationship objects.
 * Input was generated by ent.
 */
export type AffiliateAnchorageRelationshipWhereInput = {
  and?: InputMaybe<Array<AffiliateAnchorageRelationshipWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** deleted_by field predicates */
  deletedBy?: InputMaybe<Scalars['String']['input']>;
  deletedByContains?: InputMaybe<Scalars['String']['input']>;
  deletedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  deletedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  deletedByGT?: InputMaybe<Scalars['String']['input']>;
  deletedByGTE?: InputMaybe<Scalars['String']['input']>;
  deletedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  deletedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  deletedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedByLT?: InputMaybe<Scalars['String']['input']>;
  deletedByLTE?: InputMaybe<Scalars['String']['input']>;
  deletedByNEQ?: InputMaybe<Scalars['String']['input']>;
  deletedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountV2WhereInput>>;
  /** affiliates edge predicates */
  hasAffiliates?: InputMaybe<Scalars['Boolean']['input']>;
  hasAffiliatesWith?: InputMaybe<Array<AffiliateV2WhereInput>>;
  /** anchorage_legal_entities edge predicates */
  hasAnchorageLegalEntities?: InputMaybe<Scalars['Boolean']['input']>;
  hasAnchorageLegalEntitiesWith?: InputMaybe<Array<AnchorageLegalEntityWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<AffiliateAnchorageRelationshipWhereInput>;
  or?: InputMaybe<Array<AffiliateAnchorageRelationshipWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** AccountFreezeWithdrawalInput to be updated */
export type AffiliateFreezeWithdrawalInput = {
  /** affiliate ID */
  affiliateID: Scalars['ID']['input'];
  /** Indicates if we are turning the restriction on or off */
  enabled: Scalars['Boolean']['input'];
  /** Indicates why restriction is being updated */
  reason: Scalars['String']['input'];
};

/** Type of information and reason rejected */
export type AffiliateInformationRejection = {
  __typename: 'AffiliateInformationRejection';
  /** Type of information */
  informationType: AffiliateInformationType;
  /** Reason the information was rejected */
  rejectionReason: Scalars['String']['output'];
};

/** Enum of information that may be provided by institutional affiliates */
export enum AffiliateInformationType {
  /** ACCEPT_INVESTOR_FUNDS */
  ACCEPT_INVESTOR_FUNDS = 'ACCEPT_INVESTOR_FUNDS',
  /** ACCOUNT_ACTIVITY ... */
  ACCOUNT_ACTIVITY = 'ACCOUNT_ACTIVITY',
  /** ACCOUNT_ACTIVITY_BORROWING */
  ACCOUNT_ACTIVITY_BORROWING = 'ACCOUNT_ACTIVITY_BORROWING',
  /** ACCOUNT_ACTIVITY_CUSTODY */
  ACCOUNT_ACTIVITY_CUSTODY = 'ACCOUNT_ACTIVITY_CUSTODY',
  /** ACCOUNT_ACTIVITY_INVESTMENTS ... */
  ACCOUNT_ACTIVITY_INVESTMENTS = 'ACCOUNT_ACTIVITY_INVESTMENTS',
  /** ACCOUNT_ACTIVITY_LENDING */
  ACCOUNT_ACTIVITY_LENDING = 'ACCOUNT_ACTIVITY_LENDING',
  /** ACCOUNT_ACTIVITY_MINING ... */
  ACCOUNT_ACTIVITY_MINING = 'ACCOUNT_ACTIVITY_MINING',
  /** ACCOUNT_ACTIVITY_STAKING */
  ACCOUNT_ACTIVITY_STAKING = 'ACCOUNT_ACTIVITY_STAKING',
  /** ACCOUNT_ACTIVITY_TRADING */
  ACCOUNT_ACTIVITY_TRADING = 'ACCOUNT_ACTIVITY_TRADING',
  /** ACCOUNT_BANK_ACTIVITY */
  ACCOUNT_BANK_ACTIVITY = 'ACCOUNT_BANK_ACTIVITY',
  /** ACCOUNT_BANK_ACTIVITY_CUSTODY */
  ACCOUNT_BANK_ACTIVITY_CUSTODY = 'ACCOUNT_BANK_ACTIVITY_CUSTODY',
  /** ACCOUNT_BANK_ACTIVITY_STAKING */
  ACCOUNT_BANK_ACTIVITY_STAKING = 'ACCOUNT_BANK_ACTIVITY_STAKING',
  /** ACCOUNT_NAME ... */
  ACCOUNT_NAME = 'ACCOUNT_NAME',
  /** ACCOUNT_PURPOSE */
  ACCOUNT_PURPOSE = 'ACCOUNT_PURPOSE',
  /** ACCOUNT_REGISTRATION ... */
  ACCOUNT_REGISTRATION = 'ACCOUNT_REGISTRATION',
  /** ACCOUNT_REGISTRATION_CORPORATION ... */
  ACCOUNT_REGISTRATION_CORPORATION = 'ACCOUNT_REGISTRATION_CORPORATION',
  /** ACCOUNT_REGISTRATION_LLC ... */
  ACCOUNT_REGISTRATION_LLC = 'ACCOUNT_REGISTRATION_LLC',
  /** ACCOUNT_REGISTRATION_OTHER ... */
  ACCOUNT_REGISTRATION_OTHER = 'ACCOUNT_REGISTRATION_OTHER',
  /** ACCOUNT_REGISTRATION_PARTNERSHIP ... */
  ACCOUNT_REGISTRATION_PARTNERSHIP = 'ACCOUNT_REGISTRATION_PARTNERSHIP',
  /** ACCOUNT_REGISTRATION_SOLE_PROPRIETOR ... */
  ACCOUNT_REGISTRATION_SOLE_PROPRIETOR = 'ACCOUNT_REGISTRATION_SOLE_PROPRIETOR',
  /** ACCOUNT_SOURCE_OF_FUNDS ... */
  ACCOUNT_SOURCE_OF_FUNDS = 'ACCOUNT_SOURCE_OF_FUNDS',
  /** ACKNOWLEDGE_EMAIL ... */
  ACKNOWLEDGE_EMAIL = 'ACKNOWLEDGE_EMAIL',
  /** ACKNOWLEDGE_FIRST_NAME ... */
  ACKNOWLEDGE_FIRST_NAME = 'ACKNOWLEDGE_FIRST_NAME',
  /** ACKNOWLEDGE_LAST_NAME ... */
  ACKNOWLEDGE_LAST_NAME = 'ACKNOWLEDGE_LAST_NAME',
  /** ACKNOWLEDGE_MIDDLE_NAME ... */
  ACKNOWLEDGE_MIDDLE_NAME = 'ACKNOWLEDGE_MIDDLE_NAME',
  /** ACKNOWLEDGE_NAME ... */
  ACKNOWLEDGE_NAME = 'ACKNOWLEDGE_NAME',
  /** ACKNOWLEDGE_TITLE ... */
  ACKNOWLEDGE_TITLE = 'ACKNOWLEDGE_TITLE',
  /** ADB_INVESTMENT_MANAGER_AFFILIATE_SUBMISSION_ID ... */
  ADB_INVESTMENT_MANAGER_AFFILIATE_SUBMISSION_ID = 'ADB_INVESTMENT_MANAGER_AFFILIATE_SUBMISSION_ID',
  /** ADB_MANAGED_FUND_AFFILIATE_SUBMISSION_ID ... */
  ADB_MANAGED_FUND_AFFILIATE_SUBMISSION_ID = 'ADB_MANAGED_FUND_AFFILIATE_SUBMISSION_ID',
  /** ADDITIONAL_DOCUMENT */
  ADDITIONAL_DOCUMENT = 'ADDITIONAL_DOCUMENT',
  /** ADDRESS ... */
  ADDRESS = 'ADDRESS',
  /** ADDRESS2 ... */
  ADDRESS2 = 'ADDRESS2',
  /** ADMIN_ISSUE ... */
  ADMIN_ISSUE = 'ADMIN_ISSUE',
  /** ADMIN_ISSUE_CATEGORY ... */
  ADMIN_ISSUE_CATEGORY = 'ADMIN_ISSUE_CATEGORY',
  /** ADMIN_ISSUE_ISSUE_DETAIL ... */
  ADMIN_ISSUE_ISSUE_DETAIL = 'ADMIN_ISSUE_ISSUE_DETAIL',
  /** ADMIN_ISSUE_KYC_ANALYST_COMMENT ... */
  ADMIN_ISSUE_KYC_ANALYST_COMMENT = 'ADMIN_ISSUE_KYC_ANALYST_COMMENT',
  /** ADMIN_ISSUE_VALID_OR_INVALID ... */
  ADMIN_ISSUE_VALID_OR_INVALID = 'ADMIN_ISSUE_VALID_OR_INVALID',
  /** ADS_KYC_AFFILIATE_FORM_SUBMISSION_ID ... */
  ADS_KYC_AFFILIATE_FORM_SUBMISSION_ID = 'ADS_KYC_AFFILIATE_FORM_SUBMISSION_ID',
  /** AFFILIATE_TYPE ... */
  AFFILIATE_TYPE = 'AFFILIATE_TYPE',
  /** AFFILIATE_WEBSITE_URL */
  AFFILIATE_WEBSITE_URL = 'AFFILIATE_WEBSITE_URL',
  /** AIA_AUTHORIZED_PERSON_NAME ... */
  AIA_AUTHORIZED_PERSON_NAME = 'AIA_AUTHORIZED_PERSON_NAME',
  /** AIA_DATE ... */
  AIA_DATE = 'AIA_DATE',
  /** AIA_ELIGIBILITY_CATEGORY ... */
  AIA_ELIGIBILITY_CATEGORY = 'AIA_ELIGIBILITY_CATEGORY',
  /** AIA_FINANCIAL_ASSETS_DOC ... */
  AIA_FINANCIAL_ASSETS_DOC = 'AIA_FINANCIAL_ASSETS_DOC',
  /** AIA_PERSONAL_ASSETS_DOC ... */
  AIA_PERSONAL_ASSETS_DOC = 'AIA_PERSONAL_ASSETS_DOC',
  /** AIA_PERSONAL_INCOME_DOC ... */
  AIA_PERSONAL_INCOME_DOC = 'AIA_PERSONAL_INCOME_DOC',
  /** AIA_SIGNATURE ... */
  AIA_SIGNATURE = 'AIA_SIGNATURE',
  /** AIA_TITLE ... */
  AIA_TITLE = 'AIA_TITLE',
  /** ALTERNATIVE_PAYMENT_METHODS ... */
  ALTERNATIVE_PAYMENT_METHODS = 'ALTERNATIVE_PAYMENT_METHODS',
  /** AML_AUDIT_REPORTS ... */
  AML_AUDIT_REPORTS = 'AML_AUDIT_REPORTS',
  /** AML_ELEMENTE_SUSPICIOUS_ACTIVITY ... */
  AML_ELEMENTE_SUSPICIOUS_ACTIVITY = 'AML_ELEMENTE_SUSPICIOUS_ACTIVITY',
  /** AML_ELEMENT_INDIVIDUALS ... */
  AML_ELEMENT_INDIVIDUALS = 'AML_ELEMENT_INDIVIDUALS',
  /** AML_ELEMENT_ONGOING_SANCTIONS ... */
  AML_ELEMENT_ONGOING_SANCTIONS = 'AML_ELEMENT_ONGOING_SANCTIONS',
  /** AML_ELEMENT_ONGOING_VERIFICATION ... */
  AML_ELEMENT_ONGOING_VERIFICATION = 'AML_ELEMENT_ONGOING_VERIFICATION',
  /** AML_ELEMENT_POLICIES ... */
  AML_ELEMENT_POLICIES = 'AML_ELEMENT_POLICIES',
  /** AML_ELEMENT_RETAIN_RECORDS ... */
  AML_ELEMENT_RETAIN_RECORDS = 'AML_ELEMENT_RETAIN_RECORDS',
  /** AML_ELEMENT_SCREENING_OFAC ... */
  AML_ELEMENT_SCREENING_OFAC = 'AML_ELEMENT_SCREENING_OFAC',
  /** AML_ELEMENT_SCREENING_PEP ... */
  AML_ELEMENT_SCREENING_PEP = 'AML_ELEMENT_SCREENING_PEP',
  /** AML_ELEMENT_TESTING ... */
  AML_ELEMENT_TESTING = 'AML_ELEMENT_TESTING',
  /** AML_EXEMPTION_REASON ... */
  AML_EXEMPTION_REASON = 'AML_EXEMPTION_REASON',
  /** AML_FINDING ... */
  AML_FINDING = 'AML_FINDING',
  /** AML_FINDING_CATEGORY ... */
  AML_FINDING_CATEGORY = 'AML_FINDING_CATEGORY',
  /** AML_FINDING_ISSUE_DETAIL ... */
  AML_FINDING_ISSUE_DETAIL = 'AML_FINDING_ISSUE_DETAIL',
  /** AML_FINDING_KYC_ANALYST_COMMENT ... */
  AML_FINDING_KYC_ANALYST_COMMENT = 'AML_FINDING_KYC_ANALYST_COMMENT',
  /** AML_FINDING_VALID_OR_INVALID ... */
  AML_FINDING_VALID_OR_INVALID = 'AML_FINDING_VALID_OR_INVALID',
  /** AML_KYC_PROGRAM_REVIEW */
  AML_KYC_PROGRAM_REVIEW = 'AML_KYC_PROGRAM_REVIEW',
  /** AML_OFFICER_NAME ... */
  AML_OFFICER_NAME = 'AML_OFFICER_NAME',
  /** AML_POLICIES ... */
  AML_POLICIES = 'AML_POLICIES',
  /** AML_POLICY_DOCUMENT ... */
  AML_POLICY_DOCUMENT = 'AML_POLICY_DOCUMENT',
  /** AML_PROGRAM_COMPONENT ... */
  AML_PROGRAM_COMPONENT = 'AML_PROGRAM_COMPONENT',
  /** AML_PROGRAM_ELEMENTS ... */
  AML_PROGRAM_ELEMENTS = 'AML_PROGRAM_ELEMENTS',
  /** ANCHORAGE_ENTITY_TYPE ... */
  ANCHORAGE_ENTITY_TYPE = 'ANCHORAGE_ENTITY_TYPE',
  /** ANNUAL_REPORT */
  ANNUAL_REPORT = 'ANNUAL_REPORT',
  /** APPOINTMENT_OF_TRUSTEES ... */
  APPOINTMENT_OF_TRUSTEES = 'APPOINTMENT_OF_TRUSTEES',
  /** ARE_AUTHORIZED_SIGNATORIES_APPOINTED ... */
  ARE_AUTHORIZED_SIGNATORIES_APPOINTED = 'ARE_AUTHORIZED_SIGNATORIES_APPOINTED',
  /** ARE_CLIENTS_FATF ... */
  ARE_CLIENTS_FATF = 'ARE_CLIENTS_FATF',
  /** ARTICLES_OF_ASSOCIATION ... */
  ARTICLES_OF_ASSOCIATION = 'ARTICLES_OF_ASSOCIATION',
  /** ASSET_CLASSES_EXPERIENCE ... */
  ASSET_CLASSES_EXPERIENCE = 'ASSET_CLASSES_EXPERIENCE',
  /** ASSET_CLASSES_EXPERIENCE_COMMODITIES ... */
  ASSET_CLASSES_EXPERIENCE_COMMODITIES = 'ASSET_CLASSES_EXPERIENCE_COMMODITIES',
  /** ASSET_CLASSES_EXPERIENCE_DIGITAL_ASSETS ... */
  ASSET_CLASSES_EXPERIENCE_DIGITAL_ASSETS = 'ASSET_CLASSES_EXPERIENCE_DIGITAL_ASSETS',
  /** ASSET_CLASSES_EXPERIENCE_DIGITAL_ASSETS_MINING */
  ASSET_CLASSES_EXPERIENCE_DIGITAL_ASSETS_MINING = 'ASSET_CLASSES_EXPERIENCE_DIGITAL_ASSETS_MINING',
  /** ASSET_CLASSES_EXPERIENCE_EQUITIES ... */
  ASSET_CLASSES_EXPERIENCE_EQUITIES = 'ASSET_CLASSES_EXPERIENCE_EQUITIES',
  /** ASSET_CLASSES_EXPERIENCE_FIXED_INCOME ... */
  ASSET_CLASSES_EXPERIENCE_FIXED_INCOME = 'ASSET_CLASSES_EXPERIENCE_FIXED_INCOME',
  /** ASSET_DEPOSIT_AMOUNT_PER_MONTH ... */
  ASSET_DEPOSIT_AMOUNT_PER_MONTH = 'ASSET_DEPOSIT_AMOUNT_PER_MONTH',
  /** ASSET_DEPOSIT_FREQUENCY_PER_MONTH ... */
  ASSET_DEPOSIT_FREQUENCY_PER_MONTH = 'ASSET_DEPOSIT_FREQUENCY_PER_MONTH',
  /** ASSET_WITHDRAWAL_AMOUNT_PER_MONTH ... */
  ASSET_WITHDRAWAL_AMOUNT_PER_MONTH = 'ASSET_WITHDRAWAL_AMOUNT_PER_MONTH',
  /** ASSET_WITHDRAWAL_FREQUENCY_PER_MONTH ... */
  ASSET_WITHDRAWAL_FREQUENCY_PER_MONTH = 'ASSET_WITHDRAWAL_FREQUENCY_PER_MONTH',
  /** ASSUMED_NAME_CERT ... */
  ASSUMED_NAME_CERT = 'ASSUMED_NAME_CERT',
  /** AUTHORIZED_MSB_AGENTS ... */
  AUTHORIZED_MSB_AGENTS = 'AUTHORIZED_MSB_AGENTS',
  /** AUTHORIZED_PERSONS_GROUP ... */
  AUTHORIZED_PERSONS_GROUP = 'AUTHORIZED_PERSONS_GROUP',
  /** AUTHORIZED_SIGNATORIES_DOCUMENT ... */
  AUTHORIZED_SIGNATORIES_DOCUMENT = 'AUTHORIZED_SIGNATORIES_DOCUMENT',
  /** AUTHORIZED_SIGNATORY_GROUP ... */
  AUTHORIZED_SIGNATORY_GROUP = 'AUTHORIZED_SIGNATORY_GROUP',
  /** AVG_DIGITAL_ASSET_DEPOSIT_VALUE_RANGE ... */
  AVG_DIGITAL_ASSET_DEPOSIT_VALUE_RANGE = 'AVG_DIGITAL_ASSET_DEPOSIT_VALUE_RANGE',
  /** AVG_DIGITAL_ASSET_DEPOSIT_VOLUME_RANGE ... */
  AVG_DIGITAL_ASSET_DEPOSIT_VOLUME_RANGE = 'AVG_DIGITAL_ASSET_DEPOSIT_VOLUME_RANGE',
  /** AVG_DIGITAL_ASSET_WITHDRAWAL_VALUE_RANGE ... */
  AVG_DIGITAL_ASSET_WITHDRAWAL_VALUE_RANGE = 'AVG_DIGITAL_ASSET_WITHDRAWAL_VALUE_RANGE',
  /** AVG_DIGITAL_ASSET_WITHDRAWAL_VOLUME_RANGE ... */
  AVG_DIGITAL_ASSET_WITHDRAWAL_VOLUME_RANGE = 'AVG_DIGITAL_ASSET_WITHDRAWAL_VOLUME_RANGE',
  /** AVG_FIAT_DEPOSIT_VALUE_RANGE ... */
  AVG_FIAT_DEPOSIT_VALUE_RANGE = 'AVG_FIAT_DEPOSIT_VALUE_RANGE',
  /** AVG_FIAT_DEPOSIT_VOLUME_RANGE ... */
  AVG_FIAT_DEPOSIT_VOLUME_RANGE = 'AVG_FIAT_DEPOSIT_VOLUME_RANGE',
  /** AVG_FIAT_WITHDRAWAL_VALUE_RANGE ... */
  AVG_FIAT_WITHDRAWAL_VALUE_RANGE = 'AVG_FIAT_WITHDRAWAL_VALUE_RANGE',
  /** AVG_FIAT_WITHDRAWAL_VOLUME_RANGE ... */
  AVG_FIAT_WITHDRAWAL_VOLUME_RANGE = 'AVG_FIAT_WITHDRAWAL_VOLUME_RANGE',
  /** AVG_TOTAL_TRANSACTION_VOLUME_RANGE ... */
  AVG_TOTAL_TRANSACTION_VOLUME_RANGE = 'AVG_TOTAL_TRANSACTION_VOLUME_RANGE',
  /** BANKING_RELATIONSHIP ... */
  BANKING_RELATIONSHIP = 'BANKING_RELATIONSHIP',
  /** BANKING_RELATIONSHIP_EXPLANATION ... */
  BANKING_RELATIONSHIP_EXPLANATION = 'BANKING_RELATIONSHIP_EXPLANATION',
  /** BENEFICIAL_AND_CONTROL_EXEMPT */
  BENEFICIAL_AND_CONTROL_EXEMPT = 'BENEFICIAL_AND_CONTROL_EXEMPT',
  /** BENEFICIAL_AND_CONTROL_EXEMPT_REASON */
  BENEFICIAL_AND_CONTROL_EXEMPT_REASON = 'BENEFICIAL_AND_CONTROL_EXEMPT_REASON',
  /** BENEFICIAL_OWNERS_REQUIRED */
  BENEFICIAL_OWNERS_REQUIRED = 'BENEFICIAL_OWNERS_REQUIRED',
  /** BENEFICIAL_OWNER_GROUP ... */
  BENEFICIAL_OWNER_GROUP = 'BENEFICIAL_OWNER_GROUP',
  /** BENEFICIARIES_GROUP ... */
  BENEFICIARIES_GROUP = 'BENEFICIARIES_GROUP',
  /** BLOCKCHAIN_TRANSACTION_MONITORING_VENDOR ... */
  BLOCKCHAIN_TRANSACTION_MONITORING_VENDOR = 'BLOCKCHAIN_TRANSACTION_MONITORING_VENDOR',
  /** BOARD_RESOLUTION ... */
  BOARD_RESOLUTION = 'BOARD_RESOLUTION',
  /** BO_AUTHORIZED_PERSON_NAME ... */
  BO_AUTHORIZED_PERSON_NAME = 'BO_AUTHORIZED_PERSON_NAME',
  /** BO_DATE ... */
  BO_DATE = 'BO_DATE',
  /** BO_SIGNATURE ... */
  BO_SIGNATURE = 'BO_SIGNATURE',
  /** BO_TITLE ... */
  BO_TITLE = 'BO_TITLE',
  /** BUSINESS_DESCRIPTION ... */
  BUSINESS_DESCRIPTION = 'BUSINESS_DESCRIPTION',
  /** BUSINESS_ENGAGEMENT_DOCUMENT */
  BUSINESS_ENGAGEMENT_DOCUMENT = 'BUSINESS_ENGAGEMENT_DOCUMENT',
  /** BUSINESS_FUND_PAYMENTS ... */
  BUSINESS_FUND_PAYMENTS = 'BUSINESS_FUND_PAYMENTS',
  /** BUSINESS_REGISTRATION_CERTIFICATE ... */
  BUSINESS_REGISTRATION_CERTIFICATE = 'BUSINESS_REGISTRATION_CERTIFICATE',
  /** BUSINESS_REGISTRATION_NUMBER ... */
  BUSINESS_REGISTRATION_NUMBER = 'BUSINESS_REGISTRATION_NUMBER',
  /** BUSINESS_RESTRICT_WITHDRAWALS ... */
  BUSINESS_RESTRICT_WITHDRAWALS = 'BUSINESS_RESTRICT_WITHDRAWALS',
  /** CEA_SECTION_18 ... */
  CEA_SECTION_18 = 'CEA_SECTION_18',
  /** CERTIFICATE_OF_GOOD_STANDING */
  CERTIFICATE_OF_GOOD_STANDING = 'CERTIFICATE_OF_GOOD_STANDING',
  /** CERTIFICATE_OF_INCORPORATION ... */
  CERTIFICATE_OF_INCORPORATION = 'CERTIFICATE_OF_INCORPORATION',
  /** CERTIFICATE_OF_INCUMBENCY ... */
  CERTIFICATE_OF_INCUMBENCY = 'CERTIFICATE_OF_INCUMBENCY',
  /** CERTIFICATE_OF_REGISTRATION ... */
  CERTIFICATE_OF_REGISTRATION = 'CERTIFICATE_OF_REGISTRATION',
  /** CERTIFY_OF_INFORMATION_PROVIDED ... */
  CERTIFY_OF_INFORMATION_PROVIDED = 'CERTIFY_OF_INFORMATION_PROVIDED',
  /** CIP_VERIFICATION ... */
  CIP_VERIFICATION = 'CIP_VERIFICATION',
  /** CITY */
  CITY = 'CITY',
  /** CLIENT_BASE ... */
  CLIENT_BASE = 'CLIENT_BASE',
  /** CLIENT_BASE_ACCREDITED_INVESTORS ... */
  CLIENT_BASE_ACCREDITED_INVESTORS = 'CLIENT_BASE_ACCREDITED_INVESTORS',
  /** CLIENT_BASE_CRYPTO ... */
  CLIENT_BASE_CRYPTO = 'CLIENT_BASE_CRYPTO',
  /** CLIENT_BASE_FAMILY ... */
  CLIENT_BASE_FAMILY = 'CLIENT_BASE_FAMILY',
  /** CLIENT_BASE_HEDGE_FUND ... */
  CLIENT_BASE_HEDGE_FUND = 'CLIENT_BASE_HEDGE_FUND',
  /** CLIENT_BASE_INSTITUTIONAL_TRUST ... */
  CLIENT_BASE_INSTITUTIONAL_TRUST = 'CLIENT_BASE_INSTITUTIONAL_TRUST',
  /** CLIENT_BASE_NONE ... */
  CLIENT_BASE_NONE = 'CLIENT_BASE_NONE',
  /** CLIENT_BASE_PRIVATE_CAPITAL ... */
  CLIENT_BASE_PRIVATE_CAPITAL = 'CLIENT_BASE_PRIVATE_CAPITAL',
  /** CLIENT_BASE_VENTURE_CAPITAL ... */
  CLIENT_BASE_VENTURE_CAPITAL = 'CLIENT_BASE_VENTURE_CAPITAL',
  /** COMPANY_NAME ... */
  COMPANY_NAME = 'COMPANY_NAME',
  /** CONFIDENTIALITY_OF_OWNERSHIP_EXPLANATION ... */
  CONFIDENTIALITY_OF_OWNERSHIP_EXPLANATION = 'CONFIDENTIALITY_OF_OWNERSHIP_EXPLANATION',
  /** CONSTITUTION_BY_LAWS ... */
  CONSTITUTION_BY_LAWS = 'CONSTITUTION_BY_LAWS',
  /** CONSTITUTION_DOCUMENT ... */
  CONSTITUTION_DOCUMENT = 'CONSTITUTION_DOCUMENT',
  /** CONTROL_PERSON_CIP_VERIFIED ... */
  CONTROL_PERSON_CIP_VERIFIED = 'CONTROL_PERSON_CIP_VERIFIED',
  /** CONTROL_PERSON_FIRST_NAME ... */
  CONTROL_PERSON_FIRST_NAME = 'CONTROL_PERSON_FIRST_NAME',
  /** CONTROL_PERSON_GROUP ... */
  CONTROL_PERSON_GROUP = 'CONTROL_PERSON_GROUP',
  /** CONTROL_PERSON_LAST_NAME ... */
  CONTROL_PERSON_LAST_NAME = 'CONTROL_PERSON_LAST_NAME',
  /** CONTROL_PERSON_MIDDLE_NAME ... */
  CONTROL_PERSON_MIDDLE_NAME = 'CONTROL_PERSON_MIDDLE_NAME',
  /** CONTROL_STRUCTURE ... */
  CONTROL_STRUCTURE = 'CONTROL_STRUCTURE',
  /** COUNTRIES_TO_DO_BUSINESS_IN */
  COUNTRIES_TO_DO_BUSINESS_IN = 'COUNTRIES_TO_DO_BUSINESS_IN',
  /** COUNTRIES_TO_TRANSFER_ASSETS */
  COUNTRIES_TO_TRANSFER_ASSETS = 'COUNTRIES_TO_TRANSFER_ASSETS',
  /** COUNTRY */
  COUNTRY = 'COUNTRY',
  /** COUNTRY_OF_OPERATION ... */
  COUNTRY_OF_OPERATION = 'COUNTRY_OF_OPERATION',
  /** COUNTRY_OF_OPERATION_GROUP ... */
  COUNTRY_OF_OPERATION_GROUP = 'COUNTRY_OF_OPERATION_GROUP',
  /** CURRENCIES_LIST ... */
  CURRENCIES_LIST = 'CURRENCIES_LIST',
  /** DATE_OF_BIRTH ... */
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  /** DBA_NAME ... */
  DBA_NAME = 'DBA_NAME',
  /** Optional DBA or Trade names */
  DBA_NAMES = 'DBA_NAMES',
  /** DECLARATION_OF_TRUST ... */
  DECLARATION_OF_TRUST = 'DECLARATION_OF_TRUST',
  /** DELIVERY_PREFERENCE ... */
  DELIVERY_PREFERENCE = 'DELIVERY_PREFERENCE',
  /** DELIVERY_PREFERENCE_CONTRACT_NOTIFICATIONS_GROUP ... */
  DELIVERY_PREFERENCE_CONTRACT_NOTIFICATIONS_GROUP = 'DELIVERY_PREFERENCE_CONTRACT_NOTIFICATIONS_GROUP',
  /** DELIVERY_PREFERENCE_DEFAULT_GROUP ... */
  DELIVERY_PREFERENCE_DEFAULT_GROUP = 'DELIVERY_PREFERENCE_DEFAULT_GROUP',
  /** DELIVERY_PREFERENCE_DEPOSIT_ATTRIBUTION_GROUP ... */
  DELIVERY_PREFERENCE_DEPOSIT_ATTRIBUTION_GROUP = 'DELIVERY_PREFERENCE_DEPOSIT_ATTRIBUTION_GROUP',
  /** DELIVERY_PREFERENCE_INVOICES_GROUP ... */
  DELIVERY_PREFERENCE_INVOICES_GROUP = 'DELIVERY_PREFERENCE_INVOICES_GROUP',
  /** DELIVERY_PREFERENCE_KYC_INQUIRY_GROUP ... */
  DELIVERY_PREFERENCE_KYC_INQUIRY_GROUP = 'DELIVERY_PREFERENCE_KYC_INQUIRY_GROUP',
  /** DELIVERY_PREFERENCE_OTHER_GROUP ... */
  DELIVERY_PREFERENCE_OTHER_GROUP = 'DELIVERY_PREFERENCE_OTHER_GROUP',
  /** DELIVERY_PREFERENCE_PLATFORM_UPDATES_GROUP ... */
  DELIVERY_PREFERENCE_PLATFORM_UPDATES_GROUP = 'DELIVERY_PREFERENCE_PLATFORM_UPDATES_GROUP',
  /** DELIVERY_PREFERENCE_SETTLEMENT_NETWORK_GROUP ... */
  DELIVERY_PREFERENCE_SETTLEMENT_NETWORK_GROUP = 'DELIVERY_PREFERENCE_SETTLEMENT_NETWORK_GROUP',
  /** DELIVERY_PREFERENCE_TAX_DOCUMENTS_GROUP ... */
  DELIVERY_PREFERENCE_TAX_DOCUMENTS_GROUP = 'DELIVERY_PREFERENCE_TAX_DOCUMENTS_GROUP',
  /** DELIVERY_PREFERENCE_TRADE_CONFIRMATIONS_GROUP ... */
  DELIVERY_PREFERENCE_TRADE_CONFIRMATIONS_GROUP = 'DELIVERY_PREFERENCE_TRADE_CONFIRMATIONS_GROUP',
  /** DEPOSIT_FUNDING_WALLET_ADDRESSES ... */
  DEPOSIT_FUNDING_WALLET_ADDRESSES = 'DEPOSIT_FUNDING_WALLET_ADDRESSES',
  /** DEPOSIT_FUNDING_WALLET_DO_OWN_WALLET ... */
  DEPOSIT_FUNDING_WALLET_DO_OWN_WALLET = 'DEPOSIT_FUNDING_WALLET_DO_OWN_WALLET',
  /** DEPOSIT_FUNDING_WALLET_GROUP ... */
  DEPOSIT_FUNDING_WALLET_GROUP = 'DEPOSIT_FUNDING_WALLET_GROUP',
  /** DEPOSIT_FUNDING_WALLET_ORIGINATOR_COUNTRY ... */
  DEPOSIT_FUNDING_WALLET_ORIGINATOR_COUNTRY = 'DEPOSIT_FUNDING_WALLET_ORIGINATOR_COUNTRY',
  /** DEPOSIT_FUNDING_WALLET_ORIGINATOR_NAME ... */
  DEPOSIT_FUNDING_WALLET_ORIGINATOR_NAME = 'DEPOSIT_FUNDING_WALLET_ORIGINATOR_NAME',
  /** DEPOSIT_FUNDING_WIRE_INSTITUTION ... */
  DEPOSIT_FUNDING_WIRE_INSTITUTION = 'DEPOSIT_FUNDING_WIRE_INSTITUTION',
  /** DIRECTORS */
  DIRECTORS = 'DIRECTORS',
  /** DOCUMENT_LINK ... */
  DOCUMENT_LINK = 'DOCUMENT_LINK',
  /** DOCUMENT_MONEY_TRANSMITTER_LICENSE ... */
  DOCUMENT_MONEY_TRANSMITTER_LICENSE = 'DOCUMENT_MONEY_TRANSMITTER_LICENSE',
  /** DOCUMENT_PREFERENCE ... */
  DOCUMENT_PREFERENCE = 'DOCUMENT_PREFERENCE',
  /** DOCUMENT_PREFERENCE_ADDRESS_DEFAULT_EMAIL ... */
  DOCUMENT_PREFERENCE_ADDRESS_DEFAULT_EMAIL = 'DOCUMENT_PREFERENCE_ADDRESS_DEFAULT_EMAIL',
  /** DOCUMENT_PREFERENCE_ADDRESS_INVOICE ... */
  DOCUMENT_PREFERENCE_ADDRESS_INVOICE = 'DOCUMENT_PREFERENCE_ADDRESS_INVOICE',
  /** DOCUMENT_PREFERENCE_ADDRESS_INVOICE_NAME ... */
  DOCUMENT_PREFERENCE_ADDRESS_INVOICE_NAME = 'DOCUMENT_PREFERENCE_ADDRESS_INVOICE_NAME',
  /** DOCUMENT_PREFERENCE_ADDRESS_KYC_INQUIRY ... */
  DOCUMENT_PREFERENCE_ADDRESS_KYC_INQUIRY = 'DOCUMENT_PREFERENCE_ADDRESS_KYC_INQUIRY',
  /** DOCUMENT_PREFERENCE_ADDRESS_KYC_INQUIRY_NAME ... */
  DOCUMENT_PREFERENCE_ADDRESS_KYC_INQUIRY_NAME = 'DOCUMENT_PREFERENCE_ADDRESS_KYC_INQUIRY_NAME',
  /** DOCUMENT_PREFERENCE_ADDRESS_TAX_DOCUMENTS ... */
  DOCUMENT_PREFERENCE_ADDRESS_TAX_DOCUMENTS = 'DOCUMENT_PREFERENCE_ADDRESS_TAX_DOCUMENTS',
  /** DOCUMENT_PREFERENCE_ADDRESS_TAX_DOCUMENTS_NAME ... */
  DOCUMENT_PREFERENCE_ADDRESS_TAX_DOCUMENTS_NAME = 'DOCUMENT_PREFERENCE_ADDRESS_TAX_DOCUMENTS_NAME',
  /** DOCUMENT_PREFERENCE_ADDRESS_TRADE_CONFIRMATIONS ... */
  DOCUMENT_PREFERENCE_ADDRESS_TRADE_CONFIRMATIONS = 'DOCUMENT_PREFERENCE_ADDRESS_TRADE_CONFIRMATIONS',
  /** DOCUMENT_PREFERENCE_DEPOSIT_ATTRIBUTION ... */
  DOCUMENT_PREFERENCE_DEPOSIT_ATTRIBUTION = 'DOCUMENT_PREFERENCE_DEPOSIT_ATTRIBUTION',
  /** DOCUMENT_PREFERENCE_DEPOSIT_ATTRIBUTION_NAME ... */
  DOCUMENT_PREFERENCE_DEPOSIT_ATTRIBUTION_NAME = 'DOCUMENT_PREFERENCE_DEPOSIT_ATTRIBUTION_NAME',
  /** DOCUMENT_PREFERENCE_PLATFORM_SERVICES_UPDATE ... */
  DOCUMENT_PREFERENCE_PLATFORM_SERVICES_UPDATE = 'DOCUMENT_PREFERENCE_PLATFORM_SERVICES_UPDATE',
  /** DOCUMENT_PREFERENCE_PLATFORM_SERVICES_UPDATE_NAME ... */
  DOCUMENT_PREFERENCE_PLATFORM_SERVICES_UPDATE_NAME = 'DOCUMENT_PREFERENCE_PLATFORM_SERVICES_UPDATE_NAME',
  /** DOCUMENT_TITLE ... */
  DOCUMENT_TITLE = 'DOCUMENT_TITLE',
  /** DOCUMENT_TYPE ... */
  DOCUMENT_TYPE = 'DOCUMENT_TYPE',
  /** DOC_PREF_ADDRESS_CONTRACTUAL_NOTIFICATIONS ... */
  DOC_PREF_ADDRESS_CONTRACTUAL_NOTIFICATIONS = 'DOC_PREF_ADDRESS_CONTRACTUAL_NOTIFICATIONS',
  /** DOC_PREF_ADDRESS_CONTRACTUAL_NOTIFICATIONS_NAME ... */
  DOC_PREF_ADDRESS_CONTRACTUAL_NOTIFICATIONS_NAME = 'DOC_PREF_ADDRESS_CONTRACTUAL_NOTIFICATIONS_NAME',
  /** DOC_PREF_SETTLEMENT_NETWORK_NOTIFICATIONS ... */
  DOC_PREF_SETTLEMENT_NETWORK_NOTIFICATIONS = 'DOC_PREF_SETTLEMENT_NETWORK_NOTIFICATIONS',
  /** DOC_PREF_SETTLEMENT_NETWORK_NOTIFICATIONS_NAME ... */
  DOC_PREF_SETTLEMENT_NETWORK_NOTIFICATIONS_NAME = 'DOC_PREF_SETTLEMENT_NETWORK_NOTIFICATIONS_NAME',
  /** DONORS_AND_BENEFICIARIES */
  DONORS_AND_BENEFICIARIES = 'DONORS_AND_BENEFICIARIES',
  /** ECP_AUTHORIZED_PERSON_NAME ... */
  ECP_AUTHORIZED_PERSON_NAME = 'ECP_AUTHORIZED_PERSON_NAME',
  /** ECP_CUSTOMER_TYPE ... */
  ECP_CUSTOMER_TYPE = 'ECP_CUSTOMER_TYPE',
  /** ECP_DATE ... */
  ECP_DATE = 'ECP_DATE',
  /** ECP_ORGANIZATION_NAME ... */
  ECP_ORGANIZATION_NAME = 'ECP_ORGANIZATION_NAME',
  /** ECP_TITLE ... */
  ECP_TITLE = 'ECP_TITLE',
  /** EMAIL ... */
  EMAIL = 'EMAIL',
  /** EMPLOYER ... */
  EMPLOYER = 'EMPLOYER',
  /** EMPLOYMENT_STATUS ... */
  EMPLOYMENT_STATUS = 'EMPLOYMENT_STATUS',
  /** ENGAGED_ACTIVITIES_OR_INDUSTRIES ... */
  ENGAGED_ACTIVITIES_OR_INDUSTRIES = 'ENGAGED_ACTIVITIES_OR_INDUSTRIES',
  /** ENTITY_INTERMEDIARY_LAYERS ... */
  ENTITY_INTERMEDIARY_LAYERS = 'ENTITY_INTERMEDIARY_LAYERS',
  /** Full legal entity name */
  ENTITY_NAME = 'ENTITY_NAME',
  /** EXCHANGE ... */
  EXCHANGE = 'EXCHANGE',
  /** EXCHANGE_COUNTRY ... */
  EXCHANGE_COUNTRY = 'EXCHANGE_COUNTRY',
  /** FEIN ... */
  FEIN = 'FEIN',
  /** FIAT_DEPOSIT_FREQUENCY_PER_MONTH */
  FIAT_DEPOSIT_FREQUENCY_PER_MONTH = 'FIAT_DEPOSIT_FREQUENCY_PER_MONTH',
  /** FIAT_DEPOSIT_FREQUENCY_PER_YEAR ... */
  FIAT_DEPOSIT_FREQUENCY_PER_YEAR = 'FIAT_DEPOSIT_FREQUENCY_PER_YEAR',
  /** FIAT_WITHDRAWAL_FREQUENCY ... */
  FIAT_WITHDRAWAL_FREQUENCY = 'FIAT_WITHDRAWAL_FREQUENCY',
  /** FIAT_WITHDRAWAL_FREQUENCY_PER_MONTH */
  FIAT_WITHDRAWAL_FREQUENCY_PER_MONTH = 'FIAT_WITHDRAWAL_FREQUENCY_PER_MONTH',
  /** FINANCIALS_DOCUMENT ... */
  FINANCIALS_DOCUMENT = 'FINANCIALS_DOCUMENT',
  /** FINANCIAL_ACTION_TASK_FORCE ... */
  FINANCIAL_ACTION_TASK_FORCE = 'FINANCIAL_ACTION_TASK_FORCE',
  /** FINANCIAL_ACTION_TASK_FORCE_EXPLANATION ... */
  FINANCIAL_ACTION_TASK_FORCE_EXPLANATION = 'FINANCIAL_ACTION_TASK_FORCE_EXPLANATION',
  /** FINANCIAL_ACTION_TASK_FORCE_FIELDS ... */
  FINANCIAL_ACTION_TASK_FORCE_FIELDS = 'FINANCIAL_ACTION_TASK_FORCE_FIELDS',
  /** FINCEN_STATUS ... */
  FINCEN_STATUS = 'FINCEN_STATUS',
  /** FIRST_NAME ... */
  FIRST_NAME = 'FIRST_NAME',
  /** FOREIGN_BANK_CERTIFICATION */
  FOREIGN_BANK_CERTIFICATION = 'FOREIGN_BANK_CERTIFICATION',
  /** FOREIGN_BANK_CERTIFICATION_ACKNOWLEDGEMENT */
  FOREIGN_BANK_CERTIFICATION_ACKNOWLEDGEMENT = 'FOREIGN_BANK_CERTIFICATION_ACKNOWLEDGEMENT',
  /** FOREIGN_BANK_CERTIFICATION_AUTHORIZED_PERSON */
  FOREIGN_BANK_CERTIFICATION_AUTHORIZED_PERSON = 'FOREIGN_BANK_CERTIFICATION_AUTHORIZED_PERSON',
  /** FOREIGN_CORRESPONDENT_ACCOUNT ... */
  FOREIGN_CORRESPONDENT_ACCOUNT = 'FOREIGN_CORRESPONDENT_ACCOUNT',
  /** FOREIGN_FINANCIAL_INSTITUTION */
  FOREIGN_FINANCIAL_INSTITUTION = 'FOREIGN_FINANCIAL_INSTITUTION',
  /** FORMATION_DOCUMENT */
  FORMATION_DOCUMENT = 'FORMATION_DOCUMENT',
  /** FORM_5500 ... */
  FORM_5500 = 'FORM_5500',
  /** FULL_NAMES_OF_AUTHORIZED_SIGNATORIES ... */
  FULL_NAMES_OF_AUTHORIZED_SIGNATORIES = 'FULL_NAMES_OF_AUTHORIZED_SIGNATORIES',
  /** FUNDING_DEPOSIT_ADDRESS */
  FUNDING_DEPOSIT_ADDRESS = 'FUNDING_DEPOSIT_ADDRESS',
  /** FUNDING_WIRE_INSTITUTION */
  FUNDING_WIRE_INSTITUTION = 'FUNDING_WIRE_INSTITUTION',
  /** FUND_STRUCTURE_CHART ... */
  FUND_STRUCTURE_CHART = 'FUND_STRUCTURE_CHART',
  /** GRANTORS_GROUP ... */
  GRANTORS_GROUP = 'GRANTORS_GROUP',
  /** GROUP_HEADQUARTERS_COUNTRY ... */
  GROUP_HEADQUARTERS_COUNTRY = 'GROUP_HEADQUARTERS_COUNTRY',
  /** HAS_AGENT ... */
  HAS_AGENT = 'HAS_AGENT',
  /** HAS_BENEFICIAL_OWNERS ... */
  HAS_BENEFICIAL_OWNERS = 'HAS_BENEFICIAL_OWNERS',
  /** HAS_BLOCKCHAIN_TRANSACTION_MONITORING ... */
  HAS_BLOCKCHAIN_TRANSACTION_MONITORING = 'HAS_BLOCKCHAIN_TRANSACTION_MONITORING',
  /** HAS_CONFIDENTIALITY_OF_OWNERSHIP ... */
  HAS_CONFIDENTIALITY_OF_OWNERSHIP = 'HAS_CONFIDENTIALITY_OF_OWNERSHIP',
  /** HAS_DBA_NAME ... */
  HAS_DBA_NAME = 'HAS_DBA_NAME',
  /** HAS_INDIRECT_ENTITIES ... */
  HAS_INDIRECT_ENTITIES = 'HAS_INDIRECT_ENTITIES',
  /** HAS_MULTISTATE_MSB_LICENSE ... */
  HAS_MULTISTATE_MSB_LICENSE = 'HAS_MULTISTATE_MSB_LICENSE',
  /** HAS_NON_CUSTODIAL_WALLETS */
  HAS_NON_CUSTODIAL_WALLETS = 'HAS_NON_CUSTODIAL_WALLETS',
  /** HAS_PENSION_ERISA ... */
  HAS_PENSION_ERISA = 'HAS_PENSION_ERISA',
  /** HAS_PROOF_OF_ADDRESS ... */
  HAS_PROOF_OF_ADDRESS = 'HAS_PROOF_OF_ADDRESS',
  /** HAS_PROOF_OF_ADDRESS_CONTEXT ... */
  HAS_PROOF_OF_ADDRESS_CONTEXT = 'HAS_PROOF_OF_ADDRESS_CONTEXT',
  /** HAS_SEC_CFTC_RIA ... */
  HAS_SEC_CFTC_RIA = 'HAS_SEC_CFTC_RIA',
  /** HAS_TOKEN_ASSOCIATED_PROJ_CENTRALIZED_EXCHANGES ... */
  HAS_TOKEN_ASSOCIATED_PROJ_CENTRALIZED_EXCHANGES = 'HAS_TOKEN_ASSOCIATED_PROJ_CENTRALIZED_EXCHANGES',
  /** HAS_TOKEN_BLOCKCHAIN_ANALYTICS ... */
  HAS_TOKEN_BLOCKCHAIN_ANALYTICS = 'HAS_TOKEN_BLOCKCHAIN_ANALYTICS',
  /** HAS_TOKEN_BLOCKCHAIN_ANONYMITY ... */
  HAS_TOKEN_BLOCKCHAIN_ANONYMITY = 'HAS_TOKEN_BLOCKCHAIN_ANONYMITY',
  /** HAS_TOKEN_BLOCKCHAIN_BLOCK_EXPLORER ... */
  HAS_TOKEN_BLOCKCHAIN_BLOCK_EXPLORER = 'HAS_TOKEN_BLOCKCHAIN_BLOCK_EXPLORER',
  /** HAS_TOKEN_BLOCKCHAIN_INDEPENDENT_AUDIT ... */
  HAS_TOKEN_BLOCKCHAIN_INDEPENDENT_AUDIT = 'HAS_TOKEN_BLOCKCHAIN_INDEPENDENT_AUDIT',
  /** HAS_TOKEN_BLOCKCHAIN_SUFFICIENT_LIQUIDITY ... */
  HAS_TOKEN_BLOCKCHAIN_SUFFICIENT_LIQUIDITY = 'HAS_TOKEN_BLOCKCHAIN_SUFFICIENT_LIQUIDITY',
  /** HAS_TOKEN_BLOCKCHANIN_SIGNIFICANT_MARKET_ACTIVITY ... */
  HAS_TOKEN_BLOCKCHANIN_SIGNIFICANT_MARKET_ACTIVITY = 'HAS_TOKEN_BLOCKCHANIN_SIGNIFICANT_MARKET_ACTIVITY',
  /** HAS_US_TAXPAYER ... */
  HAS_US_TAXPAYER = 'HAS_US_TAXPAYER',
  /** HELD_ASSET_CLASSES ... */
  HELD_ASSET_CLASSES = 'HELD_ASSET_CLASSES',
  /** HELD_ASSET_CLASSES_COMMODITIES ... */
  HELD_ASSET_CLASSES_COMMODITIES = 'HELD_ASSET_CLASSES_COMMODITIES',
  /** HELD_ASSET_CLASSES_DIGITAL_ASSETS ... */
  HELD_ASSET_CLASSES_DIGITAL_ASSETS = 'HELD_ASSET_CLASSES_DIGITAL_ASSETS',
  /** HELD_ASSET_CLASSES_EQUITIES ... */
  HELD_ASSET_CLASSES_EQUITIES = 'HELD_ASSET_CLASSES_EQUITIES',
  /** HELD_ASSET_CLASSES_FIXED_INCOME ... */
  HELD_ASSET_CLASSES_FIXED_INCOME = 'HELD_ASSET_CLASSES_FIXED_INCOME',
  /** HIGH_RISK_COUNTRIES ... */
  HIGH_RISK_COUNTRIES = 'HIGH_RISK_COUNTRIES',
  /** HOW_MAINTAIN_AML ... */
  HOW_MAINTAIN_AML = 'HOW_MAINTAIN_AML',
  /** ID_CARD_BACK ... */
  ID_CARD_BACK = 'ID_CARD_BACK',
  /** ID_CARD_FRONT ... */
  ID_CARD_FRONT = 'ID_CARD_FRONT',
  /** INCORPORATION_COUNTRY ... */
  INCORPORATION_COUNTRY = 'INCORPORATION_COUNTRY',
  /** INCORPORATION_DATE ... */
  INCORPORATION_DATE = 'INCORPORATION_DATE',
  /** INDIRECT_OWNERS ... */
  INDIRECT_OWNERS = 'INDIRECT_OWNERS',
  /** INDIVIDUALS_CERTIFICATION */
  INDIVIDUALS_CERTIFICATION = 'INDIVIDUALS_CERTIFICATION',
  /** INDIVIDUALS_CERTIFICATION_CONFIRMATION */
  INDIVIDUALS_CERTIFICATION_CONFIRMATION = 'INDIVIDUALS_CERTIFICATION_CONFIRMATION',
  /** INDIVIDUALS_NUMBER ... */
  INDIVIDUALS_NUMBER = 'INDIVIDUALS_NUMBER',
  /** INITIAL FUNDING */
  INITIAL_FUNDING = 'INITIAL_FUNDING',
  /** INITIAL_FUNDING_WALLET_ADDRESSES ... */
  INITIAL_FUNDING_WALLET_ADDRESSES = 'INITIAL_FUNDING_WALLET_ADDRESSES',
  /** INITIAL_FUNDING_WALLET_DO_OWN_WALLET ... */
  INITIAL_FUNDING_WALLET_DO_OWN_WALLET = 'INITIAL_FUNDING_WALLET_DO_OWN_WALLET',
  /** INITIAL_FUNDING_WALLET_GROUP ... */
  INITIAL_FUNDING_WALLET_GROUP = 'INITIAL_FUNDING_WALLET_GROUP',
  /** INITIAL_FUNDING_WALLET_ORIGINATOR_COUNTRY ... */
  INITIAL_FUNDING_WALLET_ORIGINATOR_COUNTRY = 'INITIAL_FUNDING_WALLET_ORIGINATOR_COUNTRY',
  /** INITIAL_FUNDING_WALLET_ORIGINATOR_NAME ... */
  INITIAL_FUNDING_WALLET_ORIGINATOR_NAME = 'INITIAL_FUNDING_WALLET_ORIGINATOR_NAME',
  /** INVESTABLE_ASSETS ... */
  INVESTABLE_ASSETS = 'INVESTABLE_ASSETS',
  /** INVESTABLE_ASSETS_AT_ANCHORAGE */
  INVESTABLE_ASSETS_AT_ANCHORAGE = 'INVESTABLE_ASSETS_AT_ANCHORAGE',
  /** INVESTABLE_ASSETS_AT_ANCHORAGE_SGD ... */
  INVESTABLE_ASSETS_AT_ANCHORAGE_SGD = 'INVESTABLE_ASSETS_AT_ANCHORAGE_SGD',
  /** INVESTABLE_ASSETS_AT_ANCHORAGE_USD */
  INVESTABLE_ASSETS_AT_ANCHORAGE_USD = 'INVESTABLE_ASSETS_AT_ANCHORAGE_USD',
  /** INVESTABLE_ASSETS_CRYPTO_PERCENTAGE ... */
  INVESTABLE_ASSETS_CRYPTO_PERCENTAGE = 'INVESTABLE_ASSETS_CRYPTO_PERCENTAGE',
  /** INVESTABLE_ASSETS_CUSTODY ... */
  INVESTABLE_ASSETS_CUSTODY = 'INVESTABLE_ASSETS_CUSTODY',
  /** INVESTMENT_ADVISER_REGISTRATION ... */
  INVESTMENT_ADVISER_REGISTRATION = 'INVESTMENT_ADVISER_REGISTRATION',
  /** INVESTMENT_ADVISER_REGISTRATION_IARD ... */
  INVESTMENT_ADVISER_REGISTRATION_IARD = 'INVESTMENT_ADVISER_REGISTRATION_IARD',
  /** INVESTMENT_ADVISER_REGISTRATION_PRIMARY_STATE ... */
  INVESTMENT_ADVISER_REGISTRATION_PRIMARY_STATE = 'INVESTMENT_ADVISER_REGISTRATION_PRIMARY_STATE',
  /** INVESTMENT_ADVISOR_REGISTRATION ... */
  INVESTMENT_ADVISOR_REGISTRATION = 'INVESTMENT_ADVISOR_REGISTRATION',
  /** INVESTMENT_MANAGER_NAME ... */
  INVESTMENT_MANAGER_NAME = 'INVESTMENT_MANAGER_NAME',
  /** IS_BEARER_OR_REGISTERED_SHARES ... */
  IS_BEARER_OR_REGISTERED_SHARES = 'IS_BEARER_OR_REGISTERED_SHARES',
  /** IS_CHARITABLE_ORGANIZATION */
  IS_CHARITABLE_ORGANIZATION = 'IS_CHARITABLE_ORGANIZATION',
  /** IS_CUSTOMER_PRINCIPLE_OR_AGENT ... */
  IS_CUSTOMER_PRINCIPLE_OR_AGENT = 'IS_CUSTOMER_PRINCIPLE_OR_AGENT',
  /** IS_ECP ... */
  IS_ECP = 'IS_ECP',
  /** IS_PUBLIC_COMPANY ... */
  IS_PUBLIC_COMPANY = 'IS_PUBLIC_COMPANY',
  /** IS_REGISTERED_AS_MSB */
  IS_REGISTERED_AS_MSB = 'IS_REGISTERED_AS_MSB',
  /** IS_SUBSIDIARY_PUBLICLY_COMPANY ... */
  IS_SUBSIDIARY_PUBLICLY_COMPANY = 'IS_SUBSIDIARY_PUBLICLY_COMPANY',
  /** IS_TOKEN_BLOCKCHAIN_IN_PRODUCTION ... */
  IS_TOKEN_BLOCKCHAIN_IN_PRODUCTION = 'IS_TOKEN_BLOCKCHAIN_IN_PRODUCTION',
  /** IS_US_CFTC ... */
  IS_US_CFTC = 'IS_US_CFTC',
  /** JURISDICTIONS_TO_CONDUCT_INTERNATIONAL_WIRE */
  JURISDICTIONS_TO_CONDUCT_INTERNATIONAL_WIRE = 'JURISDICTIONS_TO_CONDUCT_INTERNATIONAL_WIRE',
  /** KYC_AFFILIATE_FORM_SUBMISSION_ID ... */
  KYC_AFFILIATE_FORM_SUBMISSION_ID = 'KYC_AFFILIATE_FORM_SUBMISSION_ID',
  /** LAST_NAME ... */
  LAST_NAME = 'LAST_NAME',
  /** LAW_ABIDING_CONFIRMATION ... */
  LAW_ABIDING_CONFIRMATION = 'LAW_ABIDING_CONFIRMATION',
  /** LEGAL_CITY */
  LEGAL_CITY = 'LEGAL_CITY',
  /** LEGAL_COUNTRY */
  LEGAL_COUNTRY = 'LEGAL_COUNTRY',
  /** LEGAL_REGIST_ADDR_DIF_PRINC_PLACE ... */
  LEGAL_REGIST_ADDR_DIF_PRINC_PLACE = 'LEGAL_REGIST_ADDR_DIF_PRINC_PLACE',
  /** LEGAL_STATE */
  LEGAL_STATE = 'LEGAL_STATE',
  /** LEGAL_STREET_ADDRESS */
  LEGAL_STREET_ADDRESS = 'LEGAL_STREET_ADDRESS',
  /** LEGAL_STREET_ADDRESS_SECOND ... */
  LEGAL_STREET_ADDRESS_SECOND = 'LEGAL_STREET_ADDRESS_SECOND',
  /** LEGAL_ZIP_CODE */
  LEGAL_ZIP_CODE = 'LEGAL_ZIP_CODE',
  /** LEGITIMACY_OF_ENTITY_ASSOCIATED ... */
  LEGITIMACY_OF_ENTITY_ASSOCIATED = 'LEGITIMACY_OF_ENTITY_ASSOCIATED',
  /** LICENSED_MSB_STATES ... */
  LICENSED_MSB_STATES = 'LICENSED_MSB_STATES',
  /** LICENSE_DATE_OF_REGISTRATION ... */
  LICENSE_DATE_OF_REGISTRATION = 'LICENSE_DATE_OF_REGISTRATION',
  /** LICENSE_DOCUMENT ... */
  LICENSE_DOCUMENT = 'LICENSE_DOCUMENT',
  /** LICENSE_EVIDENCE_REGISTRATION ... */
  LICENSE_EVIDENCE_REGISTRATION = 'LICENSE_EVIDENCE_REGISTRATION',
  /** LICENSE_REGISTRATION_NO ... */
  LICENSE_REGISTRATION_NO = 'LICENSE_REGISTRATION_NO',
  /** LICENSE_REGULATORY_AUTHORITY ... */
  LICENSE_REGULATORY_AUTHORITY = 'LICENSE_REGULATORY_AUTHORITY',
  /** LICENSE_TYPE ... */
  LICENSE_TYPE = 'LICENSE_TYPE',
  /** LICENSE_VALIDITY_FROM ... */
  LICENSE_VALIDITY_FROM = 'LICENSE_VALIDITY_FROM',
  /** LICENSE_VALIDITY_TO ... */
  LICENSE_VALIDITY_TO = 'LICENSE_VALIDITY_TO',
  /** LIST_OF_ATMS */
  LIST_OF_ATMS = 'LIST_OF_ATMS',
  /** MAILING_ADDRESS ... */
  MAILING_ADDRESS = 'MAILING_ADDRESS',
  /** MAILING_ADDRESS2 ... */
  MAILING_ADDRESS2 = 'MAILING_ADDRESS2',
  /** MAILING_CITY */
  MAILING_CITY = 'MAILING_CITY',
  /** MAILING_COUNTRY */
  MAILING_COUNTRY = 'MAILING_COUNTRY',
  /** MAILING_DIF_PRINCIPAL_PLACE ... */
  MAILING_DIF_PRINCIPAL_PLACE = 'MAILING_DIF_PRINCIPAL_PLACE',
  /** MAILING_STATE */
  MAILING_STATE = 'MAILING_STATE',
  /** MAILING_STREET_ADDRESS */
  MAILING_STREET_ADDRESS = 'MAILING_STREET_ADDRESS',
  /** MAILING_STREET_ADDRESS_SECOND ... */
  MAILING_STREET_ADDRESS_SECOND = 'MAILING_STREET_ADDRESS_SECOND',
  /** MAILING_ZIP_CODE */
  MAILING_ZIP_CODE = 'MAILING_ZIP_CODE',
  /** MAINTAIN_AML ... */
  MAINTAIN_AML = 'MAINTAIN_AML',
  /** MAINTAIN_AML_EVIDENCE_AML_ATTESTATION */
  MAINTAIN_AML_EVIDENCE_AML_ATTESTATION = 'MAINTAIN_AML_EVIDENCE_AML_ATTESTATION',
  /** MAINTAIN_AML_EXPLAIN_IF_NO ... */
  MAINTAIN_AML_EXPLAIN_IF_NO = 'MAINTAIN_AML_EXPLAIN_IF_NO',
  /** MANAGER_KYC_JIRA_LINK ... */
  MANAGER_KYC_JIRA_LINK = 'MANAGER_KYC_JIRA_LINK',
  /** MANAGER_LINKAGE_DOCUMENT ... */
  MANAGER_LINKAGE_DOCUMENT = 'MANAGER_LINKAGE_DOCUMENT',
  /** MANAGER_MEMBER ... */
  MANAGER_MEMBER = 'MANAGER_MEMBER',
  /** MATERIAL_NEWS ... */
  MATERIAL_NEWS = 'MATERIAL_NEWS',
  /** MIDDLE_NAME ... */
  MIDDLE_NAME = 'MIDDLE_NAME',
  /** MINING_COUNTRIES */
  MINING_COUNTRIES = 'MINING_COUNTRIES',
  /** MINING_DIGITAL_ASSETS */
  MINING_DIGITAL_ASSETS = 'MINING_DIGITAL_ASSETS',
  /** MINING_DIGITAL_ASSETS_AMOUNT */
  MINING_DIGITAL_ASSETS_AMOUNT = 'MINING_DIGITAL_ASSETS_AMOUNT',
  /** MINING_HASH_POWER */
  MINING_HASH_POWER = 'MINING_HASH_POWER',
  /** MINING_POWER_CONSUMPTION */
  MINING_POWER_CONSUMPTION = 'MINING_POWER_CONSUMPTION',
  /** MINING_STATES */
  MINING_STATES = 'MINING_STATES',
  /** MSB_AML_PROGRAM_DATE ... */
  MSB_AML_PROGRAM_DATE = 'MSB_AML_PROGRAM_DATE',
  /** MSB_LOCATION ... */
  MSB_LOCATION = 'MSB_LOCATION',
  /** MSB_REGISTRATION_AGENCY */
  MSB_REGISTRATION_AGENCY = 'MSB_REGISTRATION_AGENCY',
  /** MSB_REGISTRATION_DATE ... */
  MSB_REGISTRATION_DATE = 'MSB_REGISTRATION_DATE',
  /** MSB_REGISTRATION_NUMBER */
  MSB_REGISTRATION_NUMBER = 'MSB_REGISTRATION_NUMBER',
  /** MSB_TYPES ... */
  MSB_TYPES = 'MSB_TYPES',
  /** NAME ... */
  NAME = 'NAME',
  /** NATURE_OF_BUSINESS */
  NATURE_OF_BUSINESS = 'NATURE_OF_BUSINESS',
  /** NON_CUSTODIAL_WALLET_ADDRESSES */
  NON_CUSTODIAL_WALLET_ADDRESSES = 'NON_CUSTODIAL_WALLET_ADDRESSES',
  /** NON_MATERIAL_NEWS ... */
  NON_MATERIAL_NEWS = 'NON_MATERIAL_NEWS',
  /** NYC_OFFICE */
  NYC_OFFICE = 'NYC_OFFICE',
  /** OCCUPATION ... */
  OCCUPATION = 'OCCUPATION',
  /** OFFERING_DOCUMENT ... */
  OFFERING_DOCUMENT = 'OFFERING_DOCUMENT',
  /** OFFER_ALTERNATIVE_PAYMENT_METHOD ... */
  OFFER_ALTERNATIVE_PAYMENT_METHOD = 'OFFER_ALTERNATIVE_PAYMENT_METHOD',
  /** OFFER_CRYPTO_TRADE_TPF ... */
  OFFER_CRYPTO_TRADE_TPF = 'OFFER_CRYPTO_TRADE_TPF',
  /** ONBOARDING_API_SUBMISSION_ID */
  ONBOARDING_API_SUBMISSION_ID = 'ONBOARDING_API_SUBMISSION_ID',
  /** ONLY_DEALS_WITH_CLIENTS ... */
  ONLY_DEALS_WITH_CLIENTS = 'ONLY_DEALS_WITH_CLIENTS',
  /** ORGANIZATIONAL_OWNERSHIP_CHART */
  ORGANIZATIONAL_OWNERSHIP_CHART = 'ORGANIZATIONAL_OWNERSHIP_CHART',
  /** ORGANIZATIONAL_STRUCTURE_DOCUMENT */
  ORGANIZATIONAL_STRUCTURE_DOCUMENT = 'ORGANIZATIONAL_STRUCTURE_DOCUMENT',
  /** OUTSOURCED_ENTITY_INFORMATION */
  OUTSOURCED_ENTITY_INFORMATION = 'OUTSOURCED_ENTITY_INFORMATION',
  /** OUTSOURCE_AML_REQUIREMENTS */
  OUTSOURCE_AML_REQUIREMENTS = 'OUTSOURCE_AML_REQUIREMENTS',
  /** OWNERSHIP_CHART ... */
  OWNERSHIP_CHART = 'OWNERSHIP_CHART',
  /** OWNERSHIP_INDIVIDUAL_SSN_OR_PASSPORT_NO ... */
  OWNERSHIP_INDIVIDUAL_SSN_OR_PASSPORT_NO = 'OWNERSHIP_INDIVIDUAL_SSN_OR_PASSPORT_NO',
  /** OWNERSHIP_PERCENTAGE ... */
  OWNERSHIP_PERCENTAGE = 'OWNERSHIP_PERCENTAGE',
  /** PARTNERSHIP_AGREEMENT ... */
  PARTNERSHIP_AGREEMENT = 'PARTNERSHIP_AGREEMENT',
  /** PATRIOT_ACT_CERTIFICATION */
  PATRIOT_ACT_CERTIFICATION = 'PATRIOT_ACT_CERTIFICATION',
  /** PENSION_SUBFUNDS ... */
  PENSION_SUBFUNDS = 'PENSION_SUBFUNDS',
  /** PEP ... */
  PEP = 'PEP',
  /** PEP_INDIVIDUALS */
  PEP_INDIVIDUALS = 'PEP_INDIVIDUALS',
  /** PHONE ... */
  PHONE = 'PHONE',
  /** POOLED_VEHICLE ... */
  POOLED_VEHICLE = 'POOLED_VEHICLE',
  /** PRIMARY_ACCOUNT_OBJECTIVE ... */
  PRIMARY_ACCOUNT_OBJECTIVE = 'PRIMARY_ACCOUNT_OBJECTIVE',
  /** PRIMARY_ACCOUNT_OBJECTIVE_DEADLINE ... */
  PRIMARY_ACCOUNT_OBJECTIVE_DEADLINE = 'PRIMARY_ACCOUNT_OBJECTIVE_DEADLINE',
  /** PRINCIPAL_PLACE_OF_BUSINESS ... */
  PRINCIPAL_PLACE_OF_BUSINESS = 'PRINCIPAL_PLACE_OF_BUSINESS',
  /** PROGRAM_CUSTOMER_EMAIL ... */
  PROGRAM_CUSTOMER_EMAIL = 'PROGRAM_CUSTOMER_EMAIL',
  /** PROGRAM_CUSTOMER_NAME ... */
  PROGRAM_CUSTOMER_NAME = 'PROGRAM_CUSTOMER_NAME',
  /** PROGRAM_SIGNER_EMAIL ... */
  PROGRAM_SIGNER_EMAIL = 'PROGRAM_SIGNER_EMAIL',
  /** PROGRAM_SIGNER_FIRST_NAME ... */
  PROGRAM_SIGNER_FIRST_NAME = 'PROGRAM_SIGNER_FIRST_NAME',
  /** PROGRAM_SIGNER_GROUP ... */
  PROGRAM_SIGNER_GROUP = 'PROGRAM_SIGNER_GROUP',
  /** PROGRAM_SIGNER_LAST_NAME ... */
  PROGRAM_SIGNER_LAST_NAME = 'PROGRAM_SIGNER_LAST_NAME',
  /** PROOF_OF_ADDRESS ... */
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  /** PROOF_OF_IDENTITY ... */
  PROOF_OF_IDENTITY = 'PROOF_OF_IDENTITY',
  /** PUBLICLY_TRADED_STOCK_EXCHANGES ... */
  PUBLICLY_TRADED_STOCK_EXCHANGES = 'PUBLICLY_TRADED_STOCK_EXCHANGES',
  /** QC_ADDITIONAL_NOTES ... */
  QC_ADDITIONAL_NOTES = 'QC_ADDITIONAL_NOTES',
  /** QC_FINAL_CHECK ... */
  QC_FINAL_CHECK = 'QC_FINAL_CHECK',
  /** RECIPIENT_EMAIL ... */
  RECIPIENT_EMAIL = 'RECIPIENT_EMAIL',
  /** RECIPIENT_NAME ... */
  RECIPIENT_NAME = 'RECIPIENT_NAME',
  /** REGISTRATION_AGENCY ... */
  REGISTRATION_AGENCY = 'REGISTRATION_AGENCY',
  /** REGULATED_STATUS */
  REGULATED_STATUS = 'REGULATED_STATUS',
  /** RELATED_PARTY ... */
  RELATED_PARTY = 'RELATED_PARTY',
  /** RELATED_PARTY_GROUP ... */
  RELATED_PARTY_GROUP = 'RELATED_PARTY_GROUP',
  /** RELATED_PARTY_NAME ... */
  RELATED_PARTY_NAME = 'RELATED_PARTY_NAME',
  /** RELATED_PARTY_SCREENED ... */
  RELATED_PARTY_SCREENED = 'RELATED_PARTY_SCREENED',
  /** RELATED_PARTY_TYPE ... */
  RELATED_PARTY_TYPE = 'RELATED_PARTY_TYPE',
  /** RESTRICTED_ACTIVITIES */
  RESTRICTED_ACTIVITIES = 'RESTRICTED_ACTIVITIES',
  /** RESTRICTED_ACTIVITIES_ADULT_ENTERTAINMENT */
  RESTRICTED_ACTIVITIES_ADULT_ENTERTAINMENT = 'RESTRICTED_ACTIVITIES_ADULT_ENTERTAINMENT',
  /** RESTRICTED_ACTIVITIES_DRUGS */
  RESTRICTED_ACTIVITIES_DRUGS = 'RESTRICTED_ACTIVITIES_DRUGS',
  /** RESTRICTED_ACTIVITIES_FIREARMS */
  RESTRICTED_ACTIVITIES_FIREARMS = 'RESTRICTED_ACTIVITIES_FIREARMS',
  /** RESTRICTED_ACTIVITIES_GAMBLING */
  RESTRICTED_ACTIVITIES_GAMBLING = 'RESTRICTED_ACTIVITIES_GAMBLING',
  /** RESTRICTED_ACTIVITIES_MARIJUANA */
  RESTRICTED_ACTIVITIES_MARIJUANA = 'RESTRICTED_ACTIVITIES_MARIJUANA',
  /** RESTRICTED_ACTIVITIES_NONE */
  RESTRICTED_ACTIVITIES_NONE = 'RESTRICTED_ACTIVITIES_NONE',
  /** RESTRICTED_ACTIVITIES_TUMBLING */
  RESTRICTED_ACTIVITIES_TUMBLING = 'RESTRICTED_ACTIVITIES_TUMBLING',
  /** REVENUE_PERCENTAGE ... */
  REVENUE_PERCENTAGE = 'REVENUE_PERCENTAGE',
  /** REVENUE_SHARE_DOMESTIC ... */
  REVENUE_SHARE_DOMESTIC = 'REVENUE_SHARE_DOMESTIC',
  /** RIA_IND_AFFILIATE_ONBOARDING_API_SUBMISSION_ID ... */
  RIA_IND_AFFILIATE_ONBOARDING_API_SUBMISSION_ID = 'RIA_IND_AFFILIATE_ONBOARDING_API_SUBMISSION_ID',
  /** RIA_INST_AFFILIATE_ONBOARDING_API_SUBMISSION_ID ... */
  RIA_INST_AFFILIATE_ONBOARDING_API_SUBMISSION_ID = 'RIA_INST_AFFILIATE_ONBOARDING_API_SUBMISSION_ID',
  /** RP_INCORPORATION_DATE ... */
  RP_INCORPORATION_DATE = 'RP_INCORPORATION_DATE',
  /** RP_INCORPORATION_PLACE ... */
  RP_INCORPORATION_PLACE = 'RP_INCORPORATION_PLACE',
  /** RP_INVESTMENT_ADVISOR_NUMBER ... */
  RP_INVESTMENT_ADVISOR_NUMBER = 'RP_INVESTMENT_ADVISOR_NUMBER',
  /** SERVICE_TYPE_TO_TRANSACT_WITH */
  SERVICE_TYPE_TO_TRANSACT_WITH = 'SERVICE_TYPE_TO_TRANSACT_WITH',
  /** SETTLOR ... */
  SETTLOR = 'SETTLOR',
  /** SETTLORS_GROUP ... */
  SETTLORS_GROUP = 'SETTLORS_GROUP',
  /** SFPF_INDIVIDUALS ... */
  SFPF_INDIVIDUALS = 'SFPF_INDIVIDUALS',
  /** SGD_DEPOSIT_AMOUNT ... */
  SGD_DEPOSIT_AMOUNT = 'SGD_DEPOSIT_AMOUNT',
  /** SGD_WITHDRAWAL_AMOUNT ... */
  SGD_WITHDRAWAL_AMOUNT = 'SGD_WITHDRAWAL_AMOUNT',
  /** SHAREHOLDING_STRUCTURE ... */
  SHAREHOLDING_STRUCTURE = 'SHAREHOLDING_STRUCTURE',
  /** SHARES_HELD_PERCENT ... */
  SHARES_HELD_PERCENT = 'SHARES_HELD_PERCENT',
  /** SIGNATURE ... */
  SIGNATURE = 'SIGNATURE',
  /** SIGNED_TERMS_AGREEMENT_DOCUMENT ... */
  SIGNED_TERMS_AGREEMENT_DOCUMENT = 'SIGNED_TERMS_AGREEMENT_DOCUMENT',
  /** SOURCE_OF_FUNDS ... */
  SOURCE_OF_FUNDS = 'SOURCE_OF_FUNDS',
  /** SOURCE_OF_WEALTH */
  SOURCE_OF_WEALTH = 'SOURCE_OF_WEALTH',
  /** SOURCE_OF_WEALTH_DESCRIPTION ... */
  SOURCE_OF_WEALTH_DESCRIPTION = 'SOURCE_OF_WEALTH_DESCRIPTION',
  /** SPLIT_OF_TOTAL_TRANSACTION ... */
  SPLIT_OF_TOTAL_TRANSACTION = 'SPLIT_OF_TOTAL_TRANSACTION',
  /** SPONSOR_CLIENT_TYPE ... */
  SPONSOR_CLIENT_TYPE = 'SPONSOR_CLIENT_TYPE',
  /** State */
  STATE = 'STATE',
  /** STATEMENTS_PREFERENCE ... */
  STATEMENTS_PREFERENCE = 'STATEMENTS_PREFERENCE',
  /** STREET_ADDRESS */
  STREET_ADDRESS = 'STREET_ADDRESS',
  /** STREET_ADDRESS_SECOND ... */
  STREET_ADDRESS_SECOND = 'STREET_ADDRESS_SECOND',
  /** SUSPICIOUS_ACTIVITY ... */
  SUSPICIOUS_ACTIVITY = 'SUSPICIOUS_ACTIVITY',
  /** TAX_EXEMPTION_CERT ... */
  TAX_EXEMPTION_CERT = 'TAX_EXEMPTION_CERT',
  /** TERMS_AGREEMENT_ID ... */
  TERMS_AGREEMENT_ID = 'TERMS_AGREEMENT_ID',
  /** TICKER_SYMBOL ... */
  TICKER_SYMBOL = 'TICKER_SYMBOL',
  /** TITLE ... */
  TITLE = 'TITLE',
  /** TOKEN_TYPE ... */
  TOKEN_TYPE = 'TOKEN_TYPE',
  /** TOP_FIVE_DEPOSIT_WALLET ... */
  TOP_FIVE_DEPOSIT_WALLET = 'TOP_FIVE_DEPOSIT_WALLET',
  /** TOP_FIVE_DEPOSIT_WALLET_ADDRESS ... */
  TOP_FIVE_DEPOSIT_WALLET_ADDRESS = 'TOP_FIVE_DEPOSIT_WALLET_ADDRESS',
  /** TOP_FIVE_DEPOSIT_WALLET_NAME ... */
  TOP_FIVE_DEPOSIT_WALLET_NAME = 'TOP_FIVE_DEPOSIT_WALLET_NAME',
  /** TOP_FIVE_WITHDRAWAL_WALLET ... */
  TOP_FIVE_WITHDRAWAL_WALLET = 'TOP_FIVE_WITHDRAWAL_WALLET',
  /** TOP_FIVE_WITHDRAWAL_WALLET_ADDRESS ... */
  TOP_FIVE_WITHDRAWAL_WALLET_ADDRESS = 'TOP_FIVE_WITHDRAWAL_WALLET_ADDRESS',
  /** TOP_FIVE_WITHDRAWAL_WALLET_NAME ... */
  TOP_FIVE_WITHDRAWAL_WALLET_NAME = 'TOP_FIVE_WITHDRAWAL_WALLET_NAME',
  /** TOTAL_ASSETS_MANAGED */
  TOTAL_ASSETS_MANAGED = 'TOTAL_ASSETS_MANAGED',
  /** TOTAL_TRANSACTION_VALUE ... */
  TOTAL_TRANSACTION_VALUE = 'TOTAL_TRANSACTION_VALUE',
  /** TOTAL_VALUE_ASSETS_UNDER_CUSTODY ... */
  TOTAL_VALUE_ASSETS_UNDER_CUSTODY = 'TOTAL_VALUE_ASSETS_UNDER_CUSTODY',
  /** TRADING_ACTIVITY_JURISDICTION */
  TRADING_ACTIVITY_JURISDICTION = 'TRADING_ACTIVITY_JURISDICTION',
  /** TRADING_FREQUENCY_PER_MONTH */
  TRADING_FREQUENCY_PER_MONTH = 'TRADING_FREQUENCY_PER_MONTH',
  /** TRANSACTIONS_MONTHLY_IN_SGD ... */
  TRANSACTIONS_MONTHLY_IN_SGD = 'TRANSACTIONS_MONTHLY_IN_SGD',
  /** TRANSACTIONS_MONTHLY_IN_USD */
  TRANSACTIONS_MONTHLY_IN_USD = 'TRANSACTIONS_MONTHLY_IN_USD',
  /** TRANSACTION_FREQUENCY */
  TRANSACTION_FREQUENCY = 'TRANSACTION_FREQUENCY',
  /** TRANSACT_AT_FUND_OR_INVESTMENT_MANAGER_LEVEL ... */
  TRANSACT_AT_FUND_OR_INVESTMENT_MANAGER_LEVEL = 'TRANSACT_AT_FUND_OR_INVESTMENT_MANAGER_LEVEL',
  /** TRANSFERING_METHOD ... */
  TRANSFERING_METHOD = 'TRANSFERING_METHOD',
  /** TRANSFERRING_METHOD */
  TRANSFERRING_METHOD = 'TRANSFERRING_METHOD',
  /** TRANSFERRING_METHOD_DIGITAL_ASSETS */
  TRANSFERRING_METHOD_DIGITAL_ASSETS = 'TRANSFERRING_METHOD_DIGITAL_ASSETS',
  /** TRANSFERRING_METHOD_OTHER ... */
  TRANSFERRING_METHOD_OTHER = 'TRANSFERRING_METHOD_OTHER',
  /** TRANSFERRING_METHOD_SEN */
  TRANSFERRING_METHOD_SEN = 'TRANSFERRING_METHOD_SEN',
  /** TRANSFERRING_METHOD_WIRE */
  TRANSFERRING_METHOD_WIRE = 'TRANSFERRING_METHOD_WIRE',
  /** TRUSTEES_GROUP ... */
  TRUSTEES_GROUP = 'TRUSTEES_GROUP',
  /** TRUSTEE_GROUP ... */
  TRUSTEE_GROUP = 'TRUSTEE_GROUP',
  /** TRUST_AGREEMENT ... */
  TRUST_AGREEMENT = 'TRUST_AGREEMENT',
  /** TYPE_CUSTOMERS ... */
  TYPE_CUSTOMERS = 'TYPE_CUSTOMERS',
  /** TYPE_CUSTOMERS_MSB ... */
  TYPE_CUSTOMERS_MSB = 'TYPE_CUSTOMERS_MSB',
  /** TYPE_OF_BUSINESS ... */
  TYPE_OF_BUSINESS = 'TYPE_OF_BUSINESS',
  /** TYPE_OF_BUSINESS_SECOND ... */
  TYPE_OF_BUSINESS_SECOND = 'TYPE_OF_BUSINESS_SECOND',
  /** TYPE_OF_CUSTOMER */
  TYPE_OF_CUSTOMER = 'TYPE_OF_CUSTOMER',
  /** TYPE_OF_CUSTOMER_OTHER */
  TYPE_OF_CUSTOMER_OTHER = 'TYPE_OF_CUSTOMER_OTHER',
  /** TYPE_OF_CUSTOMER_SECOND ... */
  TYPE_OF_CUSTOMER_SECOND = 'TYPE_OF_CUSTOMER_SECOND',
  /** TYPE_OF_PIV ... */
  TYPE_OF_PIV = 'TYPE_OF_PIV',
  /** ULTIMATE_OWNERSHIP_PERSON ... */
  ULTIMATE_OWNERSHIP_PERSON = 'ULTIMATE_OWNERSHIP_PERSON',
  /** USAGE_OF_TRADING_PROCEEDS */
  USAGE_OF_TRADING_PROCEEDS = 'USAGE_OF_TRADING_PROCEEDS',
  /** USD_DEPOSIT_AMOUNT ... */
  USD_DEPOSIT_AMOUNT = 'USD_DEPOSIT_AMOUNT',
  /** USD_DEPOSIT_AMOUNT_PER_MONTH */
  USD_DEPOSIT_AMOUNT_PER_MONTH = 'USD_DEPOSIT_AMOUNT_PER_MONTH',
  /** USD_WITHDRAWAL_AMOUNT ... */
  USD_WITHDRAWAL_AMOUNT = 'USD_WITHDRAWAL_AMOUNT',
  /** USD_WITHDRAWAL_AMOUNT_PER_MONTH */
  USD_WITHDRAWAL_AMOUNT_PER_MONTH = 'USD_WITHDRAWAL_AMOUNT_PER_MONTH',
  /** VENDORS_AND_COUNTERPARTIES_COUNTRY_LIST */
  VENDORS_AND_COUNTERPARTIES_COUNTRY_LIST = 'VENDORS_AND_COUNTERPARTIES_COUNTRY_LIST',
  /** VENDORS_AND_COUNTERPARTIES_LIST */
  VENDORS_AND_COUNTERPARTIES_LIST = 'VENDORS_AND_COUNTERPARTIES_LIST',
  /** VENDOR_AND_COUNTERPARTY_COUNTRY_LIST ... */
  VENDOR_AND_COUNTERPARTY_COUNTRY_LIST = 'VENDOR_AND_COUNTERPARTY_COUNTRY_LIST',
  /** VENDOR_AND_COUNTERPARTY_LIST ... */
  VENDOR_AND_COUNTERPARTY_LIST = 'VENDOR_AND_COUNTERPARTY_LIST',
  /** VERIFICATION_BENEFICIAL_OWNER_COUNT */
  VERIFICATION_BENEFICIAL_OWNER_COUNT = 'VERIFICATION_BENEFICIAL_OWNER_COUNT',
  /** VERIFICATION_CONTROL_PERSON_COUNT */
  VERIFICATION_CONTROL_PERSON_COUNT = 'VERIFICATION_CONTROL_PERSON_COUNT',
  /** VERIFY_BENEFICIAL_OWNERS ... */
  VERIFY_BENEFICIAL_OWNERS = 'VERIFY_BENEFICIAL_OWNERS',
  /** W8_BEN_FORM ... */
  W8_BEN_FORM = 'W8_BEN_FORM',
  /** W8_OR_W9 ... */
  W8_OR_W9 = 'W8_OR_W9',
  /** W9_BEN_FORM ... */
  W9_BEN_FORM = 'W9_BEN_FORM',
  /** W9_TAX_DOCUMENT ... */
  W9_TAX_DOCUMENT = 'W9_TAX_DOCUMENT',
  /** WHITE_PAPER */
  WHITE_PAPER = 'WHITE_PAPER',
  /** WILL_CONDUCT_INTERNATIONAL_WIRE */
  WILL_CONDUCT_INTERNATIONAL_WIRE = 'WILL_CONDUCT_INTERNATIONAL_WIRE',
  /** WOLFSBERG_QUESTIONNAIRE */
  WOLFSBERG_QUESTIONNAIRE = 'WOLFSBERG_QUESTIONNAIRE',
  /** Zip code */
  ZIP_CODE = 'ZIP_CODE'
}

/** AffiliateStatus represents the status of the affiliate */
export enum AffiliateStatus {
  /** CLOSED represents a closed affiliate */
  CLOSED = 'CLOSED',
  /** OPEN represents an open (non-closed) affiliate */
  OPEN = 'OPEN'
}

/** Specifies the type of affiliate */
export enum AffiliateType {
  /** All other affiliate types. */
  ANCHORAGE_INSTITUTION = 'ANCHORAGE_INSTITUTION',
  /** INVESTMENT_MANAGER is the affiliate type fund managers */
  INVESTMENT_MANAGER = 'INVESTMENT_MANAGER',
  /** MANAGED_FUND is the affiliate type for funds managed by an investment fund */
  MANAGED_FUND = 'MANAGED_FUND',
  /** Affiliate that is an RIA parent affiliate. */
  REGISTERED_INVESTMENT_ADVISOR = 'REGISTERED_INVESTMENT_ADVISOR',
  /** Affiliate that is an RIA individual. */
  RIA_PC_INDIVIDUAL = 'RIA_PC_INDIVIDUAL',
  /** Affiliate that is an RIA institution. */
  RIA_PC_INSTITUTION = 'RIA_PC_INSTITUTION'
}

export type AffiliateV2 = Node & {
  __typename: 'AffiliateV2';
  KYCApplications: Maybe<Array<Maybe<InstitutionalAffiliate>>>;
  affiliateAnchorageRelationships: AffiliateAnchorageRelationshipConnection;
  createdAt: Scalars['Time']['output'];
  deletedAt: Maybe<Scalars['Time']['output']>;
  deletedBy: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};


export type AffiliateV2affiliateAnchorageRelationshipsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AffiliateAnchorageRelationshipWhereInput>;
};

/** A connection to a list of items. */
export type AffiliateV2Connection = {
  __typename: 'AffiliateV2Connection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AffiliateV2Edge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AffiliateV2Edge = {
  __typename: 'AffiliateV2Edge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<AffiliateV2>;
};

/** Ordering options for AffiliateV2 connections */
export type AffiliateV2Order = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AffiliateV2s. */
  field: AffiliateV2OrderField;
};

/** Properties by which AffiliateV2 connections can be ordered. */
export enum AffiliateV2OrderField {
  NAME = 'NAME'
}

/**
 * AffiliateV2WhereInput is used for filtering AffiliateV2 objects.
 * Input was generated by ent.
 */
export type AffiliateV2WhereInput = {
  and?: InputMaybe<Array<AffiliateV2WhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** deleted_by field predicates */
  deletedBy?: InputMaybe<Scalars['String']['input']>;
  deletedByContains?: InputMaybe<Scalars['String']['input']>;
  deletedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  deletedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  deletedByGT?: InputMaybe<Scalars['String']['input']>;
  deletedByGTE?: InputMaybe<Scalars['String']['input']>;
  deletedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  deletedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  deletedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedByLT?: InputMaybe<Scalars['String']['input']>;
  deletedByLTE?: InputMaybe<Scalars['String']['input']>;
  deletedByNEQ?: InputMaybe<Scalars['String']['input']>;
  deletedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** affiliate_anchorage_relationships edge predicates */
  hasAffiliateAnchorageRelationships?: InputMaybe<Scalars['Boolean']['input']>;
  hasAffiliateAnchorageRelationshipsWith?: InputMaybe<Array<AffiliateAnchorageRelationshipWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<AffiliateV2WhereInput>;
  or?: InputMaybe<Array<AffiliateV2WhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** AggregatedByCountryTransaction the aggregated total value of transactions be country */
export type AggregatedByCountryTransaction = {
  __typename: 'AggregatedByCountryTransaction';
  /** Code for country */
  countryCode: Scalars['String']['output'];
  /** Numbers of deposits */
  depositsCount: Scalars['Int']['output'];
  /** Value of deposits in USD */
  depositsValueInUsd: Scalars['String']['output'];
  /** Label for country */
  label: Scalars['String']['output'];
  /** Number of withdrawals\transfers */
  withdrawalsCount: Scalars['Int']['output'];
  /** Value of withdrawals\transfers in USD */
  withdrawalsValueInUsd: Scalars['String']['output'];
};

/** AggregatedTransactionVolume the aggregated total value of transactions in a time period */
export type AggregatedTransactionVolume = {
  __typename: 'AggregatedTransactionVolume';
  /** Numbers of deposits */
  depositsCount: Scalars['Int']['output'];
  /** Value of deposits in USD */
  depositsValueInUsd: Scalars['String']['output'];
  /** Label for the aggregated transaction volume */
  label: Scalars['String']['output'];
  /** Order of the aggregated data */
  order: Scalars['Int']['output'];
  /** Number of withdrawals\transfers */
  withdrawalsCount: Scalars['Int']['output'];
  /** Value of withdrawals\transfers in USD */
  withdrawalsValueInUsd: Scalars['String']['output'];
};

/** AggregationFilterType represents the time frame aggregation for a dataset */
export enum AggregationFilterType {
  /** Data will be aggregated per day */
  DAY = 'DAY',
  /** Data will be aggregated per month */
  MONTH = 'MONTH',
  /** Data will be aggregated per week */
  WEEK = 'WEEK'
}

/** Alert represents an altert raised in the context of a compliance investigation */
export type Alert = {
  __typename: 'Alert';
  /** AccountID associated to the alert */
  accountID: Scalars['String']['output'];
  /** Internal ID of the alert */
  alertID: Scalars['String']['output'];
  /** Type of the alert in Jira */
  alertType: Maybe<AlertType>;
  /** Jira Case info (if the alert is associated to one) */
  associatedCase: Maybe<JiraComplianceIssue>;
  /** Type of client associated to the alert */
  clientType: Maybe<Scalars['String']['output']>;
  /** When this alert was created */
  createdAt: Scalars['Date']['output'];
  /** CRR associated with the alert */
  crr: Maybe<CRRScoreOutcome>;
  /** Alert description */
  description: Maybe<Scalars['String']['output']>;
  /** Subject assigned to the alert */
  extAssignee: Maybe<Scalars['String']['output']>;
  /** Subject investigating the alert */
  extInvestigator: Maybe<Scalars['String']['output']>;
  /** ID of the alert in Jira */
  extTrackingID: Maybe<Scalars['String']['output']>;
  /** Link to the alert in Jira */
  extTrackingLink: Maybe<Scalars['String']['output']>;
  /** Total Ammount flagged by the alert in USD */
  flaggedAmount: Amount;
  /** Get the list of operations in this alert with the Connection spec */
  operations: OperationConnection;
  /** Org ID associated to the alert */
  orgKeyID: Scalars['String']['output'];
  /** Specific status of the alert */
  status: Maybe<AlertStatus>;
  /** Alert summary */
  summary: Maybe<Scalars['String']['output']>;
  /** When this alert was last updated */
  updatedAt: Scalars['Date']['output'];
};


/** Alert represents an altert raised in the context of a compliance investigation */
export type AlertoperationsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterActions?: InputMaybe<Array<OperationAction>>;
  filterCreatedSince?: InputMaybe<Scalars['String']['input']>;
  filterCreatedUntil?: InputMaybe<Scalars['String']['input']>;
  filterStates?: InputMaybe<Array<OperationState>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeChange?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortDESC?: InputMaybe<Scalars['Boolean']['input']>;
  sortField?: InputMaybe<OperationSortFields>;
};

/** AlertConnection implements the connections spec */
export type AlertConnection = {
  __typename: 'AlertConnection';
  /** edges implements the connections spec */
  edges: Array<Maybe<AlertEdge>>;
  /** pageInfo implements the connections spec */
  pageInfo: PageInfo;
};

/** AlertEdge implements the connections spec */
export type AlertEdge = {
  __typename: 'AlertEdge';
  /** cursor implements the connections spec */
  cursor: Scalars['String']['output'];
  /** node implements the connections spec */
  node: Alert;
};

/** AlertStatus are the possible states for compliance alerts */
export enum AlertStatus {
  /** CANCELED an alert that has been canceled */
  CANCELED = 'CANCELED',
  /** ESCALATED_TO_CASE an alert that has been escalated to a case */
  ESCALATED_TO_CASE = 'ESCALATED_TO_CASE',
  /** IN_REVIEW the alert is under review */
  IN_REVIEW = 'IN_REVIEW',
  /** MANAGER_REVIEW -the alert is under management review */
  MANAGER_REVIEW = 'MANAGER_REVIEW',
  /** NEW represents a new alert in Jira */
  NEW = 'NEW',
  /** PENDING represents a pending alert in Jira */
  PENDING = 'PENDING',
  /** QC_REVIEW is a alert where QC is reviewing */
  QC_REVIEW = 'QC_REVIEW',
  /** READY_QC is a alert ready for QC */
  READY_QC = 'READY_QC',
  /** RESOLVED an alert that has resolved */
  RESOLVED = 'RESOLVED'
}

/** AlertType are the possible types of jira alerts */
export enum AlertType {
  /** ADHOC_KYC represents an alert manually created via centralized client view */
  ADHOC_KYC = 'ADHOC_KYC',
  /** ATTRIBUTION_MONITORING_MISMATCH represents the attribution monitoring mismatch alert type */
  ATTRIBUTION_MONITORING_MISMATCH = 'ATTRIBUTION_MONITORING_MISMATCH',
  /** DORMANT_ACCOUNT represents the dormant account TM alert type */
  DORMANT_ACCOUNT = 'DORMANT_ACCOUNT',
  /** FIAT_FLOW_THROUGH represents the dormant account TM alert type */
  FIAT_FLOW_THROUGH = 'FIAT_FLOW_THROUGH',
  /** FIAT_HIGH_RISK_JURISDICTION represents the dormant account TM alert type */
  FIAT_HIGH_RISK_JURISDICTION = 'FIAT_HIGH_RISK_JURISDICTION',
  /** FIAT_HIGH_VALUE represents the dormant account TM alert type */
  FIAT_HIGH_VALUE = 'FIAT_HIGH_VALUE',
  /** FIAT_HIGH_VOLUME represents the dormant account TM alert type */
  FIAT_HIGH_VOLUME = 'FIAT_HIGH_VOLUME',
  /** FIAT_REPORTING_EVASION represents the dormant account TM alert type */
  FIAT_REPORTING_EVASION = 'FIAT_REPORTING_EVASION',
  /** FLOW_THROUGH represents the flow through alert type */
  FLOW_THROUGH = 'FLOW_THROUGH',
  /** FULL_KYC_REVIEW represents and alert generated by crr model, period refresh, initial onboarding, ownership change, or manual review */
  FULL_KYC_REVIEW = 'FULL_KYC_REVIEW',
  /** FUND_CLIENTS represents the fund clients TM alert type */
  FUND_CLIENTS = 'FUND_CLIENTS',
  /** HIGH_RISK_JURISDICTION represents the hrj alert type */
  HIGH_RISK_JURISDICTION = 'HIGH_RISK_JURISDICTION',
  /** HIGH_VOLUME_CLIENTS represents the high volume clients TM alert type */
  HIGH_VOLUME_CLIENTS = 'HIGH_VOLUME_CLIENTS',
  /** HISTORICAL_DEVIATIONS represents the historical deviations TM alert type */
  HISTORICAL_DEVIATIONS = 'HISTORICAL_DEVIATIONS',
  /** HUB_SPOKE_MULTI represents the hub and spoke multi alert type */
  HUB_SPOKE_MULTI = 'HUB_SPOKE_MULTI',
  /** HUB_SPOKE_SINGLE represents the hub and spoke single alert type */
  HUB_SPOKE_SINGLE = 'HUB_SPOKE_SINGLE',
  /** KYC_CRR_UPDATE represents an alert manually created via centralized client view */
  KYC_CRR_UPDATE = 'KYC_CRR_UPDATE',
  /** LOW_ACTIVITY_CLIENTS represents the low activity clients TM alert type */
  LOW_ACTIVITY_CLIENTS = 'LOW_ACTIVITY_CLIENTS',
  /** MINING_CLIENTS represents the mining clients TM alert type */
  MINING_CLIENTS = 'MINING_CLIENTS',
  /** NBFI_CLIENTS represents the nbfi clients TM alert type */
  NBFI_CLIENTS = 'NBFI_CLIENTS',
  /** NEGATIVE_NEWS represents the negative news issue type */
  NEGATIVE_NEWS = 'NEGATIVE_NEWS',
  /** NEGATIVE_NEWS_SCREENING represents the negative news screening alert type */
  NEGATIVE_NEWS_SCREENING = 'NEGATIVE_NEWS_SCREENING',
  /** OFAC_REPORTING represents the ofac reporting issue type */
  OFAC_REPORTING = 'OFAC_REPORTING',
  /** OFAC_REPORTING_SCREENING represents the ofac reporting screening alert type */
  OFAC_REPORTING_SCREENING = 'OFAC_REPORTING_SCREENING',
  /** PEP represents the pep issue type */
  PEP = 'PEP',
  /** PEP_SCREENING represents the pep screening alert type */
  PEP_SCREENING = 'PEP_SCREENING',
  /** REFERRAL represents the referral issue type */
  REFERRAL = 'REFERRAL',
  /** REPORTING_EVASION represents the TM reporting evasion alert type */
  REPORTING_EVASION = 'REPORTING_EVASION',
  /** UNHOSTED_WALLET represents the unhosted wallet alert type */
  UNHOSTED_WALLET = 'UNHOSTED_WALLET'
}

/** AlertsAtTime represents the number of alerts at a given time from a alert type */
export type AlertsAtTime = {
  __typename: 'AlertsAtTime';
  /** Type of the alert */
  alertType: Maybe<AlertType>;
  /** Date of the alerts */
  createdAt: Scalars['Date']['output'];
  /** Number of alerts */
  numberOfAlerts: Scalars['Int']['output'];
};

/** Allocated Collateral encapsulates loan collateral */
export type AllocatedCollateral = Node & {
  __typename: 'AllocatedCollateral';
  /** Comment */
  comment: Maybe<Scalars['String']['output']>;
  /** Crypto collateral page */
  cryptoCollateralDetails: Maybe<CryptoCollateralDetailsConnection>;
  /** Equity collateral page */
  equityCollateralDetails: Maybe<EquityCollateralDetailsConnection>;
  /** Identifier */
  id: Scalars['ID']['output'];
  /** Loan object that owns collateral */
  loan: Loan;
  /** Mining collateral page */
  miningCollateralDetails: Maybe<MiningCollateralDetailsConnection>;
  /** Collateral status */
  status: CollateralStatus;
};


/** Allocated Collateral encapsulates loan collateral */
export type AllocatedCollateralcryptoCollateralDetailsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Allocated Collateral encapsulates loan collateral */
export type AllocatedCollateralequityCollateralDetailsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Allocated Collateral encapsulates loan collateral */
export type AllocatedCollateralminingCollateralDetailsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for pagination */
export type AllocatedCollateralConnection = {
  __typename: 'AllocatedCollateralConnection';
  /** Records */
  edges: Array<AllocatedCollateralEdge>;
  /** Pagination info */
  pageInfo: PageInfo;
};

/** Allocated collateral edge for pagination */
export type AllocatedCollateralEdge = {
  __typename: 'AllocatedCollateralEdge';
  /** Record cursor */
  cursor: Scalars['ID']['output'];
  /** Record */
  node: AllocatedCollateral;
};

/**
 * AmendedKYCInformation specifies the new state of KYC information to be submitted/appended for an entity
 * This amended information is seen as LATEST info, always.
 */
export type AmendedKYCInformation = {
  /** ID of the client/affiliate/individual KYC */
  id: Scalars['ID']['input'];
  /** Information type of the KYC information */
  informationType: Scalars['String']['input'];
  /** KYC type of the KYC information */
  kycType: KYCType;
  /** Value of the KYC information */
  value: Scalars['String']['input'];
};

/** Amount represents a value with a unit and asset type */
export type Amount = {
  __typename: 'Amount';
  /** The asset type id for this amount */
  assetTypeID: Scalars['AssetTypeID']['output'];
  /** Further information regarding this asset type */
  assetTypeInfo: AssetTypeInfo;
  /**
   * The value for this amount formatted for display to a user, in the US format.
   * Includes commas, dollar sign for USD, but no unit type for non-USD amounts.
   */
  displayValue: Scalars['String']['output'];
  /**
   * The USD value for this amount formatted for display to a user, including commas
   * and the preceding dollar sign. Ex: $1,234.56
   */
  displayValueInUSD: Scalars['String']['output'];
  /**
   * The value for this amount, rounded to 4 decimal places without trailing zeros,
   * in the US format.
   * Includes commas, dollar sign for USD, but no unit type for non-USD amounts.
   */
  displayValueShort: Scalars['String']['output'];
  /** Information for Token like assets */
  tokenInfo: Maybe<TokenInfo>;
  /** The unit type for this amount */
  unitType: Scalars['String']['output'];
  /** The value for this amount */
  value: Scalars['String']['output'];
  /** The value for this amount with full precision, may exceed asset max precision */
  valueFullPrecision: Scalars['String']['output'];
  /** The value in USD at the current exchange rate */
  valueInUSD: Scalars['String']['output'];
  /** The value for this amount, rounded to 4 decimal places */
  valueShort: Scalars['String']['output'];
};

/** AmountConnection implements the connections spec */
export type AmountConnection = {
  __typename: 'AmountConnection';
  /** edges implements the connections spec */
  edges: Array<Maybe<AmountEdge>>;
  /** pageInfo implements the connections spec */
  pageInfo: PageInfo;
};

/** AmountDuration is a combination of a USD amount with a duration window. */
export type AmountDuration = {
  __typename: 'AmountDuration';
  /** amount is a decimal amount of USD. */
  amount: Scalars['Decimal']['output'];
  /** seconds indicates a length of time window. */
  seconds: Scalars['Int']['output'];
};

/** AmountEdge implements the connections spec */
export type AmountEdge = {
  __typename: 'AmountEdge';
  /** cursor implements the connections spec */
  cursor: Scalars['ID']['output'];
  /** node implements the connections spec */
  node: Amount;
};

/** AmountInput represents a graphql input representation of an value with a unit and asset type */
export type AmountInput = {
  /** The asset type id for this amount */
  assetTypeID: Scalars['AssetTypeID']['input'];
  /** The value for this amount */
  value: Scalars['String']['input'];
};

/**
 * AmountInputStep allows the user to input an arbitrary amount following some type rules.
 * Number types are typically absolute asset amounts, but can also represent percentages or shares.
 */
export type AmountInputStep = StakingStep & {
  __typename: 'AmountInputStep';
  /** DefaultAmount is the initial value this input will have, the input should default to 0 if none is provided here. */
  defaultAmount: Maybe<Amount>;
  /** Editable should disable the input if false. */
  editable: Scalars['Boolean']['output'];
  /** Ignore this field */
  ignoreMe: Maybe<Scalars['Boolean']['output']>;
  /** The InputType represents whether this is quantifying an amount of an asset or a share. */
  inputType: StakingStepInputType;
  /** MaxAmount is the maximum amount the user should be allowed to enter. */
  maxAmount: Maybe<Scalars['String']['output']>;
  /** MinAmount is minimum amount the user should be allowed to enter. */
  minAmount: Maybe<Scalars['String']['output']>;
  /** NumberType represents whether the number is an absolute amount or a percentage. */
  numberType: StakingStepNumberType;
  /** ReserveAmount is the amount that should be left behind for fees and other reasons. */
  reserveAmount: Maybe<Scalars['String']['output']>;
  /** Rules that change presentation of the step */
  rules: Maybe<Array<PresentationRule>>;
};

/** AmountListStep allows the user to see multiple amounts on one screen without being able to edit them. */
export type AmountListStep = StakingStep & {
  __typename: 'AmountListStep';
  /** The list of the amounts to be shown */
  amounts: Array<Amount>;
  /** Ignore this field */
  ignoreMe: Maybe<Scalars['Boolean']['output']>;
  /** Rules that change presentation of the step */
  rules: Maybe<Array<PresentationRule>>;
};

/**
 * This type is used to convey that the actor (i.e., initiator) of an
 * operation/action is an Anchorage employee or process.
 */
export type AnchorageActor = Actor & {
  __typename: 'AnchorageActor';
  /** Information about the actor. */
  actorInfo: ActorInfo;
};

/** Resolver for Anchorage Entities */
export type AnchorageEntity = {
  __typename: 'AnchorageEntity';
  /** Name of the Anchorage Entity */
  name: Scalars['String']['output'];
  /** Shorter name. May be an abbreviation */
  shortname: Scalars['String']['output'];
  /** Type of the Anchorage Entity */
  type: AnchorageEntityType;
};

/** Different types of Anchorage Entities */
export enum AnchorageEntityType {
  /** A1 */
  A1 = 'A1',
  /** AURORA */
  AURORA = 'AURORA',
  /** Anchorage Hold */
  HOLD = 'HOLD',
  /** Singapore */
  SINGAPORE = 'SINGAPORE',
  /** Anchorage Trust Company */
  TRUST_COMPANY = 'TRUST_COMPANY'
}

export type AnchorageLegalEntity = Node & {
  __typename: 'AnchorageLegalEntity';
  affiliateAnchorageRelationships: AffiliateAnchorageRelationshipConnection;
  createdAt: Scalars['Time']['output'];
  deletedAt: Maybe<Scalars['Time']['output']>;
  deletedBy: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};


export type AnchorageLegalEntityaffiliateAnchorageRelationshipsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AffiliateAnchorageRelationshipWhereInput>;
};

/** A connection to a list of items. */
export type AnchorageLegalEntityConnection = {
  __typename: 'AnchorageLegalEntityConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AnchorageLegalEntityEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AnchorageLegalEntityEdge = {
  __typename: 'AnchorageLegalEntityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<AnchorageLegalEntity>;
};

/**
 * AnchorageLegalEntityWhereInput is used for filtering AnchorageLegalEntity objects.
 * Input was generated by ent.
 */
export type AnchorageLegalEntityWhereInput = {
  and?: InputMaybe<Array<AnchorageLegalEntityWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** deleted_by field predicates */
  deletedBy?: InputMaybe<Scalars['String']['input']>;
  deletedByContains?: InputMaybe<Scalars['String']['input']>;
  deletedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  deletedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  deletedByGT?: InputMaybe<Scalars['String']['input']>;
  deletedByGTE?: InputMaybe<Scalars['String']['input']>;
  deletedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  deletedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  deletedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedByLT?: InputMaybe<Scalars['String']['input']>;
  deletedByLTE?: InputMaybe<Scalars['String']['input']>;
  deletedByNEQ?: InputMaybe<Scalars['String']['input']>;
  deletedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** affiliate_anchorage_relationships edge predicates */
  hasAffiliateAnchorageRelationships?: InputMaybe<Scalars['Boolean']['input']>;
  hasAffiliateAnchorageRelationshipsWith?: InputMaybe<Array<AffiliateAnchorageRelationshipWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<AnchorageLegalEntityWhereInput>;
  or?: InputMaybe<Array<AnchorageLegalEntityWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Answer is the type that represents an answer for a question */
export type Answer = {
  __typename: 'Answer';
  /** freeformText is the freeform text entered for the question when the question allows */
  freeformText: Scalars['String']['output'];
  /** options is the list of options selected for the question */
  options: Array<Scalars['String']['output']>;
  /** questionID is the ID of the question */
  questionID: Scalars['String']['output'];
};

/** Represents a user that is in the process of being added to an organization */
export type AppStagedUser = StagedUser & {
  __typename: 'AppStagedUser';
  /**
   * The algorithm for the user's public key. This should match the value in the organization change.
   *
   * For backward compatibility with older iOS releases, nil is returned for the default algorithm (ecdsap256). In this case, an algorithm field is not included in the endorsement.
   */
  algorithm: Maybe<Scalars['String']['output']>;
  /** Email of the staged user, if known */
  email: Maybe<Scalars['String']['output']>;
  /** The only possible value for now is eciesEncryptionCofactorSHA512IVAESGCM or null */
  encryptionAlgorithm: Maybe<Scalars['String']['output']>;
  /** Set iff publicKeyID is set */
  encryptionPublicKey: Maybe<Scalars['String']['output']>;
  /**
   * Same as publicKeyID but used for encryption
   * This is set iff encryptionAlgorithm is set
   */
  encryptionPublicKeyID: Maybe<Scalars['PublicKeyID']['output']>;
  /** Enroll token of the user */
  enrollToken: Scalars['String']['output'];
  /** Name from enrollment table (overrides the piiName) */
  enrollmentName: Maybe<Scalars['String']['output']>;
  /** exemptPiis returns list of PII types that the user is exempt from providing */
  exemptPiis: Array<IndividualInformationType>;
  /**
   * institutionalIndividual returns the institutional individual associated with
   * this staged user
   */
  institutionalIndividual: Maybe<InstitutionalIndividual>;
  /** PII information known */
  knownInformation: Array<KnownInformation>;
  /** Last login information. Will be empty if never logged in. */
  lastLogin: Maybe<Login>;
  /** missingPiis returns list of PII types that the user still needs to fill */
  missingPiis: Array<IndividualInformationType>;
  /** Name of the staged user, if known; comes from either of piiName or enrollmentName */
  name: Maybe<Scalars['String']['output']>;
  /** onboardingStatus signals the state of onboarding that the user is at */
  onboardingStatus: OnboardingStatus;
  /**
   * ID of the personally identifiable information.
   * Needed to generate the address for the blobs.
   */
  piiID: Scalars['String']['output'];
  /** Name from KYC */
  piiName: Maybe<Scalars['String']['output']>;
  /** Hex encoded representation of the public key, if known. If the user did not enroll yet this field will be null */
  publicKey: Maybe<Scalars['String']['output']>;
  /** Public key ID of the user, if known. If the user did not enroll yet this field will be null */
  publicKeyID: Maybe<Scalars['PublicKeyID']['output']>;
  /** The type of user that the enrollment will create in the organization */
  userType: UserType;
  /**
   * The encrypted shares of the wallet recovery key asscociated with this user, if any.
   * This only applies for Porto orgs with the WALLET_RECOVERY_KEY strategy.
   */
  walletRecoveryKeyShares: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * AppStatus is the simplified, app-facing state of the operation as it is processed by our system
 * These are not code-gen'd right now but they really should be
 */
export enum AppStatus {
  /** AWAITING_DEPOSIT is when the deposit address is ready and the user needs to deposit funds */
  AWAITING_DEPOSIT = 'AWAITING_DEPOSIT',
  /** CANCELED is the state when the operation has been canceled. */
  CANCELED = 'CANCELED',
  /** COMPLETE is the state when the action is complete */
  COMPLETE = 'COMPLETE',
  /** COMPLETING is when a relevant transaction is on the blockchain but not yet fully confirmed */
  COMPLETING = 'COMPLETING',
  /** EXPIRED is the state when the action has timed out */
  EXPIRED = 'EXPIRED',
  /** FAILED is the state when action could not be completed due to an error */
  FAILED = 'FAILED',
  /** AppStatusFailureCommitted represents an operation that has failed on the blockchain */
  FAILURE_COMMITTED = 'FAILURE_COMMITTED',
  /** GATHERING_APPROVALS is when the user already endorsed the operation but other singatures are being collected */
  GATHERING_APPROVALS = 'GATHERING_APPROVALS',
  /** GENERATING_ADDRESS is when the deposit address is being generated */
  GENERATING_ADDRESS = 'GENERATING_ADDRESS',
  /** IN_REVIEW is when the action is in risk review but can still be canceled */
  IN_REVIEW = 'IN_REVIEW',
  /** NEEDS_APPROVAL is when the action is collecting signatures for quorum */
  NEEDS_APPROVAL = 'NEEDS_APPROVAL',
  /** PREPARING is when the action has been created in the request state but is not ready to be signed */
  PREPARING = 'PREPARING',
  /** PROCESSING is when the organization change is post-risk review but not yet complete */
  PROCESSING = 'PROCESSING',
  /** REJECTED is the state when the customer or risk team has rejected the operation */
  REJECTED = 'REJECTED',
  /** SENDING is when the withdraw is post-risk review but not yet complete */
  SENDING = 'SENDING',
  /** WAITING_FOR_INITIATOR is the state when the action is staged, before being created */
  WAITING_FOR_INITIATOR = 'WAITING_FOR_INITIATOR'
}

/** AptosBuyIns is the map of addresses and coins for the new vesting contract */
export type AptosBuyIns = {
  __typename: 'AptosBuyIns';
  /** address is the address of the buy ins the new vesting contract */
  address: Scalars['String']['output'];
  /** coin are the tokens of the buy in for the new vesting contract */
  coin: Scalars['Decimal']['output'];
};

/** Extra parameters for aptos */
export type AptosExtraParams = {
  __typename: 'AptosExtraParams';
  /** buyIns are percentages and addresses for the new vesting contract */
  buyIns: Maybe<Array<AptosBuyIns>>;
  /** commission is the operator's commission */
  commission: Maybe<Scalars['Int']['output']>;
  /** shareholders are the addresses of the vesting contract shareholders */
  shareholders: Maybe<Array<Scalars['String']['output']>>;
  /** vestingSchedule is the vesting schedule for the new vesting contract */
  vestingSchedule: Maybe<AptosVestingSchedule>;
  /** voterAddress is the account's voter address */
  voterAddress: Maybe<Scalars['String']['output']>;
  /** withdrawalAddress is the address to which the vested tokens will be withdrawn to */
  withdrawalAddress: Maybe<Scalars['String']['output']>;
};

/** AptosVestingSchedule has the properties of the vesting schedule for the new vesting contract */
export type AptosVestingSchedule = {
  __typename: 'AptosVestingSchedule';
  /** periodDuration is the duration of the new vesting contract */
  periodDuration: Scalars['Int']['output'];
  /** schedule is the vesting schedule for the new vesting contract */
  schedule: Array<Fraction>;
  /** startTimestampSecs is the start date of the new vesting contract */
  startTimestampSecs: Scalars['String']['output'];
};

/** Response for archiveWallets and unarchiveWallets mutations. */
export type ArchiveWalletsResponse = {
  __typename: 'ArchiveWalletsResponse';
  /** How many wallets were affected by the mutation API call. */
  numberOfWalletsAffected: Scalars['Int']['output'];
};

/** Information about assets */
export type Asset = {
  __typename: 'Asset';
  /** Addresses associated with this asset */
  addresses: Array<Scalars['String']['output']>;
  /** ID of the asset */
  assetID: AssetID;
  /** Further information regarding this asset type */
  assetTypeInfo: AssetTypeInfo;
  /**
   * The amount of the asset available to perform operations with
   * Set debug to true to enable more logging.
   */
  availableBalance: Scalars['String']['output'];
  /**
   * Get the list of available balances by tokenID in this asset
   * first and after implement the connections spec
   * Set debug to true to enable more logging.
   */
  availableBalances: AmountConnection;
  /**
   * Balance at end of day (midnight UTC) over the last (optional) number of days, default 90 days
   * Set debug to true to enable more logging.
   */
  balanceHistory: Array<Scalars['String']['output']>;
  /** A list of all hold amounts aggregated by hold type for a given asset. The available balance is also returned with all holds already subtracted from it. */
  balanceWithHolds: Array<Hold>;
  /** Returns all active (queued) Celo proposals */
  celoActiveProposals: Array<CeloProposal>;
  /**
   * A list of all holds for a given asset that are client-visible. If the visibleForPurpose is not set all holds will be returned.
   * Set debug to true to enable more logging.
   */
  clientVisibleHolds: Array<Hold>;
  /** completedBalance is the raw asset amount held (in raw asset units) when considering all completed transactions */
  completedBalance: Scalars['String']['output'];
  /**
   * Get the list of completed balances by tokenID in this asset
   * first and after implement the connections spec
   * Set debug to true to enable more logging.
   */
  completedBalances: AmountConnection;
  /** An ongoing withdrawal that is blocking new withdrawals from this asset */
  conflictingWithdraw: Maybe<Operation>;
  /** Where this asset is currently delegated to if it was previously delegated */
  delegationAddress: Maybe<DelegationAddress>;
  /** List of delegation addresses allowed for this assetID and delegated by the assetID */
  delegationAddresses: Array<DelegationAddress>;
  /** Description of the asset */
  description: Scalars['String']['output'];
  /** Returns the current active Maker governance executive vote ballot, if one exists. */
  executiveMakerGovernanceBallot: Maybe<MakerGovernanceBallot>;
  /**
   * This is a status for whether the asset supports governance and whether setup has been completed.
   * See governanceSetupSteps for detail on the individual setup steps.
   */
  governanceSetupStatus: GovernanceSetupStatus;
  /**
   * Details on the specific steps required to setup this asset for participation in governance,
   * if this asset supports governance. Results will be sorted by the order in which they should be
   * completed.
   */
  governanceSetupSteps: Array<GovernanceSetupStep>;
  /** Returns all active MKR polls */
  makerPolls: Array<MakerPoll>;
  /** Name of the asset */
  name: Scalars['String']['output'];
  /** List of notices to be shown to the user for this asset */
  notices: Maybe<Array<Notice>>;
  /**
   * Get the list of operations in this asset
   * first and after implement the connections spec
   */
  operations: OperationConnection;
  /** Amount that has been deposited but not confirmed yet */
  pendingBalanceIn: Scalars['String']['output'];
  /** Amount that has been withdrawn but not confirmed yet */
  pendingBalanceOut: Scalars['String']['output'];
  /**
   * Staking information
   * If present it means this asset supports staking and this object contains all necessary information
   * to present to users and to manage operations
   */
  staking: Maybe<StakingInfo>;
  /** Unique ID of the asset */
  uniqueID: Scalars['String']['output'];
  /** The vault that holds this asset */
  vault: Vault;
  /** VestingContractAddress returns the address of the vesting contract if there is one for this asset */
  vestingContractAddress: Maybe<Scalars['String']['output']>;
  /** VestingInfo returns vesting info for the asset */
  vestingInfo: Maybe<VestingInfo>;
  /** The wallet that holds this asset */
  wallet: Wallet;
  /** Whether a withdrawal can be made or not due to conflicting operations in progress */
  withdrawPossible: Maybe<Scalars['Boolean']['output']>;
};


/** Information about assets */
export type AssetavailableBalanceArgs = {
  availableForPurpose?: InputMaybe<AvailableForPurpose>;
  debug?: Scalars['Boolean']['input'];
};


/** Information about assets */
export type AssetavailableBalancesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  availableForPurpose?: InputMaybe<AvailableForPurpose>;
  before?: InputMaybe<Scalars['ID']['input']>;
  debug?: Scalars['Boolean']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  tokenID?: InputMaybe<Scalars['String']['input']>;
};


/** Information about assets */
export type AssetbalanceHistoryArgs = {
  days?: InputMaybe<Scalars['Int']['input']>;
  debug?: Scalars['Boolean']['input'];
};


/** Information about assets */
export type AssetclientVisibleHoldsArgs = {
  debug?: Scalars['Boolean']['input'];
  visibleForPurpose?: InputMaybe<VisibleForPurpose>;
};


/** Information about assets */
export type AssetcompletedBalancesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  debug?: Scalars['Boolean']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  tokenID?: InputMaybe<Scalars['String']['input']>;
};


/** Information about assets */
export type AssetoperationsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterActions?: InputMaybe<Array<OperationAction>>;
  filterCreatedSince?: InputMaybe<Scalars['String']['input']>;
  filterCreatedUntil?: InputMaybe<Scalars['String']['input']>;
  filterRequiresDecision?: InputMaybe<Scalars['Boolean']['input']>;
  filterStates?: InputMaybe<Array<OperationState>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeChange?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortDESC?: InputMaybe<Scalars['Boolean']['input']>;
  sortField?: InputMaybe<OperationSortFields>;
};

/** AssetEnablementInfo contains the enablement rules for an asset type */
export type AssetEnablementInfo = {
  __typename: 'AssetEnablementInfo';
  /** assetTypeInfo is the information about the asset type */
  assetTypeInfo: AssetTypeInfo;
  /** entities is the enablement rules of the asset type for entities */
  entities: Array<AssetEntityEnablementStatus>;
  /** overrides is the enablement rules of the asset type for organizations */
  overrides: Array<AssetEnablementStatusOverride>;
};

/** AssetEnablementStatus is the enablement status of an asset type for an entity or organization */
export enum AssetEnablementStatus {
  /** DISABLED means the asset type is disabled for the entity or organization */
  DISABLED = 'DISABLED',
  /** ENABLED means the asset type is enabled for the entity or organization */
  ENABLED = 'ENABLED',
  /** UNSPECIFIED is the default value for the enum */
  UNSPECIFIED = 'UNSPECIFIED'
}

/**
 * AssetEnablementStatusOverride is the enablement status rule of an asset type for an organization.
 * Overrides are used to enable or disable an asset type for an organization regardless of the default/entity enablement rule.
 */
export type AssetEnablementStatusOverride = {
  __typename: 'AssetEnablementStatusOverride';
  /** entity is the entity for which the enablement status is being provided */
  entity: AnchorageEntityType;
  /** organizationKeyID is the organization for which the enablement status is being provided */
  organizationKeyID: Scalars['String']['output'];
  /** status is the enablement status of the asset type for the organization */
  status: AssetEnablementStatus;
};

/** AssetEntityEnablementStatus is the enablement status rule of an asset type for an entity */
export type AssetEntityEnablementStatus = {
  __typename: 'AssetEntityEnablementStatus';
  /** entity is the entity for which the enablement status is being provided */
  entity: AnchorageEntityType;
  /** status is the enablement status of the asset type for the entity */
  status: AssetEnablementStatus;
};

/** AssetID is the ID of an asset within a vault */
export type AssetID = {
  __typename: 'AssetID';
  /** assetSubID is used to differentiate between multiple assets of the same type within a vault */
  assetSubID: Scalars['String']['output'];
  /** assetTypeID is the type of asset being held */
  assetTypeID: Scalars['AssetTypeID']['output'];
  /** Further information regarding this asset type */
  assetTypeInfo: AssetTypeInfo;
  /** vaultID is the ID of the vault this asset is in */
  vaultID: VaultID;
};

export type AssetReferenceData = Node & {
  __typename: 'AssetReferenceData';
  assetSpecID: Maybe<AssetSpec>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  rating: AssetReferenceDataRating;
  ratingProviderID: Maybe<DataSource>;
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  version: Scalars['Int']['output'];
};

/** Ordering options for AssetReferenceData connections */
export type AssetReferenceDataOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AssetReferenceDataSlice. */
  field: AssetReferenceDataOrderField;
};

/** Properties by which AssetReferenceData connections can be ordered. */
export enum AssetReferenceDataOrderField {
  CREATED_AT = 'CREATED_AT',
  RATING = 'RATING'
}

/** AssetReferenceDataRating is enum for the field rating */
export enum AssetReferenceDataRating {
  ASSET_REFERENCE_DATA_RATING_A = 'ASSET_REFERENCE_DATA_RATING_A',
  ASSET_REFERENCE_DATA_RATING_AA = 'ASSET_REFERENCE_DATA_RATING_AA',
  ASSET_REFERENCE_DATA_RATING_AAA = 'ASSET_REFERENCE_DATA_RATING_AAA',
  ASSET_REFERENCE_DATA_RATING_B = 'ASSET_REFERENCE_DATA_RATING_B',
  ASSET_REFERENCE_DATA_RATING_C = 'ASSET_REFERENCE_DATA_RATING_C',
  ASSET_REFERENCE_DATA_RATING_UNSPECIFIED = 'ASSET_REFERENCE_DATA_RATING_UNSPECIFIED'
}

/**
 * AssetReferenceDataWhereInput is used for filtering AssetReferenceData objects.
 * Input was generated by ent.
 */
export type AssetReferenceDataWhereInput = {
  and?: InputMaybe<Array<AssetReferenceDataWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** asset_spec_id_fk edge predicates */
  hasAssetSpecID?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetSpecIDWith?: InputMaybe<Array<AssetSpecWhereInput>>;
  /** rating_provider_id_fk edge predicates */
  hasRatingProviderID?: InputMaybe<Scalars['Boolean']['input']>;
  hasRatingProviderIDWith?: InputMaybe<Array<DataSourceWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<AssetReferenceDataWhereInput>;
  or?: InputMaybe<Array<AssetReferenceDataWhereInput>>;
  /** rating field predicates */
  rating?: InputMaybe<AssetReferenceDataRating>;
  ratingIn?: InputMaybe<Array<AssetReferenceDataRating>>;
  ratingNEQ?: InputMaybe<AssetReferenceDataRating>;
  ratingNotIn?: InputMaybe<Array<AssetReferenceDataRating>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** version field predicates */
  version?: InputMaybe<Scalars['Int']['input']>;
  versionGT?: InputMaybe<Scalars['Int']['input']>;
  versionGTE?: InputMaybe<Scalars['Int']['input']>;
  versionIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  versionLT?: InputMaybe<Scalars['Int']['input']>;
  versionLTE?: InputMaybe<Scalars['Int']['input']>;
  versionNEQ?: InputMaybe<Scalars['Int']['input']>;
  versionNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type AssetSpec = Node & {
  __typename: 'AssetSpec';
  assetPrecision: Scalars['Int']['output'];
  assetSpecMetadata: Maybe<AssetSpecMetadata>;
  baseSymbol: Scalars['String']['output'];
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: AssetSpecStatus;
  symbol: Scalars['String']['output'];
  typeID: AssetSpecTypeID;
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type AssetSpecConnection = {
  __typename: 'AssetSpecConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AssetSpecEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AssetSpecEdge = {
  __typename: 'AssetSpecEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<AssetSpec>;
};

/** AssetSpecMetadata maintains additional fields for the collateral assets */
export type AssetSpecMetadata = {
  __typename: 'AssetSpecMetadata';
  cusip: Maybe<Scalars['String']['output']>;
  hashRate: Maybe<Scalars['String']['output']>;
};

/** AssetSpecMetadataInput input for additional fields for the collateral assets */
export type AssetSpecMetadataInput = {
  cusip?: InputMaybe<Scalars['String']['input']>;
  hashRate?: InputMaybe<Scalars['String']['input']>;
};

/** Ordering options for AssetSpec connections */
export type AssetSpecOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AssetSpecs. */
  field: AssetSpecOrderField;
};

/** Properties by which AssetSpec connections can be ordered. */
export enum AssetSpecOrderField {
  BASE_SYMBOL = 'BASE_SYMBOL',
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  STATUS = 'STATUS',
  SYMBOL = 'SYMBOL',
  TYPE_ID = 'TYPE_ID'
}

/** AssetSpecStatus is enum for the field status */
export enum AssetSpecStatus {
  ASSET_SPEC_STATUS_DISABLED = 'ASSET_SPEC_STATUS_DISABLED',
  ASSET_SPEC_STATUS_ENABLED = 'ASSET_SPEC_STATUS_ENABLED',
  ASSET_SPEC_STATUS_UNSPECIFIED = 'ASSET_SPEC_STATUS_UNSPECIFIED'
}

/** AssetSpecTypeID is enum for the field type_id */
export enum AssetSpecTypeID {
  ASSET_SPEC_TYPE_ID_DIGITAL_ASSET = 'ASSET_SPEC_TYPE_ID_DIGITAL_ASSET',
  ASSET_SPEC_TYPE_ID_MINING_EQUIPMENT = 'ASSET_SPEC_TYPE_ID_MINING_EQUIPMENT',
  ASSET_SPEC_TYPE_ID_SECURITY = 'ASSET_SPEC_TYPE_ID_SECURITY',
  ASSET_SPEC_TYPE_ID_UNSPECIFIED = 'ASSET_SPEC_TYPE_ID_UNSPECIFIED'
}

/**
 * AssetSpecWhereInput is used for filtering AssetSpec objects.
 * Input was generated by ent.
 */
export type AssetSpecWhereInput = {
  and?: InputMaybe<Array<AssetSpecWhereInput>>;
  /** asset_precision field predicates */
  assetPrecision?: InputMaybe<Scalars['Int']['input']>;
  assetPrecisionGT?: InputMaybe<Scalars['Int']['input']>;
  assetPrecisionGTE?: InputMaybe<Scalars['Int']['input']>;
  assetPrecisionIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  assetPrecisionLT?: InputMaybe<Scalars['Int']['input']>;
  assetPrecisionLTE?: InputMaybe<Scalars['Int']['input']>;
  assetPrecisionNEQ?: InputMaybe<Scalars['Int']['input']>;
  assetPrecisionNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** base_symbol field predicates */
  baseSymbol?: InputMaybe<Scalars['String']['input']>;
  baseSymbolContains?: InputMaybe<Scalars['String']['input']>;
  baseSymbolContainsFold?: InputMaybe<Scalars['String']['input']>;
  baseSymbolEqualFold?: InputMaybe<Scalars['String']['input']>;
  baseSymbolGT?: InputMaybe<Scalars['String']['input']>;
  baseSymbolGTE?: InputMaybe<Scalars['String']['input']>;
  baseSymbolHasPrefix?: InputMaybe<Scalars['String']['input']>;
  baseSymbolHasSuffix?: InputMaybe<Scalars['String']['input']>;
  baseSymbolIn?: InputMaybe<Array<Scalars['String']['input']>>;
  baseSymbolLT?: InputMaybe<Scalars['String']['input']>;
  baseSymbolLTE?: InputMaybe<Scalars['String']['input']>;
  baseSymbolNEQ?: InputMaybe<Scalars['String']['input']>;
  baseSymbolNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<CollateralAccountWhereInput>>;
  /** agreements edge predicates */
  hasAgreements?: InputMaybe<Scalars['Boolean']['input']>;
  hasAgreementsWith?: InputMaybe<Array<CollateralAgreementWhereInput>>;
  /** ccr_valuation_exposure_details edge predicates */
  hasCcrValuationExposureDetails?: InputMaybe<Scalars['Boolean']['input']>;
  hasCcrValuationExposureDetailsWith?: InputMaybe<Array<CCRValuationExposureDetailsWhereInput>>;
  /** eligibility_proofs edge predicates */
  hasEligibilityProofs?: InputMaybe<Scalars['Boolean']['input']>;
  hasEligibilityProofsWith?: InputMaybe<Array<EligibilityScheduleWhereInput>>;
  /** inventories edge predicates */
  hasInventories?: InputMaybe<Scalars['Boolean']['input']>;
  hasInventoriesWith?: InputMaybe<Array<CollateralInventoryWhereInput>>;
  /** prices edge predicates */
  hasPrices?: InputMaybe<Scalars['Boolean']['input']>;
  hasPricesWith?: InputMaybe<Array<PriceReferenceDataWhereInput>>;
  /** ratings edge predicates */
  hasRatings?: InputMaybe<Scalars['Boolean']['input']>;
  hasRatingsWith?: InputMaybe<Array<AssetReferenceDataWhereInput>>;
  /** settlements edge predicates */
  hasSettlements?: InputMaybe<Scalars['Boolean']['input']>;
  hasSettlementsWith?: InputMaybe<Array<CollateralSettlementWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<AssetSpecWhereInput>;
  or?: InputMaybe<Array<AssetSpecWhereInput>>;
  /** status field predicates */
  status?: InputMaybe<AssetSpecStatus>;
  statusIn?: InputMaybe<Array<AssetSpecStatus>>;
  statusNEQ?: InputMaybe<AssetSpecStatus>;
  statusNotIn?: InputMaybe<Array<AssetSpecStatus>>;
  /** symbol field predicates */
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbolContains?: InputMaybe<Scalars['String']['input']>;
  symbolContainsFold?: InputMaybe<Scalars['String']['input']>;
  symbolEqualFold?: InputMaybe<Scalars['String']['input']>;
  symbolGT?: InputMaybe<Scalars['String']['input']>;
  symbolGTE?: InputMaybe<Scalars['String']['input']>;
  symbolHasPrefix?: InputMaybe<Scalars['String']['input']>;
  symbolHasSuffix?: InputMaybe<Scalars['String']['input']>;
  symbolIn?: InputMaybe<Array<Scalars['String']['input']>>;
  symbolLT?: InputMaybe<Scalars['String']['input']>;
  symbolLTE?: InputMaybe<Scalars['String']['input']>;
  symbolNEQ?: InputMaybe<Scalars['String']['input']>;
  symbolNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** type_id field predicates */
  typeID?: InputMaybe<AssetSpecTypeID>;
  typeIDIn?: InputMaybe<Array<AssetSpecTypeID>>;
  typeIDNEQ?: InputMaybe<AssetSpecTypeID>;
  typeIDNotIn?: InputMaybe<Array<AssetSpecTypeID>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Additional information about an asset type */
export type AssetTypeInfo = {
  __typename: 'AssetTypeInfo';
  /** Abbreviation of the asset type */
  abbreviation: Scalars['String']['output'];
  /** The regex used to test for a valid address */
  addressValidationRegex: Scalars['String']['output'];
  /** The ID of the asset type */
  assetTypeID: Scalars['AssetTypeID']['output'];
  /** The blockchain name of the asset group */
  blockchainName: Scalars['String']['output'];
  /** Percent day change of the asset type */
  dayChange: Scalars['String']['output'];
  /** List of delegation addresses allowed for this asset type */
  delegationAddresses: Array<DelegationAddress>;
  /** Value of the asset type (formatted for display) */
  displayValue: Scalars['String']['output'];
  /** The max amount we can expect to withdraw for fees, used for checking balances prior to making a transfer and erroring sooner */
  feeThreshold: Amount;
  /**
   * iconURL returns the url for fetching the icon for this asset.
   * If the type is SVG, the size is ignored.
   * Icons are square.
   * The size is in pixels.
   */
  iconURL: Scalars['String']['output'];
  /** Identifies if the asset is Fiat */
  isFiat: Scalars['Boolean']['output'];
  /** Identifies if the asset is fungible */
  isFungible: Scalars['Boolean']['output'];
  /** The number of decimal places allowed for this asset type */
  maxPrecision: Scalars['String']['output'];
  /** The minimum possible balance that can be held in a wallet for this asset type. This is usually imposed by the protocol. */
  minReserveRequirement: Maybe<Amount>;
  /** Name of the asset type */
  name: Scalars['String']['output'];
  /** NetworkInfo returns information about the blockchain network on which units of this assetTypeID are transacted - nullable in case asset type does not have a network */
  networkInfo: Maybe<NetworkInfo>;
  /** The ID of the parent asset type if it exists */
  parentAssetTypeID: Maybe<Scalars['AssetTypeID']['output']>;
  /** The info of the parent asset type if it exists */
  parentAssetTypeInfo: Maybe<AssetTypeInfo>;
  /** Whether memo field is required in withdrawal for this asset */
  requiresMemo: Scalars['Boolean']['output'];
  /** standardizedStakingInfo returns the standardized staking information for this asset type. */
  standardizedStakingInfo: StandardizedStakingInfo;
  /** Identifies whether assets of this AssetType support multiple addresses (true for UTXO based, false for account based) */
  supportsMultipleAddresses: Scalars['Boolean']['output'];
  /** Identifier of token type e.g. ERC-20, ERC-721, SLP */
  tokenType: Scalars['String']['output'];
  /** Value of the asset type (as a raw decimal value) */
  value: Scalars['String']['output'];
  /** Value history of the asset type in the past "days" days (optional). Defaults to 90 days. */
  valueHistory: Array<Scalars['String']['output']>;
};


/** Additional information about an asset type */
export type AssetTypeInfoiconURLArgs = {
  iconType?: IconType;
  size?: Scalars['Int']['input'];
};


/** Additional information about an asset type */
export type AssetTypeInfovalueHistoryArgs = {
  days?: InputMaybe<Scalars['Int']['input']>;
};

/** AuthorizeSpenderOperation represents an AuthorizeSpender operation */
export type AuthorizeSpenderOperation = Operation & {
  __typename: 'AuthorizeSpenderOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /**
   * amountAuthorized is the amount the spender is authorized to spend. A null value indicates unlimited spending
   * authority
   */
  amountAuthorized: Maybe<Amount>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /** The asset associated with this operation */
  asset: Asset;
  /** asset id from with the authorization was made */
  assetID: AssetID;
  /** Further information regarding this asset type associated with this operation */
  assetTypeInfo: AssetTypeInfo;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** canonicalOperationVersion is the version of the canonical authorization that must be used to sign this authorization */
  canonicalOperationVersion: Scalars['Int']['output'];
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** confirmations returns how many confirmations the transaction associated with this authorization has received */
  confirmations: Maybe<Scalars['Int']['output']>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * extraParams are additional asset type specific parameters that were set for this transaction
   * When constructing the canonical operation for signing, the extra params go in the ExtraParams field
   * as a map from asset type id (usually, parent asset type id) to params object. The convention of extra
   * params is to omit all empty fields, and if no extra params are set for an asset type, to omit the asset
   * type from the map entirely. Note that for legacy reasons this convention
   * is the opposite of the normal CanonicalOperation convention, where no fields are ever omitted.
   */
  extraParams: Maybe<ExtraParams>;
  /** final transaction fee charged for the authorization, detected on the blockchain */
  finalFee: Maybe<Amount>;
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** max transaction fee charged for the authorization */
  maxFee: Maybe<Amount>;
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * policy that applies to this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /**
   * replayProtectionParams are the parameters to be sent to the HSM for creating and signing
   * the transactions required to effect this ExecuteOperation. The user *is not* expected
   * to review the contents of this field. However it may contain information necessary to prevent
   * replays and must be included in the payload signed by the user. Format is a hex-encoded
   * byte array.
   */
  replayProtectionParams: Maybe<Scalars['String']['output']>;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /** sendingBlockchainAddresses is a list of addresses from which an authorize spender operation is sent */
  sendingBlockchainAddresses: Array<BlockchainAddress>;
  /** signedTransaction is the blockchain transaction to be broadcast to the network */
  signedTransaction: Maybe<Scalars['String']['output']>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /**
   * sources are the originating addresses for the authorization
   * @deprecated use sendingBlockchainAddresses
   */
  sources: Array<Source>;
  /** spender is the address authorized to spend on this asset's behalf */
  spender: Scalars['String']['output'];
  /** transactionID is the blockchain transaction id once available */
  transactionID: Maybe<Scalars['String']['output']>;
  /** transactionSuccess indicates if the blockchain transaction succeeded */
  transactionSuccess: Maybe<Scalars['Boolean']['output']>;
  /**
   * usdValueFee is the USD value of the fee (finalFee if known, otherwise maxFee).
   * If both the finalFee and maxFee are not yet known, this field will be null
   */
  usdValueFee: Maybe<Scalars['String']['output']>;
  /** viewTransactionLink is the link to get more info about the transaction from a third party */
  viewTransactionLink: Maybe<Scalars['String']['output']>;
};


/** AuthorizeSpenderOperation represents an AuthorizeSpender operation */
export type AuthorizeSpenderOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** AuthorizeSpenderOperation represents an AuthorizeSpender operation */
export type AuthorizeSpenderOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/** AutoCompoundStep represents a step to ask a user if they want to enable auto-compounding for the positions in this asset. */
export type AutoCompoundStep = StakingStep & {
  __typename: 'AutoCompoundStep';
  /** List of buttons associated with this step */
  buttons: Array<BooleanButton>;
  /** Text of the step with the icons and text information */
  content: Array<Paragraph>;
  /** Ignore this field */
  ignoreMe: Maybe<Scalars['Boolean']['output']>;
  /** Rules that change presentation of the step */
  rules: Maybe<Array<PresentationRule>>;
  /** The subtitle of the step */
  subtitle: Maybe<Scalars['String']['output']>;
  /** The title of the step */
  title: Scalars['String']['output'];
};

/** AvailableForPurpose represents available balance for a certain purpose */
export enum AvailableForPurpose {
  /** CLAIM is for claiming from a Vesting Contract */
  CLAIM = 'CLAIM',
  /** DELEGATE is for delegation operation delegate type */
  DELEGATE = 'DELEGATE',
  /** LOCK is for lock operation */
  LOCK = 'LOCK',
  /** UNLOCK is for unlock operation */
  UNLOCK = 'UNLOCK',
  /** VOTE is for vote operation */
  VOTE = 'VOTE',
  /** WITHDRAW is for withdraw operation */
  WITHDRAW = 'WITHDRAW'
}

/** This list loosely corresponds to user types in lib/auth/user.go but groups together into categories. */
export enum BackendUserType {
  /** Corresponds to a user who is going through the KYC process. */
  KYC_USER = 'KYC_USER',
  /** Corresponds to a user who is associated with an existing organization. */
  ORG_USER = 'ORG_USER',
  /** Corresponds to StaffUser type defined in user.go. */
  STAFF = 'STAFF'
}

/** Represents a balance adjustment */
export type BalanceAdjustmentOperation = Operation & {
  __typename: 'BalanceAdjustmentOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** adjustmentType is the class of adjustment. */
  adjustmentType: Scalars['String']['output'];
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** amount is the total balance change */
  amount: Amount;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /** The asset associated with this operation */
  asset: Asset;
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation. Can be used to provide more context to the operation. */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /** externalAddress is the address outside the asset affected by the adjustment (or empty for fee/mint/burn) */
  externalAddress: Scalars['String']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /** transactionID is the blockchain transaction receipt id */
  transactionID: Maybe<Scalars['String']['output']>;
  /** viewTransactionLink is the link to get more info about the transaction from a third party */
  viewTransactionLink: Maybe<Scalars['String']['output']>;
  /** The wallet associated with this operation */
  wallet: Wallet;
};


/** Represents a balance adjustment */
export type BalanceAdjustmentOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/** BalanceAllocation represents the allocations for the FIAT asset (Custody, Trading, etc) */
export type BalanceAllocation = {
  __typename: 'BalanceAllocation';
  /** Amount of the balance allocation */
  amount: Amount;
  /** Percentage that holdings of this asset type represents within the organization (proportion out of 1 format. Eg: 1, 0.5, 0.1) */
  holdingPercentage: Scalars['String']['output'];
  /** Name of the balance allocation */
  name: Scalars['String']['output'];
};

/** Represents the type of balance of a transaction entry */
export enum BalanceType {
  /** Represents balance that cannot be used (HOLD) */
  HOLD = 'HOLD',
  /** Represents peding balance of a transaction */
  PENDING = 'PENDING',
  /** Represents type POSTED */
  POSTED = 'POSTED'
}

/** BidAskOutput defines the expected fields for both bid & ask RFQ's */
export type BidAskOutput = {
  __typename: 'BidAskOutput';
  /** Volatility premium in basis points */
  volatilityPremiumBps: Scalars['String']['output'];
  /** Volatility premium for the market */
  volatilityPremiumMarket: Scalars['String']['output'];
  /** Volume-weighted average price for the market */
  vwapMarket: Scalars['String']['output'];
  /** VWAP spread in basis points */
  vwapSpreadBps: Scalars['String']['output'];
};

/** Bio challenge requirements */
export type BioChallenge = {
  __typename: 'BioChallenge';
  /** Are gestures required */
  gesture: Scalars['Boolean']['output'];
  /** Phrase needed to kick off the process */
  voicePhrase: Array<Scalars['String']['output']>;
  /** List of needed words */
  voiceWords: Array<Scalars['String']['output']>;
};

/** BlockPricerTradingAsset contains the code and decimal precision length for a tradable asset */
export type BlockPricerTradingAsset = {
  __typename: 'BlockPricerTradingAsset';
  /** Code is the unique identifier for a trading asset, is not mutable */
  code: Scalars['String']['output'];
  /** Precision is the maximum number of decimal places available for a given asset */
  precision: Scalars['Int']['output'];
};

/** BlockchainAddress contains information about an Address */
export type BlockchainAddress = Node & {
  __typename: 'BlockchainAddress';
  /** Canonical text representation of the address */
  address: Scalars['String']['output'];
  /** URL to a 3rd Party service or website that displays all relevant blockchain data for the address */
  blockchainExplorerURL: Maybe<Scalars['String']['output']>;
  /** The ID of the BlockchainAddress object. Combination of network and address string */
  id: Scalars['ID']['output'];
  /** ManagedAddress contains information that is relevant to addresses managed by Anchorage. It is null for addresses that are not managed by Anchorage. */
  managedAddress: Maybe<ManagedAddress>;
  /** NetworkInfo object returns information about the Blockchain Network for this address - nullable in case the asset type of this blockchain does not have a network */
  networkInfo: Maybe<NetworkInfo>;
};

/** This type is meaningful only in the context of an operation. It identifies additional, settlement network specific, data about the source or destination of the funds and provides the address data. */
export type BlockchainAddressWithParticipant = {
  __typename: 'BlockchainAddressWithParticipant';
  /** The data about the blockchain address */
  blockchainAddress: BlockchainAddress;
  /** Data about the participant - always available when a participant is used, even when they are not a trusted counterparty */
  participant: Maybe<SNParticipant>;
  /** null if the participant is not a trusted counterparty, otherwise the trusted counterpraty object */
  trustedCounterparty: Maybe<SNTrustedCounterparty>;
};

/** Blockchain Analysis (TRM_WalletScreening,TRM_AlertsCheck) */
export type BlockchainAnalysis = {
  __typename: 'BlockchainAnalysis';
  /** score represents the risk assessment of the address */
  score: TransactionRiskScore;
  /** status is the status of the request (e.g: PENDING,UNSUPPORTED,FAILURE) */
  status: RiskRequestStatus;
  /** analysisType is the type of analysis (e.g: WalletScreening, AlertCheck) */
  type: RiskAnalysisType;
  /** url is an URL for an external chain analysis dashboard */
  url: Scalars['String']['output'];
  /** vendor is the enum value for the analysis vendor (e.g: TRM) */
  vendor: RiskAnalysisVendor;
};

/** Bluetooth information */
export type Bluetooth = {
  __typename: 'Bluetooth';
  /** Visible peripherals */
  peripherals: Array<BluetoothPeripheral>;
  /** The state of the bluetooth capabilities on the device */
  state: BluetoothState;
};


/** Bluetooth information */
export type BluetoothperipheralsArgs = {
  state?: InputMaybe<BluetoothPeripheralState>;
};

/** A specific bluetooth peripheral */
export type BluetoothPeripheral = {
  __typename: 'BluetoothPeripheral';
  /** Date in nanoseconds */
  discoveredAt: Scalars['String']['output'];
  /** Identifier of the peripheral */
  identifier: Scalars['String']['output'];
  /** Name of the peripheral */
  name: Scalars['String']['output'];
  /** Received Signal Strength Indication */
  rssi: Scalars['String']['output'];
  /** State of the peripheral */
  state: BluetoothPeripheralState;
};

/** Possible states of bluetooth peripherals */
export enum BluetoothPeripheralState {
  /** Peripheral is connected */
  CONNECTED = 'CONNECTED',
  /** Peripheral is connecting */
  CONNECTING = 'CONNECTING',
  /** Peripheral is disconnected */
  DISCONNECTED = 'DISCONNECTED',
  /** Peripheral is disconnecting */
  DISCONNECTING = 'DISCONNECTING',
  /** We failed to parse the status */
  UNKNOWN = 'UNKNOWN'
}

/** Possible bluetooth states */
export enum BluetoothState {
  /** The capability is OFF */
  POWEREDOFF = 'POWEREDOFF',
  /** The capability is ON */
  POWEREDON = 'POWEREDON',
  /** Bluetooth is resetting */
  RESETTING = 'RESETTING',
  /** Not allowed to use this capability */
  UNAUTHORIZED = 'UNAUTHORIZED',
  /** Unknown state of the capability */
  UNKNOWN = 'UNKNOWN',
  /** Device does not support this capability */
  UNSUPPORTED = 'UNSUPPORTED'
}

/** BookQuoteOutput defines the expected response from booking the trade */
export type BookQuoteOutput = {
  __typename: 'BookQuoteOutput';
  /** Response message from booking the trade */
  responseMsg: Scalars['String']['output'];
};

/** BookQuoteParams sets the information to book a trade */
export type BookQuoteParams = {
  /** Base currency for the trade */
  baseCurrency: Scalars['String']['input'];
  /** Amount of base currency */
  baseCurrencyAmount: Scalars['String']['input'];
  /** Counter currency for the trade */
  counterCurrency: Scalars['String']['input'];
  /** Final price for the trade */
  finalPrice: Scalars['String']['input'];
  /** Notional value of the trade */
  notional: Scalars['String']['input'];
  /** Reference price for the trade */
  referencePrice: Scalars['String']['input'];
  /** Side of the trade (buy or sell) */
  side: BookType;
  /** Total edge in basis points */
  totalEdgeBps: Scalars['String']['input'];
  /** Name of the trading entity */
  tradingEntity: Scalars['String']['input'];
};

/** BookType indicates the order type */
export enum BookType {
  /** Buy order type */
  BUY = 'BUY',
  /** Sell order type */
  SELL = 'SELL'
}

/** BooleanButton indicates that the button should be rendered as a boolean switch. */
export type BooleanButton = Button & {
  __typename: 'BooleanButton';
  /** The text displayed on the button */
  label: Scalars['String']['output'];
  /** Indicates if the button should be filled with color */
  primary: Scalars['Boolean']['output'];
  /** The value of the button the mutation should run with */
  value: Scalars['Boolean']['output'];
};

/** Borrower encapsulates borrower information */
export type Borrower = {
  __typename: 'Borrower';
  /** Allocated collateral page */
  allocatedCollateral: Maybe<AllocatedCollateralConnection>;
  /** Total interest accrued by borrower */
  interestToDate: Maybe<Amount>;
  /** Loans page */
  loans: Maybe<LoanConnection>;
};


/** Borrower encapsulates borrower information */
export type BorrowerallocatedCollateralArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterCollateralStatus?: InputMaybe<Array<CollateralStatus>>;
  filterLoanIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Borrower encapsulates borrower information */
export type BorrowerloansArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAfterClosedAt?: InputMaybe<Scalars['Date']['input']>;
  filterAfterMaturityDate?: InputMaybe<Scalars['Date']['input']>;
  filterAfterOriginationDate?: InputMaybe<Scalars['Date']['input']>;
  filterAfterTimestamp?: InputMaybe<Scalars['Date']['input']>;
  filterApprovalType?: InputMaybe<Array<LoanApprovalType>>;
  filterBeforeClosedAt?: InputMaybe<Scalars['Date']['input']>;
  filterBeforeMaturityDate?: InputMaybe<Scalars['Date']['input']>;
  filterBeforeOriginationDate?: InputMaybe<Scalars['Date']['input']>;
  filterBeforeTimestamp?: InputMaybe<Scalars['Date']['input']>;
  filterEntitySymbol?: InputMaybe<Scalars['String']['input']>;
  filterExactOriginationDate?: InputMaybe<Scalars['Date']['input']>;
  filterIsOpenTerm?: InputMaybe<Scalars['Boolean']['input']>;
  filterIsRefinanced?: InputMaybe<Scalars['Boolean']['input']>;
  filterRehypothecation?: InputMaybe<Scalars['Boolean']['input']>;
  filterStatus?: InputMaybe<Array<LoanTermStatus>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Borrower Interest Snapshot */
export type BorrowerInterestSnapshot = Node & {
  __typename: 'BorrowerInterestSnapshot';
  /** Amount of interest for the snapshot */
  amount: Amount;
  /** CreatedAt is when the snapshot was created */
  createdAt: Scalars['Timestamp']['output'];
  /** Identifier of the borrower interest snapshot */
  id: Scalars['ID']['output'];
  /** Snapshot date of the borrower interest snapshot */
  snapshotDate: Scalars['Date']['output'];
  /** Status of the borrower interest snapshot */
  status: LendingFacilityTransactionStatus;
};

/** Borrower Interest Snapshot connection for pagination */
export type BorrowerInterestSnapshotConnection = {
  __typename: 'BorrowerInterestSnapshotConnection';
  /** List of edges containing borrower interest snapshot and cursor information */
  edges: Array<BorrowerInterestSnapshotEdge>;
  /** Page details for the associated list of borrower interest snapshot edges */
  pageInfo: PageInfo;
};

/** BorrowerInterestSnapshot edge for pagination */
export type BorrowerInterestSnapshotEdge = {
  __typename: 'BorrowerInterestSnapshotEdge';
  /** Cursor for the associated lending facility transaction */
  cursor: Scalars['ID']['output'];
  /** Borrower Interest Snapshot */
  node: BorrowerInterestSnapshot;
};

/** Represents a parsed lined from a trusted destinations CSV */
export type BulkTrustedDestinationItem = {
  __typename: 'BulkTrustedDestinationItem';
  /** proposedTrustedDestination is the proposed trusted destination */
  proposedTrustedDestination: ProposedTrustedDestination;
  /** validationErrors is the list of validation errors for the proposed trusted destination */
  validationErrors: Array<BulkTrustedDestinationItemError>;
};

/** BulkTrustedDestinationItemError is a wrapper for message and severity */
export type BulkTrustedDestinationItemError = {
  __typename: 'BulkTrustedDestinationItemError';
  /** level is the error level */
  level: BulkTrustedDestinationItemErrorLevel;
  /** message is the error message */
  message: Scalars['String']['output'];
};

/** BulkTrustedDestinationItemErrorLevel is the severity. Warnings are non-blocking, errors are blocking */
export enum BulkTrustedDestinationItemErrorLevel {
  /** ERROR denotes an error */
  ERROR = 'ERROR',
  /** UNKNOWN denotes an unknown error level */
  UNKNOWN = 'UNKNOWN',
  /** WARNING denotes a warning */
  WARNING = 'WARNING'
}

/** Represents a parsed lined from a trusted smart contract CSV file. */
export type BulkTrustedSmartContractItem = {
  __typename: 'BulkTrustedSmartContractItem';
  /** proposedTrustedSmartContract is the proposed trusted smart contract */
  proposedTrustedSmartContract: ProposedTrustedSmartContract;
  /** validationErrors is the list of validation errors for the proposed trusted smart contract */
  validationErrors: Array<BulkTrustedSmartContractItemError>;
};

/** BulkTrustedSmartContractItemError is a wrapper for message and severity */
export type BulkTrustedSmartContractItemError = {
  __typename: 'BulkTrustedSmartContractItemError';
  /** level is the error level */
  level: BulkTrustedSmartContractItemErrorLevel;
  /** message is the error message */
  message: Scalars['String']['output'];
};

/** BulkTrustedSmartContractItemErrorLevel is the severity. Warnings are non-blocking, errors are blocking */
export enum BulkTrustedSmartContractItemErrorLevel {
  /** ERROR denotes an error. */
  ERROR = 'ERROR',
  /** UNKNOWN denotes an unknown error level. */
  UNKNOWN = 'UNKNOWN',
  /** WARNING denotes a warning. */
  WARNING = 'WARNING'
}

/** ButtonType represents a type for buttons shown in StakingSteps, all button types must extend this one. */
export type Button = {
  /** The text displayed on the button */
  label: Scalars['String']['output'];
  /** Indicates if the button should be filled with color */
  primary: Scalars['Boolean']['output'];
};

/** BuyingPower represents a brokerage account's buying power */
export type BuyingPower = {
  __typename: 'BuyingPower';
  /** Available buying power made up of a list of assets */
  available: Array<Maybe<Amount>>;
  /** Available buying power aggregate in USD */
  availableUSD: Amount;
  /** In-use buying power made up of a list of assets */
  inuse: Array<Maybe<Amount>>;
  /** In-use buying power aggregate in USD */
  inuseUSD: Amount;
  /** Total buying power made up of a list of assets */
  total: Array<Maybe<Amount>>;
  /** Total buying power aggregate in USD */
  totalUSD: Amount;
};

/** Collateral call ratios */
export type CCRLevel = {
  __typename: 'CCRLevel';
  /** Liquidation ratio */
  liquidation: Scalars['Decimal']['output'];
  /** Margin call ration */
  marginCall: Scalars['Decimal']['output'];
  /** Nominal ratio */
  nominal: Scalars['Decimal']['output'];
  /** Pre margin call ratio */
  preMarginCall: Maybe<Scalars['Decimal']['output']>;
  /** Recovery ratio */
  recovery: Maybe<Scalars['Decimal']['output']>;
  /** Reverse margin call ratio */
  reverseMarginCall: Maybe<Scalars['Decimal']['output']>;
};

export type CCRValuationCollateralDetails = Node & {
  __typename: 'CCRValuationCollateralDetails';
  account: Maybe<CollateralAccount>;
  collateralValue: Amount;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  liquidationPrice: Amount;
  marginCallPrice: Amount;
  priceTimestamp: Scalars['Timestamp']['output'];
  quantity: Scalars['Decimal']['output'];
  unitPrice: Amount;
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type CCRValuationCollateralDetailsConnection = {
  __typename: 'CCRValuationCollateralDetailsConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CCRValuationCollateralDetailsEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CCRValuationCollateralDetailsEdge = {
  __typename: 'CCRValuationCollateralDetailsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CCRValuationCollateralDetails>;
};

/** Ordering options for CCRValuationCollateralDetails connections */
export type CCRValuationCollateralDetailsOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CCRValuationCollateralDetailsSlice. */
  field: CCRValuationCollateralDetailsOrderField;
};

/** Properties by which CCRValuationCollateralDetails connections can be ordered. */
export enum CCRValuationCollateralDetailsOrderField {
  CREATED_AT = 'CREATED_AT'
}

/**
 * CCRValuationCollateralDetailsWhereInput is used for filtering CCRValuationCollateralDetails objects.
 * Input was generated by ent.
 */
export type CCRValuationCollateralDetailsWhereInput = {
  and?: InputMaybe<Array<CCRValuationCollateralDetailsWhereInput>>;
  /** collateral_value_usd field predicates */
  collateralValueUsd?: InputMaybe<Scalars['Decimal']['input']>;
  collateralValueUsdGT?: InputMaybe<Scalars['Decimal']['input']>;
  collateralValueUsdGTE?: InputMaybe<Scalars['Decimal']['input']>;
  collateralValueUsdIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  collateralValueUsdLT?: InputMaybe<Scalars['Decimal']['input']>;
  collateralValueUsdLTE?: InputMaybe<Scalars['Decimal']['input']>;
  collateralValueUsdNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  collateralValueUsdNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** account_id_fk edge predicates */
  hasAccountIDFk?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountIDFkWith?: InputMaybe<Array<CollateralAccountWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** liquidation_price field predicates */
  liquidationPrice?: InputMaybe<Scalars['Decimal']['input']>;
  liquidationPriceGT?: InputMaybe<Scalars['Decimal']['input']>;
  liquidationPriceGTE?: InputMaybe<Scalars['Decimal']['input']>;
  liquidationPriceIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  liquidationPriceLT?: InputMaybe<Scalars['Decimal']['input']>;
  liquidationPriceLTE?: InputMaybe<Scalars['Decimal']['input']>;
  liquidationPriceNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  liquidationPriceNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** margin_call_price field predicates */
  marginCallPrice?: InputMaybe<Scalars['Decimal']['input']>;
  marginCallPriceGT?: InputMaybe<Scalars['Decimal']['input']>;
  marginCallPriceGTE?: InputMaybe<Scalars['Decimal']['input']>;
  marginCallPriceIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  marginCallPriceLT?: InputMaybe<Scalars['Decimal']['input']>;
  marginCallPriceLTE?: InputMaybe<Scalars['Decimal']['input']>;
  marginCallPriceNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  marginCallPriceNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  not?: InputMaybe<CCRValuationCollateralDetailsWhereInput>;
  or?: InputMaybe<Array<CCRValuationCollateralDetailsWhereInput>>;
  /** price_timestamp field predicates */
  priceTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  priceTimestampGT?: InputMaybe<Scalars['Timestamp']['input']>;
  priceTimestampGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  priceTimestampIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  priceTimestampLT?: InputMaybe<Scalars['Timestamp']['input']>;
  priceTimestampLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  priceTimestampNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  priceTimestampNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  quantityGT?: InputMaybe<Scalars['Decimal']['input']>;
  quantityGTE?: InputMaybe<Scalars['Decimal']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  quantityLT?: InputMaybe<Scalars['Decimal']['input']>;
  quantityLTE?: InputMaybe<Scalars['Decimal']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** unit_price field predicates */
  unitPrice?: InputMaybe<Scalars['Decimal']['input']>;
  unitPriceGT?: InputMaybe<Scalars['Decimal']['input']>;
  unitPriceGTE?: InputMaybe<Scalars['Decimal']['input']>;
  unitPriceIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  unitPriceLT?: InputMaybe<Scalars['Decimal']['input']>;
  unitPriceLTE?: InputMaybe<Scalars['Decimal']['input']>;
  unitPriceNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  unitPriceNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CCRValuationExposureDetails = Node & {
  __typename: 'CCRValuationExposureDetails';
  assetSpec: Maybe<AssetSpec>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['String']['output'];
  exposure: Amount;
  id: Scalars['ID']['output'];
  priceTimestamp: Scalars['Timestamp']['output'];
  quantity: Scalars['Decimal']['output'];
  unitPrice: Amount;
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type CCRValuationExposureDetailsConnection = {
  __typename: 'CCRValuationExposureDetailsConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CCRValuationExposureDetailsEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CCRValuationExposureDetailsEdge = {
  __typename: 'CCRValuationExposureDetailsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CCRValuationExposureDetails>;
};

/** Ordering options for CCRValuationExposureDetails connections */
export type CCRValuationExposureDetailsOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CCRValuationExposureDetailsSlice. */
  field: CCRValuationExposureDetailsOrderField;
};

/** Properties by which CCRValuationExposureDetails connections can be ordered. */
export enum CCRValuationExposureDetailsOrderField {
  CREATED_AT = 'CREATED_AT'
}

/**
 * CCRValuationExposureDetailsWhereInput is used for filtering CCRValuationExposureDetails objects.
 * Input was generated by ent.
 */
export type CCRValuationExposureDetailsWhereInput = {
  and?: InputMaybe<Array<CCRValuationExposureDetailsWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** exposure_usd field predicates */
  exposureUsd?: InputMaybe<Scalars['Decimal']['input']>;
  exposureUsdGT?: InputMaybe<Scalars['Decimal']['input']>;
  exposureUsdGTE?: InputMaybe<Scalars['Decimal']['input']>;
  exposureUsdIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  exposureUsdLT?: InputMaybe<Scalars['Decimal']['input']>;
  exposureUsdLTE?: InputMaybe<Scalars['Decimal']['input']>;
  exposureUsdNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  exposureUsdNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** asset_spec_id_fk edge predicates */
  hasAssetSpecIDFk?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetSpecIDFkWith?: InputMaybe<Array<AssetSpecWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<CCRValuationExposureDetailsWhereInput>;
  or?: InputMaybe<Array<CCRValuationExposureDetailsWhereInput>>;
  /** price_timestamp field predicates */
  priceTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  priceTimestampGT?: InputMaybe<Scalars['Timestamp']['input']>;
  priceTimestampGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  priceTimestampIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  priceTimestampLT?: InputMaybe<Scalars['Timestamp']['input']>;
  priceTimestampLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  priceTimestampNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  priceTimestampNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  quantityGT?: InputMaybe<Scalars['Decimal']['input']>;
  quantityGTE?: InputMaybe<Scalars['Decimal']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  quantityLT?: InputMaybe<Scalars['Decimal']['input']>;
  quantityLTE?: InputMaybe<Scalars['Decimal']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** unit_price field predicates */
  unitPrice?: InputMaybe<Scalars['Decimal']['input']>;
  unitPriceGT?: InputMaybe<Scalars['Decimal']['input']>;
  unitPriceGTE?: InputMaybe<Scalars['Decimal']['input']>;
  unitPriceIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  unitPriceLT?: InputMaybe<Scalars['Decimal']['input']>;
  unitPriceLTE?: InputMaybe<Scalars['Decimal']['input']>;
  unitPriceNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  unitPriceNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CCRValuations = Node & {
  __typename: 'CCRValuations';
  ccr: Scalars['Percent']['output'];
  ccrValuationAgreement: Maybe<CollateralAgreement>;
  ccrValuationCollateralDetails: Maybe<Array<CCRValuationCollateralDetails>>;
  ccrValuationExposureDetails: Maybe<Array<CCRValuationExposureDetails>>;
  collateralValue: Amount;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['String']['output'];
  exposure: Amount;
  id: Scalars['ID']['output'];
  riskStatus: CCRValuationsRiskStatus;
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
  valuationTimestamp: Scalars['Timestamp']['output'];
};

/** A connection to a list of items. */
export type CCRValuationsConnection = {
  __typename: 'CCRValuationsConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CCRValuationsEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CCRValuationsEdge = {
  __typename: 'CCRValuationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CCRValuations>;
};

/** Ordering options for CCRValuations connections */
export type CCRValuationsOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CCRValuationsSlice. */
  field: CCRValuationsOrderField;
};

/** Properties by which CCRValuations connections can be ordered. */
export enum CCRValuationsOrderField {
  CREATED_AT = 'CREATED_AT',
  RISK_STATUS = 'RISK_STATUS',
  VALUATION_TIMESTAMP = 'VALUATION_TIMESTAMP'
}

/** CCRValuationsRiskStatus is enum for the field risk_status */
export enum CCRValuationsRiskStatus {
  RISK_STATUS_HEALTHY = 'RISK_STATUS_HEALTHY',
  RISK_STATUS_LIQUIDATION = 'RISK_STATUS_LIQUIDATION',
  RISK_STATUS_MARGINCALL = 'RISK_STATUS_MARGINCALL',
  RISK_STATUS_NOEXPOSURE = 'RISK_STATUS_NOEXPOSURE',
  RISK_STATUS_PREMARGINCALL = 'RISK_STATUS_PREMARGINCALL',
  RISK_STATUS_UNSPECIFIED = 'RISK_STATUS_UNSPECIFIED'
}

/**
 * CCRValuationsWhereInput is used for filtering CCRValuations objects.
 * Input was generated by ent.
 */
export type CCRValuationsWhereInput = {
  and?: InputMaybe<Array<CCRValuationsWhereInput>>;
  /** ccr field predicates */
  ccr?: InputMaybe<Scalars['Percent']['input']>;
  ccrGT?: InputMaybe<Scalars['Percent']['input']>;
  ccrGTE?: InputMaybe<Scalars['Percent']['input']>;
  ccrIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  ccrLT?: InputMaybe<Scalars['Percent']['input']>;
  ccrLTE?: InputMaybe<Scalars['Percent']['input']>;
  ccrNEQ?: InputMaybe<Scalars['Percent']['input']>;
  ccrNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  /** collateral_value_usd field predicates */
  collateralValueUsd?: InputMaybe<Scalars['Decimal']['input']>;
  collateralValueUsdGT?: InputMaybe<Scalars['Decimal']['input']>;
  collateralValueUsdGTE?: InputMaybe<Scalars['Decimal']['input']>;
  collateralValueUsdIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  collateralValueUsdLT?: InputMaybe<Scalars['Decimal']['input']>;
  collateralValueUsdLTE?: InputMaybe<Scalars['Decimal']['input']>;
  collateralValueUsdNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  collateralValueUsdNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** exposure_usd field predicates */
  exposureUsd?: InputMaybe<Scalars['Decimal']['input']>;
  exposureUsdGT?: InputMaybe<Scalars['Decimal']['input']>;
  exposureUsdGTE?: InputMaybe<Scalars['Decimal']['input']>;
  exposureUsdIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  exposureUsdLT?: InputMaybe<Scalars['Decimal']['input']>;
  exposureUsdLTE?: InputMaybe<Scalars['Decimal']['input']>;
  exposureUsdNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  exposureUsdNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** ca_id_fk edge predicates */
  hasCaIDFk?: InputMaybe<Scalars['Boolean']['input']>;
  hasCaIDFkWith?: InputMaybe<Array<CollateralAgreementWhereInput>>;
  /** ccr_valuation_collateral_details edge predicates */
  hasCcrValuationCollateralDetails?: InputMaybe<Scalars['Boolean']['input']>;
  hasCcrValuationCollateralDetailsWith?: InputMaybe<Array<CCRValuationCollateralDetailsWhereInput>>;
  /** ccr_valuation_exposure_details edge predicates */
  hasCcrValuationExposureDetails?: InputMaybe<Scalars['Boolean']['input']>;
  hasCcrValuationExposureDetailsWith?: InputMaybe<Array<CCRValuationExposureDetailsWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<CCRValuationsWhereInput>;
  or?: InputMaybe<Array<CCRValuationsWhereInput>>;
  /** risk_status field predicates */
  riskStatus?: InputMaybe<CCRValuationsRiskStatus>;
  riskStatusIn?: InputMaybe<Array<CCRValuationsRiskStatus>>;
  riskStatusNEQ?: InputMaybe<CCRValuationsRiskStatus>;
  riskStatusNotIn?: InputMaybe<Array<CCRValuationsRiskStatus>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** valuation_timestamp field predicates */
  valuationTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  valuationTimestampGT?: InputMaybe<Scalars['Timestamp']['input']>;
  valuationTimestampGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  valuationTimestampIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  valuationTimestampLT?: InputMaybe<Scalars['Timestamp']['input']>;
  valuationTimestampLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  valuationTimestampNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  valuationTimestampNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
};

/** CRRHistoryConnection contains the edges to CRRScoreDetails */
export type CRRHistoryConnection = {
  __typename: 'CRRHistoryConnection';
  /** List of edges containing CRR score details and cursor information */
  edges: Array<CRRScoreDetailsEdge>;
  /** Page details for the associated list of CrrScoreDetailsEdge */
  pageInfo: PageInfo;
};

/** CRRHistoryFilters contains filter properties to get CRR history for an affiliate */
export type CRRHistoryFilters = {
  /**
   * fromDate filters out records with createDate less than fromDate,
   * results include fromDate(inclusive).
   */
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  /**
   * getAcceptedCRR flag if true will return accepted CRR in addition
   * to dynamic CRR, if getDynamicCRR is also set to true
   */
  getAcceptedCRR?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * getDynamicCRR flag if true will return dynamic CRR in addition
   * to accepted CRR, if getAcceptedCRR is also set to true
   */
  getDynamicCRR?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * toDate filters out records with createDate greater than toDate,
   * results include toDate(inclusive).
   */
  toDate?: InputMaybe<Scalars['Date']['input']>;
};

/** CRRRiskConfigs contains the risk parameters of a CRR configuration */
export type CRRRiskConfigs = {
  __typename: 'CRRRiskConfigs';
  /** Time at which this CRR configuration version was created */
  createdAt: Scalars['Timestamp']['output'];
  /** Unique ID for this particular risk CRR configuration version */
  crrConfigID: Scalars['String']['output'];
  /** CRR configuration version */
  crrConfigVersion: Scalars['String']['output'];
  /** parameters as JSON */
  riskParametersBody: Scalars['String']['output'];
};

/** CRRScoreDetails contains information related to CRR */
export type CRRScoreDetails = Node & {
  __typename: 'CRRScoreDetails';
  /** createdAt is the date when record was created */
  createdAt: Maybe<Scalars['Timestamp']['output']>;
  /** factorGroups is a list of factors values */
  factorGroups: Array<Maybe<FactorGroup>>;
  /** id is the internal unique identifier */
  id: Scalars['ID']['output'];
  /** numericalScore is the score in decimal as string */
  numericalScore: Maybe<Scalars['String']['output']>;
  /** outcome is the result of the Model output */
  outcome: Maybe<CRRScoreOutcome>;
  /** source gives the source of the CRR */
  source: Maybe<CRRSource>;
};

/** CRRScoreDetailsEdge contains the CRRScoreDetails */
export type CRRScoreDetailsEdge = {
  __typename: 'CRRScoreDetailsEdge';
  /** Cursor for the CrrScoreDetailsEdge */
  cursor: Scalars['ID']['output'];
  /** CRR Score details */
  node: CRRScoreDetails;
};

/** CRRScoreOutcome is the model for CRR categorical outcome */
export enum CRRScoreOutcome {
  /** HIGH is the outcome for HIGH risk */
  HIGH = 'HIGH',
  /** LOW is the outcome for LOW risk */
  LOW = 'LOW',
  /** MEDIUM is the outcome for MEDIUM risk */
  MEDIUM = 'MEDIUM'
}

/** CRRSectionHistoryPoint represents the section scores for a date */
export type CRRSectionHistoryPoint = {
  __typename: 'CRRSectionHistoryPoint';
  /** date is the date of the record */
  date: Maybe<Scalars['Timestamp']['output']>;
  /** sectionValues is the list of values of the sections */
  sectionValues: Array<SectionValue>;
};

/** CRRSource is the model for CRR source */
export enum CRRSource {
  /**
   * ACCEPTED is the approved CRR either copied from dynamic or
   * overridden by a user
   */
  ACCEPTED = 'ACCEPTED',
  /** DYNAMIC is for Dynamic CRR generated by risk models */
  DYNAMIC = 'DYNAMIC'
}

/** CallSubject maintains fields for the collateral call details */
export type CallSubject = {
  __typename: 'CallSubject';
  amount: Amount;
  deliveryAccountID: Maybe<Scalars['ID']['output']>;
  deliveryAsset: Maybe<Scalars['String']['output']>;
  note: Maybe<Scalars['String']['output']>;
  payDownAmountAtCallTimeUsd: Amount;
  payDownAmountBreakdownAtCallTime: Maybe<Array<Amount>>;
  returnAccountID: Maybe<Scalars['ID']['output']>;
  returnAsset: Maybe<Scalars['String']['output']>;
  subject: Scalars['String']['output'];
  time: Scalars['Timestamp']['output'];
};

/** CancelOperationResponse contains response for a CancelOperation request. */
export type CancelOperationResponse = {
  __typename: 'CancelOperationResponse';
  /** details contains optional, detailed info, explaining why the statusCode is returned. */
  details: Maybe<Scalars['String']['output']>;
  /** statusCode indicates whether a request has been successfully received, completed, failed, etc. */
  statusCode: ResponseStatusCode;
};

/** Carrier information */
export type Carrier = {
  __typename: 'Carrier';
  /** Country ISO code of the carrier */
  countryISO: Scalars['String']['output'];
  /** Name of the carrier */
  name: Scalars['String']['output'];
  /** Technology of the carrier, e.g. LTE */
  technology: Scalars['String']['output'];
};

/** Case represents a Jira case created in the context of a compliance investigation */
export type Case = {
  __typename: 'Case';
  /** AccountID associated to the case */
  accountID: Scalars['String']['output'];
  /** List of alerts associated to the case */
  alerts: AlertConnection;
  /** Internal ID of the case */
  caseID: Scalars['String']['output'];
  /** Type of the case in Jira */
  caseType: Maybe<CaseType>;
  /** Type of client associated to the case */
  clientType: Maybe<Scalars['String']['output']>;
  /** When this case was created */
  createdAt: Scalars['Date']['output'];
  /** CRR associated with the case */
  crr: Maybe<CRRScoreOutcome>;
  /** Case description */
  description: Maybe<Scalars['String']['output']>;
  /** Subject assigned to the case */
  extAssignee: Maybe<Scalars['String']['output']>;
  /** Subject investigating the case */
  extInvestigator: Maybe<Scalars['String']['output']>;
  /** ID of the case in Jira */
  extTrackingID: Maybe<Scalars['String']['output']>;
  /** Link to the case in Jira */
  extTrackingLink: Maybe<Scalars['String']['output']>;
  /** Get the kyc process associated in this case if it exists */
  kycProcess: Maybe<KYCProcess>;
  /** Org ID associated to the case */
  orgKeyID: Scalars['String']['output'];
  /** Specific status of the case */
  status: Maybe<CaseStatus>;
  /** Case summary */
  summary: Maybe<Scalars['String']['output']>;
  /** When this case was last updated */
  updatedAt: Scalars['Date']['output'];
};


/** Case represents a Jira case created in the context of a compliance investigation */
export type CasealertsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** CaseConnection implements the connections spec */
export type CaseConnection = {
  __typename: 'CaseConnection';
  /** edges implements the connections spec */
  edges: Array<Maybe<CaseEdge>>;
  /** pageInfo implements the connections spec */
  pageInfo: PageInfo;
};

/** CaseEdge implements the connections spec */
export type CaseEdge = {
  __typename: 'CaseEdge';
  /** cursor implements the connections spec */
  cursor: Scalars['String']['output'];
  /** node implements the connections spec */
  node: Case;
};

/** CaseStatus are the possible states for compliance cases */
export enum CaseStatus {
  /** BLOCKED_PROPERTY_OR_REJECTED_TRANSACTIONS_REPORT_FILED is a case where blocked property or rejected tx is filed */
  BLOCKED_PROPERTY_OR_REJECTED_TRANSACTIONS_REPORT_FILED = 'BLOCKED_PROPERTY_OR_REJECTED_TRANSACTIONS_REPORT_FILED',
  /** BLOCKED_PROPERTY_OR_REJECTED_TRANSACTIONS_REPORT_IN_PROGRESS is a case where blocked property or rejected tx is in progress */
  BLOCKED_PROPERTY_OR_REJECTED_TRANSACTIONS_REPORT_IN_PROGRESS = 'BLOCKED_PROPERTY_OR_REJECTED_TRANSACTIONS_REPORT_IN_PROGRESS',
  /** CANCELED the case was canceled */
  CANCELED = 'CANCELED',
  /** CLIENT_APPROVED - Full KYC Review Case */
  CLIENT_APPROVED = 'CLIENT_APPROVED',
  /** ESCALATED_TO_BSAO is a case escalated to BSAO */
  ESCALATED_TO_BSAO = 'ESCALATED_TO_BSAO',
  /** ESCALATED_TO_BSAO_FIRST_CAR is a case escalated to BSAO for the first CAR */
  ESCALATED_TO_BSAO_FIRST_CAR = 'ESCALATED_TO_BSAO_FIRST_CAR',
  /** ESCALATED_TO_BSAO_SACTIONS_OFFICER is a case escalated to BSAO / Sanctions Officer */
  ESCALATED_TO_BSAO_SACTIONS_OFFICER = 'ESCALATED_TO_BSAO_SACTIONS_OFFICER',
  /** ESCALATED_TO_BSAO_SECOND_CAR is a case escalated to BSAO for the second CAR */
  ESCALATED_TO_BSAO_SECOND_CAR = 'ESCALATED_TO_BSAO_SECOND_CAR',
  /** ESCALATED_TO_COMPLIANCE is a case escalated to the compliance team */
  ESCALATED_TO_COMPLIANCE = 'ESCALATED_TO_COMPLIANCE',
  /** FALSE_POSITIVE - NNS, PEP */
  FALSE_POSITIVE = 'FALSE_POSITIVE',
  /** FIRST_CAR_TRIGGERED is a case that triggered it's first CAR */
  FIRST_CAR_TRIGGERED = 'FIRST_CAR_TRIGGERED',
  /** HIGH_RISK_PEP - PEP */
  HIGH_RISK_PEP = 'HIGH_RISK_PEP',
  /** IMMATERIAL_PEP - PEP */
  IMMATERIAL_PEP = 'IMMATERIAL_PEP',
  /** INITIATE_OFFBOARDING_REVIEW - Full KYC Review Case */
  INITIATE_OFFBOARDING_REVIEW = 'INITIATE_OFFBOARDING_REVIEW',
  /** IN_COMPLIANCE_ESCALATION_BSAO_REVIEW is a case in compliance escalation BSAO review */
  IN_COMPLIANCE_ESCALATION_BSAO_REVIEW = 'IN_COMPLIANCE_ESCALATION_BSAO_REVIEW',
  /** IN_COMPLIANCE_ESCALATION_REVIEW is a case in compliance escalation review */
  IN_COMPLIANCE_ESCALATION_REVIEW = 'IN_COMPLIANCE_ESCALATION_REVIEW',
  /** IN_REVIEW the case is under review */
  IN_REVIEW = 'IN_REVIEW',
  /** IN_REVIEW_FIRST_CAR is a case in review for it's first CAR (Continiuos Activity Review) */
  IN_REVIEW_FIRST_CAR = 'IN_REVIEW_FIRST_CAR',
  /** IN_REVIEW_SECOND_CAR is a case where the second CAR is in review */
  IN_REVIEW_SECOND_CAR = 'IN_REVIEW_SECOND_CAR',
  /** MANAGER_REVIEW -the case is under management review */
  MANAGER_REVIEW = 'MANAGER_REVIEW',
  /** MANAGER_REVIEW_FIRST_CAR is a case pending a Manager's review of the first CAR */
  MANAGER_REVIEW_FIRST_CAR = 'MANAGER_REVIEW_FIRST_CAR',
  /** MANAGER_REVIEW_SECOND_CAR is a case where the manager is reviewing the second CAR */
  MANAGER_REVIEW_SECOND_CAR = 'MANAGER_REVIEW_SECOND_CAR',
  /** MATERIAL_HIT - NNS */
  MATERIAL_HIT = 'MATERIAL_HIT',
  /** NEW represents a new case in Jira */
  NEW = 'NEW',
  /** NON_MATERIAL_HIT - NNS */
  NON_MATERIAL_HIT = 'NON_MATERIAL_HIT',
  /** NO_FILING - OFAC Reporting */
  NO_FILING = 'NO_FILING',
  /** PENDING represents a pending case in Jira */
  PENDING = 'PENDING',
  /** PENDING_BSAO_RECOMMENDATION the case is pending a recommendation from BSAO */
  PENDING_BSAO_RECOMMENDATION = 'PENDING_BSAO_RECOMMENDATION',
  /** PENDING_COO_APPROVAL is a case pending COO approval */
  PENDING_COO_APPROVAL = 'PENDING_COO_APPROVAL',
  /** PENDING_FIRST_CAR is a case pending it's first CAR */
  PENDING_FIRST_CAR = 'PENDING_FIRST_CAR',
  /** PENDING_SECOND_CAR is a case pending a second CAR */
  PENDING_SECOND_CAR = 'PENDING_SECOND_CAR',
  /** PEP_FLAG_REMOVED - PEP */
  PEP_FLAG_REMOVED = 'PEP_FLAG_REMOVED',
  /** QC_REVIEW is a case where QC is reviewing */
  QC_REVIEW = 'QC_REVIEW',
  /** READY_FOR_APPROVAL is a case ready for approval */
  READY_FOR_APPROVAL = 'READY_FOR_APPROVAL',
  /** READY_QC is a case ready for QC */
  READY_QC = 'READY_QC',
  /** RESOLVED represents a resolved case */
  RESOLVED = 'RESOLVED',
  /** SAR_FILED the case was resolved with the filing of a SAR */
  SAR_FILED = 'SAR_FILED',
  /** SAR_FILED_FIRST_CAR is a case where the first CAR was filed */
  SAR_FILED_FIRST_CAR = 'SAR_FILED_FIRST_CAR',
  /** SAR_FILED_SECOND_CAR is a case where the SAR is filed for the second CAR */
  SAR_FILED_SECOND_CAR = 'SAR_FILED_SECOND_CAR',
  /** SAR_FILING_IN_PROGRESS is a case where SAR is being filed */
  SAR_FILING_IN_PROGRESS = 'SAR_FILING_IN_PROGRESS',
  /** SAR_FILING_IN_PROGRESS_FIRST_CAR is a case where the first CAR filing is in progress */
  SAR_FILING_IN_PROGRESS_FIRST_CAR = 'SAR_FILING_IN_PROGRESS_FIRST_CAR',
  /** SAR_FILING_IN_PROGRESS_SECOND_CAR is a case where the second CAR filing is in progress */
  SAR_FILING_IN_PROGRESS_SECOND_CAR = 'SAR_FILING_IN_PROGRESS_SECOND_CAR',
  /** SAR_NOT_FILED the case was resolved with the filing of a SAR */
  SAR_NOT_FILED = 'SAR_NOT_FILED',
  /** SAR_NOT_FILED_FIRST_CAR is a case where the first CAR has not been filed */
  SAR_NOT_FILED_FIRST_CAR = 'SAR_NOT_FILED_FIRST_CAR',
  /** SAR_NOT_FILED_SECOND_CAR is a case where no SAR was filed for the second CAR */
  SAR_NOT_FILED_SECOND_CAR = 'SAR_NOT_FILED_SECOND_CAR',
  /** SECOND_CAR_TRIGGERED is a case where the second CAR was triggered */
  SECOND_CAR_TRIGGERED = 'SECOND_CAR_TRIGGERED',
  /** VSD_FILED - OFAC Reporting */
  VSD_FILED = 'VSD_FILED',
  /** VSD_FILING_IN_PROGRESS is a case where VSD is being filed */
  VSD_FILING_IN_PROGRESS = 'VSD_FILING_IN_PROGRESS'
}

/** CaseType are the possible types of jira cases */
export enum CaseType {
  /** FULL_KYC_REVIEW is a case that originates from the CCV dashboard */
  FULL_KYC_REVIEW = 'FULL_KYC_REVIEW',
  /**
   * KYC_CRR_UPDATE is a case that enables users to update previously set
   * autorisk flags (eg PEP, FFI / FCA) and revise Accepted CRR ratings
   * without going through the long process of a Full KYC Review Case
   */
  KYC_CRR_UPDATE = 'KYC_CRR_UPDATE',
  /** NEGATIVE_NEWS represents the negative news case type */
  NEGATIVE_NEWS = 'NEGATIVE_NEWS',
  /** OFAC_REPORTING represents the ofac reporting case type */
  OFAC_REPORTING = 'OFAC_REPORTING',
  /** PEP represents the pep case type */
  PEP = 'PEP',
  /** REFERRAL represents the referral case type */
  REFERRAL = 'REFERRAL',
  /** TRANSACTION_ACTIVITY represents the transaction activity case type */
  TRANSACTION_ACTIVITY = 'TRANSACTION_ACTIVITY'
}

/** CasesAtTime represents the number of cases at a given time from a case type */
export type CasesAtTime = {
  __typename: 'CasesAtTime';
  /** Type of the case */
  caseType: Maybe<CaseType>;
  /** Date of the cases */
  createdAt: Scalars['Date']['output'];
  /** Number of cases */
  numberOfCases: Scalars['Int']['output'];
};

/** Extra parameters for celo governance vote */
export type CeloGovernanceVoteExtraParams = {
  __typename: 'CeloGovernanceVoteExtraParams';
  /** actionType is used to construct the canonical operation */
  actionType: Maybe<ExtraParamsActionType>;
  /** destination tag to use in transaction */
  proposalID: Scalars['Int']['output'];
  /** voteOption is the intent of the vote */
  voteOption: VoteOption;
};

/** CeloProposal represents general data about a Celo proposal */
export type CeloProposal = {
  __typename: 'CeloProposal';
  /** currentVote represent the current voted option, if user has voted */
  currentVote: Maybe<CurrentVote>;
  /** Amount the proposer deposited to create the proposal */
  deposit: Amount;
  /** The URL where more information on the proposal can be found */
  descriptionUrl: Scalars['String']['output'];
  /** The time left for this stage to end, in seconds */
  endsIn: Scalars['String']['output'];
  /** Number of transactions involved in this proposal */
  numTransactions: Scalars['Int']['output'];
  /** ID of the proposal */
  proposalID: Scalars['Int']['output'];
  /** Address of the proposer */
  proposer: Scalars['String']['output'];
  /** The current stage the proposal is in */
  stage: ProposalStage;
  /** Time the proposal was created */
  timestamp: Scalars['String']['output'];
  /** This is the title of the proposal */
  title: Scalars['String']['output'];
  /**
   * The timestamp at which we most recently pulled information about this proposal, since we will
   * have a caching layer for some info.
   */
  updatedAt: Scalars['String']['output'];
  /** The number of upvotes received */
  upvotes: Scalars['Int']['output'];
  /** The breakdown of votes for a proposal in referendum */
  votes: VoteTotal;
};

/** Celo proposal-specific details */
export type CeloProposalDetails = {
  __typename: 'CeloProposalDetails';
  /** The Celo proposal associated with the operation */
  celoProposal: CeloProposal;
};

/** The detail for celo validator group */
export type CeloValidatorGroupDetail = {
  __typename: 'CeloValidatorGroupDetail';
  /** Total amount of gold locked, special to celo validator groups */
  lockedGold: Amount;
  /** Number of total validators in this validator group, special to celo validator groups */
  numMembers: Scalars['Int']['output'];
};

/** ChangeRoleOperation represents an operation to create, update, or delete a role in an organization */
export type ChangeRoleOperation = Operation & {
  __typename: 'ChangeRoleOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** comment is an optional given by the initiator to convey context to other operation reviewers */
  comment: Maybe<Scalars['String']['output']>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /**
   * existingRole contains identifier for the role, and its existing/current data, prior to the change made in this operation.
   * nil for CREATE operation, otherwise non-nil.
   * Use `roleChanges` to get the role's updated data, with the change made in this operation.
   */
  existingRole: Maybe<Role>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** the expected digest of the new organization after applying this change */
  newOrganizationDigest: Scalars['String']['output'];
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * policy required in order to satisfy this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /**
   * role contains identifier for the role, and the new data the role is changed to in this operation.
   * Always non-nil, but Permissions will be empty for DELETE operation.
   * Use `existingRole` to get the role's existing data.
   */
  role: Role;
  /**
   * roleChanges details changes being made by this operation to the permission group. Details are composed for the user interface.
   * @deprecated use rolePermissions, this field is used in an early Demo app release
   */
  roleChanges: RolePermissionChanges;
  /** rolePermissions details changes being made by this operation to the permission group. Details are composed for the user interface. */
  rolePermissions: RolePermissionDetails;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /** type indicates how the role is being changed */
  type: RoleChangeType;
};


/** ChangeRoleOperation represents an operation to create, update, or delete a role in an organization */
export type ChangeRoleOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** ChangeRoleOperation represents an operation to create, update, or delete a role in an organization */
export type ChangeRoleOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/** ClientAffiliateType are the affiliate types a client is allowed to create */
export type ClientAffiliateType = {
  __typename: 'ClientAffiliateType';
  /** The type of affiliate for this clientID when filling this questionnaire ID */
  affiliateType: Scalars['String']['output'];
  /** The client id */
  clientID: Scalars['String']['output'];
};

/** ClientAffiliateTypeInput is the input to create the affiliate types a client is allowed */
export type ClientAffiliateTypeInput = {
  /** The type of affiliate for this clientID when filling this questionnaire ID */
  affiliateType: Scalars['String']['input'];
  /** The client id */
  clientID: Scalars['String']['input'];
};

/**
 * ClientDashboardLoginRequestInfo returns browser data and other information about
 * the session ID and its associated request to login.  If the login request is
 * used or expired, this will not return the information.
 */
export type ClientDashboardLoginRequestInfo = {
  __typename: 'ClientDashboardLoginRequestInfo';
  /** Latitude of the browser on initial request */
  latitude: Scalars['String']['output'];
  /** Longitude of the browser on initial request */
  longitude: Scalars['String']['output'];
};

/** Type of information and reason rejected */
export type ClientInformationRejection = {
  __typename: 'ClientInformationRejection';
  /** Type of information */
  informationType: ClientInformationType;
  /** Reason the information was rejected */
  rejectionReason: Scalars['String']['output'];
};

/** Enum of information that may be provided by institutional clients */
export enum ClientInformationType {
  /** ACCOUNT_NAME ... */
  ACCOUNT_NAME = 'ACCOUNT_NAME',
  /** ACCOUNT_REGISTRATION ... */
  ACCOUNT_REGISTRATION = 'ACCOUNT_REGISTRATION',
  /** ACCOUNT_REGISTRATION_CORPORATION ... */
  ACCOUNT_REGISTRATION_CORPORATION = 'ACCOUNT_REGISTRATION_CORPORATION',
  /** ACCOUNT_REGISTRATION_LLC ... */
  ACCOUNT_REGISTRATION_LLC = 'ACCOUNT_REGISTRATION_LLC',
  /** ACCOUNT_REGISTRATION_OTHER ... */
  ACCOUNT_REGISTRATION_OTHER = 'ACCOUNT_REGISTRATION_OTHER',
  /** ACCOUNT_REGISTRATION_PARTNERSHIP ... */
  ACCOUNT_REGISTRATION_PARTNERSHIP = 'ACCOUNT_REGISTRATION_PARTNERSHIP',
  /** ACCOUNT_REGISTRATION_SOLE_PROPRIETOR ... */
  ACCOUNT_REGISTRATION_SOLE_PROPRIETOR = 'ACCOUNT_REGISTRATION_SOLE_PROPRIETOR',
  /** ACKNOWLEDGE_EMAIL ... */
  ACKNOWLEDGE_EMAIL = 'ACKNOWLEDGE_EMAIL',
  /** ACKNOWLEDGE_NAME ... */
  ACKNOWLEDGE_NAME = 'ACKNOWLEDGE_NAME',
  /** ACKNOWLEDGE_TITLE ... */
  ACKNOWLEDGE_TITLE = 'ACKNOWLEDGE_TITLE',
  /** ADDRESS ... */
  ADDRESS = 'ADDRESS',
  /** ADDRESS2 ... */
  ADDRESS2 = 'ADDRESS2',
  /** ADS_KYC_INSTITUTION_FORM_SUBMISSION_ID ... */
  ADS_KYC_INSTITUTION_FORM_SUBMISSION_ID = 'ADS_KYC_INSTITUTION_FORM_SUBMISSION_ID',
  /** ARE_INVESTMENT_ADVISOR ... */
  ARE_INVESTMENT_ADVISOR = 'ARE_INVESTMENT_ADVISOR',
  /** ARE_REGISTERED_MAS ... */
  ARE_REGISTERED_MAS = 'ARE_REGISTERED_MAS',
  /** ASSET_CLASSES_EXPERIENCE_COMMODITIES */
  ASSET_CLASSES_EXPERIENCE_COMMODITIES = 'ASSET_CLASSES_EXPERIENCE_COMMODITIES',
  /** ASSET_CLASSES_EXPERIENCE_DIGITAL_ASSETS */
  ASSET_CLASSES_EXPERIENCE_DIGITAL_ASSETS = 'ASSET_CLASSES_EXPERIENCE_DIGITAL_ASSETS',
  /** ASSET_CLASSES_EXPERIENCE_EQUITIES */
  ASSET_CLASSES_EXPERIENCE_EQUITIES = 'ASSET_CLASSES_EXPERIENCE_EQUITIES',
  /** ASSET_CLASSES_EXPERIENCE_FIXED_INCOME */
  ASSET_CLASSES_EXPERIENCE_FIXED_INCOME = 'ASSET_CLASSES_EXPERIENCE_FIXED_INCOME',
  /** BUSINESS_REGISTRATION_NUMBER ... */
  BUSINESS_REGISTRATION_NUMBER = 'BUSINESS_REGISTRATION_NUMBER',
  /** CITY */
  CITY = 'CITY',
  /** CLIENT_BASE_ACCREDITED_INVESTORS */
  CLIENT_BASE_ACCREDITED_INVESTORS = 'CLIENT_BASE_ACCREDITED_INVESTORS',
  /** CLIENT_BASE_CRYPTO */
  CLIENT_BASE_CRYPTO = 'CLIENT_BASE_CRYPTO',
  /** CLIENT_BASE_FAMILY */
  CLIENT_BASE_FAMILY = 'CLIENT_BASE_FAMILY',
  /** CLIENT_BASE_HEDGE_FUND */
  CLIENT_BASE_HEDGE_FUND = 'CLIENT_BASE_HEDGE_FUND',
  /** CLIENT_BASE_INSTITUTIONAL_TRUST */
  CLIENT_BASE_INSTITUTIONAL_TRUST = 'CLIENT_BASE_INSTITUTIONAL_TRUST',
  /** CLIENT_BASE_NONE */
  CLIENT_BASE_NONE = 'CLIENT_BASE_NONE',
  /** CLIENT_BASE_PRIVATE_CAPITAL */
  CLIENT_BASE_PRIVATE_CAPITAL = 'CLIENT_BASE_PRIVATE_CAPITAL',
  /** CLIENT_BASE_VENTURE_CAPITAL */
  CLIENT_BASE_VENTURE_CAPITAL = 'CLIENT_BASE_VENTURE_CAPITAL',
  /** COUNTRY */
  COUNTRY = 'COUNTRY',
  /** DATE_OF_INCORPORATION ... */
  DATE_OF_INCORPORATION = 'DATE_OF_INCORPORATION',
  /** DBA_NAME */
  DBA_NAME = 'DBA_NAME',
  /** Optional DBA or Trade names */
  DBA_NAMES = 'DBA_NAMES',
  /** Full legal entity name */
  ENTITY_NAME = 'ENTITY_NAME',
  /** EXPLAIN_YOU_ARE_NOT_REGISTERED ... */
  EXPLAIN_YOU_ARE_NOT_REGISTERED = 'EXPLAIN_YOU_ARE_NOT_REGISTERED',
  /** FEIN ... */
  FEIN = 'FEIN',
  /** INDIVIDUAL_WEBSITE_URL ... */
  INDIVIDUAL_WEBSITE_URL = 'INDIVIDUAL_WEBSITE_URL',
  /** INVESTABLE_ASSETS */
  INVESTABLE_ASSETS = 'INVESTABLE_ASSETS',
  /** INVESTABLE_ASSETS_CRYPTO_PERCENTAGE */
  INVESTABLE_ASSETS_CRYPTO_PERCENTAGE = 'INVESTABLE_ASSETS_CRYPTO_PERCENTAGE',
  /** INVESTMENT_ADVISER_REGISTRATION ... */
  INVESTMENT_ADVISER_REGISTRATION = 'INVESTMENT_ADVISER_REGISTRATION',
  /** INVESTMENT_ADVISER_REGISTRATION_IARD ... */
  INVESTMENT_ADVISER_REGISTRATION_IARD = 'INVESTMENT_ADVISER_REGISTRATION_IARD',
  /** INVESTMENT_ADVISER_REGISTRATION_IARD_OTHER ... */
  INVESTMENT_ADVISER_REGISTRATION_IARD_OTHER = 'INVESTMENT_ADVISER_REGISTRATION_IARD_OTHER',
  /** INVESTMENT_ADVISER_REGISTRATION_PRIMARY_STATE ... */
  INVESTMENT_ADVISER_REGISTRATION_PRIMARY_STATE = 'INVESTMENT_ADVISER_REGISTRATION_PRIMARY_STATE',
  /** INVESTMENT_ADVISOR_COUNTRY ... */
  INVESTMENT_ADVISOR_COUNTRY = 'INVESTMENT_ADVISOR_COUNTRY',
  /** INVESTMENT_ADVISOR_NAME ... */
  INVESTMENT_ADVISOR_NAME = 'INVESTMENT_ADVISOR_NAME',
  /** INVESTMENT_ADVISOR_NUMBER ... */
  INVESTMENT_ADVISOR_NUMBER = 'INVESTMENT_ADVISOR_NUMBER',
  /** KYC_INSTITUTION_FORM_SUBMISSION_ID ... */
  KYC_INSTITUTION_FORM_SUBMISSION_ID = 'KYC_INSTITUTION_FORM_SUBMISSION_ID',
  /** MAILING_ADDRESS ... */
  MAILING_ADDRESS = 'MAILING_ADDRESS',
  /** MAILING_ADDRESS2 ... */
  MAILING_ADDRESS2 = 'MAILING_ADDRESS2',
  /** PHONE ... */
  PHONE = 'PHONE',
  /** PLACE_OF_INCORPORATION ... */
  PLACE_OF_INCORPORATION = 'PLACE_OF_INCORPORATION',
  /** POOLED_VEHICLE */
  POOLED_VEHICLE = 'POOLED_VEHICLE',
  /** PRIMARY_ACCOUNT_OBJECTIVE */
  PRIMARY_ACCOUNT_OBJECTIVE = 'PRIMARY_ACCOUNT_OBJECTIVE',
  /** REGISTERED_BUSINESS_NAME ... */
  REGISTERED_BUSINESS_NAME = 'REGISTERED_BUSINESS_NAME',
  /** State */
  STATE = 'STATE',
  /** STATEMENTS_PREFERENCE ... */
  STATEMENTS_PREFERENCE = 'STATEMENTS_PREFERENCE',
  /** STREET_ADDRESS */
  STREET_ADDRESS = 'STREET_ADDRESS',
  /** Zip code */
  ZIP_CODE = 'ZIP_CODE'
}

/** Properties of a client order */
export type ClientOrder = Node & {
  __typename: 'ClientOrder';
  /** Account for this order */
  account: Maybe<TradingAccount>;
  /** Average price */
  avgPx: Scalars['String']['output'];
  /** Average all-in price */
  avgPxAllIn: Scalars['String']['output'];
  /** Client Order ID */
  clOrdID: Scalars['String']['output'];
  /** Creation Time */
  createdAt: Scalars['Timestamp']['output'];
  /**
   * Cumulative buy quantity
   * @deprecated Use cumBuyTradingQty instead
   */
  cumBuyQty: Amount;
  /** Cumulative buy quantity */
  cumBuyTradingQty: TradingAmount;
  /**
   * Cumulative sell quantity
   * @deprecated Use cumSellTradingQty instead
   */
  cumSellQty: Amount;
  /** Cumulative sell quantity */
  cumSellTradingQty: TradingAmount;
  /** Order currency */
  currency: Scalars['String']['output'];
  /** Order id */
  id: Scalars['ID']['output'];
  /** Last Transaction time */
  lastTransactTime: Scalars['Timestamp']['output'];
  /** Limit price */
  limitPrice: Maybe<Scalars['String']['output']>;
  /** Market order ID */
  marketOrderID: Maybe<Scalars['String']['output']>;
  /** Order status */
  orderStatus: OrdStatus;
  /** Order type */
  orderType: OrdType;
  /** Organization for this order */
  organization: Organization;
  /** Original client order ID */
  origClOrdID: Scalars['String']['output'];
  /**
   * Order quantity
   * @deprecated Use tradingQuantity instead
   */
  quantity: Amount;
  /** Side of the order */
  side: TradeSide;
  /** Source, e.g. API, Manual, WhiteLabel */
  sourceType: TradeSource;
  /** Subaccount ID */
  subaccountID: Maybe<Scalars['String']['output']>;
  /** Submission time */
  submitTime: Scalars['Timestamp']['output'];
  /** Symbol of the order */
  symbol: Scalars['String']['output'];
  /**
   * Total fee
   * @deprecated Use totalTradingFee instead
   */
  totalFee: Amount;
  /** Total fee */
  totalTradingFee: TradingAmount;
  /** Order quantity */
  tradingQuantity: TradingAmount;
};

/** A connection to a list of items. */
export type ClientOrderConnection = {
  __typename: 'ClientOrderConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ClientOrderEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ClientOrderEdge = {
  __typename: 'ClientOrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ClientOrder>;
};

/** Ordering options for ClientOrder connections */
export type ClientOrderOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ClientOrders. */
  field: ClientOrderOrderField;
};

/** Properties by which ClientOrder connections can be ordered. */
export enum ClientOrderOrderField {
  /** order by created_at */
  CREATED_AT = 'CREATED_AT',
  /** order by last_transact_time */
  LAST_TRANSACT_TIME = 'LAST_TRANSACT_TIME',
  /** order by submit_time */
  SUBMIT_TIME = 'SUBMIT_TIME'
}

/** ClientOrderWhereInput is used for filtering ClientOrder objects. */
export type ClientOrderWhereInput = {
  /** account_id field predicates */
  accountID?: InputMaybe<Scalars['String']['input']>;
  /** entities field predictes */
  entityIn?: InputMaybe<Array<AnchorageEntityType>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** List of IDs to filter */
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Last transact time greater than */
  lastTransactTimeGT?: InputMaybe<Scalars['Timestamp']['input']>;
  /** Last transact time less than */
  lastTransactTimeLT?: InputMaybe<Scalars['Timestamp']['input']>;
  /** order_status field predicates */
  orderStatusIn?: InputMaybe<Array<OrdStatus>>;
  /** org_key_id field predicates */
  orgKeyID?: InputMaybe<Scalars['String']['input']>;
  /** subaccount_id field predicates */
  subaccountID?: InputMaybe<Scalars['String']['input']>;
  /** symbol field predicates */
  symbolIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** ClientPaymentFlow is a payment flow */
export type ClientPaymentFlow = Node & {
  __typename: 'ClientPaymentFlow';
  /**
   * Display Amount of the payment flow, this value is rounded
   * and shouldn't be used for any calculations, use value instead
   */
  amount: Amount;
  /** Timestamp of creation of the payment flow */
  createdAt: Scalars['Timestamp']['output'];
  /** Id of the payment flow */
  id: Scalars['ID']['output'];
  /** Market Order linked to order associated with this payment */
  marketOrderID: Maybe<Scalars['String']['output']>;
  /** Order id of the payment flow */
  orderID: Scalars['String']['output'];
  /** Organization of the payment flow */
  organization: Scalars['String']['output'];
  /** Settlement ID of the payment flow */
  settlementID: Maybe<Scalars['String']['output']>;
  /** Payment status */
  status: ClientPaymentFlowStatus;
};

/** Client Payment flow connection for pagination */
export type ClientPaymentFlowConnection = {
  __typename: 'ClientPaymentFlowConnection';
  /** List of edges containing payment flow and cursor information */
  edges: Array<Maybe<ClientPaymentFlowEdge>>;
  /** Page details for the associated list of payment flow edges */
  pageInfo: PageInfo;
};

/** Payment flow edge for pagination */
export type ClientPaymentFlowEdge = {
  __typename: 'ClientPaymentFlowEdge';
  /** Cursor for the associated dealer trades */
  cursor: Scalars['String']['output'];
  /** Client Paymentflows */
  node: ClientPaymentFlow;
};

/** Filter for query client payment flow */
export type ClientPaymentFlowFilters = {
  /** Filter by assetType */
  filterAssetTypeID?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  /** Filter time after createdAt */
  filterCreatedAtAfter?: InputMaybe<Scalars['Timestamp']['input']>;
  /** Filter time before createdAt */
  filterCreatedAtBefore?: InputMaybe<Scalars['Timestamp']['input']>;
  /** Filter by Anchorage's internal identifier of a payment flow */
  filterID?: InputMaybe<Scalars['ID']['input']>;
  /** Filter by Anchorage's order identifier */
  filterOrderID?: InputMaybe<Scalars['String']['input']>;
  /** Filter by organization */
  filterOrganizationKeyID?: InputMaybe<Scalars['String']['input']>;
  /** Filter by settlement ID */
  filterSettlementID?: InputMaybe<Scalars['String']['input']>;
  /** Filter by settlment status */
  filterStatus?: InputMaybe<Array<ClientPaymentFlowStatus>>;
};

/** ClientPaymentFlowStatus is the statuses of a payment */
export enum ClientPaymentFlowStatus {
  /** Settlement has been created for this payment */
  INITIATED = 'INITIATED',
  /** Settlement is in progress (settling) */
  IN_PROGRESS = 'IN_PROGRESS',
  /** No settlement has been created for this payment */
  PENDING = 'PENDING',
  /** Settlement has been settled */
  SETTLED = 'SETTLED'
}

/** Client request type */
export enum ClientRequestType {
  /** Deposit request by lending client/member */
  DEPOSIT = 'DEPOSIT',
  /** Withdraw request by lending client/member */
  WITHDRAW = 'WITHDRAW'
}

/** Client settlement */
export type ClientSettlement = Node & {
  __typename: 'ClientSettlement';
  /** Amount of the settlement */
  amount: Amount;
  /** Assignee of the settlement */
  assignee: Maybe<Scalars['String']['output']>;
  /** Timestamp for settlement creation */
  createdAt: Scalars['Timestamp']['output'];
  /** ID of the settlement */
  id: Scalars['ID']['output'];
  /** Organization for this settlement */
  organization: Maybe<Organization>;
  /** payment count */
  paymentCount: Scalars['Int']['output'];
  /** Reason for failure or cancellation */
  reason: Maybe<Scalars['String']['output']>;
  /** Settlement status */
  status: ClientSettlementStatus;
  /** TransactionID is the manually entered transaction id for fiat or transferID for crypto */
  transactionID: Maybe<Scalars['String']['output']>;
  /** Type of the settlement */
  type: Maybe<ClientSettlementType>;
  /** UpdatedAt is when the settlement is last updated */
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
};

/** Client settlement edge */
export type ClientSettlementEdge = {
  __typename: 'ClientSettlementEdge';
  /** Cursor for the associated client settlement */
  cursor: Scalars['String']['output'];
  /** Client settlement */
  node: ClientSettlement;
};

/** Filters for client settlements */
export type ClientSettlementFilters = {
  /** Filter time after createdAt */
  filterCreatedAtAfter?: InputMaybe<Scalars['Timestamp']['input']>;
  /** Filter time before createdAt */
  filterCreatedAtBefore?: InputMaybe<Scalars['Timestamp']['input']>;
  /** Filter by settlement ID */
  filterID?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by organization */
  filterOrganizationKeyID?: InputMaybe<Scalars['String']['input']>;
  /** Filter by Organization name */
  filterOrganizationName?: InputMaybe<Scalars['String']['input']>;
  /** Filter by settlment status */
  filterStatus?: InputMaybe<Array<ClientSettlementStatus>>;
};

/** ClientSettlementStatus is the statuses of a settlement */
export enum ClientSettlementStatus {
  /** Settlement has been assigned */
  ASSIGNED = 'ASSIGNED',
  /** Settlement has been canceled */
  CANCELED = 'CANCELED',
  /** Settlement has failed */
  FAILED = 'FAILED',
  /** Settlement has been created */
  PENDING = 'PENDING',
  /** Settlement is in progress (settling) */
  PROCESSING = 'PROCESSING',
  /** Settlement has been settled */
  SETTLED = 'SETTLED'
}

/** ClientSettlementType is the type of a settlement */
export enum ClientSettlementType {
  /** Advisory fee settlement */
  ADVISORY_FEE = 'ADVISORY_FEE',
  /** Custody fee settlement */
  CUSTODY_FEE = 'CUSTODY_FEE',
  /** Management fee settlement */
  MANAGEMENT_FEE = 'MANAGEMENT_FEE',
  /** Model fee settlement */
  MODEL_FEE = 'MODEL_FEE',
  /** Trade settlement */
  TRADE = 'TRADE'
}

/** Client settlements connection */
export type ClientSettlementsConnection = {
  __typename: 'ClientSettlementsConnection';
  /** List of edges containing client settlements */
  edges: Array<Maybe<ClientSettlementEdge>>;
  /** Page details for the associated list of payment flow edges */
  pageInfo: PageInfo;
};

/** Statuses that an institutional client can go through */
export enum ClientStatus {
  /** The client has been onboarded */
  COMPLETE = 'COMPLETE',
  /** The client is in draft mode */
  DRAFT = 'DRAFT',
  /** Information is being populated. Check the individual form statuses */
  IN_PROGRESS = 'IN_PROGRESS',
  /** The client is in the process of being onboarded */
  ONBOARDING = 'ONBOARDING',
  /** The client is ready for onboarding */
  READY = 'READY'
}

/** ClosureMetadata holds metadata associated with an affiliate closure */
export type ClosureMetadata = {
  __typename: 'ClosureMetadata';
  /** Date the BSA officer recommended closure */
  bsaRecommendedDate: Maybe<Scalars['Date']['output']>;
  /** Date the affiliate was closed */
  closureDate: Scalars['Date']['output'];
  /** Indicates which team closed out the account */
  initiatedBy: Scalars['String']['output'];
  /** Risk Dashboard user who marked the account as closed */
  markedClosedBy: Scalars['String']['output'];
  /** Date of MSA termination */
  msaTerminationDate: Maybe<Scalars['Date']['output']>;
  /** Reason for closure */
  reason: Scalars['String']['output'];
};

export type CollateralAccount = Node & {
  __typename: 'CollateralAccount';
  accountMetadata: Maybe<AccountMetadata>;
  accountTypeID: CollateralAccountAccountTypeID;
  assetSpec: Maybe<AssetSpec>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inventories: Maybe<Array<CollateralInventory>>;
  location: Scalars['String']['output'];
  notes: Maybe<Scalars['String']['output']>;
  owner: Maybe<Party>;
  settlementDestinations: Maybe<Array<CollateralSettlement>>;
  settlementSources: Maybe<Array<CollateralSettlement>>;
  status: CollateralAccountStatus;
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** CollateralAccountAccountTypeID is enum for the field account_type_id */
export enum CollateralAccountAccountTypeID {
  ACCOUNT_TYPE_ID_EXTERNAL = 'ACCOUNT_TYPE_ID_EXTERNAL',
  ACCOUNT_TYPE_ID_INTERNAL = 'ACCOUNT_TYPE_ID_INTERNAL',
  ACCOUNT_TYPE_ID_UNSPECIFIED = 'ACCOUNT_TYPE_ID_UNSPECIFIED'
}

/** A connection to a list of items. */
export type CollateralAccountConnection = {
  __typename: 'CollateralAccountConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CollateralAccountEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CollateralAccountEdge = {
  __typename: 'CollateralAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CollateralAccount>;
};

/** Ordering options for CollateralAccount connections */
export type CollateralAccountOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CollateralAccounts. */
  field: CollateralAccountOrderField;
};

/** Properties by which CollateralAccount connections can be ordered. */
export enum CollateralAccountOrderField {
  ACCOUNT_TYPE_ID = 'ACCOUNT_TYPE_ID',
  CREATED_AT = 'CREATED_AT',
  STATUS = 'STATUS'
}

/** CollateralAccountStatus is enum for the field status */
export enum CollateralAccountStatus {
  ACCOUNT_STATUS_ACTIVE = 'ACCOUNT_STATUS_ACTIVE',
  ACCOUNT_STATUS_DELETED = 'ACCOUNT_STATUS_DELETED',
  ACCOUNT_STATUS_INACTIVE = 'ACCOUNT_STATUS_INACTIVE',
  ACCOUNT_STATUS_UNSPECIFIED = 'ACCOUNT_STATUS_UNSPECIFIED'
}

/**
 * CollateralAccountWhereInput is used for filtering Account objects.
 * Input was generated by ent.
 */
export type CollateralAccountWhereInput = {
  /** account_type_id field predicates */
  accountTypeID?: InputMaybe<CollateralAccountAccountTypeID>;
  accountTypeIDIn?: InputMaybe<Array<CollateralAccountAccountTypeID>>;
  accountTypeIDNEQ?: InputMaybe<CollateralAccountAccountTypeID>;
  accountTypeIDNotIn?: InputMaybe<Array<CollateralAccountAccountTypeID>>;
  and?: InputMaybe<Array<CollateralAccountWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** asset_spec edge predicates */
  hasAssetSpec?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetSpecWith?: InputMaybe<Array<AssetSpecWhereInput>>;
  /** inventories edge predicates */
  hasInventories?: InputMaybe<Scalars['Boolean']['input']>;
  hasInventoriesWith?: InputMaybe<Array<CollateralInventoryWhereInput>>;
  /** owner edge predicates */
  hasOwner?: InputMaybe<Scalars['Boolean']['input']>;
  hasOwnerWith?: InputMaybe<Array<PartyWhereInput>>;
  /** settlement_destinations edge predicates */
  hasSettlementDestinations?: InputMaybe<Scalars['Boolean']['input']>;
  hasSettlementDestinationsWith?: InputMaybe<Array<CollateralSettlementWhereInput>>;
  /** settlement_sources edge predicates */
  hasSettlementSources?: InputMaybe<Scalars['Boolean']['input']>;
  hasSettlementSourcesWith?: InputMaybe<Array<CollateralSettlementWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** location field predicates */
  location?: InputMaybe<Scalars['String']['input']>;
  locationContains?: InputMaybe<Scalars['String']['input']>;
  locationContainsFold?: InputMaybe<Scalars['String']['input']>;
  locationEqualFold?: InputMaybe<Scalars['String']['input']>;
  locationGT?: InputMaybe<Scalars['String']['input']>;
  locationGTE?: InputMaybe<Scalars['String']['input']>;
  locationHasPrefix?: InputMaybe<Scalars['String']['input']>;
  locationHasSuffix?: InputMaybe<Scalars['String']['input']>;
  locationIn?: InputMaybe<Array<Scalars['String']['input']>>;
  locationLT?: InputMaybe<Scalars['String']['input']>;
  locationLTE?: InputMaybe<Scalars['String']['input']>;
  locationNEQ?: InputMaybe<Scalars['String']['input']>;
  locationNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<CollateralAccountWhereInput>;
  /** notes field predicates */
  notes?: InputMaybe<Scalars['String']['input']>;
  notesContains?: InputMaybe<Scalars['String']['input']>;
  notesContainsFold?: InputMaybe<Scalars['String']['input']>;
  notesEqualFold?: InputMaybe<Scalars['String']['input']>;
  notesGT?: InputMaybe<Scalars['String']['input']>;
  notesGTE?: InputMaybe<Scalars['String']['input']>;
  notesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  notesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  notesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  notesLT?: InputMaybe<Scalars['String']['input']>;
  notesLTE?: InputMaybe<Scalars['String']['input']>;
  notesNEQ?: InputMaybe<Scalars['String']['input']>;
  notesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<CollateralAccountWhereInput>>;
  /** status field predicates */
  status?: InputMaybe<CollateralAccountStatus>;
  statusIn?: InputMaybe<Array<CollateralAccountStatus>>;
  statusNEQ?: InputMaybe<CollateralAccountStatus>;
  statusNotIn?: InputMaybe<Array<CollateralAccountStatus>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CollateralAgreement = Node & {
  __typename: 'CollateralAgreement';
  agreementNumber: Scalars['String']['output'];
  agreementType: CollateralAgreementAgreementType;
  assetLent: Maybe<AssetSpec>;
  assetRefDataIssuerID: Maybe<DataSource>;
  ccrValuations: Maybe<CCRValuations>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['String']['output'];
  eligibilityProofs: Maybe<Array<EligibilityProof>>;
  eligibilitySchedules: Maybe<Array<EligibilitySchedule>>;
  exposures: Maybe<Array<Exposure>>;
  id: Scalars['ID']['output'];
  inventories: Maybe<Array<CollateralInventory>>;
  liquidationInstructions: Maybe<CollateralAgreementLiquidationInstructions>;
  liquidationLevel: Maybe<Scalars['Percent']['output']>;
  loanAmountAtOrigination: Maybe<Amount>;
  marginCallCurePeriod: Maybe<Scalars['Decimal']['output']>;
  marginCallLevel: Maybe<Scalars['Percent']['output']>;
  marginLevel: CollateralAgreementMarginLevel;
  metadata: Maybe<CollateralAgreementMetadata>;
  minimalThresholdAmount: Maybe<Scalars['Decimal']['output']>;
  monitored: Scalars['Boolean']['output'];
  notes: Scalars['String']['output'];
  originationDate: Maybe<Scalars['Timestamp']['output']>;
  pledger: Maybe<Party>;
  preMarginCallLevel: Maybe<Scalars['Percent']['output']>;
  priceRefDataIssuer1ID: Maybe<DataSource>;
  priceRefDataIssuer2ID: Maybe<DataSource>;
  priceRefDataIssuer3ID: Maybe<DataSource>;
  priceRefDataIssuer4ID: Maybe<DataSource>;
  priceRefDataIssuerID: Maybe<DataSource>;
  recoveryLevel: Maybe<Scalars['Percent']['output']>;
  referencePriceAlgo: CollateralAgreementReferencePriceAlgo;
  rehypothecation: Scalars['Boolean']['output'];
  requiredCoverageLevel: Maybe<Scalars['Percent']['output']>;
  reverseCallLevel: Maybe<Scalars['Percent']['output']>;
  secured: Scalars['Boolean']['output'];
  securedParty: Maybe<Party>;
  spotPriceAtOrigination: Maybe<Amount>;
  status: CollateralAgreementStatus;
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** CollateralAgreementAgreementType is enum for the field agreement_type */
export enum CollateralAgreementAgreementType {
  COLLATERAL_AGREEMENT_TYPE_MLSA = 'COLLATERAL_AGREEMENT_TYPE_MLSA',
  COLLATERAL_AGREEMENT_TYPE_UNSPECIFIED = 'COLLATERAL_AGREEMENT_TYPE_UNSPECIFIED'
}

/** A connection to a list of items. */
export type CollateralAgreementConnection = {
  __typename: 'CollateralAgreementConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CollateralAgreementEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CollateralAgreementEdge = {
  __typename: 'CollateralAgreementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CollateralAgreement>;
};

/** CollateralAgreementLiquidationInstructions maintains fields for the collateral liquidation instructions */
export type CollateralAgreementLiquidationInstructions = {
  __typename: 'CollateralAgreementLiquidationInstructions';
  enableAutomaticLiquidation: Scalars['Boolean']['output'];
  instructionType: LiquidationInstructionType;
  notes: Maybe<Scalars['String']['output']>;
};

/** CollateralAgreementLiquidationInstructionsInput for the collateral liquidation instructions fields */
export type CollateralAgreementLiquidationInstructionsInput = {
  enableAutomaticLiquidation: Scalars['Boolean']['input'];
  instructionType: LiquidationInstructionType;
  notes?: InputMaybe<Scalars['String']['input']>;
};

/** CollateralAgreementMarginLevel is enum for the field margin_level */
export enum CollateralAgreementMarginLevel {
  COLLATERAL_AGREEMENT_MARGIN_LEVEL_POOL = 'COLLATERAL_AGREEMENT_MARGIN_LEVEL_POOL',
  COLLATERAL_AGREEMENT_MARGIN_LEVEL_TRADE = 'COLLATERAL_AGREEMENT_MARGIN_LEVEL_TRADE',
  COLLATERAL_AGREEMENT_MARGIN_LEVEL_UNSPECIFIED = 'COLLATERAL_AGREEMENT_MARGIN_LEVEL_UNSPECIFIED'
}

/** CollateralAgreementMetadataLending maintains additional fields for the collateral agreement */
export type CollateralAgreementMetadata = {
  __typename: 'CollateralAgreementMetadata';
  lendingMetadata: Maybe<CollateralAgreementMetadataLending>;
};

/** CollateralAgreementMetadataLending maintains additional fields for the collateral agreement initialted by the loan manager */
export type CollateralAgreementMetadataLending = {
  __typename: 'CollateralAgreementMetadataLending';
  associatedLoanID: Scalars['String']['output'];
  entitySymbol: Maybe<Scalars['String']['output']>;
  loanNumber: Scalars['String']['output'];
  refi: Scalars['Boolean']['output'];
  refiPreviousLoanID: Maybe<Scalars['String']['output']>;
  sourceLendingFacilityId: Scalars['String']['output'];
};

/** Ordering options for CollateralAgreement connections */
export type CollateralAgreementOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CollateralAgreements. */
  field: CollateralAgreementOrderField;
};

/** Properties by which CollateralAgreement connections can be ordered. */
export enum CollateralAgreementOrderField {
  AGREEMENT_TYPE = 'AGREEMENT_TYPE',
  CREATED_AT = 'CREATED_AT',
  MARGIN_LEVEL = 'MARGIN_LEVEL',
  ORIGINATION_DATE = 'ORIGINATION_DATE',
  REFERENCE_PRICE_ALGO = 'REFERENCE_PRICE_ALGO',
  STATUS = 'STATUS'
}

/** CollateralAgreementReferencePriceAlgo is enum for the field reference_price_algo */
export enum CollateralAgreementReferencePriceAlgo {
  COLLATERAL_AGREEMENT_REF_PRICE_ALGO_DIRECT = 'COLLATERAL_AGREEMENT_REF_PRICE_ALGO_DIRECT',
  COLLATERAL_AGREEMENT_REF_PRICE_ALGO_UNSPECIFIED = 'COLLATERAL_AGREEMENT_REF_PRICE_ALGO_UNSPECIFIED',
  COLLATERAL_AGREEMENT_REF_PRICE_ALGO_WATERFALL = 'COLLATERAL_AGREEMENT_REF_PRICE_ALGO_WATERFALL'
}

/** CollateralAgreementStatus is enum for the field status */
export enum CollateralAgreementStatus {
  COLLATERAL_AGREEMENT_STATUS_ACTIVE = 'COLLATERAL_AGREEMENT_STATUS_ACTIVE',
  COLLATERAL_AGREEMENT_STATUS_CANCELED = 'COLLATERAL_AGREEMENT_STATUS_CANCELED',
  COLLATERAL_AGREEMENT_STATUS_DELETED = 'COLLATERAL_AGREEMENT_STATUS_DELETED',
  COLLATERAL_AGREEMENT_STATUS_FINAL_MARGIN = 'COLLATERAL_AGREEMENT_STATUS_FINAL_MARGIN',
  COLLATERAL_AGREEMENT_STATUS_INACTIVE = 'COLLATERAL_AGREEMENT_STATUS_INACTIVE',
  COLLATERAL_AGREEMENT_STATUS_INITIATED = 'COLLATERAL_AGREEMENT_STATUS_INITIATED',
  COLLATERAL_AGREEMENT_STATUS_UNSPECIFIED = 'COLLATERAL_AGREEMENT_STATUS_UNSPECIFIED'
}

/**
 * CollateralAgreementWhereInput is used for filtering CollateralAgreement objects.
 * Input was generated by ent.
 */
export type CollateralAgreementWhereInput = {
  /** agreement_number field predicates */
  agreementNumber?: InputMaybe<Scalars['String']['input']>;
  agreementNumberContains?: InputMaybe<Scalars['String']['input']>;
  agreementNumberContainsFold?: InputMaybe<Scalars['String']['input']>;
  agreementNumberEqualFold?: InputMaybe<Scalars['String']['input']>;
  agreementNumberGT?: InputMaybe<Scalars['String']['input']>;
  agreementNumberGTE?: InputMaybe<Scalars['String']['input']>;
  agreementNumberHasPrefix?: InputMaybe<Scalars['String']['input']>;
  agreementNumberHasSuffix?: InputMaybe<Scalars['String']['input']>;
  agreementNumberIn?: InputMaybe<Array<Scalars['String']['input']>>;
  agreementNumberLT?: InputMaybe<Scalars['String']['input']>;
  agreementNumberLTE?: InputMaybe<Scalars['String']['input']>;
  agreementNumberNEQ?: InputMaybe<Scalars['String']['input']>;
  agreementNumberNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** agreement_type field predicates */
  agreementType?: InputMaybe<CollateralAgreementAgreementType>;
  agreementTypeIn?: InputMaybe<Array<CollateralAgreementAgreementType>>;
  agreementTypeNEQ?: InputMaybe<CollateralAgreementAgreementType>;
  agreementTypeNotIn?: InputMaybe<Array<CollateralAgreementAgreementType>>;
  and?: InputMaybe<Array<CollateralAgreementWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** asset_lent edge predicates */
  hasAssetLent?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetLentWith?: InputMaybe<Array<AssetSpecWhereInput>>;
  /** asset_ref_data_issuer_id_fk edge predicates */
  hasAssetRefDataIssuerID?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetRefDataIssuerIDWith?: InputMaybe<Array<DataSourceWhereInput>>;
  /** ccr_valuations edge predicates */
  hasCcrValuations?: InputMaybe<Scalars['Boolean']['input']>;
  hasCcrValuationsWith?: InputMaybe<Array<CCRValuationsWhereInput>>;
  /** eligibility_proofs edge predicates */
  hasEligibilityProofs?: InputMaybe<Scalars['Boolean']['input']>;
  hasEligibilityProofsWith?: InputMaybe<Array<EligibilityProofWhereInput>>;
  /** eligibility_schedules edge predicates */
  hasEligibilitySchedules?: InputMaybe<Scalars['Boolean']['input']>;
  hasEligibilitySchedulesWith?: InputMaybe<Array<EligibilityScheduleWhereInput>>;
  /** exposures edge predicates */
  hasExposures?: InputMaybe<Scalars['Boolean']['input']>;
  hasExposuresWith?: InputMaybe<Array<ExposureWhereInput>>;
  /** inventories edge predicates */
  hasInventories?: InputMaybe<Scalars['Boolean']['input']>;
  hasInventoriesWith?: InputMaybe<Array<CollateralInventoryWhereInput>>;
  /** pledger_id_fk edge predicates */
  hasPledgerIDFk?: InputMaybe<Scalars['Boolean']['input']>;
  hasPledgerIDFkWith?: InputMaybe<Array<PartyWhereInput>>;
  /** price_ref_data_issuer_1_id_fk edge predicates */
  hasPriceRefDataIssuer1ID?: InputMaybe<Scalars['Boolean']['input']>;
  hasPriceRefDataIssuer1IDWith?: InputMaybe<Array<DataSourceWhereInput>>;
  /** price_ref_data_issuer_2_id_fk edge predicates */
  hasPriceRefDataIssuer2ID?: InputMaybe<Scalars['Boolean']['input']>;
  hasPriceRefDataIssuer2IDWith?: InputMaybe<Array<DataSourceWhereInput>>;
  /** price_ref_data_issuer_3_id_fk edge predicates */
  hasPriceRefDataIssuer3ID?: InputMaybe<Scalars['Boolean']['input']>;
  hasPriceRefDataIssuer3IDWith?: InputMaybe<Array<DataSourceWhereInput>>;
  /** price_ref_data_issuer_4_id_fk edge predicates */
  hasPriceRefDataIssuer4ID?: InputMaybe<Scalars['Boolean']['input']>;
  hasPriceRefDataIssuer4IDWith?: InputMaybe<Array<DataSourceWhereInput>>;
  /** price_ref_data_issuer_id_fk edge predicates */
  hasPriceRefDataIssuerID?: InputMaybe<Scalars['Boolean']['input']>;
  hasPriceRefDataIssuerIDWith?: InputMaybe<Array<DataSourceWhereInput>>;
  /** secured_party_id_fk edge predicates */
  hasSecuredPartyIDFk?: InputMaybe<Scalars['Boolean']['input']>;
  hasSecuredPartyIDFkWith?: InputMaybe<Array<PartyWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** liquidation_level field predicates */
  liquidationLevel?: InputMaybe<Scalars['Percent']['input']>;
  liquidationLevelGT?: InputMaybe<Scalars['Percent']['input']>;
  liquidationLevelGTE?: InputMaybe<Scalars['Percent']['input']>;
  liquidationLevelIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  liquidationLevelIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  liquidationLevelLT?: InputMaybe<Scalars['Percent']['input']>;
  liquidationLevelLTE?: InputMaybe<Scalars['Percent']['input']>;
  liquidationLevelNEQ?: InputMaybe<Scalars['Percent']['input']>;
  liquidationLevelNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  liquidationLevelNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** loan_amount_at_origination field predicates */
  loanAmountAtOrigination?: InputMaybe<Scalars['Decimal']['input']>;
  loanAmountAtOriginationGT?: InputMaybe<Scalars['Decimal']['input']>;
  loanAmountAtOriginationGTE?: InputMaybe<Scalars['Decimal']['input']>;
  loanAmountAtOriginationIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  loanAmountAtOriginationIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  loanAmountAtOriginationLT?: InputMaybe<Scalars['Decimal']['input']>;
  loanAmountAtOriginationLTE?: InputMaybe<Scalars['Decimal']['input']>;
  loanAmountAtOriginationNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  loanAmountAtOriginationNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  loanAmountAtOriginationNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** margin_call_cure_period field predicates */
  marginCallCurePeriod?: InputMaybe<Scalars['Decimal']['input']>;
  marginCallCurePeriodGT?: InputMaybe<Scalars['Decimal']['input']>;
  marginCallCurePeriodGTE?: InputMaybe<Scalars['Decimal']['input']>;
  marginCallCurePeriodIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  marginCallCurePeriodIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  marginCallCurePeriodLT?: InputMaybe<Scalars['Decimal']['input']>;
  marginCallCurePeriodLTE?: InputMaybe<Scalars['Decimal']['input']>;
  marginCallCurePeriodNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  marginCallCurePeriodNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  marginCallCurePeriodNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** margin_call_level field predicates */
  marginCallLevel?: InputMaybe<Scalars['Percent']['input']>;
  marginCallLevelGT?: InputMaybe<Scalars['Percent']['input']>;
  marginCallLevelGTE?: InputMaybe<Scalars['Percent']['input']>;
  marginCallLevelIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  marginCallLevelIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  marginCallLevelLT?: InputMaybe<Scalars['Percent']['input']>;
  marginCallLevelLTE?: InputMaybe<Scalars['Percent']['input']>;
  marginCallLevelNEQ?: InputMaybe<Scalars['Percent']['input']>;
  marginCallLevelNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  marginCallLevelNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** margin_level field predicates */
  marginLevel?: InputMaybe<CollateralAgreementMarginLevel>;
  marginLevelIn?: InputMaybe<Array<CollateralAgreementMarginLevel>>;
  marginLevelNEQ?: InputMaybe<CollateralAgreementMarginLevel>;
  marginLevelNotIn?: InputMaybe<Array<CollateralAgreementMarginLevel>>;
  /** minimal_threshold_amount field predicates */
  minimalThresholdAmount?: InputMaybe<Scalars['Decimal']['input']>;
  minimalThresholdAmountGT?: InputMaybe<Scalars['Decimal']['input']>;
  minimalThresholdAmountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  minimalThresholdAmountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  minimalThresholdAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  minimalThresholdAmountLT?: InputMaybe<Scalars['Decimal']['input']>;
  minimalThresholdAmountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  minimalThresholdAmountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  minimalThresholdAmountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  minimalThresholdAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** monitored field predicates */
  monitored?: InputMaybe<Scalars['Boolean']['input']>;
  monitoredNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<CollateralAgreementWhereInput>;
  /** notes field predicates */
  notes?: InputMaybe<Scalars['String']['input']>;
  notesContains?: InputMaybe<Scalars['String']['input']>;
  notesContainsFold?: InputMaybe<Scalars['String']['input']>;
  notesEqualFold?: InputMaybe<Scalars['String']['input']>;
  notesGT?: InputMaybe<Scalars['String']['input']>;
  notesGTE?: InputMaybe<Scalars['String']['input']>;
  notesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  notesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  notesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesLT?: InputMaybe<Scalars['String']['input']>;
  notesLTE?: InputMaybe<Scalars['String']['input']>;
  notesNEQ?: InputMaybe<Scalars['String']['input']>;
  notesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<CollateralAgreementWhereInput>>;
  /** origination_date field predicates */
  originationDate?: InputMaybe<Scalars['Timestamp']['input']>;
  originationDateGT?: InputMaybe<Scalars['Timestamp']['input']>;
  originationDateGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  originationDateIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  originationDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  originationDateLT?: InputMaybe<Scalars['Timestamp']['input']>;
  originationDateLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  originationDateNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  originationDateNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  originationDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** pre_margin_call_level field predicates */
  preMarginCallLevel?: InputMaybe<Scalars['Percent']['input']>;
  preMarginCallLevelGT?: InputMaybe<Scalars['Percent']['input']>;
  preMarginCallLevelGTE?: InputMaybe<Scalars['Percent']['input']>;
  preMarginCallLevelIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preMarginCallLevelIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  preMarginCallLevelLT?: InputMaybe<Scalars['Percent']['input']>;
  preMarginCallLevelLTE?: InputMaybe<Scalars['Percent']['input']>;
  preMarginCallLevelNEQ?: InputMaybe<Scalars['Percent']['input']>;
  preMarginCallLevelNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preMarginCallLevelNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** recovery_level field predicates */
  recoveryLevel?: InputMaybe<Scalars['Percent']['input']>;
  recoveryLevelGT?: InputMaybe<Scalars['Percent']['input']>;
  recoveryLevelGTE?: InputMaybe<Scalars['Percent']['input']>;
  recoveryLevelIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  recoveryLevelIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  recoveryLevelLT?: InputMaybe<Scalars['Percent']['input']>;
  recoveryLevelLTE?: InputMaybe<Scalars['Percent']['input']>;
  recoveryLevelNEQ?: InputMaybe<Scalars['Percent']['input']>;
  recoveryLevelNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  recoveryLevelNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** reference_price_algo field predicates */
  referencePriceAlgo?: InputMaybe<CollateralAgreementReferencePriceAlgo>;
  referencePriceAlgoIn?: InputMaybe<Array<CollateralAgreementReferencePriceAlgo>>;
  referencePriceAlgoNEQ?: InputMaybe<CollateralAgreementReferencePriceAlgo>;
  referencePriceAlgoNotIn?: InputMaybe<Array<CollateralAgreementReferencePriceAlgo>>;
  /** rehypothecation field predicates */
  rehypothecation?: InputMaybe<Scalars['Boolean']['input']>;
  rehypothecationNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** required_coverage_level field predicates */
  requiredCoverageLevel?: InputMaybe<Scalars['Percent']['input']>;
  requiredCoverageLevelGT?: InputMaybe<Scalars['Percent']['input']>;
  requiredCoverageLevelGTE?: InputMaybe<Scalars['Percent']['input']>;
  requiredCoverageLevelIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  requiredCoverageLevelIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requiredCoverageLevelLT?: InputMaybe<Scalars['Percent']['input']>;
  requiredCoverageLevelLTE?: InputMaybe<Scalars['Percent']['input']>;
  requiredCoverageLevelNEQ?: InputMaybe<Scalars['Percent']['input']>;
  requiredCoverageLevelNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  requiredCoverageLevelNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** reverse_call_level field predicates */
  reverseCallLevel?: InputMaybe<Scalars['Percent']['input']>;
  reverseCallLevelGT?: InputMaybe<Scalars['Percent']['input']>;
  reverseCallLevelGTE?: InputMaybe<Scalars['Percent']['input']>;
  reverseCallLevelIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  reverseCallLevelIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reverseCallLevelLT?: InputMaybe<Scalars['Percent']['input']>;
  reverseCallLevelLTE?: InputMaybe<Scalars['Percent']['input']>;
  reverseCallLevelNEQ?: InputMaybe<Scalars['Percent']['input']>;
  reverseCallLevelNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  reverseCallLevelNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** secured field predicates */
  secured?: InputMaybe<Scalars['Boolean']['input']>;
  securedNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** spot_price_at_origination field predicates */
  spotPriceAtOrigination?: InputMaybe<Scalars['Decimal']['input']>;
  spotPriceAtOriginationGT?: InputMaybe<Scalars['Decimal']['input']>;
  spotPriceAtOriginationGTE?: InputMaybe<Scalars['Decimal']['input']>;
  spotPriceAtOriginationIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  spotPriceAtOriginationIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  spotPriceAtOriginationLT?: InputMaybe<Scalars['Decimal']['input']>;
  spotPriceAtOriginationLTE?: InputMaybe<Scalars['Decimal']['input']>;
  spotPriceAtOriginationNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  spotPriceAtOriginationNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  spotPriceAtOriginationNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** status field predicates */
  status?: InputMaybe<CollateralAgreementStatus>;
  statusIn?: InputMaybe<Array<CollateralAgreementStatus>>;
  statusNEQ?: InputMaybe<CollateralAgreementStatus>;
  statusNotIn?: InputMaybe<Array<CollateralAgreementStatus>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CollateralCall = Node & {
  __typename: 'CollateralCall';
  callSubject: Maybe<CallSubject>;
  callType: CollateralCallCallType;
  caller: Maybe<Party>;
  clientAckTime: Maybe<Scalars['Timestamp']['output']>;
  clientNotifiedTime: Maybe<Scalars['Timestamp']['output']>;
  counterParty: Maybe<Party>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['String']['output'];
  /** effectiveValues represents the struct with collateral fields that changes with price movement */
  effectiveValues: Maybe<CollateralCallEffectiveValues>;
  eligibilityProof: Maybe<EligibilityProof>;
  id: Scalars['ID']['output'];
  inventories: Maybe<Array<CollateralInventory>>;
  /** parentCall represents the parent call ticket, null if does not exist */
  parentCall: Maybe<CollateralCall>;
  settlementReason: Maybe<CollateralCallSettlementReason>;
  settlementTime: Maybe<Scalars['Timestamp']['output']>;
  status: CollateralCallStatus;
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** CollateralCallCallType is enum for the field call_type */
export enum CollateralCallCallType {
  CALL_TYPE_FINAL_MARGIN = 'CALL_TYPE_FINAL_MARGIN',
  CALL_TYPE_INITIAL_MARGIN = 'CALL_TYPE_INITIAL_MARGIN',
  CALL_TYPE_LIQUIDATION = 'CALL_TYPE_LIQUIDATION',
  CALL_TYPE_MARGIN_CALL = 'CALL_TYPE_MARGIN_CALL',
  CALL_TYPE_PRE_LIQUIDATION = 'CALL_TYPE_PRE_LIQUIDATION',
  CALL_TYPE_PRE_MARGIN_CALL = 'CALL_TYPE_PRE_MARGIN_CALL',
  CALL_TYPE_PRE_RETURN = 'CALL_TYPE_PRE_RETURN',
  CALL_TYPE_RETURN = 'CALL_TYPE_RETURN',
  CALL_TYPE_SUBSTITUTION = 'CALL_TYPE_SUBSTITUTION',
  CALL_TYPE_UNSPECIFIED = 'CALL_TYPE_UNSPECIFIED'
}

/** A connection to a list of items. */
export type CollateralCallConnection = {
  __typename: 'CollateralCallConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CollateralCallEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CollateralCallEdge = {
  __typename: 'CollateralCallEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CollateralCall>;
};

/** dynamically calculated collateral call values based on the current USD prices and time */
export type CollateralCallEffectiveValues = {
  __typename: 'CollateralCallEffectiveValues';
  /** time in seconds */
  curePeriodTimeLeft: Maybe<Scalars['Int']['output']>;
  currentCCR: Maybe<Scalars['Percent']['output']>;
  currentCollateral: Maybe<Amount>;
  currentExposure: Maybe<Amount>;
  payDownAmount: Maybe<Amount>;
  payDownAmountBreakdown: Maybe<Array<Maybe<Amount>>>;
  requiredCollateral: Maybe<Amount>;
  targetCollateral: Maybe<Amount>;
};

/** Ordering options for CollateralCall connections */
export type CollateralCallOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Calls. */
  field: CollateralCallOrderField;
};

/** Properties by which CollateralCall connections can be ordered. */
export enum CollateralCallOrderField {
  CALL_TYPE = 'CALL_TYPE',
  CREATED_AT = 'CREATED_AT',
  SETTLEMENT_REASON = 'SETTLEMENT_REASON',
  STATUS = 'STATUS'
}

/** CollateralCallSettlementReason is enum for the field settlement_reason */
export enum CollateralCallSettlementReason {
  SETTLEMENT_REASON_MARKET_MOVE = 'SETTLEMENT_REASON_MARKET_MOVE',
  SETTLEMENT_REASON_NONE = 'SETTLEMENT_REASON_NONE',
  SETTLEMENT_REASON_NOT_SETTLED = 'SETTLEMENT_REASON_NOT_SETTLED',
  SETTLEMENT_REASON_OTHER = 'SETTLEMENT_REASON_OTHER',
  SETTLEMENT_REASON_PAY_DOWN = 'SETTLEMENT_REASON_PAY_DOWN',
  SETTLEMENT_REASON_TOP_UP = 'SETTLEMENT_REASON_TOP_UP',
  SETTLEMENT_REASON_UNSPECIFIED = 'SETTLEMENT_REASON_UNSPECIFIED'
}

/** CollateralCallStatus is enum for the field status */
export enum CollateralCallStatus {
  CALL_STATUS_AGREED = 'CALL_STATUS_AGREED',
  CALL_STATUS_CANCELED = 'CALL_STATUS_CANCELED',
  CALL_STATUS_DELETED = 'CALL_STATUS_DELETED',
  CALL_STATUS_DISPUTED = 'CALL_STATUS_DISPUTED',
  CALL_STATUS_OUTSTANDING = 'CALL_STATUS_OUTSTANDING',
  CALL_STATUS_SETTLED = 'CALL_STATUS_SETTLED',
  CALL_STATUS_SETTLING = 'CALL_STATUS_SETTLING',
  CALL_STATUS_UNSPECIFIED = 'CALL_STATUS_UNSPECIFIED'
}

/** CollateralCallSubjectInput is used for Collateral Call Subject mutations */
export type CollateralCallSubjectInput = {
  amount: Scalars['Decimal']['input'];
  deliveryAccountID?: InputMaybe<Scalars['String']['input']>;
  deliveryAsset?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  returnAccountID?: InputMaybe<Scalars['String']['input']>;
  returnAsset?: InputMaybe<Scalars['String']['input']>;
  time: Scalars['Timestamp']['input'];
};

/**
 * CallWhereInput is used for filtering Call objects.
 * Input was generated by ent.
 */
export type CollateralCallWhereInput = {
  and?: InputMaybe<Array<CollateralCallWhereInput>>;
  /** call_type field predicates */
  callType?: InputMaybe<CollateralCallCallType>;
  callTypeIn?: InputMaybe<Array<CollateralCallCallType>>;
  callTypeNEQ?: InputMaybe<CollateralCallCallType>;
  callTypeNotIn?: InputMaybe<Array<CollateralCallCallType>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** caller edge predicates */
  hasCaller?: InputMaybe<Scalars['Boolean']['input']>;
  hasCallerWith?: InputMaybe<Array<PartyWhereInput>>;
  /** counter_party edge predicates */
  hasCounterParty?: InputMaybe<Scalars['Boolean']['input']>;
  hasCounterPartyWith?: InputMaybe<Array<PartyWhereInput>>;
  /** eligibility_proof edge predicates */
  hasEligibilityProof?: InputMaybe<Scalars['Boolean']['input']>;
  hasEligibilityProofWith?: InputMaybe<Array<EligibilityProofWhereInput>>;
  /** inventories edge predicates */
  hasInventories?: InputMaybe<Scalars['Boolean']['input']>;
  hasInventoriesWith?: InputMaybe<Array<CollateralInventoryWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<CollateralCallWhereInput>;
  or?: InputMaybe<Array<CollateralCallWhereInput>>;
  /** settlement_reason field predicates */
  settlementReason?: InputMaybe<CollateralCallSettlementReason>;
  settlementReasonIn?: InputMaybe<Array<CollateralCallSettlementReason>>;
  settlementReasonNEQ?: InputMaybe<CollateralCallSettlementReason>;
  settlementReasonNotIn?: InputMaybe<Array<CollateralCallSettlementReason>>;
  /** status field predicates */
  status?: InputMaybe<CollateralCallStatus>;
  statusIn?: InputMaybe<Array<CollateralCallStatus>>;
  statusNEQ?: InputMaybe<CollateralCallStatus>;
  statusNotIn?: InputMaybe<Array<CollateralCallStatus>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CollateralFee = Node & {
  __typename: 'CollateralFee';
  assetSpec: Maybe<AssetSpec>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  metadata: Maybe<CollateralFeeMetadata>;
  notes: Maybe<Scalars['String']['output']>;
  operationID: Scalars['String']['output'];
  quantity: Maybe<Amount>;
  status: CollateralFeeStatus;
  transactionTime: Scalars['Timestamp']['output'];
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type CollateralFeeConnection = {
  __typename: 'CollateralFeeConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CollateralFeeEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CollateralFeeEdge = {
  __typename: 'CollateralFeeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CollateralFee>;
};

export type CollateralFeeMetadata = {
  __typename: 'CollateralFeeMetadata';
  vaultSubID: Maybe<Scalars['Int']['output']>;
  walletID: Maybe<Scalars['String']['output']>;
};

/** CollateralFeeMetadataInput input for the metadata of collateral fee */
export type CollateralFeeMetadataInput = {
  vaultSubID?: InputMaybe<Scalars['Int']['input']>;
  walletID?: InputMaybe<Scalars['String']['input']>;
};

/** Ordering options for CollateralFee connections */
export type CollateralFeeOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CollateralFees. */
  field: CollateralFeeOrderField;
};

/** Properties by which CollateralFee connections can be ordered. */
export enum CollateralFeeOrderField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  STATUS = 'STATUS',
  TRANSACTION_TIME = 'TRANSACTION_TIME'
}

/** CollateralFeeStatus is enum for the field status */
export enum CollateralFeeStatus {
  COLLATERAL_FEE_STATUS_ACTIVE = 'COLLATERAL_FEE_STATUS_ACTIVE',
  COLLATERAL_FEE_STATUS_DELETED = 'COLLATERAL_FEE_STATUS_DELETED',
  COLLATERAL_FEE_STATUS_INACTIVE = 'COLLATERAL_FEE_STATUS_INACTIVE',
  COLLATERAL_FEE_STATUS_UNSPECIFIED = 'COLLATERAL_FEE_STATUS_UNSPECIFIED'
}

/** CollateralFeeWhereInput is used for filtering CollateralFee objects. */
export type CollateralFeeWhereInput = {
  and?: InputMaybe<Array<CollateralFeeWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** asset_spec edge predicates */
  hasAssetSpec?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetSpecWith?: InputMaybe<Array<AssetSpecWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<CollateralFeeWhereInput>;
  /** notes field predicates */
  notes?: InputMaybe<Scalars['String']['input']>;
  notesContains?: InputMaybe<Scalars['String']['input']>;
  notesContainsFold?: InputMaybe<Scalars['String']['input']>;
  notesEqualFold?: InputMaybe<Scalars['String']['input']>;
  notesGT?: InputMaybe<Scalars['String']['input']>;
  notesGTE?: InputMaybe<Scalars['String']['input']>;
  notesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  notesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  notesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  notesLT?: InputMaybe<Scalars['String']['input']>;
  notesLTE?: InputMaybe<Scalars['String']['input']>;
  notesNEQ?: InputMaybe<Scalars['String']['input']>;
  notesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** operation_id field predicates */
  operationID?: InputMaybe<Scalars['String']['input']>;
  operationIDContains?: InputMaybe<Scalars['String']['input']>;
  operationIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  operationIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  operationIDGT?: InputMaybe<Scalars['String']['input']>;
  operationIDGTE?: InputMaybe<Scalars['String']['input']>;
  operationIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  operationIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  operationIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  operationIDLT?: InputMaybe<Scalars['String']['input']>;
  operationIDLTE?: InputMaybe<Scalars['String']['input']>;
  operationIDNEQ?: InputMaybe<Scalars['String']['input']>;
  operationIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<CollateralFeeWhereInput>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  quantityGT?: InputMaybe<Scalars['Decimal']['input']>;
  quantityGTE?: InputMaybe<Scalars['Decimal']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  quantityLT?: InputMaybe<Scalars['Decimal']['input']>;
  quantityLTE?: InputMaybe<Scalars['Decimal']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** status field predicates */
  status?: InputMaybe<CollateralFeeStatus>;
  statusIn?: InputMaybe<Array<CollateralFeeStatus>>;
  statusNEQ?: InputMaybe<CollateralFeeStatus>;
  statusNotIn?: InputMaybe<Array<CollateralFeeStatus>>;
  /** transaction_time field predicates */
  transactionTime?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionTimeGT?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionTimeGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionTimeIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  transactionTimeLT?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionTimeLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionTimeNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionTimeNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CollateralInventory = Node & {
  __typename: 'CollateralInventory';
  agreement: Maybe<CollateralAgreement>;
  assetSpec: Maybe<AssetSpec>;
  collateralAccount: Maybe<CollateralAccount>;
  collateralCall: Maybe<CollateralCall>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  metadata: Maybe<InventoryMetadata>;
  notes: Scalars['String']['output'];
  owner: Maybe<Party>;
  quantity: Scalars['Decimal']['output'];
  status: CollateralInventoryStatus;
  transactionTime: Scalars['Timestamp']['output'];
  transactionType: CollateralInventoryTransactionType;
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type CollateralInventoryConnection = {
  __typename: 'CollateralInventoryConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CollateralInventoryEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CollateralInventoryEdge = {
  __typename: 'CollateralInventoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CollateralInventory>;
};

/** CollateralInventoryFilterByInput is used for filtering CollateralInventory objects for non-ent generated predicates. */
export type CollateralInventoryFilterByInput = {
  /** collateral agreement custom predicates */
  collateralAgreementLendingEntitySymbolEQ?: InputMaybe<Scalars['String']['input']>;
};

/** Ordering options for CollateralInventory connections */
export type CollateralInventoryOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CollateralInventories. */
  field: CollateralInventoryOrderField;
};

/** Properties by which CollateralInventory connections can be ordered. */
export enum CollateralInventoryOrderField {
  CREATED_AT = 'CREATED_AT',
  STATUS = 'STATUS',
  TRANSACTION_TIME = 'TRANSACTION_TIME',
  TRANSACTION_TYPE = 'TRANSACTION_TYPE'
}

/** CollateralInventoryStatus is enum for the field status */
export enum CollateralInventoryStatus {
  COLLATERAL_INVENTORY_STATUS_ACTIVE = 'COLLATERAL_INVENTORY_STATUS_ACTIVE',
  COLLATERAL_INVENTORY_STATUS_DELETED = 'COLLATERAL_INVENTORY_STATUS_DELETED',
  COLLATERAL_INVENTORY_STATUS_INACTIVE = 'COLLATERAL_INVENTORY_STATUS_INACTIVE',
  COLLATERAL_INVENTORY_STATUS_UNSPECIFIED = 'COLLATERAL_INVENTORY_STATUS_UNSPECIFIED'
}

/** CollateralInventoryTransactionType is enum for the field transaction_type */
export enum CollateralInventoryTransactionType {
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_COLLATERAL_IN = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_COLLATERAL_IN',
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_COLLATERAL_OUT = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_COLLATERAL_OUT',
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_DELETED = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_DELETED',
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_EXCESS_COLLATERAL = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_EXCESS_COLLATERAL',
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_INITIAL_MARGIN = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_INITIAL_MARGIN',
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_LIQUIDATION = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_LIQUIDATION',
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_MARGIN_CALL = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_MARGIN_CALL',
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_REHYPO_OUT = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_REHYPO_OUT',
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_REHYPO_RETURN = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_REHYPO_RETURN',
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_UNSPECIFIED = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_UNSPECIFIED'
}

/**
 * CollateralInventoryWhereInput is used for filtering CollateralInventory objects.
 * Input was generated by ent.
 */
export type CollateralInventoryWhereInput = {
  and?: InputMaybe<Array<CollateralInventoryWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** account_id_fk edge predicates */
  hasAccountIDFk?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountIDFkWith?: InputMaybe<Array<CollateralAccountWhereInput>>;
  /** asset_spec_id_fk edge predicates */
  hasAssetSpecIDFk?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetSpecIDFkWith?: InputMaybe<Array<AssetSpecWhereInput>>;
  /** ca_id_fk edge predicates */
  hasCaIDFk?: InputMaybe<Scalars['Boolean']['input']>;
  hasCaIDFkWith?: InputMaybe<Array<CollateralAgreementWhereInput>>;
  /** collateral_call edge predicates */
  hasCollateralCall?: InputMaybe<Scalars['Boolean']['input']>;
  hasCollateralCallWith?: InputMaybe<Array<CollateralCallWhereInput>>;
  /** owner_id_fk edge predicates */
  hasOwnerIDFk?: InputMaybe<Scalars['Boolean']['input']>;
  hasOwnerIDFkWith?: InputMaybe<Array<PartyWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<CollateralInventoryWhereInput>;
  /** notes field predicates */
  notes?: InputMaybe<Scalars['String']['input']>;
  notesContains?: InputMaybe<Scalars['String']['input']>;
  notesContainsFold?: InputMaybe<Scalars['String']['input']>;
  notesEqualFold?: InputMaybe<Scalars['String']['input']>;
  notesGT?: InputMaybe<Scalars['String']['input']>;
  notesGTE?: InputMaybe<Scalars['String']['input']>;
  notesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  notesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  notesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesLT?: InputMaybe<Scalars['String']['input']>;
  notesLTE?: InputMaybe<Scalars['String']['input']>;
  notesNEQ?: InputMaybe<Scalars['String']['input']>;
  notesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<CollateralInventoryWhereInput>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  quantityGT?: InputMaybe<Scalars['Decimal']['input']>;
  quantityGTE?: InputMaybe<Scalars['Decimal']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  quantityLT?: InputMaybe<Scalars['Decimal']['input']>;
  quantityLTE?: InputMaybe<Scalars['Decimal']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** status field predicates */
  status?: InputMaybe<CollateralInventoryStatus>;
  statusIn?: InputMaybe<Array<CollateralInventoryStatus>>;
  statusNEQ?: InputMaybe<CollateralInventoryStatus>;
  statusNotIn?: InputMaybe<Array<CollateralInventoryStatus>>;
  /** transaction_time field predicates */
  transactionTime?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionTimeGT?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionTimeGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionTimeIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  transactionTimeLT?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionTimeLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionTimeNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionTimeNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** transaction_type field predicates */
  transactionType?: InputMaybe<CollateralInventoryTransactionType>;
  transactionTypeIn?: InputMaybe<Array<CollateralInventoryTransactionType>>;
  transactionTypeNEQ?: InputMaybe<CollateralInventoryTransactionType>;
  transactionTypeNotIn?: InputMaybe<Array<CollateralInventoryTransactionType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CollateralRehypoLedger = Node & {
  __typename: 'CollateralRehypoLedger';
  account: Maybe<CollateralAccount>;
  assetSpec: Maybe<AssetSpec>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  metadata: Maybe<RehypoLedgerMetadata>;
  notes: Scalars['String']['output'];
  operationID: Scalars['String']['output'];
  quantity: Scalars['Decimal']['output'];
  status: CollateralRehypoLedgerStatus;
  transactionPurpose: CollateralRehypoLedgerTransactionPurpose;
  transactionTime: Scalars['Timestamp']['output'];
  transactionType: CollateralRehypoLedgerTransactionType;
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type CollateralRehypoLedgerConnection = {
  __typename: 'CollateralRehypoLedgerConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CollateralRehypoLedgerEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CollateralRehypoLedgerEdge = {
  __typename: 'CollateralRehypoLedgerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CollateralRehypoLedger>;
};

/** Ordering options for CollateralRehypoLedger connections */
export type CollateralRehypoLedgerOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CollateralRehypoLedgers. */
  field: CollateralRehypoLedgerOrderField;
};

/** Properties by which CollateralRehypoLedger connections can be ordered. */
export enum CollateralRehypoLedgerOrderField {
  CREATED_AT = 'CREATED_AT',
  STATUS = 'STATUS',
  TRANSACTION_PURPOSE = 'TRANSACTION_PURPOSE',
  TRANSACTION_TIME = 'TRANSACTION_TIME',
  TRANSACTION_TYPE = 'TRANSACTION_TYPE'
}

/** CollateralRehypoLedgerStatus is enum for the field status */
export enum CollateralRehypoLedgerStatus {
  COLLATERAL_REHYPO_LEDGER_STATUS_ACTIVE = 'COLLATERAL_REHYPO_LEDGER_STATUS_ACTIVE',
  COLLATERAL_REHYPO_LEDGER_STATUS_DELETED = 'COLLATERAL_REHYPO_LEDGER_STATUS_DELETED',
  COLLATERAL_REHYPO_LEDGER_STATUS_INACTIVE = 'COLLATERAL_REHYPO_LEDGER_STATUS_INACTIVE',
  COLLATERAL_REHYPO_LEDGER_STATUS_UNSPECIFIED = 'COLLATERAL_REHYPO_LEDGER_STATUS_UNSPECIFIED'
}

/** CollateralRehypoLedgerTransactionPurpose is enum for the field transaction_purpose */
export enum CollateralRehypoLedgerTransactionPurpose {
  COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_BORROWING = 'COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_BORROWING',
  COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_INTEREST = 'COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_INTEREST',
  COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_LENDING = 'COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_LENDING',
  COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_PROPDESK = 'COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_PROPDESK',
  COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_UNSPECIFIED = 'COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_UNSPECIFIED'
}

/** CollateralRehypoLedgerTransactionType is enum for the field transaction_type */
export enum CollateralRehypoLedgerTransactionType {
  COLLATERAL_REHYPO_LEDGER_TRANSACTION_TYPE_TRANSFER_IN = 'COLLATERAL_REHYPO_LEDGER_TRANSACTION_TYPE_TRANSFER_IN',
  COLLATERAL_REHYPO_LEDGER_TRANSACTION_TYPE_TRANSFER_OUT = 'COLLATERAL_REHYPO_LEDGER_TRANSACTION_TYPE_TRANSFER_OUT',
  COLLATERAL_REHYPO_LEDGER_TRANSACTION_TYPE_UNSPECIFIED = 'COLLATERAL_REHYPO_LEDGER_TRANSACTION_TYPE_UNSPECIFIED'
}

/**
 * CollateralRehypoLedgerWhereInput is used for filtering CollateralRehypoLedger objects.
 * Input was generated by ent.
 */
export type CollateralRehypoLedgerWhereInput = {
  and?: InputMaybe<Array<CollateralRehypoLedgerWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** account edge predicates */
  hasAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountWith?: InputMaybe<Array<CollateralAccountWhereInput>>;
  /** asset_spec edge predicates */
  hasAssetSpec?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetSpecWith?: InputMaybe<Array<AssetSpecWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<CollateralRehypoLedgerWhereInput>;
  /** notes field predicates */
  notes?: InputMaybe<Scalars['String']['input']>;
  notesContains?: InputMaybe<Scalars['String']['input']>;
  notesContainsFold?: InputMaybe<Scalars['String']['input']>;
  notesEqualFold?: InputMaybe<Scalars['String']['input']>;
  notesGT?: InputMaybe<Scalars['String']['input']>;
  notesGTE?: InputMaybe<Scalars['String']['input']>;
  notesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  notesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  notesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesLT?: InputMaybe<Scalars['String']['input']>;
  notesLTE?: InputMaybe<Scalars['String']['input']>;
  notesNEQ?: InputMaybe<Scalars['String']['input']>;
  notesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** operation_id field predicates */
  operationID?: InputMaybe<Scalars['String']['input']>;
  operationIDContains?: InputMaybe<Scalars['String']['input']>;
  operationIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  operationIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  operationIDGT?: InputMaybe<Scalars['String']['input']>;
  operationIDGTE?: InputMaybe<Scalars['String']['input']>;
  operationIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  operationIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  operationIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  operationIDLT?: InputMaybe<Scalars['String']['input']>;
  operationIDLTE?: InputMaybe<Scalars['String']['input']>;
  operationIDNEQ?: InputMaybe<Scalars['String']['input']>;
  operationIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<CollateralRehypoLedgerWhereInput>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  quantityGT?: InputMaybe<Scalars['Decimal']['input']>;
  quantityGTE?: InputMaybe<Scalars['Decimal']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  quantityLT?: InputMaybe<Scalars['Decimal']['input']>;
  quantityLTE?: InputMaybe<Scalars['Decimal']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** status field predicates */
  status?: InputMaybe<CollateralRehypoLedgerStatus>;
  statusIn?: InputMaybe<Array<CollateralRehypoLedgerStatus>>;
  statusNEQ?: InputMaybe<CollateralRehypoLedgerStatus>;
  statusNotIn?: InputMaybe<Array<CollateralRehypoLedgerStatus>>;
  /** transaction_purpose field predicates */
  transactionPurpose?: InputMaybe<CollateralRehypoLedgerTransactionPurpose>;
  transactionPurposeIn?: InputMaybe<Array<CollateralRehypoLedgerTransactionPurpose>>;
  transactionPurposeNEQ?: InputMaybe<CollateralRehypoLedgerTransactionPurpose>;
  transactionPurposeNotIn?: InputMaybe<Array<CollateralRehypoLedgerTransactionPurpose>>;
  /** transaction_time field predicates */
  transactionTime?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionTimeGT?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionTimeGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionTimeIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  transactionTimeLT?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionTimeLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionTimeNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionTimeNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** transaction_type field predicates */
  transactionType?: InputMaybe<CollateralRehypoLedgerTransactionType>;
  transactionTypeIn?: InputMaybe<Array<CollateralRehypoLedgerTransactionType>>;
  transactionTypeNEQ?: InputMaybe<CollateralRehypoLedgerTransactionType>;
  transactionTypeNotIn?: InputMaybe<Array<CollateralRehypoLedgerTransactionType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CollateralSettlement = Node & {
  __typename: 'CollateralSettlement';
  assetSpecID: Maybe<AssetSpec>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['String']['output'];
  destinationAccountID: Maybe<CollateralAccount>;
  id: Scalars['ID']['output'];
  quantity: Scalars['Decimal']['output'];
  settlementTypeID: CollateralSettlementSettlementTypeID;
  sourceAccountID: Maybe<CollateralAccount>;
  status: CollateralSettlementStatus;
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** Ordering options for CollateralSettlement connections */
export type CollateralSettlementOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CollateralSettlements. */
  field: CollateralSettlementOrderField;
};

/** Properties by which CollateralSettlement connections can be ordered. */
export enum CollateralSettlementOrderField {
  CREATED_AT = 'CREATED_AT',
  QUANTITY = 'QUANTITY',
  SETTLEMENT_TYPE_ID = 'SETTLEMENT_TYPE_ID',
  STATUS = 'STATUS'
}

/** CollateralSettlementSettlementTypeID is enum for the field settlement_type_id */
export enum CollateralSettlementSettlementTypeID {
  SETTLEMENT_TYPE_DEPOSIT = 'SETTLEMENT_TYPE_DEPOSIT',
  SETTLEMENT_TYPE_PAYMENT = 'SETTLEMENT_TYPE_PAYMENT',
  SETTLEMENT_TYPE_UNSPECIFIED = 'SETTLEMENT_TYPE_UNSPECIFIED'
}

/** CollateralSettlementStatus is enum for the field status */
export enum CollateralSettlementStatus {
  SETTLEMENT_STATUS_CANCELED = 'SETTLEMENT_STATUS_CANCELED',
  SETTLEMENT_STATUS_COMPLETED = 'SETTLEMENT_STATUS_COMPLETED',
  SETTLEMENT_STATUS_INITIATED = 'SETTLEMENT_STATUS_INITIATED',
  SETTLEMENT_STATUS_PENDING = 'SETTLEMENT_STATUS_PENDING',
  SETTLEMENT_STATUS_UNSPECIFIED = 'SETTLEMENT_STATUS_UNSPECIFIED'
}

/**
 * CollateralSettlementWhereInput is used for filtering Settlement objects.
 * Input was generated by ent.
 */
export type CollateralSettlementWhereInput = {
  and?: InputMaybe<Array<CollateralSettlementWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** asset_spec_id_fk edge predicates */
  hasAssetSpecID?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetSpecIDWith?: InputMaybe<Array<AssetSpecWhereInput>>;
  /** destination_account_id_fk edge predicates */
  hasDestinationAccountID?: InputMaybe<Scalars['Boolean']['input']>;
  hasDestinationAccountIDWith?: InputMaybe<Array<CollateralAccountWhereInput>>;
  /** source_account_id_fk edge predicates */
  hasSourceAccountID?: InputMaybe<Scalars['Boolean']['input']>;
  hasSourceAccountIDWith?: InputMaybe<Array<CollateralAccountWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<CollateralSettlementWhereInput>;
  or?: InputMaybe<Array<CollateralSettlementWhereInput>>;
  /** quantity field predicates */
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  quantityGT?: InputMaybe<Scalars['Decimal']['input']>;
  quantityGTE?: InputMaybe<Scalars['Decimal']['input']>;
  quantityIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  quantityLT?: InputMaybe<Scalars['Decimal']['input']>;
  quantityLTE?: InputMaybe<Scalars['Decimal']['input']>;
  quantityNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  quantityNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** settlement_type_id field predicates */
  settlementTypeID?: InputMaybe<CollateralSettlementSettlementTypeID>;
  settlementTypeIDIn?: InputMaybe<Array<CollateralSettlementSettlementTypeID>>;
  settlementTypeIDNEQ?: InputMaybe<CollateralSettlementSettlementTypeID>;
  settlementTypeIDNotIn?: InputMaybe<Array<CollateralSettlementSettlementTypeID>>;
  /** status field predicates */
  status?: InputMaybe<CollateralSettlementStatus>;
  statusIn?: InputMaybe<Array<CollateralSettlementStatus>>;
  statusNEQ?: InputMaybe<CollateralSettlementStatus>;
  statusNotIn?: InputMaybe<Array<CollateralSettlementStatus>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Collateral status */
export enum CollateralStatus {
  /** Active collateral */
  ACTIVE = 'ACTIVE',
  /** Canceled collateral */
  CANCELED = 'CANCELED',
  /** Pending operation */
  PENDING = 'PENDING'
}

/** Collateral transaction type */
export enum CollateralTransactionType {
  /** Deposit collateral */
  DEPOSIT = 'DEPOSIT',
  /** Withdraw collateral */
  WITHDRAW = 'WITHDRAW'
}

/**
 * CommonOperationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type
 * For resolver implementations, see (eg):
 * - service/graphqlmonolith/models/operation.go (eg EndorsementPayload)
 */
export type CommonOperationInfo = {
  __typename: 'CommonOperationInfo';
  /** Actions available on this operation */
  availableOperationDecisions: Array<OperationDecision>;
  /** Returns the bio challenge required to pass in order to endorse this operation */
  bioChallenge: Maybe<BioChallenge>;
  /**
   * canonicalOperationPayload is returned to the client to be used as the source of truth for the UI elements describing the operation for "new" operation types such as OffChainOperation that support the visual signing protocol. For old operation types, this field most NOT be used because the security of the system relies on the client constructing it on their own from other graphql fields. It's optional because not all operations are endorsable.
   * For these new operation types, it might be useful to use it in combination with the endorsementPayload field to simplify the (blind) signing of extra metadata.
   */
  canonicalOperationPayload: Maybe<Scalars['String']['output']>;
  /**
   * The path to be used to derive the control child private key to allow this operation to be endorsed if one is needed.
   * Example: m/1/2/3
   */
  controlKeyDerivationPath: Maybe<Scalars['String']['output']>;
  /** displaySubtitle is the subtitle text used in conjunction with the displayTitle */
  displaySubtitle: Scalars['String']['output'];
  /** displayTitle is the standard, human readable name used when displaying the name across all user facing applications */
  displayTitle: Scalars['String']['output'];
  /**
   * endorsementPayload returns a pre-formatted "endorsement" signable. The "Signable" payload type for these structs is always "ENDORSEMENT".
   * This is a json blob formatted and returned as a string.
   * For operations that are not signable, this is null (ex. "DepositEvent")
   */
  endorsementPayload: Maybe<Scalars['String']['output']>;
  /**
   * endorsements is the list of endorsements for the operation
   * The type is nullable so we can return null in cases when the operation is not expected to support endorsements.
   */
  endorsements: Maybe<Array<SimplifiedEndorsement>>;
  /**
   * generation is the organization generation this operation was created against
   * Organization creation operations won't have an generation before the organization has been created
   */
  generation: Maybe<Scalars['Int']['output']>;
  /** initiator is the user who started the operation. This operation could have been started by an anchorage employee or simply come from the blockchain, so not every operation has an initiator. */
  initiator: Maybe<User>;
  /**
   * initiatorV2 is actor in the Anchorage system that started the operation.
   * Operations might be initiated by Anchorage employees, Users, API Keys, etc.
   */
  initiatorV2: Maybe<Actor>;
  /** issueTicket returns the JIRA ticket ID */
  issueTicket: Maybe<Scalars['String']['output']>;
  /** operationFields describe the layout of the operation as per VSP for the iOS to display */
  operationFields: Maybe<Array<OperationField>>;
  /** operationID is the id of the operation */
  operationID: Scalars['OperationID']['output'];
  /** operationImpediment contains info about what impedes an operation from transitioning to the next state. */
  operationImpediment: Maybe<OperationImpediment>;
  /**
   * Organization that this operation belongs to
   * Organization creation operations won't have an organization before the organization has been created
   */
  organization: Maybe<Organization>;
  /** readPolicy is the non-signable representation of the policy that applies to this operation */
  readPolicy: Maybe<ReadPolicy>;
  /**
   * This controls whether or not the iOS app should show a badge saying "external" on the operation list and detail views.
   * This was created when adding walletconnect and is on when walletconnect is used. The meaning might change over time.
   */
  showExternalBadge: Scalars['Boolean']['output'];
  /** signablePayload is the payload that the user is expected to sign - it's a JSON string */
  signablePayload: Maybe<Scalars['String']['output']>;
};


/**
 * CommonOperationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type
 * For resolver implementations, see (eg):
 * - service/graphqlmonolith/models/operation.go (eg EndorsementPayload)
 */
export type CommonOperationInfobioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/**
 * CommonOperationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type
 * For resolver implementations, see (eg):
 * - service/graphqlmonolith/models/operation.go (eg EndorsementPayload)
 */
export type CommonOperationInfoendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/** ComplianceAlertTotals represents the totals for open alerts grouped by case type and age group */
export type ComplianceAlertTotals = {
  __typename: 'ComplianceAlertTotals';
  /** Type of alert */
  alertType: AlertType;
  /** Number of alerts of this type opened between 5 and 10 days ago */
  fiveToTen: Scalars['Int']['output'];
  /** Number of alerts of this type opened more than 10 days ago */
  tenPlus: Scalars['Int']['output'];
  /** Total number of alerts of this type */
  total: Scalars['Int']['output'];
  /** Number of alerts of this type opened in the last 5 days */
  zeroToFive: Scalars['Int']['output'];
};

/** ComplianceCaseTotals represents the totals for open cases grouped by case type and age group */
export type ComplianceCaseTotals = {
  __typename: 'ComplianceCaseTotals';
  /** Type of case */
  caseType: CaseType;
  /** Number of cases of this type opened between 10 and 20 days ago */
  tenToTwenty: Scalars['Int']['output'];
  /** Total number of cases of this type */
  total: Scalars['Int']['output'];
  /** Number of cases of this type opened more than 20 days ago */
  twentyPlus: Scalars['Int']['output'];
  /** Number of cases of this type opened in the last 10 days */
  zeroToTen: Scalars['Int']['output'];
};

/** ComplianceDocumentsConnection contains the edges to ComplianceDocumentsEdge */
export type ComplianceDocumentsConnection = {
  __typename: 'ComplianceDocumentsConnection';
  /** List of edges containing compliance documents and cursor information */
  edges: Array<ComplianceDocumentsEdge>;
  /** Page details for the associated list of ComplianceDocumentsEdge */
  pageInfo: PageInfo;
};

/** ComplianceDocumentsDetail contains information about documents */
export type ComplianceDocumentsDetail = {
  __typename: 'ComplianceDocumentsDetail';
  /** contentType is the type of the document */
  contentType: Maybe<Scalars['String']['output']>;
  /** createdAt is the time when the document is created */
  createdAt: Scalars['Timestamp']['output'];
  /** file is the name of the file */
  file: Scalars['String']['output'];
  /** folderID is the ID of the folder that contains the file */
  folderID: Scalars['String']['output'];
  /** size is the size of the document */
  size: Scalars['Int']['output'];
  /** sizeAsString is the size of the document as string */
  sizeAsString: Scalars['String']['output'];
};

/** ComplianceDocumentsEdge contains the CRRScoreDetails */
export type ComplianceDocumentsEdge = {
  __typename: 'ComplianceDocumentsEdge';
  /** Cursor for the ComplianceDocumentsEdge */
  cursor: Scalars['ID']['output'];
  /** CRR Score details */
  node: ComplianceDocumentsDetail;
};

/** ComplianceIssueInput input data to successfully create a new issue in Jira */
export type ComplianceIssueInput = {
  /** Affiliate ID associated to the issue */
  affiliateID: Scalars['String']['input'];
  /** Type of client that represents the affiliate */
  clientType?: InputMaybe<Scalars['String']['input']>;
  /** CRR of the affiliate */
  crr: CRRScoreOutcome;
  /** Description when applicable */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Entity Type associated to the issue */
  entityType: AnchorageEntityType;
  /** ID of the individual associated with the case */
  individualID?: InputMaybe<Scalars['String']['input']>;
  /** Name of the individual associated with the case */
  individualName?: InputMaybe<Scalars['String']['input']>;
  /** Source(s) of the issue being manually created */
  issueSources?: InputMaybe<Array<IssueSource>>;
  /** Type of issue being manually created in jira via CCV */
  issueType: AlertType;
};

/** Extra parameters for cosmos */
export type CosmosExtraParams = {
  __typename: 'CosmosExtraParams';
  /** memo to use in transaction */
  memo: Maybe<Scalars['String']['output']>;
};

/** Counterparty information */
export type Counterparty = {
  __typename: 'Counterparty';
  /** createdAt is the timestamp of creation of this record */
  createdAt: Scalars['Timestamp']['output'];
  /** createdBy is the email of the user who created this record */
  createdBy: Scalars['String']['output'];
  /** Display name of the counterparty */
  displayName: Scalars['String']['output'];
  /** Counterparty identifier */
  id: Scalars['ID']['output'];
  /** Whether a counterparty is active or not */
  isActive: Scalars['Boolean']['output'];
  /** Kind of counterparty */
  kind: CounterpartyKind;
  /** Counterparty internal name */
  name: Scalars['String']['output'];
  /** updatedAt is the timestamp of the latest update of this record */
  updatedAt: Scalars['Timestamp']['output'];
  /** updatedBy is the email of the user who last updated this record */
  updatedBy: Scalars['String']['output'];
};

/** Counterparty Analysis (LexisNexis) */
export type CounterpartyAnalysis = {
  __typename: 'CounterpartyAnalysis';
  /** resultId is an id to search the counterparty */
  resultId: Scalars['String']['output'];
  /** score represents the risk assessment of the address */
  score: TransactionRiskScore;
  /** status is the status of the request (e.g: PENDING,UNSUPPORTED,FAILURE) */
  status: RiskRequestStatus;
  /** analysisType is the type of analysis (e.g: CounterpartyCheck) */
  type: RiskAnalysisType;
  /** vendor is the enum value for the analysis vendor (e.g: LexisNexis) */
  vendor: RiskAnalysisVendor;
};

/** Counterparty connection */
export type CounterpartyConnection = {
  __typename: 'CounterpartyConnection';
  /** List of edges containing counterparty and cursor information */
  edges: Array<Maybe<CounterpartyEdge>>;
  /** Page details for the associated list of counterparties */
  pageInfo: PageInfo;
};

/** Counterparty pagination edge */
export type CounterpartyEdge = {
  __typename: 'CounterpartyEdge';
  /** Cursor for the associated Counterparty. */
  cursor: Scalars['String']['output'];
  /** Counterparty. */
  node: Counterparty;
};

/** Possible counterparty kinds */
export enum CounterpartyKind {
  /** Represents a customer */
  CUSTOMER = 'CUSTOMER',
  /** Represents a dealer */
  DEALER = 'DEALER',
  /** Represents an exchange */
  EXCHANGE = 'EXCHANGE',
  /** Represents an invalid kind */
  INVALID = 'INVALID'
}

/** CounterpartyWithErr represents a counterparty with an error message */
export type CounterpartyWithErr = {
  __typename: 'CounterpartyWithErr';
  /** Counterparty information */
  counterparty: Maybe<Counterparty>;
  /** Error message */
  err: Maybe<Scalars['String']['output']>;
};

/** Country holds country information */
export type Country = {
  __typename: 'Country';
  /** Two letter code of the country */
  code: Scalars['String']['output'];
  /** Name of the country */
  name: Scalars['String']['output'];
};

/**
 * CreateAssetSpecInput is used for create AssetSpec object.
 * Input was generated by ent.
 */
export type CreateAssetSpecInput = {
  assetPrecision: Scalars['Int']['input'];
  assetSpecMetadata?: InputMaybe<AssetSpecMetadataInput>;
  baseSymbol: Scalars['String']['input'];
  name: Scalars['String']['input'];
  status: AssetSpecStatus;
  symbol: Scalars['String']['input'];
  typeID: AssetSpecTypeID;
};

/**
 * CreateCollateralAgreementInput is used for create CollateralAgreement object.
 * Input was generated by ent.
 */
export type CreateCollateralAgreementInput = {
  agreementType: CollateralAgreementAgreementType;
  assetLentID: Scalars['ID']['input'];
  assetRefDataIssuerIDFkID: Scalars['ID']['input'];
  liquidationInstructions?: InputMaybe<CollateralAgreementLiquidationInstructionsInput>;
  liquidationLevel?: InputMaybe<Scalars['Decimal']['input']>;
  loanAmountAtOrigination: Scalars['Decimal']['input'];
  marginCallCurePeriod?: InputMaybe<Scalars['Int']['input']>;
  marginCallLevel?: InputMaybe<Scalars['Decimal']['input']>;
  marginLevel: CollateralAgreementMarginLevel;
  minimalThresholdAmount?: InputMaybe<Scalars['Decimal']['input']>;
  monitored?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  originationDate: Scalars['Timestamp']['input'];
  pledgerIDFkID: Scalars['ID']['input'];
  preMarginCallLevel?: InputMaybe<Scalars['Decimal']['input']>;
  priceRefDataIssuerIDFkID: Scalars['ID']['input'];
  recoveryLevel?: InputMaybe<Scalars['Decimal']['input']>;
  referencePriceAlgo: CollateralAgreementReferencePriceAlgo;
  rehypothecation: Scalars['Boolean']['input'];
  requiredCoverageLevel?: InputMaybe<Scalars['Decimal']['input']>;
  reverseCallLevel?: InputMaybe<Scalars['Decimal']['input']>;
  secured: Scalars['Boolean']['input'];
  securedPartyIDFkID: Scalars['ID']['input'];
  spotPriceAtOrigination: Scalars['Decimal']['input'];
};

/** CreateCollateralCallInput is used to create a CollateralCall object. */
export type CreateCollateralCallInput = {
  callSubject: CollateralCallSubjectInput;
  callType: CollateralCallCallType;
  callerID: Scalars['ID']['input'];
  collateralAgreementID: Scalars['ID']['input'];
  counterPartyID: Scalars['ID']['input'];
  eligibilityProof?: InputMaybe<CreateEligibilityProofInput>;
};

/**
 * CreateCollateralFeeInput is used for create CollateralFee object.
 * Input was generated by ent.
 */
export type CreateCollateralFeeInput = {
  assetSpecID: Scalars['ID']['input'];
  metadata?: InputMaybe<CollateralFeeMetadataInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  operationID: Scalars['String']['input'];
  quantity: Scalars['Decimal']['input'];
  transactionTime: Scalars['Timestamp']['input'];
};

/**
 * CreateCollateralInventoryInput is used for create CollateralInventory object.
 * Input was generated by ent.
 */
export type CreateCollateralInventoryInput = {
  accountID: Scalars['ID']['input'];
  assetSpecID: Scalars['ID']['input'];
  caID: Scalars['ID']['input'];
  collateralCallID?: InputMaybe<Scalars['ID']['input']>;
  metadata?: InputMaybe<InventoryMetadataInput>;
  notes: Scalars['String']['input'];
  ownerID: Scalars['ID']['input'];
  quantity: Scalars['Decimal']['input'];
  transactionTime: Scalars['Timestamp']['input'];
  transactionType: CollateralInventoryTransactionType;
};

/**
 * CreateCollateralRehypoLedgerInput is used for create CollateralRehypoLedger object.
 * Input was generated by ent.
 */
export type CreateCollateralRehypoLedgerInput = {
  accountID: Scalars['ID']['input'];
  assetSpecID: Scalars['ID']['input'];
  metadata?: InputMaybe<RehypoLedgerMetadataInput>;
  notes: Scalars['String']['input'];
  operationID: Scalars['String']['input'];
  quantity: Scalars['Decimal']['input'];
  transactionPurpose: CollateralRehypoLedgerTransactionPurpose;
  transactionTime: Scalars['Timestamp']['input'];
  transactionType: CollateralRehypoLedgerTransactionType;
};

/**
 * CreateDepositAttributionSpamThresholdInput is used for create DepositAttributionSpamThreshold object.
 * Input was generated by ent.
 */
export type CreateDepositAttributionSpamThresholdInput = {
  assetTypeID: Scalars['AssetTypeID']['input'];
  threshold: Scalars['Decimal']['input'];
};

/** CreateEligibilityProofInput is used to create EligibilityProof object. */
export type CreateEligibilityProofInput = {
  collateralAssets?: InputMaybe<Array<InputMaybe<EligibilityProofAssetDetailInput>>>;
  exposureAssets?: InputMaybe<Array<InputMaybe<EligibilityProofAssetDetailInput>>>;
};

/** CreateFeeConfig represents a fee configuration used in the creation of a fee configuration */
export type CreateFeeConfig = {
  /** Period of the configured fee */
  feePeriod: FeePeriodType;
  /** Rate of the configured fee */
  feeRate: Scalars['Float']['input'];
  /** Target of the fee configuration */
  feeTarget: FeeTargetType;
  /** Target ID of the fee configuration */
  feeTargetID?: InputMaybe<Scalars['String']['input']>;
  /** Type of the configured fee */
  feeType: FeeTypeType;
  /** Is billable defines if we directly bill the target */
  isBillable: Scalars['Boolean']['input'];
  /** Org ID associated to the case */
  orgKeyID: Scalars['String']['input'];
  /** Start date of the configured fee */
  startDate: Scalars['Date']['input'];
};

/**
 * CreateIAMRoleInput is used for create Role object.
 * Input was generated by ent.
 */
export type CreateIAMRoleInput = {
  availability?: InputMaybe<IAMRoleAvailability>;
  description: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  hasRoleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  isInRoleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  name: Scalars['String']['input'];
  permissionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
};

/**
 * CreateKYCProcessInput is used for create KYCProcess object.
 * Input was generated by ent.
 */
export type CreateKYCProcessInput = {
  /** AffiliateID is the affiliate ID of the KYCProcess. */
  affiliateID: Scalars['ID']['input'];
  /** Description is the description of the KYCProcess. */
  description: Scalars['String']['input'];
  /** Name is the name of the KYCProcess. */
  name: Scalars['String']['input'];
};

/**
 * CreateKYCProcessTriggerInput is used for create KYCProcessTrigger object.
 * Input was generated by ent.
 */
export type CreateKYCProcessTriggerInput = {
  /** The actions to be executed when the trigger is fired. */
  actions?: InputMaybe<Array<KYCProcessTriggerActionInput>>;
  /** Type of the affiliate */
  affiliateType?: InputMaybe<Scalars['String']['input']>;
  /** Type of anchorage entity */
  anchorageEntityType?: InputMaybe<Scalars['String']['input']>;
  /** The conditions to be validated when the trigger is fired. */
  conditions?: InputMaybe<Array<KYCProcessTriggerConditionInput>>;
  /** The current state of the Jira case. */
  currentJiraState: Scalars['String']['input'];
  /** The type of the Jira case. */
  jiraCaseType: Scalars['String']['input'];
  /** The previous state of the Jira case. */
  previousJiraState?: InputMaybe<Scalars['String']['input']>;
  /** Priority of the process trigger */
  priority?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * CreateKYCQuestionInput is used for create KYCQuestion object.
 * Input was generated by ent.
 */
export type CreateKYCQuestionInput = {
  /** The categories of the questionnaire */
  categories: Array<Scalars['String']['input']>;
  /** The information type of the questionnaire */
  informationType: Scalars['String']['input'];
  /** Name of the questionnaire */
  name: Scalars['String']['input'];
  /** The question definition of the questionnaire */
  questionDefinition: Scalars['String']['input'];
};

/**
 * CreateKYCQuestionnaireInput is used for create KYCQuestionnaire object.
 * Input was generated by ent.
 */
export type CreateKYCQuestionnaireInput = {
  /** The questionnaire element body, it's a JSON string of KYCQuestionnaireElement type */
  body: Scalars['String']['input'];
  /** The categories of the questionnaire */
  categories: Array<Scalars['String']['input']>;
  /** Number to days after which the questionnaire expires */
  expirationDays?: InputMaybe<Scalars['Int']['input']>;
  /** The name of the questionnaire */
  name: Scalars['String']['input'];
  /** The type of the questionnaire */
  questionnaireType: KYCQuestionnaireType;
};

/** CreateKYCQuestionnaireSubmissionInput is the input for CreateKYCQuestionnaireSubmission. */
export type CreateKYCQuestionnaireSubmissionInput = {
  /** The identifier of the KYCProcess */
  processID: Scalars['ID']['input'];
  /** The identifier of the KYCQuestionnaire */
  questionnaireID: Scalars['ID']['input'];
};

/**
 * CreateReportDataFilterInput is used for create ReportDataFilter object.
 * Input was generated by ent.
 */
export type CreateReportDataFilterInput = {
  fieldName: Scalars['String']['input'];
};

/**
 * CreateReportFileInput is used for create ReportFile object.
 * Input was generated by ent.
 */
export type CreateReportFileInput = {
  fileFormat?: InputMaybe<ReportFileFileFormat>;
};

/**
 * CreateReportInput is used for create Report object.
 * Input was generated by ent.
 */
export type CreateReportInput = {
  fileFormats: Array<ReportFileFileFormat>;
  name: Scalars['String']['input'];
  reportFilters: Array<ReportFilterInput>;
  reportSchedulesID?: InputMaybe<Scalars['ID']['input']>;
  type: Scalars['String']['input'];
  /** Format: ±[hh]:[mm] where -12:00 <= ±[hh]:[mm] <= +14:00 */
  utcOffset?: InputMaybe<Scalars['String']['input']>;
};

/**
 * CreateReportScheduleInput is used for create ReportSchedule object.
 * Input was generated by ent.
 */
export type CreateReportScheduleInput = {
  dataFilters: Array<ReportFilterInput>;
  /** 1-31. Use 0 to indicate null. Must be set to other than 0 when schedule is not daily. For non-daily reports, represents the day of week or month on which the report will be triggered */
  daysAfterNonDailyScheduleTypeToTrigger?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  fileFormats: Array<ReportFileFileFormat>;
  /** 0-23 */
  hourOfDay: Scalars['Int']['input'];
  mainFilter: CreateReportScheduleMainFilterInput;
  name: Scalars['String']['input'];
  /** organizationID should only be set by staff users. */
  organizationID?: InputMaybe<Scalars['String']['input']>;
  reportType: Scalars['String']['input'];
  scheduleType?: InputMaybe<ReportScheduleScheduleType>;
};

/**
 * CreateReportScheduleMainFilterInput is used for create ReportScheduleMainFilter object.
 * Input was generated by ent.
 */
export type CreateReportScheduleMainFilterInput = {
  fieldName: Scalars['String']['input'];
  scheduleFilterPoint?: InputMaybe<ReportScheduleMainFilterScheduleFilterPoint>;
  scheduleFilterRange?: InputMaybe<ReportScheduleMainFilterScheduleFilterRange>;
};

/** CreateStandingInstruction represents a to be created FIAT standing insctruction (trusted address) */
export type CreateStandingInstruction = {
  /** Account Number */
  accountNumber: Scalars['String']['input'];
  /** Affiliate ID linked to the Standing Instructions */
  affiliateID: Scalars['String']['input'];
  /** Beneficiary Address 1 */
  beneficiaryAddress1: Scalars['String']['input'];
  /** Beneficiary Address 2 */
  beneficiaryAddress2: Scalars['String']['input'];
  /** Beneficiary Address 3 */
  beneficiaryAddress3?: InputMaybe<Scalars['String']['input']>;
  /** Beneficiary code */
  beneficiaryCode: Scalars['String']['input'];
  /** Beneficiary Country */
  beneficiaryCountry: Scalars['String']['input'];
  /** Beneficiary Name */
  beneficiaryName: Scalars['String']['input'];
  /** Beneficiary nickname */
  beneficiaryNickname?: InputMaybe<Scalars['String']['input']>;
  /** Financial Institution address 1 */
  fiAddress1?: InputMaybe<Scalars['String']['input']>;
  /** Financial Institution address 2 */
  fiAddress2?: InputMaybe<Scalars['String']['input']>;
  /** Financial Institution code */
  fiCode: Scalars['String']['input'];
  /** Financial Institution country */
  fiCountry?: InputMaybe<Scalars['String']['input']>;
  /** Financial Institution ID */
  fiID: Scalars['String']['input'];
  /** Financial Institution name */
  fiName: Scalars['String']['input'];
  /** Intermediate Financial Institution address 1 */
  intermediateAddress1?: InputMaybe<Scalars['String']['input']>;
  /** Intermediate Financial Institution address 2 */
  intermediateAddress2?: InputMaybe<Scalars['String']['input']>;
  /** Intermediate Financial Institution code */
  intermediateCode?: InputMaybe<Scalars['String']['input']>;
  /** Intermediate Financial Institution country */
  intermediateCountry?: InputMaybe<Scalars['String']['input']>;
  /** Intermediate Financial Institution ID */
  intermediateID?: InputMaybe<Scalars['String']['input']>;
  /** Intermediate Financial Institution name */
  intermediateName?: InputMaybe<Scalars['String']['input']>;
  /** Memo field */
  memo?: InputMaybe<Scalars['String']['input']>;
  /** Organization to which the standing instruction belongs */
  organizationKeyID: Scalars['String']['input'];
};

/** CreateTenantInput */
export type CreateTenantInput = {
  /** Taxbit's client identifier */
  clientID: Scalars['String']['input'];
  /** Taxbit's client secret */
  clientSecret: Scalars['String']['input'];
  /** Organization ID associated to the tenant */
  organizationKeyID: Scalars['String']['input'];
  /** Taxbit's tenant identifier */
  tenantID: Scalars['String']['input'];
};

/** CreateVaultOperation represents an operation where a vault is added to the organization */
export type CreateVaultOperation = Operation & {
  __typename: 'CreateVaultOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** the expected digest of the new organization after applying this change */
  newOrganizationDigest: Scalars['String']['output'];
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * policies is the list of policies that will apply to the new vault
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policies: Array<Policy>;
  /**
   * policy that applies to this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /** roleChanges represents the IAM role changes that will be applied with this operation. */
  roleChanges: Maybe<IAMRoleChanges>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /** vaultDescription is the description of the vault being created */
  vaultDescription: Scalars['String']['output'];
  /** vaultName is the name of the vault being created */
  vaultName: Scalars['String']['output'];
  /**
   * vaultPolicyContainerUpdate represents the binding changes that will be applied
   * to the vault policy container
   */
  vaultPolicyContainerUpdate: Maybe<VaultPolicyContainerUpdate>;
  /** The sub ID of the vault being created */
  vaultSubID: Scalars['String']['output'];
};


/** CreateVaultOperation represents an operation where a vault is added to the organization */
export type CreateVaultOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** CreateVaultOperation represents an operation where a vault is added to the organization */
export type CreateVaultOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/** Collateral encapsulation for crypto */
export type CryptoCollateralDetails = Node & {
  __typename: 'CryptoCollateralDetails';
  /** Crypto amount */
  amount: Amount;
  /** Identifier */
  id: Scalars['ID']['output'];
  /** Operation ID */
  operationID: Maybe<Scalars['ID']['output']>;
  /** Timestamp */
  timestamp: Scalars['Timestamp']['output'];
  /** Transaction type */
  transactionType: CollateralTransactionType;
  /** Collateral vault */
  vault: Vault;
};

/** Connection for pagination */
export type CryptoCollateralDetailsConnection = {
  __typename: 'CryptoCollateralDetailsConnection';
  /** Records */
  edges: Array<CryptoCollateralDetailsEdge>;
  /** Pagination info */
  pageInfo: PageInfo;
};

/** Edge for pagination */
export type CryptoCollateralDetailsEdge = {
  __typename: 'CryptoCollateralDetailsEdge';
  /** Record cursor */
  cursor: Scalars['ID']['output'];
  /** Record */
  node: CryptoCollateralDetails;
};

/** Crypto collateral input */
export type CryptoCollateralInput = {
  /** Amount */
  amount: Scalars['Decimal']['input'];
  /** Asset type */
  assetTypeID: Scalars['AssetTypeID']['input'];
  /** Created at */
  createdAt: Scalars['Timestamp']['input'];
  /** Created by */
  createdBy: Scalars['String']['input'];
  /** Operation ID */
  operationID: Scalars['ID']['input'];
  /** Transaction type */
  transactionType: CollateralTransactionType;
  /** Updated at */
  updatedAt: Scalars['Timestamp']['input'];
  /** Updated by */
  updatedBy: Scalars['String']['input'];
};

/** CurrentVote represents the current vote option for poll */
export type CurrentVote = {
  __typename: 'CurrentVote';
  /** text representation of the vote option */
  option: Maybe<Scalars['String']['output']>;
  /** The integer corresponding to the option, if there's no vote this field is nil */
  optionID: Scalars['Int']['output'];
  /** This could be a number for a fee percent, yes/no, etc. String is generic enough */
  status: ProposalVoteStatus;
};

/** CustodyAUC returns the summary of assets under custory held in Anchorage */
export type CustodyAUC = {
  __typename: 'CustodyAUC';
  /** perAssetTotals returns the total value of a each specific asset under custody */
  perAssetTotals: Array<Amount>;
  /** usdTotal returns total value of assets under custody in USD */
  usdTotal: Amount;
};

/** CxlRejReason represents */
export enum CxlRejReason {
  /** CxlRejReasonBroker represents */
  BROKER = 'BROKER',
  /** CxlRejReasonDuplicateClOrdID represents */
  DUPLICATE_CL_ORD_ID = 'DUPLICATE_CL_ORD_ID',
  /** Invalid represents */
  INVALID = 'INVALID',
  /** CxlRejReasonInvalidStrategy represents */
  INVALID_STRATEGY = 'INVALID_STRATEGY',
  /** CxlRejReasonOrderAlreadyInPendingCancelOrPendingReplaceStatus represents */
  ORDER_ALREADY_IN_PENDING_CANCEL_OR_PENDING_REPLACE_STATUS = 'ORDER_ALREADY_IN_PENDING_CANCEL_OR_PENDING_REPLACE_STATUS',
  /** CxlRejReasonOther represents */
  OTHER = 'OTHER',
  /** CxlRejReasonRateLimit represents */
  RATE_LIMIT = 'RATE_LIMIT',
  /** CxlRejReasonStaleRequest represents */
  STALE_REQUEST = 'STALE_REQUEST',
  /** CxlRejReasonTooLateToCancel represents */
  TOO_LATE_TO_CANCEL = 'TOO_LATE_TO_CANCEL',
  /** CxlRejReasonUnknownOrder represents */
  UNKNOWN_ORDER = 'UNKNOWN_ORDER'
}

/** DApp */
export type DApp = {
  __typename: 'DApp';
  /** ID of the Disclosure document user must accept before connecting to the DApp */
  disclosureID: Scalars['String']['output'];
  /** Is the DApp enabled by Anchorage Digital */
  enabled: Scalars['Boolean']['output'];
  /** iconURL */
  iconURL: Scalars['String']['output'];
  /** DApp ID */
  id: Scalars['String']['output'];
  /** name */
  name: Scalars['String']['output'];
  /** WalletConnect v2 App ProjectID */
  projectID: Scalars['String']['output'];
  /** Risk associated with the DApp. */
  risk: Maybe<DAppRisk>;
  /** url */
  url: Maybe<Scalars['String']['output']>;
};


/** DApp */
export type DAppiconURLArgs = {
  iconSize?: Scalars['Int']['input'];
};

/** DAppInfo contains info about an DApp in a particular Org. */
export type DAppInfo = {
  __typename: 'DAppInfo';
  /** The org's Vaults that have been "allowed" (by org admin) for the DApp */
  allowedVaults: Maybe<Array<Vault>>;
  /** The DApp info */
  dapp: DApp;
  /** Info about who last connected to the DApp, and when */
  lastSession: Maybe<WalletConnectSession>;
  /** The organizationKeyID the info is for */
  orgKeyID: Scalars['ID']['output'];
};

/** DAppInfoConnection contains a paginated list of DAppInfo's - implements the connections spec for pagination */
export type DAppInfoConnection = {
  __typename: 'DAppInfoConnection';
  /** edges returns a list of DAppInfoEdge objects representing a page of DAppInfo data. implements the connections spec for pagination */
  edges: Array<DAppInfoEdge>;
  /** pageInfo returns information about the page returned above. implements the connections spec for pagination */
  pageInfo: PageInfo;
};

/** DAppInfoEdge contains a DAppInfo and the cursor representing the same DAppInfo - implements the connections spec for pagination */
export type DAppInfoEdge = {
  __typename: 'DAppInfoEdge';
  /** Cursor implements the connections spec for pagination */
  cursor: Scalars['ID']['output'];
  /** node implements the connections spec for pagination */
  node: DAppInfo;
};

/** DAppRisk represents the risk assessment of connecting to a dApp. */
export type DAppRisk = {
  __typename: 'DAppRisk';
  /** Description is a human-readable description of the risk analysis. */
  description: Scalars['String']['output'];
  /** Details are additional details about the risk analysis. */
  details: Array<Scalars['String']['output']>;
  /** RiskLevel is the risk level of connecting to a dApp. */
  riskLevel: DAppRiskLevel;
};

/** DAppRiskLevel represents the risk level of connecting to a dApp. */
export enum DAppRiskLevel {
  /** HIGH means the risk level is high and the dApp is considered malicious. */
  HIGH = 'HIGH',
  /** LOW means the risk level is low and the dApp is considered safe. */
  LOW = 'LOW',
  /** MEDIUM means the risk level is medium and the dApp is considered somewhat risky. */
  MEDIUM = 'MEDIUM',
  /** UNAVAILABLE means the risk level could not be determined at this time due to an error. */
  UNAVAILABLE = 'UNAVAILABLE',
  /** UNDETERMINED means the risk level could not be determined at this time due lack of data. Since this state is non-informative, no banner should be displayed. */
  UNDETERMINED = 'UNDETERMINED'
}

/** Filters for DailyAPY queries */
export type DailyAPYFilters = {
  /** Filters out APYs before this date, results include this date */
  afterDate?: InputMaybe<Scalars['Date']['input']>;
  /** Filters out APYs after this date, results do not include this date */
  beforeDate?: InputMaybe<Scalars['Date']['input']>;
};

/** DailyLendingJobRequestType indicates which daily jobs to run */
export enum DailyLendingJobRequestType {
  /** ALL indicates all jobs */
  ALL = 'ALL',
  /** APPROVAL indicates reward approval job */
  APPROVAL = 'APPROVAL',
  /** BORROWER_INTEREST represents a borrower interest job type */
  BORROWER_INTEREST = 'BORROWER_INTEREST',
  /** LATE_FEE represents a late fee job type */
  LATE_FEE = 'LATE_FEE',
  /** LOAN represents a loan job type */
  LOAN = 'LOAN',
  /** REWARDS indicates rewards generation job */
  REWARDS = 'REWARDS',
  /** STAKES indicates stakes update job */
  STAKES = 'STAKES'
}

export type DataSource = Node & {
  __typename: 'DataSource';
  assetRatingProviders: Maybe<Array<AssetReferenceData>>;
  caAssetRefDataIssuers: Maybe<Array<CollateralAgreement>>;
  caPriceRefDataFirstIssuers: Maybe<Array<CollateralAgreement>>;
  caPriceRefDataForthIssuers: Maybe<Array<CollateralAgreement>>;
  caPriceRefDataIssuers: Maybe<Array<CollateralAgreement>>;
  caPriceRefDataSecondIssuers: Maybe<Array<CollateralAgreement>>;
  caPriceRefDataThirdIssuers: Maybe<Array<CollateralAgreement>>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['String']['output'];
  dataSourceTypeID: DataSourceDataSourceTypeID;
  id: Scalars['ID']['output'];
  marketDataProviders: Maybe<Array<PriceReferenceData>>;
  name: Scalars['String']['output'];
  status: DataSourceStatus;
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type DataSourceConnection = {
  __typename: 'DataSourceConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DataSourceEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** DataSourceDataSourceTypeID is enum for the field data_source_type_id */
export enum DataSourceDataSourceTypeID {
  DATA_SOURCE_TYPE_ASSET_RATINGS = 'DATA_SOURCE_TYPE_ASSET_RATINGS',
  DATA_SOURCE_TYPE_MARKET_DATA = 'DATA_SOURCE_TYPE_MARKET_DATA',
  DATA_SOURCE_TYPE_UNSPECIFIED = 'DATA_SOURCE_TYPE_UNSPECIFIED'
}

/** An edge in a connection. */
export type DataSourceEdge = {
  __typename: 'DataSourceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<DataSource>;
};

/** Ordering options for DataSource connections */
export type DataSourceOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DataSources. */
  field: DataSourceOrderField;
};

/** Properties by which DataSource connections can be ordered. */
export enum DataSourceOrderField {
  CREATED_AT = 'CREATED_AT',
  DATA_SOURCE_TYPE_ID = 'DATA_SOURCE_TYPE_ID',
  NAME = 'NAME',
  STATUS = 'STATUS'
}

/** DataSourceStatus is enum for the field status */
export enum DataSourceStatus {
  DATA_SOURCE_STATUS_DELETED = 'DATA_SOURCE_STATUS_DELETED',
  DATA_SOURCE_STATUS_DISABLED = 'DATA_SOURCE_STATUS_DISABLED',
  DATA_SOURCE_STATUS_ENABLED = 'DATA_SOURCE_STATUS_ENABLED',
  DATA_SOURCE_STATUS_UNSPECIFIED = 'DATA_SOURCE_STATUS_UNSPECIFIED'
}

/**
 * DataSourceWhereInput is used for filtering DataSource objects.
 * Input was generated by ent.
 */
export type DataSourceWhereInput = {
  and?: InputMaybe<Array<DataSourceWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** data_source_type_id field predicates */
  dataSourceTypeID?: InputMaybe<DataSourceDataSourceTypeID>;
  dataSourceTypeIDIn?: InputMaybe<Array<DataSourceDataSourceTypeID>>;
  dataSourceTypeIDNEQ?: InputMaybe<DataSourceDataSourceTypeID>;
  dataSourceTypeIDNotIn?: InputMaybe<Array<DataSourceDataSourceTypeID>>;
  /** asset_rating_providers edge predicates */
  hasAssetRatingProviders?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetRatingProvidersWith?: InputMaybe<Array<AssetReferenceDataWhereInput>>;
  /** ca_asset_ref_data_issuers edge predicates */
  hasCaAssetRefDataIssuers?: InputMaybe<Scalars['Boolean']['input']>;
  hasCaAssetRefDataIssuersWith?: InputMaybe<Array<CollateralAgreementWhereInput>>;
  /** ca_price_ref_data_first_issuers edge predicates */
  hasCaPriceRefDataFirstIssuers?: InputMaybe<Scalars['Boolean']['input']>;
  hasCaPriceRefDataFirstIssuersWith?: InputMaybe<Array<CollateralAgreementWhereInput>>;
  /** ca_price_ref_data_forth_issuers edge predicates */
  hasCaPriceRefDataForthIssuers?: InputMaybe<Scalars['Boolean']['input']>;
  hasCaPriceRefDataForthIssuersWith?: InputMaybe<Array<CollateralAgreementWhereInput>>;
  /** ca_price_ref_data_issuers edge predicates */
  hasCaPriceRefDataIssuers?: InputMaybe<Scalars['Boolean']['input']>;
  hasCaPriceRefDataIssuersWith?: InputMaybe<Array<CollateralAgreementWhereInput>>;
  /** ca_price_ref_data_second_issuers edge predicates */
  hasCaPriceRefDataSecondIssuers?: InputMaybe<Scalars['Boolean']['input']>;
  hasCaPriceRefDataSecondIssuersWith?: InputMaybe<Array<CollateralAgreementWhereInput>>;
  /** ca_price_ref_data_third_issuers edge predicates */
  hasCaPriceRefDataThirdIssuers?: InputMaybe<Scalars['Boolean']['input']>;
  hasCaPriceRefDataThirdIssuersWith?: InputMaybe<Array<CollateralAgreementWhereInput>>;
  /** market_data_providers edge predicates */
  hasMarketDataProviders?: InputMaybe<Scalars['Boolean']['input']>;
  hasMarketDataProvidersWith?: InputMaybe<Array<PriceReferenceDataWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<DataSourceWhereInput>;
  or?: InputMaybe<Array<DataSourceWhereInput>>;
  /** status field predicates */
  status?: InputMaybe<DataSourceStatus>;
  statusIn?: InputMaybe<Array<DataSourceStatus>>;
  statusNEQ?: InputMaybe<DataSourceStatus>;
  statusNotIn?: InputMaybe<Array<DataSourceStatus>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

/** DealerSettlement is a settlement */
export type DealerSettlement = {
  __typename: 'DealerSettlement';
  /**
   * Signed amount of the settlement
   * @deprecated Use tradingAmount instead
   */
  amount: Maybe<Amount>;
  /**
   * To whom is this dealer settlement assigned to
   * (ie. the user who initiated the transaction)
   */
  assignee: Maybe<Scalars['String']['output']>;
  /** Timestamp of creation of the Dealer settlement */
  createdAt: Scalars['Timestamp']['output'];
  /** The creator of the Dealer settlement */
  createdBy: Scalars['String']['output'];
  /** Dealer settlement details */
  details: Scalars['String']['output'];
  /** Entity of the dealer settlement */
  entity: Scalars['String']['output'];
  /** Id of the dealer settlement */
  id: Scalars['ID']['output'];
  /** Notes justifying the creation of the dealer settlement */
  notes: Maybe<Scalars['String']['output']>;
  /** Number of payments in this settlement */
  numberPayments: Maybe<Scalars['Int']['output']>;
  /** Dealer settlement status */
  status: DealerSettlementStatus;
  /**
   * Trading amount of the settlement
   * Should be used instead of `amount`
   */
  tradingAmount: Maybe<TradingAmount>;
  /** Timestamp of the last update of the Dealer settlement */
  updatedAt: Scalars['Timestamp']['output'];
  /** The updater of the Dealer settlement */
  updatedBy: Scalars['String']['output'];
};

/** Dealer Settlement connection for pagination */
export type DealerSettlementConnection = {
  __typename: 'DealerSettlementConnection';
  /** List of edges containing settlement and cursor information */
  edges: Array<Maybe<DealerSettlementEdge>>;
  /** Page details for the associated list of payment flow edges */
  pageInfo: PageInfo;
};

/** Filters to be applied to the payment flows to generate the settlement */
export type DealerSettlementCreateInput = {
  /** Asset of the settlement */
  filterAssetTypeID: Scalars['AssetTypeID']['input'];
  /** Counterparty of the settlement */
  filterCounterparty: Scalars['String']['input'];
  /** Optional start time of the settlement */
  filterCreatedAtAfter?: InputMaybe<Scalars['Timestamp']['input']>;
  /** Optional end time of the settlement */
  filterCreatedAtBefore?: InputMaybe<Scalars['Timestamp']['input']>;
};

/** Dealer Settlement edge for pagination */
export type DealerSettlementEdge = {
  __typename: 'DealerSettlementEdge';
  /** Cursor for the associated settlements */
  cursor: Scalars['String']['output'];
  /** Settlements */
  node: DealerSettlement;
};

/** SettlementStatus is the different statuses that a settlement can go through */
export enum DealerSettlementStatus {
  /** Dealer Settlement has been canceled */
  CANCELED = 'CANCELED',
  /** Dealer Settlement has been created */
  PENDING = 'PENDING',
  /** Dealer Settlement has been settled */
  SETTLED = 'SETTLED',
  /** The associated transaction has been initiated */
  TRANSACTION_INITIATED = 'TRANSACTION_INITIATED'
}

/** Trade execution report */
export type DealerTrade = {
  __typename: 'DealerTrade';
  /** aggressorSide represents */
  aggressorSide: Maybe<TradeSide>;
  /** amountCurrency represents */
  amountCurrency: Scalars['String']['output'];
  /** avgPxAllIn represents */
  avgPxAllIn: Maybe<Scalars['String']['output']>;
  /** Average price all in amount */
  avgPxAllInAmount: Maybe<Amount>;
  /** Amount that was bought */
  boughtAmount: Maybe<Amount>;
  /** cancelSessionID represents */
  cancelSessionID: Maybe<Scalars['String']['output']>;
  /** clOrdID represents */
  clOrdID: Maybe<Scalars['String']['output']>;
  /** createdAt represents */
  createdAt: Scalars['Timestamp']['output'];
  /** createdBy represents */
  createdBy: Scalars['String']['output'];
  /** cumAmt represents */
  cumAmt: Scalars['String']['output'];
  /** cumAmtAllIn represents denotes counter quantity inclusive of fees */
  cumAmtAllIn: Scalars['String']['output'];
  /** cumProviderFee represents */
  cumProviderFee: Maybe<Scalars['String']['output']>;
  /** cumQty represents */
  cumQty: Scalars['String']['output'];
  /** currency represents */
  currency: Scalars['String']['output'];
  /** cxlRejReason represents */
  cxlRejReason: Maybe<CxlRejReason>;
  /** Entity of the dealer trade */
  entity: Scalars['String']['output'];
  /** execID represents */
  execID: Maybe<Scalars['String']['output']>;
  /** execType represents */
  execType: Maybe<ExecType>;
  /** feeCurrency represents */
  feeCurrency: Maybe<Scalars['String']['output']>;
  /** flowType represents */
  flowType: Maybe<FlowType>;
  /** groupID represents */
  groupID: Maybe<Scalars['String']['output']>;
  /** id represents */
  id: Scalars['String']['output'];
  /** initiator represents */
  initiator: DealerTradeInitiator;
  /** internalSettlementID represents */
  internalSettlementID: Maybe<Scalars['String']['output']>;
  /** internalStatus represents */
  internalStatus: Maybe<InternalStatus>;
  /** isHistorical informs wether this is a spreadsheet imported dealer trade or not */
  isHistorical: Maybe<Scalars['Boolean']['output']>;
  /** lastAmt represents */
  lastAmt: Maybe<Scalars['String']['output']>;
  /** lastFee represents */
  lastFee: Maybe<Scalars['String']['output']>;
  /** lastFeeCurrency represents */
  lastFeeCurrency: Maybe<Scalars['String']['output']>;
  /** lastProviderFee represents */
  lastProviderFee: Maybe<Scalars['String']['output']>;
  /** lastPx represents */
  lastPx: Maybe<Scalars['String']['output']>;
  /** lastQty represents */
  lastQty: Maybe<Scalars['String']['output']>;
  /** leavesQty represents */
  leavesQty: Maybe<Scalars['String']['output']>;
  /** markets is the list of the counterparties where this trade has been executed at */
  markets: Array<Maybe<Scalars['String']['output']>>;
  /** OrdRejReason represents */
  ordRejReason: Maybe<OrdRejReason>;
  /** ordStatus represents */
  ordStatus: Maybe<OrdStatus>;
  /** ordType represents */
  ordType: OrdType;
  /** orderID represents */
  orderID: Maybe<Scalars['String']['output']>;
  /** orderQty represents */
  orderQty: Scalars['String']['output'];
  /** orderSessionID represents */
  orderSessionID: Maybe<Scalars['String']['output']>;
  /** origClOrdID represents */
  origClOrdID: Maybe<Scalars['String']['output']>;
  /** parentOrderID represents */
  parentOrderID: Maybe<Scalars['String']['output']>;
  /** parentRFQID represents */
  parentRFQID: Maybe<Scalars['String']['output']>;
  /** price represents */
  price: Maybe<Scalars['String']['output']>;
  /** Price amount */
  priceAmount: Maybe<Amount>;
  /** providerFeeCurrency represents */
  providerFeeCurrency: Maybe<Scalars['String']['output']>;
  /** revision represents */
  revision: Maybe<Scalars['Int']['output']>;
  /** side represents */
  side: TradeSide;
  /** Amount that was sold */
  soldAmount: Maybe<Amount>;
  /**
   * Source represents the channel where the trade originated from along
   * with other relevant data (eg. api key in case of api channel source)
   */
  source: TradeSource;
  /**
   * Source ID represents a source-dependent identifier of where the trade
   * originated from
   */
  sourceID: Maybe<Scalars['String']['output']>;
  /** strategy represents */
  strategy: TradeStrategy;
  /** subAccount represents */
  subAccount: Maybe<Scalars['String']['output']>;
  /** symbol represents */
  symbol: Scalars['String']['output'];
  /** textMessage represents */
  textMessage: Maybe<Scalars['String']['output']>;
  /** timeInForce represents */
  timeInForce: Maybe<TimeInForce>;
  /** transactTime represents */
  transactTime: Scalars['Timestamp']['output'];
};

/** DealerTradeInitiator represents */
export enum DealerTradeInitiator {
  /** Customer represents */
  CUSTOMER = 'CUSTOMER',
  /** Invalid represents */
  INVALID = 'INVALID',
  /** Trading desk represents */
  TRADING_DESK = 'TRADING_DESK'
}

/** Dealer trades connection for pagination */
export type DealerTradesConnection = {
  __typename: 'DealerTradesConnection';
  /** List of edges containing dealer trades and cursor information */
  edges: Array<Maybe<DealerTradesEdge>>;
  /** Page details for the associated list of dealer trades edges */
  pageInfo: PageInfo;
};

/** Dealer trades edge for pagination */
export type DealerTradesEdge = {
  __typename: 'DealerTradesEdge';
  /** Cursor for the associated dealer trades */
  cursor: Scalars['String']['output'];
  /** Dealer trades */
  node: DealerTrade;
};

/** DealerTradesFilters are the filters for tradingDealerTrades-like endpoints */
export type DealerTradesFilters = {
  /**
   * Counterparty internal name to filter for counterparty, use tradingCounterparties endpoint
   * to get the (name, displayName) pairs
   */
  filterCounterparty?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter for the trade with the given executiun ID */
  filterExecID?: InputMaybe<Scalars['ID']['input']>;
  /** Filter for trades with the given FlowType */
  filterFlowType?: InputMaybe<FlowType>;
  /** Filter for the trade with the given ID */
  filterID?: InputMaybe<Scalars['ID']['input']>;
  /** Initiator of the trade: CUSTOMER for automatic RFQs or TRADING_DESK for manual trades */
  filterInitiator?: InputMaybe<Array<DealerTradeInitiator>>;
  /** Filter for historical trades */
  filterIsHistorical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the client leg of the trade, filter for empty string to get all dealer trades with a missing client leg */
  filterParentOrderID?: InputMaybe<Scalars['String']['input']>;
  /** Filter for trades with the given side */
  filterSide?: InputMaybe<TradeSide>;
  /** Source of the trade */
  filterSource?: InputMaybe<Array<TradeSource>>;
  /** Filter for trades with the given symbol */
  filterSymbol?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter all trades after or at the given transact time */
  filterTransactTimeAfter?: InputMaybe<Scalars['Timestamp']['input']>;
  /** Filter all trades before the given transact time */
  filterTransactTimeBefore?: InputMaybe<Scalars['Timestamp']['input']>;
};

/** Information about a set of default settlement instructions */
export type DefaultSettlementInstructions = {
  __typename: 'DefaultSettlementInstructions';
  /** createdAt is the timestamp of creation of this record */
  createdAt: Scalars['Timestamp']['output'];
  /** createdBy is the email of the user who created this record */
  createdBy: Scalars['String']['output'];
  /** id is the identifier */
  id: Scalars['String']['output'];
  /** instructions is a stringified json with the assetType and address for each asset */
  instructions: Scalars['String']['output'];
};

/** A single pair of wallet and network, used in snParticipantUpdate */
export type DefaultWalletInput = {
  /** The network to update the default wallet for */
  networkID: Scalars['String']['input'];
  /** The wallet unique ID (or null if the caller wants to remove the default) */
  walletUniqueID?: InputMaybe<Scalars['String']['input']>;
};

/** DelegateOperation represents a delegate operation */
export type DelegateOperation = Operation & {
  __typename: 'DelegateOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** amount returns the associated amount we delegated, only available for v1 delegation */
  amount: Maybe<Amount>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /** The asset associated with this operation */
  asset: Asset;
  /** asset id from with the delegation was made */
  assetID: AssetID;
  /** Further information regarding this asset type associated with this operation */
  assetTypeInfo: AssetTypeInfo;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** canonicalOperationVersion is the version of the canonical delegation that must be used to sign this delegation */
  canonicalOperationVersion: Scalars['Int']['output'];
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** confirmations returns how many confirmations the transaction associated with this delegation has received */
  confirmations: Maybe<Scalars['Int']['output']>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** delegateType returns the type of delegation. available for all versions */
  delegateType: Maybe<DelegateType>;
  /** delegationAddress is the address that we are delegating to (ie. for staking, baking, inflation pools, etc.) */
  delegationAddress: Maybe<DelegationAddress>;
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * extraParams are additional asset type specific parameters that were set for this transaction
   * When constructing the canonical operation for signing, the extra params go in the ExtraParams field
   * as a map from asset type id (usually, parent asset type id) to params object. The convention of extra
   * params is to omit all empty fields, and if no extra params are set for an asset type, to omit the asset
   * type from the map entirely. Note that for legacy reasons this convention
   * is the opposite of the normal CanonicalOperation convention, where no fields are ever omitted.
   */
  extraParams: Maybe<ExtraParams>;
  /** final transaction fee charged for the delegation, detected on the blockchain */
  finalFee: Maybe<Amount>;
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** max transaction fee charged for the delegation */
  maxFee: Maybe<Amount>;
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * policy that applies to this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /**
   * replayProtectionParams are the parameters to be sent to the HSM for creating and signing
   * the transactions required to effect this ExecuteOperation. The user *is not* expected
   * to review the contents of this field. However it may contain information necessary to prevent
   * replays and must be included in the payload signed by the user. Each element is a hex-encoded
   * byte array.
   */
  replayProtectionParams: Maybe<Scalars['String']['output']>;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /** sendingBlockchainAddresses is a list of addresses from which a delegate operation is sent */
  sendingBlockchainAddresses: Array<BlockchainAddress>;
  /** signedTransaction is the blockchain transaction to be broadcast to the network */
  signedTransaction: Maybe<Scalars['String']['output']>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /**
   * sources are the originating addresses for the delegation
   * @deprecated use sendingBlockchainAddresses
   */
  sources: Array<Source>;
  /** transactionID is the blockchain transaction id once available */
  transactionID: Maybe<Scalars['String']['output']>;
  /** transactionSuccess indicates if the blockchain transaction succeeded */
  transactionSuccess: Maybe<Scalars['Boolean']['output']>;
  /**
   * usdValueFee is the USD value of the fee (finalFee if known, otherwise maxFee).
   * If both the finalFee and maxFee are not yet known, this field will be null
   */
  usdValueFee: Maybe<Scalars['String']['output']>;
  /** viewTransactionLink is the link to get more info about the transaction from a third party */
  viewTransactionLink: Maybe<Scalars['String']['output']>;
};


/** DelegateOperation represents a delegate operation */
export type DelegateOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** DelegateOperation represents a delegate operation */
export type DelegateOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/** DelegateType is an enum used for delegation, specifically V1 canonical operation */
export enum DelegateType {
  /** DELEGATE is when the delegation operation is bonding funds to a validator. */
  DELEGATE = 'DELEGATE',
  /** DELEGATE_DELEGATION_POOL is when the delegation operation is adding funds to a delegation pool */
  DELEGATE_DELEGATION_POOL = 'DELEGATE_DELEGATION_POOL',
  /** DELEGATE_STAKE_POOL is when the delegation operation is adding funds to a stake pool */
  DELEGATE_STAKE_POOL = 'DELEGATE_STAKE_POOL',
  /** INITIALIZE is when the delegation operation is creating a staking contract/validator */
  INITIALIZE = 'INITIALIZE',
  /** INITIALIZE_DELEGATION_POOL is when the delegation operation is creating a delegation pool */
  INITIALIZE_DELEGATION_POOL = 'INITIALIZE_DELEGATION_POOL',
  /** INITIALIZE_STAKE_POOL is when the delegation operation is creating a stake pool validator */
  INITIALIZE_STAKE_POOL = 'INITIALIZE_STAKE_POOL',
  /** INITIALIZE_VESTING is when the delegation operation is creating a vesting contract */
  INITIALIZE_VESTING = 'INITIALIZE_VESTING',
  /** REDELEGATE is when the delegation operation is moving the delegation to another validator */
  REDELEGATE = 'REDELEGATE',
  /** UNDELEGATE is when the delegation operation is unbonding funds from a validator. Known as 'unbond' in the cosmos docs */
  UNDELEGATE = 'UNDELEGATE',
  /** UNDELEGATE_DELEGATION_POOL is when the delegation operation is unbonding funds from a delegation pool */
  UNDELEGATE_DELEGATION_POOL = 'UNDELEGATE_DELEGATION_POOL',
  /** UNDELEGATE_STAKE_POOL is when the delegation operation is unbonding funds from a stake pool */
  UNDELEGATE_STAKE_POOL = 'UNDELEGATE_STAKE_POOL'
}

/** Represents a delegation address for assets */
export type DelegationAddress = {
  __typename: 'DelegationAddress';
  /** Indicates if this validator belongs to anchorage */
  anchorageValidator: Scalars['Boolean']['output'];
  /** The detail for celo validator group */
  celoValidatorGroupDetail: Maybe<CeloValidatorGroupDetail>;
  /** Status of delegation if assetID is provided */
  delegationStatus: Maybe<DelegationStatus>;
  /** Description of the delegation address */
  description: Scalars['String']['output'];
  /** The delegation address */
  destination: Scalars['String']['output'];
  /** Name of the delegation address */
  name: Scalars['String']['output'];
  /**
   * The detail for the validator, or null if not available
   * The concrete type depends on the asset type as the details are asset type specific
   */
  validatorDetail: Maybe<ValidatorDetail>;
};

/** Status of delegation if assetID is provided */
export type DelegationStatus = {
  __typename: 'DelegationStatus';
  /** The total active amount belonging to the user, this earns the reward */
  active: Maybe<Amount>;
  /** The amount being delegated by the user right now */
  delegating: Maybe<Amount>;
  /** The pending delegated amount to be activated by the user */
  pending: Maybe<Amount>;
};

/** DeleteKYCQuestionInput is used for soft delete KYCQuestion object. */
export type DeleteKYCQuestionInput = {
  /** The id of the questionnaire to be deleted */
  id: Scalars['ID']['input'];
};

/** DeleteKYCQuestionnaireInput is used for soft delete KYCQuestionnaire object. */
export type DeleteKYCQuestionnaireInput = {
  /** The id of the questionnaire to be deleted */
  id: Scalars['ID']['input'];
};

/** Payload response for the depositAddressCreate mutation */
export type DepositAddressCreatePayload = {
  __typename: 'DepositAddressCreatePayload';
  /** The BlockchainAddress info for the address created by the mutation */
  blockchainAddress: BlockchainAddress;
};

/** DepositAttribution represents a deposit that went through the attribution process */
export type DepositAttribution = {
  __typename: 'DepositAttribution';
  /** When this deposit attribution was attributed */
  attributedAt: Maybe<Scalars['Date']['output']>;
  /** Status of the attribution done to this deposit */
  attributionStatus: DepositAttributionStatus;
  /** Type of the attribution done to this deposit */
  attributionType: Maybe<DepositAttributionType>;
  /** When this deposit attribution was created */
  createdAt: Scalars['Date']['output'];
  /** User that created the attribution (usually is automatic) */
  createdBy: Scalars['String']['output'];
  /** Notes added to the attribution submission */
  notes: Maybe<Scalars['String']['output']>;
  /** ID of the deposit */
  operation: DepositEventOperation;
  /** Originator associated with the  attribution */
  originator: Maybe<DepositAttributionOriginator>;
  /** Source address associated to the deposit attributions */
  sourceAddress: BlockchainAddress;
  /** When this deposit attribution was last updated */
  updatedAt: Maybe<Scalars['Date']['output']>;
  /** User that updated the attribution (if done manually) */
  updatedBy: Maybe<Scalars['String']['output']>;
  /**
   * User that did the attribution (if done manually)
   * @deprecated Splitted in createdBy and updatedBy.
   */
  user: Maybe<Scalars['String']['output']>;
  /** Type of wallet associated with the attribution */
  walletType: DepositAttributionWalletType;
};

/** DepositAttributionConnection implements the connections spec */
export type DepositAttributionConnection = {
  __typename: 'DepositAttributionConnection';
  /** edges implements the connections spec */
  edges: Array<Maybe<DepositAttributionEdge>>;
  /** pageInfo implements the connections spec */
  pageInfo: PageInfo;
};

/** DepositAttributionEdge implements the connections spec */
export type DepositAttributionEdge = {
  __typename: 'DepositAttributionEdge';
  /** cursor implements the connections spec */
  cursor: Scalars['String']['output'];
  /** node implements the connections spec */
  node: DepositAttributionGrouping;
};

/** DepositAttributionGrouping represents a deposit that went through the attribution process */
export type DepositAttributionGrouping = {
  __typename: 'DepositAttributionGrouping';
  /** List of all attributions associated to the source address */
  attributions: Array<Maybe<DepositAttribution>>;
  /** Key grouped by. Will be either an operationID (when grouping by operation), or a source address (when grouping by source address) */
  key: Scalars['String']['output'];
};

/** DepositAttributionGroupingType represents the possible groupings of deposit attribution entries */
export enum DepositAttributionGroupingType {
  /** Group deposit attributions by deposit event operation. The same source address might be present on multiple deposit event operations. */
  BY_OPERATION = 'BY_OPERATION',
  /** Group deposit attributions by source address. A deposit event operation with multiple source addresses will appear on multiple groups. */
  BY_SOURCE_ADDRESS = 'BY_SOURCE_ADDRESS'
}

/** DepositAttributionOriginator represents a single originator that can be associated with a deposit attribution */
export type DepositAttributionOriginator = {
  __typename: 'DepositAttributionOriginator';
  /** Country of the deposit attribution originator */
  originatorCountry: Scalars['String']['output'];
  /** Name of the deposit attribution originator */
  originatorName: Scalars['String']['output'];
};

/** DepositAttributionPair represents the operationID/sourceAddress pair associated with the attribution */
export type DepositAttributionPair = {
  /** Operation ID of the deposit being attributed */
  operationID: Scalars['String']['input'];
  /** Source Address of the deposit being attributed */
  sourceAddress: Scalars['String']['input'];
};

/** DepositAttributionSortFields are the options for sorting a list of deposit attributions */
export enum DepositAttributionSortFields {
  /** CREATED_AT is the created timestamp of the deposit attribution */
  CREATED_AT = 'CREATED_AT'
}

export type DepositAttributionSpamThreshold = Node & {
  __typename: 'DepositAttributionSpamThreshold';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isEnable: Scalars['Boolean']['output'];
  thresholdAmount: Amount;
  updatedAt: Maybe<Scalars['Time']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type DepositAttributionSpamThresholdConnection = {
  __typename: 'DepositAttributionSpamThresholdConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DepositAttributionSpamThresholdEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DepositAttributionSpamThresholdEdge = {
  __typename: 'DepositAttributionSpamThresholdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<DepositAttributionSpamThreshold>;
};

/** Ordering options for DepositAttributionSpamThreshold connections */
export type DepositAttributionSpamThresholdOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DepositAttributionSpamThresholds. */
  field: DepositAttributionSpamThresholdOrderField;
};

/** Properties by which DepositAttributionSpamThreshold connections can be ordered. */
export enum DepositAttributionSpamThresholdOrderField {
  ASSET_TYPE_ID = 'ASSET_TYPE_ID',
  CREATED_AT = 'CREATED_AT',
  IS_ENABLE = 'IS_ENABLE',
  THRESHOLD = 'THRESHOLD',
  UPDATED_AT = 'UPDATED_AT'
}

/**
 * DepositAttributionSpamThresholdWhereInput is used for filtering DepositAttributionSpamThreshold objects.
 * Input was generated by ent.
 */
export type DepositAttributionSpamThresholdWhereInput = {
  and?: InputMaybe<Array<DepositAttributionSpamThresholdWhereInput>>;
  /** asset_type_id field predicates */
  assetTypeID?: InputMaybe<Scalars['AssetTypeID']['input']>;
  assetTypeIDContains?: InputMaybe<Scalars['AssetTypeID']['input']>;
  assetTypeIDContainsFold?: InputMaybe<Scalars['AssetTypeID']['input']>;
  assetTypeIDEqualFold?: InputMaybe<Scalars['AssetTypeID']['input']>;
  assetTypeIDGT?: InputMaybe<Scalars['AssetTypeID']['input']>;
  assetTypeIDGTE?: InputMaybe<Scalars['AssetTypeID']['input']>;
  assetTypeIDHasPrefix?: InputMaybe<Scalars['AssetTypeID']['input']>;
  assetTypeIDHasSuffix?: InputMaybe<Scalars['AssetTypeID']['input']>;
  assetTypeIDIn?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  assetTypeIDLT?: InputMaybe<Scalars['AssetTypeID']['input']>;
  assetTypeIDLTE?: InputMaybe<Scalars['AssetTypeID']['input']>;
  assetTypeIDNEQ?: InputMaybe<Scalars['AssetTypeID']['input']>;
  assetTypeIDNotIn?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_enable field predicates */
  isEnable?: InputMaybe<Scalars['Boolean']['input']>;
  isEnableNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<DepositAttributionSpamThresholdWhereInput>;
  or?: InputMaybe<Array<DepositAttributionSpamThresholdWhereInput>>;
  /** threshold field predicates */
  threshold?: InputMaybe<Scalars['Decimal']['input']>;
  thresholdGT?: InputMaybe<Scalars['Decimal']['input']>;
  thresholdGTE?: InputMaybe<Scalars['Decimal']['input']>;
  thresholdIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  thresholdLT?: InputMaybe<Scalars['Decimal']['input']>;
  thresholdLTE?: InputMaybe<Scalars['Decimal']['input']>;
  thresholdNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  thresholdNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

/** DepositAttributionStatus are the possible status of a deposit attribution fragment */
export enum DepositAttributionStatus {
  /** ATTRIBUTED represents deposit attributions that have an originator name and country attributed */
  ATTRIBUTED = 'ATTRIBUTED',
  /** BLOCKED represents deposit attributions where the screening process resulted in insuccess */
  BLOCKED = 'BLOCKED',
  /** INITIATED represents newly created deposit attributions subject to a first attempt using the automatic attribution process */
  INITIATED = 'INITIATED',
  /** NON_ATTRIBUTABLE represents deposit attributions that cannot be attributed to/by any user */
  NON_ATTRIBUTABLE = 'NON_ATTRIBUTABLE',
  /** PENDING  represents deposits that have pending attributions */
  PENDING = 'PENDING',
  /** UNDER_REVIEW represents deposit attributions that have an ongoing review process */
  UNDER_REVIEW = 'UNDER_REVIEW',
  /** UNKNOWN represents an invalid deposit attribution state */
  UNKNOWN = 'UNKNOWN'
}

/** DepositAttributionType are the options for sorting a list of affiliates */
export enum DepositAttributionType {
  /** AUTOMATIC represents deposits automatically attributed */
  AUTOMATIC = 'AUTOMATIC',
  /** CLIENT_API represents deposits attributed via client api */
  CLIENT_API = 'CLIENT_API',
  /** INTERNAL represents deposits automatically attributed due to being internal anchorage addresses */
  INTERNAL = 'INTERNAL',
  /** INTRA_GROUP represents deposits automatically attributed due to being internal anchorage addresses but from a different anchorage entity */
  INTRA_GROUP = 'INTRA_GROUP',
  /** MANUAL_CLIENT represents deposits manually attributed externally by client (org users) */
  MANUAL_CLIENT = 'MANUAL_CLIENT',
  /** MANUAL_STAFF represents deposits manually attributed internally by Anchorage (staff users) */
  MANUAL_STAFF = 'MANUAL_STAFF',
  /** SMART_CONTRACT represents deposits automatically due to an interaction with a trusted smart contract */
  SMART_CONTRACT = 'SMART_CONTRACT',
  /** SPAM represents deposits attributed as spam */
  SPAM = 'SPAM',
  /** SPAM_CLIENT represents deposits attributed as spam by clients */
  SPAM_CLIENT = 'SPAM_CLIENT',
  /** SPAM_CLIENT_API represents deposits attributed as spam by clients via API */
  SPAM_CLIENT_API = 'SPAM_CLIENT_API',
  /** SPAM_STAFF represents deposits attributed as spam by staff */
  SPAM_STAFF = 'SPAM_STAFF',
  /** TRAVEL_RULE_CIPHERTRACE represents deposits automatically attributed using travel rule info from ciphertrace */
  TRAVEL_RULE_CIPHERTRACE = 'TRAVEL_RULE_CIPHERTRACE',
  /** TRAVEL_RULE_TRUST represents deposits automatically attributed using travel rule info from trust */
  TRAVEL_RULE_TRUST = 'TRAVEL_RULE_TRUST',
  /** TRUSTED_SOURCES represents deposits automatically attributed using the address trusted sources */
  TRUSTED_SOURCES = 'TRUSTED_SOURCES',
  /** TRUSTED_SOURCES_CLIENT represents deposits automatically attributed using the client defined trusted sources */
  TRUSTED_SOURCES_CLIENT = 'TRUSTED_SOURCES_CLIENT',
  /** TRUSTED_SOURCES_STAFF represents deposits automatically attributed using the staff defined trusted sources */
  TRUSTED_SOURCES_STAFF = 'TRUSTED_SOURCES_STAFF'
}

/** DepositAttributionWalletType are the options for wallet types for deposit attributions */
export enum DepositAttributionWalletType {
  /** CUSTODIAL represents a wallet where the owner does not have complete control over the wallet as they do not have the private key, a third party does. */
  CUSTODIAL = 'CUSTODIAL',
  /** SELF_HOSTED represents a wallet where the owner has complete control over the wallet as they have the private key. */
  SELF_HOSTED = 'SELF_HOSTED',
  /** UNKNOWN represents an invalid wallet type */
  UNKNOWN = 'UNKNOWN'
}

/** Represents a deposit event */
export type DepositEventOperation = Operation & {
  __typename: 'DepositEventOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /**
   * amount is the total balance change that resulted from the deposit
   * @deprecated use amountDeposit and amountFee and don't try to add them together
   */
  amount: Amount;
  /** amountDeposit is the amount that was deposited */
  amountDeposit: Amount;
  /** same as amountDeposit but using historical pricing based on the end time of the operation */
  amountDepositAtEndTime: Amount;
  /** amountFee is the amount that was charged as a fee by the blockchain during the deposit */
  amountFee: Amount;
  /** same as amountFee but using historical pricing based on the end time of the operation */
  amountFeeAtEndTime: Amount;
  /** amountTotal is amountDeposit - amountFee and is set only if feeSharesAssetTypeWithAmount is true */
  amountTotal: Maybe<Amount>;
  /** same as amountTotal but using historical pricing based on the end time of the operation */
  amountTotalAtEndTime: Maybe<Amount>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /** The asset associated with this operation */
  asset: Asset;
  /** attributions are the deposit attributions record associated with a DepositEvent, we return one per source address */
  attributions: Maybe<Array<Maybe<DepositAttribution>>>;
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** confirmations returns how many confirmations the transaction associated with this deposit event has received */
  confirmations: Scalars['Int']['output'];
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** delegationAddress describes the address that sent a delegation reward (ie. for staking, baking, inflation pools, etc.) which should match the sending address */
  delegationAddress: Maybe<DelegationAddress>;
  /**
   * The deposit operation linked to this deposit event
   * @deprecated always null - deposits don't exist any more
   */
  deposit: Maybe<DepositOperation>;
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * feeSharesAssetTypeWithAmount is true if the fee and the amount withdrawn share
   * the same asset type. Returns false otherwise, or if the fee is not known.
   */
  feeSharesAssetTypeWithAmount: Scalars['Boolean']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** isChange returns true if the deposit event is a noop transfer to the owner of the same source address */
  isChange: Scalars['Boolean']['output'];
  /** isFromGasStation indicates a deposit came from the gas station and is meant for withdraw/transfer fees */
  isFromGasStation: Scalars['Boolean']['output'];
  /**
   * linkedExecuteOperation is the withdraw/transfer/etc operation that created this
   * DepositEvent, if one exists. The linked operation may or may not belong to the same organization.
   */
  linkedExecuteOperation: Maybe<Operation>;
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * receivingAddress is the address on which a deposit is to be received
   * @deprecated use receivingBlockchainAddress
   */
  receivingAddress: Scalars['String']['output'];
  /** receivingBlockchainAddress is the address on which a deposit is to be received */
  receivingBlockchainAddress: BlockchainAddress;
  /** receivingWallet is the wallet that received the deposit */
  receivingWallet: Wallet;
  /** riskAnalysis is the collected risk information for this deposit if we have any */
  riskAnalysis: Maybe<TransactionRiskAnalysis>;
  /**
   * sendingAddress is the addresses from which a deposit is sent
   * @deprecated use sendingBlockchainAddresses
   */
  sendingAddresses: Array<Scalars['String']['output']>;
  /**
   * sendingBlockchainAddresses is a list of addresses from which a deposit is sent
   * @deprecated use sendingBlockchainAddresses2
   */
  sendingBlockchainAddresses: Array<BlockchainAddress>;
  /** same as sendingBlockchainAddresses but with additional info if the sender is a settlement network participant */
  sendingBlockchainAddresses2: Array<BlockchainAddressWithParticipant>;
  /** sendingWallet is the wallet from which the deposit was sent */
  sendingWallet: Maybe<Wallet>;
  /** sendingWireData contains information about the sending wire transfer */
  sendingWireData: Maybe<WireData>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /** tokenID is the non-fungible token id */
  tokenID: Maybe<Scalars['String']['output']>;
  /** transactionID is the blockchain transaction id */
  transactionID: Scalars['String']['output'];
  /** viewTransactionLink is the link to get more info about the transaction from a third party */
  viewTransactionLink: Scalars['String']['output'];
};


/** Represents a deposit event */
export type DepositEventOperationattributionsArgs = {
  filterAttributionStatus?: InputMaybe<Array<DepositAttributionStatus>>;
  isAttributed?: InputMaybe<Scalars['Boolean']['input']>;
  sortDESC?: InputMaybe<Scalars['Boolean']['input']>;
  sortField?: InputMaybe<DepositAttributionSortFields>;
};


/** Represents a deposit event */
export type DepositEventOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/** DepositID is the ID of a specific deposit to a vault and specified asset */
export type DepositID = {
  __typename: 'DepositID';
  /** assetSubID is used to differentiate between multiple assets of the same type within a vault */
  assetSubID: Scalars['String']['output'];
  /** assetTypeID is the type of asset being deposited */
  assetTypeID: Scalars['AssetTypeID']['output'];
  /** Further information regarding this asset type */
  assetTypeInfo: AssetTypeInfo;
  /** depositSubID represents a unique identifier for this deposit */
  depositSubID: Scalars['String']['output'];
  /** organizationKeyID stores the organization that we're depositing to */
  organizationKeyID: Scalars['String']['output'];
  /** vaultID is the ID of the vault we are depositing to */
  vaultID: VaultID;
};

/** Fiat Deposit Instructions for a wallet */
export type DepositInstructions = {
  __typename: 'DepositInstructions';
  /**
   * Bank Address
   * @deprecated Use 'bankMailingAddress' instead
   */
  bankAddress: Array<Scalars['String']['output']>;
  /** Bank Mailing Address */
  bankMailingAddress: MailingAddress;
  /** Bank Name */
  bankName: Scalars['String']['output'];
  /** Bank Routing Number */
  bankRoutingNumber: Scalars['String']['output'];
  /** Bank Swift Code */
  bankSwiftCode: Scalars['String']['output'];
  /** Benificiary Account Number */
  beneficiaryAccountNumber: Scalars['String']['output'];
  /**
   * Benificiary Address
   * @deprecated Use 'beneficiaryMailingAddress' instead
   */
  beneficiaryAddress: Array<Scalars['String']['output']>;
  /** Benificiary Mailing Address */
  beneficiaryMailingAddress: MailingAddress;
  /** Benificiary Name */
  beneficiaryName: Scalars['String']['output'];
  /** Memo field to idenitfy the wallet */
  memoField: Scalars['String']['output'];
};

/** DepositOperation represents a deposit operation */
export type DepositOperation = Operation & {
  __typename: 'DepositOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /** The asset associated with this operation */
  asset: Asset;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** Deposit events that are linked to this deposit */
  depositEvents: OperationConnection;
  /** depositID defines the asset ID, vault ID, and sub ID for this deposit */
  depositID: DepositID;
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * policy that applies to this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /**
   * receivingAddress is the address on which a deposit is to be received, populated by the blockchain service
   * @deprecated moving to signedAddress
   */
  receivingAddress: Maybe<Scalars['String']['output']>;
  /** receivingBlockchainAddress is the address on which a deposit is to be received */
  receivingBlockchainAddress: Maybe<BlockchainAddress>;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /** signedAddress is the address signed by the organization key on which a deposit is to be received, populated by the blockchain service */
  signedAddress: Maybe<SignedAddress>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
};


/** DepositOperation represents a deposit operation */
export type DepositOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** DepositOperation represents a deposit operation */
export type DepositOperationdepositEventsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortDESC?: InputMaybe<Scalars['Boolean']['input']>;
  sortField?: InputMaybe<OperationSortFields>;
};


/** DepositOperation represents a deposit operation */
export type DepositOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/**
 * DepositRewardType an Operation represents
 * Subset of DepositType in lib/operation/depositevent/operation.go
 * Only supports types stored on the operation in ExtraData
 * Operations contain reward types, see depositevent.IsRewardType()
 */
export enum DepositRewardType {
  /** ANCHORAGE_DELEGATION_REWARD is a delegation reward from an Anchorage validator */
  ANCHORAGE_DELEGATION_REWARD = 'ANCHORAGE_DELEGATION_REWARD',
  /** DELEGATION_REWARD is a delegation reward */
  DELEGATION_REWARD = 'DELEGATION_REWARD',
  /** NONE is a plain or non-reward deposit */
  NONE = 'NONE',
  /** NON_ANCHORAGE_STAKING_REWARD is a staking reward coming from a non-Anchorage validator (ETH staking) */
  NON_ANCHORAGE_STAKING_REWARD = 'NON_ANCHORAGE_STAKING_REWARD',
  /** STAKING_REWARD is a staking reward */
  STAKING_REWARD = 'STAKING_REWARD'
}

/** DepositScreening screening logs */
export type DepositScreening = {
  __typename: 'DepositScreening';
  /** amount of transaction */
  amount: Maybe<Scalars['String']['output']>;
  /** asset id */
  asset: Maybe<Scalars['String']['output']>;
  /** createdAt time */
  createdAt: Maybe<Scalars['String']['output']>;
  /** email of reviewer */
  email: Maybe<Scalars['String']['output']>;
  /** note left by reviewer */
  note: Maybe<Scalars['String']['output']>;
  /** operationID for deposit source info */
  operationID: Scalars['String']['output'];
  /** orgId is organization id */
  orgId: Maybe<Scalars['String']['output']>;
  /** organization name */
  orgName: Maybe<Scalars['String']['output']>;
  /** reviewStatus of deposit screening */
  reviewStatus: DepositScreeningStatus;
  /** transactionId of operation */
  transactionId: Maybe<Scalars['String']['output']>;
  /** transmissionDate created date */
  transmissionDate: Maybe<Scalars['String']['output']>;
};

/** Deposit screening pagination connection. */
export type DepositScreeningConnection = {
  __typename: 'DepositScreeningConnection';
  /** List of edges containing deposit screening and cursor information */
  edges: Array<DepositScreeningEdge>;
  /** Page details for the deposit screening. */
  pageInfo: PageInfo;
};

/** Deposit screening edge connection. */
export type DepositScreeningEdge = {
  __typename: 'DepositScreeningEdge';
  /** Cursor for the deposit screenings. */
  cursor: Scalars['String']['output'];
  /** Latest deposit screenings. */
  node: DepositScreening;
};

/** Possible deposit screening statuses */
export enum DepositScreeningStatus {
  /** Completed screening */
  COMPLETED = 'COMPLETED',
  /** Screening in progress */
  IN_PROGRESS = 'IN_PROGRESS',
  /** Waiting screening */
  WAITING = 'WAITING'
}

/** DepositSource full information per operationID */
export type DepositSource = {
  __typename: 'DepositSource';
  /** history for deposit source info */
  logs: Maybe<Array<DepositScreening>>;
  /** vasps of deposit screening */
  vasps: Array<DepositSourceInfo>;
};

/** DepositSourceInfo VASP info of deposit source */
export type DepositSourceInfo = {
  __typename: 'DepositSourceInfo';
  /** address of deposit source */
  address: Scalars['String']['output'];
  /** ciphertrace response for source address ownership */
  ciphertrace: Maybe<Scalars['String']['output']>;
  /** operationId for deposit source info */
  operationId: Scalars['String']['output'];
  /** trm response for source address ownership */
  trm: Maybe<Scalars['String']['output']>;
  /** trust response for source address ownership */
  trust: Maybe<Scalars['String']['output']>;
};

/** Destination identifies valid forms of destinations for a RoleAction */
export type Destination = {
  __typename: 'Destination';
  /** addresses indicates any specific addresses which are valid. An empty list does not indicate all addresses are valid. */
  addresses: Array<AMLBlockchainAddress>;
  /** anyAddress indicates any address is valid, similar to a wildcard. */
  anyAddress: Scalars['Boolean']['output'];
  /** anyVault indicates any vault is valid, similar to a wildcard. */
  anyVault: Scalars['Boolean']['output'];
  /** anyWallet indicates any wallet is valid, similar to a wildcard. */
  anyWallet: Scalars['Boolean']['output'];
  /** vaultUniqueIDs indicates any allowed destination vault id. An empty list does not indicate all vaults are valid. */
  vaultUniqueIDs: Maybe<Array<Scalars['String']['output']>>;
  /**
   * vaults indicates any specific vaults which are valid. An empty list does not indicate all vaults are valid.
   * @deprecated does not work for vaults from other orgs, use vaultUniqueIDs instead
   */
  vaults: Array<Vault>;
  /** walletUniqueIDs indicates any allowed destination wallet id. An empty list does not indicate all wallets are valid. */
  walletUniqueIDs: Maybe<Array<Scalars['String']['output']>>;
  /**
   * wallets indicates any specific wallets which are valid. An empty list does not indicate all wallets are valid.
   * @deprecated does not work for wallets from other orgs, use walletUniqueIDs instead
   */
  wallets: Array<Wallet>;
};

/** DestinationID is the ID of a trusted destination */
export type DestinationID = {
  __typename: 'DestinationID';
  /** destinationSubID is the ID of the trusted destination within the organization */
  destinationSubID: Scalars['String']['output'];
  /** organizationKeyID is the ID of the organization this vault is in */
  organizationKeyID: Scalars['String']['output'];
};

/** Represents the status of a device considering the recent activity of the user */
export enum DeviceStatus {
  /** User has been using the app recently and can perform actions */
  ACTIVE = 'ACTIVE',
  /** User did not use the app for a while, and is at-risk of losing access */
  IDLE = 'IDLE',
  /**
   * User lost device or hasn't used app for a long time.
   * At this stage, user cannot perform any actions inside the organization
   */
  LOST = 'LOST'
}

/** Extra parameters for Diem */
export type DiemExtraParams = {
  __typename: 'DiemExtraParams';
  /** tradeID to use in transactions for Designated Dealer withdrawals */
  tradeID: Maybe<Scalars['String']['output']>;
};

/** DigitalAssetAccountMetadata holds metadata of digital asset accounts */
export type DigitalAssetAccountMetadata = {
  __typename: 'DigitalAssetAccountMetadata';
  filecoinAddress: Maybe<Scalars['String']['output']>;
  vaultSubID: Maybe<Scalars['Int']['output']>;
  vaultUniqueID: Maybe<Scalars['String']['output']>;
  walletID: Maybe<Scalars['String']['output']>;
};

/** DisableVaultOperation represents an operation where a vault is disabled */
export type DisableVaultOperation = Operation & {
  __typename: 'DisableVaultOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** the expected digest of the new organization after applying this change */
  newOrganizationDigest: Scalars['String']['output'];
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * policy required in order to satisfy this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /** roleChanges represents the IAM role changes that will be applied with this operation. */
  roleChanges: Maybe<IAMRoleChanges>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /** Policies being updated by this operation */
  updatedPolicies: Array<VaultPolicyUpdate>;
  /** The vault being disabled */
  vault: Vault;
  /**
   * vaultPolicyContainerUpdate represents the binding changes that will be applied
   * to the vault policy container
   */
  vaultPolicyContainerUpdate: Maybe<VaultPolicyContainerUpdate>;
};


/** DisableVaultOperation represents an operation where a vault is disabled */
export type DisableVaultOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** DisableVaultOperation represents an operation where a vault is disabled */
export type DisableVaultOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/**
 * Disclosure represents a disclosure document. There is a 1-to-1 relationship
 * between a graphql Disclosure and a markdown file in
 * source/go/lib/disclosures/documents
 *
 * Results are sorted alphabetically ascending by the human readable title field.
 * The list of disclosures in the UI code can trust that the response is sorted.
 *
 * Furthermore, each disclosure contains info relevant to the user who requested it
 */
export type Disclosure = {
  __typename: 'Disclosure';
  /**
   * disclosureID is the internal key to identify a document (and all it's versions)
   * This is not to be user facing.
   *
   * The reason this is a disclosureID and not a documentID is that once a
   * disclosureID has been used, all future versions MUST use the same disclosureID
   * even if the human facing title changes.
   */
  disclosureID: Scalars['String']['output'];
  /**
   * involvesAccount a convenience field which indicates whether the disclosure
   * must be accepted for each account a user is involved with.
   *
   * This value is determined by whether the disclosure markdown file is in the
   * useronly/ or the involvesaccount/ folder (inside source/go/lib/disclosures/documents)
   *
   * If true, the disclosure must be accepted per (user, account) disclosure,
   * meaning the user is accepting a disclosure in "relation" to an organization.
   * There will be one or more rows in disclosure.reviews
   *
   * If false, the disclosure must be accepted per user, and there will only be one
   * row in disclosure.reviews
   *
   * NOTE: All disclosure acceptances involve a user. An acceptance is never on
   * "behalf" of an account (as in, other users of that account will still have to
   * independently accept the disclosure).
   */
  involvesAccount: Scalars['Boolean']['output'];
  /** latest indicates whether the disclosure is the latest version */
  latest: Scalars['Boolean']['output'];
  /** markdownContent is the document formatted in markdown */
  markdownContent: Scalars['String']['output'];
  /**
   * A list of reviews (both accepted and not-yet-accepted).
   * See DisclosureReview for more information.
   */
  reviews: Array<DisclosureReview>;
  /**
   * tag represents a type of action that would require this disclosure. For example,
   * a hypothetical action could be "trade", and there may be multiple types of
   * disclosures for trading. This abstraction allows us to have multiple
   * disclosures per action. This also gives us the flexibility to disable a
   * disclosure in the backend by creating a new latest document with a tag value
   * of "deprecated".
   */
  tag: Scalars['String']['output'];
  /** title is the human readable title */
  title: Scalars['String']['output'];
  /**
   * version is the sequential version number of the document, starting from 1.
   * Version numbers are monotonically increasing over time and NEVER skipped.
   * This is an integer because we won't ever run into overflow issues, and
   * expecting an integer as part of the acceptDisclosure() mutation removes the
   * issue of parsing an integer
   */
  version: Scalars['Int']['output'];
};

/**
 * A disclosure contains a list of reviews, both accepted and not-yet-accepted.
 * Unaccepted reviews are useful when prompting the user and showing a list of
 * accounts they are accepting for.
 * Accepted reviews are useful when the user wants to see what documents they
 * have agreed to.
 */
export type DisclosureReview = {
  __typename: 'DisclosureReview';
  /**
   * acceptedAt is the seconds unix timestamp at which the acceptance was received
   * by the backend. It is null if not accepted
   */
  acceptedAt: Maybe<Scalars['String']['output']>;
  /**
   * If disclosure.involvesAccount == false => accountKeyId is null
   * If disclosure.involvesAccount == true => accountKeyId contains a value and
   * there is a DisclosureReview for each account
   */
  accountID: Maybe<Scalars['String']['output']>;
  /**
   * The human readable name of the account. Useful for showing the user which
   * account they are accepting in relation to.
   *
   * This field exists if accountID exists (which only exists if InvolvesAccount is
   * true).
   */
  accountName: Maybe<Scalars['String']['output']>;
};

/** Distress signal information */
export type Distress = {
  __typename: 'Distress';
  /** Raw distress signal value */
  distressSignal: Scalars['Int']['output'];
  /** Indicates whether this meets the distress threshold */
  isDistress: Scalars['Boolean']['output'];
};

/** Represents an Document Delivery contact setting */
export type DocumentDeliveryContact = {
  /** Refers to the email of the contact */
  email: Scalars['String']['input'];
  /** Refers to the name of the contact */
  name: Scalars['String']['input'];
};

/** A Signed Docusign which represents a withdrawal authorization from a Program Customer */
export type DocusignSignedDocument = {
  __typename: 'DocusignSignedDocument';
  /** file represents the signed docusign file */
  file: Scalars['String']['output'];
};

/** ETHStakingProviderType represents an enum of all the ethereum staking providers */
export enum ETHStakingProviderType {
  /** BLOCKDAEMON provider type for ethereum staking */
  BLOCKDAEMON = 'BLOCKDAEMON',
  /** FIGMENT provider type for ethereum staking */
  FIGMENT = 'FIGMENT',
  /** STAKED provider type for ethereum staking */
  STAKED = 'STAKED',
  /** TWINSTAKE provider type for ethereum staking */
  TWINSTAKE = 'TWINSTAKE'
}

export type EditWebhookEndpointInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  subscriptions?: InputMaybe<Array<Scalars['ID']['input']>>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type EligibilityProof = Node & {
  __typename: 'EligibilityProof';
  applicableCriteria: Maybe<EligibilityProofApplicableCriteria>;
  calls: Maybe<Array<CollateralCall>>;
  collateralAgreement: Maybe<CollateralAgreement>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  refData: Maybe<EligibilityProofRefData>;
  status: EligibilityProofStatus;
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** EligibilityProofApplicableCriteria  maintains the collection of applicable criteria */
export type EligibilityProofApplicableCriteria = {
  __typename: 'EligibilityProofApplicableCriteria';
  ccr: Scalars['Percent']['output'];
  collateralAssets: Maybe<Array<Maybe<EligibilityProofApplicableCriteriaAssets>>>;
  collateralValueUSD: Amount;
  exposureAssets: Maybe<Array<Maybe<EligibilityProofApplicableCriteriaAssets>>>;
  exposureValueUSD: Amount;
  targetCCR: Maybe<Scalars['Percent']['output']>;
};

/** EligibilityProofApplicableCriteriaAssets  maintains the applicable criterion for the colalteral calls */
export type EligibilityProofApplicableCriteriaAssets = {
  __typename: 'EligibilityProofApplicableCriteriaAssets';
  assetSpec: Maybe<AssetSpec>;
  quantity: Scalars['Decimal']['output'];
  valueUSD: Amount;
};

/** EligibilityProofAssetDetailInput is used to provide details about an eligibility proof asset */
export type EligibilityProofAssetDetailInput = {
  assetSpecID: Scalars['ID']['input'];
  price: Scalars['Decimal']['input'];
  quantity: Scalars['Decimal']['input'];
};

/** Ordering options for EligibilityProof connections */
export type EligibilityProofOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EligibilityProofs. */
  field: EligibilityProofOrderField;
};

/** Properties by which EligibilityProof connections can be ordered. */
export enum EligibilityProofOrderField {
  CREATED_AT = 'CREATED_AT',
  STATUS = 'STATUS'
}

/** EligibilityProofRefData maintains the collection of EligibilityProofRefDataElement */
export type EligibilityProofRefData = {
  __typename: 'EligibilityProofRefData';
  elements: Maybe<Array<EligibilityProofRefDataElement>>;
};

/** EligibilityProofRefDataElement maintains fields for the eligibility proof elements */
export type EligibilityProofRefDataElement = {
  __typename: 'EligibilityProofRefDataElement';
  assetSpec: Maybe<AssetSpec>;
  dataSource: Maybe<DataSource>;
  price: Maybe<Amount>;
  priceTime: Maybe<Scalars['Timestamp']['output']>;
};

/** EligibilityProofStatus is enum for the field status */
export enum EligibilityProofStatus {
  COLLATERAL_PROOF_STATUS_ACTIVE = 'COLLATERAL_PROOF_STATUS_ACTIVE',
  COLLATERAL_PROOF_STATUS_DELETED = 'COLLATERAL_PROOF_STATUS_DELETED',
  COLLATERAL_PROOF_STATUS_INACTIVE = 'COLLATERAL_PROOF_STATUS_INACTIVE',
  COLLATERAL_PROOF_STATUS_UNSPECIFIED = 'COLLATERAL_PROOF_STATUS_UNSPECIFIED'
}

/**
 * EligibilityProofWhereInput is used for filtering EligibilityProof objects.
 * Input was generated by ent.
 */
export type EligibilityProofWhereInput = {
  and?: InputMaybe<Array<EligibilityProofWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** calls edge predicates */
  hasCalls?: InputMaybe<Scalars['Boolean']['input']>;
  hasCallsWith?: InputMaybe<Array<CollateralCallWhereInput>>;
  /** collateral agreement edge predicates */
  hasCollateralAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  hasCollateralAgreementWith?: InputMaybe<Array<CollateralAgreementWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EligibilityProofWhereInput>;
  or?: InputMaybe<Array<EligibilityProofWhereInput>>;
  /** status field predicates */
  status?: InputMaybe<EligibilityProofStatus>;
  statusIn?: InputMaybe<Array<EligibilityProofStatus>>;
  statusNEQ?: InputMaybe<EligibilityProofStatus>;
  statusNotIn?: InputMaybe<Array<EligibilityProofStatus>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EligibilitySchedule = Node & {
  __typename: 'EligibilitySchedule';
  assetSpecID: Maybe<AssetSpec>;
  caID: Maybe<CollateralAgreement>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['String']['output'];
  haircut: Scalars['Decimal']['output'];
  id: Scalars['ID']['output'];
  status: EligibilityScheduleStatus;
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** Ordering options for EligibilitySchedule connections */
export type EligibilityScheduleOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EligibilitySchedules. */
  field: EligibilityScheduleOrderField;
};

/** Properties by which EligibilitySchedule connections can be ordered. */
export enum EligibilityScheduleOrderField {
  CREATED_AT = 'CREATED_AT',
  STATUS = 'STATUS'
}

/** EligibilityScheduleStatus is enum for the field status */
export enum EligibilityScheduleStatus {
  ELIGIBILITY_SCHEDULE_STATUS_ACTIVE = 'ELIGIBILITY_SCHEDULE_STATUS_ACTIVE',
  ELIGIBILITY_SCHEDULE_STATUS_DELETED = 'ELIGIBILITY_SCHEDULE_STATUS_DELETED',
  ELIGIBILITY_SCHEDULE_STATUS_INACTIVE = 'ELIGIBILITY_SCHEDULE_STATUS_INACTIVE',
  ELIGIBILITY_SCHEDULE_STATUS_UNSPECIFIED = 'ELIGIBILITY_SCHEDULE_STATUS_UNSPECIFIED'
}

/**
 * EligibilityScheduleWhereInput is used for filtering EligibilitySchedule objects.
 * Input was generated by ent.
 */
export type EligibilityScheduleWhereInput = {
  and?: InputMaybe<Array<EligibilityScheduleWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** haircut field predicates */
  haircut?: InputMaybe<Scalars['Decimal']['input']>;
  haircutGT?: InputMaybe<Scalars['Decimal']['input']>;
  haircutGTE?: InputMaybe<Scalars['Decimal']['input']>;
  haircutIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  haircutLT?: InputMaybe<Scalars['Decimal']['input']>;
  haircutLTE?: InputMaybe<Scalars['Decimal']['input']>;
  haircutNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  haircutNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** asset_spec_id_fk edge predicates */
  hasAssetSpecID?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetSpecIDWith?: InputMaybe<Array<AssetSpecWhereInput>>;
  /** ca_id_fk edge predicates */
  hasCaID?: InputMaybe<Scalars['Boolean']['input']>;
  hasCaIDWith?: InputMaybe<Array<CollateralAgreementWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EligibilityScheduleWhereInput>;
  or?: InputMaybe<Array<EligibilityScheduleWhereInput>>;
  /** status field predicates */
  status?: InputMaybe<EligibilityScheduleStatus>;
  statusIn?: InputMaybe<Array<EligibilityScheduleStatus>>;
  statusNEQ?: InputMaybe<EligibilityScheduleStatus>;
  statusNotIn?: InputMaybe<Array<EligibilityScheduleStatus>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnabledModules = Node & {
  __typename: 'EnabledModules';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  organizationKeyID: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type EnabledModulesConnection = {
  __typename: 'EnabledModulesConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<EnabledModulesEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EnabledModulesEdge = {
  __typename: 'EnabledModulesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<EnabledModules>;
};

/**
 * EnabledModulesWhereInput is used for filtering EnabledModules objects.
 * Input was generated by ent.
 */
export type EnabledModulesWhereInput = {
  and?: InputMaybe<Array<EnabledModulesWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EnabledModulesWhereInput>;
  or?: InputMaybe<Array<EnabledModulesWhereInput>>;
  /** organization_key_id field predicates */
  organizationKeyID?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDContains?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDGT?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDGTE?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationKeyIDLT?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDLTE?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDNEQ?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Possible enrollment types */
export enum EnrollType {
  /** The user is joining an existing organization */
  EXISTING_ORG_ENROLL = 'EXISTING_ORG_ENROLL',
  /** The user is joining a new organization */
  NEW_ORG_ENROLL = 'NEW_ORG_ENROLL',
  /** The enrollment will replace the user within its organization, replacing the existing key */
  REPLACEMENT_ENROLL = 'REPLACEMENT_ENROLL'
}

/** EnrollingUserControlKey represents the state of control keys for a user that's being added to an organization */
export type EnrollingUserControlKey = {
  __typename: 'EnrollingUserControlKey';
  /** Once the control key for the user is provided by the admin adding them or the last admin in an org creation, it'll appear here */
  encryptedControlMasterPrivateKey: Maybe<Scalars['String']['output']>;
  /** Once the user has scanned their QR code and submitted the encryption key, it'll appear here */
  encryptionPublicKey: Maybe<Scalars['String']['output']>;
  /** This is the enroll token (the content of the qr code) for an enrolling user */
  enrollToken: Scalars['String']['output'];
  /** The user's (staged?) name */
  name: Scalars['String']['output'];
  /** The hash of their public key once they've scanned their QR code */
  publicKeyID: Maybe<Scalars['String']['output']>;
};

/** Current enrollment information */
export type Enrollment = {
  __typename: 'Enrollment';
  /** This is true if you must submit control keys for all users (including yourself) before being allowed in the org */
  controlMasterPublicKeyRequired: Scalars['Boolean']['output'];
  /** The enrollment token of this enrollment. */
  enrollToken: Scalars['String']['output'];
  /** Your user will be asked to generate and back up the control master key */
  isControlMasterPublicKeySubmitter: Scalars['Boolean']['output'];
  /** PII information known */
  knownInformation: Array<KnownInformation>;
  /** The name of the user submitted during enrollment. This is not necessarily the name that the ended up with in the organization after enrollment, so it should be used only during enrollment */
  name: Maybe<Scalars['String']['output']>;
  /** The organizationKeyID of the organization once the enrollment process is complete */
  organizationKeyID: Maybe<Scalars['String']['output']>;
  /** The name of the organization you are enrolling into */
  organizationName: Scalars['String']['output'];
  /** PII information required */
  requiredInformation: Array<RequiredInformation>;
  /** The staged organization associated with this enrollment */
  stagedOrganization: StagedOrganization;
  /** The status of the enrollment process */
  status: EnrollmentStatus;
  /** The type of enrollment being performed */
  type: EnrollType;
  /**
   * During organization creation, every admin user will see this list and has to get their control key from here when it's available
   * The last admin will submit the control keys for everyone
   * When adding a user, the initiator, will submit an encrypted control key for them at the time when the initiator endorses the user add operation and it will also show up here. In that case, this list might contain only a single item.
   */
  userControlKeys: Array<EnrollingUserControlKey>;
  /** The status of the enrollment process as it should be shown in the user interface. */
  visualStatus: EnrollmentVisualStatus;
};

/** A connection to a list of enrollments. */
export type EnrollmentConnection = {
  __typename: 'EnrollmentConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<EnrollmentEdge>>>;
  /** Information to aid in pagination */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection, representing an Enrollment instance */
export type EnrollmentEdge = {
  __typename: 'EnrollmentEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge */
  node: Maybe<Enrollment>;
};

/** Ordering options for Enrollment connections */
export type EnrollmentOrderBy = {
  /** The ordering direction */
  direction?: OrderDirection;
  /** The field by which to order enrollments */
  field?: EnrollmentOrderByField;
};

/** Properties by which Enrollment connections can be ordered */
export enum EnrollmentOrderByField {
  /** Order enrollments by creation date */
  CREATED_AT = 'CREATED_AT'
}

/** Possible enrollment statuses */
export enum EnrollmentStatus {
  /** Enrollment completed but the user has not logged in for the first time yet */
  COMPLETE = 'COMPLETE',
  /** Enrollment completed and no further changes can be made to the submitted information */
  LOCKED = 'LOCKED',
  /** The user has not submitted the minimal required information, i.e. name, email and phone number */
  OPEN = 'OPEN',
  /** Enrollment is still underway */
  PENDING = 'PENDING',
  /** Enrollment was rejected */
  REJECTED = 'REJECTED'
}

/** Defines a vault policy to be associated with an enrollment */
export type EnrollmentVaultPolicy = {
  /**
   * The actions within the vault that the enrollment gives access to
   * Deprecated Legacy PolicyMaps, use 'scopes'.
   * @deprecated Use 'scopes' instead
   */
  actions?: InputMaybe<Array<PolicyActionType>>;
  /** A map of scopes to quorum requirements */
  scopes?: InputMaybe<Array<ScopeToQuorumRequirements>>;
  /** The vault sub ID that the policy refers to */
  vaultSubID: Scalars['String']['input'];
};

/** Enrollment status as it should be shown in the user interface */
export enum EnrollmentVisualStatus {
  /** All steps have been completed, but not approved into the organization. */
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  /** Enrollment token has been used, but not all enrollment steps have been completed. */
  REGISTRATION_IN_PROGRESS = 'REGISTRATION_IN_PROGRESS',
  /** Token has been created, but has not been used. */
  TOKEN_CREATED = 'TOKEN_CREATED',
  /** All steps have been completed, but organization has not been seeded yet. */
  WAITING_FOR_ORG_SEEDING = 'WAITING_FOR_ORG_SEEDING'
}

/** A filter to be used against Enrollment fields. */
export type EnrollmentWhereInput = {
  /** Statuses of enrollment to filter by */
  enrollStatusIn?: InputMaybe<Array<EnrollmentStatus>>;
  /** Types of enrollment to filter by */
  enrollTypeIn?: InputMaybe<Array<EnrollType>>;
};

/** EntityStatus is the status of a lending entity */
export enum EntityStatus {
  /** Active is an active lending entity */
  ACTIVE = 'ACTIVE',
  /** Deleted is an Deleted lending entity */
  DELETED = 'DELETED',
  /** Inactive is an inactive lending entity */
  INACTIVE = 'INACTIVE'
}

/** Collateral encapsulation for equity */
export type EquityCollateralDetails = Node & {
  __typename: 'EquityCollateralDetails';
  /** Amount of equity */
  amount: Scalars['Decimal']['output'];
  /** Equity symbol */
  cusip: Scalars['String']['output'];
  /** Identifier */
  id: Scalars['ID']['output'];
  /** Timestamp */
  timestamp: Scalars['Timestamp']['output'];
  /** Transaction type */
  transactionType: CollateralTransactionType;
};

/** Connection for pagination */
export type EquityCollateralDetailsConnection = {
  __typename: 'EquityCollateralDetailsConnection';
  /** Records */
  edges: Array<EquityCollateralDetailsEdge>;
  /** Pagination info */
  pageInfo: PageInfo;
};

/** Edge for pagination */
export type EquityCollateralDetailsEdge = {
  __typename: 'EquityCollateralDetailsEdge';
  /** Record cursor */
  cursor: Scalars['ID']['output'];
  /** Record */
  node: EquityCollateralDetails;
};

/** Equity collateral input */
export type EquityCollateralInput = {
  /** Amount */
  amount: Scalars['Decimal']['input'];
  /** Created at */
  createdAt: Scalars['Timestamp']['input'];
  /** Created by */
  createdBy: Scalars['String']['input'];
  /** Cusip */
  cusip: Scalars['String']['input'];
  /** Transaction type */
  transactionType: CollateralTransactionType;
  /** Updated at */
  updatedAt: Scalars['Timestamp']['input'];
  /** Updated by */
  updatedBy: Scalars['String']['input'];
};

/** Simple interface for an error with a message. */
export type ErrorMessage = {
  /** An error message. */
  message: Scalars['String']['output'];
};

/** EstablishWalletConnectSessionResponse */
export type EstablishWalletConnectSessionResponse = {
  __typename: 'EstablishWalletConnectSessionResponse';
  /** connectionID is the ID of the connection */
  connectionID: Maybe<Scalars['ID']['output']>;
  /** Was request successful */
  success: Scalars['Boolean']['output'];
};

/** Extra parameters for ethereum */
export type EthereumExtraParams = {
  __typename: 'EthereumExtraParams';
  /** stakingProvider is the provider that will be used to stake the ethereum */
  stakingProvider: Maybe<ETHStakingProviderType>;
  /** validatorCount is the amount of validators for this staking operation */
  validatorCount: Maybe<Scalars['String']['output']>;
  /** withdrawalAddress is the address to where the funds will be returned to when withdrawal is possible */
  withdrawalAddress: Maybe<Scalars['String']['output']>;
};

/** ExecType represents */
export enum ExecType {
  /** Canceled represents */
  CANCELED = 'CANCELED',
  /** CancelRejected represents */
  CANCEL_REJECTED = 'CANCEL_REJECTED',
  /** DoneForDay represents */
  DONE_FOR_DAY = 'DONE_FOR_DAY',
  /** Invalid represents */
  INVALID = 'INVALID',
  /** New represents */
  NEW = 'NEW',
  /** Paused represents */
  PAUSED = 'PAUSED',
  /** PendingResume represents */
  PENDINGVRESUME = 'PENDINGVRESUME',
  /** PendingCancel represents */
  PENDING_CANCEL = 'PENDING_CANCEL',
  /** PendingNew represents */
  PENDING_NEW = 'PENDING_NEW',
  /** PendingPause represents */
  PENDING_PAUSE = 'PENDING_PAUSE',
  /** PendingReplace represents */
  PENDING_REPLACE = 'PENDING_REPLACE',
  /** Rejected represents */
  REJECTED = 'REJECTED',
  /** Replaced represents */
  REPLACED = 'REPLACED',
  /** ReplaceRejected represents */
  REPLACE_REJECTED = 'REPLACE_REJECTED',
  /** Restated represents */
  RESTATED = 'RESTATED',
  /** Resumed represents */
  RESUMED = 'RESUMED',
  /** Started represents */
  STARTED = 'STARTED',
  /** Stopped represents */
  STOPPED = 'STOPPED',
  /** Trade represents */
  TRADE = 'TRADE',
  /** Triggered represents */
  TRIGGERED = 'TRIGGERED'
}

export type Exposure = Node & {
  __typename: 'Exposure';
  caID: Maybe<CollateralAgreement>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['String']['output'];
  exposure: Scalars['Decimal']['output'];
  exposureUsd: Scalars['Decimal']['output'];
  externalTransactionData: Maybe<ExternalTransactionData>;
  id: Scalars['ID']['output'];
  priceRefID: Maybe<PriceReferenceData>;
  sourceSystem: ExposureSourceSystem;
  status: ExposureStatus;
  tradeDate: Scalars['Timestamp']['output'];
  typeID: ExposureTypeID;
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type ExposureConnection = {
  __typename: 'ExposureConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ExposureEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ExposureEdge = {
  __typename: 'ExposureEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Exposure>;
};

/** Ordering options for Exposure connections */
export type ExposureOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Exposures. */
  field: ExposureOrderField;
};

/** Properties by which Exposure connections can be ordered. */
export enum ExposureOrderField {
  CREATED_AT = 'CREATED_AT',
  EXPOSURE = 'EXPOSURE',
  EXPOSURE_USD = 'EXPOSURE_USD',
  SOURCE_SYSTEM = 'SOURCE_SYSTEM',
  STATUS = 'STATUS',
  TRADE_DATE = 'TRADE_DATE',
  TYPE_ID = 'TYPE_ID'
}

/** ExposureSourceSystem is enum for the field source_system */
export enum ExposureSourceSystem {
  EXPOSURE_SOURCE_SYSTEM_COLLATERAL_MANAGER = 'EXPOSURE_SOURCE_SYSTEM_COLLATERAL_MANAGER',
  EXPOSURE_SOURCE_SYSTEM_LOAN_MANAGER = 'EXPOSURE_SOURCE_SYSTEM_LOAN_MANAGER',
  EXPOSURE_SOURCE_SYSTEM_UNSPECIFIED = 'EXPOSURE_SOURCE_SYSTEM_UNSPECIFIED'
}

/** ExposureStatus is enum for the field status */
export enum ExposureStatus {
  EXPOSURE_STATUS_ACTIVE = 'EXPOSURE_STATUS_ACTIVE',
  EXPOSURE_STATUS_DELETED = 'EXPOSURE_STATUS_DELETED',
  EXPOSURE_STATUS_INACTIVE = 'EXPOSURE_STATUS_INACTIVE',
  EXPOSURE_STATUS_UNSPECIFIED = 'EXPOSURE_STATUS_UNSPECIFIED'
}

/** ExposureTypeID is enum for the field type_id */
export enum ExposureTypeID {
  EXPOSURE_TYPE_ID_DECREASE = 'EXPOSURE_TYPE_ID_DECREASE',
  EXPOSURE_TYPE_ID_FINAL_EXPOSURE = 'EXPOSURE_TYPE_ID_FINAL_EXPOSURE',
  EXPOSURE_TYPE_ID_INCREASE = 'EXPOSURE_TYPE_ID_INCREASE',
  EXPOSURE_TYPE_ID_INITIAL_EXPOSURE = 'EXPOSURE_TYPE_ID_INITIAL_EXPOSURE',
  EXPOSURE_TYPE_ID_UNSPECIFIED = 'EXPOSURE_TYPE_ID_UNSPECIFIED'
}

/**
 * ExposureWhereInput is used for filtering Exposure objects.
 * Input was generated by ent.
 */
export type ExposureWhereInput = {
  and?: InputMaybe<Array<ExposureWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** exposure field predicates */
  exposure?: InputMaybe<Scalars['Decimal']['input']>;
  exposureGT?: InputMaybe<Scalars['Decimal']['input']>;
  exposureGTE?: InputMaybe<Scalars['Decimal']['input']>;
  exposureIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  exposureLT?: InputMaybe<Scalars['Decimal']['input']>;
  exposureLTE?: InputMaybe<Scalars['Decimal']['input']>;
  exposureNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  exposureNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** exposure_usd field predicates */
  exposureUsd?: InputMaybe<Scalars['Decimal']['input']>;
  exposureUsdGT?: InputMaybe<Scalars['Decimal']['input']>;
  exposureUsdGTE?: InputMaybe<Scalars['Decimal']['input']>;
  exposureUsdIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  exposureUsdLT?: InputMaybe<Scalars['Decimal']['input']>;
  exposureUsdLTE?: InputMaybe<Scalars['Decimal']['input']>;
  exposureUsdNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  exposureUsdNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** ca_id_fk edge predicates */
  hasCaID?: InputMaybe<Scalars['Boolean']['input']>;
  hasCaIDWith?: InputMaybe<Array<CollateralAgreementWhereInput>>;
  /** price_ref_id_fk edge predicates */
  hasPriceRefID?: InputMaybe<Scalars['Boolean']['input']>;
  hasPriceRefIDWith?: InputMaybe<Array<PriceReferenceDataWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ExposureWhereInput>;
  or?: InputMaybe<Array<ExposureWhereInput>>;
  /** source_system field predicates */
  sourceSystem?: InputMaybe<ExposureSourceSystem>;
  sourceSystemIn?: InputMaybe<Array<ExposureSourceSystem>>;
  sourceSystemNEQ?: InputMaybe<ExposureSourceSystem>;
  sourceSystemNotIn?: InputMaybe<Array<ExposureSourceSystem>>;
  /** status field predicates */
  status?: InputMaybe<ExposureStatus>;
  statusIn?: InputMaybe<Array<ExposureStatus>>;
  statusNEQ?: InputMaybe<ExposureStatus>;
  statusNotIn?: InputMaybe<Array<ExposureStatus>>;
  /** trade_date field predicates */
  tradeDate?: InputMaybe<Scalars['Timestamp']['input']>;
  tradeDateGT?: InputMaybe<Scalars['Timestamp']['input']>;
  tradeDateGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  tradeDateIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  tradeDateLT?: InputMaybe<Scalars['Timestamp']['input']>;
  tradeDateLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  tradeDateNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  tradeDateNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** type_id field predicates */
  typeID?: InputMaybe<ExposureTypeID>;
  typeIDIn?: InputMaybe<Array<ExposureTypeID>>;
  typeIDNEQ?: InputMaybe<ExposureTypeID>;
  typeIDNotIn?: InputMaybe<Array<ExposureTypeID>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

/** External account. */
export type ExternalAccount = Node & {
  __typename: 'ExternalAccount';
  /** Account location. */
  externalAccountLocation: ExternalAccountLocation;
  /** External account identifier. */
  id: Scalars['ID']['output'];
  /** External account name. */
  name: Scalars['String']['output'];
  /** Human-readable notes. */
  notes: Scalars['String']['output'];
  /** When was this account info last changed. */
  updatedAt: Scalars['Timestamp']['output'];
  /** Who created the account in the database. */
  updatedBy: Scalars['String']['output'];
};

/** Balance of a given external account */
export type ExternalAccountBalance = {
  __typename: 'ExternalAccountBalance';
  /** Balance */
  balance: Amount;
  /** External account */
  externalAccount: ExternalAccount;
};

/** External account balance pagination connection. */
export type ExternalAccountBalanceConnection = {
  __typename: 'ExternalAccountBalanceConnection';
  /** List of edges containing external account balances and cursor information */
  edges: Array<ExternalAccountBalanceEdge>;
  /** Page details for the associated list of external account balances. */
  pageInfo: PageInfo;
};

/** External account balance edge connection. */
export type ExternalAccountBalanceEdge = {
  __typename: 'ExternalAccountBalanceEdge';
  /** CursorType for the associated external account balance. */
  cursor: Scalars['String']['output'];
  /** External account balance. */
  node: ExternalAccountBalance;
};

/** External account pagination connection */
export type ExternalAccountConnection = {
  __typename: 'ExternalAccountConnection';
  /** List of edges containing external accounts and cursor information */
  edges: Array<ExternalAccountEdge>;
  /** Page details for the associated list of external accounts. */
  pageInfo: PageInfo;
};

/** External account pagination edge */
export type ExternalAccountEdge = {
  __typename: 'ExternalAccountEdge';
  /** CursorType for the associated external account. */
  cursor: Scalars['String']['output'];
  /** External account. */
  node: ExternalAccount;
};

/** An external account location (i.e. bank or institution). */
export type ExternalAccountLocation = Node & {
  __typename: 'ExternalAccountLocation';
  /** External account location identifier. */
  id: Scalars['ID']['output'];
  /** Location (i.e., bank/institution name). */
  location: Scalars['String']['output'];
  /** Human-readable notes. */
  notes: Scalars['String']['output'];
  /** When was the account inserted in the database. */
  updatedAt: Scalars['Timestamp']['output'];
  /** Who created the account in the database. */
  updatedBy: Scalars['String']['output'];
};

/** External account location pagination connection */
export type ExternalAccountLocationConnection = {
  __typename: 'ExternalAccountLocationConnection';
  /** List of edges containing external account locations and cursor information */
  edges: Array<ExternalAccountLocationEdge>;
  /** Page details for the associated list of external account locations. */
  pageInfo: PageInfo;
};

/** External account location pagination edge */
export type ExternalAccountLocationEdge = {
  __typename: 'ExternalAccountLocationEdge';
  /** CursorType for the associated external account location. */
  cursor: Scalars['String']['output'];
  /** External account location. */
  node: ExternalAccountLocation;
};

/** Balance of a given organization on an external account */
export type ExternalAccountOrgBalance = {
  __typename: 'ExternalAccountOrgBalance';
  /** Trading account associated with this balance */
  account: Maybe<TradingAccount>;
  /** Organization affiliate associated with this balance */
  affiliate: Maybe<Account>;
  /** Balance */
  balance: Amount;
  /** Time of last executed transaction */
  lastExecutedAt: Scalars['Timestamp']['output'];
  /** Organization associated with this balance */
  organization: Organization;
};

/** External account balance pagination connection. */
export type ExternalAccountOrgBalanceConnection = {
  __typename: 'ExternalAccountOrgBalanceConnection';
  /** List of edges containing external account balances and cursor information */
  edges: Array<ExternalAccountOrgBalanceEdge>;
  /** Page details for the associated list of external account balances. */
  pageInfo: PageInfo;
};

/** External account balance edge connection. */
export type ExternalAccountOrgBalanceEdge = {
  __typename: 'ExternalAccountOrgBalanceEdge';
  /** CursorType for the associated external account balance. */
  cursor: Scalars['String']['output'];
  /** External account balance. */
  node: ExternalAccountOrgBalance;
};

/** Transaction on an external account */
export type ExternalAccountTransaction = Node & {
  __typename: 'ExternalAccountTransaction';
  /** Which brokerage account is associated with this external account */
  account: Maybe<TradingAccount>;
  /** Which Anchorage affiliate entity identifier is associated with this account */
  affiliate: Maybe<Account>;
  /** Amount transacted */
  amount: Amount;
  /** When did this transaction occur */
  executedAt: Scalars['Timestamp']['output'];
  /** External account where transaction occurred */
  externalAccount: ExternalAccount;
  /** Free-form text that might be used to store information regarding transaction identifiers of external entities */
  externalID: Scalars['String']['output'];
  /** Transaction identifier */
  id: Scalars['ID']['output'];
  /** Human-readable notes */
  notes: Scalars['String']['output'];
  /** Organization associated with this transaction */
  organization: Organization;
  /** Type of transaction */
  transactionType: ExternalAccountTransactionType;
  /** When was this transaction updated */
  updatedAt: Scalars['Timestamp']['output'];
  /** Who updated this transaction */
  updatedBy: Scalars['String']['output'];
  /** Which organization vault is associated with this transaction */
  vault: Maybe<Vault>;
};

/** External account transaction pagination connection. */
export type ExternalAccountTransactionConnection = {
  __typename: 'ExternalAccountTransactionConnection';
  /** List of edges containing external account transactions and cursor information */
  edges: Array<ExternalAccountTransactionEdge>;
  /** Page details for the associated list of external account transactions. */
  pageInfo: PageInfo;
};

/** External account transaction edge connection. */
export type ExternalAccountTransactionEdge = {
  __typename: 'ExternalAccountTransactionEdge';
  /** CursorType for the associated external account transaction. */
  cursor: Scalars['String']['output'];
  /** External account transaction. */
  node: ExternalAccountTransaction;
};

/** Type of external account transaction. */
export enum ExternalAccountTransactionType {
  /** Charged fees */
  FEE = 'FEE',
  /** Amount was from other operation generating a decrement */
  OTHER_DECREMENT = 'OTHER_DECREMENT',
  /** Amount was from other operation generating an increment */
  OTHER_INCREMENT = 'OTHER_INCREMENT',
  /** Amount was pre-deposited. */
  PREDEPOSIT = 'PREDEPOSIT',
  /** Amount was deposited from proceeds */
  PROCEEDS_DEPOSIT = 'PROCEEDS_DEPOSIT',
  /** Amount is being spent on trades */
  SPENDING_ON_TRADES = 'SPENDING_ON_TRADES',
  /** Amount was withdrawed back to the client */
  WITHDRAWAL_BACK_TO_CLIENT = 'WITHDRAWAL_BACK_TO_CLIENT'
}

/** ExternalTransactionData  maintains the details of external transactions */
export type ExternalTransactionData = {
  __typename: 'ExternalTransactionData';
  transactionID: Maybe<Scalars['String']['output']>;
};

/**
 * Extra parameters per asset type
 * We assume you don't simultaneously transact in mainnet and testnet
 */
export type ExtraParams = {
  __typename: 'ExtraParams';
  /** params for aptos */
  aptosExtraParams: Maybe<AptosExtraParams>;
  /** params for celo governance vote */
  celoGovernanceVoteExtraParams: Maybe<CeloGovernanceVoteExtraParams>;
  /** params for cosmos */
  cosmosExtraParams: Maybe<CosmosExtraParams>;
  /**
   * params for diem
   * @deprecated Unused, will be removed in the future
   */
  diemExtraParams: Maybe<DiemExtraParams>;
  /** params for ethereum staking */
  ethereumExtraParams: Maybe<EthereumExtraParams>;
  /** params for fiat */
  fiatExtraParams: Maybe<FiatExtraParams>;
  /** params for flow delegate/undelegate */
  flowDelegateExtraParams: Maybe<FlowDelegateExtraParams>;
  /** params for maker */
  makerExtraParams: Maybe<MakerExtraParams>;
  /** params for oasis undelegate */
  oasisUndelegateExtraParams: Maybe<OasisUndelegateExtraParams>;
  /** params for ripple */
  rippleExtraParams: Maybe<RippleExtraParams>;
  /** params for stellar */
  stellarExtraParams: Maybe<StellarExtraParams>;
  /** params for assets */
  supportedExtraParams: Maybe<SupportedExtraParams>;
  /** params for tendermint */
  tendermintExtraParams: Maybe<TendermintExtraParams>;
};

/** Type of the ExtraParams in an operation's canonical representation. */
export enum ExtraParamsActionType {
  /** For celo governance/referendum vote */
  CELO_VOTE_REFERENDUM = 'CELO_VOTE_REFERENDUM',
  /** For flow delegate */
  FLOW_DELEGATE = 'FLOW_DELEGATE',
  /** For flow undelegate */
  FLOW_UNDELEGATE = 'FLOW_UNDELEGATE',
  /** For maker authorize spender */
  MKR_AUTHORIZE_SPENDER = 'MKR_AUTHORIZE_SPENDER',
  /** For maker executive vote */
  MKR_VOTE_EXECUTIVE = 'MKR_VOTE_EXECUTIVE',
  /** For maker poll vote */
  MKR_VOTE_POLL = 'MKR_VOTE_POLL',
  /** For oasis undelegate */
  OASIS_UNDELEGATE = 'OASIS_UNDELEGATE'
}

/** Sorting options for lending facility yield */
export enum FacilityYieldSortKey {
  /** Sort by Asset type option for lending facility yield */
  ASSET_TYPE = 'ASSET_TYPE',
  /** Sort by Balance option for lending facility yield */
  BALANCE = 'BALANCE'
}

/** FactorGroup represents a group of factors */
export type FactorGroup = {
  __typename: 'FactorGroup';
  /** factorGroups is a list of children groups */
  factorGroups: Maybe<Array<FactorGroup>>;
  /** factors is a list of pair of factor names and values as string */
  factors: Maybe<Array<FactorValues>>;
  /** id is the identifier of the group */
  id: Scalars['String']['output'];
  /** label is the description of the group */
  label: Scalars['String']['output'];
};

/** FactorValues represents a pair of factor name and value in string */
export type FactorValues = {
  __typename: 'FactorValues';
  /** id is the name of a factor */
  id: Scalars['String']['output'];
  /** label is the description of the group */
  label: Scalars['String']['output'];
  /** oldValue is the old value for the factor from previous run */
  oldValue: Scalars['String']['output'];
  /** value is the value for the factor */
  value: Scalars['String']['output'];
};

/** Feature represents a feature that can be made conditionally available to certain clients or users */
export type Feature = {
  __typename: 'Feature';
  /** The name of a feature */
  name: FeatureName;
};

/** FeatureName indicates the types of features that can be enabled */
export enum FeatureName {
  /** Controls the enablement of credit management at the trading account level */
  ACCOUNT_LEVEL_CREDIT_MANAGEMENT = 'ACCOUNT_LEVEL_CREDIT_MANAGEMENT',
  /**
   * Allows client to use features related to external transfers.
   * This relates to the createTransfer APIv2 endpoint, creating a role with
   * TRANSFER permission and external destinations, and the ability to add API
   * public keys to the organization via operation.
   */
  APIV2_EXTERNAL_TRANSFER = 'APIV2_EXTERNAL_TRANSFER',
  /** Obsolete flag, use only to manually clear the flag from the database. */
  APIV2_TRANSFER_PERMISSION = 'APIV2_TRANSFER_PERMISSION',
  /** Enables an organization to use ECDSA signing with APIv2. EdDSA is the default. */
  APIv2_ECDSA_AUTH = 'APIv2_ECDSA_AUTH',
  /** Controls whether settlements are automatically settled. */
  AUTOMATICALLY_SETTLE_SETTLEMENTS = 'AUTOMATICALLY_SETTLE_SETTLEMENTS',
  /**
   * Enable support for withdrawals V2 for Bitcoin-like assets (BTC/LTC). This
   * feature is required to enable concurrent withdrawals for these assets.
   */
  BITCOINLIKE_WITHDRAWAL_V2 = 'BITCOINLIKE_WITHDRAWAL_V2',
  /** Controls the access to the bulk vault creation functionality. */
  BULK_VAULT_CREATION = 'BULK_VAULT_CREATION',
  /** Enable Deposit Attribution API for an org */
  DEPOSIT_ATTRIBUTION_API = 'DEPOSIT_ATTRIBUTION_API',
  /** Enable Deposit Attribution Blocking Screening for an org */
  DEPOSIT_ATTRIBUTION_BLOCKING_SCREENING = 'DEPOSIT_ATTRIBUTION_BLOCKING_SCREENING',
  /** Enable experimental implementations of concurrent withdrawals. */
  EXPERIMENTAL_CONCURRENT_WITHDRAWALS = 'EXPERIMENTAL_CONCURRENT_WITHDRAWALS',
  /** Enable AML enforcement on external transfers. */
  EXTERNAL_TRANSFERS_AML = 'EXTERNAL_TRANSFERS_AML',
  /** Controls the automation of risk review. */
  EXTERNAL_TRANSFER_AUTO_RISK_REVIEW = 'EXTERNAL_TRANSFER_AUTO_RISK_REVIEW',
  /** Enable anchorage internal alerts on client external withdrawals & transfers */
  EXTERNAL_WITHDRAWAL_ALERT = 'EXTERNAL_WITHDRAWAL_ALERT',
  /** Enable use of fiat assets */
  FIAT_ENABLED = 'FIAT_ENABLED',
  /** Controls whether the organization automatically claims its rewards or automatically delegates its rewards */
  FLOW_AUTO_REWARD_DELEGATION_DISABLED = 'FLOW_AUTO_REWARD_DELEGATION_DISABLED',
  /** Enables an organization to use API endpoints for holds. */
  HOLDS = 'HOLDS',
  /**
   * Controls client trades that go through an improved state machine where they
   * are only moved to a final EXECUTED state when all relevant information (eg.
   * final price, commission, etc) is supplied.
   */
  IMPROVED_TRADE_STATE_TRANSITION = 'IMPROVED_TRADE_STATE_TRANSITION',
  /** Controls whether location based authorization is enabled */
  LOCATION_BASED_AUTHORIZATION = 'LOCATION_BASED_AUTHORIZATION',
  /** Enable Onboarding API for an org */
  ONBOARDING_API = 'ONBOARDING_API',
  /** returns only Assets with sub-id zero vs all assets within a vault */
  RETURN_LEGACY_ASSET_SUB_IDS = 'RETURN_LEGACY_ASSET_SUB_IDS',
  /** Enable RIA flat fee enhancement */
  RIA_FLAT_FEE = 'RIA_FLAT_FEE',
  /** Enable risk assessment for smart contracts */
  RISK_SMART_CONTRACTS = 'RISK_SMART_CONTRACTS',
  /** Enable skip Deposit Attribution for testing purposes for a specific org */
  SKIP_DEPOSIT_ATTRIBUTION_FOR_TESTING_PURPOSES = 'SKIP_DEPOSIT_ATTRIBUTION_FOR_TESTING_PURPOSES',
  /** Enable Sub Quorums for an org */
  SUB_QUORUMS = 'SUB_QUORUMS',
  /** Controls the ability to request allocation when executing a quote. */
  TRADING_ALLOCATION = 'TRADING_ALLOCATION',
  /** Enable Trading order API for an org */
  TRADING_MARKET_ORDER = 'TRADING_MARKET_ORDER',
  /** Controls the access to the transfer access requests functionality. */
  TRANSFER_ACCESS_REQUEST = 'TRANSFER_ACCESS_REQUEST',
  /** Enables deposit/withdraw functionality in Client Web Dashboard through vault details page. */
  VAULT_DETAILS_OPERATION = 'VAULT_DETAILS_OPERATION',
  /** Indicates whether wallets apiv2 functionality is enabled */
  WALLETS_APIV2 = 'WALLETS_APIV2',
  /** Kill Siwtch the Wallet API Phase3: wallet_creation and address_provision */
  WALLETS_APIV2_PHASE3_KILL_SWITCH = 'WALLETS_APIV2_PHASE3_KILL_SWITCH'
}

/** FeeConfig represents a fee configuration associated to an organization */
export type FeeConfig = {
  __typename: 'FeeConfig';
  /** Internal ID of the fee configuration */
  feeConfigID: Scalars['String']['output'];
  /** Period of the configured fee */
  feePeriod: FeePeriodType;
  /** Rate of the configured fee */
  feeRate: Scalars['Float']['output'];
  /** Target of the fee configuration */
  feeTarget: FeeTargetType;
  /** Target ID of the fee configuration */
  feeTargetID: Maybe<Scalars['String']['output']>;
  /** Type of the configured fee */
  feeType: FeeTypeType;
  /** Is billable defines if we directly bill the target */
  isBillable: Scalars['Boolean']['output'];
  /** Org ID associated to the case */
  orgKeyID: Scalars['String']['output'];
  /** Start date of the configured fee */
  startDate: Scalars['Date']['output'];
};

/** FeeConfigConnection implements the connections spec */
export type FeeConfigConnection = {
  __typename: 'FeeConfigConnection';
  /** edges implements the connections spec */
  edges: Array<Maybe<FeeConfig>>;
  /** pageInfo implements the connections spec */
  pageInfo: PageInfo;
};

/** FeeCustomization allows for customization of how transaction fees can be calculated. */
export type FeeCustomization = {
  /** At what level to reserve funds as transaction fee. */
  feeReservationLevel?: FeeReservationLevel;
};

/** FeePeriod are the possible periods of fees */
export enum FeePeriodType {
  /** MONTHLY represents the monthly fee period */
  MONTHLY = 'MONTHLY',
  /** UNKNOWN represents an unknown period */
  UNKNOWN = 'UNKNOWN'
}

/** Fee reimbursement encapsulation */
export type FeeReimbursement = Node & {
  __typename: 'FeeReimbursement';
  /** Asset Type ID */
  assetTypeID: Scalars['AssetTypeID']['output'];
  /** Identifier */
  id: Scalars['ID']['output'];
  /** Operation ID */
  operationID: Maybe<Scalars['ID']['output']>;
  /** Owed Fees */
  owedFees: Scalars['String']['output'];
  /** Pool Type */
  poolType: PoolType;
  /** Reimbursed Amount */
  reimbursedAmount: Scalars['String']['output'];
  /** Reimbursement date */
  reimbursementDate: Scalars['Timestamp']['output'];
};

/** Connection for pagination */
export type FeeReimbursementConnection = {
  __typename: 'FeeReimbursementConnection';
  /** Records */
  edges: Array<FeeReimbursementEdge>;
  /** Pagination info */
  pageInfo: PageInfo;
};

/** Edge for pagination */
export type FeeReimbursementEdge = {
  __typename: 'FeeReimbursementEdge';
  /** Record cursor */
  cursor: Scalars['ID']['output'];
  /** Cursor */
  node: FeeReimbursement;
};

/** Filters for  the fee reimbursement object */
export type FeeReimbursementFilters = {
  /** Filter for all fee reimbursements after this reimbursement date */
  filterAfterReimbursementDate?: InputMaybe<Scalars['Timestamp']['input']>;
  /** Filter by asset type id */
  filterAssetTypeID?: InputMaybe<Scalars['AssetTypeID']['input']>;
  /** Filter for all fee reimbursements before this reimbursement date */
  filterBeforeReimbursementDate?: InputMaybe<Scalars['Timestamp']['input']>;
  /** Filter by Fee Reimbursement IDs */
  filterFeeReimbursementIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by pool type */
  filterPoolType?: InputMaybe<PoolType>;
};

/**
 * FeeReservationLevel defines the level at which funds will be reserved as transaction fee.
 * It can be used to cap the max amount transaction fee.
 * Internally the setting is currently translated to different BaseFeeMultiplier values being used.
 * BaseFeeMultiplier controls what multiplier to use when calculating "max base fee".
 * Currently this is only applicable to transaction for ETH and ERC20 tokens.
 */
export enum FeeReservationLevel {
  /**
   * ECONOMY level means to reserve the least funds as transaction fee, thus increasing the chance
   * of the transaction being stuck waiting for actual network fee to come below the reserve.
   * This option is best suited when user wants to wait when actual network fee is high.
   * Note that if actual network fee never becomes low enough, then the transaction could be stuck
   * forever (until canceled.)
   */
  ECONOMY = 'ECONOMY',
  /**
   * GENEROUS level means to reserve the most funds as transaction fee, thus mimizing the chance
   * of the transaction being stuck waiting for actual network fee to come below the reserve.
   * This option is best suited when user still wants to execute the transaction even when network
   * fee is high.
   * Note that actual transaction fee spent is still determined by the network at the time of
   * transaction execution.
   */
  GENEROUS = 'GENEROUS',
  /** MEDIUM level strikes a balance between GENEROUS and ECONOMY. */
  MEDIUM = 'MEDIUM',
  /** UNSPECIFIED means no value specificed, and default behavior will be used. */
  UNSPECIFIED = 'UNSPECIFIED'
}

/** FeeTargetType are the possible types of fee targets */
export enum FeeTargetType {
  /** SUBACCOUNT represents a ledger subacount */
  SUBACCOUNT = 'SUBACCOUNT',
  /** UNKNOWN represents an unknown target */
  UNKNOWN = 'UNKNOWN'
}

/** FeeTypeType are the possible types of fees */
export enum FeeTypeType {
  /** ADVISORY represents the Advisory fee type */
  ADVISORY = 'ADVISORY',
  /** CUSTODY represents the Custody fee type */
  CUSTODY = 'CUSTODY',
  /** MANAGEMENT represents the Management fee type */
  MANAGEMENT = 'MANAGEMENT',
  /** MODEL represents the Model fee type */
  MODEL = 'MODEL',
  /** UNKNOWN represents an unknown fee type */
  UNKNOWN = 'UNKNOWN'
}

/** A report of a client's fiat balances at the start and end of a month */
export type FiatAccountStatement = {
  __typename: 'FiatAccountStatement';
  /** The displayable account ID, derived from the client's OrganizationKeyID and affiliate AccountID and truncated to 12 characters */
  accountID: Scalars['String']['output'];
  /** The account name, equal to the client's affiliate account name */
  accountName: Scalars['String']['output'];
  /** The ending balance as a decimal number in USD */
  endingBalance: Scalars['String']['output'];
  /** The anchorage entity, equal to the anchorage legal entity associate with this client's affiliate account */
  entityName: Scalars['String']['output'];
  /** fiat transactions for this account in this month */
  fiatTransactions: Array<FiatAccountStatementTransaction>;
  /** The organization key ID */
  organizationKeyID: Scalars['String']['output'];
  /** The organization name, equal to the client's affiliate organization name */
  organizationName: Scalars['String']['output'];
  /** The end date, in the format January 31, 2018 */
  periodEnd: Scalars['String']['output'];
  /** The start date, in the format January 1, 2018 */
  periodStart: Scalars['String']['output'];
  /** The starting balance as a decimal number in USD */
  startingBalance: Scalars['String']['output'];
};

/**
 * The representation of a fiat withdrawal or deposit
 * transaction as presented in an account statement.
 */
export type FiatAccountStatementTransaction = {
  __typename: 'FiatAccountStatementTransaction';
  /** The symbol of the asset transacted */
  currency: Scalars['String']['output'];
  /** The date the transaction was effective */
  date: Scalars['String']['output'];
  /** The quantity of asset transacted */
  quantity: Scalars['String']['output'];
  /** The type of transaction */
  transactionType: Scalars['String']['output'];
};

/** Extra parameters for fiat */
export type FiatExtraParams = {
  __typename: 'FiatExtraParams';
  /** accountNumber is the account number of the destination */
  accountNumber: Maybe<Scalars['String']['output']>;
  /** routingNumber is the routing number of the destination */
  routingNumber: Maybe<Scalars['String']['output']>;
  /** standingInstructionId is the id of the standing instruction */
  standingInstructionId: Maybe<Scalars['String']['output']>;
};

/** Extra parameters for flow Delegate */
export type FlowDelegateExtraParams = {
  __typename: 'FlowDelegateExtraParams';
  /** actionType is used to construct the canonical operation */
  actionType: Maybe<ExtraParamsActionType>;
  /** isStake dictates whether the operation is a delegation or staking */
  isStake: Maybe<Scalars['Boolean']['output']>;
  /** nodeID is a unique identifier for a node that can be delegate to */
  nodeID: Maybe<Scalars['String']['output']>;
};

/** FlowType represents */
export enum FlowType {
  /** invalid represents */
  INVALID = 'INVALID',
  /** talos_institutional represents */
  TALOS_INSTITUTIONAL = 'TALOS_INSTITUTIONAL',
  /** talos_retail represents */
  TALOS_RETAIL = 'TALOS_RETAIL'
}

/** Statuses that an institutional form can go through */
export enum FormStatus {
  /** All required information was collected and validated */
  COMPLETE = 'COMPLETE',
  /** The client has been created but no communications have been sent out to it */
  DRAFT = 'DRAFT',
  /** The information collection is in progress but not accepted */
  IN_PROGRESS = 'IN_PROGRESS',
  /** The form is under review and cannot be edited */
  IN_REVIEW = 'IN_REVIEW'
}

/** Fraction is a representation of fraction */
export type Fraction = {
  __typename: 'Fraction';
  /** denominator is the denominator/bottom of the fraction */
  denominator: Scalars['Int']['output'];
  /** numerator is the numerator/the top of the fraction */
  numerator: Scalars['Int']['output'];
};

/** FragmentBindingSortField are the options for sorting a list of fragment bindings. */
export enum FragmentBindingSortField {
  /**
   * DISPLAY_NAME sorts by the fragment bindings display name.
   * The default policy will always be at the top of the list.
   */
  DISPLAY_NAME = 'DISPLAY_NAME'
}

/** Payload response for submitGeneralFeedback mutation */
export type GeneralFeedbackResponse = {
  __typename: 'GeneralFeedbackResponse';
  /** Was request successful */
  success: Scalars['Boolean']['output'];
};

/** Payload response for generateAPIKeyWithPayload mutation. */
export type GenerateAPIKeyPayload = {
  __typename: 'GenerateAPIKeyPayload';
  /** State of the APIKey after the mutation. */
  apiKey: APIKey;
  /** The secret API key value. This is the only time the value will be shared. */
  secret: Scalars['String']['output'];
  /** Some API public keys must be added to the organization before use. If necessary, a new pending operation is returned. */
  userAddOperation: Maybe<Operation>;
};

/** GenericOperation represents a GenericOperation */
export type GenericOperation = Operation & {
  __typename: 'GenericOperation';
  /**
   * The following are resolved by operationresolvers/operation_offchain.go
   * account that this operation is associated with. Only applicable to vault operations (ex. Withdraw)
   */
  account: Maybe<Account>;
  /**
   * The following are resolved by type CommonOperation:
   * action is the action that the operation performs, which is generally the same as the operation type
   */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /** Further information regarding this asset type associated with this operation */
  assetTypeInfo: AssetTypeInfo;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * The following are resolved by type EndorsableOperation:
   * Returns the bio challenge required to pass in order to endorse this operation - TODO: unused field
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to, which is the source organization (not necessarily the org using the initiatorKey)
   * @deprecated use operationInfo
   */
  organization: Maybe<Organization>;
  /**
   * policy that applies to this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /** signedTransaction is the blockchain transaction to be broadcast to the network */
  signedTransaction: Maybe<Scalars['String']['output']>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /** Mandatory user-facing risk analysis information about the smart contract being executed. */
  smartContractRisk: SmartContractRisk;
  /** transactionID is the blockchain transaction id once available */
  transactionID: Maybe<Scalars['String']['output']>;
  /** viewTransactionLink is the link to get more info about the transaction from a third party */
  viewTransactionLink: Maybe<Scalars['String']['output']>;
};


/** GenericOperation represents a GenericOperation */
export type GenericOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** GenericOperation represents a GenericOperation */
export type GenericOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/** GenericOrgChangeOperation is a generic operation that can be used to perform arbitrary operations on an organization */
export type GenericOrgChangeOperation = Operation & {
  __typename: 'GenericOrgChangeOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /** policy that applies to this operation */
  policy: Policy;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
};


/** GenericOrgChangeOperation is a generic operation that can be used to perform arbitrary operations on an organization */
export type GenericOrgChangeOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** GenericOrgChangeOperation is a generic operation that can be used to perform arbitrary operations on an organization */
export type GenericOrgChangeOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/** GlobalTradingLimit contains metadata related to a global trading limit */
export type GlobalTradingLimit = {
  __typename: 'GlobalTradingLimit';
  /** Value of the limit */
  limitValue: Scalars['String']['output'];
  /** Value type of the limit */
  limitValueType: Scalars['String']['output'];
  /** The timestamp in nanos of when limit was last updated */
  updatedAt: Scalars['Timestamp']['output'];
  /** Contains the email of the user that updated */
  updatedBy: Scalars['String']['output'];
};

/** GovernanceSetupStatus represents the state of setting up governance for an asset */
export enum GovernanceSetupStatus {
  /** COMPLETE represents the state when voting setup has been completed */
  COMPLETE = 'COMPLETE',
  /** INCOMPLETE represents the state when voting setup has not been started or completed */
  INCOMPLETE = 'INCOMPLETE',
  /** NOT_SUPPORTED is for assets that do not support voting (eg BTC) */
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  /** UNAVAILABLE represents the state when voting setup on the blockchain is impossible */
  UNAVAILABLE = 'UNAVAILABLE'
}

/** GovernanceSetupStep represents an individual step and its state for setting up governance */
export type GovernanceSetupStep = {
  __typename: 'GovernanceSetupStep';
  /** description is a user-friendly description of this setup step */
  description: Scalars['String']['output'];
  /** state represents the state of this individual step */
  state: GovernanceStepState;
  /** type represents the type of this setup step */
  type: GovernanceSetupType;
};

/** GovernanceSetupType represents the type of the setup step */
export enum GovernanceSetupType {
  /** CELO_CREATE_ACCOUNT encodes a CELO_CREATE_ACCOUNT, enabling this asset to lock/unlock, participate in validator voting, and vote on governance proposals */
  CELO_CREATE_ACCOUNT = 'CELO_CREATE_ACCOUNT',
  /** IOU_CHIEF_APPROVAL encodes an approve ERC20 operation for a ETH address to the MKR Chief contract, sent to the IOU contract */
  IOU_CHIEF_APPROVAL = 'IOU_CHIEF_APPROVAL',
  /** LOCK encodes a LOCK operation, binding an amount of this asset for use in voting */
  LOCK = 'LOCK',
  /** MKR_CHIEF_APPROVAL encodes an approve ERC20 operation for a ETH address to the MKR Chief contract, sent to the base MKR contract */
  MKR_CHIEF_APPROVAL = 'MKR_CHIEF_APPROVAL'
}

/** GovernanceStepProgress represents the progress of an individual step for setting up governance */
export enum GovernanceStepProgress {
  /** COMPLETE is when the step is complete */
  COMPLETE = 'COMPLETE',
  /** FAILED is when the step failed for any reason */
  FAILED = 'FAILED',
  /** IN_PROGRESS is when the step is in progress */
  IN_PROGRESS = 'IN_PROGRESS',
  /** NOT_READY is when the step hasn't been initiated but isn't ready yet */
  NOT_READY = 'NOT_READY',
  /** READY_TO_START is when the step hasn't been initiated but is ready */
  READY_TO_START = 'READY_TO_START'
}

/** GovernanceSetupState represents the state of an individual step for setting up governance */
export type GovernanceStepState = {
  __typename: 'GovernanceStepState';
  /** operationID is optional if this step has created an operation to link to */
  operationID: Maybe<Scalars['String']['output']>;
  /** progress describes the state of this individual setup step */
  progress: GovernanceStepProgress;
};

/** GroupHoldingBreakdown represents the HoldingBreakdown but filtered by currency type (Crypto and/or Fiat) */
export type GroupHoldingBreakdown = {
  __typename: 'GroupHoldingBreakdown';
  /**
   * Returns the percentage breakdown by asset in portfolio.
   * Set debug to true to enable more logging.
   * NOTE: this could significantly increase query lantency and amount of data returned.
   *   Access it only when must.
   */
  holdings: Array<HoldingBreakdown>;
  /** TotalBalanceAmount returns an amount type object representing the value in USD of all holdings for the organization or vault based on the currency type */
  totalBalanceAmount: Amount;
};

/** High Risk Jurisdictions countries Analsysis */
export type HRJAnalysis = {
  __typename: 'HRJAnalysis';
  /** geolocationRiskScore epresents the risk assessment of the device location */
  geolocationRiskScore: TransactionRiskScore;
  /** ipAddressRiskScore epresents the risk assessment of the IP Address */
  ipAddressRiskScore: TransactionRiskScore;
  /** isGeolocationHighRisk represents whether the device location of the user is HRJ country */
  isGeolocationHighRisk: Scalars['Boolean']['output'];
  /** isIPAdressHighRisk represents whether the IP Address of the user is HRJ country */
  isIPAdressHighRisk: Scalars['Boolean']['output'];
  /** status is the status of the request (e.g: PENDING,UNSUPPORTED,FAILURE,COMPLETE) */
  status: RiskRequestStatus;
  /** analysisType is the type of analysis (e.g: HRJCheck) */
  type: RiskAnalysisType;
  /** user represents the info of the user endorsing the operation */
  user: User;
};

/** Hold is a quantity of an asset. It used to represent holds but now it represents any amount relevant to the asset. */
export type Hold = {
  __typename: 'Hold';
  /** apiKeyName represents the API Key name. Its value will be set if the hold is of HoldTypePlacedByAPIKey, otherwise it may be empty. */
  apiKeyName: Maybe<Scalars['String']['output']>;
  /** description is the user-friendly description of this balance */
  description: Scalars['String']['output'];
  /** endTime is the time the balance is no longer effective */
  endTime: Maybe<Scalars['String']['output']>;
  /** The amount of balance */
  holdAmount: Amount;
  /** holdID represents the hold ID. Its value will be set if the hold is of HoldTypePlacedByAPIKey, otherwise it may be empty. */
  holdID: Maybe<Scalars['String']['output']>;
  /** The category that describes why the hold is in place */
  holdType: HoldType;
  /** startTime is the time the balance goes into effect */
  startTime: Maybe<Scalars['String']['output']>;
};

/** HoldOwnerType should match entities.HoldOwnerType */
export enum HoldOwnerType {
  /** API_KEY */
  API_KEY = 'API_KEY',
  /** MINT_BURN */
  MINT_BURN = 'MINT_BURN',
  /** OPERATION */
  OPERATION = 'OPERATION',
  /** SETTLEMENT_NETWORK */
  SETTLEMENT_NETWORK = 'SETTLEMENT_NETWORK'
}

/** HoldStatus should match entities.HoldStatus */
export enum HoldStatus {
  /** ACTIVE */
  ACTIVE = 'ACTIVE',
  /** EXECUTING */
  EXECUTING = 'EXECUTING',
  /** EXPIRED */
  EXPIRED = 'EXPIRED',
  /** RELEASED */
  RELEASED = 'RELEASED'
}

/** HoldType represents the types/reasons a hold can occur */
export enum HoldType {
  /** ALL_TIME_REWARDS is the total of all rewards that have been received by this address during participation actions */
  ALL_TIME_REWARDS = 'ALL_TIME_REWARDS',
  /** AML is for when AML checks occur on deposits */
  AML = 'AML',
  /** ATTRIBUTION_BLOCKED is for holds created by deposits that are blocked due to the screening process flag them as being potentially suspicious. */
  ATTRIBUTION_BLOCKED = 'ATTRIBUTION_BLOCKED',
  /** ATTRIBUTION_UNDER_REVIEW  is for holds created by deposits that are under review, that is, waiting for the screening process of the attribution to complete. */
  ATTRIBUTION_UNDER_REVIEW = 'ATTRIBUTION_UNDER_REVIEW',
  /** AVAILABLE is the amount that can be withdrawn from the regular address */
  AVAILABLE = 'AVAILABLE',
  /** DELEGATED_USABLE represents the delegated tokens that are not unvested */
  DELEGATED_USABLE = 'DELEGATED_USABLE',
  /** DELEGATE_AVAILABLE is the amount that can be delegated by this address */
  DELEGATE_AVAILABLE = 'DELEGATE_AVAILABLE',
  /** DELEGATE_VOTE_LOCK is a user-initiated lock of token to a delegate */
  DELEGATE_VOTE_LOCK = 'DELEGATE_VOTE_LOCK',
  /** DELEGATION_REWARDS is the amount of rewards accrued from staking */
  DELEGATION_REWARDS = 'DELEGATION_REWARDS',
  /**
   * EXECUTE_OPERATION indicates a hold applied as the result of a pending operation for this asset. Examples include
   * withdrawals, transfers, and network transaction fees.
   */
  EXECUTE_OPERATION = 'EXECUTE_OPERATION',
  /** LOCK is a user-initiated hold (for example, locking MKR) */
  LOCK = 'LOCK',
  /** MINT_BURN is the amount locked to be used in a mint or burn conversion */
  MINT_BURN = 'MINT_BURN',
  /** NON_ATTRIBUTABLE is for holds created by deposits that cannot be attributed by any user. */
  NON_ATTRIBUTABLE = 'NON_ATTRIBUTABLE',
  /** OFFCHAIN_VESTING is for holds created by offchain vesting to hold funds prior to their vesting time. */
  OFFCHAIN_VESTING = 'OFFCHAIN_VESTING',
  /** PENDING is a balance state right before LOCK, where it exists but it is not accessible */
  PENDING = 'PENDING',
  /** PENDING_ATTRIBUTION is the amount holded due to pending deposit attributions */
  PENDING_ATTRIBUTION = 'PENDING_ATTRIBUTION',
  /** PLACED_BY_API_KEY indicates a hold that was placed by a user via an API client */
  PLACED_BY_API_KEY = 'PLACED_BY_API_KEY',
  /** REDELEGATING */
  REDELEGATING = 'REDELEGATING',
  /** RESTAKING_REWARDS is the amount of rewards accrued from restaking */
  RESTAKING_REWARDS = 'RESTAKING_REWARDS',
  /** SETTLEMENT_NETWORK is the amount locked to be used in a settlement. It's locked right before executing a settlement and unlocked after the settlement is completed. */
  SETTLEMENT_NETWORK = 'SETTLEMENT_NETWORK',
  /** SPAM is the amount holded due to deposits flagged as spam */
  SPAM = 'SPAM',
  /** UNBOND is a user-initiated waiting hold applied for a successful unbonding operation (for example, unbonding cosmos) */
  UNBOND = 'UNBOND',
  /** UNBONDED */
  UNBONDED = 'UNBONDED',
  /** UNVESTED is the opposite of BalanceTypeVested. These funds can't be claimed yet. */
  UNVESTED = 'UNVESTED',
  /** UNVESTED_DELEGATABLE is the unvested balance that is delegatable. */
  UNVESTED_DELEGATABLE = 'UNVESTED_DELEGATABLE',
  /** VESTED applies to vesting contracts and represents the funds that are held in it and have already vested, so they can be claimed (provided they are also unlocked) */
  VESTED = 'VESTED',
  /** VESTING_AVAILABLE is the amount that's in your vesting contract and is available for being claimed */
  VESTING_AVAILABLE = 'VESTING_AVAILABLE',
  /** VESTING_TOTAL is the total balance of the vesting contract if there is one */
  VESTING_TOTAL = 'VESTING_TOTAL',
  /** VESTING_UNLOCKED is the portion of the balance that's in the vesting contract and is not locked */
  VESTING_UNLOCKED = 'VESTING_UNLOCKED',
  /** VOTE_PROXY_LOCK is the amount locked by the vote proxy contract */
  VOTE_PROXY_LOCK = 'VOTE_PROXY_LOCK'
}

/**
 * HoldingBreakdown is a misnomer - should have been called HoldingWithBreakdowns:
 * - A holding is the total balance (amount with asset type) for a given asset type.
 * - The breakdown adds additional info to this balance, including sub-balances and the percentage of the portfolio allocated to each asset type.
 * Note: A holdingBreakdown can cause a significant query latency, especially when aggregating large numbers of wallets.
 */
export type HoldingBreakdown = {
  __typename: 'HoldingBreakdown';
  /** Asset Type Info of the holding asset */
  assetTypeInfo: AssetTypeInfo;
  /** FIAT asset can have multiple allocations, this field will return the allocations for the FIAT asset */
  balanceAllocation: Array<BalanceAllocation>;
  /** HoldingBalance is the sum of all amounts of assets of this asset type within the organization/vault */
  holdingBalance: Amount;
  /**
   * Percentage of the parent org/vault's portfolio that this holding makes up (proportion out of 1 format. Eg: 100% -> 1, 20% -> 0.2).
   * This is probably misplaced, and should have been a map of asset types to percentages on the org/vault resolvers.
   */
  holdingPercentage: Scalars['String']['output'];
  /** Number of wallets that hold this asset */
  numberOfWallets: Scalars['Int']['output'];
  /**
   * subBalances represent a portion of the balance that is
   * being used for a specific purpose, eg "available" or "locked".
   */
  subBalances: Array<NamedBalance>;
};


/**
 * HoldingBreakdown is a misnomer - should have been called HoldingWithBreakdowns:
 * - A holding is the total balance (amount with asset type) for a given asset type.
 * - The breakdown adds additional info to this balance, including sub-balances and the percentage of the portfolio allocated to each asset type.
 * Note: A holdingBreakdown can cause a significant query latency, especially when aggregating large numbers of wallets.
 */
export type HoldingBreakdownsubBalancesArgs = {
  visibleForPurpose?: InputMaybe<VisibleForPurpose>;
};

/** Sorting options for holdings in portfolios and vaults */
export enum HoldingSortFields {
  /** Sorts by the USD value of each holding with the holding of assettype = USD first */
  USD_FIRST = 'USD_FIRST',
  /** Sort by the USD value of each holding */
  USD_VALUE = 'USD_VALUE'
}

export type IAM = {
  __typename: 'IAM';
  checkPermission: Scalars['Boolean']['output'];
  permissions: IAMPermissionConnection;
  resourceID: Scalars['String']['output'];
  roles: IAMRoleConnection;
};


export type IAMcheckPermissionArgs = {
  action: IAMPermissionAction;
  principal?: InputMaybe<Scalars['String']['input']>;
  resourceID: Scalars['String']['input'];
};


export type IAMpermissionsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IAMPermissionOrder>;
  where?: InputMaybe<IAMPermissionWhereInput>;
};


export type IAMrolesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IAMRoleOrder>;
  where?: InputMaybe<IAMRoleWhereInput>;
};

/** IAMAccessLevel is enum for the field accessLevel */
export enum IAMAccessLevel {
  /** User is Approver. Allowed to approve Operations on the Resource. */
  APPROVER = 'APPROVER',
  /** User is Initiator. Allowed to initiate Operations on the Resource. */
  INITIATOR = 'INITIATOR',
  /** User is both Initiator and Approver. Allowed to initiator and approve Operations on the Resource. */
  INITIATOR_AND_APPROVER = 'INITIATOR_AND_APPROVER'
}

export type IAMCategory = Node & {
  __typename: 'IAMCategory';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['String']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  permissions: Maybe<Array<IAMPermission>>;
  type: IAMCategoryType;
  updatedAt: Maybe<Scalars['Time']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** CategoryType is enum for the field type */
export enum IAMCategoryType {
  ADMINISTRATOR_POLICIES_MANAGEMENT = 'ADMINISTRATOR_POLICIES_MANAGEMENT',
  API_KEY_MANAGEMENT = 'API_KEY_MANAGEMENT',
  API_PERMISSION_GROUP_MANAGEMENT = 'API_PERMISSION_GROUP_MANAGEMENT',
  CONNECTED_APPS_MANAGEMENT = 'CONNECTED_APPS_MANAGEMENT',
  SETTLEMENT_NETWORK_MANAGEMENT = 'SETTLEMENT_NETWORK_MANAGEMENT',
  STAFF_MEMBERS_PERMISSIONS = 'STAFF_MEMBERS_PERMISSIONS',
  TRUSTED_DESTINATIONS_MANAGEMENT = 'TRUSTED_DESTINATIONS_MANAGEMENT',
  TRUSTED_SMART_CONTRACTS_MANAGEMENT = 'TRUSTED_SMART_CONTRACTS_MANAGEMENT',
  UNSPECIFIED = 'UNSPECIFIED',
  USER_ACCESS_MANAGEMENT = 'USER_ACCESS_MANAGEMENT',
  VAULTS_MANAGEMENT = 'VAULTS_MANAGEMENT',
  VAULT_TRANSACTIONS = 'VAULT_TRANSACTIONS'
}

/**
 * IAMCategoryWhereInput is used for filtering IAMCategory objects.
 * Input was generated by ent.
 */
export type IAMCategoryWhereInput = {
  and?: InputMaybe<Array<IAMCategoryWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** permissions edge predicates */
  hasPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  hasPermissionsWith?: InputMaybe<Array<IAMPermissionWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<IAMCategoryWhereInput>;
  or?: InputMaybe<Array<IAMCategoryWhereInput>>;
  /** type field predicates */
  type?: InputMaybe<IAMCategoryType>;
  typeIn?: InputMaybe<Array<IAMCategoryType>>;
  typeNEQ?: InputMaybe<IAMCategoryType>;
  typeNotIn?: InputMaybe<Array<IAMCategoryType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

/** IAMMember is a member for a role */
export type IAMMember = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  vaults: VaultConnection;
};


/** IAMMember is a member for a role */
export type IAMMembervaultsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VaultWhereInput>;
};

/** A connection to a list of items. */
export type IAMMemberConnection = {
  __typename: 'IAMMemberConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<IAMMemberEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IAMMemberEdge = {
  __typename: 'IAMMemberEdge';
  /** A CursorType for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<IAMMember>;
};

/** Ordering options for Member connections */
export type IAMMemberOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Users. */
  field: IAMMemberOrderField;
};

/** Properties by which IAM Member connections can be ordered. */
export enum IAMMemberOrderField {
  NAME = 'NAME'
}

export type IAMMemberUser = IAMMember & Node & {
  __typename: 'IAMMemberUser';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  user: User;
  vaults: VaultConnection;
};


export type IAMMemberUservaultsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VaultWhereInput>;
};

/** A filter to be used against User fields. */
export type IAMMemberWhereInput = {
  /** id field predicates */
  id?: InputMaybe<Scalars['String']['input']>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type IAMPermission = Node & {
  __typename: 'IAMPermission';
  action: IAMPermissionAction;
  availability: IAMPermissionAvailability;
  category: Maybe<IAMCategory>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['String']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  resourceType: IAMPermissionResourceType;
  roles: Maybe<Array<Role>>;
  updatedAt: Maybe<Scalars['Time']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** IAMPermissionAction is enum for the field action */
export enum IAMPermissionAction {
  API_KEY_CREATE_INITIATE = 'API_KEY_CREATE_INITIATE',
  API_KEY_REVOKE_INITIATE = 'API_KEY_REVOKE_INITIATE',
  API_KEY_UPDATE_INITIATE = 'API_KEY_UPDATE_INITIATE',
  API_KEY_WITHDRAW_INITIATE = 'API_KEY_WITHDRAW_INITIATE',
  BACKOFFICE_SENSITIVE_DATA_ACCESS = 'BACKOFFICE_SENSITIVE_DATA_ACCESS',
  CAN_APPROVE_SELFHOSTED_ADS_WITHDRAWALS_ACCESS = 'CAN_APPROVE_SELFHOSTED_ADS_WITHDRAWALS_ACCESS',
  CLIENT_OPS_ACCESS = 'CLIENT_OPS_ACCESS',
  CLIENT_OPS_DIRECT_EDIT_ACCESS = 'CLIENT_OPS_DIRECT_EDIT_ACCESS',
  CLOSE_CLIENT_ACCOUNT_ACCESS = 'CLOSE_CLIENT_ACCOUNT_ACCESS',
  COMPLIANCE_ALERTS_OPS_ACCESS = 'COMPLIANCE_ALERTS_OPS_ACCESS',
  COMPLIANCE_DOCS_KYC_OPS_ACCESS = 'COMPLIANCE_DOCS_KYC_OPS_ACCESS',
  COMPLIANCE_DOCS_TRANSACTION_ACTIVITY_OPS_ACCESS = 'COMPLIANCE_DOCS_TRANSACTION_ACTIVITY_OPS_ACCESS',
  COMPLIANCE_HOLD_ACCESS = 'COMPLIANCE_HOLD_ACCESS',
  COMPLIANCE_TRUST_ACCESS = 'COMPLIANCE_TRUST_ACCESS',
  CONNECTED_APPS_VIEWERS_ACCESS = 'CONNECTED_APPS_VIEWERS_ACCESS',
  CREATE_VAULT_INITIATE = 'CREATE_VAULT_INITIATE',
  CRR_MODEL_RISK_CONFIG_ACCESS = 'CRR_MODEL_RISK_CONFIG_ACCESS',
  DEPOSIT_INITIATE = 'DEPOSIT_INITIATE',
  DISABLE_VAULT_INITIATE = 'DISABLE_VAULT_INITIATE',
  ENABLE_VAULT_INITIATE = 'ENABLE_VAULT_INITIATE',
  FREEZE_WITHDRAWAL_ACCESS = 'FREEZE_WITHDRAWAL_ACCESS',
  KYC_ALERTS_OPS_ACCESS = 'KYC_ALERTS_OPS_ACCESS',
  LENDING_OPS_ACCESS = 'LENDING_OPS_ACCESS',
  LENDING_OPS_TRANSFERS_ACCESS = 'LENDING_OPS_TRANSFERS_ACCESS',
  MANUAL_DEPOSIT_ATTRIBUTION_ACCESS = 'MANUAL_DEPOSIT_ATTRIBUTION_ACCESS',
  ON_CALL_ACCESS = 'ON_CALL_ACCESS',
  OPERATOR_SELF_ACCESS = 'OPERATOR_SELF_ACCESS',
  ORGANIZATIONS_IAM_VIEW = 'ORGANIZATIONS_IAM_VIEW',
  ORGANIZATIONS_USERS_DOWNLOAD = 'ORGANIZATIONS_USERS_DOWNLOAD',
  ORGANIZATION_POLICIES_VIEW = 'ORGANIZATION_POLICIES_VIEW',
  PERMISSION_GROUP_CREATE_INITIATE = 'PERMISSION_GROUP_CREATE_INITIATE',
  PERMISSION_GROUP_DELETE_INITIATE = 'PERMISSION_GROUP_DELETE_INITIATE',
  PERMISSION_GROUP_UPDATE_INITIATE = 'PERMISSION_GROUP_UPDATE_INITIATE',
  PERSON_KYC_VIEWERS_ACCESS = 'PERSON_KYC_VIEWERS_ACCESS',
  REVIEWER_HOLD_ACCESS = 'REVIEWER_HOLD_ACCESS',
  REVIEWER_TRUST_ACCESS = 'REVIEWER_TRUST_ACCESS',
  RISK_TRADING_LIMITS_ACCESS = 'RISK_TRADING_LIMITS_ACCESS',
  ROLE_CHANGE_INITIATE = 'ROLE_CHANGE_INITIATE',
  SETTLEMENT_NETWORK_PARTICIPANTS_EDIT = 'SETTLEMENT_NETWORK_PARTICIPANTS_EDIT',
  SETTLEMENT_OPS_ACCESS = 'SETTLEMENT_OPS_ACCESS',
  STAKING_INITIATE = 'STAKING_INITIATE',
  TMS_MODEL_RISK_CONFIG_ACCESS = 'TMS_MODEL_RISK_CONFIG_ACCESS',
  TRADING_ACCESS = 'TRADING_ACCESS',
  TRADING_COMPLIANCE_ACCESS = 'TRADING_COMPLIANCE_ACCESS',
  TRADING_OPS_ACCESS = 'TRADING_OPS_ACCESS',
  TRUSTED_DESTINATIONS_ADD_INITIATE = 'TRUSTED_DESTINATIONS_ADD_INITIATE',
  TRUSTED_DESTINATIONS_REMOVE_INITIATE = 'TRUSTED_DESTINATIONS_REMOVE_INITIATE',
  TRUSTED_DESTINATIONS_VIEW = 'TRUSTED_DESTINATIONS_VIEW',
  TRUSTED_SMART_CONTRACTS_ADD_INITIATE = 'TRUSTED_SMART_CONTRACTS_ADD_INITIATE',
  TRUSTED_SMART_CONTRACTS_REMOVE_INITIATE = 'TRUSTED_SMART_CONTRACTS_REMOVE_INITIATE',
  TRUSTED_SMART_CONTRACTS_VIEW = 'TRUSTED_SMART_CONTRACTS_VIEW',
  UNSPECIFIED = 'UNSPECIFIED',
  UPDATE_ORG_POLICIES_INITIATE = 'UPDATE_ORG_POLICIES_INITIATE',
  UPDATE_VAULT_POLICIES_INITIATE = 'UPDATE_VAULT_POLICIES_INITIATE',
  USER_ADD_INITIATE = 'USER_ADD_INITIATE',
  USER_REMOVE_INITIATE = 'USER_REMOVE_INITIATE',
  USER_REPLACE_INITIATE = 'USER_REPLACE_INITIATE',
  VAULTS_DETAILS_EDIT = 'VAULTS_DETAILS_EDIT',
  VAULTS_DETAILS_VIEW = 'VAULTS_DETAILS_VIEW',
  VAULTS_MEMBERS_VIEW = 'VAULTS_MEMBERS_VIEW',
  VAULTS_POLICIES_VIEW = 'VAULTS_POLICIES_VIEW',
  VAULTS_WALLET_CREATE = 'VAULTS_WALLET_CREATE',
  VIEW_CRR_HISTORY_ACCESS = 'VIEW_CRR_HISTORY_ACCESS',
  VOTE_INITIATE = 'VOTE_INITIATE',
  WITHDRAW_INITIATE = 'WITHDRAW_INITIATE',
  WITHDRAW_VIEW = 'WITHDRAW_VIEW'
}

/** PermissionAvailability is enum for the field availability */
export enum IAMPermissionAvailability {
  BASE = 'BASE',
  SPECIFIC = 'SPECIFIC',
  UNSPECIFIED = 'UNSPECIFIED'
}

/** A connection to a list of items. */
export type IAMPermissionConnection = {
  __typename: 'IAMPermissionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<IAMPermissionEdge>>>;
  /** True if at least one of the permissions is related to dynamic resources, e.g. vaults. */
  hasDynamicPermissions: Scalars['Boolean']['output'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IAMPermissionEdge = {
  __typename: 'IAMPermissionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<IAMPermission>;
};

/** Ordering options for Permission connections */
export type IAMPermissionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Permissions. */
  field: IAMPermissionOrderField;
};

/** Properties by which Permission connections can be ordered. */
export enum IAMPermissionOrderField {
  DISPLAY_NAME = 'DISPLAY_NAME',
  NAME = 'NAME'
}

/** IAMPermissionResourceType is enum for the field resource_type */
export enum IAMPermissionResourceType {
  ORGANIZATION = 'ORGANIZATION',
  UNDEFINED = 'UNDEFINED',
  VAULT = 'VAULT'
}

/**
 * IAMPermissionWhereInput is used for filtering Permission objects.
 * Input was generated by ent.
 */
export type IAMPermissionWhereInput = {
  /** action field predicates */
  action?: InputMaybe<IAMPermissionAction>;
  actionIn?: InputMaybe<Array<IAMPermissionAction>>;
  actionNEQ?: InputMaybe<IAMPermissionAction>;
  actionNotIn?: InputMaybe<Array<IAMPermissionAction>>;
  and?: InputMaybe<Array<IAMPermissionWhereInput>>;
  /** availability field predicates */
  availability?: InputMaybe<IAMPermissionAvailability>;
  availabilityIn?: InputMaybe<Array<IAMPermissionAvailability>>;
  availabilityNEQ?: InputMaybe<IAMPermissionAvailability>;
  availabilityNotIn?: InputMaybe<Array<IAMPermissionAvailability>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** category edge predicates */
  hasCategory?: InputMaybe<Scalars['Boolean']['input']>;
  hasCategoryWith?: InputMaybe<Array<IAMCategoryWhereInput>>;
  /** roles edge predicates */
  hasRoles?: InputMaybe<Scalars['Boolean']['input']>;
  hasRolesWith?: InputMaybe<Array<IAMRoleWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<IAMPermissionWhereInput>;
  or?: InputMaybe<Array<IAMPermissionWhereInput>>;
  /** resource_type field predicates */
  resourceType?: InputMaybe<IAMPermissionResourceType>;
  resourceTypeIn?: InputMaybe<Array<IAMPermissionResourceType>>;
  resourceTypeNEQ?: InputMaybe<IAMPermissionResourceType>;
  resourceTypeNotIn?: InputMaybe<Array<IAMPermissionResourceType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

/** IAMResource is a resource where we have IAM controls */
export type IAMResource = {
  name: Scalars['String']['output'];
};

/** IAMRole is a group of permissions */
export type IAMRole = Node & {
  __typename: 'IAMRole';
  availability: IAMRoleAvailability;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['String']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  hasRoles: Maybe<Array<IAMRole>>;
  id: Scalars['ID']['output'];
  isInRoles: Maybe<Array<IAMRole>>;
  members: IAMMemberConnection;
  name: Scalars['String']['output'];
  permissions: IAMPermissionConnection;
  roleType: IAMRoleRoleType;
  subtitle: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['Time']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};


/** IAMRole is a group of permissions */
export type IAMRolemembersArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IAMMemberOrder>;
  where?: InputMaybe<IAMMemberWhereInput>;
};


/** IAMRole is a group of permissions */
export type IAMRolepermissionsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IAMPermissionOrder>;
  where?: InputMaybe<IAMPermissionWhereInput>;
};

/** IAMRoleAvailability is enum for the field availability */
export enum IAMRoleAvailability {
  BASE = 'BASE',
  SPECIFIC = 'SPECIFIC',
  UNSPECIFIED = 'UNSPECIFIED'
}

/** IAMRoleChange is a single role change that will be the outcome of a given operation. */
export type IAMRoleChange = {
  __typename: 'IAMRoleChange';
  /** Added field represents the users that will be added role. */
  added: Array<Actor>;
  /** Removed field represents the users that will be removed role. */
  removed: Array<Actor>;
  /** resources field is the list of resources bounded to the role change. */
  resources: Array<Resource>;
  /** roleSnapshot is the state of the role before the change. */
  roleSnapshot: IAMRoleSnapshot;
};

/** IAMRoleChanges is the list of role changes that will be the outcome of a given operation. */
export type IAMRoleChanges = {
  __typename: 'IAMRoleChanges';
  /** changes is the list of role changes that will be the outcome of a given operation. */
  changes: Array<IAMRoleChange>;
  /** summary is the summary of the role changes. */
  summary: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type IAMRoleConnection = {
  __typename: 'IAMRoleConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<IAMRoleEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IAMRoleEdge = {
  __typename: 'IAMRoleEdge';
  /** A CursorType for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<IAMRole>;
};

/** Ordering options for Role connections */
export type IAMRoleOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Roles. */
  field: IAMRoleOrderField;
};

/** Properties by which Role connections can be ordered. */
export enum IAMRoleOrderField {
  CREATED_AT = 'CREATED_AT',
  DISPLAY_NAME = 'DISPLAY_NAME',
  NAME = 'NAME'
}

/** IAMRoleRoleType is enum for the field role_type */
export enum IAMRoleRoleType {
  CUSTOM = 'CUSTOM',
  SYSTEM = 'SYSTEM',
  UNSPECIFIED = 'UNSPECIFIED'
}

/** IAMRoleSnapshot is the state of a role at the time of the operation creation. */
export type IAMRoleSnapshot = {
  __typename: 'IAMRoleSnapshot';
  /** description is the description of the role. */
  description: Scalars['String']['output'];
  /** id is the id of the role. */
  id: Scalars['ID']['output'];
  /** name is the name of the role. */
  name: Scalars['String']['output'];
};

/**
 * IAMRoleWhereInput is used for filtering Role objects.
 * Input was generated by ent.
 */
export type IAMRoleWhereInput = {
  and?: InputMaybe<Array<IAMRoleWhereInput>>;
  /** availability field predicates */
  availability?: InputMaybe<IAMRoleAvailability>;
  availabilityIn?: InputMaybe<Array<IAMRoleAvailability>>;
  availabilityNEQ?: InputMaybe<IAMRoleAvailability>;
  availabilityNotIn?: InputMaybe<Array<IAMRoleAvailability>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** has_roles edge predicates */
  hasHasRoles?: InputMaybe<Scalars['Boolean']['input']>;
  hasHasRolesWith?: InputMaybe<Array<IAMRoleWhereInput>>;
  /** is_in_roles edge predicates */
  hasIsInRoles?: InputMaybe<Scalars['Boolean']['input']>;
  hasIsInRolesWith?: InputMaybe<Array<IAMRoleWhereInput>>;
  /** permissions edge predicates */
  hasPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  hasPermissionsWith?: InputMaybe<Array<IAMRoleWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<IAMRoleWhereInput>;
  or?: InputMaybe<Array<IAMRoleWhereInput>>;
  /** organization_key_id field predicates */
  organizationKeyID?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDContains?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDGT?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDGTE?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationKeyIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  organizationKeyIDLT?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDLTE?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDNEQ?: InputMaybe<Scalars['String']['input']>;
  organizationKeyIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationKeyIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** role_type field predicates */
  roleType?: InputMaybe<IAMRoleRoleType>;
  roleTypeIn?: InputMaybe<Array<IAMRoleRoleType>>;
  roleTypeNEQ?: InputMaybe<IAMRoleRoleType>;
  roleTypeNotIn?: InputMaybe<Array<IAMRoleRoleType>>;
  /** subtitle field predicates */
  subtitle?: InputMaybe<Scalars['String']['input']>;
  subtitleContains?: InputMaybe<Scalars['String']['input']>;
  subtitleContainsFold?: InputMaybe<Scalars['String']['input']>;
  subtitleEqualFold?: InputMaybe<Scalars['String']['input']>;
  subtitleGT?: InputMaybe<Scalars['String']['input']>;
  subtitleGTE?: InputMaybe<Scalars['String']['input']>;
  subtitleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  subtitleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  subtitleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  subtitleIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  subtitleLT?: InputMaybe<Scalars['String']['input']>;
  subtitleLTE?: InputMaybe<Scalars['String']['input']>;
  subtitleNEQ?: InputMaybe<Scalars['String']['input']>;
  subtitleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  subtitleNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

/** IAMUserAccess represents a user-access relation. */
export type IAMUserAccess = Node & {
  __typename: 'IAMUserAccess';
  /** The access level of the user. */
  accessLevel: IAMAccessLevel;
  /** Unique identifier of this IAMUserAccess. Defaults to user.ID. */
  id: Scalars['ID']['output'];
  /** The user associated with this IAMUserAccess. */
  user: UserV2;
};

/** A connection to a list of items. */
export type IAMUserAccessConnection = {
  __typename: 'IAMUserAccessConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<IAMUserAccessEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IAMUserAccessEdge = {
  __typename: 'IAMUserAccessEdge';
  /** A CursorType for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<IAMUserAccess>;
};

/** A filter to be used against IAMUserAccess fields. */
export type IAMUserAccessWhereInput = {
  /** accessLevel field predicates */
  accessLevel?: InputMaybe<Scalars['String']['input']>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type IAMUserRole = Node & {
  __typename: 'IAMUserRole';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['String']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  hasRoles: Maybe<Array<IAMRole>>;
  id: Scalars['ID']['output'];
  isInRoles: Maybe<Array<IAMRole>>;
  name: Scalars['String']['output'];
  organizationKeyID: Maybe<Scalars['String']['output']>;
  permissions: IAMPermissionConnection;
  roleType: IAMRoleRoleType;
  subtitle: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['Time']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
  vaults: VaultConnection;
};


export type IAMUserRolepermissionsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IAMPermissionOrder>;
  where?: InputMaybe<IAMPermissionWhereInput>;
};


export type IAMUserRolevaultsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VaultWhereInput>;
};

export type IAMUserRoleConnection = {
  __typename: 'IAMUserRoleConnection';
  edges: Maybe<Array<Maybe<IAMUserRoleEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type IAMUserRoleEdge = {
  __typename: 'IAMUserRoleEdge';
  cursor: Scalars['CursorType']['output'];
  node: Maybe<IAMUserRole>;
};

/** IconType is the type of the icon requested */
export enum IconType {
  /** PNG */
  PNG = 'PNG',
  /** SVG */
  SVG = 'SVG'
}

/**
 * IndividualAffiliateRelationship describes the relationship between an individual
 * and a affiliate.
 */
export enum IndividualAffiliateRelationship {
  /** AML_SERVICE_PROVIDER represents an AML Service Provider relation between individual and affiliate */
  AML_SERVICE_PROVIDER = 'AML_SERVICE_PROVIDER',
  /** AUTHORIZED_SIGNATORY represents an authorized signatory relation between individual and affiliate */
  AUTHORIZED_SIGNATORY = 'AUTHORIZED_SIGNATORY',
  /** BENEFICIAL_OWNER represents a Beneficial Owner relation between individual and affiliate */
  BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
  /** BENEFICIARY represents a Beneficiary relation between individual and affiliate */
  BENEFICIARY = 'BENEFICIARY',
  /** BOARD_DIRECTOR represents a Board Director relation between individual and affiliate */
  BOARD_DIRECTOR = 'BOARD_DIRECTOR',
  /** CONTROL_PERSON represents a Control Person relation between individual and affiliate */
  CONTROL_PERSON = 'CONTROL_PERSON',
  /** FUND_ADMINISTRATOR represents a Fund Administrator relation between individual and affiliate */
  FUND_ADMINISTRATOR = 'FUND_ADMINISTRATOR',
  /** GENERAL_PARTNER represents a General Partner relation between individual and affiliate */
  GENERAL_PARTNER = 'GENERAL_PARTNER',
  /** GRANTOR_DONOR represents a Grantor/Donor/Settlor relation between individual and affiliate */
  GRANTOR_DONOR = 'GRANTOR_DONOR',
  /** INVESTMENT_MANAGER represents a Investment Manager between individual and affiliate */
  INVESTMENT_MANAGER = 'INVESTMENT_MANAGER',
  /** MANAGING_MEMBER represents a Managing Member relation between individual and affiliate */
  MANAGING_MEMBER = 'MANAGING_MEMBER',
  /** OTHER represents another relation between individual and affiliate */
  OTHER = 'OTHER',
  /** PARTICIPATING_MEMBER represents an Authorized User relation between individual and affiliate */
  PARTICIPATING_MEMBER = 'PARTICIPATING_MEMBER',
  /** SENIOR_OFFICER represents a Senior Officer relation between individual and affiliate */
  SENIOR_OFFICER = 'SENIOR_OFFICER',
  /** TRUSTEE_SUCCESSOR_TRUSTEE represents a Trustee/Success Trustee relation between individual and affiliate */
  TRUSTEE_SUCCESSOR_TRUSTEE = 'TRUSTEE_SUCCESSOR_TRUSTEE'
}

/** IndividualAffiliateRelationshipAudit contains information related to an individual affiliate relationship audit */
export type IndividualAffiliateRelationshipAudit = Node & {
  __typename: 'IndividualAffiliateRelationshipAudit';
  /** The relation with affiliate */
  affiliate: InstitutionalAffiliate;
  /** The audit action */
  auditAction: Scalars['String']['output'];
  /** The audit notes */
  auditNotes: Maybe<Scalars['String']['output']>;
  /** The audit timestamp */
  auditTimestamp: Scalars['Timestamp']['output'];
  /** The audit user */
  auditUser: Scalars['String']['output'];
  /** The individual control title within the affiliate */
  controlTitle: Maybe<Scalars['String']['output']>;
  /** The individual affiliate relationship id */
  id: Scalars['ID']['output'];
  /** The relation with individual */
  individual: InstitutionalIndividual;
  /** The kyc screening audit related information */
  kycScreening: Maybe<KYCScreening>;
  /** Specified if the relation is with individual or related party */
  memberType: Scalars['String']['output'];
  /** The individual ownership percentage related with the affiliate */
  ownershipPercentage: Maybe<Scalars['String']['output']>;
  /** The relation with related party */
  relatedParty: Maybe<KYCRelatedParty>;
  /** The individual roles within the affiliate */
  relationshipTypes: Maybe<Array<IndividualAffiliateRelationship>>;
};

/** Type of information and reason rejected */
export type IndividualInformationRejection = {
  __typename: 'IndividualInformationRejection';
  /** Type of information */
  informationType: IndividualInformationType;
  /** Reason the information was rejected */
  rejectionReason: Scalars['String']['output'];
};

/** Individual PII Types */
export enum IndividualInformationType {
  /** App access desired. E.g. Admin, regular, none. */
  ACCESS_TYPE = 'ACCESS_TYPE',
  /** ACKNOWLEDGE_EMAIL... */
  ACKNOWLEDGE_EMAIL = 'ACKNOWLEDGE_EMAIL',
  /** ACKNOWLEDGE_NAME ... */
  ACKNOWLEDGE_NAME = 'ACKNOWLEDGE_NAME',
  /** ACKNOWLEDGE_TITLE... */
  ACKNOWLEDGE_TITLE = 'ACKNOWLEDGE_TITLE',
  /** First part of the address, usually door number and street */
  ADDRESS = 'ADDRESS',
  /** Second part of the address, usually city, state and zip code */
  ADDRESS2 = 'ADDRESS2',
  /** ADDRESS_PROOF ... */
  ADDRESS_PROOF = 'ADDRESS_PROOF',
  /** ADDRESS_PROOF_DOCUMENT ... */
  ADDRESS_PROOF_DOCUMENT = 'ADDRESS_PROOF_DOCUMENT',
  /** ADS_KYC_IND_FORM_SUBMISSION_ID ... */
  ADS_KYC_INDIVIDUAL_FORM_SUBMISSION_ID = 'ADS_KYC_INDIVIDUAL_FORM_SUBMISSION_ID',
  /** CALCUATED_INFO_HASH ... */
  CALCULATED_INFO_HASH = 'CALCULATED_INFO_HASH',
  /** CITIZENSHIP_STATUS ... */
  CITIZENSHIP_STATUS = 'CITIZENSHIP_STATUS',
  /** CITY */
  CITY = 'CITY',
  /** COMPANY_NAME ... */
  COMPANY_NAME = 'COMPANY_NAME',
  /** COUNTRY */
  COUNTRY = 'COUNTRY',
  /** Date of birth */
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  /** Email */
  EMAIL = 'EMAIL',
  /** First name */
  FIRST_NAME = 'FIRST_NAME',
  /** GOVERNMENT_ID */
  GOVERNMENT_ID = 'GOVERNMENT_ID',
  /** HAS_SSN */
  HAS_SSN = 'HAS_SSN',
  /** ID Card back side picture */
  ID_CARD_BACK = 'ID_CARD_BACK',
  /** ID_CARD_BACK_SECOND... */
  ID_CARD_BACK_SECOND = 'ID_CARD_BACK_SECOND',
  /** ID Card front side picture */
  ID_CARD_FRONT = 'ID_CARD_FRONT',
  /** ID_CARD_FRONT_SECOND ... */
  ID_CARD_FRONT_SECOND = 'ID_CARD_FRONT_SECOND',
  /** ID_ISSUING_COUNTRY ... */
  ID_ISSUING_COUNTRY = 'ID_ISSUING_COUNTRY',
  /** ID_ISSUING_COUNTRY_SECOND ... */
  ID_ISSUING_COUNTRY_SECOND = 'ID_ISSUING_COUNTRY_SECOND',
  /** The type of the ID (ex. Passport) */
  ID_TYPE = 'ID_TYPE',
  /** ID_TYPE_SECOND ... */
  ID_TYPE_SECOND = 'ID_TYPE_SECOND',
  /** The type of individual. E.g. beneficial owner, authorized person, participating member */
  INDIVIDUAL_TYPE = 'INDIVIDUAL_TYPE',
  /** IS_MAILING_ADDRESS_DIFFERENT ... */
  IS_MAILING_ADDRESS_DIFFERENT = 'IS_MAILING_ADDRESS_DIFFERENT',
  /** KYC_IND_FORM_SUBMISSION_ID ... */
  KYC_INDIVIDUAL_FORM_SUBMISSION_ID = 'KYC_INDIVIDUAL_FORM_SUBMISSION_ID',
  /** Last name */
  LAST_NAME = 'LAST_NAME',
  /** MAILING_CITY ... */
  MAILING_CITY = 'MAILING_CITY',
  /** MAILING_COUNTRY ... */
  MAILING_COUNTRY = 'MAILING_COUNTRY',
  /** MAILING_STATE ... */
  MAILING_STATE = 'MAILING_STATE',
  /** MAILING_STREET_ADDRESS ... */
  MAILING_STREET_ADDRESS = 'MAILING_STREET_ADDRESS',
  /** MAILING_ZIP_CODE ... */
  MAILING_ZIP_CODE = 'MAILING_ZIP_CODE',
  /** MATERIAL_NEWS */
  MATERIAL_NEWS = 'MATERIAL_NEWS',
  /** Middle name */
  MIDDLE_NAME = 'MIDDLE_NAME',
  /** Name */
  NAME = 'NAME',
  /** NON_MATERIAL_NEWS */
  NON_MATERIAL_NEWS = 'NON_MATERIAL_NEWS',
  /** ONBOARDING_API_SUBMISSION_ID ... */
  ONBOARDING_API_SUBMISSION_ID = 'ONBOARDING_API_SUBMISSION_ID',
  /** PASSPORT_DOCUMENT */
  PASSPORT_DOCUMENT = 'PASSPORT_DOCUMENT',
  /** PEP ... */
  PEP = 'PEP',
  /** PEP_COUNTRY_SERVED ... */
  PEP_COUNTRY_SERVED = 'PEP_COUNTRY_SERVED',
  /** PEP_END_DATE_SERVED ... */
  PEP_END_DATE_SERVED = 'PEP_END_DATE_SERVED',
  /** PEP_ROLE ... */
  PEP_ROLE = 'PEP_ROLE',
  /** PEP_START_DATE_SERVED ... */
  PEP_START_DATE_SERVED = 'PEP_START_DATE_SERVED',
  /** Phone number */
  PHONE = 'PHONE',
  /** PRIMARY_COUNTRY ... */
  PRIMARY_COUNTRY = 'PRIMARY_COUNTRY',
  /** RP_INCORPORATION_DATE ... */
  RP_INCORPORATION_DATE = 'RP_INCORPORATION_DATE',
  /** RP_INCORPORATION_PLACE ... */
  RP_INCORPORATION_PLACE = 'RP_INCORPORATION_PLACE',
  /** RP_INVESTMENT_ADVISOR_NUMBER ... */
  RP_INVESTMENT_ADVISOR_NUMBER = 'RP_INVESTMENT_ADVISOR_NUMBER',
  /** SECOND_COUNTRY ... */
  SECOND_COUNTRY = 'SECOND_COUNTRY',
  /** SETTLOR */
  SETTLOR = 'SETTLOR',
  /** SHARES_HELD_PERCENT ... */
  SHARES_HELD_PERCENT = 'SHARES_HELD_PERCENT',
  /** Social security number */
  SSN = 'SSN',
  /** State */
  STATE = 'STATE',
  /** STREET_ADDRESS */
  STREET_ADDRESS = 'STREET_ADDRESS',
  /** TITLE ... */
  TITLE = 'TITLE',
  /** US_RESIDENCY_STATUS ... */
  US_RESIDENCY_STATUS = 'US_RESIDENCY_STATUS',
  /** Ownership percentage */
  VERIFY_OWNERSHIP_PERCENTAGE = 'VERIFY_OWNERSHIP_PERCENTAGE',
  /** Role */
  VERIFY_ROLE = 'VERIFY_ROLE',
  /** Voice and video recording */
  VOICE_PHRASE = 'VOICE_PHRASE',
  /** Zip code */
  ZIP_CODE = 'ZIP_CODE'
}

/** Operation initiator */
export type Initiator = APIKey | User;

/** A response choice used for anti-money laundering questions when creating a withdrawal or a trusted destination */
export type InputAMLChoice = {
  /** The choice ID that was selected from the list of possible choices. This is required. */
  choiceID?: InputMaybe<Scalars['String']['input']>;
  /** If a free form answer is possible then this is the text response entered. */
  freeFormAnswer?: InputMaybe<Scalars['String']['input']>;
  /** The AMLQuestion question ID that this answer choice is responding to */
  questionID: Scalars['String']['input'];
};

/** Type used for submitting affiliate information */
export type InputAffiliateInformation = {
  /** The type of information */
  informationType: AffiliateInformationType;
  /** The value of the information */
  value: Scalars['String']['input'];
};

/** Extra parameters for aptos */
export type InputAptosExtraParams = {
  /** buyInsAddresses are the addresses of the buy ins the new vesting contract */
  buyInsAddresses?: InputMaybe<Array<Scalars['String']['input']>>;
  /** buyInsCoins are the tokens that match the buyInsAddresses for the new vesting contract */
  buyInsCoins?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** commission is the operator commission percentage */
  commission?: InputMaybe<Scalars['Int']['input']>;
  /** shareholders are the addresses of the vesting contract shareholders */
  shareholders?: InputMaybe<Array<Scalars['String']['input']>>;
  /** vestingPeriodDuration is the duration of the new vesting contract */
  vestingPeriodDuration?: InputMaybe<Scalars['Int']['input']>;
  /** vestingScheduleDenominators are the schedule fraction denominators for the new vesting contract */
  vestingScheduleDenominators?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** vestingScheduleDenominators are the schedule fraction numerators for the new vesting contract */
  vestingScheduleNumerators?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** vestingStartTimestampSecs is the start date of the new vesting contract */
  vestingStartTimestampSecs?: InputMaybe<Scalars['String']['input']>;
  /** voterAddress is the account's voter address */
  voterAddress?: InputMaybe<Scalars['String']['input']>;
  /** withdrawalAddress is the address to which the vested tokens will be withdrawn to */
  withdrawalAddress?: InputMaybe<Scalars['String']['input']>;
};

/** InputBlockchainAddress describes a combination of blockchain network and text address */
export type InputBlockchainAddress = {
  /** address is the text representation of a blockchain address. */
  address: Scalars['String']['input'];
  /** amlQuestionnaire is for AML answers submitted on the newer questionnaire flow. */
  amlQuestionnaire: InputQuestionnaireSubmission;
  /** networkID is the network or blockchain for which the address. */
  networkID: Scalars['ID']['input'];
};

/** Extra parameters for celo */
export type InputCeloGovernanceVoteExtraParams = {
  /** actionType is used to construct canonical payload */
  actionType?: InputMaybe<ExtraParamsActionType>;
  /** destination tag to use in transaction */
  proposalID: Scalars['Int']['input'];
  /** voteOption is the intent of the vote */
  voteOption: VoteOption;
};

/** Type used for submitting client information */
export type InputClientInformation = {
  /** The type of information */
  informationType: ClientInformationType;
  /** The value of the information */
  value: Scalars['String']['input'];
};

/** Extra parameters for cosmos */
export type InputCosmosExtraParams = {
  /** memo to use in transaction */
  memo?: InputMaybe<Scalars['String']['input']>;
};

/** InputCreateTrustedSource represents the data necessary to create a trusted source */
export type InputCreateTrustedSource = {
  /** Network from the respective source address */
  network: Scalars['String']['input'];
  /** Organization where we want to add the trusted source */
  organizationID: Scalars['String']['input'];
  /** Country of the trusted source originator */
  originatorCountry: Scalars['String']['input'];
  /** Name of the trusted source originator */
  originatorName: Scalars['String']['input'];
  /** Source address to consider as trusted */
  sourceAddress: Scalars['String']['input'];
  /** Country of the trusted source vasp in case the originator does not control the wallet. */
  vaspCountry?: InputMaybe<Scalars['String']['input']>;
  /** Name of the trusted source vasp in case the originator does not control the wallet */
  vaspName?: InputMaybe<Scalars['String']['input']>;
  /** Type of wallet associated with the trusted source */
  walletType: TrustedSourceWalletType;
};

/** InputDepositAttributionOriginator represents a single originator that can be associated with a deposit attribution */
export type InputDepositAttributionOriginator = {
  /** Country of the deposit attribution originator */
  originatorCountry: Scalars['String']['input'];
  /** Name of the deposit attribution originator */
  originatorName: Scalars['String']['input'];
};

/** InputDestination is an input for valid forms of destinations for a role */
export type InputDestination = {
  /** addresses indicates any specific addresses which are valid. An empty list does not indicate all addresses are valid. */
  addresses?: InputMaybe<Array<InputBlockchainAddress>>;
  /** internalDestinationsAllowed indicates which internal destinations that will be allowed. If not supplied, defaults to NONE. */
  internalDestinationsAllowed: InternalDestinationsAllowed;
  /**
   * trustedDestinations indicates existing trusted destinations which will be copied into allowed destination addresses.
   *
   * The trusted destination data is not linked the resulting RolePermission. Any change to the trusted destination will not change the RolePermission.
   * Metadata is retained to display the input trusted destination for operation reviewers.
   */
  trustedDestinationSubIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Extra parameters for diem */
export type InputDiemExtraParams = {
  /** tradeId to use in transaction if it's from or to a Designated Dealer */
  tradeId?: InputMaybe<Scalars['String']['input']>;
};

/** Represents an update to a setting */
export type InputDocDeliverySetting = {
  /** Id of the affiliate (if applicable) */
  affiliateID?: InputMaybe<Scalars['ID']['input']>;
  /** Refers to the value(s) of the setting */
  value?: InputMaybe<Array<DocumentDeliveryContact>>;
};

/** InputEditDepositAttributions is a tuple of operationID/sourceAddress that shall be changed */
export type InputEditDepositAttributions = {
  /** Array of pairs being attributed */
  attributionPairs: Array<DepositAttributionPair>;
  /** Notes added to the attribution submission */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Originator associated with the attribution */
  originator?: InputMaybe<InputDepositAttributionOriginator>;
  /** Type of wallet associated with the attribution */
  walletType?: InputMaybe<DepositAttributionWalletType>;
};

/** InputEditTrustedSource represents the data necessary to edit a trusted source */
export type InputEditTrustedSource = {
  /** Trusted source identifier */
  id: Scalars['String']['input'];
  /** Country of the trusted source originator */
  originatorCountry?: InputMaybe<Scalars['String']['input']>;
  /** Name of the trusted source originator */
  originatorName?: InputMaybe<Scalars['String']['input']>;
  /** Type of wallet associated with the trusted source */
  walletType?: InputMaybe<TrustedSourceWalletType>;
};

/** InputEncryptedControlMasterPrivateKey is is used in submitStagedControlKeys */
export type InputEncryptedControlMasterPrivateKey = {
  /** The private key encrypted to the encryption key of this device */
  encryptedControlMasterPrivateKey: Scalars['String']['input'];
  /** The user's publicKeyID from EnrollingUserControlKey.publicKeyID */
  publicKeyID: Scalars['String']['input'];
};

/** InputEncryptedWalletRecoveryKeyShares is used in submitStagedWalletRecoveryKeyShares mutation */
export type InputEncryptedWalletRecoveryKeyShares = {
  /** The share of the wallet recovery key encrypted to the encryption key of this device */
  encryptedShare: Scalars['String']['input'];
  /** The user's publicKeyID */
  publicKeyID: Scalars['String']['input'];
};

/** InputEndorseOperation represents the input needed to endorse an operation. */
export type InputEndorseOperation = {
  /**
   * This field is required when submitting execute operations for the HSM to process.
   * The child control key is derived from the master control key for the relevant wallet
   * according to the Porto spec:
   * https://www.notion.so/anchorage/Porto-Control-key-key-export-features-d9b433d754234d60a75884007b859477
   */
  childControlKey?: InputMaybe<Scalars['String']['input']>;
  /**
   * This field is required when adding or replacing users in self-operated organizations (where the Operator for the Organization is SELF)
   * It requires a copy of the control key, encrypted by the encryptionPublicKey for the new user.
   * It should be formatter in whatever way the secure enclave libraries like it in so it's easy to decrypt on the new user's device.
   */
  encryptedControlMasterPrivateKey?: InputMaybe<Scalars['String']['input']>;
  /** ID of the operation to endorse. */
  operationID: Scalars['String']['input'];
  /** Signed endorsement with metadata payload. */
  payload: Scalars['String']['input'];
};

/** Extra parameters for ethereum staking */
export type InputEthereumExtraParams = {
  /** stakingProvider is the provider that will be used to stake the ethereum */
  stakingProvider?: InputMaybe<ETHStakingProviderType>;
  /** validatorCount is the amount of validators for this staking operation */
  validatorCount?: InputMaybe<Scalars['String']['input']>;
  /** withdrawalAddress is the address to where the funds will be returned to when withdrawal is possible */
  withdrawalAddress?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Extra parameters per asset type
 * We assume you don't simultaneously transact in mainnet and testnet
 */
export type InputExtraParams = {
  /** params for aptos */
  aptosExtraParams?: InputMaybe<InputAptosExtraParams>;
  /** params for celo governance vote */
  celoGovernanceVoteExtraParams?: InputMaybe<InputCeloGovernanceVoteExtraParams>;
  /** params for cosmos */
  cosmosExtraParams?: InputMaybe<InputCosmosExtraParams>;
  /**
   * params for diem
   * @deprecated Unused, will be removed in future versions
   */
  diemExtraParams?: InputMaybe<InputDiemExtraParams>;
  /** params for ethereum staking */
  ethereumExtraParams?: InputMaybe<InputEthereumExtraParams>;
  /** params for FIAT */
  fiatExtraParams?: InputMaybe<InputFiatExtraParams>;
  /** input params for flow delegation */
  flowDelegateExtraParams?: InputMaybe<InputFlowDelegateExtraParams>;
  /** params for maker */
  makerExtraParams?: InputMaybe<InputMakerExtraParams>;
  /** input params for oasis undelegate */
  oasisUndelegateExtraParams?: InputMaybe<InputOasisUndelegateExtraParams>;
  /** params for ripple */
  rippleExtraParams?: InputMaybe<InputRippleExtraParams>;
  /** params for stellar */
  stellarExtraParams?: InputMaybe<InputStellarExtraParams>;
  /** extra supported params for assets */
  supportedExtraParams?: InputMaybe<InputSupportedExtraParams>;
  /** params for tendermint */
  tendermintExtraParams?: InputMaybe<InputTendermintExtraParams>;
};

/** Extra parameters for Fiat */
export type InputFiatExtraParams = {
  /** Unique Identifier of a Standing Instruction */
  standingInstructionID: Scalars['String']['input'];
};

/** Extra parameters for flow Delegate */
export type InputFlowDelegateExtraParams = {
  /** actionType is used to construct canonical payload */
  actionType?: InputMaybe<ExtraParamsActionType>;
  /** isStake dictates whether the operation is a delegation or staking */
  isStake?: InputMaybe<Scalars['Boolean']['input']>;
  /** nodeID is a unique identifier for a node that can be delegate to */
  nodeID?: InputMaybe<Scalars['String']['input']>;
};

/**
 * InputFragmentBindingChange represents a fragment binding with the scope and the set of
 * fragments that compose the policy
 */
export type InputFragmentBindingChange = {
  /** The ID of the binding that is being updated. */
  bindingID: Scalars['String']['input'];
  /** The human readable display name of the binding. */
  displayName: Scalars['String']['input'];
  /** The fragments that will be updated. */
  fragments: Array<InputStructuredFragment>;
  /** The action that the binding applies to. */
  scope: Scalars['String']['input'];
};

/** InputFragmentPolicyQuorum is the representation of policy quorum update */
export type InputFragmentPolicyQuorum = {
  /** The updated human readable description of the quorum. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The updated human readable display name of the quorum. */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** The updated set of principals that will be granted the role. */
  members: Array<Scalars['String']['input']>;
  /** The strictness requirement of fulfilling the quorum in order to fulfill the containing policy fragment. */
  quorumRequirement: QuorumRequirement;
  /** The updated number of endorsements from the expanded members which must be provided. */
  threshold: Scalars['Int']['input'];
};

/** Type used for submitting PII */
export type InputIndividualInformation = {
  /** The type of information */
  informationType: IndividualInformationType;
  /** The value of the information */
  value: Scalars['String']['input'];
};

/** InputKYCQuestionAnswer is the input type for the actual user answer for a question */
export type InputKYCQuestionAnswer = {
  /** The free form text written in response to the question */
  freeformText?: InputMaybe<Scalars['String']['input']>;
  /** A group of answers, used to answer for a question of type group */
  group: Array<Array<InputKYCQuestionAnswer>>;
  /** The option IDs selected in response to the question */
  options?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The id of the question this answer is related to */
  questionID: Scalars['String']['input'];
};

/** Type used for submitting known information */
export type InputKnownInformation = {
  /** The grouped information value if applicable */
  groupValue?: InputMaybe<Array<InputMaybe<Array<InputKnownInformation>>>>;
  /** The type of information */
  informationType: Scalars['String']['input'];
  /** The value of the information */
  value: Scalars['String']['input'];
};

/** Extra parameters for maker */
export type InputMakerExtraParams = {
  /** toggle for IOU or MKR authorize spender transaction */
  isAuthorizingIOUToken?: InputMaybe<Scalars['Boolean']['input']>;
  /** for polling: the ID of the option being voted on */
  optionID?: InputMaybe<Scalars['Int']['input']>;
  /** for polling: the ID of the poll being voted on */
  pollID?: InputMaybe<Scalars['Int']['input']>;
  /** what type of vote: VOTE for executive, POLLVOTE for polling */
  voteType?: InputMaybe<Scalars['String']['input']>;
};

/** Extra parameters for oasis undelegate */
export type InputOasisUndelegateExtraParams = {
  /** actionType is used to construct canonical payload */
  actionType?: InputMaybe<ExtraParamsActionType>;
  /** estimated value of shares at time of undelegate request */
  estimatedSharesAmount?: InputMaybe<Scalars['String']['input']>;
  /** numShares to undelegate */
  numShares?: InputMaybe<Scalars['String']['input']>;
  /** percentShares to undelegate */
  percentShares?: InputMaybe<Scalars['String']['input']>;
};

/**
 * InputOneOffParam is to generically input parameters for one-off operations
 * such as Ethereum contract parameters
 */
export type InputOneOffParam = {
  /** The label (or key) for a one-off operation parameter */
  label: Scalars['String']['input'];
  /** The value for a one-off operation parameter */
  value: Scalars['String']['input'];
};

/**
 * InputOperationActionPair represents a set composed of an OperationAction and a specificed sub action,
 * e.g. a pair ORGANIZATION_CHANGE action with CREATE_VAULT subAction
 */
export type InputOperationActionPair = {
  /** The OperationAction of the pair. */
  action: OperationAction;
  /** The string representation of the sub action of the pair, if any. */
  subAction?: InputMaybe<Scalars['String']['input']>;
};

/** Type used for submitting PII */
export type InputPII = {
  /** The type of information */
  informationType: PIIInformationType;
  /** The value of the information */
  value: Scalars['String']['input'];
};

/** InputPerformManualDepositAttributions is a tuple of operationID/sourceAddress that shall be attributed */
export type InputPerformManualDepositAttributions = {
  /** Array of pairs being attributed */
  attributionPairs: Array<DepositAttributionPair>;
  /** Notes added to the attribution submission */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Originator associated with the attribution */
  originator: InputDepositAttributionOriginator;
  /** Flag to indicate if the source addresses associated with this attribution should be added to the trusted sources */
  trustedSource: Scalars['Boolean']['input'];
  /** Type of wallet associated with the attribution */
  walletType: DepositAttributionWalletType;
};

/** InputPolicy is constructed of lists of quorums and other data */
export type InputPolicy = {
  /** The action associated with this policy */
  action: PolicyActionType;
  /** initiateOnlyUsers is a list of initators that can create but can't approve the operations with this action */
  initiateOnlyUsers?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Minimum number of endorsments needed from the requiredUsers List.
   * When not present or empty defaults to the number of required users.
   */
  numRequiredUsers?: InputMaybe<Scalars['Int']['input']>;
  /** Quorums are the list of quorums for this policy */
  quorums: Array<InputQuorum>;
  /**
   * List of soft required users. This requirement is not cryptographically enforced,
   * meaning that this will serve as a guideline to the risk reviewer only.
   */
  requiredUsers?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** InputPolicyContainerUpdate represents a set of changes to be applied to a policy container. */
export type InputPolicyContainerUpdate = {
  /** fragmentBindingChanges represents the list of fragment binding changes to be applied. */
  fragmentBindingChanges?: InputMaybe<Array<InputFragmentBindingChange>>;
  /** roleBindingChanges represents the list of role binding changes to be applied. */
  roleBindingChanges?: InputMaybe<Array<InputRoleBindingChange>>;
};

/** Answer provided for a particular question with metadata */
export type InputQuestionnaireAnswer = {
  /** List of options or freeform text for the answer */
  answerData: InputQuestionnaireAnswerData;
  /** Unique identifier for the question */
  questionID: Scalars['String']['input'];
};

/** Actual answer data provided by the user */
export type InputQuestionnaireAnswerData = {
  /** Freeform text given by the user */
  freeformText?: InputMaybe<Scalars['String']['input']>;
  /** Options selected by the user */
  options: Array<Scalars['String']['input']>;
};

/** Questionnaire submission that a user sends */
export type InputQuestionnaireSubmission = {
  /** List of answers to submit on a questionnaire */
  answers: Array<InputQuestionnaireAnswer>;
  /** Unique identifier for the questionnaire */
  questionnaireID: Scalars['String']['input'];
};

/**
 * InputQuorum represents a list of authorized keys combined with the required number of
 * keys in order for the quorum to be met.
 */
export type InputQuorum = {
  /** Number of signatures required to satisfy this quorum */
  signaturesRequired: Scalars['Int']['input'];
  /** List of keys authorized to participate in this quorum */
  userKeyIDs: Array<Scalars['String']['input']>;
};

/** Extra parameters for ripple */
export type InputRippleExtraParams = {
  /** destination tag to use in transaction */
  destinationTag?: InputMaybe<Scalars['String']['input']>;
};

/**
 * InputRoleBindingChange represents a role binding with the role and the set of
 * authorized keys that belong to that role binding
 */
export type InputRoleBindingChange = {
  /** The ID of the binding that is being updated. */
  bindingID: Scalars['String']['input'];
  /**
   * The updated set of principals that will be granted the role.
   * If this list is empty the binding won't be deleted.
   */
  members: Array<Scalars['String']['input']>;
  /** The role that the binding update is granting. */
  roleID: Scalars['String']['input'];
};

/** InputRolePermission is an input into the app mutation for changeRole */
export type InputRolePermission = {
  /** action describes the action that is being enabled for this role */
  action: RoleAction;
  /** anySourceVault indicates the action is valid for any source vault. */
  anySourceVault?: InputMaybe<Scalars['Boolean']['input']>;
  /** dailyUSDTransferLimit is an optional USD limit of funds transferrable in a 24-hour window. */
  dailyUSDTransferLimit?: InputMaybe<Scalars['Decimal']['input']>;
  /** destination indicates valid destinations for this action. */
  destination?: InputMaybe<InputDestination>;
  /**
   * sourceVaultSubIDs is the list of vault sub ids that this action wants to be valid for.
   *
   * For backwards compatibility, an empty list is equivalent to the wildcard, meaning all current and future vaults are allowed. `anySourceVault` is preferred to indicate a wildcard.
   */
  sourceVaultSubIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Extra parameters for stellar */
export type InputStellarExtraParams = {
  /** memo to use in transaction */
  memo?: InputMaybe<Scalars['String']['input']>;
};

/** InputStructuredFragment is the representation of a fragment update */
export type InputStructuredFragment = {
  /** The updated quorums that must be met in order to fulfill the fragment. */
  quorums: Array<InputFragmentPolicyQuorum>;
  /** The type of back office risk review that must be performed. */
  riskReviewRequirement: RiskReviewRequirement;
};

/** InputSubmission is the parsed AML answers */
export type InputSubmission = {
  __typename: 'InputSubmission';
  /** answers is the list of answers for the questionnaire */
  answers: Maybe<Array<Answer>>;
  /** questionnaireID is the ID of the questionnaire */
  questionnaireID: Scalars['String']['output'];
};

/** Optional extra params for assets that require it */
export type InputSupportedExtraParams = {
  /** fieldVames contains the names of extra params for the asset */
  fieldNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** fieldValues contains the values for extra params for the asset */
  fieldValues?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Extra Parameters for tendermint */
export type InputTendermintExtraParams = {
  /** memo is the message to be included in the transaction */
  memo?: InputMaybe<Scalars['String']['input']>;
  /** redelegateSrc is used when redelegating with the value of the source validator */
  redelegateSrc?: InputMaybe<Scalars['String']['input']>;
};

/** Optional extra params for trusted destination depending on the asset being used */
export type InputTrustedDestinationExtraParams = {
  /** memo is the destination tag/memo required by the asset for transactions */
  memo?: InputMaybe<Scalars['String']['input']>;
};

/** InputUserAccessChange represents an access level change of a user on a specific resource. */
export type InputUserAccessChange = {
  /** accessLevel is the new IAM access level for the user on the resource. */
  accessLevel?: InputMaybe<IAMAccessLevel>;
  /** Unique UserV2 identifier. */
  userID: Scalars['ID']['input'];
};

/** InputUserAccessUpdate represents an access level change of a user on a specific resource. */
export type InputUserAccessUpdate = {
  /** changes is the set of user access level changes to be applied. */
  changes: Array<InputUserAccessChange>;
  /** resourceID is the unique id of the resource (vault or organization) being updated for user accesses */
  resourceID: Scalars['String']['input'];
};

/**
 * InputVaultFragmentBindingChange represents a fragment binding with the scope and the set of
 * fragments that compose the vault policy
 */
export type InputVaultFragmentBindingChange = {
  /** inputFragmentBindingChange represents the changes that will be updated in the fragment binding. */
  inputFragmentBindingChange?: InputMaybe<InputFragmentBindingChange>;
  /** Vault sub ID */
  vaultSubID: Scalars['Int']['input'];
};

/** Vault policies by vault sub ID */
export type InputVaultPolicy = {
  /** Policies */
  policies: Array<InputPolicy>;
  /** Vault sub ID */
  vaultSubID: Scalars['String']['input'];
};

/** InputVaultPolicyContainerUpdate represents a set of changes to be applied to a vault policy container. */
export type InputVaultPolicyContainerUpdate = {
  /** fragmentBindingChanges represents the list of fragment binding changes to be applied. */
  fragmentBindingChanges?: InputMaybe<Array<InputVaultFragmentBindingChange>>;
  /** roleBindingChanges represents the list of role binding changes to be applied. */
  roleBindingChanges?: InputMaybe<Array<InputVaultRoleBindingChange>>;
};

/**
 * InputVaultRoleBindingChange represents a role binding with the role and the set of
 * authorized keys that belong to that vault role binding
 */
export type InputVaultRoleBindingChange = {
  /** inputRoleBindingChange represents the changes that will be updated in the role binding. */
  inputRoleBindingChange?: InputMaybe<InputRoleBindingChange>;
  /** Vault sub ID */
  vaultSubID: Scalars['Int']['input'];
};

/** Represents a affiliate */
export type InstitutionalAffiliate = {
  __typename: 'InstitutionalAffiliate';
  /** Accounts associated to the affiliate */
  accounts: Array<Account>;
  /** ActivitiesCSV represents the activities in a csv format */
  activitiesCSV: Scalars['String']['output'];
  /** Unique identifier for the affiliate */
  affiliateID: Scalars['String']['output'];
  /** Affiliate type */
  affiliateType: Maybe<Scalars['String']['output']>;
  /** List of alert totals by type and age */
  alertTotals: Array<ComplianceAlertTotals>;
  /** List of the alerts associated with the affiliate */
  alerts: AlertConnection;
  /** List of the alerts associated with the affiliate in CSV format */
  alertsCSV: Scalars['String']['output'];
  /** Number of alerts created over time */
  alertsOverTime: Array<AlertsAtTime>;
  /** List of case totals by type and age */
  caseTotals: Array<ComplianceCaseTotals>;
  /** List of the cases associated with the affiliate */
  cases: CaseConnection;
  /** List of the cases associated with the affiliate in CSV format */
  casesCSV: Scalars['String']['output'];
  /** Number of cases created over time */
  casesOverTime: Array<CasesAtTime>;
  /** Unique identifier for the institution */
  clientID: Scalars['String']['output'];
  /** All members from other affiliates under the same institution */
  clientMembers: Array<InstitutionalIndividual>;
  /** returns of the clients/orgs that this affiliate is part of */
  clients: Array<InstitutionalClient>;
  /** Metadata associated with Affiliate closure */
  closureMetadata: Maybe<ClosureMetadata>;
  /** CRR outcome is the accepted CRR value for the affiliate */
  crrScoreOutcome: Maybe<CRRScoreOutcome>;
  /** List of the deposit attributions associated with the account */
  depositAttributions: DepositAttributionConnection;
  /** Anchorage entities associated with this affiliate */
  entities: Array<AnchorageEntity>;
  /** FCA flag stored for the affiliate */
  fca: Scalars['Boolean']['output'];
  /** FFI flag stored for the affiliate */
  ffi: Scalars['Boolean']['output'];
  /** The status of the client */
  formStatus: FormStatus;
  /** All known information about this institutional client */
  knownInformation: Array<KnownAffiliateInformation>;
  kycProcesses: KYCProcessConnection;
  /** KYC applicable questionnaire */
  kycQuestionnaire: Maybe<Questionnaire>;
  /** KYC form questionnaire last submission */
  kycQuestionnaireSubmission: Maybe<QuestionnaireSubmission>;
  /**
   * KYC Related Parties is a temporary solution for the KYC Related Parties entity.
   * This will be implemented with a generic KYC Entities solution
   */
  kycRelatedParties: KYCRelatedPartyConnection;
  /** Last KYC refresh date is the date when the affiliate had  Full KYC Review */
  lastKYCRefreshDate: Maybe<Scalars['Date']['output']>;
  /** Material News flag stored for the affiliate */
  materialNews: Scalars['Boolean']['output'];
  /** All members of the institution */
  members: Array<InstitutionalIndividual>;
  /** All member audit changes in a given affiliate */
  membersAudit: Maybe<Array<IndividualAffiliateRelationshipAudit>>;
  /** Name of the institution */
  name: Scalars['String']['output'];
  /** Next KYC refresh date is the date when a KYC refresh is due */
  nextKYCRefreshDate: Maybe<Scalars['Date']['output']>;
  /** Non-Material News flag stored for the affiliate */
  nonMaterialNews: Scalars['Boolean']['output'];
  /** Onboarding Date is the date when the affiliate was fully onboarded at Anchorage */
  onboardingDate: Maybe<Scalars['Date']['output']>;
  /** The parent affiliate if any */
  parentAffiliate: Maybe<InstitutionalAffiliate>;
  /** Unique identifier for the parent affiliate */
  parentAffiliateID: Maybe<Scalars['String']['output']>;
  /** PEP flag stored for the affiliate */
  pep: Scalars['Boolean']['output'];
  /** Portfolio is an affiliate level summary of holdings */
  portfolio: Portfolio;
  /** PortfolioCSV represents the affiliate portfolio in a csv format */
  portfolioCSV: Scalars['String']['output'];
  /** Primary customer type is the value of the PrimaryCustomerType */
  primaryCustomerType: Maybe<Scalars['String']['output']>;
  /** All program customers for the affiliate */
  programCustomers: Array<InstitutionalAffiliate>;
  /** Associated institution representatives */
  representatives: Array<InstitutionalRepresentative>;
  /** Secondary customer type is the value of the SecondaryCustomerType */
  secondaryCustomerType: Maybe<Scalars['String']['output']>;
  /** Settings returns this affiliate specific settings */
  settings: Maybe<Array<Setting>>;
  /** Status of the affiliate */
  status: AffiliateStatus;
  /** Suspicious Activity flag stored for the affiliate */
  suspiciousActivity: Scalars['Boolean']['output'];
  /** withdrawalFrozen indicates whether the affiliate has or not withdrawal frozen */
  withdrawalFrozen: Scalars['Boolean']['output'];
};


/** Represents a affiliate */
export type InstitutionalAffiliateactivitiesCSVArgs = {
  filterAccountIDs: Array<Scalars['String']['input']>;
  filterActions?: InputMaybe<Array<OperationAction>>;
  filterAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterStates?: InputMaybe<Array<OperationState>>;
  fromDate: Scalars['Timestamp']['input'];
  organizationKeyID: Scalars['String']['input'];
  toDate: Scalars['Timestamp']['input'];
};


/** Represents a affiliate */
export type InstitutionalAffiliatealertsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  alertID?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<AlertStatus>;
};


/** Represents a affiliate */
export type InstitutionalAffiliatealertsCSVArgs = {
  alertID?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<AlertStatus>;
};


/** Represents a affiliate */
export type InstitutionalAffiliatealertsOverTimeArgs = {
  aggregatedBy: AggregationFilterType;
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};


/** Represents a affiliate */
export type InstitutionalAffiliatecasesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  caseID?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<CaseStatus>;
};


/** Represents a affiliate */
export type InstitutionalAffiliatecasesCSVArgs = {
  caseID?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<CaseStatus>;
};


/** Represents a affiliate */
export type InstitutionalAffiliatecasesOverTimeArgs = {
  aggregatedBy: AggregationFilterType;
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};


/** Represents a affiliate */
export type InstitutionalAffiliatedepositAttributionsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAttributionStatus?: InputMaybe<Array<DepositAttributionStatus>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupedBy?: InputMaybe<DepositAttributionGroupingType>;
  isAttributed?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  operationID?: InputMaybe<Scalars['String']['input']>;
  sortDESC?: InputMaybe<Scalars['Boolean']['input']>;
  sortField?: InputMaybe<DepositAttributionSortFields>;
  sourceAddress?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a affiliate */
export type InstitutionalAffiliateknownInformationArgs = {
  filterInformationTypes?: InputMaybe<Array<AffiliateInformationType>>;
  latestOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Represents a affiliate */
export type InstitutionalAffiliatekycProcessesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  filter?: InputMaybe<KYCProcessWhereInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<KYCProcessOrder>>;
};


/** Represents a affiliate */
export type InstitutionalAffiliatekycQuestionnaireArgs = {
  questionnaireType?: InputMaybe<Scalars['String']['input']>;
};


/** Represents a affiliate */
export type InstitutionalAffiliatekycRelatedPartiesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KYCRelatedPartyWhereInput>;
};

/** InstitutionalAffiliateConnection implements the connections spec */
export type InstitutionalAffiliateConnection = {
  __typename: 'InstitutionalAffiliateConnection';
  /** edges implements the connections spec */
  edges: Array<Maybe<InstitutionalAffiliateEdge>>;
  /** pageInfo implements the connections spec */
  pageInfo: PageInfo;
};

/** InstitutionalAffiliateEdge implements the connections spec */
export type InstitutionalAffiliateEdge = {
  __typename: 'InstitutionalAffiliateEdge';
  /** cursor implements the connections spec */
  cursor: Scalars['String']['output'];
  /** node implements the connections spec */
  node: InstitutionalAffiliate;
};

/** InstitutionalAffiliateMemberFilters specifies filters for the institutional affiliate member gql queries */
export type InstitutionalAffiliateMemberFilters = {
  /** List of affiliate IDs to filter the results */
  affiliateIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** List of individual IDs to filter the results */
  individualIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** InstitutionalAffiliateRelationshipPair encodes an affiliate with a list of relationships */
export type InstitutionalAffiliateRelationshipPair = {
  __typename: 'InstitutionalAffiliateRelationshipPair';
  /** affiliate implements the connections spec */
  affiliate: InstitutionalAffiliate;
  /** relationship implements the connections spec */
  relationship: Array<IndividualAffiliateRelationship>;
};

/** InstitutionalAffiliateRelationshipPairConnection implements the connections spec */
export type InstitutionalAffiliateRelationshipPairConnection = {
  __typename: 'InstitutionalAffiliateRelationshipPairConnection';
  /** edges implements the connections spec */
  edges: Array<Maybe<InstitutionalAffiliateRelationshipPairEdge>>;
  /** pageInfo implements the connections spec */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection */
  totalCount: Scalars['Int']['output'];
};

/** InstitutionalAffiliateRelationshipPairEdge implements the connections spec */
export type InstitutionalAffiliateRelationshipPairEdge = {
  __typename: 'InstitutionalAffiliateRelationshipPairEdge';
  /** cursor implements the connections spec */
  cursor: Scalars['String']['output'];
  /** node implements the connections spec */
  node: InstitutionalAffiliateRelationshipPair;
};

/** InstitutionalAffiliateSortFields are the options for sorting a list of affiliates */
export enum InstitutionalAffiliateSortFields {
  /** FORM_STATUS is the status associted to the affiliate's form */
  FORM_STATUS = 'FORM_STATUS',
  /** NAME is the name of the affiliate */
  NAME = 'NAME',
  /** ORG_NAME is the name of the organization associated to the affiliate */
  ORG_NAME = 'ORG_NAME',
  /** PENDING_ATTRIBUTIONS is to sort for affiliates' pending attributions number */
  PENDING_ATTRIBUTIONS = 'PENDING_ATTRIBUTIONS'
}

/** Base resolver for all institutional audit csv exports */
export type InstitutionalAuditCSVExports = {
  __typename: 'InstitutionalAuditCSVExports';
  /** Returns the audit information for the affiliate members */
  affiliateMembersCSV: Scalars['String']['output'];
  /** Returns the audit information for the affiliates info */
  affiliatesInfoCSV: Scalars['String']['output'];
  /** Returns the audit information for the individuals info */
  individualsInfoCSV: Scalars['String']['output'];
};


/** Base resolver for all institutional audit csv exports */
export type InstitutionalAuditCSVExportsaffiliateMembersCSVArgs = {
  filters?: InputMaybe<InstitutionalAffiliateMemberFilters>;
};


/** Base resolver for all institutional audit csv exports */
export type InstitutionalAuditCSVExportsaffiliatesInfoCSVArgs = {
  filters?: InputMaybe<InstitutionalInfoFilters>;
};


/** Base resolver for all institutional audit csv exports */
export type InstitutionalAuditCSVExportsindividualsInfoCSVArgs = {
  filters?: InputMaybe<InstitutionalInfoFilters>;
};

/** Represents an institution */
export type InstitutionalClient = {
  __typename: 'InstitutionalClient';
  /** List of affiliates associated with this client */
  affiliates: Array<InstitutionalAffiliate>;
  /** Unique identifier for the institution */
  clientID: Scalars['String']['output'];
  /** Shows information of when the client was deleted, or null if active */
  deletedAt: Maybe<Scalars['String']['output']>;
  /** Anchorage entities associated with this client */
  entities: Array<AnchorageEntity>;
  /** The status of the client form */
  formStatus: FormStatus;
  /** All known information about this institutional client */
  knownInformation: Array<KnownClientInformation>;
  /** KYC applicable questionnaire */
  kycQuestionnaire: Maybe<Questionnaire>;
  /** All members of the institution */
  members: Array<InstitutionalIndividual>;
  /** Name of the institution */
  name: Scalars['String']['output'];
  /** Associated institution representatives */
  representatives: Array<InstitutionalRepresentative>;
  /**
   * The staged organization created based on this client.
   * It will be null if the client has not reached that state yet.
   */
  stagedOrganization: Maybe<StagedOrganization>;
  /** The status of the client */
  status: ClientStatus;
};


/** Represents an institution */
export type InstitutionalClientknownInformationArgs = {
  filterInformationTypes?: InputMaybe<Array<ClientInformationType>>;
  latestOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Represents an institution */
export type InstitutionalClientkycQuestionnaireArgs = {
  questionnaireType?: InputMaybe<Scalars['String']['input']>;
};

/** Filter states for cosmetically archived institutional clients */
export enum InstitutionalCosmeticStatus {
  /** Shows active */
  ACTIVE = 'ACTIVE',
  /** Shows all */
  ALL = 'ALL',
  /** Shows only deleted */
  DELETED = 'DELETED'
}

/** Represents an individual belonging to an institution */
export type InstitutionalIndividual = {
  __typename: 'InstitutionalIndividual';
  /**
   * Relationships of this individual with the provided affiliate.
   * If no affiliate ID is provided, and this call is within an affiliate resolver,
   * that affiliate ID will be used instead.
   */
  affiliateRelationships: Maybe<Array<IndividualAffiliateRelationship>>;
  /** The client identifier, if any. */
  clientID: Maybe<Scalars['String']['output']>;
  /** Creation date of the institutional individual */
  createdAt: Scalars['DateTime']['output'];
  /** The status of the client */
  formStatus: FormStatus;
  /** Unique ID of the individual */
  individualID: Scalars['String']['output'];
  /** Institutional affiliates that this institutional individual is associated with. */
  institutionalAffiliateRelationships: InstitutionalAffiliateRelationshipPairConnection;
  /** A job title is the role of an individual in all of the affiliates of the client one belongs to */
  jobTitle: Maybe<Scalars['String']['output']>;
  /** Specific known information, queried by type */
  knownInformation: Array<KnownInformation>;
  kycProcesses: KYCProcessConnection;
  /** KYC applicable questionnaire */
  kycQuestionnaire: Maybe<Questionnaire>;
  /**
   * The percentage of the affiliate that the individual owns. Only relevant for
   * beneficial owners.
   */
  ownershipPercentage: Maybe<Scalars['String']['output']>;
  /** Person associated with this individual, if any */
  person: Maybe<Person>;
  /** Associated institution representatives */
  representatives: Array<InstitutionalRepresentative>;
  /** Role of an individual in the affiliate (such as CEO, COO, etc). */
  role: Maybe<Scalars['String']['output']>;
};


/** Represents an individual belonging to an institution */
export type InstitutionalIndividualaffiliateRelationshipsArgs = {
  affiliateID?: InputMaybe<Scalars['String']['input']>;
};


/** Represents an individual belonging to an institution */
export type InstitutionalIndividualinstitutionalAffiliateRelationshipsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Represents an individual belonging to an institution */
export type InstitutionalIndividualjobTitleArgs = {
  clientID?: InputMaybe<Scalars['String']['input']>;
};


/** Represents an individual belonging to an institution */
export type InstitutionalIndividualknownInformationArgs = {
  filterInformationTypes?: InputMaybe<Array<IndividualInformationType>>;
  latestOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Represents an individual belonging to an institution */
export type InstitutionalIndividualkycProcessesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  filter?: InputMaybe<KYCProcessWhereInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<KYCProcessOrder>>;
};


/** Represents an individual belonging to an institution */
export type InstitutionalIndividualkycQuestionnaireArgs = {
  questionnaireType?: InputMaybe<Scalars['String']['input']>;
};


/** Represents an individual belonging to an institution */
export type InstitutionalIndividualownershipPercentageArgs = {
  affiliateID?: InputMaybe<Scalars['String']['input']>;
};


/** Represents an individual belonging to an institution */
export type InstitutionalIndividualroleArgs = {
  affiliateID?: InputMaybe<Scalars['String']['input']>;
};

/** Connection for InstitutionalIndividual type */
export type InstitutionalIndividualConnection = {
  __typename: 'InstitutionalIndividualConnection';
  /** List of edges */
  edges: Maybe<Array<Maybe<InstitutionalIndividualEdge>>>;
  /** Information to aid in pagination */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection */
  totalCount: Scalars['Int']['output'];
};

/** Edge for InstitutionalIndividual type */
export type InstitutionalIndividualEdge = {
  __typename: 'InstitutionalIndividualEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: InstitutionalIndividual;
};

/** InstitutionalInfoFilters specifies filters for the institutional info gql queries */
export type InstitutionalInfoFilters = {
  /** List of IDs to filter the results */
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** List of affiliate IDs to filter the results */
  informationTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Base resolver to search for institutional KYC Information. */
export type InstitutionalKYCInformation = {
  __typename: 'InstitutionalKYCInformation';
  /** Returns the latest KYC information across all kyc types */
  latestKYCInformation: KYCInformationConnection;
};


/** Base resolver to search for institutional KYC Information. */
export type InstitutionalKYCInformationlatestKYCInformationArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filters: KYCInformationFilters;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Represents a person that is allowed to fill out institutional forms */
export type InstitutionalRepresentative = {
  __typename: 'InstitutionalRepresentative';
  /** Email of the the representative. */
  email: Scalars['String']['output'];
  /** Name of the representative. */
  name: Scalars['String']['output'];
};

/** Enum of lifecyle stages, for institutional clients/affiliates/individuals */
export enum InstitutionalValueLifecycle {
  /** DRAFT means the value is not yet final, and can be changed */
  DRAFT = 'DRAFT',
  /** HISTORICAL means the value is a version that can no longer be changed in the database, but is not the most-recent version */
  HISTORICAL = 'HISTORICAL',
  /** LATEST means the value is a version that can no longer be changed in the database, and is the most-recent version */
  LATEST = 'LATEST'
}

/** Interest payment entity type for creation */
export enum InterestPaymentCreateIDType {
  /** Creating an interest payment based on a invoice */
  INVOICE = 'INVOICE',
  /** Creating an interest payment based on a loan */
  LOAN = 'LOAN'
}

/** Interest payments encapsulation */
export type InterestPayments = Node & {
  __typename: 'InterestPayments';
  /** Amount paid */
  amount: Amount;
  /** Blockchain transaction ID associated with the lending facility transaction */
  blockchainTransactionID: Maybe<Scalars['String']['output']>;
  /** Comments */
  comments: Maybe<Scalars['String']['output']>;
  /** Destinations represents the destination addresses for a transaction */
  destinations: Array<Scalars['String']['output']>;
  /** Fee for the lending facility transaction */
  fee: Maybe<Amount>;
  /** Identifier */
  id: Scalars['ID']['output'];
  /** Invoice for payment */
  invoice: LendingInvoice;
  /** Notes for the lending facility transaction */
  notes: Maybe<Scalars['String']['output']>;
  /** Operation ID */
  operationID: Maybe<Scalars['ID']['output']>;
  /** Payment date */
  paymentDate: Scalars['Date']['output'];
  /** Remaining amount on associated operation */
  remainingOperationAmount: Maybe<Amount>;
  /** Sources represents the originating addresses for a transaction */
  sources: Array<Scalars['String']['output']>;
  /** Collateral Vault */
  vault: Vault;
};

/** Connection for pagination */
export type InterestPaymentsConnection = {
  __typename: 'InterestPaymentsConnection';
  /** Records */
  edges: Array<InterestPaymentsEdge>;
  /** Pagination info */
  pageInfo: PageInfo;
};

/** Edge for pagination */
export type InterestPaymentsEdge = {
  __typename: 'InterestPaymentsEdge';
  /** Record cursor */
  cursor: Scalars['ID']['output'];
  /** Cursor */
  node: InterestPayments;
};

/**
 * InternalDestinationsAllowed is an enum of choices for allowing internal destinations.
 * It may be expanded in the future to allow more specificity.
 */
export enum InternalDestinationsAllowed {
  /** ALLOW_ALL indicates all internal destinations will be allowed. This includes any vault, any wallet, or other future internal destination. */
  ALLOW_ALL = 'ALLOW_ALL',
  /** NONE indicates no internal destinations will be allowed */
  NONE = 'NONE'
}

/** InternalStatus represents */
export enum InternalStatus {
  /** canceled represents */
  CANCELED = 'CANCELED',
  /** executed represents */
  EXECUTED = 'EXECUTED',
  /** invalid represents */
  INVALID = 'INVALID',
  /** pending represents */
  PENDING = 'PENDING',
  /** rejected represents */
  REJECTED = 'REJECTED',
  /** settled represents */
  SETTLED = 'SETTLED',
  /** settling represents */
  SETTLING = 'SETTLING'
}

/** InventoryMetadata maintains additional fields for the collateral inventory records */
export type InventoryMetadata = {
  __typename: 'InventoryMetadata';
  operationId: Maybe<Scalars['String']['output']>;
  transactionId: Maybe<Scalars['String']['output']>;
};

/** InventoryMetadataInpt input for additional fields for the collateral inventory records */
export type InventoryMetadataInput = {
  operationId?: InputMaybe<Scalars['String']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
};

/** Filters for  the invoice object */
export type InvoiceFilters = {
  /** Filter invoices with due date after the given date */
  filterAfterDueDate?: InputMaybe<Scalars['Date']['input']>;
  /** Filter invoices that end after the given date */
  filterAfterPeriodEnd?: InputMaybe<Scalars['Date']['input']>;
  /** Filter invoices that start after the given date */
  filterAfterPeriodStart?: InputMaybe<Scalars['Date']['input']>;
  /** Filter invoices with the given asset ids */
  filterAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  /** Filter invoices with due date before the given date */
  filterBeforeDueDate?: InputMaybe<Scalars['Date']['input']>;
  /** Filter invoices that end before the given date */
  filterBeforePeriodEnd?: InputMaybe<Scalars['Date']['input']>;
  /** Filter invoices that start before the given date */
  filterBeforePeriodStart?: InputMaybe<Scalars['Date']['input']>;
  /** Filters by lending entity IDs */
  filterEntityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filters by lending entity name. This is a contains filter */
  filterEntityName?: InputMaybe<Scalars['String']['input']>;
  /** Filter invoices with the given facility ids */
  filterFacilityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by invoice IDs */
  filterInvoiceIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter invoices with the given status */
  filterInvoiceStatus?: InputMaybe<Array<LendingInvoiceStatus>>;
  /** Filter by Loan IDs */
  filterLoanIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter invoices with the given operation ids */
  filterOperationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** IssueSource are the possible sources of issues for Referral, PEP, NEGATIVE NEwS and OFAC cases */
export enum IssueSource {
  /** BANKING_PARTNER_RFI is a compliance issue case source */
  BANKING_PARTNER_RFI = 'BANKING_PARTNER_RFI',
  /** SOURCE_314_B is a compliance issue case source */
  BLOCKCHAIN_ANALYTICS = 'BLOCKCHAIN_ANALYTICS',
  /** CORRECTED_AMENDED_SAR is a compliance issue case source */
  CORRECTED_AMENDED_SAR = 'CORRECTED_AMENDED_SAR',
  /** CRR_RISK_FACTOR_CHANGE is a KYC ad hoc issue alert source */
  CRR_RISK_FACTOR_CHANGE = 'CRR_RISK_FACTOR_CHANGE',
  /** DA_BEHAVIORAL is a compliance issue case source */
  DA_BEHAVIORAL = 'DA_BEHAVIORAL',
  /** EMPLOYEE_REFERRAL is a compliance issue case source */
  EMPLOYEE_REFERRAL = 'EMPLOYEE_REFERRAL',
  /** FIAT_BEHAVIORAL is a compliance issue case source */
  FIAT_BEHAVIORAL = 'FIAT_BEHAVIORAL',
  /** MONTHLY_TRANSACTION_REVIEW is a compliance issue case source */
  MONTHLY_TRANSACTION_REVIEW = 'MONTHLY_TRANSACTION_REVIEW',
  /** NNS_ALERT is a compliance issue case source */
  NNS_ALERT = 'NNS_ALERT',
  /** NON_MATERIAL_CLIENT_CHANGE is a KYC ad hoc issue alert source */
  NON_MATERIAL_CLIENT_CHANGE = 'NON_MATERIAL_CLIENT_CHANGE',
  /** OTHER is a KYC ad hoc issue alert source */
  OTHER = 'OTHER',
  /** PEP_ALERT is a compliance issue case source */
  PEP_ALERT = 'PEP_ALERT',
  /** QA_CTMG_IA_FINDING is a KYC ad hoc issue alert source */
  QA_CTMG_IA_FINDING = 'QA_CTMG_IA_FINDING',
  /** SANCTIONS_ALERT is a compliance issue case source */
  SANCTIONS_ALERT = 'SANCTIONS_ALERT',
  /** SOURCE_90_DAY_REVIEW is a compliance issue case source */
  SOURCE_90_DAY_REVIEW = 'SOURCE_90_DAY_REVIEW',
  /** SOURCE_314_A_TRUE_MATCH is a compliance issue case source */
  SOURCE_314_A_TRUE_MATCH = 'SOURCE_314_A_TRUE_MATCH',
  /** SOURCE_314_B is a compliance issue case source */
  SOURCE_314_B = 'SOURCE_314_B'
}

/** JiraComplianceIssue represents the output of the CreateComplianceIssue mutation */
export type JiraComplianceIssue = {
  __typename: 'JiraComplianceIssue';
  /** jiraID is the id of the issue in Jira */
  jiraID: Scalars['String']['output'];
  /** jiraLink is the link to the issue in Jira */
  jiraLink: Scalars['String']['output'];
};

export type KYC = {
  __typename: 'KYC';
  kycProcessTriggers: KYCProcessTriggerConnection;
  kycQuestionnaireSubmissions: KYCQuestionnaireSubmissionConnection;
  kycQuestionnaires: KYCQuestionnaireConnection;
  kycQuestions: KYCQuestionConnection;
  /**
   * KYC Related Parties is a temporary solution for the KYC Related Parties entity.
   * This will be implemented with a generic KYC Entities solution
   */
  kycRelatedParties: KYCRelatedPartyConnection;
};


export type KYCkycProcessTriggersArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<KYCProcessTriggerOrder>>;
  where?: InputMaybe<KYCProcessTriggerWhereInput>;
};


export type KYCkycQuestionnaireSubmissionsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<KYCQuestionnaireSubmissionOrder>>;
  where?: InputMaybe<KYCQuestionnaireSubmissionWhereInput>;
};


export type KYCkycQuestionnairesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<KYCQuestionnaireOrder>>;
  where?: InputMaybe<KYCQuestionnaireWhereInput>;
};


export type KYCkycQuestionsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<KYCQuestionOrder>>;
  where?: InputMaybe<KYCQuestionWhereInput>;
};


export type KYCkycRelatedPartiesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KYCRelatedPartyWhereInput>;
};

/** KYCEntityRelationship describes the relationship between two kyc entities. */
export enum KYCEntityRelationship {
  /** AML_SERVICE_PROVIDER represents an AML Service Provider relation */
  AML_SERVICE_PROVIDER = 'AML_SERVICE_PROVIDER',
  /** AUTHORIZED_SIGNATORY represents an authorized signatory relation */
  AUTHORIZED_SIGNATORY = 'AUTHORIZED_SIGNATORY',
  /** BENEFICIAL_OWNER represents a Beneficial Owner relation */
  BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
  /** BENEFICIARY represents a Beneficiary relation */
  BENEFICIARY = 'BENEFICIARY',
  /** BOARD_DIRECTOR represents a Board Director relation */
  BOARD_DIRECTOR = 'BOARD_DIRECTOR',
  /** CONTROL_PERSON represents a Control Person relation */
  CONTROL_PERSON = 'CONTROL_PERSON',
  /** FUND_ADMINISTRATOR represents a Fund Administrator relation */
  FUND_ADMINISTRATOR = 'FUND_ADMINISTRATOR',
  /** GENERAL_PARTNER represents a General Partner relation */
  GENERAL_PARTNER = 'GENERAL_PARTNER',
  /** GRANTOR_DONOR represents a Grantor/Donor/Settlor relation */
  GRANTOR_DONOR = 'GRANTOR_DONOR',
  /** INVESTMENT_MANAGER represents a Investment Manager */
  INVESTMENT_MANAGER = 'INVESTMENT_MANAGER',
  /** MANAGING_MEMBER represents a Managing Member relation */
  MANAGING_MEMBER = 'MANAGING_MEMBER',
  /** OTHER represents another relation */
  OTHER = 'OTHER',
  /** PARTICIPATING_MEMBER represents an Authorized User relation */
  PARTICIPATING_MEMBER = 'PARTICIPATING_MEMBER',
  /** SENIOR_OFFICER represents a Senior Officer relation */
  SENIOR_OFFICER = 'SENIOR_OFFICER',
  /** TRUSTEE_SUCCESSOR_TRUSTEE represents a Trustee/Success Trustee relation */
  TRUSTEE_SUCCESSOR_TRUSTEE = 'TRUSTEE_SUCCESSOR_TRUSTEE'
}

/** KYCEntityType is enum for the kyc entity type */
export enum KYCEntityType {
  /** AFFILIATE */
  AFFILIATE = 'AFFILIATE',
  /** INDIVIDUAL */
  INDIVIDUAL = 'INDIVIDUAL',
  /** RELATED_PARTY */
  RELATED_PARTY = 'RELATED_PARTY',
  /** UNSPECIFIED */
  UNSPECIFIED = 'UNSPECIFIED'
}

/**
 * KYCEntityWhereInput is used for filtering KYCEntity objects.
 * Input was generated by ent.
 */
export type KYCEntityWhereInput = {
  and?: InputMaybe<Array<KYCEntityWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idContainsFold?: InputMaybe<Scalars['ID']['input']>;
  idEqualFold?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<KYCEntityWhereInput>;
  or?: InputMaybe<Array<KYCEntityWhereInput>>;
};

/** KYCInformation specifies the KYC Information for all institutions */
export type KYCInformation = {
  __typename: 'KYCInformation';
  /** ID of the client/affiliate/individual KYC record */
  id: Scalars['String']['output'];
  /** Information type of the KYC record */
  informationType: Scalars['String']['output'];
  /** KYC type of the KYC information record. */
  kycType: KYCType;
  /**
   * Entity label of the client/affiliate/individual KYC record.
   * For individuals this will be email and name for other KYC types
   */
  label: Scalars['String']['output'];
  /** The notes associated to a given audit trail entry */
  notes: Maybe<Scalars['String']['output']>;
  /** The timestamp when the change was made */
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  /** The user that made the change associated to the audit trail info */
  updatedBy: Maybe<Scalars['String']['output']>;
  /** Information value of the KYC record */
  value: Scalars['String']['output'];
  /** The lifecycle stage of the value */
  valueLifecycle: InstitutionalValueLifecycle;
};

/** KYCInformationConnection implements the connections spec */
export type KYCInformationConnection = {
  __typename: 'KYCInformationConnection';
  /** edges implements the connections spec */
  edges: Array<Maybe<KYCInformationEdge>>;
  /** pageInfo implements the connections spec */
  pageInfo: PageInfo;
};

/** KYCInformationEdge implements the connections spec */
export type KYCInformationEdge = {
  __typename: 'KYCInformationEdge';
  /** cursor implements the connections spec */
  cursor: Scalars['String']['output'];
  /** node implements the connections spec */
  node: KYCInformation;
};

/** KYCInformationFilters specifies filters for the institutional info queries across multiple KYC types */
export type KYCInformationFilters = {
  /** List of IDs to filter the results */
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** List of information types to filter the results */
  informationTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** KYC type used to filter KYC information */
  kycType: KYCType;
  /** search value used to filter KYC information values. */
  searchValue: Scalars['String']['input'];
};

/** KYCNavStatusType is enum for the kyc Nav element status type */
export enum KYCNavStatusType {
  /** COMPLETED means all required questions are answered */
  COMPLETED = 'COMPLETED',
  /** ERROR means at least one question has an error */
  ERROR = 'ERROR',
  /** INCOMPLETE means no questions are answered */
  INCOMPLETE = 'INCOMPLETE'
}

/** KYCOptionsList is a reference to list of options to display in the question */
export enum KYCOptionsList {
  /** COUNTRIES is a list of countries */
  COUNTRIES = 'COUNTRIES',
  /** UNSPECIFIED is a default value for the options list */
  UNSPECIFIED = 'UNSPECIFIED'
}

/** KYCProcess is the ent schema for the KYCProcess entity. */
export type KYCProcess = Node & {
  __typename: 'KYCProcess';
  alloyToken: Maybe<Scalars['String']['output']>;
  case: Maybe<Case>;
  caseID: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['String']['output'];
  description: Scalars['String']['output'];
  endedAt: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  kycAnalyst: Maybe<Scalars['String']['output']>;
  kycEntityID: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  qcAnalyst: Maybe<Scalars['String']['output']>;
  questionnaireSubmissions: Maybe<Array<KYCQuestionnaireSubmission>>;
  state: KYCProcessState;
  updatedAt: Maybe<Scalars['Time']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type KYCProcessConnection = {
  __typename: 'KYCProcessConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<KYCProcessEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type KYCProcessEdge = {
  __typename: 'KYCProcessEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<KYCProcess>;
};

/**
 * KYCProcessEntityWhereInput is used for filtering KYCProcessEntity objects.
 * Input was generated by ent.
 */
export type KYCProcessEntityWhereInput = {
  and?: InputMaybe<Array<KYCProcessEntityWhereInput>>;
  /** kyc_entity edge predicates */
  hasKycEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasKycEntityWith?: InputMaybe<Array<KYCEntityWhereInput>>;
  /** kyc_process edge predicates */
  hasKycProcess?: InputMaybe<Scalars['Boolean']['input']>;
  hasKycProcessWith?: InputMaybe<Array<KYCProcessWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idContainsFold?: InputMaybe<Scalars['ID']['input']>;
  idEqualFold?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kyc_entity_id field predicates */
  kycEntityID?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDContains?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDGT?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDGTE?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  kycEntityIDLT?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDLTE?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kyc_process_id field predicates */
  kycProcessID?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDContains?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDGT?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDGTE?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  kycProcessIDLT?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDLTE?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<KYCProcessEntityWhereInput>;
  or?: InputMaybe<Array<KYCProcessEntityWhereInput>>;
};

/** Ordering options for KYC Process connections */
export type KYCProcessOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Users. */
  field: KYCProcessOrderField;
};

/** Properties by which KYC Process connections can be ordered. */
export enum KYCProcessOrderField {
  ID = 'ID',
  JIRA_CASE_ID = 'JIRA_CASE_ID',
  JIRA_CASE_TYPE = 'JIRA_CASE_TYPE',
  NAME = 'NAME',
  STATE = 'STATE'
}

/** KYCProcessState is enum for the field state */
export enum KYCProcessState {
  CANCELED = 'CANCELED',
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  REVIEW = 'REVIEW',
  UNSPECIFIED = 'UNSPECIFIED'
}

/** KYCProcessTrigger is used for configuring the KYC Process Triggers. */
export type KYCProcessTrigger = Node & {
  __typename: 'KYCProcessTrigger';
  /** The actions to be taken when the trigger is fired. */
  actions: Maybe<Array<KYCProcessTriggerAction>>;
  /** Type of the affiliate */
  affiliateType: Maybe<Scalars['String']['output']>;
  /** Anchorage entity type */
  anchorageEntityType: Maybe<Scalars['String']['output']>;
  /** The conditions to be validated when the trigger is fired. */
  conditions: Maybe<Array<KYCProcessTriggerCondition>>;
  /** The current JIRA state. */
  currentJiraState: Scalars['String']['output'];
  /** The unique identifier for the KYCProcessTrigger. */
  id: Scalars['ID']['output'];
  /** The JIRA case type. */
  jiraCaseType: Scalars['String']['output'];
  /** The previous JIRA state. */
  previousJiraState: Maybe<Scalars['String']['output']>;
  /** Priority of the trigger */
  priority: Scalars['Int']['output'];
};

/** A KYCProcess trigger action configuration */
export type KYCProcessTriggerAction = {
  __typename: 'KYCProcessTriggerAction';
  /** The type of the KYCProcessTriggerAction. */
  actionType: KYCProcessTriggerActionType;
  /** The configuration of the KYCProcessTriggerAction. */
  config: Scalars['String']['output'];
};

/** KYCProcessTriggerActionInput is used to create/update KYCProcessTrigger actions. */
export type KYCProcessTriggerActionInput = {
  /** The type of the KYCProcessTriggerAction. */
  actionType: KYCProcessTriggerActionType;
  /** The configuration of the KYCProcessTriggerAction. */
  config: Scalars['String']['input'];
};

/** KYCProcessTriggerActionType is enum for the field type */
export enum KYCProcessTriggerActionType {
  /** KYC Process action type. */
  KYC_PROCESS = 'KYC_PROCESS',
  /** Notification action type. */
  NOTIFICATION = 'NOTIFICATION',
  /** Questionnaire submission action type. */
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  /** Screening action type. */
  SCREENING = 'SCREENING'
}

/** A connection to a list of items. */
export type KYCProcessTriggerCondition = {
  __typename: 'KYCProcessTriggerCondition';
  /** The type of the KYCProcessTriggerCondition. */
  conditionType: KYCProcessTriggerConditionType;
  /** The configuration of the KYCProcessTriggerCondition. */
  config: Scalars['String']['output'];
};

/** A KYCProcessTriggerConditionInput is used to create/update KYCProcessTrigger conditions. */
export type KYCProcessTriggerConditionInput = {
  /** The type of the KYCProcessTriggerCondition. */
  conditionType: KYCProcessTriggerConditionType;
  /** The configuration of the KYCProcessTriggerCondition. */
  config: Scalars['String']['input'];
};

/** KYCProcessTriggerConditionType is enum for the field KYCProcessCondition type */
export enum KYCProcessTriggerConditionType {
  /** KYC Process action type. */
  KYC_PROCESS = 'KYC_PROCESS',
  /** Questionnaire submission action type. */
  QUESTIONNAIRE = 'QUESTIONNAIRE'
}

/** A connection to a list of items. */
export type KYCProcessTriggerConnection = {
  __typename: 'KYCProcessTriggerConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<KYCProcessTriggerEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type KYCProcessTriggerEdge = {
  __typename: 'KYCProcessTriggerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<KYCProcessTrigger>;
};

/** Ordering options for KYCProcessTrigger connections */
export type KYCProcessTriggerOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order KYCProcessTriggers. */
  field: KYCProcessTriggerOrderField;
};

/** Properties by which KYCProcessTrigger connections can be ordered. */
export enum KYCProcessTriggerOrderField {
  /** Order by Affiliate type field */
  AFFILIATE_TYPE = 'AFFILIATE_TYPE',
  /** Order by Anchorage entity type field */
  ANCHORAGE_ENTITY_TYPE = 'ANCHORAGE_ENTITY_TYPE',
  /** Order by Current JIRA State field */
  CURRENT_JIRA_STATE = 'CURRENT_JIRA_STATE',
  /** Order by ID field */
  ID = 'ID',
  /** Order by JIRA Case Type field */
  JIRA_CASE_TYPE = 'JIRA_CASE_TYPE',
  /** Order by Previous JIRA State field */
  PREVIOUS_JIRA_STATE = 'PREVIOUS_JIRA_STATE',
  /** Order by Priority */
  PRIORITY = 'PRIORITY'
}

/**
 * KYCProcessTriggerWhereInput is used for filtering KYCProcessTrigger objects.
 * Input was generated by ent.
 */
export type KYCProcessTriggerWhereInput = {
  /** affiliate_type field predicates */
  affiliateType?: InputMaybe<Scalars['String']['input']>;
  affiliateTypeContains?: InputMaybe<Scalars['String']['input']>;
  affiliateTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  affiliateTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  affiliateTypeGT?: InputMaybe<Scalars['String']['input']>;
  affiliateTypeGTE?: InputMaybe<Scalars['String']['input']>;
  affiliateTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  affiliateTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  affiliateTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  affiliateTypeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  affiliateTypeLT?: InputMaybe<Scalars['String']['input']>;
  affiliateTypeLTE?: InputMaybe<Scalars['String']['input']>;
  affiliateTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  affiliateTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  affiliateTypeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** anchorage_entity_type field predicates */
  anchorageEntityType?: InputMaybe<Scalars['String']['input']>;
  anchorageEntityTypeContains?: InputMaybe<Scalars['String']['input']>;
  anchorageEntityTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  anchorageEntityTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  anchorageEntityTypeGT?: InputMaybe<Scalars['String']['input']>;
  anchorageEntityTypeGTE?: InputMaybe<Scalars['String']['input']>;
  anchorageEntityTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  anchorageEntityTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  anchorageEntityTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  anchorageEntityTypeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  anchorageEntityTypeLT?: InputMaybe<Scalars['String']['input']>;
  anchorageEntityTypeLTE?: InputMaybe<Scalars['String']['input']>;
  anchorageEntityTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  anchorageEntityTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  anchorageEntityTypeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<KYCProcessTriggerWhereInput>>;
  /** current_jira_state field predicates */
  currentJiraState?: InputMaybe<Scalars['String']['input']>;
  currentJiraStateContains?: InputMaybe<Scalars['String']['input']>;
  currentJiraStateContainsFold?: InputMaybe<Scalars['String']['input']>;
  currentJiraStateEqualFold?: InputMaybe<Scalars['String']['input']>;
  currentJiraStateGT?: InputMaybe<Scalars['String']['input']>;
  currentJiraStateGTE?: InputMaybe<Scalars['String']['input']>;
  currentJiraStateHasPrefix?: InputMaybe<Scalars['String']['input']>;
  currentJiraStateHasSuffix?: InputMaybe<Scalars['String']['input']>;
  currentJiraStateIn?: InputMaybe<Array<Scalars['String']['input']>>;
  currentJiraStateLT?: InputMaybe<Scalars['String']['input']>;
  currentJiraStateLTE?: InputMaybe<Scalars['String']['input']>;
  currentJiraStateNEQ?: InputMaybe<Scalars['String']['input']>;
  currentJiraStateNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idContainsFold?: InputMaybe<Scalars['ID']['input']>;
  idEqualFold?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** jira_case_type field predicates */
  jiraCaseType?: InputMaybe<Scalars['String']['input']>;
  jiraCaseTypeContains?: InputMaybe<Scalars['String']['input']>;
  jiraCaseTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  jiraCaseTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  jiraCaseTypeGT?: InputMaybe<Scalars['String']['input']>;
  jiraCaseTypeGTE?: InputMaybe<Scalars['String']['input']>;
  jiraCaseTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  jiraCaseTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  jiraCaseTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  jiraCaseTypeLT?: InputMaybe<Scalars['String']['input']>;
  jiraCaseTypeLTE?: InputMaybe<Scalars['String']['input']>;
  jiraCaseTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  jiraCaseTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<KYCProcessTriggerWhereInput>;
  or?: InputMaybe<Array<KYCProcessTriggerWhereInput>>;
  /** previous_jira_state field predicates */
  previousJiraState?: InputMaybe<Scalars['String']['input']>;
  previousJiraStateContains?: InputMaybe<Scalars['String']['input']>;
  previousJiraStateContainsFold?: InputMaybe<Scalars['String']['input']>;
  previousJiraStateEqualFold?: InputMaybe<Scalars['String']['input']>;
  previousJiraStateGT?: InputMaybe<Scalars['String']['input']>;
  previousJiraStateGTE?: InputMaybe<Scalars['String']['input']>;
  previousJiraStateHasPrefix?: InputMaybe<Scalars['String']['input']>;
  previousJiraStateHasSuffix?: InputMaybe<Scalars['String']['input']>;
  previousJiraStateIn?: InputMaybe<Array<Scalars['String']['input']>>;
  previousJiraStateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  previousJiraStateLT?: InputMaybe<Scalars['String']['input']>;
  previousJiraStateLTE?: InputMaybe<Scalars['String']['input']>;
  previousJiraStateNEQ?: InputMaybe<Scalars['String']['input']>;
  previousJiraStateNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  previousJiraStateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** priority field predicates */
  priority?: InputMaybe<Scalars['Int']['input']>;
  priorityGT?: InputMaybe<Scalars['Int']['input']>;
  priorityGTE?: InputMaybe<Scalars['Int']['input']>;
  priorityIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  priorityLT?: InputMaybe<Scalars['Int']['input']>;
  priorityLTE?: InputMaybe<Scalars['Int']['input']>;
  priorityNEQ?: InputMaybe<Scalars['Int']['input']>;
  priorityNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/**
 * KYCProcessWhereInput is used for filtering KYCProcess objects.
 * Input was generated by ent.
 */
export type KYCProcessWhereInput = {
  /** alloy_token field predicates */
  alloyToken?: InputMaybe<Scalars['String']['input']>;
  alloyTokenContains?: InputMaybe<Scalars['String']['input']>;
  alloyTokenContainsFold?: InputMaybe<Scalars['String']['input']>;
  alloyTokenEqualFold?: InputMaybe<Scalars['String']['input']>;
  alloyTokenGT?: InputMaybe<Scalars['String']['input']>;
  alloyTokenGTE?: InputMaybe<Scalars['String']['input']>;
  alloyTokenHasPrefix?: InputMaybe<Scalars['String']['input']>;
  alloyTokenHasSuffix?: InputMaybe<Scalars['String']['input']>;
  alloyTokenIn?: InputMaybe<Array<Scalars['String']['input']>>;
  alloyTokenIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  alloyTokenLT?: InputMaybe<Scalars['String']['input']>;
  alloyTokenLTE?: InputMaybe<Scalars['String']['input']>;
  alloyTokenNEQ?: InputMaybe<Scalars['String']['input']>;
  alloyTokenNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  alloyTokenNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<KYCProcessWhereInput>>;
  /** case_id field predicates */
  caseID?: InputMaybe<Scalars['String']['input']>;
  caseIDContains?: InputMaybe<Scalars['String']['input']>;
  caseIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  caseIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  caseIDGT?: InputMaybe<Scalars['String']['input']>;
  caseIDGTE?: InputMaybe<Scalars['String']['input']>;
  caseIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  caseIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  caseIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  caseIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  caseIDLT?: InputMaybe<Scalars['String']['input']>;
  caseIDLTE?: InputMaybe<Scalars['String']['input']>;
  caseIDNEQ?: InputMaybe<Scalars['String']['input']>;
  caseIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  caseIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** ended_at field predicates */
  endedAt?: InputMaybe<Scalars['Time']['input']>;
  endedAtGT?: InputMaybe<Scalars['Time']['input']>;
  endedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  endedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  endedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  endedAtLT?: InputMaybe<Scalars['Time']['input']>;
  endedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  endedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  endedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  endedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** kyc_entity edge predicates */
  hasKycEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasKycEntityWith?: InputMaybe<Array<KYCEntityWhereInput>>;
  /** kyc_process_entities edge predicates */
  hasKycProcessEntities?: InputMaybe<Scalars['Boolean']['input']>;
  hasKycProcessEntitiesWith?: InputMaybe<Array<KYCProcessEntityWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idContainsFold?: InputMaybe<Scalars['ID']['input']>;
  idEqualFold?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kyc_analyst field predicates */
  kycAnalyst?: InputMaybe<Scalars['String']['input']>;
  kycAnalystContains?: InputMaybe<Scalars['String']['input']>;
  kycAnalystContainsFold?: InputMaybe<Scalars['String']['input']>;
  kycAnalystEqualFold?: InputMaybe<Scalars['String']['input']>;
  kycAnalystGT?: InputMaybe<Scalars['String']['input']>;
  kycAnalystGTE?: InputMaybe<Scalars['String']['input']>;
  kycAnalystHasPrefix?: InputMaybe<Scalars['String']['input']>;
  kycAnalystHasSuffix?: InputMaybe<Scalars['String']['input']>;
  kycAnalystIn?: InputMaybe<Array<Scalars['String']['input']>>;
  kycAnalystIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  kycAnalystLT?: InputMaybe<Scalars['String']['input']>;
  kycAnalystLTE?: InputMaybe<Scalars['String']['input']>;
  kycAnalystNEQ?: InputMaybe<Scalars['String']['input']>;
  kycAnalystNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  kycAnalystNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** kyc_entity_id field predicates */
  kycEntityID?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDContains?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDGT?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDGTE?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  kycEntityIDLT?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDLTE?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  kycEntityIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<KYCProcessWhereInput>;
  or?: InputMaybe<Array<KYCProcessWhereInput>>;
  /** qc_analyst field predicates */
  qcAnalyst?: InputMaybe<Scalars['String']['input']>;
  qcAnalystContains?: InputMaybe<Scalars['String']['input']>;
  qcAnalystContainsFold?: InputMaybe<Scalars['String']['input']>;
  qcAnalystEqualFold?: InputMaybe<Scalars['String']['input']>;
  qcAnalystGT?: InputMaybe<Scalars['String']['input']>;
  qcAnalystGTE?: InputMaybe<Scalars['String']['input']>;
  qcAnalystHasPrefix?: InputMaybe<Scalars['String']['input']>;
  qcAnalystHasSuffix?: InputMaybe<Scalars['String']['input']>;
  qcAnalystIn?: InputMaybe<Array<Scalars['String']['input']>>;
  qcAnalystIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  qcAnalystLT?: InputMaybe<Scalars['String']['input']>;
  qcAnalystLTE?: InputMaybe<Scalars['String']['input']>;
  qcAnalystNEQ?: InputMaybe<Scalars['String']['input']>;
  qcAnalystNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  qcAnalystNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** state field predicates */
  state?: InputMaybe<KYCProcessState>;
  stateIn?: InputMaybe<Array<KYCProcessState>>;
  stateNEQ?: InputMaybe<KYCProcessState>;
  stateNotIn?: InputMaybe<Array<KYCProcessState>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type KYCQuestion = Node & {
  __typename: 'KYCQuestion';
  categories: Array<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  informationType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  questionDefinition: KYCQuestionDefinition;
  updatedAt: Scalars['Time']['output'];
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** KYCQuestionAnswer is the actual user answer for a question */
export type KYCQuestionAnswer = {
  __typename: 'KYCQuestionAnswer';
  /** The free form text written in response to the question */
  freeformText: Maybe<Scalars['String']['output']>;
  /** A group of answers, used to answer for a question of type group */
  group: Array<Array<KYCQuestionAnswer>>;
  /** The option IDs selected in response to the question */
  options: Maybe<Array<Scalars['String']['output']>>;
  /** The id of the question this answer is related to */
  questionID: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type KYCQuestionConnection = {
  __typename: 'KYCQuestionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<KYCQuestionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** KYCQuestionDefinition contains the details for a particular question */
export type KYCQuestionDefinition = {
  __typename: 'KYCQuestionDefinition';
  /**
   * alternativeTexts is a list of alternative question texts in the question defenition.
   * This can be used to override text value for a specific question inside a questionnaire.
   */
  alternativeTexts: Maybe<Array<Scalars['String']['output']>>;
  /** The text to display below the options or field */
  footer: Scalars['String']['output'];
  /** Used to allow custom text answer by using this "id" (only for dropdown) */
  freeformOptionID: Scalars['String']['output'];
  /** The group of questions that can be answered more than once (only for GROUP) */
  group: Array<KYCQuestionDefinition>;
  /** groupMetadata is the metadata that affect the child entities created by the group questions */
  groupMetadata: Maybe<KYCQuestionGroupMetadata>;
  /** The text to display below "text" but above the options or field */
  header: Scalars['String']['output'];
  /** hidden is used for one question value should not be displayed. Could be used prefilled with an answer in a previous form and serve only for triggering questions within a questionnaire. */
  hidden: Maybe<Scalars['Boolean']['output']>;
  /** The unique identifier for the question */
  id: Scalars['String']['output'];
  /** The optional user-friendly shorthand name for the question */
  name: Scalars['String']['output'];
  /** Identifies whether this question is optional */
  optional: Scalars['Boolean']['output'];
  /** List of possible options (only for CHIP, RADIO, CHECKBOX, DROPDOWN, DROPDOWN_MULTI_SELECT, RADIO_GROUP) */
  options: Array<KYCQuestionOption>;
  /**
   * OptionsList is a reference to a list of options to display in the question (only for CHIP, RADIO, CHECKBOX, DROPDOWN, DROPDOWN_MULTI_SELECT, RADIO_GROUP)
   * Can be used as an alternative of the actual array of options
   */
  optionsList: KYCOptionsList;
  /** The text to display inside the field (only for textfield or dropdown) */
  placeholderText: Scalars['String']['output'];
  /** preFill should be used to specify if a given question should have its value prefilled or not */
  preFill: Scalars['Boolean']['output'];
  /** Used for one question value should not be editable. Could be used prefilled with an answer in a previous form and serve only information purposes. */
  readOnly: Scalars['Boolean']['output'];
  /** The rules for validating the user's answer */
  rules: Array<KYCQuestionRule>;
  /** SampleText is the text to display as a sample answer */
  sampleText: Scalars['String']['output'];
  /** The text to display below SectionTitle but above the field, in case the question is a section */
  sectionSubTitle: Scalars['String']['output'];
  /** The text to display above the field, in case the question is a section */
  sectionTitle: Scalars['String']['output'];
  /** Used to show the next question before answering this one this can cascade, if the next question has ShowNext, also show it (only for OneByOne) */
  showNext: Scalars['Boolean']['output'];
  /** The optional internal identifier tag */
  tag: Scalars['String']['output'];
  /** The text to display above the options or field */
  text: Scalars['String']['output'];
  /** A tooltip to explain the question */
  tooltip: Scalars['String']['output'];
  /** Used to only ask this question if the user selected a specific option e.g. {{"o1", "o2"}, {"o3"}} means [[o1 AND o2] OR [o3]] */
  triggerOptionID: Array<Array<Scalars['String']['output']>>;
  /** The type of question */
  type: KYCQuestionType;
};

/** An edge in a connection. */
export type KYCQuestionEdge = {
  __typename: 'KYCQuestionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<KYCQuestion>;
};

/** KYCQuestionGroupMetadata is the metadata that affect the child entities created by the group questions */
export type KYCQuestionGroupMetadata = {
  __typename: 'KYCQuestionGroupMetadata';
  /** question tag of the child question that holds the relation types */
  childRelationTag: Maybe<Scalars['String']['output']>;
  /** default relation type for the relation group question */
  defaultRelationType: Maybe<KYCEntityRelationship>;
  /** entity type for the relation group question */
  entityType: KYCEntityType;
};

/** KYCQuestionOption is a selectable element for chip, radio, dropdown, checkbox, dropdown_multi_select, and RADIO_GROUP questions */
export type KYCQuestionOption = {
  __typename: 'KYCQuestionOption';
  /** When an exclusive option is selected, it should be the only selected option */
  exclusive: Scalars['Boolean']['output'];
  /** The unique identifier for the option */
  id: Scalars['String']['output'];
  /** In a RADIO_GROUP question, this represents the questions that will be active when this option is selected */
  questionGroup: Maybe<Array<KYCQuestionDefinition>>;
  /** The subtext for the option */
  subtext: Scalars['String']['output'];
  /** Display a subtitle based on a previous question (only for radio questions) */
  subtitleQuestionID: Scalars['String']['output'];
  /** The optional internal identifier tag */
  tag: Scalars['String']['output'];
  /** The text to display for this option */
  text: Scalars['String']['output'];
  /** The tooltip for the option */
  tooltip: Scalars['String']['output'];
};

/** Ordering options for KYCQuestion connections */
export type KYCQuestionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order KYCQuestions. */
  field: KYCQuestionOrderField;
};

/** Properties by which KYCQuestion connections can be ordered. */
export enum KYCQuestionOrderField {
  /** Order by Created At field */
  CREATED_AT = 'CREATED_AT',
  /** Order by ID field */
  ID = 'ID',
  /** Order by Information Type field */
  INFORMATION_TYPE = 'INFORMATION_TYPE'
}

/** KYCQuestionRule describes a rule that validates a question's answers */
export type KYCQuestionRule = {
  __typename: 'KYCQuestionRule';
  /** A custom error message to be displayed if the validation fails */
  errorMessage: Scalars['String']['output'];
  /** The ID of a question whose answer will be the value for the rule */
  questionId: Maybe<Scalars['String']['output']>;
  /** The type of validation rule */
  type: KYCQuestionRuleType;
  /** The value of the rule (e.g. a regular expression or a number indicating the max value) */
  value: Scalars['String']['output'];
};

/** KYCQuestionRuleType describes the validation preformed by the rule */
export enum KYCQuestionRuleType {
  /** ADD_DAYS is a rule that checks if the date answer is within a given number of days from the current date */
  ADD_DAYS = 'ADD_DAYS',
  /** CHECK_SUM is a rule that checks if the sum of the answers for a given question inside a group equals a given value */
  CHECK_SUM = 'CHECK_SUM',
  /** DISTINCT is a rule that checks if a given question inside a group has unique answers */
  DISTINCT = 'DISTINCT',
  /** MAX_DATE is the maximum accepted date in the answer for date type questions */
  MAX_DATE = 'MAX_DATE',
  /** MAX_LENGTH is a maximum amount of characters or items in the answer */
  MAX_LENGTH = 'MAX_LENGTH',
  /** MAX_SUM is a rule that checks if the sum of the answers for a given question inside a group is below a given value */
  MAX_SUM = 'MAX_SUM',
  /** MIN_DATE is the minimum accepted date in the answer for date type questions */
  MIN_DATE = 'MIN_DATE',
  /** MIN_LENGTH is a minimum amount of characters or items in the answer */
  MIN_LENGTH = 'MIN_LENGTH',
  /** MIN_SUM is a rule that checks if the sum of the answers for a given question inside a group is above a given value */
  MIN_SUM = 'MIN_SUM',
  /** REGEX is a rule that contains a regex expression that is validated against the the answer text */
  REGEX = 'REGEX'
}

/** KYCQuestionType is the type of the questionnaire question */
export enum KYCQuestionType {
  /** CARD_SELECT allows the selection of one option in a list displayed as cards */
  CARD_SELECT = 'CARD_SELECT',
  /** CHECKBOX allows for the selection of multiple options in a list, adequate for short lists, may be optional */
  CHECKBOX = 'CHECKBOX',
  /** CHIP allows the selection of one option in a list, adequate for selecting words in short lists */
  CHIP = 'CHIP',
  /** CONFIRMATION is a question type to confirm a section of information */
  CONFIRMATION = 'CONFIRMATION',
  /** DATE is a question picker input type, should be answered with freeform text in the form of MM/DD/YYYY, may be optional */
  DATE = 'DATE',
  /** DROPDOWN allows the selection of one option in a list, adequate for long lists */
  DROPDOWN = 'DROPDOWN',
  /** DROPDOWN_MULTI_SELECT allows for the selection of multiple options in a list, adequate for long lists, may be optional */
  DROPDOWN_MULTI_SELECT = 'DROPDOWN_MULTI_SELECT',
  /** GROUP enables a group of questions to be answered multiple times, may be optional, use MAX_LENGTH/MIN_LENGTH to specify the required number of times to answer the questions, answers must be given ion the answer group */
  GROUP = 'GROUP',
  /** RADIO allows the selection of one option in a list, adequate for short lists */
  RADIO = 'RADIO',
  /** RADIO_GROUP enables a group of questions when the corresponding option is selected, the sub questions are answered as regular questions, may be optional */
  RADIO_GROUP = 'RADIO_GROUP',
  /** SECTION indicates the question is to be handled as a section describing the next questions, cannot receive answers */
  SECTION = 'SECTION',
  /** SUMMARY is also free text input, may be optional */
  SUMMARY = 'SUMMARY',
  /** TEXTFIELD is a free text input, may be optional */
  TEXTFIELD = 'TEXTFIELD',
  /** TEXT_AREA is also free text input, may be optional */
  TEXT_AREA = 'TEXT_AREA',
  /** UPLOAD allows file uploads, may be optional */
  UPLOAD = 'UPLOAD',
  /** VOID is a no-op input type, cannot receive answers */
  VOID = 'VOID'
}

/**
 * KYCQuestionWhereInput is used for filtering KYCQuestion objects.
 * Input was generated by ent.
 */
export type KYCQuestionWhereInput = {
  and?: InputMaybe<Array<KYCQuestionWhereInput>>;
  categoriesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idContainsFold?: InputMaybe<Scalars['ID']['input']>;
  idEqualFold?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** information_type field predicates */
  informationType?: InputMaybe<Scalars['String']['input']>;
  informationTypeContains?: InputMaybe<Scalars['String']['input']>;
  informationTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  informationTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  informationTypeGT?: InputMaybe<Scalars['String']['input']>;
  informationTypeGTE?: InputMaybe<Scalars['String']['input']>;
  informationTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  informationTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  informationTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  informationTypeLT?: InputMaybe<Scalars['String']['input']>;
  informationTypeLTE?: InputMaybe<Scalars['String']['input']>;
  informationTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  informationTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<KYCQuestionWhereInput>;
  or?: InputMaybe<Array<KYCQuestionWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

/** KYCQuestionnaire is for the KYCQuestionnaire entity. */
export type KYCQuestionnaire = Node & {
  __typename: 'KYCQuestionnaire';
  body: KYCQuestionnaireElement;
  categories: Array<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['String']['output'];
  deletedAt: Maybe<Scalars['Time']['output']>;
  expirationDays: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  questionnaireType: KYCQuestionnaireType;
  updatedAt: Maybe<Scalars['Time']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type KYCQuestionnaireConnection = {
  __typename: 'KYCQuestionnaireConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<KYCQuestionnaireEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type KYCQuestionnaireEdge = {
  __typename: 'KYCQuestionnaireEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<KYCQuestionnaire>;
};

/** KYCQuestionnaireElement is for the KYCQuestionnaireElement entity. */
export type KYCQuestionnaireElement = {
  __typename: 'KYCQuestionnaireElement';
  elements: Array<KYCQuestionnaireElement>;
  id: Scalars['ID']['output'];
  layout: Maybe<KYCQuestionnaireElementLayout>;
  preFill: Scalars['Boolean']['output'];
  question: Maybe<KYCQuestionDefinition>;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  triggeredBy: Array<Array<Scalars['String']['output']>>;
  type: KYCQuestionnaireElementType;
};

/** KYCQuestionnaireElementLayout is for the KYCQuestionnaireElementLayout entity. */
export type KYCQuestionnaireElementLayout = {
  __typename: 'KYCQuestionnaireElementLayout';
  /** group template details for a group question element group */
  groupTemplate: Maybe<KYCQuestionnaireElementLayoutGroupTemplate>;
  /** icon used for a questionnaire element. Will mostly be used for NAV elements */
  icon: Scalars['String']['output'];
  /** layout orientation of a questionnaire element */
  orientation: KYCQuestionnaireElementLayoutOrientation;
  /** layout type of questionnaire element */
  type: KYCQuestionnaireElementLayoutType;
};

/**
 * KYCQuestionnaireElementLayoutGroupTemplate is a layout property for kyc questionnaire element that specifies
 * detailed fields for configuring a group question template in the user interface
 */
export type KYCQuestionnaireElementLayoutGroupTemplate = {
  __typename: 'KYCQuestionnaireElementLayoutGroupTemplate';
  /** icon used for each questionnaire group element */
  icon: Scalars['String']['output'];
  /** title used for a group item modals */
  itemSubtitle: Scalars['String']['output'];
  /** template string used for an existing group item */
  itemTemplate: Scalars['String']['output'];
  /** title used for a group item modals */
  itemTitle: Scalars['String']['output'];
  /** template string used for new group item */
  newItemTemplate: Scalars['String']['output'];
};

/** KYCQuestionnaireElementOrientation is enum for the KYCQuestionnaireElement orientation */
export enum KYCQuestionnaireElementLayoutOrientation {
  HORIZONTAL = 'HORIZONTAL',
  UNSPECIFIED = 'UNSPECIFIED',
  VERTICAL = 'VERTICAL'
}

/** KYCQuestionnaireElementLayoutType is enum for the KYCQuestionnaireElementLayout type */
export enum KYCQuestionnaireElementLayoutType {
  MODAL = 'MODAL',
  SUB_BLOCK = 'SUB_BLOCK',
  UNSPECIFIED = 'UNSPECIFIED'
}

/** KYCQuestionnaireElementType is enum for the KYCQuestionnaireElement type */
export enum KYCQuestionnaireElementType {
  BLOCK = 'BLOCK',
  NAV = 'NAV',
  QUESTION = 'QUESTION',
  QUESTIONNAIRE = 'QUESTIONNAIRE'
}

/** KYCQuestionnaireNavStatus is for the KYCQuestionnaireNavStatus entity */
export type KYCQuestionnaireNavStatus = {
  __typename: 'KYCQuestionnaireNavStatus';
  /** elementID is the nav element id */
  elementID: Scalars['String']['output'];
  /** status is the Nav element status indicating all questions are answered */
  status: KYCNavStatusType;
};

/** Ordering options for KYCQuestionnaire connections */
export type KYCQuestionnaireOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order KYCQuestionnaires. */
  field: KYCQuestionnaireOrderField;
};

/** Properties by which KYCQuestionnaire connections can be ordered. */
export enum KYCQuestionnaireOrderField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  NAME = 'NAME',
  QUESTIONNAIRE_TYPE = 'QUESTIONNAIRE_TYPE'
}

/** KYCQuestionnaireSubmission is for the KYCQuestionnaireSubmission entity. */
export type KYCQuestionnaireSubmission = Node & {
  __typename: 'KYCQuestionnaireSubmission';
  answers: Maybe<Array<KYCQuestionAnswer>>;
  body: Maybe<KYCQuestionnaireElement>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['String']['output'];
  expirationDays: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  kycProcess: KYCProcess;
  kycQuestionnaire: KYCQuestionnaire;
  name: Maybe<Scalars['String']['output']>;
  progress: Maybe<KYCQuestionnaireSubmissionProgress>;
  state: KYCQuestionnaireSubmissionState;
  submittedAt: Maybe<Scalars['Time']['output']>;
  submittedBy: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['Time']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type KYCQuestionnaireSubmissionConnection = {
  __typename: 'KYCQuestionnaireSubmissionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<KYCQuestionnaireSubmissionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type KYCQuestionnaireSubmissionEdge = {
  __typename: 'KYCQuestionnaireSubmissionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<KYCQuestionnaireSubmission>;
};

/** Ordering options for KYCQuestionnaireSubmission connections */
export type KYCQuestionnaireSubmissionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order KYCQuestionnaireSubmissions. */
  field: KYCQuestionnaireSubmissionOrderField;
};

/** Properties by which KYCQuestionnaireSubmission connections can be ordered. */
export enum KYCQuestionnaireSubmissionOrderField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  STATE = 'STATE'
}

/** KYCQuestionnaireSubmissionProgress is for the KYCQuestionnaireSubmissionProgress entity. */
export type KYCQuestionnaireSubmissionProgress = {
  __typename: 'KYCQuestionnaireSubmissionProgress';
  /** currentNavElementID is the current nav element id that is being filled in the questionnaire */
  currentNavElementID: Maybe<Scalars['String']['output']>;
  /** navStatus is the status (if all questions are answered) of the nav elements in the questionnaire */
  navStatus: Maybe<Array<KYCQuestionnaireNavStatus>>;
  /** percentage is the percentage of required questions answered in the questionnaire */
  percentage: Maybe<Scalars['String']['output']>;
  /** validationErrors are the current validation errors on the questionnaire */
  validationErrors: Maybe<Array<KYCQuestionnaireValidationError>>;
};

/** KYCQuestionnaireSubmissionState is enum for the field state */
export enum KYCQuestionnaireSubmissionState {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  INIT = 'INIT',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_REVIEW = 'IN_REVIEW'
}

/**
 * KYCQuestionnaireSubmissionWhereInput is used for filtering KYCQuestionnaireSubmission objects.
 * Input was generated by ent.
 */
export type KYCQuestionnaireSubmissionWhereInput = {
  and?: InputMaybe<Array<KYCQuestionnaireSubmissionWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** kyc_process edge predicates */
  hasKycProcess?: InputMaybe<Scalars['Boolean']['input']>;
  hasKycProcessWith?: InputMaybe<Array<KYCProcessWhereInput>>;
  /** kyc_questionnaire edge predicates */
  hasKycQuestionnaire?: InputMaybe<Scalars['Boolean']['input']>;
  hasKycQuestionnaireWith?: InputMaybe<Array<KYCQuestionnaireWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idContainsFold?: InputMaybe<Scalars['ID']['input']>;
  idEqualFold?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kyc_process_id field predicates */
  kycProcessID?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDContains?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDGT?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDGTE?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  kycProcessIDLT?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDLTE?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  kycProcessIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kyc_questionnaire_id field predicates */
  kycQuestionnaireID?: InputMaybe<Scalars['ID']['input']>;
  kycQuestionnaireIDContains?: InputMaybe<Scalars['ID']['input']>;
  kycQuestionnaireIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  kycQuestionnaireIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  kycQuestionnaireIDGT?: InputMaybe<Scalars['ID']['input']>;
  kycQuestionnaireIDGTE?: InputMaybe<Scalars['ID']['input']>;
  kycQuestionnaireIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  kycQuestionnaireIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  kycQuestionnaireIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  kycQuestionnaireIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  kycQuestionnaireIDLT?: InputMaybe<Scalars['ID']['input']>;
  kycQuestionnaireIDLTE?: InputMaybe<Scalars['ID']['input']>;
  kycQuestionnaireIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  kycQuestionnaireIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  kycQuestionnaireIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<KYCQuestionnaireSubmissionWhereInput>;
  or?: InputMaybe<Array<KYCQuestionnaireSubmissionWhereInput>>;
  /** state field predicates */
  state?: InputMaybe<KYCQuestionnaireSubmissionState>;
  stateIn?: InputMaybe<Array<KYCQuestionnaireSubmissionState>>;
  stateNEQ?: InputMaybe<KYCQuestionnaireSubmissionState>;
  stateNotIn?: InputMaybe<Array<KYCQuestionnaireSubmissionState>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

/** KYCQuestionnaireType is enum for the field questionnaire_type */
export enum KYCQuestionnaireType {
  KYC_ONBOARDING = 'KYC_ONBOARDING',
  KYC_QC = 'KYC_QC',
  KYC_REVIEW = 'KYC_REVIEW',
  RIA_TERMS = 'RIA_TERMS',
  UNSPECIFIED = 'UNSPECIFIED'
}

/** KYCQuestionnaireValidationError is for the KYCQuestionnaireValidationError entity */
export type KYCQuestionnaireValidationError = {
  __typename: 'KYCQuestionnaireValidationError';
  /** The index of the rule that failed in the question's rules list */
  failingRuleIndex: Maybe<Scalars['Int']['output']>;
  /** message is the error message */
  message: Scalars['String']['output'];
  /** navElementID is the nav element id associated to the question that has the error */
  navElementID: Scalars['String']['output'];
  /** questionID is the ID of the question that has the error */
  questionID: Scalars['String']['output'];
};

/**
 * KYCQuestionnaireWhereInput is used for filtering KYCQuestionnaire objects.
 * Input was generated by ent.
 */
export type KYCQuestionnaireWhereInput = {
  and?: InputMaybe<Array<KYCQuestionnaireWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAtLT?: InputMaybe<Scalars['Time']['input']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idContainsFold?: InputMaybe<Scalars['ID']['input']>;
  idEqualFold?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<KYCQuestionnaireWhereInput>;
  or?: InputMaybe<Array<KYCQuestionnaireWhereInput>>;
  /** questionnaire_type field predicates */
  questionnaireType?: InputMaybe<KYCQuestionnaireType>;
  questionnaireTypeIn?: InputMaybe<Array<KYCQuestionnaireType>>;
  questionnaireTypeNEQ?: InputMaybe<KYCQuestionnaireType>;
  questionnaireTypeNotIn?: InputMaybe<Array<KYCQuestionnaireType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

/** KYCRelatedParty represents a related party */
export type KYCRelatedParty = {
  __typename: 'KYCRelatedParty';
  /** The related party country code */
  countryCode: Maybe<Scalars['String']['output']>;
  /** The related party id */
  id: Scalars['ID']['output'];
  /** The related party name */
  name: Scalars['String']['output'];
  /** Relationships of this related party with the provided affiliate. */
  relationships: Maybe<Array<KYCRelatedPartyRelationship>>;
};

/** KYCRelatedPartyConnection represents a list of KYCRelatedParty edges. */
export type KYCRelatedPartyConnection = {
  __typename: 'KYCRelatedPartyConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<KYCRelatedPartyEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type KYCRelatedPartyEdge = {
  __typename: 'KYCRelatedPartyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<KYCRelatedParty>;
};

/** KYCRelatedPartyRelationship specifies the relationship between a related party and an affiliate */
export type KYCRelatedPartyRelationship = {
  __typename: 'KYCRelatedPartyRelationship';
  /** The related party relationship affiliate id */
  affiliateID: Scalars['ID']['output'];
  /** Kyc roles of this related party with the provided affiliate. */
  kycRoles: Maybe<Array<IndividualAffiliateRelationship>>;
  /**
   * The percentage of the affiliate that the individual owns. Only relevant for
   * beneficial owners.
   */
  ownershipPercentage: Maybe<Scalars['String']['output']>;
  /** Role of an individual in the affiliate (such as CEO, COO, etc). */
  role: Maybe<Scalars['String']['output']>;
};

/** KYCRelatedPartyWhereInput specifies filtering options for KYCRelatedPartyConnection. */
export type KYCRelatedPartyWhereInput = {
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** KYCScreening specifies properties related with KYC Information Screening */
export type KYCScreening = {
  __typename: 'KYCScreening';
  /** specify if should force to push changes to the screening process */
  forceScreening: Scalars['Boolean']['output'];
  /** specify what is the reason for pusing changes to the screening process */
  screeningReason: Scalars['String']['output'];
};

/** KYCScreeningInput specifies properties related with KYC Information Screening */
export type KYCScreeningInput = {
  /** specify if should force to push changes to the screening process */
  forceScreening: Scalars['Boolean']['input'];
  /** specify what is the reason for pusing changes to the screening process */
  screeningReason: Scalars['String']['input'];
};

/** KYCType specifies different entity types of KYC Information */
export enum KYCType {
  /** AFFILIATE */
  AFFILIATE = 'AFFILIATE',
  /** CLIENT */
  CLIENT = 'CLIENT',
  /** INDIVIDUAL */
  INDIVIDUAL = 'INDIVIDUAL'
}

/** KYCValidationError is a validation error that occurred while saving or submitting a kyc questionnaire answer */
export type KYCValidationError = {
  __typename: 'KYCValidationError';
  /** The index of the rule that failed in the question's rules list */
  failedValidationRuleIndex: Maybe<Scalars['Int']['output']>;
  /** A custom error message for the validation */
  message: Scalars['String']['output'];
  /** The id of the page the question is in */
  pageID: Scalars['String']['output'];
  /** The id of the question the error occurred in */
  questionID: Scalars['String']['output'];
};

/**
 * Key is a interface that shall be implemented by all types that derive/augment
 * keys.
 */
export type Key = {
  /** Unique identifier of this key pair, usually it's the public key ID. */
  id: Scalars['ID']['output'];
  /** Key information. */
  keyInfo: KeyInfo;
};

/** Information about an asymmetric key algorithm. */
export type KeyAlgorithm = {
  __typename: 'KeyAlgorithm';
  /**
   * DON'T USE THIS FIELD. This field is deprecated and will be removed in the
   * future. Use digestibleName instead.
   * @deprecated Use digestibleName instead.
   */
  digestableName: Scalars['String']['output'];
  /**
   * An opaque representation of the algorithm's name. Clients shall
   * not make any assumptions about the value of this field. This field
   * shall be used whenever a digest needs to be computed and the algorithm
   * of a key needs to be included (e.g., Keys that are added to
   * organization structures). If this field is null, the key's algorithm should
   * not be taken into account when computing digests.
   */
  digestibleName: Maybe<Scalars['String']['output']>;
  /** A description of the algorithm in a form suitable for displaying in UIs. */
  displayDescription: Scalars['String']['output'];
  /** The name of the algorithm in a form suitable for displaying in UIs. */
  displayName: Scalars['String']['output'];
};

/** A connection to a list of keys. */
export type KeyConnection = {
  __typename: 'KeyConnection';
  /** A list of edges. */
  edges: Array<Maybe<KeyEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** KeyData contains all publically available information of an asymmetric key pair. */
export type KeyData = {
  __typename: 'KeyData';
  /** Details about the cryptographic algorithm used to create this key. */
  algorithm: KeyAlgorithm;
  /**
   * String representation of the public key of this key pair. The specific format
   * used depends on the algorithm. Most of the time this field should be treated by
   * clients as an opaque string.
   */
  publicKey: Scalars['String']['output'];
};

/** An edge in a connection. */
export type KeyEdge = {
  __typename: 'KeyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Key;
};

/** Key represents information about a cryptographic key/key pair. */
export type KeyInfo = Node & {
  __typename: 'KeyInfo';
  /** Timestamp of when this key was added to our system. */
  addedAt: Scalars['Timestamp']['output'];
  /** Optional user-provided description of this key pair. */
  description: Maybe<Scalars['String']['output']>;
  /**
   * Optional master control key encrypted to the encryptionKey.
   * See spec: Porto: Control key + key export features.
   */
  encryptedControlMasterPrivateKey: Maybe<Scalars['String']['output']>;
  /**
   * Optional key for receiving encrypted data such as wallet key backups.
   * See spec: Porto: Control key + key export features.
   */
  encryptionKey: Maybe<KeyData>;
  /** Unique identifier of this key pair, usually it's the public key ID. */
  id: Scalars['ID']['output'];
  /**
   * If this field is null the key can be deleted/removed/revoked.
   * Otherwise, it contains the human readable reason for why it can't be.
   */
  irremovabilityReason: Maybe<Scalars['String']['output']>;
  /**
   * Indicates if the key has been used recently or not. Note that this value
   * might be computed and not reflect exact real-time status.
   */
  isActive: Maybe<Scalars['Boolean']['output']>;
  /**
   * True if the key was marked as lost by Anchorage. Marking a key as lost is
   * not an irreversible operation and it can be unmarked by Anchorage.
   */
  isMarkedLost: Maybe<Scalars['Boolean']['output']>;
  /** Public key details. */
  key: KeyData;
  /**
   * Timestamp of when this key was last used. Might be null if this key was not used
   * yet.
   */
  lastUsedAt: Maybe<Scalars['Timestamp']['output']>;
  /** Name of this key pair. This name might be cryptographically protected. */
  name: Scalars['String']['output'];
  /** User that owns this key. */
  owner: UserV2;
  /** Indicates the last known status of this key. */
  status: KeyStatus;
};

/** KeyStatus represents the status of a key. */
export enum KeyStatus {
  /** The key will become active after some required action and cannot yet be used. */
  PENDING = 'PENDING',
  /** The key was revoked/removed and cannot be used anymore. */
  REVOKED = 'REVOKED',
  /** The key is ready to be used but hasn't been used yet. */
  UNUSED = 'UNUSED',
  /** The key has been used already. */
  USED = 'USED'
}

/** Filtering options for Keys returned from the connection. */
export type KeyWhereInput = {
  /** Filter by key IDs. */
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Currently known affiliate information */
export type KnownAffiliateInformation = {
  __typename: 'KnownAffiliateInformation';
  /**
   * Address from where the content may be loaded.
   * Only present on blob types
   */
  contentURL: Maybe<Scalars['String']['output']>;
  /** Group information */
  groupInformation: Maybe<Array<Maybe<Array<KnownAffiliateInformation>>>>;
  /** The type of information */
  informationType: AffiliateInformationType;
  /** The kyc screening audit related information */
  kycScreening: Maybe<KYCScreening>;
  /** The notes associated to a given audit trail entry */
  notes: Maybe<Scalars['String']['output']>;
  /** The timestamp when the change was made */
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  /** The user that made the change associated to the audit trail info */
  updatedBy: Maybe<Scalars['String']['output']>;
  /** The value of the information */
  value: Scalars['String']['output'];
  /** The lifecyle stage of the value */
  valueLifecycle: InstitutionalValueLifecycle;
};

/** Currently known client information */
export type KnownClientInformation = {
  __typename: 'KnownClientInformation';
  /**
   * Address from where the content may be loaded.
   * Only present on blob types
   */
  contentURL: Maybe<Scalars['String']['output']>;
  /** The type of information */
  informationType: ClientInformationType;
  /** The notes associated to a given audit trail entry */
  notes: Maybe<Scalars['String']['output']>;
  /** The timestamp when the change was made */
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  /** The user that made the change associated to the audit trail info */
  updatedBy: Maybe<Scalars['String']['output']>;
  /** The value of the information */
  value: Scalars['String']['output'];
  /** The lifecyle stage of the value */
  valueLifecycle: InstitutionalValueLifecycle;
};

/** Currently known individual information */
export type KnownInformation = {
  __typename: 'KnownInformation';
  /**
   * Address from where the content may be loaded.
   * Only present on blob types
   */
  contentURL: Maybe<Scalars['String']['output']>;
  /** The type of information */
  informationType: IndividualInformationType;
  /** The kyc screening audit related information */
  kycScreening: Maybe<KYCScreening>;
  /** The notes associated to a given audit trail entry */
  notes: Maybe<Scalars['String']['output']>;
  /** The timestamp when the change was made */
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  /** The user that made the change associated to the audit trail info */
  updatedBy: Maybe<Scalars['String']['output']>;
  /**
   * The value of the information
   * WARNING: This is all redacted when querying from the app or client dashboard.
   */
  value: Scalars['String']['output'];
  /** The lifecyle stage of the value */
  valueLifecycle: InstitutionalValueLifecycle;
};

/** Late Fee */
export type LateFee = {
  __typename: 'LateFee';
  /** Amount of fee */
  amount: Amount;
  /** CreatedAt is when the fee is created */
  createdAt: Scalars['Timestamp']['output'];
  /** FeeDate is the date the fee occurred */
  feeDate: Scalars['Date']['output'];
  /** Identifier of the late fee */
  id: Scalars['ID']['output'];
};

/** Lender encapsulates lender related information for a membership */
export type Lender = {
  __typename: 'Lender';
  /** Should the interest generated for this member be automatically reinvested */
  autoReinvestInterest: Maybe<Scalars['Boolean']['output']>;
  /** Total balance member has in the facility if applicable */
  balance: Maybe<Amount>;
  /** Represents if a lending entity wants to close membership */
  closeOut: Maybe<Scalars['Boolean']['output']>;
  /** Total rewards earned by member till date if applicable */
  earnToDate: Maybe<Amount>;
  /**
   * The max amount available to withdrawal that the member has in the facility
   * maxWithdrawal = balance + pendingBalance - pendingWithdrawals
   */
  maxWithdrawal: Maybe<Amount>;
  /**
   * The pending balance the member has in the facility if applicable
   * pendingBalance = approvedDeposits - approvedWithdrawals
   */
  pendingBalance: Maybe<Amount>;
  /** Page for the member's stake in the lending facility on the range of dates - default only current date */
  stakes: Maybe<LendingFacilityMembershipStakeConnection>;
  /** CSV of member's stake in the lending facility on the range of dates */
  stakesCSV: Scalars['String']['output'];
};


/** Lender encapsulates lender related information for a membership */
export type LenderstakesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  afterDate?: InputMaybe<Scalars['Date']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  beforeDate?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  reverse?: InputMaybe<Scalars['Boolean']['input']>;
  sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  sortKey?: InputMaybe<LendingFacilityMembershipStakeSortKey>;
};


/** Lender encapsulates lender related information for a membership */
export type LenderstakesCSVArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  afterDate?: InputMaybe<Scalars['Date']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  beforeDate?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  sortKey?: InputMaybe<LendingFacilityMembershipStakeSortKey>;
};

/** LendingAccessRequestStatus types */
export enum LendingAccessRequestStatus {
  /** Org has been approved to lend */
  ACCEPTED = 'ACCEPTED',
  /** Org lending request has not been created */
  NOT_CREATED = 'NOT_CREATED',
  /** Org has a pending request */
  PENDING = 'PENDING',
  /** Org has an unknown status */
  UNKNOWN = 'UNKNOWN'
}

/** Base type for all exports */
export type LendingCSVExport = {
  __typename: 'LendingCSVExport';
  /** Returns the list of facility memberships in CSV format */
  facilityMembershipsCSV: Scalars['String']['output'];
  /** Returns the list of invoices in CSV format */
  invoicesCSV: Scalars['String']['output'];
  /** Returns the list of loans in CSV format */
  loansCSV: Scalars['String']['output'];
  /** Returns the list of transactions in CSV format */
  transactionsCSV: Scalars['String']['output'];
  /** Returns the list of transfer requests in CSV format */
  transferRequestsCSV: Scalars['String']['output'];
};


/** Base type for all exports */
export type LendingCSVExportfacilityMembershipsCSVArgs = {
  facilityMembersFilters?: InputMaybe<LendingFacilityMembershipFilters>;
};


/** Base type for all exports */
export type LendingCSVExportinvoicesCSVArgs = {
  invoiceFilters?: InputMaybe<InvoiceFilters>;
};


/** Base type for all exports */
export type LendingCSVExportloansCSVArgs = {
  loanFilters?: InputMaybe<LoanFilters>;
};


/** Base type for all exports */
export type LendingCSVExporttransactionsCSVArgs = {
  transactionFilters?: InputMaybe<TransactionFilters>;
};


/** Base type for all exports */
export type LendingCSVExporttransferRequestsCSVArgs = {
  transferRequestFilters?: InputMaybe<TransferRequestFilters>;
};

/** Lending Entity */
export type LendingEntity = Node & {
  __typename: 'LendingEntity';
  /** Organization identifier associated with the lending entity */
  account: Maybe<Account>;
  /** Borrower lending entity collateral party id */
  collateralPartyID: Maybe<Scalars['String']['output']>;
  /** Time when the lending entity was created */
  createdAt: Maybe<Scalars['Timestamp']['output']>;
  /** List of emails for communication with lending entity */
  emails: Array<Scalars['String']['output']>;
  /** Indicates if the lending entity is active or inactive */
  entityStatus: EntityStatus;
  /** Entity symbol unique identifier */
  entityTicker: Scalars['String']['output'];
  /** Identifier of the lending entity */
  id: Scalars['ID']['output'];
  /** Lending invoices fot the given lending entity / org */
  lendingEntityInvoices: LendingInvoiceConnection;
  /** List of facility memberships */
  lendingFacilityMemberships: Array<LendingFacilityMembership>;
  /** Members transactions */
  lendingFacilityTransactions: Maybe<LendingFacilityTransactionConnection>;
  /**
   * Returns the list of lending facility transactions made by a
   * lending account across its facility memberships in a CSV format
   * if the daterange params are not entered, the query will return
   * lending transaction for the last 30 days
   */
  lendingFacilityTransactionsCSV: Scalars['String']['output'];
  /**
   * Returns the rewards earned by a lending user in a CSV format
   * if the daterange params are not entered, the query will return
   * lending rewards for the last 30 days
   */
  lendingRewardsCSV: Scalars['String']['output'];
  /** Indicates if the entity is a borrower, lender or both */
  lendingSide: LendingSide;
  /** Entity metadata in json format */
  metadata: Maybe<Scalars['String']['output']>;
  /** Name of the lending entity */
  name: Scalars['String']['output'];
  /** Total USD amount for lending entity across different memberships */
  totalUSDAmount: Amount;
  /** Lending entity vault */
  vault: Maybe<Vault>;
};


/** Lending Entity */
export type LendingEntitylendingEntityInvoicesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Lending Entity */
export type LendingEntitylendingFacilityMembershipsArgs = {
  filterFacilityIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterMembershipIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterRole?: InputMaybe<Array<LendingFacilityMembershipRole>>;
};


/** Lending Entity */
export type LendingEntitylendingFacilityTransactionsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  facilityID?: InputMaybe<Scalars['ID']['input']>;
  filterEndTime?: InputMaybe<Scalars['Timestamp']['input']>;
  filterLoanEntitySymbol?: InputMaybe<Scalars['String']['input']>;
  filterStartTime?: InputMaybe<Scalars['Timestamp']['input']>;
  filterStatus?: InputMaybe<Array<LendingFacilityTransactionStatus>>;
  filterTransactionType?: InputMaybe<LendingFacilityTransactionType>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Lending Entity */
export type LendingEntitylendingFacilityTransactionsCSVArgs = {
  afterTransactionDate?: InputMaybe<Scalars['Date']['input']>;
  beforeTransactionDate?: InputMaybe<Scalars['Date']['input']>;
  filterFacilityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterTransactionStatus?: InputMaybe<Array<LendingFacilityTransactionStatus>>;
  filterTransactionType?: InputMaybe<Array<LendingFacilityTransactionType>>;
};


/** Lending Entity */
export type LendingEntitylendingRewardsCSVArgs = {
  afterDate?: InputMaybe<Scalars['Date']['input']>;
  beforeDate?: InputMaybe<Scalars['Date']['input']>;
  filterFacilityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Lending entity connection for pagination */
export type LendingEntityConnection = {
  __typename: 'LendingEntityConnection';
  /** List of edges containing lending entity and cursor information */
  edges: Array<LendingEntityEdge>;
  /** Page details for the associated list of lending entity edges */
  pageInfo: PageInfo;
};

/** Lending entity edge for pagination */
export type LendingEntityEdge = {
  __typename: 'LendingEntityEdge';
  /** Cursor for the associated lending entity */
  cursor: Scalars['ID']['output'];
  /** Lending entity */
  node: LendingEntity;
};

/** Lending facility */
export type LendingFacility = Node & {
  __typename: 'LendingFacility';
  /** Returns the APY for a facility in CSV format */
  apyCSV: Scalars['String']['output'];
  /** Asset type of the lending facility */
  assetTypeInfo: AssetTypeInfo;
  /** Is interest automatically reinvested for members of this facility? */
  autoReinvestInterest: Scalars['Boolean']['output'];
  /** Total amount borrowed from the lending facility */
  borrowerTotalAmount: Maybe<Amount>;
  /** Time when the lending facility was created */
  createdAt: Scalars['Timestamp']['output'];
  /** Person or desk that created the lending facility */
  createdBy: Scalars['String']['output'];
  /** Page of daily APY for the lending facility - default only current APY */
  dailyAPY: LendingFacilityDailyAPYConnection;
  /** Description of the lending facility */
  description: Scalars['String']['output'];
  /** Extended facility fee report */
  extendedFeeReport: LendingFacilityExtendedFeeDailyReport;
  /** All facility loans */
  facilityLoans: LoanConnection;
  /** Fee percentage Anchorage charges members */
  fee: Maybe<Scalars['Percent']['output']>;
  /** Facility fee amount snapshots */
  feeAmountSnapshots: LendingFacilityFeeAmountSnapshotConnection;
  /** Facility fees */
  feeDetails: LendingFacilityFeeConnection;
  /** Facility fee report */
  feeReport: Array<LendingFacilityFeeDailyReport>;
  /** Identifier of the lending facility */
  id: Scalars['ID']['output'];
  /** Is this facility internal? */
  internal: Scalars['Boolean']['output'];
  /** Is this facility a fixed rate facility? */
  isFixedRate: Scalars['Boolean']['output'];
  /** Are the transactions in this facility not represented on chain */
  isOffChain: Scalars['Boolean']['output'];
  /** Total amount lending facility is holding */
  lenderTotalAmount: Amount;
  /** Members */
  members: Maybe<LendingFacilityMembershipConnection>;
  /** Arbitrary JSON data for use by internal users */
  metadata: Maybe<Scalars['String']['output']>;
  /** Name of the lending facility */
  name: Scalars['String']['output'];
  /** Get the sum of reinvestment transactions for a given month */
  reinvestmentTransactionsSum: Scalars['String']['output'];
  /** Risk level of the lending facility */
  riskLevel: Maybe<LendingFacilityRiskLevel>;
  /** Date in YYYY-MM-DD format representing the Total amount snapshot date */
  snapshotDate: Scalars['Date']['output'];
  /** State of the lending facility */
  state: Maybe<LendingFacilityState>;
  /** Average APY of the last thirty days */
  thirtyDayAverage: Maybe<Scalars['Percent']['output']>;
  /** Page of transactions for the lending facility */
  transactions: LendingFacilityTransactionConnection;
  /** Time when the lending facility was last updated */
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  /** Person or desk that last updated the lending facility last */
  updatedBy: Maybe<Scalars['String']['output']>;
  /** Wallet identifier of the lending facility */
  wallet: Maybe<Wallet>;
};


/** Lending facility */
export type LendingFacilityapyCSVArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filters?: InputMaybe<DailyAPYFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Lending facility */
export type LendingFacilitydailyAPYArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  afterDate?: InputMaybe<Scalars['Date']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  beforeDate?: InputMaybe<Scalars['Date']['input']>;
  filters?: InputMaybe<DailyAPYFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Lending facility */
export type LendingFacilityextendedFeeReportArgs = {
  filterAfterDate: Scalars['Date']['input'];
  filterBeforeDate: Scalars['Date']['input'];
};


/** Lending facility */
export type LendingFacilityfacilityLoansArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAfterClosedAt?: InputMaybe<Scalars['Date']['input']>;
  filterAfterMaturityDate?: InputMaybe<Scalars['Date']['input']>;
  filterAfterOriginationDate?: InputMaybe<Scalars['Date']['input']>;
  filterAfterTimestamp?: InputMaybe<Scalars['Date']['input']>;
  filterApprovalType?: InputMaybe<Array<LoanApprovalType>>;
  filterBeforeClosedAt?: InputMaybe<Scalars['Date']['input']>;
  filterBeforeMaturityDate?: InputMaybe<Scalars['Date']['input']>;
  filterBeforeOriginationDate?: InputMaybe<Scalars['Date']['input']>;
  filterBeforeTimestamp?: InputMaybe<Scalars['Date']['input']>;
  filterExactOriginationDate?: InputMaybe<Scalars['Date']['input']>;
  filterFacilityMemberID?: InputMaybe<Scalars['ID']['input']>;
  filterIsOpenTerm?: InputMaybe<Scalars['Boolean']['input']>;
  filterIsRefinanced?: InputMaybe<Scalars['Boolean']['input']>;
  filterLoanIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterRehypothecation?: InputMaybe<Scalars['Boolean']['input']>;
  filterStatus?: InputMaybe<Array<LoanTermStatus>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Lending facility */
export type LendingFacilityfeeAmountSnapshotsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAfterSnapshotDate?: InputMaybe<Scalars['Date']['input']>;
  filterBeforeSnapshotDate?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Lending facility */
export type LendingFacilityfeeDetailsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAfterFeeDate?: InputMaybe<Scalars['Date']['input']>;
  filterBeforeFeeDate?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  sortKey?: InputMaybe<LendingFacilityFeeSortKey>;
};


/** Lending facility */
export type LendingFacilityfeeReportArgs = {
  filterAfterDate: Scalars['Date']['input'];
  filterBeforeDate: Scalars['Date']['input'];
};


/** Lending facility */
export type LendingFacilitymembersArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterOrgID?: InputMaybe<Scalars['String']['input']>;
  filterRole?: InputMaybe<LendingFacilityMembershipRole>;
  filterStatus?: InputMaybe<LendingFacilityMembershipStatus>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Lending facility */
export type LendingFacilityreinvestmentTransactionsSumArgs = {
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};


/** Lending facility */
export type LendingFacilitytransactionsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  transactionFilters?: InputMaybe<TransactionFilters>;
};

/** Lending facility connection for pagination */
export type LendingFacilityConnection = {
  __typename: 'LendingFacilityConnection';
  /** List of edges containing lending facility and cursor information */
  edges: Array<LendingFacilityEdge>;
  /** Page details for the associated list of lending facility edges */
  pageInfo: PageInfo;
};

/** Lending facility daily APY connection for pagination */
export type LendingFacilityDailyAPYConnection = {
  __typename: 'LendingFacilityDailyAPYConnection';
  /** List of edges containing lending facility daily APY and cursor information */
  edges: Array<LendingFacilityDailyAPYEdge>;
  /** Page details for the associated list of lending facility daily APY edges */
  pageInfo: PageInfo;
};

/** Lending facility daily APY edge for pagination */
export type LendingFacilityDailyAPYEdge = {
  __typename: 'LendingFacilityDailyAPYEdge';
  /** Cursor for the associated lending facility daily APY */
  cursor: Scalars['ID']['output'];
  /** Lending facility daily APY */
  node: LendingFacilityDailyApy;
};

/** Lending facility daily APY status */
export enum LendingFacilityDailyAPYStatus {
  /** Final daily APY */
  FINAL = 'FINAL',
  /** Pending daily APY */
  PENDING = 'PENDING'
}

/** Lending facility daily APY */
export type LendingFacilityDailyApy = Node & {
  __typename: 'LendingFacilityDailyApy';
  /** APY percentage value of the lending facility for a particular date */
  apy: Maybe<Scalars['Percent']['output']>;
  /** Date in YYYY-MM-DD format for the APY of the lending facility */
  date: Scalars['Date']['output'];
  /** Identifier for the daily APY of the lending facility */
  id: Scalars['ID']['output'];
  /** Reward amount value of the lending facility for a particular date */
  rewardAmount: Amount;
  /** Status of this APY */
  status: LendingFacilityDailyAPYStatus;
};

/** Lending facility daily APY edge for pagination */
export type LendingFacilityEdge = {
  __typename: 'LendingFacilityEdge';
  /** Cursor for the associated lending facility */
  cursor: Scalars['ID']['output'];
  /** Lending facility */
  node: LendingFacility;
};

/** Lending facility fee report extended with helpful or derived attributes */
export type LendingFacilityExtendedFeeDailyReport = {
  __typename: 'LendingFacilityExtendedFeeDailyReport';
  /** Rows of the facility fee report */
  rows: Array<LendingFacilityFeeDailyReport>;
  /** Summary of the daily facility fee report */
  summary: Maybe<LendingFacilityFeeDailyReportSummary>;
};

/** Lending facility fee */
export type LendingFacilityFee = Node & {
  __typename: 'LendingFacilityFee';
  /** FeeDate for a given lending facility fee */
  feeDate: Scalars['Date']['output'];
  /** Identifier of the lending facility fee */
  id: Scalars['ID']['output'];
  /** PercentageAmount for the fee for a given day */
  percentageAmount: Scalars['Percent']['output'];
};

/** Lending facility fee amount snapshot */
export type LendingFacilityFeeAmountSnapshot = Node & {
  __typename: 'LendingFacilityFeeAmountSnapshot';
  /** FeeAmount for the fee amount snapshot for a given day */
  feeAmount: Amount;
  /** Identifier of the late fee amount snapshot */
  id: Scalars['ID']['output'];
  /** SnapshotDate for a given lending facility fee amount snapshot */
  snapshotDate: Scalars['Date']['output'];
};

/** Lending facility fee amount snapshot connection for pagination */
export type LendingFacilityFeeAmountSnapshotConnection = {
  __typename: 'LendingFacilityFeeAmountSnapshotConnection';
  /** List of edges containing lending facility fee amount snapshots and cursor information */
  edges: Array<LendingFacilityFeeAmountSnapshotEdge>;
  /** Page details for the associated list of lending facility fee amount snapshot edges */
  pageInfo: PageInfo;
};

/** Lending facility fee amount snapshot edge for pagination */
export type LendingFacilityFeeAmountSnapshotEdge = {
  __typename: 'LendingFacilityFeeAmountSnapshotEdge';
  /** Cursor for the associated lending facility fee amount snapshot */
  cursor: Scalars['ID']['output'];
  /** Lending facility fee amount snapshot */
  node: LendingFacilityFeeAmountSnapshot;
};

/** Lending facility fee connection for pagination */
export type LendingFacilityFeeConnection = {
  __typename: 'LendingFacilityFeeConnection';
  /** List of edges containing lending facility fees and cursor information */
  edges: Array<LendingFacilityFeeEdge>;
  /** Page details for the associated list of lending facility fee edges */
  pageInfo: PageInfo;
};

/** Lending facility fee report */
export type LendingFacilityFeeDailyReport = {
  __typename: 'LendingFacilityFeeDailyReport';
  /** Daily facility annualized APY */
  annualizedApy: Scalars['Percent']['output'];
  /** Daily facility balance */
  balance: Scalars['Decimal']['output'];
  /** Date of the report */
  date: Scalars['Date']['output'];
  /** Daily facility fee amount */
  feeAmount: Scalars['Decimal']['output'];
  /** Daily facility fee percentage */
  feePercentage: Scalars['Percent']['output'];
  /** Daily facility gross APY */
  grossApy: Scalars['Percent']['output'];
  /** Daily facility gross reward */
  grossReward: Scalars['Decimal']['output'];
  /** Daily facility net APY */
  netApy: Scalars['Percent']['output'];
  /** Daily facility net reward */
  netReward: Scalars['Decimal']['output'];
};

/** Aggregate or summarizing details of the daily facility fee report for a particular date range */
export type LendingFacilityFeeDailyReportSummary = {
  __typename: 'LendingFacilityFeeDailyReportSummary';
  /** Sum of the daily fee amounts for a given report */
  totalFeeAmount: Scalars['Decimal']['output'];
};

/** Lending facility fee edge for pagination */
export type LendingFacilityFeeEdge = {
  __typename: 'LendingFacilityFeeEdge';
  /** Cursor for the associated lending facility fee */
  cursor: Scalars['ID']['output'];
  /** Lending facility fee */
  node: LendingFacilityFee;
};

/** Sorting options for lending facility fees */
export enum LendingFacilityFeeSortKey {
  /** Sort by 'Created at' option for lending facility fees */
  CREATED_AT = 'CREATED_AT',
  /** Sort by 'Facility id' option for lending facility fees */
  FACILITY_ID = 'FACILITY_ID',
  /** Sort by 'Fee date' option for lending facility fees */
  FEE_DATE = 'FEE_DATE'
}

/** Lending facility member */
export type LendingFacilityMembership = Node & {
  __typename: 'LendingFacilityMembership';
  /**
   * Total balance member has in the facility if applicable
   * @deprecated use lender balance instead
   */
  balance: Maybe<Amount>;
  /** Borrower information */
  borrower: Maybe<Borrower>;
  /**
   * Represents if a lending entity wants to close membership
   * @deprecated use lender closeOut instead
   */
  closeOut: Maybe<Scalars['Boolean']['output']>;
  /** Time at which this membership was created */
  createdAt: Scalars['Timestamp']['output'];
  /** Person or desk created this membership */
  createdBy: Scalars['String']['output'];
  /**
   * Total rewards earned by member till date if applicable
   * @deprecated use lender earnToDate instead
   */
  earnToDate: Maybe<Amount>;
  /** The lending facility the member is part of */
  facility: LendingFacility;
  /** Fixed rate value of the membership in a fixed rate facility */
  fixedRate: Maybe<Scalars['Percent']['output']>;
  /** Identifier of the lending facility member */
  id: Scalars['ID']['output'];
  /** Lender information */
  lender: Maybe<Lender>;
  /** lendingEntity is the lending entiry of the lending facility member */
  lendingEntity: LendingEntity;
  /** Maturity date of the deposit of a fixed rate member */
  maturityDate: Maybe<Scalars['Date']['output']>;
  /** Members transactions */
  membersTransactions: Maybe<LendingFacilityTransactionConnection>;
  /**
   * The pending balance the member has in the facility if applicable
   * pending balance = total balance + pending deposits - pending withdrawals
   * @deprecated use lender pendingBalance instead
   */
  pendingBalance: Maybe<Amount>;
  /** Role of the lending facility member */
  role: Maybe<LendingFacilityMembershipRole>;
  /**
   * Page for the member's stake in the lending facility on the range of dates - default only current date
   * @deprecated use lender stakes instead
   */
  stakes: Maybe<LendingFacilityMembershipStakeConnection>;
  /** Status of the lending facility member */
  status: LendingFacilityMembershipStatus;
};


/** Lending facility member */
export type LendingFacilityMembershipmembersTransactionsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterStatus?: InputMaybe<Array<LendingFacilityTransactionStatus>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Lending facility member */
export type LendingFacilityMembershipstakesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  afterDate?: InputMaybe<Scalars['Date']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  beforeDate?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  reverse?: InputMaybe<Scalars['Boolean']['input']>;
  sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  sortKey?: InputMaybe<LendingFacilityMembershipStakeSortKey>;
};

/** Lending facility member connection for pagination */
export type LendingFacilityMembershipConnection = {
  __typename: 'LendingFacilityMembershipConnection';
  /** List of edges containing lending facility member and cursor information */
  edges: Array<LendingFacilityMembershipEdge>;
  /** Page details for the associated list of lending facility member edges */
  pageInfo: PageInfo;
};

/** Lending facility member edge for pagination */
export type LendingFacilityMembershipEdge = {
  __typename: 'LendingFacilityMembershipEdge';
  /** Cursor for the associated lending facility member */
  cursor: Scalars['ID']['output'];
  /** Lending facility member */
  node: LendingFacilityMembership;
};

/** Filters for the lending facility memberships object */
export type LendingFacilityMembershipFilters = {
  /** Filter by asset ids */
  filterAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  /** Filter by auto reinvestment */
  filterAutoReinvestment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by close out */
  filterCloseOut?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by entity IDs */
  filterEntityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by facility IDs */
  filterFacilityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by membership ID */
  filterIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by role */
  filterRole?: InputMaybe<LendingFacilityMembershipRole>;
  /** Filter by status */
  filterStatus?: InputMaybe<LendingFacilityMembershipStatus>;
};

/** Lending Facility Members role options */
export enum LendingFacilityMembershipRole {
  /** Borrower role for the lending facility member */
  BORROWER = 'BORROWER',
  /** Both Borrower and Lender role for the lending facility member */
  BOTH = 'BOTH',
  /** Lender role for the lending facility member */
  LENDER = 'LENDER'
}

/** Lending facility members sorting options */
export enum LendingFacilityMembershipSortKey {
  /** Sort by 'Created at' option for lending facility members */
  CREATED_AT = 'CREATED_AT',
  /** Sort by ID option for lending facility members */
  ID = 'ID',
  /** Sort by Status option for lending facility members */
  STATUS = 'STATUS'
}

/** Lending facility member stake */
export type LendingFacilityMembershipStake = Node & {
  __typename: 'LendingFacilityMembershipStake';
  /** Date in timestamp format for the member stake */
  date: Scalars['Date']['output'];
  /** Identifier of the member's stake */
  id: Scalars['ID']['output'];
  /** Proportion is percentage representing member's stake in the facility */
  proportion: Maybe<Scalars['Percent']['output']>;
  /** Reward amount corresponding to proportion */
  rewardAmount: Amount;
  /** Status of the lending facility reward associated with this stake */
  rewardStatus: Maybe<LendingFacilityTransactionStatus>;
  /** Total current invested amount of the member's capital */
  totalAmount: Maybe<Amount>;
};

/** Lending facility member stake connection for pagination */
export type LendingFacilityMembershipStakeConnection = {
  __typename: 'LendingFacilityMembershipStakeConnection';
  /** List of edges containing lending facility member stake and cursor information */
  edges: Array<LendingFacilityMembershipStakeEdge>;
  /** Page details for the associated list of lending facility member stake edges */
  pageInfo: PageInfo;
};

/** Lending facility member stake edge for pagination */
export type LendingFacilityMembershipStakeEdge = {
  __typename: 'LendingFacilityMembershipStakeEdge';
  /** Cursor for the associated lending facility member stake */
  cursor: Scalars['ID']['output'];
  /** Lending facility member stake */
  node: LendingFacilityMembershipStake;
};

/** Sorting options for lending facility members stakes */
export enum LendingFacilityMembershipStakeSortKey {
  /** Sort by Date option for lending facility members stakes */
  DATE = 'DATE',
  /** Sort by ID option for lending facility members stakes */
  ID = 'ID'
}

/** Lending Facility Members status options */
export enum LendingFacilityMembershipStatus {
  /** Active status for the lending facility member */
  ACTIVE = 'ACTIVE',
  /** Inactive status for the lending facility member */
  INACTIVE = 'INACTIVE'
}

/** Lending facility risk level */
export enum LendingFacilityRiskLevel {
  /** High risk level for lending facility */
  HIGH = 'HIGH',
  /** Low risk level for lending facility */
  LOW = 'LOW',
  /** Medium risk level for lending facility */
  MEDIUM = 'MEDIUM'
}

/** Lending facilities sorting options */
export enum LendingFacilitySortKey {
  /** Sort by Asset Type option for lending facilities */
  ASSET_TYPE = 'ASSET_TYPE',
  /** Sort by 'Created at' option for lending facilities */
  CREATED_AT = 'CREATED_AT',
  /** Sort by Description option for lending facilities */
  DESCRIPTION = 'DESCRIPTION',
  /** Sort by Fee option for lending facilities */
  FEE = 'FEE',
  /** Sort by ID option for lending facilities */
  ID = 'ID',
  /** Sort by Name option for lending facilities */
  NAME = 'NAME',
  /** Sort by Risk Level option for lending facilities */
  RISK_LEVEL = 'RISK_LEVEL'
}

/** Lending Facility state */
export enum LendingFacilityState {
  /** Active state for lending facility */
  ACTIVE = 'ACTIVE',
  /** Deleted state for lending facility */
  DELETED = 'DELETED',
  /** Inactive state for lending facility */
  INACTIVE = 'INACTIVE'
}

/** Lending facility transaction */
export type LendingFacilityTransaction = Node & {
  __typename: 'LendingFacilityTransaction';
  /** Amount for the lending facility transaction */
  amount: Amount;
  /** Asset type of the lending facility transaction */
  assetTypeInfo: AssetTypeInfo;
  /** Date represents the transactionTime in timestamp format */
  date: Scalars['Timestamp']['output'];
  /** Destinations represents the destination addresses for a transaction */
  destinations: Array<Scalars['String']['output']>;
  /** Facility  associated to this transaction */
  facility: LendingFacility;
  /** Fee for the lending facility transaction */
  fee: Maybe<Amount>;
  /** Identifier of the lending facility transaction */
  id: Scalars['ID']['output'];
  /** IgnoredForRecon represents the flag denoting whether the transaction is ignored in reconciliation */
  ignoredForRecon: Maybe<Scalars['Boolean']['output']>;
  /** Loan represents the loan that this transaction is tied to */
  loan: Maybe<Loan>;
  /** Member associated to this transaction */
  member: LendingFacilityMembership;
  /** Arbitrary JSON data for use by internal users */
  metadata: Maybe<Scalars['String']['output']>;
  /** Notes for the lending facility transaction */
  notes: Maybe<Scalars['String']['output']>;
  /** The operation associated with this transaction. */
  operationID: Maybe<Scalars['String']['output']>;
  /** Transaction payment method is either WIRE or Crypto */
  paymentMethod: Maybe<LendingFacilityTransactionPaymentMethod>;
  /** remainingOperationAmount represents the remaining operation amount */
  remainingOperationAmount: Maybe<Amount>;
  /** Sources represents the originating addresses for a transaction */
  sources: Array<Scalars['String']['output']>;
  /** Blockchain transaction ID associated with the lending facility transaction */
  transactionID: Maybe<Scalars['String']['output']>;
  /** Status of the lending facility transaction */
  transactionStatus: LendingFacilityTransactionStatus;
  /** Type of the lending facility transaction */
  transactionType: LendingFacilityTransactionType;
  /** transferRequest represents the transfer request associated with the transaction if there is one */
  transferRequest: Maybe<TransferRequest>;
  /** UserComments represents the user comments for the transaction */
  userComments: Maybe<Scalars['String']['output']>;
};

/** Lending facility transaction connection for pagination */
export type LendingFacilityTransactionConnection = {
  __typename: 'LendingFacilityTransactionConnection';
  /** List of edges containing lending facility transaction and cursor information */
  edges: Array<LendingFacilityTransactionEdge>;
  /** Page details for the associated list of lending facility transaction edges */
  pageInfo: PageInfo;
};

/** Lending facility transaction edge for pagination */
export type LendingFacilityTransactionEdge = {
  __typename: 'LendingFacilityTransactionEdge';
  /** Cursor for the associated lending facility transaction */
  cursor: Scalars['ID']['output'];
  /** Lending facility transaction */
  node: LendingFacilityTransaction;
};

/** Lending facility transaction type */
export enum LendingFacilityTransactionPaymentMethod {
  /** Crypto transaction payment */
  PAYMENT_METHOD_CRYPTO = 'PAYMENT_METHOD_CRYPTO',
  /** Wire transaction payment */
  PAYMENT_METHOD_WIRE = 'PAYMENT_METHOD_WIRE'
}

/** Sorting options for lending facility transaction */
export enum LendingFacilityTransactionSortKey {
  /** Sort by 'Asset type' option for lending facility transaction */
  ASSET_TYPE = 'ASSET_TYPE',
  /** Sort by ID option for lending facility transaction */
  ID = 'ID',
  /** Sort by 'Transaction ID' option for lending facility transaction */
  TRANSACTION_ID = 'TRANSACTION_ID',
  /** Sort by 'Transaction type' option for lending facility transaction */
  TRANSACTION_TYPE = 'TRANSACTION_TYPE'
}

/** Lending facility transaction status */
export enum LendingFacilityTransactionStatus {
  /** Approved status for lending facility transaction */
  APPROVED = 'APPROVED',
  /** Canceled transfer status for lending facility transaction */
  CANCELED = 'CANCELED',
  /** Failed status for lending facility transaction */
  FAILED = 'FAILED',
  /** Pending status for lending facility transaction */
  PENDING = 'PENDING',
  /** Pending transfer status for lending facility transaction */
  PENDING_TRANSFER = 'PENDING_TRANSFER',
  /** Processed status for lending facility transaction */
  PROCESSED = 'PROCESSED',
  /** Rejected transfer status for lending facility transaction */
  REJECTED = 'REJECTED'
}

/** Lending facility transaction type */
export enum LendingFacilityTransactionType {
  /** CLOSEOUT transaction type */
  CLOSEOUT = 'CLOSEOUT',
  /** Deposit transaction type */
  DEPOSIT = 'DEPOSIT',
  /** Interest transaction type */
  INTEREST = 'INTEREST',
  /** INTEREST_WITHDRAWAL transaction type */
  INTEREST_WITHDRAWAL = 'INTEREST_WITHDRAWAL',
  /** REINVESTMENT transaction type */
  REINVESTMENT = 'REINVESTMENT',
  /** Withdrawal transaction type */
  WITHDRAWAL = 'WITHDRAWAL'
}

/** Invoice details for invoice */
export type LendingInvoice = Node & {
  __typename: 'LendingInvoice';
  /** Comments on the invoice */
  comments: Maybe<Scalars['String']['output']>;
  /** Time invoice was created at */
  createdAt: Scalars['Timestamp']['output'];
  /** Days accrued */
  daysAccrued: Scalars['String']['output'];
  /** Due date */
  dueDate: Scalars['Date']['output'];
  /** Identifier */
  id: Scalars['ID']['output'];
  /** Interest payments page */
  interestPayments: Maybe<InterestPaymentsConnection>;
  /** Late fee total of previous pay period late fees */
  lateFeeTotal: Amount;
  /** Late Fees */
  lateFees: Array<Maybe<LateFee>>;
  /** Loan */
  loan: Loan;
  /** Arbitrary JSON data for use by internal users */
  metadata: Maybe<Scalars['String']['output']>;
  /** The amount for this period, without late fees and carried over amounts */
  periodAmount: Amount;
  /** End of invoice period */
  periodEnd: Scalars['Date']['output'];
  /** Start of invoice period */
  periodStart: Scalars['Date']['output'];
  /** Previous Invoice Remaining Amount if it exists */
  previousInvoiceRemainingAmount: Amount;
  /** The amount that's settled and unsettledAmount */
  settledAndUnsettledAmount: SettledAndUnsettledAmount;
  /** Time invoice was settled at */
  settlementDate: Maybe<Scalars['Date']['output']>;
  /** Invoice status */
  status: LendingInvoiceStatus;
  /** Total invoice amount owed */
  totalInvoiceAmount: Amount;
  /** USD value */
  usdValue: Maybe<Scalars['String']['output']>;
};


/** Invoice details for invoice */
export type LendingInvoiceinterestPaymentsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterInterestPaymentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterOperationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterPaymentDateAfter?: InputMaybe<Scalars['Date']['input']>;
  filterPaymentDateBefore?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Invoice details for invoice */
export type LendingInvoicelateFeesArgs = {
  filterAfterFeeDate?: InputMaybe<Scalars['Date']['input']>;
  filterBeforeFeeDate?: InputMaybe<Scalars['Date']['input']>;
};

/** Connection for pagination */
export type LendingInvoiceConnection = {
  __typename: 'LendingInvoiceConnection';
  /** Records */
  edges: Array<LendingInvoiceEdge>;
  /** Pagination info */
  pageInfo: PageInfo;
};

/** Edge for pagination */
export type LendingInvoiceEdge = {
  __typename: 'LendingInvoiceEdge';
  /** Record cursor */
  cursor: Scalars['ID']['output'];
  /** Record */
  node: LendingInvoice;
};

/** LendingInvoiceStatus represents the statuses of lending invoice */
export enum LendingInvoiceStatus {
  /** Invoice that was either overpaid or partially settled when a new invoice was generated. The remaing balance was carried to the new invoice */
  CARRIED_FORWARD = 'CARRIED_FORWARD',
  /** Amount paid was greater than due amount. Difference will be credited in the next invoice */
  OVERPAID = 'OVERPAID',
  /** Partially paid */
  PARTIALLY_SETTLED = 'PARTIALLY_SETTLED',
  /** Paid */
  SETTLED = 'SETTLED',
  /** Missing payment */
  UNSETTLED = 'UNSETTLED'
}

/** Lending job history record */
export type LendingJobHistoryRecord = Node & {
  __typename: 'LendingJobHistoryRecord';
  /** Time of creation of the history record */
  createdAt: Scalars['Timestamp']['output'];
  /** Job details */
  details: Scalars['String']['output'];
  /** Identifier of the lending job history record */
  id: Scalars['ID']['output'];
  /** Date of the job in timestamp format */
  jobDate: Scalars['Date']['output'];
  /** State of the job history record */
  jobState: LendingJobHistoryState;
  /** Type of the job history record */
  jobType: LendingJobHistoryType;
  /** Revert date in the case of a revert */
  revertDate: Maybe<Scalars['Date']['output']>;
};

/** Lending job history record connection for pagination */
export type LendingJobHistoryRecordConnection = {
  __typename: 'LendingJobHistoryRecordConnection';
  /** List of edges containing lending job history records and cursor information */
  edges: Array<LendingJobHistoryRecordEdge>;
  /** Page details for the associated list of lending job history record edges */
  pageInfo: PageInfo;
};

/** Lending job history record edge for pagination */
export type LendingJobHistoryRecordEdge = {
  __typename: 'LendingJobHistoryRecordEdge';
  /** Cursor for the associated lending job history record */
  cursor: Scalars['ID']['output'];
  /** Lending job history record */
  node: LendingJobHistoryRecord;
};

/** LendingJobHistoryState indicates the state of a job */
export enum LendingJobHistoryState {
  /** COMPLETED represents a completed job state */
  COMPLETED = 'COMPLETED',
  /** FAILED represents a failed job state */
  FAILED = 'FAILED',
  /** PROCESSING represents a processing job state */
  PROCESSING = 'PROCESSING',
  /** REVERTED represents a reverted job state */
  REVERTED = 'REVERTED',
  /** SCHEDULED represents a scheduled job state */
  SCHEDULED = 'SCHEDULED'
}

/** LendingJobHistoryType indicates the type of a job */
export enum LendingJobHistoryType {
  /** BORROWER_INTEREST represents a borrower interest job type */
  BORROWER_INTEREST = 'BORROWER_INTEREST',
  /** BORROWER_INVOICE represents a invoice job type */
  BORROWER_INVOICE = 'BORROWER_INVOICE',
  /** DAILY_ALL represents a daily combined (rewards and stakes) job history record type */
  DAILY_ALL = 'DAILY_ALL',
  /** DAILY_REWARD represents a daily reward job history record type */
  DAILY_REWARD = 'DAILY_REWARD',
  /** DAILY_STAKE represents a daily stake job history record type */
  DAILY_STAKE = 'DAILY_STAKE',
  /** LATE_FEE represents a late fee job type */
  LATE_FEE = 'LATE_FEE',
  /** LOAN represents a loan job type */
  LOAN = 'LOAN',
  /** MONTHLY represents a monthly job history record type */
  MONTHLY = 'MONTHLY',
  /** REVERT represents a job that reverts other jobs */
  REVERT = 'REVERT',
  /** REWARDS_APPROVAL represents a rewards approval job history record type */
  REWARDS_APPROVAL = 'REWARDS_APPROVAL'
}

/** Lending Scheduled job record */
export type LendingScheduledJobRecord = Node & {
  __typename: 'LendingScheduledJobRecord';
  /** Time of creation of the record */
  createdAt: Scalars['Timestamp']['output'];
  /** Who created the job */
  createdBy: Scalars['String']['output'];
  /** Identifier of the lending scheduled job record */
  id: Scalars['ID']['output'];
  /** State of the job record */
  jobState: LendingJobHistoryState;
  /** Type of the job record */
  jobType: LendingJobHistoryType;
  /** Time at which the job is scheduled to run */
  scheduledTime: Scalars['Timestamp']['output'];
};

/** Lending scheduled job record connection for pagination */
export type LendingScheduledJobRecordConnection = {
  __typename: 'LendingScheduledJobRecordConnection';
  /** List of edges containing lending scheduled job records and cursor information */
  edges: Array<LendingScheduledJobRecordEdge>;
  /** Page details for the associated list of lending scheduled job record edges */
  pageInfo: PageInfo;
};

/** Lending scheduled job record edge for pagination */
export type LendingScheduledJobRecordEdge = {
  __typename: 'LendingScheduledJobRecordEdge';
  /** Cursor for the associated lending scheduled job record */
  cursor: Scalars['ID']['output'];
  /** Lending scheduled job record */
  node: LendingScheduledJobRecord;
};

/** LendingSide is the entity type */
export enum LendingSide {
  /** BORROWER is lending entity type borrower */
  BORROWER = 'BORROWER',
  /** BOTH is lending entity type both lender and borrower */
  BOTH = 'BOTH',
  /** LENDER is lending entity type lender */
  LENDER = 'LENDER'
}

/** Transfer requests sorting options */
export enum LendingTransferRequestSortKey {
  /** Sort by 'Approved at' option for transfer requests */
  APPROVED_AT = 'APPROVED_AT',
  /** Sort by 'Created at' option for transfer requests */
  CREATED_AT = 'CREATED_AT',
  /** Sort by ID option for transfer requests */
  ID = 'ID'
}

export enum LiquidationInstructionType {
  COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_AT_END_CURE = 'COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_AT_END_CURE',
  COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_MARGIN_CALL_ONLY = 'COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_MARGIN_CALL_ONLY',
  COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_NONE = 'COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_NONE',
  COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_PRIORITY = 'COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_PRIORITY',
  COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_UNSPECIFIED = 'COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_UNSPECIFIED'
}

/** LiquidityVenue represents a venue where liquidity can be obtained for trading */
export type LiquidityVenue = {
  __typename: 'LiquidityVenue';
  /** displayName is the user facing identifier for a liquidity venue(e.g. Coinbase Exchange) */
  displayName: Scalars['String']['output'];
  /** name is the model/backend facing identifier for a liquidity venue(e.g. coinbaseexchange) */
  name: Scalars['String']['output'];
};

/** Loan encapsulates loan information */
export type Loan = Node & {
  __typename: 'Loan';
  /** Allocated collateral page */
  allocatedCollateral: Maybe<AllocatedCollateralConnection>;
  /** Amount of the loan at origination */
  amountAtOrigination: Amount;
  /** Amount snapshots page */
  amountSnapshots: LoanAmountSnapshotConnection;
  /** Loan approval type */
  approvalType: LoanApprovalType;
  /** Borrower interest snapshot page */
  borrowerInterestSnapshots: BorrowerInterestSnapshotConnection;
  /** Loan collateral call ratios */
  ccrLevel: CCRLevel;
  /** closedAt is the date the loan closes at */
  closedAt: Maybe<Scalars['Date']['output']>;
  /** Collateral agreement id associated with loan term */
  collateralAgreementID: Maybe<Scalars['String']['output']>;
  /** Collateral exposure id associated with loan term */
  collateralExposureID: Maybe<Scalars['String']['output']>;
  /** Comments on the loan */
  comments: Maybe<Scalars['String']['output']>;
  /** URL of MLSA sheet */
  docLink: Maybe<Scalars['String']['output']>;
  /** Entity Symbol */
  entitySymbol: Scalars['String']['output'];
  /** Relates spreadsheet ID with loan */
  externalID: Maybe<Scalars['String']['output']>;
  /** Lending facility membership which owns the loan */
  facilityMember: LendingFacilityMembership;
  /** Loan identifier */
  id: Scalars['ID']['output'];
  /** Loan interest rate */
  interestRate: Scalars['Decimal']['output'];
  /** Total accrued interest for loan */
  interestToDate: Maybe<Amount>;
  /** isMonitored specifies if the loan collateral exposure is beign monitored */
  isMonitored: Maybe<Scalars['Boolean']['output']>;
  /** The current loan value */
  latestSnapshotAmount: Maybe<Amount>;
  /** The current loan value in USD */
  latestSnapshotUSDValue: Maybe<Scalars['Decimal']['output']>;
  /** Liquidity proof required */
  liquidityProof: Scalars['Boolean']['output'];
  /** Loan type */
  loanType: LoanType;
  /** Loan maturity date */
  maturityDate: Maybe<Scalars['Date']['output']>;
  /** Arbitrary JSON data for use by internal users */
  metadata: Maybe<Scalars['String']['output']>;
  /** monthToDateInterest specifies how much interest has been accrued this month */
  monthToDateInterest: Maybe<Amount>;
  /** origination date of the loan */
  originationDate: Maybe<Scalars['Date']['output']>;
  /** originationEntity is the source of origination */
  originationEntity: Scalars['String']['output'];
  /**
   * PendingAmount represents the current loan amount accouting approved
   * deposit and withrawal transaction associated with the loan id
   */
  pendingAmount: Maybe<Amount>;
  /** ID of previous loan term */
  previousLoan: Maybe<Loan>;
  /** Rehypothecation state */
  rehypothecation: Scalars['Boolean']['output'];
  /** Is this a secured loan or not */
  secured: Maybe<Scalars['Boolean']['output']>;
  /** Loan status */
  status: LoanTermStatus;
  /** URL of term sheet */
  termSheetLocator: Scalars['String']['output'];
  /** USD exchange rate of the loan */
  usdExchangeRate: Maybe<Scalars['Decimal']['output']>;
};


/** Loan encapsulates loan information */
export type LoanallocatedCollateralArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterCollateralStatus?: InputMaybe<Array<CollateralStatus>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Loan encapsulates loan information */
export type LoanamountSnapshotsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAfterSnapshotDate?: InputMaybe<Scalars['Date']['input']>;
  filterBeforeSnapshotDate?: InputMaybe<Scalars['Date']['input']>;
  filterExactSnapshotDate?: InputMaybe<Scalars['Date']['input']>;
  filterStatus?: InputMaybe<Array<InputMaybe<LendingFacilityTransactionStatus>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Loan encapsulates loan information */
export type LoanborrowerInterestSnapshotsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAfterSnapshotDate?: InputMaybe<Scalars['Date']['input']>;
  filterBeforeSnapshotDate?: InputMaybe<Scalars['Date']['input']>;
  filterFacilityMemberIDs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  filterLoanTermIDs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  filterStatus?: InputMaybe<Array<InputMaybe<LendingFacilityTransactionStatus>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Current state of loaned amount */
export type LoanAmountSnapshot = Node & {
  __typename: 'LoanAmountSnapshot';
  /** Loaned amount */
  amount: Amount;
  /** When the snapshot was created */
  createdAt: Scalars['Timestamp']['output'];
  /** Identifier */
  id: Scalars['ID']['output'];
  /** Date for amount snapshot */
  snapshotDate: Scalars['Date']['output'];
  /** Status of the snapshot */
  status: LendingFacilityTransactionStatus;
};

/** Loan amount connection for pagination */
export type LoanAmountSnapshotConnection = {
  __typename: 'LoanAmountSnapshotConnection';
  /** Records */
  edges: Array<LoanAmountSnapshotEdge>;
  /** Pagination info */
  pageInfo: PageInfo;
};

/** Loan amount edge for pagination */
export type LoanAmountSnapshotEdge = {
  __typename: 'LoanAmountSnapshotEdge';
  /** Cursor for record */
  cursor: Scalars['ID']['output'];
  /** Record */
  node: LoanAmountSnapshot;
};

/** Approval type for Loan */
export enum LoanApprovalType {
  /** Pre approved type */
  PRE_APPROVED = 'PRE_APPROVED',
  /** Three approvals required */
  THREE_APPROVALS = 'THREE_APPROVALS'
}

/** Loan connection for paginations */
export type LoanConnection = {
  __typename: 'LoanConnection';
  /** Loans edges */
  edges: Array<LoanEdge>;
  /** Loan pagination info */
  pageInfo: PageInfo;
};

/** Loan edge for pagination */
export type LoanEdge = {
  __typename: 'LoanEdge';
  /** Cursor for loan record */
  cursor: Scalars['ID']['output'];
  /** Loan record */
  node: Loan;
};

/** Filters for  the loan object */
export type LoanFilters = {
  /** Filter for all loans after this closed At date */
  filterAfterClosedAt?: InputMaybe<Scalars['Date']['input']>;
  /** Filter for all loans after this maturity date */
  filterAfterMaturityDate?: InputMaybe<Scalars['Date']['input']>;
  /** Filter for all loans after this origination date */
  filterAfterOriginationDate?: InputMaybe<Scalars['Date']['input']>;
  /** Filter for all loans after this createdTime */
  filterAfterTimestamp?: InputMaybe<Scalars['Date']['input']>;
  /** Filter by approval type */
  filterApprovalType?: InputMaybe<Array<LoanApprovalType>>;
  /** Filter for all loans beffor this closed at date */
  filterBeforeClosedAt?: InputMaybe<Scalars['Date']['input']>;
  /** Filter for all loans before this maturity date */
  filterBeforeMaturityDate?: InputMaybe<Scalars['Date']['input']>;
  /** Filter for all loans before this origination date */
  filterBeforeOriginationDate?: InputMaybe<Scalars['Date']['input']>;
  /** Filter for all loans before this createdAt time */
  filterBeforeTimestamp?: InputMaybe<Scalars['Date']['input']>;
  /** Filter by Entity Symbol */
  filterEntitySymbol?: InputMaybe<Scalars['String']['input']>;
  /** Filter for all loans that are this exact origination date */
  filterExactOriginationDate?: InputMaybe<Scalars['Date']['input']>;
  /** Filter by facility ID */
  filterFacilityID?: InputMaybe<Scalars['ID']['input']>;
  /** Filter by facility member ID */
  filterFacilityMemberID?: InputMaybe<Scalars['ID']['input']>;
  /** Filter for liquidity proof */
  filterHasLiquidityProof?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter for loans being monitored for collateral exposure */
  filterIsMonitored?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter for all loans after before closed At date */
  filterIsOpenTerm?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter for all loans on if this is a refi loan */
  filterIsRefinanced?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by lending entity ID */
  filterLendingEntityID?: InputMaybe<Scalars['ID']['input']>;
  /** Filter by lending entity Name */
  filterLendingEntityName?: InputMaybe<Scalars['String']['input']>;
  /** Filter by Loan IDs */
  filterLoanIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by loan type */
  filterLoanType?: InputMaybe<Array<LoanType>>;
  /** Filter by if loan is available for rehypothecation */
  filterRehypothecation?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by loan status */
  filterStatus?: InputMaybe<Array<LoanTermStatus>>;
};

/** Loan term status */
export enum LoanTermStatus {
  /** Loan active */
  ACTIVE = 'ACTIVE',
  /** Loan canceled */
  CANCELED = 'CANCELED',
  /** Loan paid/closed */
  CLOSED = 'CLOSED',
  /** Awaiting collateral */
  COLLATERAL_PENDING = 'COLLATERAL_PENDING',
  /** Loan denied */
  DENIED = 'DENIED',
  /** Pending approval */
  PENDING = 'PENDING',
  /** Approved by risk */
  RISK_APPROVED = 'RISK_APPROVED'
}

/** Loans terms input */
export type LoanTermsInput = {
  /** amountAtOrigination represents the number of units in facility asset type that the loan was initially originated at */
  amountAtOrigination?: InputMaybe<Scalars['Decimal']['input']>;
  /** entitySymbol represents the name of the loan that lending desk knows */
  entitySymbol?: InputMaybe<Scalars['String']['input']>;
  /** Interest rate */
  interestRate?: InputMaybe<Scalars['Decimal']['input']>;
  /** Liquidation CCR */
  liquidationCCR?: InputMaybe<Scalars['Decimal']['input']>;
  /** Margin call CCR */
  marginCallCCR?: InputMaybe<Scalars['Decimal']['input']>;
  /** Maturity date */
  maturityDate?: InputMaybe<Scalars['Date']['input']>;
  /** Arbitrary JSON data for use by internal users */
  metadata?: InputMaybe<Scalars['String']['input']>;
  /** Nominal CCR */
  nominalCCR?: InputMaybe<Scalars['Decimal']['input']>;
  /** Origination Date */
  originationDate?: InputMaybe<Scalars['Date']['input']>;
  /** Pre margin call CCR */
  preMarginCallCCR?: InputMaybe<Scalars['Decimal']['input']>;
  /** Recovery CCR */
  recoveryCCR?: InputMaybe<Scalars['Decimal']['input']>;
  /** Rehypothecation */
  rehypothecatable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Reverse margin call CCR */
  reverseMarginCallCCR?: InputMaybe<Scalars['Decimal']['input']>;
  /** Term sheet locator */
  termSheetLocator?: InputMaybe<Scalars['String']['input']>;
  /**
   * usdExchangeRate represents the exchange rate used when the loan was initiated
   * this is an optional input, if not provided it will be computed on the backend
   * based on the originationDate. If the origination is today, we'll use the current
   * asset price at the loan creation time. Else, we'll be using the close price
   * of the origination date.
   */
  usdExchangeRate?: InputMaybe<Scalars['Decimal']['input']>;
};

/** Loan types */
export enum LoanType {
  /** Regular default loan */
  DEFAULT = 'DEFAULT',
  /** FCM loan */
  FCM = 'FCM',
  /** Filecoin loan */
  FILECOIN = 'FILECOIN',
  /** Mining loan */
  MINING_LEASE = 'MINING_LEASE',
  /** Share backed loan */
  SHARE_BACKED = 'SHARE_BACKED'
}

/** LockOperation represents a Lock operation */
export type LockOperation = Operation & {
  __typename: 'LockOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** amount is the Amount that is being locked up */
  amount: Amount;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /** The asset associated with this operation */
  asset: Asset;
  /** asset id from with the authorization was made */
  assetID: AssetID;
  /** Further information regarding this asset type associated with this operation */
  assetTypeInfo: AssetTypeInfo;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** canonicalOperationVersion is the version of the canonical authorization that must be used to sign this authorization */
  canonicalOperationVersion: Scalars['Int']['output'];
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** confirmations returns how many confirmations the transaction associated with this authorization has received */
  confirmations: Maybe<Scalars['Int']['output']>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * extraParams are additional asset type specific parameters that were set for this transaction
   * When constructing the canonical operation for signing, the extra params go in the ExtraParams field
   * as a map from asset type id (usually, parent asset type id) to params object. The convention of extra
   * params is to omit all empty fields, and if no extra params are set for an asset type, to omit the asset
   * type from the map entirely. Note that for legacy reasons this convention
   * is the opposite of the normal CanonicalOperation convention, where no fields are ever omitted.
   */
  extraParams: Maybe<ExtraParams>;
  /** final transaction fee charged for the authorization, detected on the blockchain */
  finalFee: Maybe<Amount>;
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** max transaction fee charged for the authorization */
  maxFee: Maybe<Amount>;
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * policy that applies to this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /**
   * replayProtectionParams are the parameters to be sent to the HSM for creating and signing
   * the transactions required to effect this ExecuteOperation. The user *is not* expected
   * to review the contents of this field. However it may contain information necessary to prevent
   * replays and must be included in the payload signed by the user. Format is a hex-encoded
   * byte array.
   */
  replayProtectionParams: Maybe<Scalars['String']['output']>;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /** sendingBlockchainAddresses is a list of addresses from which a lock operation is sent */
  sendingBlockchainAddresses: Array<BlockchainAddress>;
  /** signedTransaction is the blockchain transaction to be broadcast to the network */
  signedTransaction: Maybe<Scalars['String']['output']>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /**
   * sources are the originating addresses for the authorization
   * @deprecated use sendingBlockchainAddresses
   */
  sources: Array<Source>;
  /** transactionID is the blockchain transaction id once available */
  transactionID: Maybe<Scalars['String']['output']>;
  /** transactionSuccess indicates if the blockchain transaction succeeded */
  transactionSuccess: Maybe<Scalars['Boolean']['output']>;
  /**
   * usdValueFee is the USD value of the fee (finalFee if known, otherwise maxFee).
   * If both the finalFee and maxFee are not yet known, this field will be null
   */
  usdValueFee: Maybe<Scalars['String']['output']>;
  /** viewTransactionLink is the link to get more info about the transaction from a third party */
  viewTransactionLink: Maybe<Scalars['String']['output']>;
};


/** LockOperation represents a Lock operation */
export type LockOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** LockOperation represents a Lock operation */
export type LockOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/** This list should match the list in lib/std/logger/logger.go */
export enum LoggerComponent {
  /** component defined in logger.go */
  AUTHORIZATION = 'AUTHORIZATION',
  /** component defined in logger.go */
  BILLING = 'BILLING',
  /** component defined in logger.go */
  BLOCKCHAIN = 'BLOCKCHAIN',
  /** component defined in logger.go */
  CLIENT_ENTITIES = 'CLIENT_ENTITIES',
  /** component defined in logger.go */
  COLLATERAL = 'COLLATERAL',
  /** component defined in logger.go */
  COMPLIANCE_ALERTS = 'COMPLIANCE_ALERTS',
  /** component defined in logger.go */
  CUSTODY = 'CUSTODY',
  /** component defined in logger.go */
  DEPOSIT_ATTRIBUTION = 'DEPOSIT_ATTRIBUTION',
  /** component defined in logger.go */
  IDENTITY = 'IDENTITY',
  /** component defined in logger.go */
  KYC = 'KYC',
  /** component defined in logger.go */
  LENDING = 'LENDING',
  /** component defined in logger.go */
  NOTIFICATION = 'NOTIFICATION',
  /** component defined in logger.go */
  ORGANIZATION = 'ORGANIZATION',
  /** component defined in logger.go */
  REPORTING = 'REPORTING',
  /** component defined in logger.go */
  RISK_REVIEW = 'RISK_REVIEW',
  /** component defined in logger.go */
  SETTLEMENT_NETWORK = 'SETTLEMENT_NETWORK',
  /** component defined in logger.go */
  TAX = 'TAX',
  /** component defined in logger.go */
  TRADING = 'TRADING'
}

/** Login information */
export type Login = {
  __typename: 'Login';
  /** loginMetadata contains data about this login */
  loginMetadata: Maybe<LoginMetadata>;
};

/** LoginMetadata contains all of the metadata gathered about this login */
export type LoginMetadata = {
  __typename: 'LoginMetadata';
  /** altitude is the altitude measured by the device */
  altitude: Scalars['String']['output'];
  /** App version running on the device */
  appVersion: Scalars['String']['output'];
  /** level of the battery in percentage */
  batteryLevel: Scalars['String']['output'];
  /** biometricFingerprint is a hash of the the biometrics data on the device */
  biometricFingerprint: Scalars['String']['output'];
  /** biometricType is the type of biometics used to authenticate to the secure enclave on the device */
  biometricType: Scalars['String']['output'];
  /** bluetooth information */
  bluetooth: Bluetooth;
  /** Carrier information */
  carrier: Carrier;
  /** createdAt is the time at which the data was gathered */
  createdAt: Scalars['Timestamp']['output'];
  /** deviceInfo is the name of the device */
  deviceInfo: Scalars['String']['output'];
  /** Device type */
  deviceType: Scalars['String']['output'];
  /** IP address from which the login was made (can be empty string for old logins) */
  ipAddress: Scalars['String']['output'];
  /** latitude is the latitude from GPS */
  latitude: Scalars['String']['output'];
  /** longitude is the longitude from GPS */
  longitude: Scalars['String']['output'];
  /** Model of the device */
  model: Scalars['String']['output'];
  /** list of network interfaces active */
  networkInterfaces: Array<RiskMetadataNetworkInterface>;
  /** information regarding pedometer */
  pedometer: Pedometer;
  /** rawMetadata is the unprocessed data sent from the phone */
  rawMetadata: Scalars['String']['output'];
  /**
   * number of steps. -1 if unknown.
   * @deprecated use pedometer field instead
   */
  steps: Scalars['Int']['output'];
  /** System name in use in the device */
  systemName: Scalars['String']['output'];
  /** System version in use in the device */
  systemVersion: Scalars['String']['output'];
  /** list of wifi names active */
  wifi: Array<Scalars['String']['output']>;
};

/** Lost device report */
export type LostDevice = {
  __typename: 'LostDevice';
  /** When was the loss reported. This is a timestamp with nanosecond precision */
  lostAt: Scalars['String']['output'];
  /** Organization that the device belongs to */
  organization: Organization;
  /** Reporter of the loss */
  reporter: Scalars['String']['output'];
  /** User associated with the lost device */
  user: User;
};

/** Mailing Address */
export type MailingAddress = {
  __typename: 'MailingAddress';
  /** Address line 1 */
  address1: Scalars['String']['output'];
  /** Address line 2 */
  address2: Maybe<Scalars['String']['output']>;
  /** City */
  city: Scalars['String']['output'];
  /** Country */
  country: Scalars['String']['output'];
  /** Province */
  province: Scalars['String']['output'];
  /** Zip Code */
  zipCode: Scalars['String']['output'];
};

/** Extra parameters for maker */
export type MakerExtraParams = {
  __typename: 'MakerExtraParams';
  /** toggle for IOU or MKR authorize spender transaction */
  isAuthorizingIOUToken: Maybe<Scalars['Boolean']['output']>;
  /** for polling: the ID of the option being voted on */
  optionID: Maybe<Scalars['Int']['output']>;
  /** for polling: the ID of the poll being voted on */
  pollID: Maybe<Scalars['Int']['output']>;
  /** what type of vote: EXECUTIVE for votes which affect the blockchain, POLL for votes that don't */
  voteType: Maybe<Scalars['String']['output']>;
};

/** The top-level struct returned by the Maker governance dashboard. */
export type MakerGovernanceBallot = {
  __typename: 'MakerGovernanceBallot';
  /** Whether this ballot is still active */
  active: Scalars['Boolean']['output'];
  /** Timestamp when the ballot finished. Only significant when 'active' is false */
  endedAt: Scalars['String']['output'];
  /** The proposals to vote on in this ballot */
  proposals: Array<MakerGovernanceProposal>;
  /**
   * Timestamp when the ballot was created.
   * @deprecated The date the Ballot was created is not useful information. Use MakerGovernanceProposal.startedAt instead.
   */
  startedAt: Scalars['String']['output'];
  /**
   * Should always be 'Governance: Executive Vote' for the current active ballot. May have
   * different values for past ballots.
   */
  topic: Scalars['String']['output'];
  /** ex: 'Here you can vote to approve a raise to the Dai Credit System's governance fee.' */
  topicBlurb: Scalars['String']['output'];
};

/** MakerGovernanceProposal is a single proposal within a ballot */
export type MakerGovernanceProposal = {
  __typename: 'MakerGovernanceProposal';
  /**
   * This is a big, multi-paragraph markdown string describing both this specific proposal and
   * including information about Maker govenrance voting in general. Might as well just render
   * the whole thing for the client, as does Maker's governance dashboard.
   */
  about: Scalars['String']['output'];
  /** The timestamp at which this was executed. It will be empty if it hasn't been executed. */
  executedAt: Maybe<Scalars['String']['output']>;
  /** ex: 'Vote for this proposal to lower the Stability fee by 2% to a total of 14.5% per year' */
  proposalBlurb: Scalars['String']['output'];
  /**
   * This is the Ethereum address of the proposal (a ds-spell contract), and is what should be
   * submitted in the `voteYeas` array of a Maker vote operation
   */
  source: Scalars['String']['output'];
  /** Timestamp when the proposal was proposed. */
  startedAt: Scalars['String']['output'];
  /** ex: 'Keep the Stability Fee set to a total of 14.5% per year' */
  title: Scalars['String']['output'];
  /** voteStatus is the voting status of the proposal */
  voteStatus: ProposalVoteStatus;
};

/** MakerPoll represents one poll with multiple voting options */
export type MakerPoll = {
  __typename: 'MakerPoll';
  /**
   * This is a big, multi-paragraph markdown string describing both this specific proposal and
   * including information about Maker polling in general. Similar to executive votes, we will
   * render the whole thing for the client.
   */
  about: Scalars['String']['output'];
  /** currentVote represent the current voted option, if user has voted */
  currentVote: Maybe<CurrentVote>;
  /** The timestamp at which voting will end for this poll in UnixNano */
  endTime: Scalars['String']['output'];
  /** Each option has an OptionID and the value it represents */
  options: Array<MakerPollOption>;
  /** The PollID of this poll */
  pollID: Scalars['Int']['output'];
  /** ex: 'Signal your support to adjust the Stability Fee within a range of 1% to 9%' */
  summary: Scalars['String']['output'];
  /** ex: 'Stability Fee Adjustment - November 11, 2019' */
  title: Scalars['String']['output'];
  /**
   * The timestamp at which we most recently pulled information about this poll, since we will
   * have a caching layer for some info.
   */
  updatedAt: Scalars['String']['output'];
};

/** Maker polling-specific details */
export type MakerPollDetails = {
  __typename: 'MakerPollDetails';
  /** The Maker poll associated with the operation */
  makerPoll: MakerPoll;
};

/** MakerPollOption represents one voting option in a poll */
export type MakerPollOption = {
  __typename: 'MakerPollOption';
  /** This could be a number for a fee percent, yes/no, etc. String is generic enough */
  description: Scalars['String']['output'];
  /** The integer corresponding to the option */
  optionID: Scalars['Int']['output'];
  /** A decimal of the amount of MKR supporting this option */
  supportAmount: Scalars['String']['output'];
};

/** Maker executive voting-specific details */
export type MakerVoteDetails = {
  __typename: 'MakerVoteDetails';
  /** Returns the Maker governance executive vote ballot active when this operation was created, if one exists. */
  makerBallot: MakerGovernanceBallot;
};

/** ManagedAddress contains information that is relevant to addresses managed by Anchorage */
export type ManagedAddress = {
  __typename: 'ManagedAddress';
  /** The time of creation of this address. This is marked as the time that the address is made visible to the client - this will be null if the address is not marked as used */
  createdAt: Maybe<Scalars['String']['output']>;
  /** A list of wallets associated with this managed address. Usually a single wallet, but some addresses, such as vesting contracts may be associated with multiple wallets. */
  wallets: Array<Wallet>;
};

/** ManualTradeRequestParams contains all trade values in format provided during registering manual trade */
export type ManualTradeRequestParams = {
  __typename: 'ManualTradeRequestParams';
  /** Amount for asset bought */
  AmountBought: Scalars['String']['output'];
  /** Amount of asset sold */
  AmountSold: Scalars['String']['output'];
  /** Type of asset bought */
  AssetBought: Scalars['String']['output'];
  /** Type of asset sold */
  AssetSold: Scalars['String']['output'];
  /** Timestamp trade was created at */
  CreatedAt: Scalars['Timestamp']['output'];
  /** Fee */
  Fee: Scalars['String']['output'];
  /** Fee currency */
  FeeCurrency: Scalars['String']['output'];
  /** Organization key ID for the trade */
  OrganizationKeyID: Scalars['String']['output'];
  /** Price */
  Price: Scalars['String']['output'];
  /** Trade ID for the trade */
  TradeID: Scalars['String']['output'];
  /** Capacity in which Anchorage acted on this trade */
  capacity: TradeCapacity;
};

/** Marketing Widget */
export type MarketingWidget = {
  __typename: 'MarketingWidget';
  /** Body of the widget as text */
  body: Scalars['String']['output'];
  /**
   * ctaText is a customizable call-to-action text for the action
   * @deprecated This field was split into two. Use either 'webCtaText' or 'iOSCtaText' instead
   */
  ctaText: Scalars['String']['output'];
  /**
   * displayPage is an indentifier for which page this widget should appear on:
   *   - dashboard
   *   - vault
   *   - wallet_list
   *   - wallet_detail
   */
  displayPage: Maybe<Scalars['DisplayPage']['output']>;
  /** iOSCtaText is a customizable call-to-action text for the action in the iOS app */
  iOSCtaText: Scalars['String']['output'];
  /** iOSUrl is the link when users click in the iOS app. */
  iOSURL: Scalars['String']['output'];
  /** Identifier of the marketing widget */
  id: Scalars['ID']['output'];
  /** imageURL is points to an existing image */
  imageURL: Scalars['String']['output'];
  /** isSuppressible, when true, indicates the user can dismiss this widget */
  isSupressible: Scalars['Boolean']['output'];
  /** Title of the widget */
  title: Scalars['String']['output'];
  /** webCtaText is a customizable call-to-action text for the web action */
  webCtaText: Scalars['String']['output'];
  /** webURL is for link when users click through the web */
  webURL: Scalars['String']['output'];
  /** widgetType indicates the type of a widget to the front end (e.g. modal, banner) */
  widgetType: WidgetType;
};

/** Method indicates the fx rate used in RFQ */
export enum Method {
  /** Direct method for FX rate calculation */
  DIRECT = 'DIRECT',
  /** Synthetic method for FX rate calculation */
  SYNTHETIC = 'SYNTHETIC'
}

/** Collateral encapsulation for mining */
export type MiningCollateralDetails = Node & {
  __typename: 'MiningCollateralDetails';
  /** Identifier */
  id: Scalars['ID']['output'];
  /** Implied value for collateral */
  impliedValue: Amount;
  /** Metadata */
  metadata: Scalars['String']['output'];
  /** Timestamp */
  timestamp: Scalars['Timestamp']['output'];
  /** Transaction type */
  transactionType: CollateralTransactionType;
};

/** Connection for pagination */
export type MiningCollateralDetailsConnection = {
  __typename: 'MiningCollateralDetailsConnection';
  /** Records */
  edges: Array<MiningCollateralDetailsEdge>;
  /** Pagination info */
  pageInfo: PageInfo;
};

/** Edge for pagination */
export type MiningCollateralDetailsEdge = {
  __typename: 'MiningCollateralDetailsEdge';
  /** Record cursor */
  cursor: Scalars['ID']['output'];
  /** Record */
  node: MiningCollateralDetails;
};

/** Mining collateral input */
export type MiningCollateralInput = {
  /** Amount */
  amount: Scalars['Decimal']['input'];
  /** Asset typr */
  assetTypeID: Scalars['AssetTypeID']['input'];
  /** Created at */
  createdAt: Scalars['Timestamp']['input'];
  /** Created by */
  createdBy: Scalars['String']['input'];
  /** Implied value */
  impliedValue: Scalars['Decimal']['input'];
  /** Transaction type */
  transactionType: CollateralTransactionType;
  /** Updated at */
  updatedAt: Scalars['Timestamp']['input'];
  /** Updated by */
  updatedBy: Scalars['String']['input'];
};

/** MiningEquipmentAccountMetadata holds metadata of mining equipments accounts */
export type MiningEquipmentAccountMetadata = {
  __typename: 'MiningEquipmentAccountMetadata';
  accountName: Maybe<Scalars['String']['output']>;
};

/** A mint or burn conversion. */
export type MintBurnConversion = Node & {
  __typename: 'MintBurnConversion';
  /** Authorization Operation ID */
  authorizationOperationID: Maybe<Scalars['OperationID']['output']>;
  /** Unique mintburn conversion identifier */
  id: Scalars['ID']['output'];
};

/** MonthlyLendingJobRequestType indicates which monthly jobs to run */
export enum MonthlyLendingJobRequestType {
  /** BORROWER_INVOICE indicates borrower invoice job */
  BORROWER_INVOICE = 'BORROWER_INVOICE',
  /** REINVESTMENT indicates lender reward reinvestment job */
  REINVESTMENT = 'REINVESTMENT'
}

/** Mutation exposes all methods that allow information to be updated on the backend */
export type Mutation = {
  __typename: 'Mutation';
  /**
   * Accept one disclosure. There should be one call per DisclosureReview from the
   * disclosures query.
   *
   * Returns true if acceptance was saved or if acceptance already exists.
   * Returns false if an error occurred. If there is an error, it will also be sent to Sentry
   */
  acceptDisclosure: Scalars['Boolean']['output'];
  /**
   * ackWalletKeyDownload should be called whenver a WalletKey, identified by the given walletKeyID from the WalletKeyBackup type, was successfully downloaded.
   * ackWalletKeyDownload will unlock the wallet for use (NOOP if already unlocked.)
   * ackWalletKeyDownload returns nothing on success (or error.)
   */
  ackWalletKeyDownload: Scalars['Boolean']['output'];
  /**
   * Adds a representative to the affiliate
   *
   * If the representative exists, the name is ignored, otherwise it is required.
   */
  addAffiliateRepresentative: Scalars['Boolean']['output'];
  /** addClientAffiliateType adds an affiliate type for the client when an affiliate fills a specific questionnaire id */
  addClientAffiliateType: Scalars['Boolean']['output'];
  /**
   * Adds a representative to the client
   *
   * If the representative exists, the name is ignored, otherwise it is required.
   */
  addClientRepresentative: Scalars['Boolean']['output'];
  /**
   * Allows adding individuals to an institution with one or more relationships.
   * Ordering of relationships doesn't matter.
   *
   * first, middle, and last must all be filled out. If middle name
   * is not filled out, an empty string must be submitted for middleName
   * so that we have an explicit record that the middle name was not filled out.
   */
  addIndividualAndRelationships: Array<IndividualInformationRejection>;
  /**
   * Adds a representative to an individual in a client
   *
   * If the representative exists, the name is ignored, otherwise it is required.
   */
  addIndividualRepresentativeInClient: Scalars['Boolean']['output'];
  /** Allows adding related parties to an affiliate. */
  addRelatedPartyRelationships: Scalars['Boolean']['output'];
  /**
   * Handles the submission of brand new values of KYC information to amend old ones.
   * If successful, the audit trail ID related with the change gets returned.
   */
  amendKYCInformation: Maybe<Scalars['ID']['output']>;
  /** Allows a risk reviewer to approve the information of a affiliate */
  approveAffiliate: Scalars['Boolean']['output'];
  /** Allows a risk reviewer to approve the information of a client */
  approveClient: Scalars['Boolean']['output'];
  /** Allows a risk reviewer to approve the information of an individual */
  approveIndividualInClient: Scalars['Boolean']['output'];
  /** Approves the given standing instruction if in PENDING state */
  approvesStandingInstruction: Scalars['Boolean']['output'];
  /**
   * archiveWallets archives wallets identified by the given IDs.
   * Returns error if walletIDs is empty.
   * Returns no error if nothing is updated (eg, no wallet matches, or all matching wallets
   * already are in "archived" state.)
   */
  archiveWallets: ArchiveWalletsResponse;
  /** Assign Funds to a Program Customer from the Unassigned Subaccount */
  assignUnassignedFunds: Scalars['Boolean']['output'];
  attributeDepositsAsSpam: Scalars['Boolean']['output'];
  /** Create initial collateral agreement IDs for loans */
  backfillCollateralAgreementIDs: Scalars['Boolean']['output'];
  /** Create initial collateral exposure IDs for loans */
  backfillCollateralExposureIDs: Scalars['Boolean']['output'];
  /** Create initial parties for collateral */
  backfillCollateralPartyIDs: Scalars['Boolean']['output'];
  /** Tries to backfill facility transaction metadata from the on chain operations; Internal use only */
  backfillLendingFacilityTransactionMetadata: Scalars['String']['output'];
  /** Tries to backfill interest payment metadata from the on chain operations; Internal use only */
  backfillLendingInterestPaymentMetadata: Scalars['String']['output'];
  /** Backfill USD values for snapshots */
  backfillPriceMemberStakesSnapshot: Scalars['Boolean']['output'];
  /** Endorse multiple operations. */
  batchEndorse: Scalars['Boolean']['output'];
  /** Book a quote for a trade */
  blockPricerBookQuote: BookQuoteOutput;
  /** Execute a Request for Quote (RFQ) operation */
  blockPricerRfq: RFQOutput;
  /** cancelManualTrade allows to cancel a manual trade. */
  cancelManualTrade: Scalars['Boolean']['output'];
  /** Cancel an offchain vesting schedule and release its holds */
  cancelOffchainVestingSchedule: Scalars['Boolean']['output'];
  /**
   * Sends a request to cancel an operation.
   * This API is async, and safe to call repeatedly.
   * The API call will return a code indicating the status of the cancelation:
   * - OK: means the operation has been canceled successfully.
   * - ACCEPTED: means the request has been successfully received but not yet processed.
   *
   * Sample client errors:
   * - org does not have "Allow Op-Cancelation" feature enabled.
   * - operation is in non-cancelable state (eg, COMPLETE).
   * - operation is of non-cancelable type (eg, GAS_STATION; non-execute).
   */
  cancelOperationAsync: CancelOperationResponse;
  /**
   * Request the creation of a role change operation
   * It returns a generic operation for developer friendliness but it will
   * always be of type "ChangeRoleOperation"
   */
  changeRole: Operation;
  /**
   * Clears all disclosure acceptances, regardless of org/account/disclosure/version, for the User in the context.
   *
   * Returns true unless error was encountered.
   */
  clearDisclosureAcceptances: Scalars['Boolean']['output'];
  /** Approve a client dashboard login */
  clientDashboardLogin: Scalars['Boolean']['output'];
  /** Log out of all client dashboard sessions for this user */
  clientDashboardLogout: Scalars['Boolean']['output'];
  /** Close account(s) */
  closeAccounts: Scalars['Boolean']['output'];
  /** Closes an affiliate */
  closeAffiliate: Scalars['Boolean']['output'];
  /** Add a new collateral agreement */
  collateralCreateAgreement: Scalars['ID']['output'];
  /** Create an asset spec */
  collateralCreateAssetSpec: Scalars['ID']['output'];
  /** Create a collateral call */
  collateralCreateCall: Scalars['ID']['output'];
  /** Add new record in the fees ledger */
  collateralCreateFee: Scalars['ID']['output'];
  /** Add new record in the inventory ledger */
  collateralCreateInventory: Scalars['ID']['output'];
  /** Add new record in the rehypo ledger */
  collateralCreateRehypoLedger: Scalars['ID']['output'];
  /** Soft delete a collateral agreement */
  collateralDeleteAgreement: Scalars['Boolean']['output'];
  /** Soft delete an asset spec */
  collateralDeleteAssetSpec: Scalars['Boolean']['output'];
  /** Soft delete a record in the fees ledger */
  collateralDeleteFee: Scalars['Boolean']['output'];
  /** Soft delete a record in the inventory ledger */
  collateralDeleteInventory: Scalars['Boolean']['output'];
  /** ReIssue a collateral call */
  collateralReIssueCall: Scalars['ID']['output'];
  /** Update a collateral agreement */
  collateralUpdateAgreement: Scalars['Boolean']['output'];
  /** Update an asset spec */
  collateralUpdateAssetSpec: Scalars['Boolean']['output'];
  /** Update a collateral call */
  collateralUpdateCall: Scalars['Boolean']['output'];
  /** Update a record in the fees ledger */
  collateralUpdateFee: Scalars['Boolean']['output'];
  /** Update a record in the inventory ledger */
  collateralUpdateInventory: Scalars['Boolean']['output'];
  /** Update a record in the rehypo ledger */
  collateralUpdateRehypoLedger: Scalars['Boolean']['output'];
  /** Update Transaction Monitoring configurations for a given rule */
  complianceUpdateTMSConfigs: Scalars['Boolean']['output'];
  /** Create specific API Key for transfers for a given domain (lending / brokerage / other) */
  createAPIKeyForTransfers: Operation;
  /**
   * Create and endorse an operation at the same time
   * @deprecated Use endorse
   */
  createAndEndorse: Scalars['Boolean']['output'];
  /**
   * Request the preparation of a claim vested one-off operation.
   * It returns a generic operation for developer friendliness but it will
   * always be of type "OneOffOperation".
   * @deprecated use the prepare version of this mutation
   */
  createClaimFromVestingContract: Operation;
  /** Creates a comment on the client dashboard */
  createClientOperationComment: OperationComment;
  /**
   * Request the creation of a delegation operation.
   * It returns a generic operation for developer friendliness but it will
   * always be of type "DelegateOperation".
   * @deprecated use the prepare version of this mutation
   */
  createDelegation: Operation;
  createDepositAttributionSpamThreshold: Scalars['Boolean']['output'];
  /** Creates a new enrollment token and sends an invitation email to the provided email address */
  createEnrollTokenAndSendEmail: Scalars['String']['output'];
  /** Request the creation of a generic operation */
  createGenericOperation: Scalars['String']['output'];
  /** Request the creation of a withdrawal using a generic operation. Returns the ID of the created operation. */
  createGenericWithdrawal: Scalars['String']['output'];
  /** Create a new compliance issue in Jira */
  createJiraComplianceIssue: JiraComplianceIssue;
  /** Create KYCProcess object. */
  createKYCProcess: Maybe<KYCProcess>;
  /** Create KYCProcessTrigger mutation. */
  createKYCProcessTrigger: Maybe<KYCProcessTrigger>;
  /** Create KYCQuestion object. */
  createKYCQuestion: Maybe<KYCQuestion>;
  /** Create KYCQuestionnaire object. */
  createKYCQuestionnaire: Maybe<KYCQuestionnaire>;
  /** Create KYCQuestionnaireSubmission mutation. */
  createKYCQuestionnaireSubmission: Maybe<KYCQuestionnaireSubmission>;
  /** Create a mint or burn conversion operation. */
  createMintBurnConversion: MintBurnConversion;
  /** Request the creation of an offchain operation */
  createOffChainOperation: Scalars['String']['output'];
  /** Create an offchain vesting schedule */
  createOffchainVestingSchedule: OffchainVestingSchedule;
  /** Create specific permission group for transfers for a given domain (lending / brokerage / other) */
  createPermissionGroupForTransfers: Operation;
  createReport: Report;
  createReportSchedule: ReportSchedule;
  /** Creates a settlement for the given organization including the provided trade IDs */
  createSettlement: Scalars['Boolean']['output'];
  /** Creates a new standing instruction */
  createStandingInstruction: Scalars['String']['output'];
  /**
   * Request the preparation of a Tendermint claim staking rewards one-off operation.
   * It returns a generic operation for developer friendliness but it will
   * always be of type "OneOffOperation".
   */
  createTendermintClaimRewardsOneoff: Operation;
  /** Creates a test module */
  createTestModule: Scalars['String']['output'];
  /** Creates trusted sources in batch */
  createTrustedSources: Scalars['Boolean']['output'];
  /** Create a settlement network settlement between two participants */
  createTwoWaySettlement: SNTwoWaySettlement;
  /** Create a specific wallet group for a given asset type */
  createWalletGroup: WalletGroup;
  /** Deletes an affiliate and all its information */
  deleteAffiliate: Scalars['Boolean']['output'];
  /**
   * Soft deletes a client. This sets the field isActive to false, and deletedAt the current time.
   * Returns true on success. There is no way to undelete a client without going into the database.
   */
  deleteClient: Scalars['Boolean']['output'];
  /** Delete a comment on the client dashbaord */
  deleteClientOperationComment: Scalars['Boolean']['output'];
  /** Delete KYCProcessTrigger mutation. */
  deleteKYCProcessTrigger: Scalars['Boolean']['output'];
  /** Delete KYCQuestion object. */
  deleteKYCQuestion: Scalars['Boolean']['output'];
  /** Delete KYCQuestionnaire object. */
  deleteKYCQuestionnaire: Scalars['Boolean']['output'];
  /** Deletes an existing settlementID */
  deleteSettlement: Scalars['Boolean']['output'];
  /** Deletes a test module */
  deleteTestModule: Scalars['Boolean']['output'];
  /** Deletes personal identifiable information for a user */
  deleteUserPII: Scalars['Boolean']['output'];
  deleteWebhookEndpoint: Scalars['Boolean']['output'];
  /** Provisions a new deposit address for the given wallet ID and returns the address as a string */
  depositAddressCreate: DepositAddressCreatePayload;
  /** Deposit Source Screening new log */
  depositScreening: Scalars['Boolean']['output'];
  /** Disable a module for all organizations on a given operator */
  disableModuleForOperator: Scalars['Boolean']['output'];
  /** Disable a module for a list of organizations */
  disableModuleForOrganizations: Scalars['Boolean']['output'];
  /** Disables the given standing instruction */
  disableStandingInstruction: Scalars['Boolean']['output'];
  /** Edit an existing comment on the client dashboard */
  editClientOperationComment: Scalars['Boolean']['output'];
  /** Edit a manual attribution to a given list of operations */
  editDepositAttributions: Scalars['Boolean']['output'];
  /** Edit a operations' description */
  editOperationDescription: Scalars['Boolean']['output'];
  editSpamAttributionData: Scalars['Boolean']['output'];
  /** Edit single trusted source status */
  editTrustedSourceStatus: Scalars['Boolean']['output'];
  /** Edit trusted sources in batch */
  editTrustedSources: Scalars['Boolean']['output'];
  /** Enable a module for all organizations on a given operator */
  enableModuleForOperator: Scalars['Boolean']['output'];
  /** Enable a module for a list of organizations */
  enableModuleForOrganizations: Scalars['Boolean']['output'];
  /** Enables subaccounts for an Organization */
  enableSubaccountsForOrganization: Scalars['Boolean']['output'];
  /** Endorse an operation */
  endorse: Scalars['Boolean']['output'];
  /** Retrieves a new enrollment token, if orgPolicyScopes or Scopes(in vaultPolicyActions) are present, they are used, otherwise, their respective orgPolicyActions and actions (within vaultPolicyAction) are used instead */
  enrollToken: Scalars['String']['output'];
  /**
   * Establish a WalletConnect connection (aka session) for the wallet.
   * NOTE: naming GraphQL `...Connection` causes `make lint-graphql` to fail with
   *   A field that returns a Connection Type must include forward pagination arguments (`first` and `after`)...
   *   - see https://relay.dev/graphql/connections.htm#sec-Reserved-Types
   */
  establishWalletConnectSession: EstablishWalletConnectSessionResponse;
  /** Save/update an external account location */
  externalAccountLocationSave: Scalars['Boolean']['output'];
  /** Delete external account locations */
  externalAccountLocationsDelete: Scalars['Boolean']['output'];
  /** Save/update an external account */
  externalAccountSave: Scalars['Boolean']['output'];
  /** Save/update an external account transaction */
  externalAccountTransactionSave: Scalars['Boolean']['output'];
  /** Set account id to given value for all external account transactions for given org */
  externalAccountTransactionSetAccountIDForAllTransactionsInOrg: Scalars['Int']['output'];
  /** Set account id to given value for specific external account transaction */
  externalAccountTransactionSetAccountIDForTransaction: Scalars['Boolean']['output'];
  /** Delete external account transactions */
  externalAccountTransactionsDelete: Scalars['Boolean']['output'];
  /** Delete external accounts */
  externalAccountsDelete: Scalars['Boolean']['output'];
  /** Submits general customer feedback */
  feedbackSubmitGeneral: GeneralFeedbackResponse;
  /** Submits new asset feedback */
  feedbackSubmitNewAsset: NewAssetFeedbackResponse;
  /** fillAffiliateFormData fills the form data for an affiliate and creates it's corresponding individuals, then submits the form for the affiliate and its individuals */
  fillAffiliateFormData: Scalars['Boolean']['output'];
  /** fillClientFormData fills the form data for an client, then submits the form */
  fillClientFormData: Scalars['Boolean']['output'];
  /** Upload a financing statement */
  financingStatementUpload: Scalars['Boolean']['output'];
  /**
   * Generates a new REST API key. This key will be associated with
   * the provided email. If no email is provided, the email of the
   * logged in user will be used instead.
   *
   * This mutation returns the API key. It is the only time such key
   * will be shared.
   */
  generateAPIKeyWithPayload: GenerateAPIKeyPayload;
  /**
   * Generates a signed URLs to upload documents related
   * to compliance alerts
   */
  generateMultiSignedURLs: Array<UploadSignedUrl>;
  /**
   * Generates a signed URL to upload documents related
   * to compliance alerts
   */
  generateSignedURLToUploadDocsForComplianceAlerts: Scalars['String']['output'];
  /**
   * Returns a URL that can be used to access the form
   * The "formType" can be one of client, affiliate or individual.
   */
  getFormSessionURL: Scalars['String']['output'];
  /** Requests a quote given the provided request. If no quote can be provided this mutation returns */
  getQuote: Maybe<Quote>;
  iamRoleAddModule: Scalars['Boolean']['output'];
  iamRoleAddUsers: Scalars['Boolean']['output'];
  iamRoleCreate: IAMRole;
  iamRoleDelete: Scalars['Boolean']['output'];
  iamRoleRemoveAllModules: Scalars['Boolean']['output'];
  iamRoleUpdate: IAMRole;
  iamRoleUpdateUsers: Scalars['Boolean']['output'];
  /** Create an interest payment */
  interestPaymentCreate: Scalars['Boolean']['output'];
  /** Delete an interest payment */
  interestPaymentDelete: Scalars['Boolean']['output'];
  /** Invalidates an enrollment token. */
  invalidateEnrollment: Scalars['Boolean']['output'];
  /** Cancels a pending lending transaction */
  lendingCancelFacilityTransaction: Scalars['Boolean']['output'];
  /** lendingDailyJobRun triggers daily jobs for rewards and stakes */
  lendingDailyJobRun: Scalars['Boolean']['output'];
  /**
   * Requests a new deposit from a lending facility
   * @deprecated Use lendingDepositTransferRequest instead
   */
  lendingDepositRequest: Scalars['Boolean']['output'];
  /** Requests a new deposit to a lending facility that could automatically originate a transfer after Lending Ops approval */
  lendingDepositTransferRequest: Scalars['Boolean']['output'];
  /** Create a lending entity */
  lendingEntityCreate: Scalars['ID']['output'];
  /** Delete a lending entity */
  lendingEntityDelete: Scalars['Boolean']['output'];
  /** Update entity */
  lendingEntityUpdate: Scalars['Boolean']['output'];
  /** Creates new lending facility with the given parameters */
  lendingFacilityCreate: Scalars['ID']['output'];
  /** Creates a new lending facility fee for a given date */
  lendingFacilityFeeCreate: Scalars['Boolean']['output'];
  /** Updates lending facility fee on a given date */
  lendingFacilityFeeUpdate: Scalars['Boolean']['output'];
  /** Create facility member */
  lendingFacilityMembershipCreate: Scalars['ID']['output'];
  /** Delete facility member */
  lendingFacilityMembershipDelete: Scalars['Boolean']['output'];
  /** Update facility member */
  lendingFacilityMembershipUpdate: Scalars['Boolean']['output'];
  /** Update operationID of all reinvestment transactions in given month */
  lendingFacilityReinvestmentTransactionsUpdateOperationID: Scalars['Boolean']['output'];
  /** Create a new lending facility transaction */
  lendingFacilityTransactionCreate: Scalars['ID']['output'];
  /** Delete an existing lending facility transaction */
  lendingFacilityTransactionDelete: Scalars['Boolean']['output'];
  /** Update an existing lending facility transaction */
  lendingFacilityTransactionUpdate: Scalars['Boolean']['output'];
  /** Updates an existing lending facility with the given parameters */
  lendingFacilityUpdate: Scalars['Boolean']['output'];
  /** Creates a new lending fee reimbursment */
  lendingFeeReimbursementCreate: Scalars['Boolean']['output'];
  /** Delete a fee reimbursement */
  lendingFeeReimbursementDelete: Scalars['Boolean']['output'];
  /** Updates a lending fee reimbursment */
  lendingFeeReimbursementUpdate: Scalars['Boolean']['output'];
  /** Updates invoice state */
  lendingInvoicesUpdate: Scalars['Boolean']['output'];
  /** lendingMonthlyJobRun triggers monthly jobs for yield lending reinvestment or borrower invoice */
  lendingMonthlyJobRun: Scalars['Boolean']['output'];
  /** lendingReconciliationJobRun runs the lending reconciliation job */
  lendingReconciliationJobRun: Scalars['Boolean']['output'];
  /** lendingRevertJobRun triggers the revert of past daily jobs up until the specified date */
  lendingRevertJobRun: Scalars['Boolean']['output'];
  /** Update a lending transfer request */
  lendingTransferRequestUpdate: Scalars['Boolean']['output'];
  /**
   * Requests a new withdrawal from a lending facility
   * @deprecated Use lendingWithdrawalTransferRequest instead
   */
  lendingWithdrawalRequest: Scalars['Boolean']['output'];
  /** Requests a new withdrawal from a lending facility that could automatically originate a transfer after Lending Ops approval */
  lendingWithdrawalTransferRequest: Scalars['Boolean']['output'];
  /** Create a loan */
  loanCreate: Scalars['Boolean']['output'];
  /** Create a crypto collateral */
  loanCreateCryptoCollaterals: Scalars['Boolean']['output'];
  /** Create a equity collateral */
  loanCreateEquityCollaterals: Scalars['Boolean']['output'];
  /** Create a mining collateral */
  loanCreateMiningCollaterals: Scalars['Boolean']['output'];
  /** Soft deletes a crypto collateral */
  loanDeleteCryptoCollateral: Scalars['Boolean']['output'];
  /** Soft deletes a equity collateral */
  loanDeleteEquityCollateral: Scalars['Boolean']['output'];
  /** Soft deletes a mining collateral */
  loanDeleteMiningCollateral: Scalars['Boolean']['output'];
  /** Refinance a loan */
  loanRefinance: Scalars['Boolean']['output'];
  /** Update loan terms */
  loanUpdate: Scalars['Boolean']['output'];
  /** Marks a list of affiliateV2, its relationships and accountsV2 as deleted */
  markAffiliatesV2AndAccountsV2AsDeleted: Scalars['Boolean']['output'];
  /** Marks the shares associated with the user as exported */
  markWalletRecoveryKeySharesExported: Scalars['Boolean']['output'];
  /** Merges a list of persons into one, the first in the list. */
  mergePersons: Scalars['Boolean']['output'];
  /** This takes a "prepared" operation (not a staged one) and makes it visible in the app and triggers a push notification to make sure the user can get to the operation easily on the app and endorse it. */
  operationMakeVisibleInOperationsList: Scalars['Boolean']['output'];
  /**
   * An easier to use and signatureless API for rejecting operations
   * The same can be achieved with the endorse API, but it requires a signature, which can make UX unnecessarily annoying and isn't usable from the client dashboard
   * This is usable only by org users for now (not staff users) because we need to record which user rejected it
   */
  operationReject: Scalars['Boolean']['output'];
  /** Add a new manual attribution to a given list of operations */
  performManualDepositAttributions: Scalars['Boolean']['output'];
  /**
   * Request the preparation of an add trusted destination operation
   * It returns a generic operation for developer friendliness but it will
   * always be of type "AddTrustedDestinationOperation".
   */
  prepareAddTrustedDestination: Operation;
  /**
   * Request the preparation of an AuthorizeSpender operation.
   * As opposed to create, prepare requires a clientID.
   * It returns a generic operation for developer friendliness but it will
   * always be of type "AuthorizeSpender".
   *
   * For simplicity, the address of the "spender" will be filled in by the
   * server. If this becomes parametrizable in the future, a field will be
   * added here.
   */
  prepareAuthorizeSpender: Operation;
  /** Request the preparation of a organization change operation. This will be a single operation with multiple changes. */
  prepareCreateGenericOrganizationChange: Operation;
  /**
   * Request the preparation of a create vault operation
   * As opposed to create, prepare requires a clientID.
   * It returns a generic operation for developer friendliness but it will
   * always be of type "CreateVaultOperation".
   */
  prepareCreateVault: Operation;
  /**
   * Request the preparation of a delegation operation.
   * As opposed to create, prepare requires a clientID.
   * It returns a generic operation for developer friendliness but it will
   * always be of type "DelegateOperation".
   */
  prepareDelegation: Operation;
  /**
   * Request the preparation of a disable vault operation
   * As opposed to create, prepare requires a clientID.
   * Disable the vault with the given vault sub ID
   */
  prepareDisableVault: Operation;
  /**
   * Request the preparation of a Lock operation.
   * As opposed to create, prepare requires a clientID.
   * It returns a generic operation for developer friendliness but it will
   * always be of type "Lock".
   */
  prepareLock: Operation;
  /**
   * Request the preparation of a one-off operation.
   * As opposed to create, prepare requires a clientID.
   * It returns a generic operation for developer friendliness but it will
   * always be of type "OneOffOperation".
   *
   * This is deprecated to reduce our attack surface, use one-off type specific mutations
   * @deprecated Use one-off type specific mutations
   */
  prepareOneOff: Operation;
  /**
   * Request the preparation of a remove trusted destination operation
   * As opposed to create, prepare requires a clientID.
   * It returns a generic operation for developer friendliness but it will
   * always be of type "RemoveTrustedDestinationOperation".
   */
  prepareRemoveTrustedDestination: Operation;
  /**
   * Request the preparation of a remove user operation
   * As opposed to create, prepare requires a clientID.
   * It returns a generic operation for developer friendliness but it will
   * always be of type "UserRemoveOperation".
   */
  prepareRemoveUser: Operation;
  /**
   * Request the preparation of an Unlock operation.
   * As opposed to create, prepare requires a clientID.
   * It returns a generic operation for developer friendliness but it will
   * always be of type "Unlock".
   */
  prepareUnlock: Operation;
  /**
   * Request the preparation of a org policy update operation
   * As opposed to create, prepare requires a clientID.
   * It returns a generic operation for developer friendliness but it will
   * always be of type "OrgPolicyChangeOperation.
   */
  prepareUpdateOrgPolicy: Operation;
  /**
   * Request the preparation of a vault policy update operation
   * As opposed to create, prepare requires a clientID.
   * It returns a generic operation for developer friendliness but it will
   * always be of type "VaultPolicyChangeOperation".
   */
  prepareUpdateVaultPolicy: Operation;
  /**
   * Request the preparation of a Vote operation.
   * As opposed to create, prepare requires a clientID.
   * It returns a generic operation for developer friendliness but it will
   * always be of type "Vote".
   */
  prepareVote: Operation;
  /**
   * Request the preparation of a withdrawal operation.
   * It returns a generic operation for developer friendliness but it will
   * always be of type "WithdrawOperation".
   * Call the PrepareWithdrawal query with one of the following parameter groups populated.
   * Group 1 - walletID
   * Group 2 - vaultSubID and assetSubID
   * ALL parameters in ONE AND ONLY ONE group of the two above groups MUST be populated.
   * -- the "ONLY ONE" rule applies to both Source and Destination (with slight variation).
   * -- though, Source and Destination can provide different groups.
   * Slight variation:
   * -- for Source, if vaultSubID is provide, assetSubID must also be provided.
   * -- for Destination, if vaultSubID is provide, assetSubID is still optional
   *    (if not provided, would default to first asset in the given vault).
   */
  prepareWithdrawal: Operation;
  /** Registers a token for push notifications */
  registerDeviceToken: Scalars['Boolean']['output'];
  /** registerManualTrade allows to register a trade that was executed outside of the trading system. */
  registerManualTrade: Scalars['ID']['output'];
  registerWebhookEndpoint: WebhookEndpoint;
  /** Removes a representative of the affiliate */
  removeAffiliateRepresentative: Scalars['Boolean']['output'];
  /** Removes a representative of the client */
  removeClientRepresentative: Scalars['Boolean']['output'];
  /** Removes an individual from an affiliate */
  removeIndividualFromAffiliate: Scalars['Boolean']['output'];
  /** Removes a representative of the individual */
  removeIndividualRepresentative: Scalars['Boolean']['output'];
  /** Removes related party relations from multiple affiliates */
  removeRelatedPartyRelationships: Scalars['Boolean']['output'];
  /** Removes a set of trusted sources defined for deposit attributions */
  removeTrustedSources: Scalars['Boolean']['output'];
  /**
   * Allows a risk reviewer to replace an individual defined by the external Client with an existing individual.
   * Any existing data/forms provided for the 'individualID' such as if forms were partially filled out will be entirely deleted.
   * All defined affiliates and representatives of the 'individualID' will be transfered to the 'replacementIndividualID'
   */
  replaceIndividualInClient: Scalars['Boolean']['output'];
  /**
   * Retrieves a replacement token that can be used to replace the device associated
   * with
   * the provided user key ID.
   *
   * If no user key ID is provided, the replacement token refers to the caller.
   */
  replaceToken: Scalars['String']['output'];
  /** Sends a request to activate trading to Anchorage */
  requestActivateTrading: Scalars['Boolean']['output'];
  /** Requests access to lending for an existing account */
  requestLendingAccess: Scalars['Boolean']['output'];
  /** Resends an affiliate form to a specific representative. */
  resendAffiliateForm: Scalars['Boolean']['output'];
  /** Resends a client form to a specific representative. */
  resendClientForm: Scalars['Boolean']['output'];
  /** Resends an individual form to a representative of that individual. */
  resendIndividualForm: Scalars['Boolean']['output'];
  /** Make the given list of vaults the only ones allowed for the DApp. */
  resetAllowedVaultsForDApp: ResetAllowedVaultsForDAppResponse;
  /** Removes any collateral references on loan manager entities */
  resetCollateralIntegration: Scalars['Boolean']['output'];
  /** Restore a comment on the client dashbaord */
  restoreClientOperationComment: OperationComment;
  /** Revokes an API key */
  revokeAPIKeyWithErrors: RevokeAPIKeyPayload;
  /** Create RIA fee configurations */
  riaCreatFeeConfiguration: Scalars['String']['output'];
  /** Endorses an operation */
  riskEndorse: Scalars['Boolean']['output'];
  /** rollbackKYCInformation rollback a change made in the kyc information table with the audit ID */
  rollbackKYCInformation: Scalars['Boolean']['output'];
  /** sanitizeAddress checks the validity of an address and provides the canonical form of the address. */
  sanitizeAddress: SanitizeAddressPayload;
  /** saveSettings creates or updates a given setting */
  saveDocumentDeliverySetting: Scalars['Boolean']['output'];
  /** Save KYCQuestionnaireSubmission mutation. */
  saveKYCQuestionnaireSubmission: Maybe<SaveKYCQuestionnaireSubmissionResponse>;
  /**
   * Sends an invitation email to the email associated with the enroll token. Only supports
   * Porto enrollments
   */
  sendInvitationEmailForEnrollment: Scalars['Boolean']['output'];
  /**
   * Sends KYCEvent mutation.
   * This triggers the same actions defined in the kyc process triggers as if
   * it was a jira case transition.
   */
  sendKYCEvent: Scalars['Boolean']['output'];
  /** Send KYCQuestionnaireSubmission mutation. */
  sendKYCQuestionnaireSubmission: Scalars['Boolean']['output'];
  /** Send emails for statements that can but haven't been sent yet */
  sendUnsentStatementEmails: Scalars['Boolean']['output'];
  /** Sets whether a specific organization can trade only in crypto. Returns true if the operation was successful */
  setCryptoOnly: Scalars['Boolean']['output'];
  setEnabledWebhookEventTypes: Scalars['Boolean']['output'];
  /** SetExemptPII allows us to skip a piece of PII if not available (example: videos) */
  setExemptPII: Scalars['Boolean']['output'];
  /**
   * Enables or disables a feature for the supplied organization. Returns true if the operation was successful
   * TODO: migrate existing apps to this one and drop devdebug's
   */
  setFeatureEnabled: Scalars['Boolean']['output'];
  /**
   * Sets a specific form to a specific status.
   * The "formType" can be one of client, affiliate or individual.
   */
  setFormStatus: Scalars['Boolean']['output'];
  /** Marks a device as having been lost */
  setLostDevice: Scalars['Boolean']['output'];
  /**
   * Sets the current notification count for this user, so that the correct
   * number can be sent in future push notifications
   */
  setNotificationCount: Scalars['Boolean']['output'];
  /**
   * Disable or re-enable an organization
   * Set disabled True to disable, False to re-enable
   */
  setOrganizationDisabled: Scalars['Boolean']['output'];
  /**
   * Sets the notes for a given organization. Edits existing notes or creates
   * them if they don't exist.
   */
  setOrganizationNotes: Scalars['Boolean']['output'];
  /** Set a new organization wire fee config */
  setOrganizationWireFeeConfig: Scalars['Boolean']['output'];
  /**
   * setSLAForOrg sets the current sla for the organization
   * Note that this doesn't affect existing operations.
   * the sla parameter is a duration string (e.g. "24h", or "12h30m")
   * from the first time the operation entered risk review
   */
  setSLAForOrg: Scalars['Boolean']['output'];
  /** Sets whether a list of users can trade. Returns the list of traders updated */
  setTraders: Array<Maybe<Scalars['String']['output']>>;
  /**
   * Sets the value of a personal identifiable information for a user. Creates a new
   * personal identifiable information attribute, if it doesn't exist
   */
  setUserPII: Scalars['Boolean']['output'];
  /** Set Wallet key PDF export preference */
  setWalletKeyExportPreference: SetWalletKeyExportPreferenceResponse;
  /** Add a trusted counterparty to a list of partipants. Description is copied to each participant. */
  snAddTrustedCounterparties: Array<SNTrustedCounterparty>;
  /**
   * Create a settlement network participant from a given account ID. The caller must have access to the account.
   * The name is set based on the name of the affiliate that the account is associated with.
   */
  snParticipantCreate: SNParticipant;
  /**
   * Update a settlement network participant. Some fields are staff-only and some fields can be set by the user.
   * If there's no comment on a field, it's a staff-only field.
   */
  snParticipantUpdate: SNParticipant;
  /**
   * Accepts an incoming settlement and sets the wallets, returns the settlement if successful.
   *
   * New wallets will be created for any missing wallet amounts, provided a vaultID is given.
   */
  snSettlementAccept: SNTwoWaySettlement;
  /**
   * Overrides the settlement acceptance for a participant.
   * Approval requires prior wallet selection for the participant's side.
   * Rejection is only possible
   * Only applicable to development environment.
   */
  snSettlementApprovalOverride: SNTwoWaySettlement;
  /** Rejects an incoming settlement, returns the settlement if successful */
  snSettlementReject: SNTwoWaySettlement;
  /** Update a trusted counterparty for a participant */
  snUpdateTrustedCounterparty: SNTrustedCounterparty;
  /**
   * stagedOrganizationComplete completes the staged organization and creates an add organization operation
   * returns the operation id
   */
  stagedOrganizationComplete: Maybe<Scalars['String']['output']>;
  /** Creates a new StagedOrganization */
  stagedOrganizationCreate: StagedOrganization;
  /** stagedOrganizationDelete sets the deleted flag on the staged org and removes all existing enrollments */
  stagedOrganizationDelete: Scalars['Boolean']['output'];
  /** stagedOrganizationUpdate updates a staged organization. It takes the old version of the staged organization a json and the new version and safely updates it */
  stagedOrganizationUpdate: StagedOrganization;
  /** stagedOrganizationUpdateV2 updates a staged organization. Instead of relying on the clients manipulating the definition, it has inputs */
  stagedOrganizationUpdateV2: StagedOrganization;
  /** Start the client forms. This will dispatch emails if need be. */
  startClientForms: Scalars['Boolean']['output'];
  /** Starts dynamic CRR calculation */
  startDynamicCRRCalculation: Scalars['Boolean']['output'];
  /**
   * Starts a Organization Full Recovery when all users lost device access.
   * Returns a boolean representing the mutations success.
   */
  startOrganizationRecovery: Scalars['Boolean']['output'];
  /** Transfers funds from the commingled wallet to a program customer wallet for gas fees */
  subaccountGasFillWallet: Scalars['Boolean']['output'];
  /** Sweep deposited funds on a subaccount linked wallet to the commingled wallet */
  subaccountSweepLinkedWallet: Scalars['Boolean']['output'];
  /** Edits answers on a submitted questionnaire */
  submitEditedQuestionnaire: Scalars['Boolean']['output'];
  /** Allows the submission of ad-hoc PII */
  submitPII: Scalars['Boolean']['output'];
  /** This sets the control master public key for the current user's staged organization and the private key equivalents for other users. This is called only once pre-onboarding by the last admin and shouldn't need to be called more than once. */
  submitStagedControlKeys: Scalars['Boolean']['output'];
  /**
   * This sets the wallet recovery public key for the organization and it associates the encrypted shares with the users.
   * The wallet recovery public key is signed with the lead admin's encryption key.
   */
  submitStagedWalletRecoveryKeyShares: Scalars['Boolean']['output'];
  /** Create a Tax Tenant */
  taxCreateTenant: TaxTenant;
  /** Delete a Tax Tenant */
  taxDeleteTenant: Scalars['Boolean']['output'];
  /** Update a Tax Tenant */
  taxUpdateTenant: Scalars['Boolean']['output'];
  /**
   * Terminate a WalletConnect connection to the DApp, eg, Snapshot, for the wallet.
   * NOTE: naming GraphQL `...Connection` causes `make lint-graphql` to fail with
   *   A field that returns a Connection Type must include forward pagination arguments (`first` and `after`)...
   *   - see https://relay.dev/graphql/connections.htm#sec-Reserved-Types
   */
  terminateWalletConnectSession: TerminateWalletConnectSessionResponse;
  /**
   * Terminate a WalletConnect connection to the DApp, eg, Snapshot, for the wallet.
   * NOTE: naming GraphQL `...Connection` causes `make lint-graphql` to fail with
   *   A field that returns a Connection Type must include forward pagination arguments (`first` and `after`)...
   *   - see https://relay.dev/graphql/connections.htm#sec-Reserved-Types
   */
  terminateWalletConnectSessions: TerminateWalletConnectSessionResponse;
  /** Trade on a quote by quote ID */
  tradeQuote: TradeQuoteResponse;
  /** Save a new settlement address for an asset */
  tradingAddSettlementAddress: Scalars['Boolean']['output'];
  /** Adds a trading symbol */
  tradingAddSymbol: Scalars['Boolean']['output'];
  /**
   * Approves an already added trading symbol
   * @deprecated use tradingApproveSymbolByID instead
   */
  tradingApproveSymbol: Scalars['Boolean']['output'];
  /** Approves an already added trading symbol */
  tradingApproveSymbolByID: Scalars['Boolean']['output'];
  /** Assign/Unassign a client settlement to an op */
  tradingAssignClientSettlement: Scalars['Boolean']['output'];
  /** Cancel a client settlement */
  tradingCancelClientSettlements: Array<Maybe<Scalars['String']['output']>>;
  /** Create a new counterparty */
  tradingCounterpartyCreate: Scalars['ID']['output'];
  /** Activate or deactivate a specific counterparty */
  tradingCounterpartyUpdate: Scalars['ID']['output'];
  /** Creates a new brokerage trading account */
  tradingCreateAccount: TradingAccount;
  /** Create a trading asset */
  tradingCreateAsset: TradingAsset;
  /** Create a client settlement */
  tradingCreateClientSettlement: ClientSettlement;
  /** Created an external asset */
  tradingCreateExternalAsset: TradingExternalAsset;
  /** Creates a client settlement out of a fee */
  tradingCreateFeeClientSettlement: ClientSettlement;
  /**
   * Creates a settlement for the given organization and account.
   * The trades are selected based on the filters provided.
   */
  tradingCreateSettlementFromFilters: Settlement;
  /** Create a new permission set */
  tradingCreateTradingPermission: Array<TradingPermission>;
  /**
   * Create wallet settlement instructions for Anchorage Client Settlement FBO Org
   * This wallet will be used as the source or destination of the automated
   * RIA/B2B2X settlements
   */
  tradingCreateWalletSettlementInstructions: WalletSettlementInstruction;
  /** Create whitelabel account */
  tradingCreateWhitelabelAccount: Maybe<TradingWhitelabelAccount>;
  /** Create whitelabel user */
  tradingCreateWhitelabelUser: Maybe<TradingWhitelabelUser>;
  /** Cancels a manual dealer trade */
  tradingDealerManualTradeCancel: Scalars['Boolean']['output'];
  /** Creates a dealer manual trade */
  tradingDealerManualTradeCreate: Scalars['ID']['output'];
  /** Update fields in dealer manual trades */
  tradingDealerManualTradeUpdate: Scalars['Boolean']['output'];
  /** Cancels a dealer settlement */
  tradingDealerSettlementCancel: Scalars['Boolean']['output'];
  /** Creates a dealer settlement */
  tradingDealerSettlementCreate: Scalars['ID']['output'];
  /** Create a dealer settlement with all payment flows with a counterparty and asset */
  tradingDealerSettlementCreateCounterpartyAsset: Scalars['ID']['output'];
  /** Create or preview a dealer settlement creation */
  tradingDealerSettlementCreateFromFilters: DealerSettlement;
  /** Initiates a dealer settlement */
  tradingDealerSettlementInitiate: Scalars['Boolean']['output'];
  /** Settles a dealer settlement */
  tradingDealerSettlementSettle: Scalars['Boolean']['output'];
  /** Updates the transaction IDs of a dealer settlement */
  tradingDealerSettlementUpdateTransactionIDs: Scalars['Boolean']['output'];
  /**
   * Delete a trading asset
   * Currently this is a hard delete, we may want to change this for a soft delete
   */
  tradingDeleteAsset: Scalars['Boolean']['output'];
  /** Delete an external asset */
  tradingDeleteExternalAsset: Scalars['Boolean']['output'];
  /** Delete a trade settlement transaction */
  tradingDeleteTradeSettlementTransaction: Scalars['Boolean']['output'];
  /** Deletes a wallet settlement instruction entry */
  tradingDeleteWalletSettlementInstruction: Scalars['Boolean']['output'];
  /** Delete whitelabel user */
  tradingDeleteWhitelabelUser: Scalars['Boolean']['output'];
  /** Enable/Disables an already added trading symbol */
  tradingEnableSymbolByID: Scalars['Boolean']['output'];
  /** Link a dealer side trade to a client side trade */
  tradingLinkDealerAndClientTrades: Scalars['Boolean']['output'];
  /** Process a client settlement */
  tradingProcessClientSettlement: Scalars['Boolean']['output'];
  /** Reinitiate a client settlement */
  tradingReinitiateClientSettlement: Scalars['Boolean']['output'];
  /** Add a new trade settlement transaction */
  tradingSaveTradeSettlementTransaction: Scalars['String']['output'];
  /** Updates multiple trading accounts */
  tradingUpdateAccounts: Array<UpdateAccountResult>;
  /** Updates trading precision for a symbol */
  tradingUpdateAssetPrecision: Scalars['Boolean']['output'];
  /** Update fiat settlemnt transaction */
  tradingUpdateFiatSettlementTransaction: Scalars['Boolean']['output'];
  /** Edit already set crypto settlement instruction for both default and org-specific instructions */
  tradingUpdateSettlementAddress: Scalars['Boolean']['output'];
  /**
   * Updates a trading symbol
   * @deprecated use tradingUpdateSymbolByID instead
   */
  tradingUpdateSymbol: Scalars['Boolean']['output'];
  /** Updates a trading symbol */
  tradingUpdateSymbolByID: Scalars['Boolean']['output'];
  /** Update an existing trade settlement transaction */
  tradingUpdateTradeSettlementTransaction: Scalars['Boolean']['output'];
  /** Update a whitelabel account */
  tradingUpdateWhitelabelAccount: Scalars['Boolean']['output'];
  /** Update whitelabel user */
  tradingUpdateWhitelabelUser: Scalars['Boolean']['output'];
  /** Enable or disable TravelRule autosend */
  travelRuleSetAutomation: Scalars['Boolean']['output'];
  /**
   * Triggers the data quality checks for Persons. These checks run async and the
   * results can be retrieved with the returned ID through
   * `getPersonDataQualityChecksResult`
   */
  triggerPersonDataQualityChecks: Scalars['ID']['output'];
  triggerWebhook: Scalars['Boolean']['output'];
  /**
   * unarchiveWallets unarchives wallets identified by the given IDs.
   * Returns error if walletIDs is empty.
   * Returns no error if nothing is updated (eg, no wallet matches, or all matching wallets
   * already are in "unarchived" state.)
   */
  unarchiveWallets: ArchiveWalletsResponse;
  /**
   * Update an existing REST API key. Currently it only supports updating the description.
   *
   * This mutation returns boolean indicating whether the update is successful.
   */
  updateAPIKey: Scalars['Boolean']['output'];
  /** updates accounts withdrawal freeze restrictions */
  updateAccountsFreezeWithdrawal: Scalars['Boolean']['output'];
  /** Creates or updates a affiliate. If affiliate ID is provided it updates */
  updateAffiliate: InstitutionalAffiliate;
  /** updateAffiliateDocusignTemplate sets a specific docusign template to be used in terms and conditions */
  updateAffiliateDocusignTemplate: Scalars['String']['output'];
  /** Allows the submission of ad-hoc institutional information */
  updateAffiliateInformation: Array<AffiliateInformationRejection>;
  /** Changes an affiliateV2 name */
  updateAffiliateV2Name: Scalars['Boolean']['output'];
  /** updates affiliates withdrawal freeze restrictions */
  updateAffiliatesFreezeWithdrawal: Scalars['Boolean']['output'];
  /** Update asset metadata (name, description) */
  updateAssetMetadata: Asset;
  /** Update the auto compound state */
  updateAutoCompound: Scalars['Boolean']['output'];
  /** Update CRR risk parameters configuration */
  updateCRRRiskParameters: Scalars['Boolean']['output'];
  /** Creates or updates a client. If client ID is provided it updates */
  updateClient: InstitutionalClient;
  /** Allows the update of institutional client information */
  updateClientInformation: Array<ClientInformationRejection>;
  /** Allows the update of an institutional client name */
  updateClientName: Scalars['Boolean']['output'];
  updateDepositAttributionSpamThreshold: Scalars['Boolean']['output'];
  /** UpdateGlobalTradingLimit updates the global trading limit */
  updateGlobalTradingLimit: Scalars['Boolean']['output'];
  /**
   * Allows updating an individual belonging to an institution.
   * If the individual has start its form, this mutation will fail.
   * Ordering of relationships doesn't matter.
   */
  updateIndividualAndRelationships: Array<IndividualInformationRejection>;
  /** Allows the submission of ad-hoc PII */
  updateIndividualInformation: Array<IndividualInformationRejection>;
  /** Update KYCProcessTrigger mutation. */
  updateKYCProcessTrigger: Maybe<KYCProcessTrigger>;
  /** Update KYCQuestion object. */
  updateKYCQuestion: Maybe<KYCQuestion>;
  /** Update KYCQuestionnaire object. */
  updateKYCQuestionnaire: Maybe<KYCQuestionnaire>;
  /** updateMarketingWidget tells the backend a widget had an interaction */
  updateMarketingWidget: Scalars['Boolean']['output'];
  /**
   * Updates an existing or creates a new network status
   * Returns true  in case of successful   update/creation
   * Returns false in case of unsuccessful update/creation
   */
  updateNetworkStatus: Scalars['Boolean']['output'];
  /** updateRIATermsQuestionnaire sets a specific questionnaire to be used in terms and conditions */
  updateRIATermsQuestionnaire: Scalars['Boolean']['output'];
  /** Allows updating related party relationship with an affiliate. */
  updateRelatedPartyRelationships: Scalars['Boolean']['output'];
  updateReportSchedule: ReportSchedule;
  /** Update role metadata (name) */
  updateRoleMetadata: Role;
  /** Updates the settlement status, atomically updating the underlying trades' statuses */
  updateSettlementStatus: Scalars['Boolean']['output'];
  /** Updates whether the given organization is approved by the Compliance Team to access trading */
  updateTradeApprovalByComplianceTeam: Scalars['Boolean']['output'];
  /** Updates whether the given organization is approved by the Trading Team to access trading */
  updateTradeApprovalByTradingTeam: Scalars['Boolean']['output'];
  /** Updates an organization's trade commission rate */
  updateTradeCommissionRate: Scalars['Boolean']['output'];
  /** Updates an organization's trade credit limit (USD) */
  updateTradeCreditLimit: Scalars['Boolean']['output'];
  /** Updates an organization's trade settings */
  updateTradeSettings: Scalars['Boolean']['output'];
  /** Updates a trade status */
  updateTradeStatus: Scalars['Boolean']['output'];
  /** Updates an organization's list of email recipients to be notified for trading-related communications. */
  updateTradingEmailRecipients: Scalars['Boolean']['output'];
  /** updateUnsettledManualTrade allows to update an unsettled manual trade. */
  updateUnsettledManualTrade: Scalars['Boolean']['output'];
  /** Updates the settings for a user. */
  updateUserSettings: Scalars['Boolean']['output'];
  /** Updates the account a vault belongs to */
  updateVaultAccount: Scalars['Boolean']['output'];
  /** Update vault metadata (name, description) */
  updateVaultMetadata: Vault;
  updateWebhookEndpoint: WebhookEndpoint;
  /** Upload an account statement to GCS to make available for download in the client dashboard */
  uploadAccountStatement: Scalars['Boolean']['output'];
  /**
   * Allows the submission of affiliate information files
   * @deprecated use the upload rest endpoint instead
   */
  uploadAffiliateInformation: Maybe<Scalars['String']['output']>;
  /**
   * Allows the submission of PII files
   * @deprecated use the upload rest endpoint instead
   */
  uploadIndividualInformation: Maybe<Scalars['String']['output']>;
  /** Upload an trusted destinations list to validate and process into a bulk trusted destination creation */
  uploadTrustedDestinationsCSV: Array<BulkTrustedDestinationItem>;
  /** Upload an trusted smart contracts list to validate and process into a bulk trusted smart contract creation */
  uploadTrustedSmartContractsCSV: Array<BulkTrustedSmartContractItem>;
  /** Approve a previously initialized WalletConnect session. */
  walletConnectApproveSession: WalletConnectApproveSessionResponse;
  /**
   * Initializes a WalletConnect session with the provided wallet and URI. This is
   * an alternative to the `establishWalletConnectSession` mutation and allows for
   * more control over the session.
   */
  walletConnectInitializeSession: WalletConnectInitializeSessionResponse;
  /** Reject a previously initialized WalletConnect session. */
  walletConnectRejectSession: WalletConnectRejectSessionResponse;
  /** Creates a brand new wallet for the given vault and networkID and returns details of the newly created wallet */
  walletCreate: WalletResponsePayload;
  /** Updates the name of the wallet for walletID to the specified name string */
  walletRename: WalletResponsePayload;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationacceptDisclosureArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  clientAcceptedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  disclosureID: Scalars['String']['input'];
  documentVersion: Scalars['Int']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationackWalletKeyDownloadArgs = {
  walletKeyID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationaddAffiliateRepresentativeArgs = {
  affiliateID: Scalars['String']['input'];
  representativeEmail: Scalars['String']['input'];
  representativeName?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationaddClientAffiliateTypeArgs = {
  input: ClientAffiliateTypeInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationaddClientRepresentativeArgs = {
  clientID: Scalars['String']['input'];
  representativeEmail: Scalars['String']['input'];
  representativeName?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationaddIndividualAndRelationshipsArgs = {
  affiliateID: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  entries?: InputMaybe<Array<InputIndividualInformation>>;
  firstName: Scalars['String']['input'];
  kycRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  kycScreening?: InputMaybe<KYCScreeningInput>;
  lastName: Scalars['String']['input'];
  middleName: Scalars['String']['input'];
  ownershipPercentage?: InputMaybe<Scalars['String']['input']>;
  representativeEmail?: InputMaybe<Scalars['String']['input']>;
  representativeName?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationaddIndividualRepresentativeInClientArgs = {
  clientID: Scalars['String']['input'];
  individualID: Scalars['String']['input'];
  representativeEmail: Scalars['String']['input'];
  representativeName?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationaddRelatedPartyRelationshipsArgs = {
  affiliateID: Scalars['ID']['input'];
  countryCode?: InputMaybe<Scalars['String']['input']>;
  kycRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  kycScreening?: InputMaybe<KYCScreeningInput>;
  name: Scalars['String']['input'];
  ownershipPercentage?: InputMaybe<Scalars['String']['input']>;
  relatedPartyID?: InputMaybe<Scalars['ID']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationamendKYCInformationArgs = {
  entries?: InputMaybe<Array<AmendedKYCInformation>>;
  notes: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationapproveAffiliateArgs = {
  affiliateID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationapproveClientArgs = {
  clientID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationapproveIndividualInClientArgs = {
  clientID: Scalars['String']['input'];
  individualID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationapprovesStandingInstructionArgs = {
  standingInstructionID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationarchiveWalletsArgs = {
  walletIDs: Array<Scalars['ID']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationassignUnassignedFundsArgs = {
  orgID: Scalars['String']['input'];
  purpose: TransactionType;
  subaccountID: Scalars['String']['input'];
  transactionIDs: Array<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationattributeDepositsAsSpamArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  operationIDs: Array<Scalars['OperationID']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationbackfillCollateralExposureIDsArgs = {
  filterLoanTermIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationbackfillLendingFacilityTransactionMetadataArgs = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationbackfillLendingInterestPaymentMetadataArgs = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationbackfillPriceMemberStakesSnapshotArgs = {
  filterAssetList: Array<Scalars['String']['input']>;
  filterSnapshotDate: Scalars['Date']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationbatchEndorseArgs = {
  distressSignal?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  operations: Array<InputEndorseOperation>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationblockPricerBookQuoteArgs = {
  bookQuoteInput: BookQuoteParams;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationblockPricerRfqArgs = {
  rfqInput: RFQParams;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcancelManualTradeArgs = {
  note?: InputMaybe<Scalars['String']['input']>;
  organizationKeyID: Scalars['String']['input'];
  tradeID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcancelOffchainVestingScheduleArgs = {
  vestingScheduleID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcancelOperationAsyncArgs = {
  operationID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationchangeRoleArgs = {
  changeType: RoleChangeType;
  clientID?: InputMaybe<Scalars['String']['input']>;
  expiration?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<InputRolePermission>>;
  roleSubID?: InputMaybe<Scalars['String']['input']>;
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationclientDashboardLoginArgs = {
  payload: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcloseAccountsArgs = {
  updates: Array<AccountClosure>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcloseAffiliateArgs = {
  affiliateID: Scalars['String']['input'];
  bsaRecommendedDate?: InputMaybe<Scalars['Date']['input']>;
  closureDate: Scalars['Date']['input'];
  initiatedBy: Scalars['String']['input'];
  msaSigned?: InputMaybe<Scalars['Boolean']['input']>;
  msaTerminationDate?: InputMaybe<Scalars['Date']['input']>;
  reason: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcollateralCreateAgreementArgs = {
  input: CreateCollateralAgreementInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcollateralCreateAssetSpecArgs = {
  input: CreateAssetSpecInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcollateralCreateCallArgs = {
  input: CreateCollateralCallInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcollateralCreateFeeArgs = {
  input: CreateCollateralFeeInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcollateralCreateInventoryArgs = {
  input: CreateCollateralInventoryInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcollateralCreateRehypoLedgerArgs = {
  input: CreateCollateralRehypoLedgerInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcollateralDeleteAgreementArgs = {
  id: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcollateralDeleteAssetSpecArgs = {
  id: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcollateralDeleteFeeArgs = {
  id: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcollateralDeleteInventoryArgs = {
  id: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcollateralReIssueCallArgs = {
  input: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcollateralUpdateAgreementArgs = {
  input: UpdateCollateralAgreementInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcollateralUpdateAssetSpecArgs = {
  input: UpdateAssetSpecInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcollateralUpdateCallArgs = {
  input: UpdateCollateralCallInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcollateralUpdateFeeArgs = {
  input: UpdateCollateralFeeInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcollateralUpdateInventoryArgs = {
  input: UpdateCollateralInventoryInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcollateralUpdateRehypoLedgerArgs = {
  input: UpdateCollateralRehypoLedgerInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcomplianceUpdateTMSConfigsArgs = {
  entityType: TMSRuleEntityType;
  ruleGroup: TMSRuleGroup;
  ruleName: TMSRuleName;
  tmsConfigs: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateAPIKeyForTransfersArgs = {
  description: Scalars['String']['input'];
  domain: TransferAccessRequestDomain;
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateAndEndorseArgs = {
  distressSignal?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  operationID: Scalars['String']['input'];
  payload: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateClaimFromVestingContractArgs = {
  amount: Scalars['String']['input'];
  assetSubID: Scalars['String']['input'];
  assetTypeID: Scalars['AssetTypeID']['input'];
  vaultSubID: Scalars['String']['input'];
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateClientOperationCommentArgs = {
  message: Scalars['String']['input'];
  operationID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateDelegationArgs = {
  amount?: InputMaybe<Scalars['String']['input']>;
  assetSubID: Scalars['String']['input'];
  assetTypeID: Scalars['AssetTypeID']['input'];
  clientID?: InputMaybe<Scalars['String']['input']>;
  delegateType?: InputMaybe<DelegateType>;
  destination: Scalars['String']['input'];
  expiration?: InputMaybe<Scalars['String']['input']>;
  extraParams?: InputMaybe<InputExtraParams>;
  message?: InputMaybe<Scalars['String']['input']>;
  organizationKeyID?: InputMaybe<Scalars['String']['input']>;
  vaultSubID: Scalars['String']['input'];
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateDepositAttributionSpamThresholdArgs = {
  input: CreateDepositAttributionSpamThresholdInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateEnrollTokenAndSendEmailArgs = {
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  orgPolicyScopes?: InputMaybe<Array<ScopeToQuorumRequirements>>;
  vaultPolicyActions?: InputMaybe<Array<EnrollmentVaultPolicy>>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateGenericOperationArgs = {
  assetSubID: Scalars['String']['input'];
  assetTypeID: Scalars['AssetTypeID']['input'];
  genericOperationType: Scalars['String']['input'];
  payload: Scalars['String']['input'];
  vaultSubID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateGenericWithdrawalArgs = {
  amount: Scalars['String']['input'];
  destination?: InputMaybe<Scalars['String']['input']>;
  destinationWalletUniqueID?: InputMaybe<Scalars['ID']['input']>;
  spendableID: Scalars['String']['input'];
  walletUniqueID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateJiraComplianceIssueArgs = {
  input: ComplianceIssueInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateKYCProcessArgs = {
  input: CreateKYCProcessInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateKYCProcessTriggerArgs = {
  input: CreateKYCProcessTriggerInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateKYCQuestionArgs = {
  input: CreateKYCQuestionInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateKYCQuestionnaireArgs = {
  input: CreateKYCQuestionnaireInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateKYCQuestionnaireSubmissionArgs = {
  input: CreateKYCQuestionnaireSubmissionInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateMintBurnConversionArgs = {
  amount: AmountInput;
  destinationAssetTypeID: Scalars['AssetTypeID']['input'];
  destinationWalletID: Scalars['ID']['input'];
  sourceWalletID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateOffChainOperationArgs = {
  assetSubID: Scalars['String']['input'];
  assetTypeID: Scalars['AssetTypeID']['input'];
  offChainOperationType: Scalars['String']['input'];
  payload: Scalars['String']['input'];
  vaultSubID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateOffchainVestingScheduleArgs = {
  accountID: Scalars['String']['input'];
  assetTypeID: Scalars['AssetTypeID']['input'];
  dryRun: Scalars['Boolean']['input'];
  vestingScheduleCSVBase64: Scalars['String']['input'];
  walletAddresses: Array<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreatePermissionGroupForTransfersArgs = {
  domain: TransferAccessRequestDomain;
  name: Scalars['String']['input'];
  vaultSubIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateReportArgs = {
  input: CreateReportInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateReportScheduleArgs = {
  input: CreateReportScheduleInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateSettlementArgs = {
  accountID?: InputMaybe<Scalars['ID']['input']>;
  includeAllUnsettledTrades?: InputMaybe<Scalars['Boolean']['input']>;
  organizationKeyID?: InputMaybe<Scalars['String']['input']>;
  tradeIDs: Array<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateStandingInstructionArgs = {
  standingInstruction: CreateStandingInstruction;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateTendermintClaimRewardsOneoffArgs = {
  assetSubID: Scalars['String']['input'];
  assetTypeID: Scalars['AssetTypeID']['input'];
  clientID: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  validatorAddress: Scalars['String']['input'];
  vaultSubID: Scalars['String']['input'];
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateTestModuleArgs = {
  name: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateTrustedSourcesArgs = {
  input: Array<InputCreateTrustedSource>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateTwoWaySettlementArgs = {
  acceptorID: Scalars['ID']['input'];
  clientReferenceID?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  proposerID: Scalars['ID']['input'];
  vaultUniqueID?: InputMaybe<Scalars['ID']['input']>;
  walletAmounts: Array<WalletAmountInput>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationcreateWalletGroupArgs = {
  assetTypeID: Scalars['AssetTypeID']['input'];
  name: Scalars['String']['input'];
  numberOfWallets: Scalars['Int']['input'];
  vaultSubID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationdeleteAffiliateArgs = {
  affiliateID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationdeleteClientArgs = {
  clientID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationdeleteClientOperationCommentArgs = {
  commentID: Scalars['String']['input'];
  commentSubID: Scalars['Int']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationdeleteKYCProcessTriggerArgs = {
  id: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationdeleteKYCQuestionArgs = {
  input: DeleteKYCQuestionInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationdeleteKYCQuestionnaireArgs = {
  input: DeleteKYCQuestionnaireInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationdeleteSettlementArgs = {
  settlementID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationdeleteTestModuleArgs = {
  id: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationdeleteUserPIIArgs = {
  piiType: Array<PIIInformationType>;
  userId: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationdeleteWebhookEndpointArgs = {
  id: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationdepositAddressCreateArgs = {
  walletID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationdepositScreeningArgs = {
  email: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  operationID: Scalars['String']['input'];
  reviewStatus: DepositScreeningStatus;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationdisableModuleForOperatorArgs = {
  moduleID: Scalars['ID']['input'];
  operator: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationdisableModuleForOrganizationsArgs = {
  moduleID: Scalars['ID']['input'];
  organizationKeyIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationdisableStandingInstructionArgs = {
  standingInstructionID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationeditClientOperationCommentArgs = {
  commentID: Scalars['String']['input'];
  commentSubID: Scalars['Int']['input'];
  message: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationeditDepositAttributionsArgs = {
  attributions: InputEditDepositAttributions;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationeditOperationDescriptionArgs = {
  message: Scalars['String']['input'];
  operationID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationeditSpamAttributionDataArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  operationIDs: Array<Scalars['OperationID']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationeditTrustedSourceStatusArgs = {
  disabledReason?: InputMaybe<TrustedSourceDisableReason>;
  id: Scalars['String']['input'];
  status: TrustedSourceStatus;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationeditTrustedSourcesArgs = {
  input: Array<InputEditTrustedSource>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationenableModuleForOperatorArgs = {
  moduleID: Scalars['ID']['input'];
  operator: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationenableModuleForOrganizationsArgs = {
  moduleID: Scalars['ID']['input'];
  organizationKeyIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationenableSubaccountsForOrganizationArgs = {
  affiliateID: Scalars['String']['input'];
  ledgerOnboard: Scalars['Boolean']['input'];
  orgID: Scalars['String']['input'];
  organizationType?: SubaccountOrganizationType;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationendorseArgs = {
  childControlKey?: InputMaybe<Scalars['String']['input']>;
  distressSignal?: InputMaybe<Scalars['Int']['input']>;
  encryptedControlMasterPrivateKey?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  operationID: Scalars['String']['input'];
  payload: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationenrollTokenArgs = {
  orgPolicyActions?: InputMaybe<Array<PolicyActionType>>;
  orgPolicyScopes?: InputMaybe<Array<ScopeToQuorumRequirements>>;
  vaultPolicyActions?: InputMaybe<Array<EnrollmentVaultPolicy>>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationestablishWalletConnectSessionArgs = {
  qrCode: Scalars['String']['input'];
  walletUniqueID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationexternalAccountLocationSaveArgs = {
  id: Scalars['ID']['input'];
  location: Scalars['String']['input'];
  notes: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationexternalAccountLocationsDeleteArgs = {
  id: Array<Scalars['ID']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationexternalAccountSaveArgs = {
  id: Scalars['ID']['input'];
  locationID: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  notes: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationexternalAccountTransactionSaveArgs = {
  accountID?: InputMaybe<Scalars['ID']['input']>;
  affiliateID?: InputMaybe<Scalars['ID']['input']>;
  assetTypeID: Scalars['AssetTypeID']['input'];
  executedAt: Scalars['Timestamp']['input'];
  externalAccountID: Scalars['ID']['input'];
  externalID: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  notes: Scalars['String']['input'];
  organizationKeyID: Scalars['ID']['input'];
  quantity: Scalars['String']['input'];
  transactionType: ExternalAccountTransactionType;
  vaultSubID?: InputMaybe<Scalars['ID']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationexternalAccountTransactionSetAccountIDForAllTransactionsInOrgArgs = {
  accountID: Scalars['ID']['input'];
  organizationKeyID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationexternalAccountTransactionSetAccountIDForTransactionArgs = {
  accountID: Scalars['ID']['input'];
  externalAccountTransactionID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationexternalAccountTransactionsDeleteArgs = {
  id: Array<Scalars['ID']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationexternalAccountsDeleteArgs = {
  id: Array<Scalars['ID']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationfeedbackSubmitGeneralArgs = {
  message: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationfeedbackSubmitNewAssetArgs = {
  requestedAsset: Scalars['String']['input'];
  volume: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationfillAffiliateFormDataArgs = {
  affiliateID: Scalars['String']['input'];
  createAnchorageIndividuals: Scalars['Boolean']['input'];
  createExtraIndividuals: Scalars['Boolean']['input'];
  individualNames?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationfillClientFormDataArgs = {
  clientID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationfinancingStatementUploadArgs = {
  domainSpecificID: Scalars['ID']['input'];
  month: Scalars['Int']['input'];
  orgID?: InputMaybe<Scalars['ID']['input']>;
  pdfContent: Scalars['String']['input'];
  statementType: StatementType;
  year: Scalars['Int']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationgenerateAPIKeyWithPayloadArgs = {
  description: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  expiration?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roleSubID?: InputMaybe<Scalars['String']['input']>;
  version: Scalars['Int']['input'];
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationgenerateMultiSignedURLsArgs = {
  uploadCaseFiles: Array<UploadAlertCaseFiles>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationgenerateSignedURLToUploadDocsForComplianceAlertsArgs = {
  documentName: Scalars['String']['input'];
  folderID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationgetFormSessionURLArgs = {
  formID: Scalars['String']['input'];
  formType: Scalars['String']['input'];
  useKycFormURLFormat?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationgetQuoteArgs = {
  request: QuoteRequestParams;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationiamRoleAddModuleArgs = {
  id: Scalars['ID']['input'];
  moduleID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationiamRoleAddUsersArgs = {
  id: Scalars['ID']['input'];
  organizationKeyID: Scalars['String']['input'];
  resources: Array<Scalars['String']['input']>;
  users: Array<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationiamRoleCreateArgs = {
  input: CreateIAMRoleInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationiamRoleDeleteArgs = {
  id: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationiamRoleRemoveAllModulesArgs = {
  roleID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationiamRoleUpdateArgs = {
  id: Scalars['ID']['input'];
  input: UpdateIAMRoleInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationiamRoleUpdateUsersArgs = {
  input: Array<UpdateIAMRoleUsersInput>;
  organizationKeyID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationinterestPaymentCreateArgs = {
  amount: Scalars['String']['input'];
  associatedID: Scalars['ID']['input'];
  associatedIDType: InterestPaymentCreateIDType;
  comments?: InputMaybe<Scalars['String']['input']>;
  operationID?: InputMaybe<Scalars['ID']['input']>;
  paymentDate: Scalars['Date']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationinterestPaymentDeleteArgs = {
  interestPaymentID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationinvalidateEnrollmentArgs = {
  enrollToken: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingCancelFacilityTransactionArgs = {
  transactionID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingDailyJobRunArgs = {
  requestType: DailyLendingJobRequestType;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingDepositRequestArgs = {
  amount: Scalars['String']['input'];
  assetType?: InputMaybe<Scalars['AssetTypeID']['input']>;
  lendingEntityID: Scalars['ID']['input'];
  lendingFacilityID?: InputMaybe<Scalars['ID']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingDepositTransferRequestArgs = {
  amount: Scalars['String']['input'];
  lendingEntityID: Scalars['ID']['input'];
  lendingFacilityID: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  walletID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingEntityCreateArgs = {
  accountID?: InputMaybe<Scalars['ID']['input']>;
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  emails: Array<Scalars['String']['input']>;
  entityTicker: Scalars['String']['input'];
  metadata?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgID?: InputMaybe<Scalars['ID']['input']>;
  side: LendingSide;
  status?: InputMaybe<EntityStatus>;
  vaultSubID?: InputMaybe<Scalars['ID']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingEntityDeleteArgs = {
  entityID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingEntityUpdateArgs = {
  accountID?: InputMaybe<Scalars['ID']['input']>;
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  entityID: Scalars['ID']['input'];
  entityTicker?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orgID?: InputMaybe<Scalars['ID']['input']>;
  side?: InputMaybe<LendingSide>;
  status?: InputMaybe<EntityStatus>;
  vaultSubID?: InputMaybe<Scalars['ID']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingFacilityCreateArgs = {
  assetType: Scalars['AssetTypeID']['input'];
  autoReinvestInterest?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy: Scalars['String']['input'];
  description: Scalars['String']['input'];
  fee?: InputMaybe<Scalars['Percent']['input']>;
  fixedYield?: InputMaybe<Scalars['Percent']['input']>;
  internal?: InputMaybe<Scalars['Boolean']['input']>;
  isFixedRate?: InputMaybe<Scalars['Boolean']['input']>;
  isOffChain?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  riskLevel: LendingFacilityRiskLevel;
  state: LendingFacilityState;
  totalAmount: Scalars['String']['input'];
  walletID?: InputMaybe<Scalars['ID']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingFacilityFeeCreateArgs = {
  feeDate: Scalars['Date']['input'];
  lendingFacilityID: Scalars['ID']['input'];
  percentageAmount: Scalars['Percent']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingFacilityFeeUpdateArgs = {
  feeDate: Scalars['Date']['input'];
  lendingFacilityID: Scalars['ID']['input'];
  percentageAmount: Scalars['Percent']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingFacilityMembershipCreateArgs = {
  autoReinvestInterest?: InputMaybe<Scalars['Boolean']['input']>;
  closeOut?: InputMaybe<Scalars['Boolean']['input']>;
  facilityID: Scalars['ID']['input'];
  fixedRate?: InputMaybe<Scalars['Percent']['input']>;
  lendingEntityID: Scalars['ID']['input'];
  maturityDate?: InputMaybe<Scalars['Date']['input']>;
  role: LendingFacilityMembershipRole;
  status: LendingFacilityMembershipStatus;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingFacilityMembershipDeleteArgs = {
  memberID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingFacilityMembershipUpdateArgs = {
  autoReinvestInterest?: InputMaybe<Scalars['Boolean']['input']>;
  closeOut?: InputMaybe<Scalars['Boolean']['input']>;
  fixedRate?: InputMaybe<Scalars['Percent']['input']>;
  maturityDate?: InputMaybe<Scalars['Date']['input']>;
  memberID: Scalars['ID']['input'];
  status: LendingFacilityMembershipStatus;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingFacilityReinvestmentTransactionsUpdateOperationIDArgs = {
  facilityID: Scalars['ID']['input'];
  month: Scalars['Int']['input'];
  operationID: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingFacilityTransactionCreateArgs = {
  amount: Scalars['String']['input'];
  facilityMemberID: Scalars['ID']['input'];
  fee?: InputMaybe<Scalars['String']['input']>;
  feeAsset?: InputMaybe<Scalars['AssetTypeID']['input']>;
  ignoredForRecon?: InputMaybe<Scalars['Boolean']['input']>;
  loanTermID?: InputMaybe<Scalars['ID']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  operationID?: InputMaybe<Scalars['String']['input']>;
  paymentMethod: LendingFacilityTransactionPaymentMethod;
  transactionStatus: LendingFacilityTransactionStatus;
  transactionTime: Scalars['Timestamp']['input'];
  transactionType: LendingFacilityTransactionType;
  userComments?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingFacilityTransactionDeleteArgs = {
  facilityTransactionID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingFacilityTransactionUpdateArgs = {
  amount?: InputMaybe<Scalars['String']['input']>;
  facilityMemberID?: InputMaybe<Scalars['ID']['input']>;
  facilityTransactionID: Scalars['ID']['input'];
  fee?: InputMaybe<Scalars['String']['input']>;
  feeAsset?: InputMaybe<Scalars['AssetTypeID']['input']>;
  ignoredForRecon?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  operationID?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<LendingFacilityTransactionPaymentMethod>;
  transactionStatus?: InputMaybe<LendingFacilityTransactionStatus>;
  transactionTime?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionType?: InputMaybe<LendingFacilityTransactionType>;
  userComments?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingFacilityUpdateArgs = {
  autoReinvestInterest?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  facilityID: Scalars['ID']['input'];
  fee?: InputMaybe<Scalars['Percent']['input']>;
  fixedYield?: InputMaybe<Scalars['Percent']['input']>;
  internal?: InputMaybe<Scalars['Boolean']['input']>;
  isFixedRate?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  riskLevel?: InputMaybe<LendingFacilityRiskLevel>;
  state?: InputMaybe<LendingFacilityState>;
  walletID?: InputMaybe<Scalars['ID']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingFeeReimbursementCreateArgs = {
  assetTypeID: Scalars['AssetTypeID']['input'];
  operationID: Scalars['ID']['input'];
  poolType: PoolType;
  reimbursedAmount: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingFeeReimbursementDeleteArgs = {
  feeReimbursementID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingFeeReimbursementUpdateArgs = {
  assetTypeID?: InputMaybe<Scalars['AssetTypeID']['input']>;
  feeReimbursementDate?: InputMaybe<Scalars['Timestamp']['input']>;
  feeReimbursementID: Scalars['ID']['input'];
  operationID?: InputMaybe<Scalars['ID']['input']>;
  owedFees?: InputMaybe<Scalars['String']['input']>;
  poolType?: InputMaybe<PoolType>;
  reimbursedAmount?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingInvoicesUpdateArgs = {
  comments?: InputMaybe<Scalars['String']['input']>;
  invoiceID: Scalars['ID']['input'];
  invoiceStatus?: InputMaybe<LendingInvoiceStatus>;
  metadata?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingMonthlyJobRunArgs = {
  requestType: MonthlyLendingJobRequestType;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingRevertJobRunArgs = {
  jobRunOptions?: InputMaybe<RevertJobOptions>;
  revertUntil: Scalars['Date']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingTransferRequestUpdateArgs = {
  comments?: InputMaybe<Scalars['String']['input']>;
  transferRequestID: Scalars['ID']['input'];
  transferRequestStatus: TransferRequestStatus;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingWithdrawalRequestArgs = {
  amount: Scalars['String']['input'];
  lendingEntityID: Scalars['ID']['input'];
  lendingFacilityID: Scalars['ID']['input'];
  walletID?: InputMaybe<Scalars['ID']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationlendingWithdrawalTransferRequestArgs = {
  amount: Scalars['String']['input'];
  lendingEntityID: Scalars['ID']['input'];
  lendingFacilityID: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  walletID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationloanCreateArgs = {
  approvalType?: InputMaybe<LoanApprovalType>;
  comments?: InputMaybe<Scalars['String']['input']>;
  docLink: Scalars['String']['input'];
  facilityMemberID: Scalars['ID']['input'];
  liquidityProof: Scalars['Boolean']['input'];
  loanTerms: LoanTermsInput;
  loanType: LoanType;
  secured: Scalars['Boolean']['input'];
  termStatus: LoanTermStatus;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationloanCreateCryptoCollateralsArgs = {
  cryptoCollaterals: Array<CryptoCollateralInput>;
  loanID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationloanCreateEquityCollateralsArgs = {
  equityCollaterals: Array<EquityCollateralInput>;
  loanID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationloanCreateMiningCollateralsArgs = {
  loanID: Scalars['ID']['input'];
  miningCollaterals: Array<MiningCollateralInput>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationloanDeleteCryptoCollateralArgs = {
  cryptoCollateralID: Scalars['ID']['input'];
  loanID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationloanDeleteEquityCollateralArgs = {
  equityCollateralID: Scalars['ID']['input'];
  loanID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationloanDeleteMiningCollateralArgs = {
  loanID: Scalars['ID']['input'];
  miningCollateralID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationloanRefinanceArgs = {
  approvalType?: InputMaybe<LoanApprovalType>;
  comments?: InputMaybe<Scalars['String']['input']>;
  docLink: Scalars['String']['input'];
  facilityMemberID: Scalars['ID']['input'];
  isMonitored?: InputMaybe<Scalars['Boolean']['input']>;
  liquidityProof: Scalars['Boolean']['input'];
  loanTerms: LoanTermsInput;
  loanType: LoanType;
  previousLoanID: Scalars['ID']['input'];
  secured: Scalars['Boolean']['input'];
  termStatus: LoanTermStatus;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationloanUpdateArgs = {
  comments?: InputMaybe<Scalars['String']['input']>;
  isMonitored?: InputMaybe<Scalars['Boolean']['input']>;
  liquidityProof?: InputMaybe<Scalars['Boolean']['input']>;
  loanID: Scalars['ID']['input'];
  loanTerms?: InputMaybe<LoanTermsInput>;
  loanType?: InputMaybe<LoanType>;
  secured?: InputMaybe<Scalars['Boolean']['input']>;
  termStatus?: InputMaybe<LoanTermStatus>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationmarkAffiliatesV2AndAccountsV2AsDeletedArgs = {
  affiliateIDs: Array<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationmergePersonsArgs = {
  personIDs: Array<Scalars['ID']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationoperationMakeVisibleInOperationsListArgs = {
  operationID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationoperationRejectArgs = {
  operationID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationperformManualDepositAttributionsArgs = {
  attributions: InputPerformManualDepositAttributions;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationprepareAddTrustedDestinationArgs = {
  amlChoices?: InputMaybe<Array<InputAMLChoice>>;
  amlQuestionnaire?: InputMaybe<InputQuestionnaireSubmission>;
  assetTypeID: Scalars['AssetTypeID']['input'];
  clientID: Scalars['String']['input'];
  description: Scalars['String']['input'];
  destinationAddress: Scalars['String']['input'];
  expiration?: InputMaybe<Scalars['String']['input']>;
  isNetworkLevel?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationKeyID?: InputMaybe<Scalars['String']['input']>;
  trustedDestinationExtraParams?: InputMaybe<InputTrustedDestinationExtraParams>;
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationprepareAuthorizeSpenderArgs = {
  amountAuthorized?: InputMaybe<Scalars['String']['input']>;
  assetSubID: Scalars['String']['input'];
  assetTypeID: Scalars['AssetTypeID']['input'];
  clientID: Scalars['String']['input'];
  expiration?: InputMaybe<Scalars['String']['input']>;
  extraParams?: InputMaybe<InputExtraParams>;
  message?: InputMaybe<Scalars['String']['input']>;
  organizationKeyID?: InputMaybe<Scalars['String']['input']>;
  vaultSubID: Scalars['String']['input'];
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationprepareCreateGenericOrganizationChangeArgs = {
  addAssetSupport?: InputMaybe<Array<AddAssetSupportInput>>;
  addQuorumRecoveries?: InputMaybe<Array<AddQuorumRecoveryInput>>;
  addTrustedDestinations?: InputMaybe<Array<AddTrustedDestinationsInput>>;
  addTrustedSmartContracts?: InputMaybe<Array<AddTrustedSmartContractInput>>;
  message?: InputMaybe<Scalars['String']['input']>;
  organizationKeyID?: InputMaybe<Scalars['String']['input']>;
  organizationRename?: InputMaybe<OrganizationRenameInput>;
  removeQuorumRecoveries?: InputMaybe<Array<RemoveQuorumRecoveryInput>>;
  removeTrustedSmartContracts?: InputMaybe<Array<RemoveTrustedSmartContractInput>>;
  removeUserKeys?: InputMaybe<Array<RemoveUserKeyInput>>;
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationprepareCreateVaultArgs = {
  accountID?: InputMaybe<Scalars['String']['input']>;
  clientID: Scalars['String']['input'];
  description: Scalars['String']['input'];
  expiration?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  policies?: InputMaybe<Array<InputPolicy>>;
  policyContainerUpdates?: InputMaybe<InputPolicyContainerUpdate>;
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationprepareDelegationArgs = {
  amount?: InputMaybe<Scalars['String']['input']>;
  assetSubID: Scalars['String']['input'];
  assetTypeID: Scalars['AssetTypeID']['input'];
  clientID: Scalars['String']['input'];
  delegateType?: InputMaybe<DelegateType>;
  destination: Scalars['String']['input'];
  expiration?: InputMaybe<Scalars['String']['input']>;
  extraParams?: InputMaybe<InputExtraParams>;
  message?: InputMaybe<Scalars['String']['input']>;
  organizationKeyID?: InputMaybe<Scalars['String']['input']>;
  vaultSubID: Scalars['String']['input'];
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationprepareDisableVaultArgs = {
  clientID: Scalars['String']['input'];
  expiration?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  vaultSubID: Scalars['String']['input'];
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationprepareLockArgs = {
  amount: Scalars['String']['input'];
  assetSubID: Scalars['String']['input'];
  assetTypeID: Scalars['AssetTypeID']['input'];
  clientID: Scalars['String']['input'];
  expiration?: InputMaybe<Scalars['String']['input']>;
  extraParams?: InputMaybe<InputExtraParams>;
  message?: InputMaybe<Scalars['String']['input']>;
  vaultSubID: Scalars['String']['input'];
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationprepareOneOffArgs = {
  assetSubID: Scalars['String']['input'];
  assetTypeID: Scalars['AssetTypeID']['input'];
  clientID: Scalars['String']['input'];
  expiration?: InputMaybe<Scalars['String']['input']>;
  extraParams?: InputMaybe<InputExtraParams>;
  message?: InputMaybe<Scalars['String']['input']>;
  oneOffParams: Array<InputOneOffParam>;
  oneOffType: OneOffType;
  vaultSubID: Scalars['String']['input'];
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationprepareRemoveTrustedDestinationArgs = {
  clientID: Scalars['String']['input'];
  expiration?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  trustedDestinationSubID: Scalars['String']['input'];
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationprepareRemoveUserArgs = {
  clientID: Scalars['String']['input'];
  expiration?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  publicKeyID: Scalars['PublicKeyID']['input'];
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationprepareUnlockArgs = {
  amount: Scalars['String']['input'];
  assetSubID: Scalars['String']['input'];
  assetTypeID: Scalars['AssetTypeID']['input'];
  clientID: Scalars['String']['input'];
  expiration?: InputMaybe<Scalars['String']['input']>;
  extraParams?: InputMaybe<InputExtraParams>;
  message?: InputMaybe<Scalars['String']['input']>;
  vaultSubID: Scalars['String']['input'];
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationprepareUpdateOrgPolicyArgs = {
  clientID: Scalars['String']['input'];
  expiration?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  policies?: InputMaybe<Array<InputPolicy>>;
  policyContainerUpdates?: InputMaybe<InputPolicyContainerUpdate>;
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationprepareUpdateVaultPolicyArgs = {
  clientID: Scalars['String']['input'];
  expiration?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  policyContainerUpdates?: InputMaybe<InputVaultPolicyContainerUpdate>;
  userAccessUpdates?: InputMaybe<Array<InputUserAccessUpdate>>;
  vaultPolicies?: InputMaybe<Array<InputVaultPolicy>>;
  visibleInOperationsList?: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationprepareVoteArgs = {
  assetSubID: Scalars['String']['input'];
  assetTypeID: Scalars['AssetTypeID']['input'];
  clientID: Scalars['String']['input'];
  expiration?: InputMaybe<Scalars['String']['input']>;
  extraParams?: InputMaybe<InputExtraParams>;
  message?: InputMaybe<Scalars['String']['input']>;
  vaultSubID: Scalars['String']['input'];
  visibleInOperationsList?: Scalars['Boolean']['input'];
  voteYeas: Array<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationprepareWithdrawalArgs = {
  amlChoices?: InputMaybe<Array<InputAMLChoice>>;
  amlQuestionnaire?: InputMaybe<InputQuestionnaireSubmission>;
  amount: Scalars['String']['input'];
  assetSubID?: InputMaybe<Scalars['String']['input']>;
  assetTypeID: Scalars['AssetTypeID']['input'];
  clientID: Scalars['String']['input'];
  destination?: InputMaybe<Scalars['String']['input']>;
  destinationAssetSubID?: InputMaybe<Scalars['String']['input']>;
  destinationSNParticipantID?: InputMaybe<Scalars['String']['input']>;
  destinationVaultSubID?: InputMaybe<Scalars['String']['input']>;
  destinationWalletID?: InputMaybe<Scalars['ID']['input']>;
  expiration?: InputMaybe<Scalars['String']['input']>;
  extraParams?: InputMaybe<InputExtraParams>;
  feeCustomization?: InputMaybe<FeeCustomization>;
  message?: InputMaybe<Scalars['String']['input']>;
  tokenID?: InputMaybe<Scalars['String']['input']>;
  vaultSubID?: InputMaybe<Scalars['String']['input']>;
  visibleInOperationsList?: Scalars['Boolean']['input'];
  walletID?: InputMaybe<Scalars['ID']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationregisterDeviceTokenArgs = {
  deviceToken: Scalars['String']['input'];
  topic: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationregisterManualTradeArgs = {
  accountID?: InputMaybe<Scalars['ID']['input']>;
  amountBought: Scalars['String']['input'];
  amountSold: Scalars['String']['input'];
  assetBought: Scalars['String']['input'];
  assetSold: Scalars['String']['input'];
  capacity: TradeCapacity;
  createdAt: Scalars['Timestamp']['input'];
  fee: Scalars['String']['input'];
  feeCurrency: Scalars['String']['input'];
  orderType?: InputMaybe<OrdType>;
  organizationKeyID: Scalars['String']['input'];
  parentTradeID?: InputMaybe<Scalars['ID']['input']>;
  price: Scalars['String']['input'];
  side?: InputMaybe<TradeSide>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationregisterWebhookEndpointArgs = {
  in: NewWebhookEndpointInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationremoveAffiliateRepresentativeArgs = {
  affiliateID: Scalars['String']['input'];
  representativeEmail: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationremoveClientRepresentativeArgs = {
  clientID: Scalars['String']['input'];
  representativeEmail: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationremoveIndividualFromAffiliateArgs = {
  affiliateID: Array<Scalars['ID']['input']>;
  individualID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationremoveIndividualRepresentativeArgs = {
  individualID: Scalars['String']['input'];
  representativeEmail: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationremoveRelatedPartyRelationshipsArgs = {
  affiliateID: Array<Scalars['ID']['input']>;
  relatedPartyID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationremoveTrustedSourcesArgs = {
  trustedSouceIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationreplaceIndividualInClientArgs = {
  clientID: Scalars['String']['input'];
  individualID: Scalars['String']['input'];
  replacementIndividualID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationreplaceTokenArgs = {
  userKeyID?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationrequestLendingAccessArgs = {
  accountID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationresendAffiliateFormArgs = {
  affiliateID: Scalars['String']['input'];
  representativeEmail: Scalars['String']['input'];
  useKycFormURLFormat?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationresendClientFormArgs = {
  clientID: Scalars['String']['input'];
  representativeEmail: Scalars['String']['input'];
  useKycFormURLFormat?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationresendIndividualFormArgs = {
  individualID: Scalars['String']['input'];
  representativeEmail: Scalars['String']['input'];
  useKycFormURLFormat?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationresetAllowedVaultsForDAppArgs = {
  allowedVaultSubIDs: Array<Scalars['String']['input']>;
  dappID: Scalars['String']['input'];
  organizationKeyID?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationrestoreClientOperationCommentArgs = {
  commentID: Scalars['String']['input'];
  commentSubID: Scalars['Int']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationrevokeAPIKeyWithErrorsArgs = {
  keyID: Scalars['String']['input'];
  reportCompromised?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationriaCreatFeeConfigurationArgs = {
  input: CreateFeeConfig;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationriskEndorseArgs = {
  decision: OperationDecision;
  operationID: Scalars['String']['input'];
  overrideRequiredApprovals?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationrollbackKYCInformationArgs = {
  auditID: Scalars['ID']['input'];
  notes: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsanitizeAddressArgs = {
  address: Scalars['String']['input'];
  networkID: Scalars['NetworkID']['input'];
  validationPurpose?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsaveDocumentDeliverySettingArgs = {
  isOrgLevel: Scalars['Boolean']['input'];
  organizationID: Scalars['ID']['input'];
  settingType: SettingType;
  settings?: InputMaybe<Array<InputDocDeliverySetting>>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsaveKYCQuestionnaireSubmissionArgs = {
  input: SaveKYCQuestionnaireSubmissionInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsendInvitationEmailForEnrollmentArgs = {
  enrollToken: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsendKYCEventArgs = {
  input: SendKYCEventInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsendKYCQuestionnaireSubmissionArgs = {
  input: SendKYCQuestionnaireSubmissionInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsendUnsentStatementEmailsArgs = {
  organizationID: Scalars['String']['input'];
  statementTypes?: InputMaybe<Array<StatementType>>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsetCryptoOnlyArgs = {
  isCryptoOnly: Scalars['Boolean']['input'];
  organizationKeyID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsetEnabledWebhookEventTypesArgs = {
  in: Array<Scalars['String']['input']>;
  orgID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsetExemptPIIArgs = {
  enrollToken: Scalars['String']['input'];
  informationType: IndividualInformationType;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsetFeatureEnabledArgs = {
  enabled: Scalars['Boolean']['input'];
  featureName: FeatureName;
  organizationKeyID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsetFormStatusArgs = {
  formID: Scalars['String']['input'];
  formStatus: FormStatus;
  formType: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsetLostDeviceArgs = {
  recovered?: InputMaybe<Scalars['Boolean']['input']>;
  userKeyID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsetNotificationCountArgs = {
  count: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsetOrganizationDisabledArgs = {
  disabled: Scalars['Boolean']['input'];
  organizationKeyID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsetOrganizationNotesArgs = {
  notes: Scalars['String']['input'];
  organizationKeyID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsetOrganizationWireFeeConfigArgs = {
  chargeWireFee: Scalars['Boolean']['input'];
  organizationKeyID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsetSLAForOrgArgs = {
  organizationKeyID: Scalars['String']['input'];
  sla: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsetTradersArgs = {
  input: Array<SetTraderInput>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsetUserPIIArgs = {
  piis: Array<InputMaybe<InputPII>>;
  userId: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsetWalletKeyExportPreferenceArgs = {
  deliveryChannel: WalletKeyExportChannel;
  email?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsnAddTrustedCounterpartiesArgs = {
  counterpartyID: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  ownerIDs: Array<Scalars['ID']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsnParticipantCreateArgs = {
  accountID: Scalars['String']['input'];
  customDefaultWallets?: InputMaybe<Array<ReplaceDefaultWalletInput>>;
  defaultVaultUniqueID?: InputMaybe<Scalars['String']['input']>;
  organizationID: Scalars['String']['input'];
  tier?: InputMaybe<SNParticipantTier>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsnParticipantUpdateArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  customDefaultWallets?: InputMaybe<Array<DefaultWalletInput>>;
  defaultVaultUniqueID?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  replaceCustomDefaultWallets?: InputMaybe<Array<ReplaceDefaultWalletInput>>;
  tier?: InputMaybe<SNParticipantTier>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsnSettlementAcceptArgs = {
  settlementID: Scalars['ID']['input'];
  vaultID?: InputMaybe<Scalars['ID']['input']>;
  vaultUniqueID?: InputMaybe<Scalars['ID']['input']>;
  walletAmounts: Array<WalletAmountInput>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsnSettlementApprovalOverrideArgs = {
  approve: Scalars['Boolean']['input'];
  participantID: Scalars['ID']['input'];
  settlementID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsnSettlementRejectArgs = {
  settlementID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsnUpdateTrustedCounterpartyArgs = {
  counterpartyID: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  ownerID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationstagedOrganizationCompleteArgs = {
  orgToken: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationstagedOrganizationCreateArgs = {
  backupStrategy?: InputMaybe<WalletKeyBackupStrategy>;
  clientID: Scalars['String']['input'];
  hsmZoneID: Scalars['String']['input'];
  name: Scalars['String']['input'];
  operator: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationstagedOrganizationDeleteArgs = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
  orgToken: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationstagedOrganizationUpdateArgs = {
  newDefinition: Scalars['String']['input'];
  oldDefinition: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationstagedOrganizationUpdateV2Args = {
  enrollTokensToEdit?: InputMaybe<Array<StagedTokenInput>>;
  enrollTokensToRemove?: InputMaybe<Array<Scalars['String']['input']>>;
  individualsToAdd?: InputMaybe<Array<StagedTokenInput>>;
  orgToken: Scalars['String']['input'];
  signaturesRequired?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationstartClientFormsArgs = {
  clientID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationstartDynamicCRRCalculationArgs = {
  affiliateIDs: Array<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationstartOrganizationRecoveryArgs = {
  lostUserKeyIDs: Array<Scalars['String']['input']>;
  organizationKeyID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsubaccountGasFillWalletArgs = {
  amount: AmountInput;
  walletID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsubaccountSweepLinkedWalletArgs = {
  walletID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsubmitEditedQuestionnaireArgs = {
  answers: Array<InputQuestionnaireAnswer>;
  complete?: InputMaybe<Scalars['Boolean']['input']>;
  submissionID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsubmitPIIArgs = {
  entries: Array<InputPII>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsubmitStagedControlKeysArgs = {
  controlMasterPublicKey: Scalars['String']['input'];
  userKeys: Array<InputEncryptedControlMasterPrivateKey>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationsubmitStagedWalletRecoveryKeySharesArgs = {
  encryptedShares: Array<InputEncryptedWalletRecoveryKeyShares>;
  walletRecoveryKeyAlgorithm: Scalars['String']['input'];
  walletRecoveryPublicKey: Scalars['String']['input'];
  walletRecoveryPublicKeySignature: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtaxCreateTenantArgs = {
  input: CreateTenantInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtaxDeleteTenantArgs = {
  tenantID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtaxUpdateTenantArgs = {
  input: UpdateTenantInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationterminateWalletConnectSessionArgs = {
  connectionID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationterminateWalletConnectSessionsArgs = {
  dappID: Scalars['String']['input'];
  organizationKeyID: Scalars['String']['input'];
  userKeyIDs: Array<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradeQuoteArgs = {
  accountID?: InputMaybe<Scalars['String']['input']>;
  allocationRequest?: InputMaybe<Scalars['Boolean']['input']>;
  quoteID: Scalars['String']['input'];
  side: TradeSide;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingAddSettlementAddressArgs = {
  address: Scalars['String']['input'];
  assetTypeID: Scalars['AssetTypeID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingAddSymbolArgs = {
  entity?: InputMaybe<AnchorageEntityType>;
  symbol: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingApproveSymbolArgs = {
  entity?: InputMaybe<AnchorageEntityType>;
  symbol: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingApproveSymbolByIDArgs = {
  id: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingAssignClientSettlementArgs = {
  isUnassign?: InputMaybe<Scalars['Boolean']['input']>;
  orgKeyID: Scalars['String']['input'];
  settlementID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingCancelClientSettlementsArgs = {
  orgKeyID: Scalars['String']['input'];
  settlementIDs: Array<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingCounterpartyCreateArgs = {
  displayName: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
  kind: CounterpartyKind;
  name: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingCounterpartyUpdateArgs = {
  id: Scalars['ID']['input'];
  isActive: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingCreateAccountArgs = {
  accountID?: InputMaybe<Scalars['ID']['input']>;
  enable?: InputMaybe<Scalars['Boolean']['input']>;
  limits?: InputMaybe<Array<AmountInput>>;
  name: Scalars['String']['input'];
  organizationKeyID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingCreateAssetArgs = {
  input: TradingCreateAssetInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingCreateClientSettlementArgs = {
  assetTypeID: Scalars['String']['input'];
  orgKeyID: Scalars['String']['input'];
  paymentFlowIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingCreateExternalAssetArgs = {
  input: TradingCreateExternalAssetInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingCreateFeeClientSettlementArgs = {
  feeTransactionID: Scalars['String']['input'];
  orgKeyID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingCreateSettlementFromFiltersArgs = {
  filters: TradingCreateSettlementFromFiltersInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingCreateTradingPermissionArgs = {
  input: TradingPermissionsInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingCreateWalletSettlementInstructionsArgs = {
  assetTypeID: Scalars['AssetTypeID']['input'];
  walletUniqueID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingCreateWhitelabelAccountArgs = {
  accountID: Scalars['String']['input'];
  accountTalosAlias: Scalars['String']['input'];
  organizationKeyID: Scalars['String']['input'];
  talosCustomer?: InputMaybe<Scalars['String']['input']>;
  talosCustomerID?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingCreateWhitelabelUserArgs = {
  auth0ID: Scalars['String']['input'];
  email: Scalars['String']['input'];
  organizationKeyID: Scalars['String']['input'];
  otpSeed?: InputMaybe<Scalars['String']['input']>;
  otpSeedEncryptionAlgo?: InputMaybe<Scalars['Int']['input']>;
  userKeyID?: InputMaybe<Scalars['String']['input']>;
  userKeyIDType?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingDealerManualTradeCancelArgs = {
  id: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingDealerManualTradeCreateArgs = {
  amount: Scalars['String']['input'];
  amountCurrency: Scalars['String']['input'];
  currency: Scalars['String']['input'];
  entity?: InputMaybe<Scalars['String']['input']>;
  execID?: InputMaybe<Scalars['ID']['input']>;
  internalSettlementID?: InputMaybe<Scalars['ID']['input']>;
  isHistorical?: InputMaybe<Scalars['Boolean']['input']>;
  market: Scalars['String']['input'];
  ordType: OrdType;
  parentOrderID: Scalars['ID']['input'];
  price: Scalars['String']['input'];
  quantity: Scalars['String']['input'];
  side: TradeSide;
  symbol: Scalars['String']['input'];
  transactTime: Scalars['Timestamp']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingDealerManualTradeUpdateArgs = {
  ID: Scalars['ID']['input'];
  amount?: InputMaybe<Scalars['String']['input']>;
  amountCurrency?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  execID?: InputMaybe<Scalars['ID']['input']>;
  market?: InputMaybe<Scalars['String']['input']>;
  ordType?: InputMaybe<OrdType>;
  parentOrderID?: InputMaybe<Scalars['ID']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['String']['input']>;
  side?: InputMaybe<TradeSide>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  transactTime?: InputMaybe<Scalars['Timestamp']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingDealerSettlementCancelArgs = {
  id: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingDealerSettlementCreateArgs = {
  paymentFlowIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingDealerSettlementCreateCounterpartyAssetArgs = {
  assetType: Scalars['AssetTypeID']['input'];
  counterparty: Scalars['String']['input'];
  entity?: InputMaybe<AnchorageEntityType>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingDealerSettlementCreateFromFiltersArgs = {
  input: DealerSettlementCreateInput;
  isPreview: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingDealerSettlementInitiateArgs = {
  id: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingDealerSettlementSettleArgs = {
  id: Scalars['ID']['input'];
  transactionIDs: Array<Scalars['ID']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingDealerSettlementUpdateTransactionIDsArgs = {
  id: Scalars['ID']['input'];
  transactionIDs: Array<Scalars['ID']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingDeleteAssetArgs = {
  input: TradingDeleteAssetInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingDeleteExternalAssetArgs = {
  input: TradingDeleteExternalAssetInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingDeleteTradeSettlementTransactionArgs = {
  tradeSettlementTransactionID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingDeleteWalletSettlementInstructionArgs = {
  instructionID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingDeleteWhitelabelUserArgs = {
  id: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingEnableSymbolByIDArgs = {
  id: Scalars['ID']['input'];
  isEnabled: Scalars['Boolean']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingLinkDealerAndClientTradesArgs = {
  clientTradeID: Scalars['String']['input'];
  dealerTradeID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingProcessClientSettlementArgs = {
  orgKeyID: Scalars['String']['input'];
  settlementID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingReinitiateClientSettlementArgs = {
  orgKeyID: Scalars['String']['input'];
  settlementID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingSaveTradeSettlementTransactionArgs = {
  assetTypeID: Scalars['AssetTypeID']['input'];
  settlementID: Scalars['String']['input'];
  transactionID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingUpdateAccountsArgs = {
  organizationKeyID: Scalars['ID']['input'];
  updateAccounts: Array<UpdateAccountInput>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingUpdateAssetPrecisionArgs = {
  active: Scalars['Boolean']['input'];
  baseSizeScale: Scalars['Int']['input'];
  minimumOrderSize?: InputMaybe<Scalars['Decimal']['input']>;
  minimumOrderSizeString?: InputMaybe<Scalars['String']['input']>;
  priceScale: Scalars['Int']['input'];
  quoteSizeScale: Scalars['Int']['input'];
  symbol: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingUpdateFiatSettlementTransactionArgs = {
  orgKeyID: Scalars['String']['input'];
  settlementID: Scalars['String']['input'];
  transactionID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingUpdateSettlementAddressArgs = {
  address: Scalars['String']['input'];
  assetTypeID: Scalars['AssetTypeID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingUpdateSymbolArgs = {
  entity?: InputMaybe<AnchorageEntityType>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  newSymbol?: InputMaybe<Scalars['String']['input']>;
  symbol: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingUpdateSymbolByIDArgs = {
  id: Scalars['ID']['input'];
  newEntity?: InputMaybe<AnchorageEntityType>;
  newSymbol?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingUpdateTradeSettlementTransactionArgs = {
  assetTypeID?: InputMaybe<Scalars['AssetTypeID']['input']>;
  tradeSettlementTransactionID: Scalars['String']['input'];
  transactionID?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingUpdateWhitelabelAccountArgs = {
  accountID?: InputMaybe<Scalars['String']['input']>;
  accountTalosAlias?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  organizationKeyID?: InputMaybe<Scalars['String']['input']>;
  talosCustomer?: InputMaybe<Scalars['String']['input']>;
  talosCustomerID?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtradingUpdateWhitelabelUserArgs = {
  auth0ID?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  organizationKeyID?: InputMaybe<Scalars['String']['input']>;
  userKeyID?: InputMaybe<Scalars['String']['input']>;
  userKeyIDType?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtravelRuleSetAutomationArgs = {
  automationEnabled: Scalars['Boolean']['input'];
  operationID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationtriggerWebhookArgs = {
  endpointID: Scalars['ID']['input'];
  in: TriggerWebhookInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationunarchiveWalletsArgs = {
  walletIDs: Array<Scalars['ID']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateAPIKeyArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  keyID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateAccountsFreezeWithdrawalArgs = {
  updates: Array<AccountFreezeWithdrawalInput>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateAffiliateArgs = {
  affiliateID?: InputMaybe<Scalars['String']['input']>;
  affiliateType?: InputMaybe<Scalars['String']['input']>;
  anchorageEntityTypes: Array<AnchorageEntityType>;
  clientID: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parentAffiliateID?: InputMaybe<Scalars['String']['input']>;
  topLevelAffiliateID?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateAffiliateDocusignTemplateArgs = {
  affiliateID: Scalars['ID']['input'];
  templateID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateAffiliateInformationArgs = {
  affiliateID: Scalars['ID']['input'];
  entries: Array<InputKnownInformation>;
  kycScreening?: InputMaybe<KYCScreeningInput>;
  notes: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateAffiliateV2NameArgs = {
  affiliateID: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateAffiliatesFreezeWithdrawalArgs = {
  updates: Array<AffiliateFreezeWithdrawalInput>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateAssetMetadataArgs = {
  assetSubID: Scalars['String']['input'];
  assetTypeID: Scalars['AssetTypeID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  vaultSubID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateAutoCompoundArgs = {
  assetSubID: Scalars['String']['input'];
  assetTypeID: Scalars['AssetTypeID']['input'];
  enabled: Scalars['Boolean']['input'];
  organizationID: Scalars['String']['input'];
  vaultSubID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateCRRRiskParametersArgs = {
  crrConfigs: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateClientArgs = {
  anchorageEntityTypes: Array<AnchorageEntityType>;
  clientID?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateClientInformationArgs = {
  clientID: Scalars['ID']['input'];
  entries: Array<InputKnownInformation>;
  notes: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateClientNameArgs = {
  clientID: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateDepositAttributionSpamThresholdArgs = {
  assetTypeID: Scalars['AssetTypeID']['input'];
  input: UpdateDepositAttributionSpamThresholdInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateGlobalTradingLimitArgs = {
  newLimit: Scalars['Int']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateIndividualAndRelationshipsArgs = {
  affiliateID: Scalars['ID']['input'];
  entries?: InputMaybe<Array<InputIndividualInformation>>;
  individualID: Scalars['ID']['input'];
  kycRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  kycScreening?: InputMaybe<KYCScreeningInput>;
  ownershipPercentage?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateIndividualInformationArgs = {
  entries: Array<InputKnownInformation>;
  individualID: Scalars['ID']['input'];
  kycScreening?: InputMaybe<KYCScreeningInput>;
  notes: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateKYCProcessTriggerArgs = {
  id: Scalars['ID']['input'];
  input: UpdateKYCProcessTriggerInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateKYCQuestionArgs = {
  id: Scalars['ID']['input'];
  input: UpdateKYCQuestionInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateKYCQuestionnaireArgs = {
  id: Scalars['ID']['input'];
  input: UpdateKYCQuestionnaireInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateMarketingWidgetArgs = {
  id: Scalars['ID']['input'];
  interaction: WidgetInteraction;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateNetworkStatusArgs = {
  additionalInfoUrl?: InputMaybe<Scalars['String']['input']>;
  isDegraded: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  networkID: Scalars['NetworkID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateRIATermsQuestionnaireArgs = {
  affiliateID: Scalars['ID']['input'];
  questionnaireName: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateRelatedPartyRelationshipsArgs = {
  affiliateID: Scalars['ID']['input'];
  kycRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  kycScreening?: InputMaybe<KYCScreeningInput>;
  ownershipPercentage?: InputMaybe<Scalars['String']['input']>;
  relatedPartyID: Scalars['ID']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateReportScheduleArgs = {
  input: UpdateReportScheduleInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateRoleMetadataArgs = {
  name: Scalars['String']['input'];
  roleSubID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateSettlementStatusArgs = {
  note: Scalars['String']['input'];
  settlementID: Scalars['String']['input'];
  status: TradeStatus;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateTradeApprovalByComplianceTeamArgs = {
  enabled: Scalars['Boolean']['input'];
  organizationKeyID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateTradeApprovalByTradingTeamArgs = {
  enabled: Scalars['Boolean']['input'];
  organizationKeyID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateTradeCommissionRateArgs = {
  organizationKeyID: Scalars['String']['input'];
  tradeCommissionBPS: Scalars['Int']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateTradeCreditLimitArgs = {
  creditLimit: Scalars['String']['input'];
  organizationKeyID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateTradeSettingsArgs = {
  RFQTTL?: InputMaybe<Scalars['String']['input']>;
  acceptPriceLeniency?: InputMaybe<Scalars['String']['input']>;
  acceptTimeLeniency?: InputMaybe<Scalars['String']['input']>;
  organizationKeyID: Scalars['String']['input'];
  priceImprovementRatio?: InputMaybe<Scalars['String']['input']>;
  pricesTimeout?: InputMaybe<Scalars['String']['input']>;
  quoteTTL?: InputMaybe<Scalars['String']['input']>;
  useFintechFlow?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateTradeStatusArgs = {
  note: Scalars['String']['input'];
  organizationKeyID: Scalars['String']['input'];
  status: TradeStatus;
  tradeID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateTradingEmailRecipientsArgs = {
  emails: Array<Scalars['String']['input']>;
  organizationKeyID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateUnsettledManualTradeArgs = {
  accountID?: InputMaybe<Scalars['ID']['input']>;
  amountBought: Scalars['String']['input'];
  amountSold: Scalars['String']['input'];
  assetBought: Scalars['String']['input'];
  assetSold: Scalars['String']['input'];
  capacity: TradeCapacity;
  createdAt: Scalars['Timestamp']['input'];
  fee: Scalars['String']['input'];
  feeCurrency: Scalars['String']['input'];
  orderType?: InputMaybe<OrdType>;
  organizationKeyID: Scalars['String']['input'];
  parentTradeID?: InputMaybe<Scalars['ID']['input']>;
  price: Scalars['String']['input'];
  side: TradeSide;
  tradeID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateUserSettingsArgs = {
  tradeDailyDigestEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  tradeNotificationPerTransactionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  userKeyID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateVaultAccountArgs = {
  accountID: Scalars['String']['input'];
  organizationKeyID: Scalars['String']['input'];
  vaultSubID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateVaultMetadataArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  vaultSubID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationupdateWebhookEndpointArgs = {
  id: Scalars['ID']['input'];
  in: EditWebhookEndpointInput;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationuploadAccountStatementArgs = {
  accountID: Scalars['String']['input'];
  displayDateDay: Scalars['Int']['input'];
  displayDateMonth: Scalars['Int']['input'];
  displayDateYear: Scalars['Int']['input'];
  fileBase64: Scalars['String']['input'];
  orgKeyID?: InputMaybe<Scalars['String']['input']>;
  statementType: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationuploadAffiliateInformationArgs = {
  affiliateID: Scalars['ID']['input'];
  digestOnly?: InputMaybe<Scalars['Boolean']['input']>;
  fileBase64: Scalars['String']['input'];
  informationType: Scalars['String']['input'];
  kycScreening?: InputMaybe<KYCScreeningInput>;
  notes: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationuploadIndividualInformationArgs = {
  fileBase64: Scalars['String']['input'];
  individualID: Scalars['ID']['input'];
  informationType: Scalars['String']['input'];
  kycScreening?: InputMaybe<KYCScreeningInput>;
  notes: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationuploadTrustedDestinationsCSVArgs = {
  fileBase64: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationuploadTrustedSmartContractsCSVArgs = {
  csvFileToUploadBase64: Scalars['String']['input'];
  orgID?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationwalletConnectApproveSessionArgs = {
  sessionID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationwalletConnectInitializeSessionArgs = {
  uri: Scalars['String']['input'];
  walletUniqueID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationwalletConnectRejectSessionArgs = {
  reason?: InputMaybe<Scalars['String']['input']>;
  sessionID: Scalars['ID']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationwalletCreateArgs = {
  name: Scalars['String']['input'];
  networkID: Scalars['ID']['input'];
  vaultSubID: Scalars['String']['input'];
};


/** Mutation exposes all methods that allow information to be updated on the backend */
export type MutationwalletRenameArgs = {
  name: Scalars['String']['input'];
  walletID: Scalars['ID']['input'];
};

/** NFTCollection defines a NFT collection metadata */
export type NFTCollection = {
  __typename: 'NFTCollection';
  /** Further information regarding this asset type */
  assetTypeInfo: AssetTypeInfo;
  /** Token type for the collection (e.g. ERC721) */
  collectionType: Scalars['String']['output'];
  /** Image URL of the most recently added NFT, max 9 */
  images: Array<Maybe<Scalars['String']['output']>>;
  /** The last NFT Token added to the collection */
  latestToken: NftTokenInfo;
  /** Collection Name */
  name: Scalars['String']['output'];
  /** Number of tokens from this collection we currently have */
  tokenCount: Scalars['Int']['output'];
  /** Total price of all NFT tokens from this collection */
  totalPrice: Amount;
};

/**
 * NamedBalance represent balance labeled with holds or other allocations over assets in the same portfolio with the same AssetTypeID.
 * Except for holds placed by API Key, the data is grouped by hold type, with the balance being the sum of the amount over all holds of that type.
 * For holds placed by API Key,
 */
export type NamedBalance = {
  __typename: 'NamedBalance';
  /**
   * amount represents the total amount of all holds of a specific hold type (that is not HoldTypePlacedByAPIKey),
   * and represents the amount of a specific hold if it is of HoldTypePlacedByAPIKey.
   */
  amount: Amount;
  /** apiKeyName represents the API Key name. Its value will be set if the hold is of HoldTypePlacedByAPIKey, otherwise it may be empty. */
  apiKeyName: Maybe<Scalars['String']['output']>;
  /**
   * description represents the HoldType, the types/reasons a hold can occur for holds not placed by API key,
   * and represents the
   */
  description: Scalars['String']['output'];
  /** holdID represents the hold ID. Its value will be set if the hold is of HoldTypePlacedByAPIKey, otherwise it may be empty. */
  holdID: Maybe<Scalars['String']['output']>;
};

/** NetworkInfo contains information about the blockchain network on which assets are traded */
export type NetworkInfo = {
  __typename: 'NetworkInfo';
  /**
   * iconURL returns the url for fetching the icon for this network.
   * If the type is SVG, the size is ignored.
   * Icons are square.
   * The size is in pixels.
   */
  iconURL: Scalars['String']['output'];
  /** Returns the name of the Network in the form of a string */
  name: Scalars['String']['output'];
  /** Returns the ID of the native asset type for this network */
  nativeAssetTypeID: Scalars['AssetTypeID']['output'];
  /** Returns the ID of the Network */
  networkID: Scalars['ID']['output'];
  /** Information about the network when it is in degraded status (null if it's not degraded) */
  networkStatus: Maybe<NetworkStatus>;
  /** True if the network supports multiple addresses per wallet (ex. bitcoin) */
  supportsMultipleAddressesPerWallet: Scalars['Boolean']['output'];
  /** True if the network supports network level trusted destination (TD can be used on any asset on this network) */
  supportsNetworkLevelTrustedDestination: Scalars['Boolean']['output'];
};


/** NetworkInfo contains information about the blockchain network on which assets are traded */
export type NetworkInfoiconURLArgs = {
  iconType?: IconType;
  size?: Scalars['Int']['input'];
};

/** NetworkStatus contains information when a network is in degraded status */
export type NetworkStatus = {
  __typename: 'NetworkStatus';
  /** optional link for more information (null if no additional information available) */
  additionalInfoUrl: Maybe<Scalars['String']['output']>;
  /** longer description of the network status */
  message: Scalars['String']['output'];
  /** Returns the ID of the Network */
  networkID: Scalars['NetworkID']['output'];
  /** short title describing the network status */
  title: Scalars['String']['output'];
};

/** Payload response for submitNewAssetFeedback mutation */
export type NewAssetFeedbackResponse = {
  __typename: 'NewAssetFeedbackResponse';
  /** Was request successful */
  success: Scalars['Boolean']['output'];
};

export type NewWebhookEndpointInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  orgID?: InputMaybe<Scalars['String']['input']>;
  publicKey: Scalars['String']['input'];
  subscriptions?: InputMaybe<Array<Scalars['ID']['input']>>;
  url: Scalars['String']['input'];
};

/** Token info for an NFT like token */
export type NftTokenInfo = TokenInfo & {
  __typename: 'NftTokenInfo';
  /** When this NFT was first deposited */
  addedAt: Maybe<Scalars['String']['output']>;
  /** The asset type id for this token */
  assetTypeID: Scalars['AssetTypeID']['output'];
  /** Value of the token type (formatted for display) */
  displayValue: Maybe<Scalars['String']['output']>;
  /** The image url of this NFT */
  imageURL: Maybe<Scalars['String']['output']>;
  /** The name of the NFT */
  name: Scalars['String']['output'];
  /** The unique id for this token */
  tokenID: Scalars['String']['output'];
  /** The Explorer URL for this NFT */
  url: Scalars['String']['output'];
  /** Value of the token type (as a raw decimal value) */
  value: Maybe<Scalars['String']['output']>;
};

/** Node interface defined for objects with a Globally Unique ID. Implemented as defined in the Shopify GraphQL Tutorial, among other places. Reference link: https://github.com/Shopify/graphql-design-tutorial/blob/master/TUTORIAL.md */
export type Node = {
  /** The globally unique ID of the object */
  id: Scalars['ID']['output'];
};

/** Details for a validator that doesn't use a network address as its identifier */
export type NonAddressValidatorDetail = {
  __typename: 'NonAddressValidatorDetail';
  /** The validator identifier can be any string */
  identifier: Scalars['String']['output'];
  /** The user visible label of the identifier to show in UI (e.g. "Node ID") */
  identifierLabel: Scalars['String']['output'];
};

/** Notice is a notification with paragraphs and color. */
export type Notice = {
  __typename: 'Notice';
  /** Paragraph representing the notice content */
  content: Paragraph;
  /** Type of the notice that has a matching style */
  type: NoticeType;
};

/** NoticeType is an Enum representing the severity of a Notice. */
export enum NoticeType {
  /** Error notice */
  ERROR = 'ERROR',
  /** Information notice */
  INFO = 'INFO',
  /** Warning notice */
  WARNING = 'WARNING'
}

/** Extra parameters for oasis undelegate */
export type OasisUndelegateExtraParams = {
  __typename: 'OasisUndelegateExtraParams';
  /** actionType is used to construct the canonical operation */
  actionType: Maybe<ExtraParamsActionType>;
  /** estimatedSharesAmount is the estimate value in ROSE that the numShares corresponds to based on the conversion rate at the time the operation was created */
  estimatedSharesAmount: Maybe<Scalars['String']['output']>;
  /** numShares actually being undelegated */
  numShares: Maybe<Scalars['String']['output']>;
  /** percentShares that were requested to undelegate */
  percentShares: Maybe<Scalars['String']['output']>;
};

/** OasisValidatorDetail is an implementation of ValidatorDetail for Oasis */
export type OasisValidatorDetail = {
  __typename: 'OasisValidatorDetail';
  /** Commission rate of the validator, e.g.: "5.5%" or null if not available */
  commissionRate: Maybe<Scalars['String']['output']>;
  /** Total amount delegated to the validator or null if not available */
  totalDelegated: Maybe<Amount>;
  /** Uptime of the validator, e.g.: "99.8%" or null if not available */
  uptime: Maybe<Scalars['String']['output']>;
};

/** OffChainOperation represents an OffChainOperation */
export type OffChainOperation = Operation & {
  __typename: 'OffChainOperation';
  /**
   * The following are resolved by operationresolvers/operation_offchain.go
   * account that this operation is associated with. Only applicable to vault operations (ex. Withdraw)
   */
  account: Maybe<Account>;
  /**
   * The following are resolved by type CommonOperation:
   *
   * action is the action that the operation performs, which is generally the same as the operation type
   */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /**
   * appStatus is the app-facing state of the operation, a simplified version of operationState
   * TODO: unused field
   */
  appStatus: AppStatus;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * The following are resolved by type EndorsableOperation:
   *
   * Returns the bio challenge required to pass in order to endorse this operation - TODO: unused field
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /**
   * endorsements is the list of endorsements for the operation
   * TODO: unused field
   */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to, which is the source organization (not necessarily the org using the initiatorKey)
   * @deprecated use operationInfo
   */
  organization: Maybe<Organization>;
  /**
   * policy that applies to this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /** Mandatory user-facing risk analysis information about the smart contract being executed. */
  smartContractRisk: SmartContractRisk;
};


/** OffChainOperation represents an OffChainOperation */
export type OffChainOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** OffChainOperation represents an OffChainOperation */
export type OffChainOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/** OffchainVestingSchedule enforces offchain unlocking of funds */
export type OffchainVestingSchedule = {
  __typename: 'OffchainVestingSchedule';
  /** Account for the vesting schedule */
  account: Account;
  /** Breakdown of vesting schedule by wallet */
  allocations: Array<VestingWallet>;
  /** Time when the last vesting occurs in this schedule */
  endTime: Scalars['Timestamp']['output'];
  /** Creation time of the vesting schedule */
  startTime: Scalars['Timestamp']['output'];
  /** Current status of the vesting schedule */
  status: OffchainVestingScheduleStatus;
  /** Total amount to be vested */
  totalAmount: Amount;
  /** Amount vested so far */
  vestedAmount: Amount;
  /** Offchain vesting schedule identifier */
  vestingScheduleID: Scalars['String']['output'];
};

/** OffchainVestingScheduleStatus is the offchain vesting schedule status */
export enum OffchainVestingScheduleStatus {
  /** Currently vesting */
  ACTIVE = 'ACTIVE',
  /** Vesting canceled */
  CANCELED = 'CANCELED',
  /** Fully vested */
  COMPLETE = 'COMPLETE',
  /** Vesting schedule not yet created */
  DRAFT = 'DRAFT'
}

/** OnboardingStatus signals the step for onboarding of the StagedUser */
export enum OnboardingStatus {
  /** COMPLETE is the last onboarding step */
  COMPLETE = 'COMPLETE',
  /** COMPLETE_WITH_EXEMPTED_PIIS is the last step if PIIs were exempted by the team */
  COMPLETE_WITH_EXEMPTED_PIIS = 'COMPLETE_WITH_EXEMPTED_PIIS',
  /** PIIS is the second step of onboarding: waiting for PIIs */
  PIIS = 'PIIS',
  /** QRSCAN is the first step of onboarding: waiting for QR code scan */
  QRSCAN = 'QRSCAN'
}

/** OneOffOperation represents a one-off operation (for example: Edgeware Signaling) */
export type OneOffOperation = Operation & {
  __typename: 'OneOffOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** amount returns the non-fee balance this operation affected if any */
  amount: Maybe<Amount>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /** The asset associated with this operation */
  asset: Asset;
  /** asset id from with the operation was made */
  assetID: AssetID;
  /** Further information regarding this asset type associated with this operation */
  assetTypeInfo: AssetTypeInfo;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** canonicalOperationVersion is the version of the canonical operation that must be used to sign this operation */
  canonicalOperationVersion: Scalars['Int']['output'];
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** confirmations returns how many confirmations the transaction associated with this operation has received */
  confirmations: Maybe<Scalars['Int']['output']>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced for this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * extraParams are additional asset type specific parameters that were set for this transaction
   * When constructing the canonical operation for signing, the extra params go in the ExtraParams field
   * as a map from asset type id (usually, parent asset type id) to params object. The convention of extra
   * params is to omit all empty fields, and if no extra params are set for an asset type, to omit the asset
   * type from the map entirely. Note that for legacy reasons this convention
   * is the opposite of the normal CanonicalOperation convention, where no fields are ever omitted.
   */
  extraParams: Maybe<ExtraParams>;
  /** final transaction fee charged for the operation, detected on the blockchain */
  finalFee: Maybe<Amount>;
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** max transaction fee charged for the operation */
  maxFee: Maybe<Amount>;
  /** oneOffParams is a generic list of parameters used for this one-off operation */
  oneOffParams: Array<OneOffParam>;
  /** oneOffType is the type of one-off operation (eg. EDG_SIG for Edgeware Signaling) */
  oneOffType: OneOffType;
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * policy that applies to this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /** rawTransaction is the unsigned transaction effecting the intent of this operation */
  rawTransaction: Maybe<Scalars['String']['output']>;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /** sendingBlockchainAddresses is a list of addresses from which a one off operation is sent */
  sendingBlockchainAddresses: Array<BlockchainAddress>;
  /** signedTransaction is the blockchain transaction to be broadcast to the network */
  signedTransaction: Maybe<Scalars['String']['output']>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /**
   * sources are the originating addresses for the operation
   * @deprecated use sendingBlockchainAddresses
   */
  sources: Array<Source>;
  /** transactionID is the blockchain transaction id once available */
  transactionID: Maybe<Scalars['String']['output']>;
  /** transactionSuccess indicates if the blockchain transaction succeeded */
  transactionSuccess: Maybe<Scalars['Boolean']['output']>;
  /**
   * usdValueFee is the USD value of the fee (finalFee if known, otherwise maxFee).
   * If both the finalFee and maxFee are not yet known, this field will be null
   */
  usdValueFee: Maybe<Scalars['String']['output']>;
  /** viewTransactionLink is the link to get more info about the transaction from a third party */
  viewTransactionLink: Maybe<Scalars['String']['output']>;
};


/** OneOffOperation represents a one-off operation (for example: Edgeware Signaling) */
export type OneOffOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** OneOffOperation represents a one-off operation (for example: Edgeware Signaling) */
export type OneOffOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/**
 * OneOffParam is a way to generically display parameters for one-off operations
 * such as Ethereum contract parameters
 */
export type OneOffParam = {
  __typename: 'OneOffParam';
  /** The human-readable description to display for a one-off operation parameter */
  description: Scalars['String']['output'];
  /** The label (or key) for a one-off operation parameter */
  label: Scalars['String']['output'];
  /** The type of a one-off operation parameter */
  type: OneOffParamType;
  /** The value to display for a one-off operation parameter */
  value: Scalars['String']['output'];
};

/** OneOffParamType determines the type of the value for a one-off operation parameter */
export enum OneOffParamType {
  /** A string representation of an Amount in JSON */
  AMOUNT = 'AMOUNT',
  /** A string representation of an Amount as a decimal string */
  AMOUNT_DECIMAL = 'AMOUNT_DECIMAL',
  /** schema.AssetTypeAddress, marshaled to JSON */
  ASSET_TYPE_ADDRESS = 'ASSET_TYPE_ADDRESS',
  /** A normal string address */
  ASSET_TYPE_ADDRESS_STRING = 'ASSET_TYPE_ADDRESS_STRING',
  /** human readable boolean value */
  BOOLEAN = 'BOOLEAN',
  /** hex encoded binary */
  BYTES = 'BYTES',
  /** An Edgeware address used for signaling where airdropped edgeware assets should be sent */
  EDGEWARE_PUBLIC_KEY = 'EDGEWARE_PUBLIC_KEY',
  /** A string representation of a SignedAddress in JSON */
  SIGNED_ADDRESS = 'SIGNED_ADDRESS',
  /** uint256, represented as an ascii number */
  UINT256 = 'UINT256'
}

/** OneOffType is the type of one-off operation */
export enum OneOffType {
  /** Setup Governance for AAVE */
  AAVE_GOVERNANCE_SETUP = 'AAVE_GOVERNANCE_SETUP',
  /** Setup Governance for AAVE by type */
  AAVE_GOVERNANCE_SETUP_BY_TYPE = 'AAVE_GOVERNANCE_SETUP_BY_TYPE',
  /** Increases lockup period of staked assets to enable voting */
  APTOS_INCREASE_LOCKUP_PERIOD = 'APTOS_INCREASE_LOCKUP_PERIOD',
  /** Rotate consensus key for aptos account */
  APTOS_ROTATE_CONSENSUS_KEY = 'APTOS_ROTATE_CONSENSUS_KEY',
  /** Change beneficiary address of vesting contract for Aptos account */
  APTOS_SET_BENEFICIARY = 'APTOS_SET_BENEFICIARY',
  /** Change operator address for Aptos account */
  APTOS_SET_OPERATOR = 'APTOS_SET_OPERATOR',
  /** Change voter address for Aptos account */
  APTOS_SET_VOTER = 'APTOS_SET_VOTER',
  /** Update Operator Commission */
  APTOS_UPDATE_OPERATOR_COMMISSION = 'APTOS_UPDATE_OPERATOR_COMMISSION',
  /** Withdraw unlocked tokens from vesting contract */
  APTOS_VESTING_ADMIN_WITHDRAW = 'APTOS_VESTING_ADMIN_WITHDRAW',
  /** Set a management role on a vesting contract */
  APTOS_VESTING_SET_MANAGEMENT_ROLE = 'APTOS_VESTING_SET_MANAGEMENT_ROLE',
  /** Terminate a vesting contract */
  APTOS_VESTING_TERMINATE_CONTRACT = 'APTOS_VESTING_TERMINATE_CONTRACT',
  /** Withdraw already unlocked funds */
  APTOS_WITHDRAW_UNLOCKED_STAKE = 'APTOS_WITHDRAW_UNLOCKED_STAKE',
  /** Migrate an aragon v1 address to aragon v2 */
  ARAGON_MIGRATE_V1_TO_V2 = 'ARAGON_MIGRATE_V1_TO_V2',
  /** Request the legacy augur contract to approve the conversion */
  AUGUR_APPROVE_MIGRATION = 'AUGUR_APPROVE_MIGRATION',
  /** Migrate an already approved augur address to Augur v2 */
  AUGUR_MIGRATE_V1_TO_V2 = 'AUGUR_MIGRATE_V1_TO_V2',
  /** Authorizes a key to be used for participation in Celo's lightweight identity protocol */
  AUTHORIZE_ATTESTATION_SIGNER = 'AUTHORIZE_ATTESTATION_SIGNER',
  /** Authorizes a key to participate in BFT consensus, and register and manage a Validator or Validator Group */
  AUTHORIZE_VALIDATOR_SIGNER = 'AUTHORIZE_VALIDATOR_SIGNER',
  /** Authorizes a key for validator key rotation */
  AUTHORIZE_VALIDATOR_SIGNER_WITH_KEYS = 'AUTHORIZE_VALIDATOR_SIGNER_WITH_KEYS',
  /** Authorizes another key to sign on our behalf for the purposes of voting on the blockchain */
  AUTHORIZE_VOTE_SIGNER = 'AUTHORIZE_VOTE_SIGNER',
  /** AUTO_COMPOUND is a one-off operation that automatically compounds the rewards of a staking asset */
  AUTO_COMPOUND = 'AUTO_COMPOUND',
  /** Votes for Celo Validator groups may be activated once a new epoch begins */
  CELO_ACTIVATE = 'CELO_ACTIVATE',
  /** Moves celo token funds from the vesting contract to our address */
  CELO_CLAIM_TOKEN = 'CELO_CLAIM_TOKEN',
  /** Creates an account on the blockchain that's used for participation */
  CELO_CREATE_ACCOUNT = 'CELO_CREATE_ACCOUNT',
  /** Cancels an in-progress unlocking and re-locks some of the gold from it */
  CELO_RELOCK = 'CELO_RELOCK',
  /** Revokes pending votes that are not active */
  CELO_REVOKE_PENDING = 'CELO_REVOKE_PENDING',
  /** Change the expiration setting on a ReleaseGold contract */
  CELO_SET_CAN_EXPIRE = 'CELO_SET_CAN_EXPIRE',
  /** Set the Metadata URL of an Account or ReleaseGold contract */
  CELO_SET_METADATA_URL = 'CELO_SET_METADATA_URL',
  /** Set the name of an Account or ReleaseGold contract */
  CELO_SET_NAME = 'CELO_SET_NAME',
  /** Withdraws recently unlocked funds from the LockGold contract into our vesting contract */
  CELO_WITHDRAW_LOCKED = 'CELO_WITHDRAW_LOCKED',
  /** Moves funds from the vesting contract into our address */
  CLAIM_VESTED = 'CLAIM_VESTED',
  /** Setup Governance for COMP */
  COMP_GOVERNANCE_SETUP = 'COMP_GOVERNANCE_SETUP',
  /** dYdX Bridge v3 to v4 tokens */
  DYDX_BRIDGE_CHAINS = 'DYDX_BRIDGE_CHAINS',
  /** Setup Governance for dYdX */
  DYDX_GOVERNANCE_SETUP = 'DYDX_GOVERNANCE_SETUP',
  /** Setup Governance for dYdX by type */
  DYDX_GOVERNANCE_SETUP_BY_TYPE = 'DYDX_GOVERNANCE_SETUP_BY_TYPE',
  /** Edgeware signaling for the one time airdrop signaling on Ethereum, expired September 1st, 2019 */
  EDGEWARE_SIGNALING = 'EDGEWARE_SIGNALING',
  /** Filecoin add signer to multisig actor */
  FILECOIN_ADD_SIGNER = 'FILECOIN_ADD_SIGNER',
  /** Filecoin miner change of ownership address */
  FILECOIN_MINER_CHANGE_OWNER_ADDRESS = 'FILECOIN_MINER_CHANGE_OWNER_ADDRESS',
  /** Confirm change to worker address for FIL miners */
  FILECOIN_MINER_CONFIRM_WORKER_ADDRESS_CHANGE = 'FILECOIN_MINER_CONFIRM_WORKER_ADDRESS_CHANGE',
  /** Change worker and control addresses for FIL miners */
  FILECOIN_MINER_ROTATE_WORKER_AND_CONTROL_ADDRESSES = 'FILECOIN_MINER_ROTATE_WORKER_AND_CONTROL_ADDRESSES',
  /** Terminates sectors for FIL miners */
  FILECOIN_MINER_TERMINATE_SECTORS = 'FILECOIN_MINER_TERMINATE_SECTORS',
  /** Filecoin withdrawal miner balance */
  FILECOIN_MINER_WITHDRAW_BALANCE = 'FILECOIN_MINER_WITHDRAW_BALANCE',
  /** Moves funds from the signing contract to our address */
  FILECOIN_PROPOSE = 'FILECOIN_PROPOSE',
  /** Filecoin remove signer from multisig actor */
  FILECOIN_REMOVE_SIGNER = 'FILECOIN_REMOVE_SIGNER',
  /** Filecoin swap signer within multisig actor */
  FILECOIN_SWAP_SIGNER = 'FILECOIN_SWAP_SIGNER',
  /** Flow claim unstaked */
  FLOW_CLAIM_UNSTAKED = 'FLOW_CLAIM_UNSTAKED',
  /** Flow claim vested */
  FLOW_CLAIM_VESTED = 'FLOW_CLAIM_VESTED',
  /** Create new Flow Account */
  FLOW_CREATE_ACCOUNT = 'FLOW_CREATE_ACCOUNT',
  /** Flow delegate rewards */
  FLOW_DELEGATE_REWARDS = 'FLOW_DELEGATE_REWARDS',
  /** Set up Flow Staking Collection */
  FLOW_SETUP_STAKING_COLLECTION = 'FLOW_SETUP_STAKING_COLLECTION',
  /** Flow stake unstaked */
  FLOW_STAKE_UNSTAKED = 'FLOW_STAKE_UNSTAKED',
  /** Flow claim rewards */
  FLOW_WITHDRAW_REWARDS = 'FLOW_WITHDRAW_REWARDS',
  /** Moves KEEP Token funds from the vesting contract into our address */
  KEEP_CLAIM_VESTED = 'KEEP_CLAIM_VESTED',
  /** Locks funds for participation */
  LOCK = 'LOCK',
  /** Unlock maker on behalf of the client */
  MAKER_V1_1_UNLOCK = 'MAKER_V1_1_UNLOCK',
  /** Approve the vote delegate contract to move MKR tokens */
  MAKER_VOTE_DELEGATE_APPROVE = 'MAKER_VOTE_DELEGATE_APPROVE',
  /** Approve the vote delegate contract to move IOU tokens */
  MAKER_VOTE_DELEGATE_APPROVE_IOU = 'MAKER_VOTE_DELEGATE_APPROVE_IOU',
  /** Free assets from Maker Vote Delegate */
  MAKER_VOTE_DELEGATE_FREE = 'MAKER_VOTE_DELEGATE_FREE',
  /** Lock assets to Maker Vote Delegate */
  MAKER_VOTE_DELEGATE_LOCK = 'MAKER_VOTE_DELEGATE_LOCK',
  /** Approve the vote proxy contract to transfer to lock */
  MAKER_VOTE_PROXY_APPROVE = 'MAKER_VOTE_PROXY_APPROVE',
  /** Initiate Link with an External Address through the Maker Vote Proxy */
  MAKER_VOTE_PROXY_FACTORY_INITIATE_LINK = 'MAKER_VOTE_PROXY_FACTORY_INITIATE_LINK',
  /** Setup Governance for NOTE */
  NOTE_GOVERNANCE_SETUP = 'NOTE_GOVERNANCE_SETUP',
  /** Amend the commission schedule on the Oasis blockchain */
  OASIS_AMEND_COMMISSION_SCHEDULE = 'OASIS_AMEND_COMMISSION_SCHEDULE',
  /** Register an entity on the Oasis blockchain */
  OASIS_REGISTER_ENTITY = 'OASIS_REGISTER_ENTITY',
  /** Create Solana nonce accounts */
  SOLANA_CREATE_NONCE_ACCOUNT = 'SOLANA_CREATE_NONCE_ACCOUNT',
  /** Withdraw from Solana stake account */
  SOLANA_WITHDRAW_STAKE = 'SOLANA_WITHDRAW_STAKE',
  /** Deploy a new starknet account using UDC */
  STARKNET_UDC_DEPLOY_ACCOUNT = 'STARKNET_UDC_DEPLOY_ACCOUNT',
  /** Setup Governance for staked AAVE */
  STKAAVE_GOVERNANCE_SETUP = 'STKAAVE_GOVERNANCE_SETUP',
  /** Setup Governance for staked AAVE by type */
  STKAAVE_GOVERNANCE_SETUP_BY_TYPE = 'STKAAVE_GOVERNANCE_SETUP_BY_TYPE',
  /** Claim rewards of tendermint assets */
  TENDERMINT_CLAIM_REWARDS = 'TENDERMINT_CLAIM_REWARDS',
  /** Tezos sweep moves funds from a KT1 address to a tz1 address for the same asset. This is part of the babylon migration of Oct 2019. */
  TEZOS_SWEEP = 'TEZOS_SWEEP',
  /** Setup Governance for UNI */
  UNI_GOVERNANCE_SETUP = 'UNI_GOVERNANCE_SETUP',
  /** Unlocks locked funds */
  UNLOCK = 'UNLOCK',
  /** DEPRECATED USE DYDX_BRIDGE_CHAINS: WETH dYdX Bridge v3 to v4 tokens */
  WETHDYDX_BRIDGE_CHAINS = 'WETHDYDX_BRIDGE_CHAINS',
  /** Setup Governance for Wrapped Ethereum dYdX */
  WETHDYDX_GOVERNANCE_SETUP = 'WETHDYDX_GOVERNANCE_SETUP',
  /** Setup Governance for Wrapped Ethereum dYdX by type */
  WETHDYDX_GOVERNANCE_SETUP_BY_TYPE = 'WETHDYDX_GOVERNANCE_SETUP_BY_TYPE'
}

/**
 * Operation is a generic interface representing an operation
 * For resolver implementations, see (eg):
 * - service/graphqlmonolith/models/operation.go (eg OperationState)
 * - service/graphqlmonolith/resolvers/operationresolvers/operation.go (eg HasDistress)
 */
export type Operation = {
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Maybe<Organization>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * slaLeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
};


/**
 * Operation is a generic interface representing an operation
 * For resolver implementations, see (eg):
 * - service/graphqlmonolith/models/operation.go (eg OperationState)
 * - service/graphqlmonolith/resolvers/operationresolvers/operation.go (eg HasDistress)
 */
export type OperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/** The Action an Operation represents */
export enum OperationAction {
  /** AUTHORIZE_SPENDER is a request to authorize an address to transfer an amount on this asset's behalf */
  AUTHORIZE_SPENDER = 'AUTHORIZE_SPENDER',
  /** BALANCE_ADJUSTMENT is a balance adjustment */
  BALANCE_ADJUSTMENT = 'BALANCE_ADJUSTMENT',
  /** CREATE_VAULT is a request to create a new vault */
  CREATE_VAULT = 'CREATE_VAULT',
  /** DELEGATE is a request to delegate vault assets to be staked, baked, inflation pool, etc. */
  DELEGATE = 'DELEGATE',
  /** DEPOSIT is a request for an address at which assets may be deposited */
  DEPOSIT = 'DEPOSIT',
  /** DEPOSIT_EVENT is an observed deposit */
  DEPOSIT_EVENT = 'DEPOSIT_EVENT',
  /** GENERIC is an action to arbitrary operations */
  GENERIC = 'GENERIC',
  /** GENERIC_ORGANIZATION_CHANGE is an action to arbitrary operations */
  GENERIC_ORGANIZATION_CHANGE = 'GENERIC_ORGANIZATION_CHANGE',
  /** LOCK is a request to lock funds to be used as part of governance, defi, etc. */
  LOCK = 'LOCK',
  /** OFFCHAIN_OPERATION is an action to perform offchain operation such as Snapshot Voting */
  OFFCHAIN_OPERATION = 'OFFCHAIN_OPERATION',
  /** ONE_OFF is a one-off operation action */
  ONE_OFF = 'ONE_OFF',
  /** ORGANIZATION_ADD is a request to create a new customer */
  ORGANIZATION_ADD = 'ORGANIZATION_ADD',
  /** ORGANIZATION_CHANGE represents a group of changes to the organization */
  ORGANIZATION_CHANGE = 'ORGANIZATION_CHANGE',
  /** ORGANIZATION_POLICY_CHANGE is a request to change an organization's policy */
  ORGANIZATION_POLICY_CHANGE = 'ORGANIZATION_POLICY_CHANGE',
  /** ORGANIZATION_POLICY_CHANGES is a request to change multiple organization's policies */
  ORGANIZATION_POLICY_CHANGES = 'ORGANIZATION_POLICY_CHANGES',
  /** ORGANIZATION_RECOVERY is a request to recover an org */
  ORGANIZATION_RECOVERY = 'ORGANIZATION_RECOVERY',
  /** ROLE_CHANGE is a request to change a role belonging to an organization */
  ROLE_CHANGE = 'ROLE_CHANGE',
  /** SIGN_TERMS_AND_CONDITIONS is an action to sign terms and conditions for offchain interactions - like for Dapps */
  SIGN_TERMS_AND_CONDITIONS = 'SIGN_TERMS_AND_CONDITIONS',
  /** TRANSFER is a request to transfer funds via API */
  TRANSFER = 'TRANSFER',
  /** TRUSTED_DESTINATION_ADD is a request to add a new trusted destination */
  TRUSTED_DESTINATION_ADD = 'TRUSTED_DESTINATION_ADD',
  /** TRUSTED_DESTINATION_REMOVE is a request to remove an existing trusted destination */
  TRUSTED_DESTINATION_REMOVE = 'TRUSTED_DESTINATION_REMOVE',
  /** TRUSTED_SMART_CONTRACT_ADD is a request to add a new trusted smart contract */
  TRUSTED_SMART_CONTRACT_ADD = 'TRUSTED_SMART_CONTRACT_ADD',
  /** TRUSTED_SMART_CONTRACT_REMOVE is a request to remove an existing trusted smart contract */
  TRUSTED_SMART_CONTRACT_REMOVE = 'TRUSTED_SMART_CONTRACT_REMOVE',
  /** UNLOCK is a request to unlock funds */
  UNLOCK = 'UNLOCK',
  /** USER_ADD is a request to add a user */
  USER_ADD = 'USER_ADD',
  /** USER_ADD_AND_POLICIES is a request to add an user and add it to policies */
  USER_ADD_AND_POLICIES = 'USER_ADD_AND_POLICIES',
  /** USER_REMOVE is a request to remove a user */
  USER_REMOVE = 'USER_REMOVE',
  /** USER_REMOVE_AND_POLICIES is a request to remove an user from the organization and its policies */
  USER_REMOVE_AND_POLICIES = 'USER_REMOVE_AND_POLICIES',
  /** USER_REPLACE is a request to replace a user within an org */
  USER_REPLACE = 'USER_REPLACE',
  /** VAULT_DISABLE is a request to disable a vault */
  VAULT_DISABLE = 'VAULT_DISABLE',
  /** VAULT_POLICY_CHANGE is a request to change a vault's policy */
  VAULT_POLICY_CHANGE = 'VAULT_POLICY_CHANGE',
  /** VAULT_POLICY_CHANGES is a request to change multiple vault's policies */
  VAULT_POLICY_CHANGES = 'VAULT_POLICY_CHANGES',
  /** VOTE is a request to vote */
  VOTE = 'VOTE',
  /** WITHDRAW is a request to withdraw assets */
  WITHDRAW = 'WITHDRAW'
}

/** OperationComment is one comment for an operation */
export type OperationComment = {
  __typename: 'OperationComment';
  /** commentID is the ID of the comment, not exposed to the user but used for edit/delete/restore calls */
  commentID: Scalars['String']['output'];
  /** commentSubID is the revision of the comment, not exposed to the user but used for edit/delete/restore calls */
  commentSubID: Scalars['Int']['output'];
  /** createdAt is the timestamp at which the comment was initially created */
  createdAt: Scalars['String']['output'];
  /** createdBy is the user who created the comment (User defined in ./organization.graphqls) */
  createdBy: User;
  /** editedAt is the timestamp at which the comment was most recently modified */
  editedAt: Maybe<Scalars['String']['output']>;
  /** editedBy is the user who most recently edited the comment (User defined in ./organization.graphqls) */
  editedBy: Maybe<User>;
  /** message is the main body of the comment */
  message: Scalars['String']['output'];
};

/** OperationConnection implements the connections spec */
export type OperationConnection = {
  __typename: 'OperationConnection';
  /** edges implements the connections spec */
  edges: Array<Maybe<OperationEdge>>;
  /** pageInfo implements the connections spec */
  pageInfo: PageInfo;
};

/** Possible decisions available in response to an operation */
export enum OperationDecision {
  /** Approve the operation */
  APPROVE = 'APPROVE',
  /** Initiate the operation, but do not approve it */
  INITIATE = 'INITIATE',
  /** Reject the operation */
  REJECT = 'REJECT'
}

/** Representation of operation details */
export type OperationDetails = {
  __typename: 'OperationDetails';
  /** Operation amount */
  amount: Maybe<Scalars['String']['output']>;
  /** Operation amount asset */
  amountAsset: Maybe<Scalars['AssetTypeID']['output']>;
  /** ID of the blockchain transaction */
  blockchainTransactionID: Scalars['String']['output'];
  /** Operation description */
  description: Scalars['String']['output'];
  /** List of destination addresses */
  destinationAddresses: Array<Scalars['String']['output']>;
  /** Operation fee quantity */
  fee: Maybe<Scalars['String']['output']>;
  /** Operation fee asset */
  feeAsset: Maybe<Scalars['AssetTypeID']['output']>;
  /** ID of the operation */
  operationID: Scalars['String']['output'];
  /** Operation type */
  operationType: OperationAction;
  /** List of source addresses */
  sourceAddresses: Array<Scalars['String']['output']>;
};

/** OperationEdge implements the connections spec */
export type OperationEdge = {
  __typename: 'OperationEdge';
  /** cursor implements the connections spec */
  cursor: Scalars['ID']['output'];
  /** node implements the connections spec */
  node: Operation;
};

/**
 * OperationField is the union of all the fields shown to the user in the iOS app
 * This is expressed as a union because some fields are signed and some are not
 * if the field is a signed field, the app is expected to lookup the content from SignablePayload whereas UnsignedOperationField contains the content directly
 */
export type OperationField = SignedOperationField | UnsignedOperationField;

/** OperationImpediment contains info about what impedes an operation from transitioning to the next state. */
export type OperationImpediment = {
  __typename: 'OperationImpediment';
  /** details contains more detailed info (than userMessage) about the impediment. */
  details: Maybe<Scalars['String']['output']>;
  /** impedimentType is the classification of impediments, such as NETWORK_CONDITIONS. */
  impedimentType: Scalars['String']['output'];
  /** userMessage contains user-friendly message about the impediment. */
  userMessage: Scalars['String']['output'];
};

/** OperationSortFields are the options for sorting a list of operations */
export enum OperationSortFields {
  /** CREATED_AT is the time at which the operation was initiated */
  CREATED_AT = 'CREATED_AT',
  /** END_TIME is the time at which the operation was ended */
  END_TIME = 'END_TIME',
  /** STATE is the current state of the operation */
  STATE = 'STATE'
}

/**
 * OperationState is the state of the operation as it is processed by our system
 * These are not code-gen'd right now but they really should be
 */
export enum OperationState {
  /** ADDRESS_AVAILABLE is the state when an address has been generated by the HSM and we are waiting for a deposit */
  ADDRESS_AVAILABLE = 'ADDRESS_AVAILABLE',
  /** ADDRESS_REQUEST is the state when an address has been requested from the HSM, but it's not available yet */
  ADDRESS_REQUEST = 'ADDRESS_REQUEST',
  /**
   * BROADCASTABLE is the state when the transaction is ready for blockchain service to broadcast.
   * When a transaction is in this state and before it's moved to BROADCASTING state, if cancelation request
   * is received, the transaction can be successfully canceled.
   */
  BROADCASTABLE = 'BROADCASTABLE',
  /**
   * OperationStateBroadcasted is the state when the blockchain service *has* broadcasted the transaction,
   * but scanner hasn't detected the transaction on-chain yet.
   */
  BROADCASTED = 'BROADCASTED',
  /**
   * BROADCASTING is the state when the blockchain service is broadcasting the transaction.
   * When a transaction is in this state, user can still attempt to send a cancelation request - but
   * the request would be ignored unless the broadcasting encounters network congestion, in which case
   * the transaction would be moved to BROADCASTABLE state, allow cancelation to succeed.
   */
  BROADCASTING = 'BROADCASTING',
  /** CANCELED is the state when the operation has been canceled. */
  CANCELED = 'CANCELED',
  /** COMPLETE is the state when the action is complete */
  COMPLETE = 'COMPLETE',
  /**
   *  DEPOSIT_EVENT_ATTRIBUTION_REQUIRED is the state a deposit event has if it is already confirmed on the blockchain
   * but requires more information about the sources of the deposit (only appliable to regular deposits for ADB)
   */
  DEPOSIT_EVENT_ATTRIBUTION_REQUIRED = 'DEPOSIT_EVENT_ATTRIBUTION_REQUIRED',
  /** DEPOSIT_EVENT_DETECTED is the state a deposit event is created in when we detect it on the blockchain */
  DEPOSIT_EVENT_DETECTED = 'DEPOSIT_EVENT_DETECTED',
  /** ERRORED is the state when the action has experienced a fatal error */
  ERRORED = 'ERRORED',
  /** EXPIRED is the state when the action has timed out */
  EXPIRED = 'EXPIRED',
  /** FAILURE_COMMITTED means that the transaction was a failure on the blockchain and maybe only the fee was consumed */
  FAILURE_COMMITTED = 'FAILURE_COMMITTED',
  /** GAS_PENDING is the state when the operation is waiting for a deposit from the gas station */
  GAS_PENDING = 'GAS_PENDING',
  /** INITIATOR_EXPIRED is the state when initiator has not timely approved the operation, causing it auto-expired. */
  INITIATOR_EXPIRED = 'INITIATOR_EXPIRED',
  /** INITIATOR_PREPARED is the state when we ask the initiator of the operation to accept it before others process it, but, unlike in INITIATOR_REQUIRED, this state allows any number of operations to be created at the same time and the first one to be endorsed wins. */
  INITIATOR_PREPARED = 'INITIATOR_PREPARED',
  /** INITIATOR_REJECTED is the state operations go into if the initiator rejected them. The app is not expected to show these because they might be operations that were never endorsed in the first place and don't matter. */
  INITIATOR_REJECTED = 'INITIATOR_REJECTED',
  /** INITIATOR_REQUIRED is the state when we ask the initiator of the operation to accept it before others process it */
  INITIATOR_REQUIRED = 'INITIATOR_REQUIRED',
  /** OFFCHAIN_OPERATION_SIGNING is the state when the HSM is in the process of signing the OffChainOperation */
  OFFCHAIN_OPERATION_SIGNING = 'OFFCHAIN_OPERATION_SIGNING',
  /** ORGANIZATION_CHANGE_REQUEST is the state a organization change operation is in as soon as a user requests it, before it's processed by us */
  ORGANIZATION_CHANGE_REQUEST = 'ORGANIZATION_CHANGE_REQUEST',
  /** ORGANIZATION_SIGNING is the state when the HSM is in the process of signing the organization */
  ORGANIZATION_SIGNING = 'ORGANIZATION_SIGNING',
  /** ORG_CREATION_SIGNING is the state when the HSM is in  the process of signing a new organization */
  ORG_CREATION_SIGNING = 'ORG_CREATION_SIGNING',
  /** ORG_RISK_RECON is the state when we are performing risk recon but are not done gathering info yet for new orgs */
  ORG_RISK_RECON = 'ORG_RISK_RECON',
  /** QR_CODE_SCANNING is the state when we are waiting for users to scan the organization recovery QR Codes. */
  QR_CODE_SCANNING = 'QR_CODE_SCANNING',
  /** RISK_PENDING is the state when all risk data is ready and the risk team has to review the operation */
  RISK_PENDING = 'RISK_PENDING',
  /** RISK_RECON is the state when we are performing risk recon but are not done gathering info yet */
  RISK_RECON = 'RISK_RECON',
  /** RISK_REJECTED is the state when the risk team has rejected the operation */
  RISK_REJECTED = 'RISK_REJECTED',
  /** SIGS_FAILED is the state when a user has denied or canceled an operation */
  SIGS_FAILED = 'SIGS_FAILED',
  /** SIGS_REQUIRED is the state when we ask users to confirm an operation */
  SIGS_REQUIRED = 'SIGS_REQUIRED',
  /** TRANSACTION_REQUEST is the state a transaction operation is in as soon as a user requests it, before it's processed by us */
  TRANSACTION_REQUEST = 'TRANSACTION_REQUEST',
  /** TRANSACTION_SIGNING is the state when the HSM is in  the process of signing the transaction */
  TRANSACTION_SIGNING = 'TRANSACTION_SIGNING'
}

/** OrdRejReason represents */
export enum OrdRejReason {
  /** RejReasonBrokerOption represents */
  BROKER_OPTION = 'BROKER_OPTION',
  /** RejReasonDuplicateOrder represents */
  DUPLICATE_ORDER = 'DUPLICATE_ORDER',
  /** RejReasonExchangeClosed represents */
  EXCHANGE_CLOSED = 'EXCHANGE_CLOSED',
  /** RejReasonForceCancel represents */
  FORCE_CANCEL = 'FORCE_CANCEL',
  /** RejReasonImmediateOrderDidNotCross represents */
  IMMEDIATE_ORDER_DID_NOT_CROSS = 'IMMEDIATE_ORDER_DID_NOT_CROSS',
  /** RejReasonInternalError represents */
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  /** Invalid represents */
  INVALID = 'INVALID',
  /** RejReasonInvalidStrategy represents */
  INVALID_STRATEGY = 'INVALID_STRATEGY',
  /** RejReasonOrderExceedsLimit represents */
  ORDER_EXCEEDS_LIMIT = 'ORDER_EXCEEDS_LIMIT',
  /** RejReasonPostOnlyOrderWouldCross represents */
  POST_ONLY_ORDER_WOULD_CROSS = 'POST_ONLY_ORDER_WOULD_CROSS',
  /** RejReasonQuoteExpired represents */
  QUOTE_EXPIRED = 'QUOTE_EXPIRED',
  /** RejReasonQuoteRequestTimeout represents */
  QUOTE_REQUEST_TIMEOUT = 'QUOTE_REQUEST_TIMEOUT',
  /** RejReasonRateLimit represents */
  RATE_LIMIT = 'RATE_LIMIT',
  /** RejReasonStaleRequest represents */
  STALE_REQUEST = 'STALE_REQUEST',
  /** RejReasonTooLateToEnter represents */
  TOO_LATE_TO_ENTER = 'TOO_LATE_TO_ENTER',
  /** RejReasonUnknownMarket represents */
  UNKNOWN_MARKET = 'UNKNOWN_MARKET',
  /** RejReasonUnknownOrder represents */
  UNKNOWN_ORDER = 'UNKNOWN_ORDER',
  /** RejReasonUnknownSymbol represents */
  UNKNOWN_SYMBOL = 'UNKNOWN_SYMBOL'
}

/** OrdStatus represents */
export enum OrdStatus {
  /** OrdStatusCanceled represents */
  CANCELED = 'CANCELED',
  /** OrdStatusDoneForDay represents */
  DONE_FOR_DAY = 'DONE_FOR_DAY',
  /** OrdStatusFilled represents */
  FILLED = 'FILLED',
  /** Invalid represents */
  INVALID = 'INVALID',
  /** OrdStatusNew represents */
  NEW = 'NEW',
  /** OrdStatusPartiallyFilled represents */
  PARTIALLY_FILLED = 'PARTIALLY_FILLED',
  /** OrdStatusPendingCancel represents */
  PENDING_CANCEL = 'PENDING_CANCEL',
  /** OrdStatusPendingNew represents */
  PENDING_NEW = 'PENDING_NEW',
  /** OrdStatusPendingReplace represents */
  PENDING_REPLACE = 'PENDING_REPLACE',
  /** OrdStatusRejected represents */
  REJECTED = 'REJECTED'
}

/** OrdType represents */
export enum OrdType {
  /** Invalid represents */
  INVALID = 'INVALID',
  /** OrdTypeLimit represents */
  LIMIT = 'LIMIT',
  /** OrdTypeLimitAllIn represents */
  LIMIT_ALL_IN = 'LIMIT_ALL_IN',
  /** OrdTypeMarket represents */
  MARKET = 'MARKET',
  /** OrdTypeRFQ represents */
  RFQ = 'RFQ'
}

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  /** Specifies an ascending order for a given `orderBy` argument. */
  ASC = 'ASC',
  /** Specifies a descending order for a given `orderBy` argument. */
  DESC = 'DESC'
}

/** Order execution report */
export type OrderExecutionReport = {
  __typename: 'OrderExecutionReport';
  /** aggressorSide represents */
  aggressorSide: Maybe<TradeSide>;
  /** amountCurrency represents */
  amountCurrency: Scalars['String']['output'];
  /** avgPx represents */
  avgPx: Scalars['String']['output'];
  /** avgPxAllIn represents */
  avgPxAllIn: Maybe<Scalars['String']['output']>;
  /** cancelSessionID represents */
  cancelSessionID: Maybe<Scalars['String']['output']>;
  /** clOrdID represents */
  clOrdID: Scalars['String']['output'];
  /** createdAt represents */
  createdAt: Scalars['Timestamp']['output'];
  /** cumAmt represents */
  cumAmt: Scalars['String']['output'];
  /** cumProviderFee represents */
  cumProviderFee: Maybe<Scalars['String']['output']>;
  /** cumQty represents */
  cumQty: Scalars['String']['output'];
  /** currency represents */
  currency: Scalars['String']['output'];
  /** cxlRejReason represents */
  cxlRejReason: Maybe<CxlRejReason>;
  /** execID represents */
  execID: Scalars['String']['output'];
  /** execType represents */
  execType: ExecType;
  /** feeCurrency represents */
  feeCurrency: Maybe<Scalars['String']['output']>;
  /** flowType represents */
  flowType: FlowType;
  /** groupID represents */
  groupID: Maybe<Scalars['String']['output']>;
  /** id represents */
  id: Scalars['String']['output'];
  /** lastAmt represents */
  lastAmt: Maybe<Scalars['String']['output']>;
  /** lastFee represents */
  lastFee: Maybe<Scalars['String']['output']>;
  /** lastFeeCurrency represents */
  lastFeeCurrency: Maybe<Scalars['String']['output']>;
  /** lastMarket represents */
  lastMarket: Maybe<Scalars['String']['output']>;
  /** lastProviderFee represents */
  lastProviderFee: Maybe<Scalars['String']['output']>;
  /** lastPx represents */
  lastPx: Maybe<Scalars['String']['output']>;
  /** lastQty represents */
  lastQty: Maybe<Scalars['String']['output']>;
  /** leavesQty represents */
  leavesQty: Scalars['String']['output'];
  /** OrdRejReason represents */
  ordRejReason: Maybe<OrdRejReason>;
  /** ordStatus represents */
  ordStatus: OrdStatus;
  /** ordType represents */
  ordType: OrdType;
  /** orderID represents */
  orderID: Scalars['String']['output'];
  /** orderQty represents */
  orderQty: Scalars['String']['output'];
  /** orderSessionID represents */
  orderSessionID: Maybe<Scalars['String']['output']>;
  /** origClOrdID represents */
  origClOrdID: Maybe<Scalars['String']['output']>;
  /** parentOrderID represents */
  parentOrderID: Scalars['String']['output'];
  /** parentRFQID represents */
  parentRFQID: Scalars['String']['output'];
  /** price represents */
  price: Maybe<Scalars['String']['output']>;
  /** providerFeeCurrency represents */
  providerFeeCurrency: Maybe<Scalars['String']['output']>;
  /** requestUser represents */
  requestUser: Scalars['String']['output'];
  /** revision represents */
  revision: Scalars['Int']['output'];
  /** side represents */
  side: TradeSide;
  /** subAccount represents */
  subAccount: Maybe<Scalars['String']['output']>;
  /** symbol represents */
  symbol: Scalars['String']['output'];
  /** textMessage represents */
  textMessage: Maybe<Scalars['String']['output']>;
  /** timeInForce represents */
  timeInForce: TimeInForce;
  /** transactTime represents */
  transactTime: Scalars['Timestamp']['output'];
};

/** Order execution report connection for pagination */
export type OrderExecutionReportConnection = {
  __typename: 'OrderExecutionReportConnection';
  /** List of edges containing order execution report and cursor information */
  edges: Array<Maybe<OrderExecutionReportEdge>>;
  /** Page details for the associated list of  order execution report edges */
  pageInfo: PageInfo;
};

/** Order execution report edge for pagination */
export type OrderExecutionReportEdge = {
  __typename: 'OrderExecutionReportEdge';
  /** Cursor for the associated order execution report */
  cursor: Scalars['String']['output'];
  /** Order execution report */
  node: OrderExecutionReport;
};

/**
 * Holds information about the action of an organization that cannot be safistied due
 * to the respective list of users.
 *
 * This is tightly coupled with users' device health.
 */
export type OrgPolicyQuorumSummary = {
  __typename: 'OrgPolicyQuorumSummary';
  /** Action under analysis. */
  action: PolicyActionType;
  /** The human readable display name of the policy. */
  displayName: Scalars['String']['output'];
  /** Status of this quorum given current user devices' health */
  status: Maybe<PolicyStatus>;
  /** Users that are used to compute the quorum. */
  users: Array<User>;
};


/**
 * Holds information about the action of an organization that cannot be safistied due
 * to the respective list of users.
 *
 * This is tightly coupled with users' device health.
 */
export type OrgPolicyQuorumSummaryusersArgs = {
  sortField?: InputMaybe<UserSortField>;
};

/** Represents an organization */
export type Organization = IAMResource & {
  __typename: 'Organization';
  /** List of accounts associated with this organization */
  accounts: Array<Account>;
  /** Returns a list of subaccounts for an AffiliateID under an Organization */
  affiliateSubaccounts: Array<Subaccount>;
  /** Returns sha256(organization key id + user salt) to hide Anchorage identifiers from blockchain risk analysis providers */
  analysisUserHandle: Scalars['String']['output'];
  /** Send the user to this URL to log in. This is primarily useful to the iOS app. */
  clientDashboardURL: Scalars['String']['output'];
  /** An ongoing org change that is blocking new org changes */
  conflictingOrgChange: Maybe<Operation>;
  /** Timestamp of when the organization was created with nanosecond precision */
  createdAt: Scalars['String']['output'];
  /** SLA is the sla for the organization expressed in nanoseconds */
  currentSLA: Scalars['String']['output'];
  /** Timestamp of when the organization was disabled with nanosecond precision */
  disabledAt: Maybe<Scalars['String']['output']>;
  /** enabledFeatures returns an array of enabled features for this organization */
  enabledFeatures: Array<Feature>;
  enabledModules: OrganizationModuleConnection;
  /** Returns enrollments in the organization, with optional filtering. */
  enrollments: EnrollmentConnection;
  exportIAMRoleAssignments: Scalars['String']['output'];
  /** Gets the generation of this version of the organization */
  generation: Scalars['String']['output'];
  /** List of trusted destinations associated with this organization, with pagination. */
  getTrustedDestinations: TrustedDestinationConnection;
  /** Get the list of users in all generations of the organization */
  historicalUsers: Array<User>;
  iam: IAM;
  iamRoles: IAMRoleConnection;
  /** Indicates whether the organization can trade only in crypto */
  isCryptoOnly: Scalars['Boolean']['output'];
  /** Whether this organization is an ETF Issuer */
  isUsingETF: Scalars['Boolean']['output'];
  /** Whether this organization is using the SMA system */
  isUsingSMA: Scalars['Boolean']['output'];
  /** Lost device reports currently active for this organization */
  lostDevices: Array<LostDevice>;
  /** Name of the organization */
  name: Scalars['String']['output'];
  /** Number of users at risk of loosing or lost access */
  nonHealthyUserCount: Scalars['Int']['output'];
  /** Returns an offchain vesting schedule */
  offchainVestingSchedule: OffchainVestingSchedule;
  /** List of offchain vesting schedules associated with this organization */
  offchainVestingSchedules: Array<OffchainVestingSchedule>;
  /**
   * Get the list of operations in this organization
   * first and after implement the connections spec
   */
  operations: OperationConnection;
  /** Operator generally depends on who's reviewing operations for this organization. Usually this is ANCHORAGE or SELF */
  operator: Scalars['String']['output'];
  /** Whether an organization change can be made or not due to conflicting operations in progress */
  orgChangePossible: Maybe<Scalars['Boolean']['output']>;
  /**
   * The full canonical organization object, as a json string, that is signed over to validate the organization.
   * Technically this field is not needed, you should be able to reconstruct this object with other data provided
   * in the graphql Organization. This field is provided for convenience. Validating that the signature field
   * is correct for this object is not sufficient, you must also deserialize the json and validate the fields
   * in this object match the graphql-returned fields.
   * DO NOT validate this object against the provided graphql publicKey field. The organization's public key
   * must be stored at time of enrollment, and only the stored value can be used to validate signatures.
   */
  orgObject: Scalars['String']['output'];
  /**
   * Provides a summary of an organization's quorum policy
   * @deprecated This looks abandoned a long time ago
   */
  orgPolicyDescription: Scalars['String']['output'];
  /** The id of the organization */
  organizationKeyID: Scalars['String']['output'];
  /** Return the OwnFunds related to the Organization that uses the Subaccount system */
  ownSubaccountFunds: Array<SubaccountAffiliateCustomerFields>;
  /**
   * Defines the Policy that must be satisfied in order to perform any given Action
   * @deprecated Legacy PolicyMaps. Use 'readPolicies'
   */
  policies: Array<Policy>;
  /** Information about policy containers on the resource. */
  policyInfo: Maybe<PolicyInfo>;
  /** Policies that cannot be satisfied. May be empty if there are no issues. */
  policyQuorumSummary: Maybe<PolicyQuorumSummary>;
  /**
   * Portfolio returns an organization-level summary of current holdings
   * Set the optional filterAssetTypeIDs to restrict the holdings list (amounts) to just these AssetTypeIDs.
   * Set debug to true to enable more logging.
   */
  portfolio: Portfolio;
  /**
   * The hash of the canonical json serialization of the previous version of the Organization.
   * If this is the first version, the previousHash will be the empty string.
   * Returned as hex-encoded byte-string
   */
  previousHash: Scalars['String']['output'];
  /** The public key of the organization. Returned as hex-encoded byte-string */
  publicKey: Scalars['String']['output'];
  /** Non-signable representation of the policies applicable to this organization */
  readPolicies: ReadPolicyConnection;
  /**
   * List of items each representing the permission details of a role.
   *
   * The roles are those associated with the organization, as used by the user interface.
   *
   * For each RolePermissionDetails, the changeType will be UNCHANGED.
   */
  rolePermissions: Array<RolePermissionDetails>;
  /** List of roles associated with this organization. This reflects the internal representation of the Role. */
  roles: Array<Role>;
  /** Settings returns this organization specific settings */
  settings: Maybe<Array<Setting>>;
  /**
   * The signature from the HSM over this organization's data
   * Returned as hex-encoded byte-string
   */
  signature: Signature;
  /**
   * List all participants you have access to.
   * For staff users, this is all participants in the system.
   * For organization users, this is only the participants for the caller's organization.
   * Not paginated.
   */
  snParticipants: Array<SNParticipant>;
  /** Returns a subaccount with SubaccountID and OrganizationID */
  subaccount: Subaccount;
  /** Return the Affiliates information related to the Organization that uses the Subaccount system */
  subaccountProgramCustomers: Array<SubaccountAffiliateCustomerFields>;
  /** The Trade Commission Rate in BPS */
  tradeCommissionRate: Maybe<Scalars['Int']['output']>;
  /** The number of trades of the organization */
  tradeCount: Scalars['Int']['output'];
  /** The credit limit of the organization (USD) */
  tradeCreditLimit: Maybe<Amount>;
  /** The credit spend of the organization (USD) */
  tradeCreditSpend: Maybe<Amount>;
  /** The trade settings of the organization */
  tradeSettings: TradeSettings;
  /** Get the list of trader users in this organization */
  traders: Array<User>;
  /** Whether the compliance team has approved this organization for trading */
  tradingApprovedByComplianceTeam: Scalars['Boolean']['output'];
  /** Whether the trading team has approved this organization for trading */
  tradingApprovedByTradingTeam: Scalars['Boolean']['output'];
  /** Whether trading is enabled for this organization */
  tradingEnabled: Scalars['Boolean']['output'];
  /** The trading volume of the organization (USD) */
  tradingVolume: Maybe<Amount>;
  /** Specific trusted destination */
  trustedDestination: Maybe<TrustedDestination>;
  /** List of trusted destinations */
  trustedDestinations: Array<TrustedDestination>;
  /** Get a trusted smart contract by SubID. */
  trustedSmartContract: Maybe<TrustedSmartContract>;
  /**
   * List of trusted smart contracts associated with this organization, with zero or more optional filters, with pagination.
   * If multiple filters are provided, they are AND'ed: only entities matching *all* filters will be returned.
   */
  trustedSmartContracts: TrustedSmartContractConnection;
  /** Returns sha256(organization key id) to help clean up previous bug when analysisUserHandle had been generated with an empty salt */
  unsaltedAnalysisUserHandle: Scalars['String']['output'];
  /** Number of users in the organization */
  userCount: Scalars['Int']['output'];
  /** Get the list of users in this organization */
  users: Array<User>;
  /** vault with particular vaultSubID associated with this organization */
  vault: Vault;
  /** List of vaults associated with this organization */
  vaults: Array<Vault>;
  /** List of vaults associated with this organization, with pagination. */
  vaultsWithPagination: VaultConnection;
  /** walletKeyBackupStrategy can be only ADMIN_KEYS_QUOURM for now and this strategy has no other configs so far */
  walletKeyBackupStrategy: Maybe<WalletKeyBackupStrategy>;
};


/** Represents an organization */
export type OrganizationaffiliateSubaccountsArgs = {
  filterAffiliateID: Scalars['String']['input'];
};


/** Represents an organization */
export type OrganizationenabledModulesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrganizationModuleWhereInput>;
};


/** Represents an organization */
export type OrganizationenrollmentsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<EnrollmentOrderBy>;
  where?: InputMaybe<EnrollmentWhereInput>;
};


/** Represents an organization */
export type OrganizationgetTrustedDestinationsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterByAssetTypeID?: InputMaybe<Scalars['AssetTypeID']['input']>;
  filterByDestinationAddress?: InputMaybe<Scalars['String']['input']>;
  filterByName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Represents an organization */
export type OrganizationiamRolesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IAMRoleOrder>;
  where?: InputMaybe<IAMRoleWhereInput>;
};


/** Represents an organization */
export type OrganizationoffchainVestingScheduleArgs = {
  scheduleID: Scalars['String']['input'];
};


/** Represents an organization */
export type OrganizationoperationsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterActions?: InputMaybe<Array<OperationAction>>;
  filterAssetSubID?: InputMaybe<Scalars['String']['input']>;
  filterAssetTypeID?: InputMaybe<Scalars['AssetTypeID']['input']>;
  filterAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterCreatedSince?: InputMaybe<Scalars['String']['input']>;
  filterCreatedUntil?: InputMaybe<Scalars['String']['input']>;
  filterDelegateTypes?: InputMaybe<Array<DelegateType>>;
  filterDepositRewardTypes?: InputMaybe<Array<DepositRewardType>>;
  filterOperationIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterPublicKeyID?: InputMaybe<Scalars['String']['input']>;
  filterRequiresDecision?: InputMaybe<Scalars['Boolean']['input']>;
  filterStates?: InputMaybe<Array<OperationState>>;
  filterVaultSubID?: InputMaybe<Scalars['String']['input']>;
  filterVaultSubIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeChange?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortDESC?: InputMaybe<Scalars['Boolean']['input']>;
  sortField?: InputMaybe<OperationSortFields>;
};


/** Represents an organization */
export type OrganizationportfolioArgs = {
  debug?: Scalars['Boolean']['input'];
  filterAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  forceRunBlockedQuery?: Scalars['Boolean']['input'];
};


/** Represents an organization */
export type OrganizationreadPoliciesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Represents an organization */
export type OrganizationsubaccountArgs = {
  filterSubaccountID: Scalars['String']['input'];
};


/** Represents an organization */
export type OrganizationtradeCountArgs = {
  since?: InputMaybe<Scalars['String']['input']>;
  until?: InputMaybe<Scalars['String']['input']>;
};


/** Represents an organization */
export type OrganizationtradingVolumeArgs = {
  since?: InputMaybe<Scalars['String']['input']>;
  until?: InputMaybe<Scalars['String']['input']>;
};


/** Represents an organization */
export type OrganizationtrustedDestinationArgs = {
  destinationSubID: Scalars['String']['input'];
};


/** Represents an organization */
export type OrganizationtrustedDestinationsArgs = {
  destinationAssetTypeID?: InputMaybe<Scalars['AssetTypeID']['input']>;
};


/** Represents an organization */
export type OrganizationtrustedSmartContractArgs = {
  smartContractSubID: Scalars['String']['input'];
};


/** Represents an organization */
export type OrganizationtrustedSmartContractsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  filterAddresses?: Array<Scalars['String']['input']>;
  filterNameSubstr?: InputMaybe<Scalars['String']['input']>;
  filterTrustedSmartContractSubIDs?: Array<Scalars['String']['input']>;
  filterVaultSubIDs?: Array<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortDESC?: Scalars['Boolean']['input'];
  sortField?: TrustedSmartContractSortingField;
};


/** Represents an organization */
export type OrganizationusersArgs = {
  userKeyIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** Represents an organization */
export type OrganizationvaultArgs = {
  overrideAllowedAssetSubIDsForOrgKeyIDsWithSlowBackOfficeQuery?: Array<Scalars['Int']['input']>;
  preloadAvailableBalanceForPurposes?: Array<AvailableForPurpose>;
  vaultSubID?: InputMaybe<Scalars['String']['input']>;
  vaultUniqueID?: InputMaybe<Scalars['String']['input']>;
};


/** Represents an organization */
export type OrganizationvaultsArgs = {
  filterCurrentUserIsVaultMember?: InputMaybe<Scalars['Boolean']['input']>;
  filterStates?: InputMaybe<Array<VaultState>>;
  forceRunBlockedQuery?: Scalars['Boolean']['input'];
};


/** Represents an organization */
export type OrganizationvaultsWithPaginationArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterCurrentUserIsVaultMember?: InputMaybe<Scalars['Boolean']['input']>;
  filterStates?: InputMaybe<Array<VaultState>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  sortField?: InputMaybe<VaultSortField>;
};

/** OrganizationExternalAccountBalance holds the balance related to a specific asset for an account */
export type OrganizationExternalAccountBalance = {
  __typename: 'OrganizationExternalAccountBalance';
  /** amount holds the information about the balance of a specific asset */
  amount: Amount;
  /** lastUpdatedAt is the timestamp of the latest change in the balance of this asset */
  lastUpdatedAt: Scalars['Timestamp']['output'];
};

/** OrganizationExternalAccountBalanceConnection contains a paginated list of external accounts balance - implements the connections spec for pagination */
export type OrganizationExternalAccountBalanceConnection = {
  __typename: 'OrganizationExternalAccountBalanceConnection';
  /** edges returns a list of WalletEdge objects representing a page of external accounts balance data. implements the connections spec for pagination */
  edges: Array<OrganizationExternalAccountBalanceEdge>;
  /** pageInfo returns information about the page returned above. implements the connections spec for pagination */
  pageInfo: PageInfo;
  /** total balance in USD of the org */
  totalOrgBalance: Amount;
};

/** OrganizationExternalAccountBalanceEdge contains an account balance and the cursor representing it - implements the connections spec for pagination */
export type OrganizationExternalAccountBalanceEdge = {
  __typename: 'OrganizationExternalAccountBalanceEdge';
  /** cursor implements the connections spec for pagination */
  cursor: Scalars['String']['output'];
  /** node implements the connections spec for pagination */
  node: OrganizationExternalAccountBalanceType;
};

/** OrganizationExternalAccountBalanceType holds the overview information about the balance for an account */
export type OrganizationExternalAccountBalanceType = {
  __typename: 'OrganizationExternalAccountBalanceType';
  /** account holds the information about this account */
  account: Maybe<Account>;
  /** balances holds the information about the balance of each asset and when each has last been udpated */
  balances: Array<OrganizationExternalAccountBalance>;
  /** lastUpdatedAt is the timestamp of the latest change in the account balance */
  lastUpdatedAt: Scalars['Timestamp']['output'];
  /** totalBalance is the total balance of the account in USD */
  totalBalance: Amount;
};

export type OrganizationModule = Node & {
  __typename: 'OrganizationModule';
  createdAt: Scalars['Time']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  moduleType: OrganizationModuleModuleType;
  name: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type OrganizationModuleConnection = {
  __typename: 'OrganizationModuleConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<OrganizationModuleEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrganizationModuleEdge = {
  __typename: 'OrganizationModuleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<OrganizationModule>;
};

/** OrganizationModuleModuleType is enum for the field module_type */
export enum OrganizationModuleModuleType {
  BASE = 'BASE',
  SPECIFIC = 'SPECIFIC',
  UNSPECIFIED = 'UNSPECIFIED'
}

/**
 * OrganizationModuleWhereInput is used for filtering OrganizationModule objects.
 * Input was generated by ent.
 */
export type OrganizationModuleWhereInput = {
  and?: InputMaybe<Array<OrganizationModuleWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** module_type field predicates */
  moduleType?: InputMaybe<OrganizationModuleModuleType>;
  moduleTypeIn?: InputMaybe<Array<OrganizationModuleModuleType>>;
  moduleTypeNEQ?: InputMaybe<OrganizationModuleModuleType>;
  moduleTypeNotIn?: InputMaybe<Array<OrganizationModuleModuleType>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<OrganizationModuleWhereInput>;
  or?: InputMaybe<Array<OrganizationModuleWhereInput>>;
};

/** Notes for a single organization */
export type OrganizationNotes = {
  __typename: 'OrganizationNotes';
  /** Timestamp in nanos of when these notes were last edited */
  lastEditedAt: Scalars['String']['output'];
  /** Email of the risk user that last edited this comment */
  lastEditedBy: Scalars['String']['output'];
  /** Content of the notes */
  notesContent: Scalars['String']['output'];
};

/** OrganizationPurposeType is enum for the field purpose */
export enum OrganizationPurpose {
  /** An organization containing asset information */
  ASSET_SUPPORT = 'ASSET_SUPPORT',
  /** An organization containing quorum recovery information */
  QUORUM_RECOVERY = 'QUORUM_RECOVERY'
}

/** OrganizationRenameInput represents the input needed to rename an organization */
export type OrganizationRenameInput = {
  /** The new name of the organization. */
  name: Scalars['String']['input'];
};

/** Trading availability for an organization */
export enum OrganizationTradingStatus {
  /** Trading disabled */
  DISABLED = 'DISABLED',
  /** Trading enabled */
  ENABLED = 'ENABLED'
}

/** OrganizationsDB contains a pageable list of organizations */
export type OrganizationsDB = {
  __typename: 'OrganizationsDB';
  /** edges implements the connections spec */
  edges: Array<Maybe<OrganizationsDBEdge>>;
  /** pageInfo implements the connections spec */
  pageInfo: PageInfo;
};

/** OrganizationsDBEdge implements the connections spec */
export type OrganizationsDBEdge = {
  __typename: 'OrganizationsDBEdge';
  /** cursor implements the connections spec */
  cursor: Scalars['String']['output'];
  /** node implements the connections spec */
  node: Organization;
};

/** Error occurred when API key has outstanding holds. */
export type OutstandingHoldsError = ErrorMessage & {
  __typename: 'OutstandingHoldsError';
  /** An error message. */
  message: Scalars['String']['output'];
};

/** PII Information Types */
export enum PIIInformationType {
  /** App access desired. E.g. Admin, regular, none. */
  ACCESS_TYPE = 'ACCESS_TYPE',
  /** First part of the address, usually door number and street */
  ADDRESS = 'ADDRESS',
  /** Second part of the address, usually city, state and zip code */
  ADDRESS2 = 'ADDRESS2',
  /** CITY */
  CITY = 'CITY',
  /** Country */
  COUNTRY = 'COUNTRY',
  /** Date of birth */
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  /** Email */
  EMAIL = 'EMAIL',
  /** First Name */
  FIRST_NAME = 'FIRST_NAME',
  /** ID Card back side picture */
  ID_CARD_BACK = 'ID_CARD_BACK',
  /** ID Card front side picture */
  ID_CARD_FRONT = 'ID_CARD_FRONT',
  /** The type of individual. E.g. beneficial owner, authorized person, participating member */
  INDIVIDUAL_TYPE = 'INDIVIDUAL_TYPE',
  /** Last Name */
  LAST_NAME = 'LAST_NAME',
  /** Name */
  NAME = 'NAME',
  /** Phone number */
  PHONE = 'PHONE',
  /** Social security number */
  SSN = 'SSN',
  /** State */
  STATE = 'STATE',
  /** Voice and video recording */
  VOICE_PHRASE = 'VOICE_PHRASE',
  /** Zip code */
  ZIP_CODE = 'ZIP_CODE'
}

/** Rejection reasons for PII entries */
export enum PIIRequirementReason {
  /** The image is too blurry */
  BLURRY = 'BLURRY',
  /** The submitted value was inconclusive */
  INCONCLUSIVE = 'INCONCLUSIVE',
  /** The submitted value is invalid */
  INVALID = 'INVALID',
  /** The information type is missing */
  MISSING = 'MISSING',
  /** Other */
  OTHER = 'OTHER'
}

/** Type used to return personal identifiable information */
export type PIIValue = {
  __typename: 'PIIValue';
  /** The type of information */
  piiType: PIIInformationType;
  /** The value of the information */
  value: Maybe<Scalars['String']['output']>;
};

/** PageInfo implements the connections spec */
export type PageInfo = {
  __typename: 'PageInfo';
  /** endCursor returns the cursor of the last value in the page returned */
  endCursor: Scalars['String']['output'];
  /** hasNextPage implements the connections spec */
  hasNextPage: Scalars['Boolean']['output'];
  /** hasPreviousPage implements the connections spec */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** startCursor returns the cursor of the first value in the page returned */
  startCursor: Scalars['String']['output'];
  /** totalResults returns the number of all results without pagination */
  totalResults: Maybe<Scalars['Int']['output']>;
};

/** PageParams holds the information for paginating the query */
export type PageParams = {
  /** Cursor after which edges should be picked - for forward pagination */
  after?: InputMaybe<Scalars['ID']['input']>;
  /** Cursor before which edges should be picked - for backward pagination */
  before?: InputMaybe<Scalars['ID']['input']>;
  /** Maximum number of edges returned after the 'after' cursor - for forward pagination */
  first?: InputMaybe<Scalars['Int']['input']>;
  /** Maximum number of edges returned before the 'before' cursor - for backward pagination */
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Paragraph represents a paragraph of text with an icon. */
export type Paragraph = {
  __typename: 'Paragraph';
  /**
   * iconURL returns the url for fetching the icon for this network.
   * If the type is SVG, the size is ignored.
   * Icons are square.
   * The size is in pixels.
   */
  iconURL: Scalars['String']['output'];
  /** The text to be shown */
  text: Scalars['String']['output'];
};


/** Paragraph represents a paragraph of text with an icon. */
export type ParagraphiconURLArgs = {
  iconType?: IconType;
  size?: Scalars['Int']['input'];
};

export type Party = Node & {
  __typename: 'Party';
  accountID: Maybe<Scalars['String']['output']>;
  accountName: Maybe<Scalars['String']['output']>;
  accounts: Maybe<Array<CollateralAccount>>;
  caPledgers: Maybe<Array<CollateralAgreement>>;
  caSecuredParties: Maybe<Array<CollateralAgreement>>;
  callCallers: Maybe<Array<CollateralCall>>;
  callCounterparties: Maybe<Array<CollateralCall>>;
  createdAt: Scalars['Timestamp']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inventoryOwners: Maybe<Array<CollateralInventory>>;
  metadata: Maybe<PartyMetadata>;
  name: Scalars['String']['output'];
  orgID: Maybe<Scalars['String']['output']>;
  orgName: Maybe<Scalars['String']['output']>;
  status: PartyStatus;
  typeID: PartyTypeID;
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type PartyConnection = {
  __typename: 'PartyConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PartyEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PartyEdge = {
  __typename: 'PartyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Party>;
};

/** PartyMetadata maintains additional fields for the collateral party */
export type PartyMetadata = {
  __typename: 'PartyMetadata';
  emails: Maybe<Array<Scalars['String']['output']>>;
  lendingEntityID: Maybe<Scalars['String']['output']>;
  vaultSubID: Maybe<Scalars['Int']['output']>;
};

/** Ordering options for Party connections */
export type PartyOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Parties. */
  field: PartyOrderField;
};

/** Properties by which Party connections can be ordered. */
export enum PartyOrderField {
  ACCOUNT_ID = 'ACCOUNT_ID',
  ACCOUNT_NAME = 'ACCOUNT_NAME',
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  ORG_ID = 'ORG_ID',
  ORG_NAME = 'ORG_NAME',
  STATUS = 'STATUS',
  TYPE_ID = 'TYPE_ID'
}

/** PartyStatus is enum for the field status */
export enum PartyStatus {
  PARTY_STATUS_DELETED = 'PARTY_STATUS_DELETED',
  PARTY_STATUS_DISABLED = 'PARTY_STATUS_DISABLED',
  PARTY_STATUS_ENABLED = 'PARTY_STATUS_ENABLED',
  PARTY_STATUS_UNSPECIFIED = 'PARTY_STATUS_UNSPECIFIED'
}

/** PartyTypeID is enum for the field type_id */
export enum PartyTypeID {
  PARTY_TYPE_ID_COLLATERAL_MANAGER = 'PARTY_TYPE_ID_COLLATERAL_MANAGER',
  PARTY_TYPE_ID_PLEDGER = 'PARTY_TYPE_ID_PLEDGER',
  PARTY_TYPE_ID_SECURED_PARTY = 'PARTY_TYPE_ID_SECURED_PARTY',
  PARTY_TYPE_ID_UNSPECIFIED = 'PARTY_TYPE_ID_UNSPECIFIED'
}

/**
 * PartyWhereInput is used for filtering Party objects.
 * Input was generated by ent.
 */
export type PartyWhereInput = {
  /** account_id field predicates */
  accountID?: InputMaybe<Scalars['String']['input']>;
  accountIDContains?: InputMaybe<Scalars['String']['input']>;
  accountIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  accountIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  accountIDGT?: InputMaybe<Scalars['String']['input']>;
  accountIDGTE?: InputMaybe<Scalars['String']['input']>;
  accountIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  accountIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  accountIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  accountIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  accountIDLT?: InputMaybe<Scalars['String']['input']>;
  accountIDLTE?: InputMaybe<Scalars['String']['input']>;
  accountIDNEQ?: InputMaybe<Scalars['String']['input']>;
  accountIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  accountIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** account_name field predicates */
  accountName?: InputMaybe<Scalars['String']['input']>;
  accountNameContains?: InputMaybe<Scalars['String']['input']>;
  accountNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  accountNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  accountNameGT?: InputMaybe<Scalars['String']['input']>;
  accountNameGTE?: InputMaybe<Scalars['String']['input']>;
  accountNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  accountNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  accountNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  accountNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  accountNameLT?: InputMaybe<Scalars['String']['input']>;
  accountNameLTE?: InputMaybe<Scalars['String']['input']>;
  accountNameNEQ?: InputMaybe<Scalars['String']['input']>;
  accountNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  accountNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<PartyWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<CollateralAccountWhereInput>>;
  /** ca_pledgers edge predicates */
  hasCaPledgers?: InputMaybe<Scalars['Boolean']['input']>;
  hasCaPledgersWith?: InputMaybe<Array<CollateralAgreementWhereInput>>;
  /** ca_secured_parties edge predicates */
  hasCaSecuredParties?: InputMaybe<Scalars['Boolean']['input']>;
  hasCaSecuredPartiesWith?: InputMaybe<Array<CollateralAgreementWhereInput>>;
  /** call_callers edge predicates */
  hasCallCallers?: InputMaybe<Scalars['Boolean']['input']>;
  hasCallCallersWith?: InputMaybe<Array<CollateralCallWhereInput>>;
  /** call_counterparties edge predicates */
  hasCallCounterparties?: InputMaybe<Scalars['Boolean']['input']>;
  hasCallCounterpartiesWith?: InputMaybe<Array<CollateralCallWhereInput>>;
  /** inventory_owners edge predicates */
  hasInventoryOwners?: InputMaybe<Scalars['Boolean']['input']>;
  hasInventoryOwnersWith?: InputMaybe<Array<CollateralInventoryWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<PartyWhereInput>;
  or?: InputMaybe<Array<PartyWhereInput>>;
  /** org_id field predicates */
  orgID?: InputMaybe<Scalars['String']['input']>;
  orgIDContains?: InputMaybe<Scalars['String']['input']>;
  orgIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  orgIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  orgIDGT?: InputMaybe<Scalars['String']['input']>;
  orgIDGTE?: InputMaybe<Scalars['String']['input']>;
  orgIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  orgIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  orgIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  orgIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  orgIDLT?: InputMaybe<Scalars['String']['input']>;
  orgIDLTE?: InputMaybe<Scalars['String']['input']>;
  orgIDNEQ?: InputMaybe<Scalars['String']['input']>;
  orgIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  orgIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** org_name field predicates */
  orgName?: InputMaybe<Scalars['String']['input']>;
  orgNameContains?: InputMaybe<Scalars['String']['input']>;
  orgNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  orgNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  orgNameGT?: InputMaybe<Scalars['String']['input']>;
  orgNameGTE?: InputMaybe<Scalars['String']['input']>;
  orgNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  orgNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  orgNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  orgNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  orgNameLT?: InputMaybe<Scalars['String']['input']>;
  orgNameLTE?: InputMaybe<Scalars['String']['input']>;
  orgNameNEQ?: InputMaybe<Scalars['String']['input']>;
  orgNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  orgNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** status field predicates */
  status?: InputMaybe<PartyStatus>;
  statusIn?: InputMaybe<Array<PartyStatus>>;
  statusNEQ?: InputMaybe<PartyStatus>;
  statusNotIn?: InputMaybe<Array<PartyStatus>>;
  /** type_id field predicates */
  typeID?: InputMaybe<PartyTypeID>;
  typeIDIn?: InputMaybe<Array<PartyTypeID>>;
  typeIDNEQ?: InputMaybe<PartyTypeID>;
  typeIDNotIn?: InputMaybe<Array<PartyTypeID>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

/** PaymentAmount contains client settlement payments amount and count */
export type PaymentAmount = {
  __typename: 'PaymentAmount';
  /** Amount of payments */
  amount: Amount;
  /** Count of payments */
  count: Scalars['Int']['output'];
};

/** PaymentFlow is a payment flow */
export type PaymentFlow = Node & {
  __typename: 'PaymentFlow';
  /**
   * Display Amount of the payment flow, this value is rounded
   * and shouldn't be used for any calculations, use value instead
   * @deprecated Use tradingAmount instead
   */
  amount: Amount;
  /** Counterparty of the payment flow */
  counterparty: Scalars['String']['output'];
  /** Timestamp of creation of the payment flow */
  createdAt: Scalars['Timestamp']['output'];
  /** Entity of the payment flow ('HOLD', 'SINGAPORE', 'A1', etc.) */
  entity: Scalars['String']['output'];
  /** Id of the payment flow */
  id: Scalars['ID']['output'];
  /** Order id of the payment flow */
  orderID: Scalars['String']['output'];
  /** Settlement ID of the payment flow */
  settlementID: Maybe<Scalars['String']['output']>;
  /** Settlement status */
  settlementStatus: Maybe<DealerSettlementStatus>;
  /** Side of the payment flow */
  side: TradeSide;
  /** Trading amount of the payment flow */
  tradingAmount: TradingAmount;
  /**
   * CounterpartyWithErr object related to this payment flow.
   * This field is automatically resolved based on the counterparty
   */
  tradingCounterparty: Maybe<CounterpartyWithErr>;
  /**
   * Amount of the payment flow, this is the value with maximum precision
   * This is the value that should be used for calculations
   */
  value: Scalars['Decimal']['output'];
};

/** Payment flow connection for pagination */
export type PaymentFlowConnection = {
  __typename: 'PaymentFlowConnection';
  /** List of edges containing payment flow and cursor information */
  edges: Array<Maybe<PaymentFlowEdge>>;
  /** Page details for the associated list of payment flow edges */
  pageInfo: PageInfo;
};

/** Payment flow edge for pagination */
export type PaymentFlowEdge = {
  __typename: 'PaymentFlowEdge';
  /** Cursor for the associated dealer trades */
  cursor: Scalars['String']['output'];
  /** Dealer trades */
  node: PaymentFlow;
};

/** Collection of pedometer data */
export type Pedometer = {
  __typename: 'Pedometer';
  /** List of pedometer data entries */
  data: Array<PedometerData>;
  /** State of the pedometer data collection */
  state: Scalars['String']['output'];
};

/** Holds information collected via pedometer */
export type PedometerData = {
  __typename: 'PedometerData';
  /** End collection date with nano precision */
  endDate: Scalars['String']['output'];
  /** Start collection date with nano precision */
  startDate: Scalars['String']['output'];
  /** Number of steps taken. -1 means error */
  steps: Scalars['Int']['output'];
};

/** Natural person with a unique government identifier */
export type Person = Node & {
  __typename: 'Person';
  /**
   * Email of the Person
   * Fetched from latest KYC submission, except for Porto only persons
   */
  email: Scalars['String']['output'];
  /** ID of the Person */
  id: Scalars['ID']['output'];
  /** Institutional affiliates that this person is associated with. */
  institutionalAffiliateRelationships: InstitutionalAffiliateRelationshipPairConnection;
  /** The latest KYC InstitutionalIndividual that represents this natural person */
  institutionalIndividual: Maybe<InstitutionalIndividual>;
  /** All institutional individuals associated with this this natural person */
  institutionalIndividuals: InstitutionalIndividualConnection;
  /**
   * Name of the Person
   * Fetched from latest KYC submission, except for Porto only persons
   */
  name: Scalars['String']['output'];
  /**
   * Phone number of the Person
   * Fetched from latest KYC submission
   */
  phoneNumber: Scalars['String']['output'];
  /** List of unique devices used by this person */
  uniqueDevices: UniqueDeviceConnection;
  /**
   * A list of users that represent the enrollments this natural person
   * has done across several organizations
   */
  users: UserConnection;
  /**
   * A list of users that represent the enrollments this natural person
   * has done across several organizations
   */
  usersV2: UserV2Connection;
};


/** Natural person with a unique government identifier */
export type PersoninstitutionalAffiliateRelationshipsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Natural person with a unique government identifier */
export type PersoninstitutionalIndividualsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Natural person with a unique government identifier */
export type PersonuniqueDevicesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UniqueDeviceWhereInput>;
};


/** Natural person with a unique government identifier */
export type PersonusersArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Natural person with a unique government identifier */
export type PersonusersV2Args = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A connection to a list of persons. */
export type PersonConnection = {
  __typename: 'PersonConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PersonEdge>>>;
  /** Information to aid in pagination */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection */
  totalCount: Scalars['Int']['output'];
};

/** Contains the result of the checks ran */
export type PersonDataQualityChecksResult = {
  __typename: 'PersonDataQualityChecksResult';
  /** Description of the error occurred */
  error: Maybe<Scalars['String']['output']>;
  /** List of the exceptions found with persons' data */
  result: Maybe<Array<PersonDataQualityException>>;
  /** Current state of the person quality checks */
  state: PersonDataQualityChecksState;
};

/** Indicates the state of the person data quality checks */
export enum PersonDataQualityChecksState {
  /** Person data quality checks have been completed successfully */
  COMPLETE = 'COMPLETE',
  /** There was an error while running the person data quality */
  ERROR = 'ERROR',
  /** Person data quality checks have not started */
  NOT_STARTED = 'NOT_STARTED',
  /** Person data quality checks are still running */
  RUNNING = 'RUNNING'
}

/** Person data quality exception. */
export type PersonDataQualityException = {
  __typename: 'PersonDataQualityException';
  /** Human-readable reason for this exception. */
  exceptionReason: Scalars['String']['output'];
  /** Person IDs associated with this exception */
  personIDs: Array<Scalars['ID']['output']>;
  /** Person names associated with this exception. They are in the same order as the IDs */
  personNames: Array<Scalars['String']['output']>;
};

/** An edge in a connection, representing a Person instance */
export type PersonEdge = {
  __typename: 'PersonEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge */
  node: Maybe<Person>;
};

/** Ordering options for Person connections */
export type PersonOrderBy = {
  /** The ordering direction */
  direction?: OrderDirection;
  /** The field by which to order Persons */
  field: PersonOrderByField;
};

/** Properties by which Person connections can be ordered */
export enum PersonOrderByField {
  /** Order Persons by email */
  EMAIL = 'EMAIL',
  /** Order Persons by name */
  NAME = 'NAME'
}

/** A filter to be used against Person fields. Filters are mutually exclusive. */
export type PersonWhereInput = {
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Person IDs to filter by */
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** String to search for in PII values */
  piiContains?: InputMaybe<Scalars['String']['input']>;
};

/** Policy is constructed of lists of quorums and other data */
export type Policy = {
  __typename: 'Policy';
  /** The action associated with this policy */
  action: PolicyActionType;
  /** List of keys authorized to initiate these types of operations */
  initiateOnlyUsers: Array<User>;
  /** quorums are the list of quorums for this policy */
  quorums: Array<Quorum>;
  /**
   * List of users that are required in order to perform this operation.
   * This list is not cryptographically protected.
   * @deprecated use requiredUsersQuorums instead
   */
  requiredUsers: Array<User>;
  /**
   * requiredUsersQuorums are the softly required user quorums for this policy.
   * If there are no requiredUsersQuorums an empty list will be returned.
   */
  requiredUsersQuorums: Array<RequiredUsersQuorum>;
};


/** Policy is constructed of lists of quorums and other data */
export type PolicyinitiateOnlyUsersArgs = {
  sortField?: InputMaybe<UserSortField>;
};


/** Policy is constructed of lists of quorums and other data */
export type PolicyrequiredUsersArgs = {
  sortField?: InputMaybe<UserSortField>;
};

/**
 * Enumerates the different possible policy actions
 * This enum is not exaustive compared to schema.Action since there are actions that cannot
 * actually be protected by a policy, e.g. a deposit
 */
export enum PolicyActionType {
  /** request to create a new vault */
  CREATE_VAULT = 'CREATE_VAULT',
  /** request for an address at which assets may be deposited */
  DEPOSIT = 'DEPOSIT',
  /** request to create a new generic organization change */
  GENERIC_ORGANIZATION_CHANGE = 'GENERIC_ORGANIZATION_CHANGE',
  /** Used for actions that have no governing policy. */
  NONE = 'NONE',
  /** request to create a new offchain operation */
  OFFCHAIN_OPERATION_ADD = 'OFFCHAIN_OPERATION_ADD',
  /** request to change an organization */
  ORGANIZATION_CHANGE = 'ORGANIZATION_CHANGE',
  /** request to change an organization's policy */
  ORGANIZATION_POLICY_CHANGE = 'ORGANIZATION_POLICY_CHANGE',
  /** request to change an organization fragment binding */
  ORGANIZATION_POLICY_FRAGMENT_BINDING_CHANGE = 'ORGANIZATION_POLICY_FRAGMENT_BINDING_CHANGE',
  /** request to change an organization role binding */
  ORGANIZATION_POLICY_ROLE_BINDING_CHANGE = 'ORGANIZATION_POLICY_ROLE_BINDING_CHANGE',
  /** request to rename an organization */
  ORGANIZATION_RENAME = 'ORGANIZATION_RENAME',
  /** request to add a quorum recovery entry */
  QUORUM_RECOVERY_ADD = 'QUORUM_RECOVERY_ADD',
  /** request to remove a quorum recovery entry */
  QUORUM_RECOVERY_REMOVE = 'QUORUM_RECOVERY_REMOVE',
  /** request to create, update or delete a role */
  ROLE_CHANGE = 'ROLE_CHANGE',
  /** request to add a new trusted destination */
  TRUSTED_DESTINATION_ADD = 'TRUSTED_DESTINATION_ADD',
  /** request to remove an existing trusted destination */
  TRUSTED_DESTINATION_REMOVE = 'TRUSTED_DESTINATION_REMOVE',
  /** request to add a new trusted smart contract */
  TRUSTED_SMART_CONTRACT_ADD = 'TRUSTED_SMART_CONTRACT_ADD',
  /** request to remove an existing trusted smart contract */
  TRUSTED_SMART_CONTRACT_REMOVE = 'TRUSTED_SMART_CONTRACT_REMOVE',
  /** request to add a user */
  USER_ADD = 'USER_ADD',
  /** request to remove a user */
  USER_REMOVE = 'USER_REMOVE',
  /** request to change a vault's policy */
  VAULT_POLICY_CHANGE = 'VAULT_POLICY_CHANGE',
  /** request to change a vault fragment binding */
  VAULT_POLICY_FRAGMENT_BINDING_CHANGE = 'VAULT_POLICY_FRAGMENT_BINDING_CHANGE',
  /** request to change a vault role binding */
  VAULT_POLICY_ROLE_BINDING_CHANGE = 'VAULT_POLICY_ROLE_BINDING_CHANGE',
  /** request to move funds out of a vault */
  WITHDRAW = 'WITHDRAW'
}

/**
 * PolicyContainer represents a single identifiable subject in a policy graph.
 * The policy graph is a single parented hierarchy structure such that all policy containers inherit policy data and fragments from their ancestry up
 * through the highest known parent in an evaluation context.
 */
export type PolicyContainer = {
  __typename: 'PolicyContainer';
  /** The globally unique identifier for the policy container. */
  containerID: Scalars['String']['output'];
  /** The policy data that is attached to the container. */
  dataContainer: PolicyDataContainer;
  /** The fragment data that is attached to the container. */
  fragmentContainer: PolicyFragmentContainer;
  /**
   * The Policy Container of the parent type in the hierarchy.
   * Organizations do not have a parent.
   */
  parent: Maybe<PolicyContainer>;
};

/** PolicyContainerUpdate is the collection of updates being applied to the policy container */
export type PolicyContainerUpdate = {
  __typename: 'PolicyContainerUpdate';
  /** fragmentBindingUpdates is the fragment changes that will be applied to the policy */
  fragmentBindingUpdates: Maybe<Array<Maybe<PolicyFragmentBindingUpdate>>>;
  /** roleBindingUpdates is the role binding changes that will be applied to the policy */
  roleBindingUpdates: Maybe<Array<Maybe<PolicyRoleBindingUpdate>>>;
};

/** Secure information required to evaluate policies. */
export type PolicyDataContainer = {
  __typename: 'PolicyDataContainer';
  /** The bindings of principals to roles on the container. */
  roleBindings: Array<PolicyRoleBinding>;
  /** The roles that are defined on the policy container. */
  roles: Array<PolicyRole>;
};

/** The fragment that may be met in order to perform the scoped action. */
export type PolicyFragment = PolicyStructuredFragment;

/** A scoped policy fragment that may be met in order to perform the scoped action. */
export type PolicyFragmentBinding = Node & {
  __typename: 'PolicyFragmentBinding';
  /** The human readable description of the binding. */
  description: Scalars['String']['output'];
  /** The human readable display name of the binding. */
  displayName: Scalars['String']['output'];
  /** A globally unique identifier of the binding. */
  fragmentBindingID: Scalars['String']['output'];
  /** The fragments that may be met in order to perform the scoped action. */
  fragments: Array<PolicyFragment>;
  /** The ID of the PolicyFragmentBinding. Mirrors fragmentBindingID */
  id: Scalars['ID']['output'];
  /** The fragment is for internal use and should not be displayed to users. */
  internal: Scalars['Boolean']['output'];
  /** The action that the binding applies to. */
  scope: Scalars['String']['output'];
};

/** PolicyFragmentBindingUpdate is an update that will be applied to a single fragment */
export type PolicyFragmentBindingUpdate = {
  __typename: 'PolicyFragmentBindingUpdate';
  /** originalBinding is the fragment binding prior to update. This will not be present for newly added fragment bindings. */
  originalBinding: Maybe<PolicyFragmentBinding>;
  /** updatedBinding is the fragment binding prior to update. This will not be present for fragment bindings being deleted. */
  updatedBinding: Maybe<PolicyFragmentBinding>;
};

/** Policy fragment binding pagination connection. */
export type PolicyFragmentConnection = {
  __typename: 'PolicyFragmentConnection';
  /** List of edges containing policy fragment bindings. */
  edges: Array<PolicyFragmentEdge>;
  /** Page details for the policy fragment connection. */
  pageInfo: PageInfo;
};

/** Policy fragments that define governance of the container. */
export type PolicyFragmentContainer = {
  __typename: 'PolicyFragmentContainer';
  /** Scoped policies that are bound to the container. */
  fragmentBindings: Array<PolicyFragmentBinding>;
};

/** Policy fragment binding edge connection. */
export type PolicyFragmentEdge = {
  __typename: 'PolicyFragmentEdge';
  /** Cursor implements the connections spec for pagination */
  cursor: Scalars['String']['output'];
  /** Node implements the connections spec for pagination */
  node: PolicyFragmentBinding;
};

/**
 * PolicyInfo is the information about the policy containers on the resource.
 * This should be called 'Policy' but due to legacy migration needs a unique name.
 */
export type PolicyInfo = {
  __typename: 'PolicyInfo';
  /**
   * The directly applied policy of the organization.
   * All child resources (such as vaults) will inherit the applied policy following inheritance semantics.
   */
  appliedPolicy: Maybe<PolicyContainer>;
  /** The effective policy of the organization when inheritance is applied. */
  effectivePolicy: Maybe<PolicyContainer>;
  /** The source of truth for the policy. */
  sourceOfTruth: Maybe<PolicySourceOfTruth>;
};


/**
 * PolicyInfo is the information about the policy containers on the resource.
 * This should be called 'Policy' but due to legacy migration needs a unique name.
 */
export type PolicyInfoappliedPolicyArgs = {
  sortField?: InputMaybe<FragmentBindingSortField>;
};


/**
 * PolicyInfo is the information about the policy containers on the resource.
 * This should be called 'Policy' but due to legacy migration needs a unique name.
 */
export type PolicyInfoeffectivePolicyArgs = {
  sortField?: InputMaybe<FragmentBindingSortField>;
};

/**
 * A a threshold based majority of endorsements.
 * Quorums within Anchorage only support the concept of "ENDORSE" and "REJECT" and do not support the concept of votes.
 * This means 2 things:
 *    1. Any rejection will ultimately supercede an action and the quorum will be fundamentally unfulfillable.
 *    2. If any N set of members endorse such that N is greater than the threshold, the quorum is considered fulfilled
 */
export type PolicyQuorum = Node & {
  __typename: 'PolicyQuorum';
  /** The human readable description of the quorum. */
  description: Maybe<Scalars['String']['output']>;
  /** The effective set of members in the quorum when members is expanded. */
  displayMembers: Array<Actor>;
  /** The human readable display name of the quorum. */
  displayName: Maybe<Scalars['String']['output']>;
  /**
   * The effective set of members in the quorum when members is expanded.
   * @deprecated Legacy UserV1. Use 'displayMembers' instead.
   */
  expandedMembers: Array<User>;
  /** The ID of the PolicyQuorum. This ID will be based on the quorum position within the array. */
  id: Scalars['ID']['output'];
  /** The set of principals that will be granted the role. */
  members: Array<Scalars['String']['output']>;
  /** The strictness requirement of fulfilling the quorum in order to fulfill the containing policy fragment. */
  quorumRequirement: QuorumRequirement;
  /** The number of endorsements from the expanded members which must be provided. */
  threshold: Scalars['Int']['output'];
};

/** Policy quorum pagination connection. */
export type PolicyQuorumConnection = {
  __typename: 'PolicyQuorumConnection';
  /** A list of edges. */
  edges: Array<PolicyQuorumEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PolicyQuorumEdge = {
  __typename: 'PolicyQuorumEdge';
  /** A CursorType for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: PolicyQuorum;
};

/** Holds unsatisfiable policies at the org and vaults level. */
export type PolicyQuorumSummary = {
  __typename: 'PolicyQuorumSummary';
  /** Analysis of organization actions */
  inOrg: Array<OrgPolicyQuorumSummary>;
  /** Analysis of vaults actions */
  inVaults: Array<VaultPolicyQuorumSummary>;
  /** Returns status for the most at-risk policy. */
  status: PolicyStatus;
};

/** PolicyResource represents the resource that the policy is applied to. */
export type PolicyResource = Organization | Vault;

/** A role that may be granted to principals of the Organization. */
export type PolicyRole = {
  __typename: 'PolicyRole';
  /** The permissions and roles that comprise the role. */
  members: Array<Scalars['String']['output']>;
  /** A globally unique identifier of the role. */
  roleID: Scalars['String']['output'];
};

/** A relationship between a role and a set of principals that will be granted that role. */
export type PolicyRoleBinding = {
  __typename: 'PolicyRoleBinding';
  /** The set of principals that will be granted the role. */
  members: Array<Scalars['String']['output']>;
  /** The role that the binding is granting. */
  role: PolicyRole;
  /** A globally unique identifier of the binding. */
  roleBindingID: Scalars['String']['output'];
};

/** PolicyRoleBindingUpdate is an update that will be applied to a single role binding fragment. */
export type PolicyRoleBindingUpdate = {
  __typename: 'PolicyRoleBindingUpdate';
  /** originalBinding is the role binding prior to update. This will not be present for newly added role bindings. */
  originalBinding: Maybe<PolicyRoleBinding>;
  /** updatedBinding is the binding after update. This will not be present for bindings being deleted. */
  updatedBinding: Maybe<PolicyRoleBinding>;
};

/**
 * PolicySourceOfTruth is the current source of truth for policies.
 * As organizations move from governance policies to policy containers they move from POLICY_SOT_LEGACY_EXCLUSIVE -> POLICY_SOT_CONTAINER.
 */
export enum PolicySourceOfTruth {
  /** Policy is defined and enforced by policy containers for this resource. */
  POLICY_SOT_CONTAINER = 'POLICY_SOT_CONTAINER',
  /**
   * Policy is defined and enforce by the legacy governance policies for this resource.
   * Policy Containers are derived from the legacy governance policies.
   */
  POLICY_SOT_LEGACY = 'POLICY_SOT_LEGACY',
  /** Policy is defined and enforced by legacy governance policies for this resource. */
  POLICY_SOT_LEGACY_EXCLUSIVE = 'POLICY_SOT_LEGACY_EXCLUSIVE'
}

/** Possible policy status */
export enum PolicyStatus {
  /** At least one quorum has as many users as the minimum required */
  AT_RISK = 'AT_RISK',
  /** All quorums are healthy */
  GOOD = 'GOOD',
  /** At least one quorum cannot be achieved */
  LOST = 'LOST',
  /** At least one quorum is reaching the minimum required users for quorum */
  NEEDS_ATTENTION = 'NEEDS_ATTENTION'
}

/** A strongly typed policy fragment that may be fulfilled in order to perform a scoped action. */
export type PolicyStructuredFragment = {
  __typename: 'PolicyStructuredFragment';
  /** The minimum number of members required in the fragment base quorum. */
  defaultMinimumMembers: Scalars['Int']['output'];
  /** The minimum number of endorsements required in the fragment base quorum. */
  defaultMinimumThreshold: Scalars['Int']['output'];
  /** All the quorums that must be met in order to fulfill the fragment. */
  quorums: Maybe<Array<PolicyQuorum>>;
  /** The destinations for applicable actions that must be used in order to fulfill the fragment. */
  restrictedDestinations: Maybe<Array<Scalars['String']['output']>>;
  /** The type of back office risk review that must be performed. */
  riskReviewRequirement: RiskReviewRequirement;
};

/** Represents an org policy update */
export type PolicyUpdate = {
  __typename: 'PolicyUpdate';
  /** Original policy */
  originalPolicy: Policy;
  /** new initiators list for the policy */
  updatedInitiateOnlyUsers: Array<User>;
  /** quorums are the list of quorums for this policy */
  updatedQuorums: Array<Quorum>;
  /**
   * new list of required users.
   * this list is not cryptographically protected
   * @deprecated use updatedRequiredUsersQuorums instead
   */
  updatedRequiredUsers: Array<User>;
  /**
   * updatedRequiredUsersQuorums are the softly required user quorums for this policy update.
   * If there are no updatedRequiredUsersQuorums an empty list will be returned.
   */
  updatedRequiredUsersQuorums: Array<RequiredUsersQuorum>;
};


/** Represents an org policy update */
export type PolicyUpdateupdatedInitiateOnlyUsersArgs = {
  sortField?: InputMaybe<UserSortField>;
};


/** Represents an org policy update */
export type PolicyUpdateupdatedRequiredUsersArgs = {
  sortField?: InputMaybe<UserSortField>;
};

/** Type of lending pool */
export enum PoolType {
  /** Accelerator program pool type */
  ACCELERATOR = 'ACCELERATOR',
  /** Conservative pool type */
  CONSERVATIVE = 'CONSERVATIVE',
  /** Moderate pool type */
  MODERATE = 'MODERATE',
  /** Fixed term pool type */
  TERM = 'TERM'
}

/** Organization or vault level summary of holdings */
export type Portfolio = {
  __typename: 'Portfolio';
  /**
   * allNFTCollections returns all NFT collections without pagination based on which NFTs we have per vault
   * Set debug to true to enable more logging.
   */
  allNFTCollections: Array<Maybe<NFTCollection>>;
  /** Percentage representing how much the portfolio balance changed in the past 24h (proportion out of 1 format, Eg: 1, 0.5, 0.1) */
  dayChange: Scalars['String']['output'];
  /**
   * GroupHoldingsBreakdown returns holdingsBreakdowns and can optionally be used to query for a specific type of holdings (fiat/crypto)
   * HoldingsBreakdown contains two concepts:
   * - A holding is the total balance for a given asset type
   * - The breakdown adds additional info to this balance, including sub-balances and the percentage of the portfolio it makes up
   * Set debug to true to enable more logging.
   * Note: holdingsBreakdown can cause significant query latency, especially when aggregating large numbers of wallets. Use NonZeroHoldings instead when you can.
   */
  groupHoldingsBreakdown: GroupHoldingBreakdown;
  /**
   * HoldingBreakdown is a misnomer - should have been called HoldingWithBreakdowns:
   * - A holding is the total balance (amount with asset type) for a given asset type.
   * - The breakdown adds additional info to this balance, including sub-balances and the percentage of the portfolio allocated to each asset type.
   * Set debug to true to enable more logging.
   * Note: holdingsBreakdown can cause significant query latency, especially when aggregating large numbers of wallets. Use NonZeroHoldings instead when you can.
   * @deprecated Doesn't handle fiat/cyrpto distinction, use groupHoldingsBreakdown instead
   */
  holdingsBreakdown: Array<HoldingBreakdown>;
  /**
   * NonZeroHoldings returns a list of holdings with the following properties:
   *   - A holding is the total balance (amount with asset type) for a given asset type.
   *   - The zero balances have been filtered out.
   * Should be used in preference to holdingsBreakdown.
   */
  nonZeroHoldings: Array<Amount>;
  /**
   * The value in USD of all holdings for the organization or vault
   * @deprecated Use totalBalanceAmount instead
   */
  totalBalance: Scalars['String']['output'];
  /** TotalBalanceAmount returns an amount type object representing the value in USD of all holdings for the organization or vault */
  totalBalanceAmount: Amount;
};


/** Organization or vault level summary of holdings */
export type PortfolioallNFTCollectionsArgs = {
  debug?: Scalars['Boolean']['input'];
};


/** Organization or vault level summary of holdings */
export type PortfoliogroupHoldingsBreakdownArgs = {
  isFiat?: InputMaybe<Scalars['Boolean']['input']>;
  sortField?: InputMaybe<HoldingSortFields>;
};


/** Organization or vault level summary of holdings */
export type PortfolioholdingsBreakdownArgs = {
  sortField?: InputMaybe<HoldingSortFields>;
};


/** Organization or vault level summary of holdings */
export type PortfoliononZeroHoldingsArgs = {
  filterByAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  includeOnlyFiatTrading?: InputMaybe<Scalars['Boolean']['input']>;
  isFiat?: InputMaybe<Scalars['Boolean']['input']>;
};

/** PresentationRule represents the most basic rule that other rules must extend. */
export type PresentationRule = {
  /** Ignore this field */
  ignoreMe: Maybe<Scalars['Boolean']['output']>;
};

/** PresentationRuleJustOnceAny indicates that the step should be skipped by the UI if the UI local state indicates that the current user has previously completed any staking form containing this rule with the same flagIdentifier. */
export type PresentationRuleJustOnce = PresentationRule & {
  __typename: 'PresentationRuleJustOnce';
  /** FlagIdentifier is the identifier used by the consumer to remember if this step has been seen before in a completed form. */
  flagIdentifier: Maybe<Scalars['String']['output']>;
  /** Ignore this field */
  ignoreMe: Maybe<Scalars['Boolean']['output']>;
};

export type PriceReferenceData = Node & {
  __typename: 'PriceReferenceData';
  assetSpecID: Maybe<AssetSpec>;
  createdAt: Scalars['Timestamp']['output'];
  exposures: Maybe<Array<Exposure>>;
  id: Scalars['ID']['output'];
  marketdataProviderID: Maybe<DataSource>;
  price: Scalars['Decimal']['output'];
  updatedAt: Maybe<Scalars['Timestamp']['output']>;
  version: Scalars['Int']['output'];
};

/** Ordering options for PriceReferenceData connections */
export type PriceReferenceDataOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order PriceReferenceDataSlice. */
  field: PriceReferenceDataOrderField;
};

/** Properties by which PriceReferenceData connections can be ordered. */
export enum PriceReferenceDataOrderField {
  CREATED_AT = 'CREATED_AT',
  PRICE = 'PRICE',
  VERSION = 'VERSION'
}

/**
 * PriceReferenceDataWhereInput is used for filtering PriceReferenceData objects.
 * Input was generated by ent.
 */
export type PriceReferenceDataWhereInput = {
  and?: InputMaybe<Array<PriceReferenceDataWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  /** asset_spec_id_fk edge predicates */
  hasAssetSpecID?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetSpecIDWith?: InputMaybe<Array<AssetSpecWhereInput>>;
  /** exposures edge predicates */
  hasExposures?: InputMaybe<Scalars['Boolean']['input']>;
  hasExposuresWith?: InputMaybe<Array<ExposureWhereInput>>;
  /** marketdata_provider_id_fk edge predicates */
  hasMarketdataProviderID?: InputMaybe<Scalars['Boolean']['input']>;
  hasMarketdataProviderIDWith?: InputMaybe<Array<DataSourceWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<PriceReferenceDataWhereInput>;
  or?: InputMaybe<Array<PriceReferenceDataWhereInput>>;
  /** price field predicates */
  price?: InputMaybe<Scalars['Decimal']['input']>;
  priceGT?: InputMaybe<Scalars['Decimal']['input']>;
  priceGTE?: InputMaybe<Scalars['Decimal']['input']>;
  priceIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  priceLT?: InputMaybe<Scalars['Decimal']['input']>;
  priceLTE?: InputMaybe<Scalars['Decimal']['input']>;
  priceNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  priceNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** version field predicates */
  version?: InputMaybe<Scalars['Int']['input']>;
  versionGT?: InputMaybe<Scalars['Int']['input']>;
  versionGTE?: InputMaybe<Scalars['Int']['input']>;
  versionIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  versionLT?: InputMaybe<Scalars['Int']['input']>;
  versionLTE?: InputMaybe<Scalars['Int']['input']>;
  versionNEQ?: InputMaybe<Scalars['Int']['input']>;
  versionNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** ProductUpdates contain information regarding new features and important changes to the product. */
export type ProductUpdates = {
  __typename: 'ProductUpdates';
  /** feedURL is the URL address for the feed. */
  feedURL: Scalars['String']['output'];
  /** items is a list of posts in the feed */
  items: Array<ProductUpdatesItem>;
  /** unreadCount is the number of posts in the feed marked as unread for the current user */
  unreadCount: Maybe<Scalars['Int']['output']>;
};

/** ProductUpdatesCategory represents the level of importance a product update can be identified with for notifying the user */
export enum ProductUpdatesCategory {
  /** IMPORTANT marks items with a high level of importance */
  IMPORTANT = 'IMPORTANT'
}

/** ProductUpdatesItem contains information regarding a change to the product. */
export type ProductUpdatesItem = {
  __typename: 'ProductUpdatesItem';
  /** content of the product update in plain text format */
  content: Scalars['String']['output'];
  /** content of the product update in HTML format */
  contentHTML: Scalars['String']['output'];
  /** id is used to differentiate between multiple items */
  id: Scalars['String']['output'];
  /** list of image URLs embedded in the post */
  images: Array<Scalars['String']['output']>;
  /** optional URL to redirect users for additional information */
  link: Maybe<Scalars['String']['output']>;
  /** optional text for rendering the redirect URL */
  linkText: Maybe<Scalars['String']['output']>;
  /** URL to the standalone post */
  postURL: Maybe<Scalars['String']['output']>;
  /** human readable title for the product update */
  title: Scalars['String']['output'];
};

/** ProgramSignerData holds the necessary details of a program signer */
export type ProgramSignerData = {
  __typename: 'ProgramSignerData';
  /** Email of the program signer */
  email: Maybe<Scalars['String']['output']>;
  /** Name of the entity the program signer is associated to */
  entityName: Maybe<Scalars['String']['output']>;
  /** First name of the program signer */
  firstName: Maybe<Scalars['String']['output']>;
  /** Last name of the program signer */
  lastName: Maybe<Scalars['String']['output']>;
  /** Name of the parent entity if it exists */
  parentEntityName: Maybe<Scalars['String']['output']>;
};

/** PromoterType is the different types of actors that can cause a trade state change */
export enum PromoterType {
  /** The state change was promoted by an API user */
  API_USER = 'API_USER',
  /** The state change was promoted by the customer experience team */
  RISK_REVIEWER = 'RISK_REVIEWER',
  /** The state change was promoted by the automated trading system */
  TRADING_SYSTEM = 'TRADING_SYSTEM',
  /** The state change promoter is unknown */
  UNKNOWN = 'UNKNOWN',
  /** The state change was promoted by the end user */
  USER = 'USER'
}

/** ProposalStage represents where the proposal is in its life cycle */
export enum ProposalStage {
  /** APPROVAL is when the designated approvers can approve the proposal */
  APPROVAL = 'APPROVAL',
  /** EXECUTION is when a proposal has been approved and is ready for execution */
  EXECUTION = 'EXECUTION',
  /** EXPIRATION is when a proposal has timed out without enough support */
  EXPIRATION = 'EXPIRATION',
  /** NONE is when nothing has happened */
  NONE = 'NONE',
  /** QUEUED is when a proposal is on the queue and waiting to be activated */
  QUEUED = 'QUEUED',
  /** REFERENDUM is when a proposal is ready to be voted on by the general community */
  REFERENDUM = 'REFERENDUM'
}

/** ProposalVoteStatus represents the state of a proposal, and whether it's voted on, in progress, or not */
export enum ProposalVoteStatus {
  /** COMPLETE is the state when the proposal has been voted on */
  COMPLETE = 'COMPLETE',
  /** NONE is the state when the proposal has not been voted on */
  NONE = 'NONE',
  /** PENDING is the state when the proposal is in progress but has not complete (because of an operation in progress) */
  PENDING = 'PENDING'
}

/** ProposedTrustedDestination is the type that represents a proposed trusted destination */
export type ProposedTrustedDestination = {
  __typename: 'ProposedTrustedDestination';
  /** amlSubmission returns the recorded answers for the AML questionnaire */
  amlSubmission: Maybe<InputSubmission>;
  /** Further information regarding this asset type */
  assetTypeInfo: AssetTypeInfo;
  /** Description of the trusted address */
  description: Scalars['String']['output'];
  /** The address */
  destination: Scalars['String']['output'];
  /**
   * isAtNetworkLevel denotes whether the trusted destination is used as network level or asset level
   * true: network level, false: asset level
   */
  isAtNetworkLevel: Scalars['Boolean']['output'];
  /** Name of the trusted address */
  name: Scalars['String']['output'];
  /** ExtraParams for for trusted destinations, depending on the asset being used */
  trustedDestinationExtraParams: Maybe<TrustedDestinationExtraParams>;
};

/** ProposedTrustedSmartContract is the type that represents a proposed trusted smart contract */
export type ProposedTrustedSmartContract = {
  __typename: 'ProposedTrustedSmartContract';
  /** Description of the trusted smart contract. */
  description: Scalars['String']['output'];
  /** Name of the trusted smart contract. */
  name: Scalars['String']['output'];
  /** The network ID the smart contract is for. */
  networkID: Scalars['ID']['output'];
  /** The network info corresponding to the network ID. Available only if the ID is valid. */
  networkInfo: Maybe<NetworkInfo>;
  /** The canonical address. */
  smartContractAddress: Scalars['String']['output'];
  /** Unique IDs of the vaults in which the smart contract is considered "trusted". */
  vaultIDs: Array<Scalars['String']['output']>;
  /**
   * The vaults in which the smart contract is considered "trusted".
   * The length may be smaller than vaultIDs: for all Unique IDs, only those with a valid vault are included in this list.
   */
  vaults: Array<Vault>;
};

/** The query type, represents all of the entry points into our object graph */
export type Query = {
  __typename: 'Query';
  accountConstraintGroups: AccountConstraintGroupConnection;
  accountConstraintStates: AccountConstraintStateConnection;
  accountConstraints: AccountConstraintConnection;
  accountsV2: AccountV2Connection;
  /** Return a CSV of the daily balance by address and vault of the org for each of the days in the range */
  addressLevelBalanceSheetsByVaultCSV: Scalars['String']['output'];
  /**
   * Triggers an async job to generate CSV of the daily balance by address and vault of the user's org for each of the days in the range.
   * If a Statement Generation job had already been triggered before (regardless of status),
   * - if genNewVersion=true, will trigger a job (to generate a new version of the report),
   * - if genNewVersion=false, will return previous job's info.
   */
  addressLevelBalanceSheetsByVaultCSVAsync: Maybe<StatementGenResponse>;
  affiliateAnchorageRelationships: AffiliateAnchorageRelationshipConnection;
  /** Affiliate Application Status */
  affiliateApplicationStatusCSV: Scalars['String']['output'];
  affiliatesV2: AffiliateV2Connection;
  /** returns all statements for an org */
  allStatements: Array<Statement>;
  /** Return all trusted smart contracts (across all organizations). */
  allTrustedSmartContracts: Array<TrustedSmartContract>;
  /** amlQuestionnaireAnswers returns a list of answers from a previous AML record to prefill a questionnaire */
  amlQuestionnaireAnswers: AMLPrefillAnswers;
  /**
   * amlQuestions returns a list of questions required for anti-money laundering for an associated
   * address with optional answers. This is used when creating a withdrawal or trusted destination.
   */
  amlQuestions: Array<AMLQuestion>;
  anchorageLegalEntities: AnchorageLegalEntityConnection;
  /** Returns a REST API key */
  apiKey: APIKey;
  /** Returns the list of all REST API keys, with optional filters */
  apiKeys: Array<APIKey>;
  /** apiMetrics returns information about the API */
  apiMetrics: APIMetrics;
  /** Checks if a email sending workflow is already running for the given organization */
  areStatementsBeingSent: Scalars['Boolean']['output'];
  /** Return a CSV of addresses associated with the organization */
  assetAddressesCSV: Scalars['String']['output'];
  /** Information regarding this asset type */
  assetTypeInfo: AssetTypeInfo;
  /** List of information for all supported asset types */
  assetTypeInfos: Array<AssetTypeInfo>;
  /** Return a CSV of the daily balance by asset and vault of the org for each of the days in the range */
  balanceSheetsByVaultCSV: Scalars['String']['output'];
  /**
   * Triggers an async job to generate CSV of the daily balance by asset and vault of the user's org for each of the days in the range.
   * If a Statement Generation job had already been triggered before (regardless of status),
   * - if genNewVersion=true, will trigger a job (to generate a new version of the report),
   * - if genNewVersion=false, will return previous job's info.
   */
  balanceSheetsByVaultCSVAsync: Maybe<StatementGenResponse>;
  /** Return a CSV of the daily balance by asset of the org for each of the days in the range */
  balanceSheetsCSV: Scalars['String']['output'];
  /**
   * Triggers an async job to generate CSV of the daily balance by asset of the user's org for each of the days in the range.
   * If a Statement Generation job had already been triggered before (regardless of status),
   * - if genNewVersion=true, will trigger a job (to generate a new version of the report),
   * - if genNewVersion=false, will return previous job's info.
   */
  balanceSheetsCSVAsync: Maybe<StatementGenResponse>;
  /** Get list of trading assets to supply base, quote, counter currencies */
  blockPricerTradingAssets: Array<BlockPricerTradingAsset>;
  /** Get list of whitelisted trading entity names */
  blockPricerTradingEntities: Array<Scalars['String']['output']>;
  /** Get a list of liquidity venues */
  blockPricerTradingLiquidityVenues: Array<LiquidityVenue>;
  /** Used only to bust the apollo-client cache */
  cacheBuster: Scalars['Boolean']['output'];
  /** Returns if an user has permission to approve an operation */
  canApproveOperation: Scalars['Boolean']['output'];
  /** Checks if user can edit CRR Risk parameters */
  canChangeCRRRiskConfigs: Scalars['Boolean']['output'];
  /** Checks if user can access CRR history data and view */
  canViewCRRHistory: Scalars['Boolean']['output'];
  /** Checks if user can access Close Account button */
  canViewCloseAccount: Scalars['Boolean']['output'];
  /** Checks if user can access Compliance Alerts information */
  canViewComplianceAlerts: Scalars['Boolean']['output'];
  /** Checks if user can access the Freeze withdrawal button */
  canViewFreezeWithdrawal: Scalars['Boolean']['output'];
  /** Checks if user can access KYC Alerts information */
  canViewKYCAlerts: Scalars['Boolean']['output'];
  /** Returns the affiliate types available for institutional client */
  clientAffiliateTypes: Maybe<Array<ClientAffiliateType>>;
  /** Retrieve details about a client dashboard login request before it is claimed */
  clientDashboardLoginRequestInfo: ClientDashboardLoginRequestInfo;
  /** Get lending entities for client dashboard using Auth */
  clientLendingEntities: Array<Maybe<LendingEntity>>;
  /** Get lending facilities for client dashboard */
  clientLendingFacilities: Array<Maybe<LendingFacility>>;
  /** Client Orders */
  clientOrders: ClientOrderConnection;
  /** Returns list of trades for the client dashboard */
  clientTradesView: TradesView;
  /** Returns a list of collateral accounts within collateral manager */
  collateralAccounts: CollateralAccountConnection;
  /** Returns a list of collateral agreements within collateral manager */
  collateralAgreements: CollateralAgreementConnection;
  /** Returns a list of asset specifications within collateral manager */
  collateralAssetSpecs: AssetSpecConnection;
  /** Returns a list of ccr valuations within collateral manager */
  collateralCCRValuations: CCRValuationsConnection;
  /** Returns a list of collateral calls within collateral manager */
  collateralCalls: CollateralCallConnection;
  /** Returns a list of collateral datasources within collateral manager */
  collateralDatasources: DataSourceConnection;
  /** Returns a list of collateral exposures within collateral manager */
  collateralExposures: ExposureConnection;
  /** Returns a list of collateral fee items within collateral manager */
  collateralFees: CollateralFeeConnection;
  /** Returns a list of collateral inventories within collateral manager */
  collateralInventories: CollateralInventoryConnection;
  /** Returns a list of collateral parties within collateral manager */
  collateralParties: PartyConnection;
  /** Returns a list of collateral rehypo ledger items within collateral manager */
  collateralRehypoLedgers: CollateralRehypoLedgerConnection;
  /** Shows the comments performed by risk reviewers */
  comments: Array<RiskComment>;
  /** Checks if user can edit transaction monitoring system configs */
  complianceCanUpdateTMSConfigs: Scalars['Boolean']['output'];
  /** Gets compliance documents */
  complianceDocuments: ComplianceDocumentsConnection;
  /** Returns the transaction monitoring system configs */
  complianceTMSConfigs: TMSConfigsConnection;
  /** Returns the transaction monitoring system configs metadata */
  complianceTMSConfigsMetadata: Array<TMSRuleGroupMetadata>;
  /** currentUser returns information about the currently logged in user */
  currentRiskUser: RiskDashboardUser;
  /** currentUser returns information about the currently logged in user */
  currentUser: User;
  /** custodyAUC returns the summary of assets under custory held in Anchorage */
  custodyAUC: CustodyAUC;
  depositAttributionSpamThresholds: DepositAttributionSpamThresholdConnection;
  /** List of the deposit attributions associated with the organization */
  depositAttributions: DepositAttributionConnection;
  /** DepositScreeningLatestFiltered returns paginated latest screenings */
  depositScreeningLatestFiltered: DepositScreeningConnection;
  /** depositSource returns history and vasp info for give opID */
  depositSource: DepositSource;
  /**
   * Gets latest disclosures. Each item is a 1-to-1 mapping to a markdown file in
   * source/go/lib/disclosures/documents.
   *
   * This will only fetch the latest version of each document. So if Terms Of Use
   * has 3 versions, versions 1 and 2 won't be in the list of responses
   */
  disclosures: Array<Disclosure>;
  /**
   * Gets latest-version disclosure documents applicable to the given UI screen.
   * This will only fetch the latest version of each document.
   */
  disclosuresByScreen: Array<Disclosure>;
  /** Retrieves the base64 encoded file content of a specified report file. */
  downloadReport: Scalars['String']['output'];
  /** enabledFeatures returns an array of enabled features for this user */
  enabledFeatures: Array<Feature>;
  /** Enrollment specific information */
  enrollment: Maybe<Enrollment>;
  /** Export Wallet key PDF according to preference. */
  exportWalletKey: WalletKeyExportResult;
  /** Filter external account balances */
  externalAccountBalances: ExternalAccountBalanceConnection;
  /** Filter external account locations */
  externalAccountLocations: ExternalAccountLocationConnection;
  /** Filter organization balances on external accounts */
  externalAccountOrgBalances: ExternalAccountOrgBalanceConnection;
  /** Get list of valid external account transaction types */
  externalAccountTransactionTypes: Array<ExternalAccountTransactionType>;
  /** Filter external account transactions */
  externalAccountTransactions: ExternalAccountTransactionConnection;
  /** Filter external accounts */
  externalAccounts: ExternalAccountConnection;
  /** Transactions for lending */
  facilityTransactions: LendingFacilityTransactionConnection;
  /** Get all lending fee reimbursements */
  feeReimbursements: FeeReimbursementConnection;
  /** Fetches a Signed Withdrawal Authorization for a Program Customer within an RIA */
  fetchSignedDocusignForWithdrawal: DocusignSignedDocument;
  /** filteredOrganizations is a paginated, filterable list of all organizations that exist */
  filteredOrganizations: OrganizationsDB;
  /** Produces the filename for an account statement for a given client account */
  generateAccountStatementFilename: Scalars['String']['output'];
  /** Generates an account statement PDF */
  generateAccountStatementPdf: AccountStatementPdf;
  /** Return all addresses that have been generated for each vault for all orgs */
  generateAllVaultAddressesReport: Scalars['String']['output'];
  /** Generates white label OTP code */
  generateWhiteLabelOTP: Scalars['String']['output'];
  /** Returns the list of all REST API keys, with optional filters */
  getApiKeys: Array<APIKey>;
  /** getAssetTypesEnabledForOrg get the enabled asset types for an org */
  getAssetTypesEnabledForOrg: Array<Scalars['AssetTypeID']['output']>;
  /** List of enablement information for all supported asset types */
  getAssetsEnablementInfo: Array<AssetEnablementInfo>;
  /** Gets CRR history for a given affiliate */
  getCRRHistory: CRRHistoryConnection;
  /** Gets CRR history CSV for a given affiliate */
  getCRRHistoryCSV: Scalars['String']['output'];
  /** Get current CRR parameters in the CRR configuration */
  getCRRRiskConfigs: CRRRiskConfigs;
  /** Gets CRR section values history for a given affiliate */
  getCRRSectionHistory: Array<CRRSectionHistoryPoint>;
  /** getDAppInfo gets the DApp, and org-specific info about the DApp: last connection info, vaults enabled for the DApp. */
  getDAppInfo: DAppInfo;
  /** getDAppInfos gets a set, each containing a DApp, and org-specific info about the DApp: last connection info, vaults allowed for the DApp. */
  getDAppInfos: DAppInfoConnection;
  /** Gets the total number of deposit attributions */
  getDepositAttributionsCount: Scalars['Int']['output'];
  /**
   * Return a CSV of the asset prices at a particular hour for given asset types, or if no asset types provided then for all asset types
   * Used for one specific client:
   * https://www.notion.so/anchorage/Asset-Prices-For-Bain-Capital-1f310566f0904097a3f99843014ed94c
   */
  getHourlyAssetTypePrices: Scalars['String']['output'];
  /** Gets the result of the Persons data quality checks of a given run. */
  getPersonDataQualityChecksResult: PersonDataQualityChecksResult;
  /** Fetches the WalletID of the Commingled Funds of the Subaccounts */
  getSubaccountOrganizationCommingledWallet: Wallet;
  /** Returns an organization's list of email recipients to be notified for trading-related communications. */
  getTradingEmailRecipients: Array<Scalars['String']['output']>;
  /** Gets Transaction count by destination country for a given organization */
  getTransactionByDestCountry: Array<AggregatedByCountryTransaction>;
  /** Gets Transaction Volume history for a given organization */
  getTransactionVolumeHistory: Array<AggregatedTransactionVolume>;
  /** Gets the trusted sources */
  getTrustedSources: TrustedSourceConnection;
  /** Gets personal identifiable information for a given user */
  getUserPII: Maybe<Array<PIIValue>>;
  /**
   * Get all active connections for the User.
   * @deprecated Use getWalletConnectSessionsWithPagination instead
   */
  getWalletConnectSessions: Array<WalletConnectSession>;
  /**
   * Get all active connections for the Org.
   * @deprecated Use getWalletConnectSessionsForOrgWithPagination instead
   */
  getWalletConnectSessionsForOrg: Array<WalletConnectSession>;
  /** Get all active connections for the Org. */
  getWalletConnectSessionsForOrgWithPagination: WalletConnectSessionConnection;
  /** Get WalletConnect sessions (aka connections). */
  getWalletConnectSessionsWithPagination: WalletConnectSessionConnection;
  /**
   * Get the Info for a wallet for the spendable ID
   * this is used for unsupported-by-ADB tokens represented by SpendableID
   */
  getWalletSpendableIDInfo: WalletSpendableIDInfo;
  /** Returns the global trading credit usage */
  globalTradingCreditUsed: Maybe<Scalars['String']['output']>;
  /** Returns the global trading credit limit */
  globalTradingLimit: GlobalTradingLimit;
  hasManualDepositAttributionAccess: Scalars['Boolean']['output'];
  /** Placeholder */
  helloWorld: Scalars['String']['output'];
  iamPermissions: IAMPermissionConnection;
  /** Individual Application Status */
  individualApplicationStatusCSV: Scalars['String']['output'];
  /** Institution Application Status */
  institutionApplicationStatusCSV: Scalars['String']['output'];
  /** Returns a specific institutional affiliate */
  institutionalAffiliate: InstitutionalAffiliate;
  /** Returns all institutional affiliates */
  institutionalAffiliates: InstitutionalAffiliateConnection;
  /** Return a CSV with all institutional affiliates */
  institutionalAffiliatesCSV: Scalars['String']['output'];
  /** Base resolver for all institutional audit csv exports */
  institutionalAuditCSVExports: InstitutionalAuditCSVExports;
  /** Returns a specific institutional client */
  institutionalClient: InstitutionalClient;
  /** Returns all institutional clients */
  institutionalClients: Array<InstitutionalClient>;
  /** Base resolver to search for institutional KYC Information. */
  institutionalKYCInformation: InstitutionalKYCInformation;
  /** Get all interest payments */
  interestPayments: InterestPaymentsConnection;
  kyc: Maybe<KYC>;
  /** Base resolver for all lending csv exports */
  lendingCSVExport: LendingCSVExport;
  /** Get all lending entities */
  lendingEntities: LendingEntityConnection;
  /** Get lending facilities */
  lendingFacilities: LendingFacilityConnection;
  /** Get facility by ID */
  lendingFacility: LendingFacility;
  /** Get all lending facility memberships */
  lendingFacilityMemberships: Maybe<LendingFacilityMembershipConnection>;
  /** Get lending invoices */
  lendingInvoices: LendingInvoiceConnection;
  /** Get lending job history records */
  lendingJobHistoryRecords: LendingJobHistoryRecordConnection;
  /** Get lending scheduled job records */
  lendingScheduledJobRecords: LendingScheduledJobRecordConnection;
  /** Get all transfer requests */
  lendingTransferRequests: TransferRequestConnection;
  /** Get all loans */
  loans: LoanConnection;
  /** marketingWidgets query returns all the marketing widgets for a given display page */
  marketingWidgets: Array<MarketingWidget>;
  /** List of information for all native supported asset types */
  nativeAssetTypeInfos: Array<AssetTypeInfo>;
  /** Returns all degraded or non-degraded network statuses */
  networkStatuses: Array<NetworkStatus>;
  /** operation gets an operation by its id */
  operation: Maybe<Operation>;
  /** Return a list of operation details */
  operationDetails: Array<Maybe<OperationDetails>>;
  /**
   * Get the list of operations in this organization
   * first and after implement the connections spec
   */
  operations: OperationConnection;
  /**
   * organization by id and optional generation.
   * Id is the organization public key ID, and generation is organization generation
   */
  organization: Organization;
  /** Filter external account balances */
  organizationExternalAccountsBalances: OrganizationExternalAccountBalanceConnection;
  organizationForPurposeKeyID: Scalars['ID']['output'];
  organizationModules: OrganizationModuleConnection;
  /**
   * Notes for the given organization, if any exist. Organization notes are a
   * single freetext field per organization.
   */
  organizationNotes: Maybe<OrganizationNotes>;
  /** organizationStatements returns all statements for an organization */
  organizationStatements: Array<Statement>;
  /** organizations is the list of organizations that exist */
  organizations: Array<Organization>;
  /** Return a csv report of all organizations along with metadata */
  organizationsReport: Scalars['String']['output'];
  /** TODO: add a description */
  outstandingTradingPositions: Array<TradingPosition>;
  /** Returns the list of outstanding trading positions for the specified organization */
  outstandingTradingPositionsRisk: Array<TradingPosition>;
  /** persons is the list of all persons Anchorage knows about */
  persons: PersonConnection;
  /**
   * Returns a paginated list of all policies (FragmentBindings) configurable
   * by an OperationAction pair, for a resource.
   * Only a subset of actions allows manipulating affected policies (e.g. VAULT_POLICY_CHANGE)
   * If the action creates a new resource, a template of the affected policies is returned.
   * First element represents the default policy of the resource.
   *
   * In the first iteration pagination is not implemented.
   */
  policyFragmentsAffected: PolicyFragmentConnection;
  /**
   * Returns a paginated list of all the enforced policies (FragmentBindings), for a resource.
   * Policies may be filtered by a Operation action pair (actions and subactions).
   * SubActions are used to distinguish between OneOffs with the same action.
   * @deprecated Use iam.permissions
   */
  policyFragmentsEnforced: PolicyFragmentConnection;
  /**
   * Portfolio returns an organization-level summary of current holdings
   * Set the optional filterAssetTypeIDs to restrict the holdings list (amounts) to just these AssetTypeIDs.
   * Set debug to true to enable more logging.
   * @deprecated Use Organization.portfolio
   */
  portfolio: Portfolio;
  /** Get info on product updates */
  productUpdates: ProductUpdates;
  /** questionnaireByID returns the questionnaire for a specific ID provided */
  questionnaireByID: Questionnaire;
  /** questionnaireByName returns the active questionnaire for the name provided */
  questionnaireByName: Questionnaire;
  /** Get the available values for a specific filter field in a report type. */
  reportFilterAvailableValues: Array<Maybe<ReportFilterConfigValue>>;
  reportSchedules: ReportScheduleConnection;
  /**
   * Get the configuration (available filters) for a specific report type.
   * This can be called by staff users who must provide an organization ID.
   */
  reportTypeConfig: ReportTypeConfig;
  /**
   * Get a list of report types that are available for generation.
   * This can be called by staff users who must provide an organization ID.
   */
  reportTypes: Array<Maybe<ReportType>>;
  reports: ReportConnection;
  /** Returns true if the logged in organization has already requested a trading activation */
  requestedActivateTrading: Scalars['Boolean']['output'];
  /** Returns all the markets present on all trades */
  retrieveTradeMarkets: Array<Scalars['String']['output']>;
  /** Returns RIA fee configurations for the given filters */
  riaFeeConfigurations: FeeConfigConnection;
  /** Shows detailed information on risk requirements for this operation */
  riskReviewRequirements: Maybe<RiskReviewRequirements>;
  /** Returns list of trades for the risk dashboard */
  riskTradesView: TradesView;
  /** Returns the list of approval groups the current risk user is in */
  riskUserApprovalGroups: Array<RiskApprovalGroupType>;
  /** Returns the list of groups the current risk user is in */
  riskUserGroups: Array<Scalars['String']['output']>;
  /** Returns the list of settlements */
  settlementViewClient: SettlementView;
  /** Returns the list of settlements */
  settlementViewRisk: SettlementView;
  /**
   * Gets a signed URL to download documents related
   * to compliance alerts
   */
  signedURLToDownloadDocsForComplianceAlerts: Scalars['String']['output'];
  /**
   * Returns the list of assets that can be used in settlements between two given participants.
   * For an asset to be usable, the custodians for the two participants must support the asset and the transfer API must support the asset. More restrictions can be added in the future.
   */
  snAssets: Array<AssetTypeInfo>;
  /** Get a settlement network participant by ID. Anyone with knowledge of the ID can learn at least the participant's name and custodian. */
  snParticipantByID: SNParticipant;
  /**
   * Sorted and paginated list of participants.
   * Sorted from most recent to least recent by creation time by default.
   */
  snParticipants: SNParticipantConnection;
  /** Get a settlement network settlement by ID. */
  snSettlementByID: SNTwoWaySettlement;
  /**
   * Filtered, sorted and paginated list of settlements.
   * Sorted from most recent to least recent by creation time by default.
   */
  snSettlements: SNTwoWaySettlementConnection;
  /**
   * Filtered, sorted and paginated list of counterparties.
   * Sorted from most recent to least recent by creation time by default.
   */
  snTrustedCounterparties: SNCounterpartyConnection;
  /** Returns all completed transactions between two nanosecond timestamps in csv format */
  socReportCSV: Scalars['String']['output'];
  /** Returns the staged organization identified by the ID if it exists */
  stagedOrganization: Maybe<StagedOrganization>;
  /** Returns consolidated information standards like countries, states or others */
  standards: Standards;
  /** Get FIAT Standing Instructions */
  standingInstructions: Array<StandingInstruction>;
  /** Returns the status of lending request of the organization */
  statusForLendingAccessRequest: LendingAccessRequestStatus;
  /** All available keys for the vault */
  supportedAPIVaultRoles: Array<RoleAction>;
  taxTenants: Array<TaxTenant>;
  /** Returns the csv list of trades for the client dashboard */
  tradesClientViewCsv: Scalars['String']['output'];
  /** Returns the list of organization trading aliases in CSV format */
  tradesRiskOrgAliasesCsv: Scalars['String']['output'];
  /** Returns list of trades for the risk dashboard in CSV format */
  tradesRiskViewCsv: Scalars['String']['output'];
  /** Lists an org trading accounts */
  tradingAccounts: Array<Maybe<TradingAccount>>;
  /** Get the configuration for advanced trading */
  tradingAdvancedTradingConfig: AdvancedTradingConfig;
  /** Get all trading asset */
  tradingAssets: Array<TradingAsset>;
  /** Returns a list of payment flows */
  tradingClientPaymentFlows: ClientPaymentFlowConnection;
  /** Returns list of client trades */
  tradingClientTrades: TradesView;
  /** Returns a CSV with all changes to compliance related trading settings */
  tradingComplianceChangesCsv: Scalars['String']['output'];
  /** Get list of counterparties */
  tradingCounterparties: CounterpartyConnection;
  /** Returns a list of dealer settlements */
  tradingDealerSettlements: DealerSettlementConnection;
  /** Return all dealer trades, both automatic dealer trades from execution reports and also dealer manual trades */
  tradingDealerTrades: DealerTradesConnection;
  /** Return all dealer trades CSV, both automatic dealer trades from execution reports and also dealer manual trades */
  tradingDealerTradesCSV: Scalars['String']['output'];
  /** Get all trading external assets */
  tradingExternalAssets: Array<TradingExternalAsset>;
  /** Get client settlements */
  tradingGetClientSettlements: ClientSettlementsConnection;
  /** Returns trade execution reports. */
  tradingGetExecutionReports: TradeExecutionReportConnection;
  /** Get order execution reports */
  tradingGetOrderExecutionReports: OrderExecutionReportConnection;
  /**
   * TODO: add filters
   * Fetch the unsettled client balances
   */
  tradingGetUnsettledClientBalances: UnsettledClientBalancesConnection;
  /**
   * Get wallet settlement instructions
   * These instructions are used to decide where the RIA automatic settlements
   * should be send from and to in the perspective of the Anchorage Client
   * Settlement FBO Org.
   * The settlement instructions for the RIA organizations are stored in the ledger
   */
  tradingGetWalletSettlementInstructions: Array<WalletSettlementInstruction>;
  /** Get the default settlement addresses for all the assets */
  tradingLatestDefaultSettlementInstructions: DefaultSettlementInstructions;
  /** Get order execution reports for client initiated trades */
  tradingOrderExecReportsForClientInitiatedTrades: OrderExecutionReportConnection;
  /** Returns a list of payment flows */
  tradingPaymentFlows: PaymentFlowConnection;
  /** Get trading permissions */
  tradingPermissions: Array<TradingPermission>;
  /** Returns the outstanding positions for the specified list of trades while ensuring they all belong to the specified organization */
  tradingSettlementPreview: TradingSettlementPreview;
  /** Get trading symbols. Setting isEnabled to true and isApproved to false is an invalid combination of filters for this endpoint. */
  tradingSymbols: Array<TradingSymbol>;
  /** Fetch a list of trade settlement transactions */
  tradingTradeSettlementTransactions: TradeSettlementTransactionConnection;
  /** Get unsettled client balances summary */
  tradingUnsettledClientBalanceSummary: TradingUnsettledClientBalanceSummary;
  /** Returns a list of unsettled dealer balances */
  tradingUnsettledDealerBalances: UnsettledDealerBalancesConnection;
  /** Get whitelabel accounts */
  tradingWhitelabelAccounts: Array<TradingWhitelabelAccount>;
  /** Get whitelabel users */
  tradingWhitelabelUsers: Array<TradingWhitelabelUser>;
  /** Return a CSV of transactions with the given filters */
  transactionHistoryCSV: Scalars['String']['output'];
  /**
   * Triggers an async job to generate CSV of transactions matching the given filters.
   * If a Statement Generation job had already been triggered before (regardless of status),
   * - if genNewVersion=true, will trigger a job (to generate a new version of the report),
   * - if genNewVersion=false, will return previous job's info.
   */
  transactionHistoryCSVAsync: Maybe<StatementGenResponse>;
  /** Lists the client transfer access requests */
  transferAccessRequests: Maybe<Array<TransferAccessRequest>>;
  /** Get travel rule information */
  travelRuleInformation: TravelRuleInformation;
  /** Return a csv report of all trusted destinations */
  trustedDestinationsReport: Scalars['String']['output'];
  /** Return manual trade if not settled */
  unSettledManualTrade: Maybe<ManualTradeRequestParams>;
  /** Returns detailed information about institutional users in csv format */
  userReportCSV: Scalars['String']['output'];
  /** Returns the settings for a user. */
  userSettings: UserSettings;
  /** usersV2 is the list of all users Anchorage knows about */
  usersV2: UserV2Connection;
  /** vault by sub ID for this organization */
  vault: Vault;
  /** List of vaults associated with this organization */
  vaults: Array<Vault>;
  /** List of vaults associated with this organization, with pagination. */
  vaultsWithPagination: VaultConnection;
  /** Gets info that could be used to filter on wallet lists. */
  walletFilterData: Maybe<WalletFilterData>;
  /** Get WalletGroup by ID */
  walletGroup: WalletGroup;
  /** Get all wallet groups in this vault */
  walletGroups: Maybe<Array<WalletGroup>>;
  /** Get Wallet key PDF export preference */
  walletKeyExportPreference: WalletKeyExportPreference;
  /**
   * Get all wallets within all vaults of this organization.
   * This API will soon replace "wallets".
   *
   * NOTE: these quantity filters will be silently ignored iff the org is Porto:
   * - filterMinQuantity, filterMaxQuantity, quantityRangeIsInclusive.
   * - filterByNonZeroBalance.
   */
  wallets: WalletConnection;
  /**
   * Get all wallets within all vaults of this organization
   * This API will soon be replaced by "wallets".
   *
   * NOTE: these quantity filters will be silently ignored iff the org is Porto:
   * - filterMinQuantity, filterMaxQuantity, quantityRangeIsInclusive.
   * - filterByNonZeroBalance.
   */
  wallets1: WalletConnection;
  webhookAllEventTypes: Maybe<Array<WebhookEventType>>;
  webhookEndpoint: WebhookEndpoint;
  webhookEndpoints: WebhookEndpointConnection;
  webhookEventTypes: WebhookEventTypeConnection;
  webhookValidationKey: WebhookValidationKey;
  /** whatIsMyIP is useful for debugging our ip detection through middlewares */
  whatIsMyIP: Scalars['String']['output'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryaccountConstraintGroupsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccountConstraintGroupWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryaccountConstraintStatesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccountConstraintStateWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryaccountConstraintsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccountConstraintWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryaccountsV2Args = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccountV2WhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryaddressLevelBalanceSheetsByVaultCSVArgs = {
  endDateDay: Scalars['Int']['input'];
  endDateMonth: Scalars['Int']['input'];
  endDateYear: Scalars['Int']['input'];
  startDateDay: Scalars['Int']['input'];
  startDateMonth: Scalars['Int']['input'];
  startDateYear: Scalars['Int']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryaddressLevelBalanceSheetsByVaultCSVAsyncArgs = {
  endDateInclusiveRFC3339: Scalars['String']['input'];
  genNewVersion?: Scalars['Boolean']['input'];
  startDateRFC3339: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryaffiliateAnchorageRelationshipsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AffiliateAnchorageRelationshipWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryaffiliatesV2Args = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AffiliateV2Order>;
  where?: InputMaybe<AffiliateV2WhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryallStatementsArgs = {
  excludeTypes?: InputMaybe<Array<StatementType>>;
  includeAllOrTheseTypes?: InputMaybe<Array<StatementType>>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryamlQuestionnaireAnswersArgs = {
  address: Scalars['String']['input'];
  assetTypeID: Scalars['AssetTypeID']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryamlQuestionsArgs = {
  address: Scalars['String']['input'];
  assetTypeID: Scalars['AssetTypeID']['input'];
  isWithdrawal?: InputMaybe<Scalars['Boolean']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryanchorageLegalEntitiesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AnchorageLegalEntityWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryapiKeyArgs = {
  keyID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryapiKeysArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  roleSubID?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryareStatementsBeingSentArgs = {
  organizationKeyID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryassetTypeInfoArgs = {
  assetType: Scalars['AssetTypeID']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryassetTypeInfosArgs = {
  isFiat?: InputMaybe<Scalars['Boolean']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerybalanceSheetsByVaultCSVArgs = {
  endDateDay: Scalars['Int']['input'];
  endDateMonth: Scalars['Int']['input'];
  endDateYear: Scalars['Int']['input'];
  startDateDay: Scalars['Int']['input'];
  startDateMonth: Scalars['Int']['input'];
  startDateYear: Scalars['Int']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerybalanceSheetsByVaultCSVAsyncArgs = {
  endDateInclusiveRFC3339: Scalars['String']['input'];
  genNewVersion?: Scalars['Boolean']['input'];
  startDateRFC3339: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerybalanceSheetsCSVArgs = {
  endDateDay: Scalars['Int']['input'];
  endDateMonth: Scalars['Int']['input'];
  endDateYear: Scalars['Int']['input'];
  startDateDay: Scalars['Int']['input'];
  startDateMonth: Scalars['Int']['input'];
  startDateYear: Scalars['Int']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerybalanceSheetsCSVAsyncArgs = {
  endDateInclusiveRFC3339: Scalars['String']['input'];
  genNewVersion?: Scalars['Boolean']['input'];
  startDateRFC3339: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerycacheBusterArgs = {
  uniqueID?: InputMaybe<Scalars['String']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerycanApproveOperationArgs = {
  operationID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryclientAffiliateTypesArgs = {
  clientID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryclientDashboardLoginRequestInfoArgs = {
  sessionID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryclientLendingEntitiesArgs = {
  filterLendingEntityID?: InputMaybe<Scalars['ID']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryclientLendingFacilitiesArgs = {
  filterFacilityStates?: InputMaybe<Array<InputMaybe<LendingFacilityState>>>;
  filterLendingFacilityIDs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  sortKey?: InputMaybe<LendingFacilitySortKey>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryclientOrdersArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ClientOrderOrder>;
  where?: InputMaybe<ClientOrderWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryclientTradesViewArgs = {
  afterTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  beforeTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  filterAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterSettlementIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterSide?: InputMaybe<TradeSide>;
  filterStatuses?: InputMaybe<Array<TradeStatus>>;
  filterSymbol?: InputMaybe<Scalars['String']['input']>;
  filterTradeID?: InputMaybe<Scalars['String']['input']>;
  filterUserKeyID?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerycollateralAccountsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CollateralAccountOrder>;
  where?: InputMaybe<CollateralAccountWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerycollateralAgreementsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CollateralAgreementOrder>;
  where?: InputMaybe<CollateralAgreementWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerycollateralAssetSpecsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AssetSpecOrder>;
  where?: InputMaybe<AssetSpecWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerycollateralCCRValuationsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CCRValuationsOrder>;
  where?: InputMaybe<CCRValuationsWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerycollateralCallsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CollateralCallOrder>;
  where?: InputMaybe<CollateralCallWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerycollateralDatasourcesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DataSourceOrder>;
  where?: InputMaybe<DataSourceWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerycollateralExposuresArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ExposureOrder>;
  where?: InputMaybe<ExposureWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerycollateralFeesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CollateralFeeOrder>;
  where?: InputMaybe<CollateralFeeWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerycollateralInventoriesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  filterBy?: InputMaybe<CollateralInventoryFilterByInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CollateralInventoryOrder>;
  where?: InputMaybe<CollateralInventoryWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerycollateralPartiesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PartyOrder>;
  where?: InputMaybe<PartyWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerycollateralRehypoLedgersArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CollateralRehypoLedgerOrder>;
  where?: InputMaybe<CollateralRehypoLedgerWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerycommentsArgs = {
  operationID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerycomplianceDocumentsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  folderID: Scalars['String']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerycomplianceTMSConfigsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAfterTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  filterBeforeTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  filterEntityType?: InputMaybe<TMSRuleEntityType>;
  filterLatestVersionOnly?: InputMaybe<Scalars['Boolean']['input']>;
  filterTMSConfigVersion?: InputMaybe<Scalars['Int']['input']>;
  filterTMSRuleGroup?: InputMaybe<TMSRuleGroup>;
  filterTMSRuleName?: InputMaybe<Array<TMSRuleName>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerycomplianceTMSConfigsMetadataArgs = {
  filterEntityType?: InputMaybe<TMSRuleEntityType>;
  filterTMSRuleGroup?: InputMaybe<Array<TMSRuleGroup>>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerydepositAttributionSpamThresholdsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DepositAttributionSpamThresholdOrder>;
  where?: InputMaybe<DepositAttributionSpamThresholdWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerydepositAttributionsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAttributionStatus?: InputMaybe<Array<DepositAttributionStatus>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupedBy?: InputMaybe<DepositAttributionGroupingType>;
  isAttributed?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  operationID?: InputMaybe<Scalars['String']['input']>;
  sortDESC?: InputMaybe<Scalars['Boolean']['input']>;
  sortField?: InputMaybe<DepositAttributionSortFields>;
  sourceAddress?: InputMaybe<Scalars['String']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerydepositScreeningLatestFilteredArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  operationID?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<DepositScreeningStatus>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerydepositSourceArgs = {
  operationID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerydisclosuresArgs = {
  filterDisclosureIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerydisclosuresByScreenArgs = {
  screen: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerydownloadReportArgs = {
  fileID: Scalars['ID']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryexportWalletKeyArgs = {
  walletKeyID: Scalars['ID']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryexternalAccountBalancesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAssetTypeIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterEndTimestampExclusive?: InputMaybe<Scalars['Timestamp']['input']>;
  filterExternalAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterStartTimestampInclusive?: InputMaybe<Scalars['Timestamp']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryexternalAccountLocationsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterID?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  updatedAfterTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedBeforeTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryexternalAccountOrgBalancesArgs = {
  accountAggregation?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAssetTypeIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterEndTimestampExclusive?: InputMaybe<Scalars['Timestamp']['input']>;
  filterOrganizationKeyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterStartTimestampInclusive?: InputMaybe<Scalars['Timestamp']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryexternalAccountTransactionsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterID?: InputMaybe<Scalars['ID']['input']>;
  filterOrganizationKeyID?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  updatedAfterTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedBeforeTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryexternalAccountsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterID?: InputMaybe<Scalars['ID']['input']>;
  filterLocationID?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  updatedAfterTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedBeforeTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryfacilityTransactionsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  transactionFilters?: InputMaybe<TransactionFilters>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryfeeReimbursementsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  feeReimbursementFilters?: InputMaybe<FeeReimbursementFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryfetchSignedDocusignForWithdrawalArgs = {
  transactionID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryfilteredOrganizationsArgs = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  filterOrganizationName?: InputMaybe<Scalars['String']['input']>;
  filterTradingStatus?: InputMaybe<OrganizationTradingStatus>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygenerateAccountStatementFilenameArgs = {
  accountID: Scalars['String']['input'];
  orgKeyID?: InputMaybe<Scalars['String']['input']>;
  statementDay: Scalars['Int']['input'];
  statementMonth: Scalars['Int']['input'];
  statementType: StatementType;
  statementYear: Scalars['Int']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygenerateAccountStatementPdfArgs = {
  accountID: Scalars['String']['input'];
  debug?: Scalars['Boolean']['input'];
  endDateDay: Scalars['Int']['input'];
  endDateMonth: Scalars['Int']['input'];
  endDateYear: Scalars['Int']['input'];
  orgKeyID?: InputMaybe<Scalars['String']['input']>;
  startDateDay: Scalars['Int']['input'];
  startDateMonth: Scalars['Int']['input'];
  startDateYear: Scalars['Int']['input'];
  statementType?: InputMaybe<StatementType>;
  store?: InputMaybe<Scalars['Boolean']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetApiKeysArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  orgKeyID?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  version?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetAssetTypesEnabledForOrgArgs = {
  organizationKeyID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetAssetsEnablementInfoArgs = {
  filterAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetCRRHistoryArgs = {
  affiliateID: Scalars['String']['input'];
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filters?: InputMaybe<CRRHistoryFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetCRRHistoryCSVArgs = {
  affiliateID: Scalars['String']['input'];
  filters?: InputMaybe<CRRHistoryFilters>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetCRRSectionHistoryArgs = {
  affiliateID: Scalars['String']['input'];
  fromDate: Scalars['Timestamp']['input'];
  toDate: Scalars['Timestamp']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetDAppInfoArgs = {
  dappID: Scalars['String']['input'];
  organizationKeyID?: InputMaybe<Scalars['String']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetDAppInfosArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAllowedDApps?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  organizationKeyID?: InputMaybe<Scalars['String']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetDepositAttributionsCountArgs = {
  pendingAttributions?: InputMaybe<Scalars['Boolean']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetHourlyAssetTypePricesArgs = {
  assetTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  day: Scalars['String']['input'];
  hour: Scalars['Int']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetPersonDataQualityChecksResultArgs = {
  id: Scalars['ID']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetSubaccountOrganizationCommingledWalletArgs = {
  network: Scalars['NetworkID']['input'];
  organizationKeyID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetTradingEmailRecipientsArgs = {
  organizationKeyID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetTransactionByDestCountryArgs = {
  filterAccountIDs: Array<Scalars['String']['input']>;
  fromDate: Scalars['Timestamp']['input'];
  organizationKeyID: Scalars['String']['input'];
  toDate: Scalars['Timestamp']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetTransactionVolumeHistoryArgs = {
  aggregatedBy: AggregationFilterType;
  filterAccountIDs: Array<Scalars['String']['input']>;
  fromDate: Scalars['Timestamp']['input'];
  organizationKeyID: Scalars['String']['input'];
  toDate: Scalars['Timestamp']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetTrustedSourcesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  network?: InputMaybe<Scalars['String']['input']>;
  organizationID?: InputMaybe<Scalars['String']['input']>;
  originatorCountry?: InputMaybe<Scalars['String']['input']>;
  originatorName?: InputMaybe<Scalars['String']['input']>;
  sourceAddresses?: InputMaybe<Array<Scalars['String']['input']>>;
  trustedSourceStatus?: InputMaybe<TrustedSourceStatus>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetUserPIIArgs = {
  piiType: Array<PIIInformationType>;
  userId: Scalars['ID']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetWalletConnectSessionsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterByCurrentUser?: InputMaybe<Scalars['Boolean']['input']>;
  filterStatuses?: InputMaybe<Array<WalletConnectSessionStatus>>;
  first?: Scalars['Int']['input'];
  last?: Scalars['Int']['input'];
  sortDESC?: Scalars['Boolean']['input'];
  sortField?: WalletConnectSessionSortField;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetWalletConnectSessionsForOrgArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterStatuses?: InputMaybe<Array<WalletConnectSessionStatus>>;
  first?: Scalars['Int']['input'];
  last?: Scalars['Int']['input'];
  organizationKeyID: Scalars['String']['input'];
  sortDESC?: Scalars['Boolean']['input'];
  sortField?: WalletConnectSessionSortField;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetWalletConnectSessionsForOrgWithPaginationArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterStatuses?: InputMaybe<Array<WalletConnectSessionStatus>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  organizationKeyID: Scalars['String']['input'];
  sortDESC?: Scalars['Boolean']['input'];
  sortField?: WalletConnectSessionSortField;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetWalletConnectSessionsWithPaginationArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterByCurrentUser?: InputMaybe<Scalars['Boolean']['input']>;
  filterStatuses?: InputMaybe<Array<WalletConnectSessionStatus>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  organizationKeyID?: InputMaybe<Scalars['String']['input']>;
  sortDESC?: Scalars['Boolean']['input'];
  sortField?: WalletConnectSessionSortField;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerygetWalletSpendableIDInfoArgs = {
  spendableID: Scalars['String']['input'];
  walletID: Scalars['ID']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryiamPermissionsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IAMPermissionOrder>;
  where?: InputMaybe<IAMPermissionWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryinstitutionalAffiliateArgs = {
  affiliateID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryinstitutionalAffiliatesArgs = {
  accountIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  affiliateName?: InputMaybe<Scalars['String']['input']>;
  affiliateTypes?: InputMaybe<Array<AffiliateType>>;
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  excludeAffiliateTypes?: InputMaybe<Array<AffiliateType>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  organizationIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  pendingDepositAttributions?: Scalars['Boolean']['input'];
  sortDESC?: InputMaybe<Scalars['Boolean']['input']>;
  sortField?: InputMaybe<InstitutionalAffiliateSortFields>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryinstitutionalAffiliatesCSVArgs = {
  affiliateName?: InputMaybe<Scalars['String']['input']>;
  organizationIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryinstitutionalClientArgs = {
  clientID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryinstitutionalClientsArgs = {
  filterArchivedClients?: InputMaybe<InstitutionalCosmeticStatus>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryinterestPaymentsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterBlockchainTransactionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterEntityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterFacilityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterInterestPaymentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterInvoiceIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterLoanIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterOperationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterPaymentDateAfter?: InputMaybe<Scalars['Date']['input']>;
  filterPaymentDateBefore?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerylendingEntitiesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAccountID?: InputMaybe<Scalars['ID']['input']>;
  filterLendingEntityID?: InputMaybe<Scalars['ID']['input']>;
  filterLendingSide?: InputMaybe<Array<LendingSide>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerylendingFacilitiesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAssetTypeID?: InputMaybe<Scalars['String']['input']>;
  filterFacilityStates?: InputMaybe<Array<InputMaybe<LendingFacilityState>>>;
  filterFixedRate?: InputMaybe<Scalars['Boolean']['input']>;
  filterInternal?: InputMaybe<Scalars['Boolean']['input']>;
  filterLendingFacilityIDs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  filterOffChain?: InputMaybe<Scalars['Boolean']['input']>;
  filterRiskLevel?: InputMaybe<LendingFacilityRiskLevel>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  sortKey?: InputMaybe<LendingFacilitySortKey>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerylendingFacilityArgs = {
  facilityID: Scalars['ID']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerylendingFacilityMembershipsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<LendingFacilityMembershipFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerylendingInvoicesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  invoiceFilters?: InputMaybe<InvoiceFilters>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerylendingJobHistoryRecordsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  afterJobDate?: InputMaybe<Scalars['Date']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  beforeJobDate?: InputMaybe<Scalars['Date']['input']>;
  filterState?: InputMaybe<LendingJobHistoryState>;
  filterType?: InputMaybe<LendingJobHistoryType>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerylendingScheduledJobRecordsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterScheduledTimeAfter?: InputMaybe<Scalars['Timestamp']['input']>;
  filterScheduledTimeBefore?: InputMaybe<Scalars['Timestamp']['input']>;
  filterState?: InputMaybe<LendingJobHistoryState>;
  filterType?: InputMaybe<LendingJobHistoryType>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerylendingTransferRequestsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  sortKey?: InputMaybe<LendingTransferRequestSortKey>;
  transferRequestFilters?: InputMaybe<TransferRequestFilters>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryloansArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  loanFilters?: InputMaybe<LoanFilters>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerymarketingWidgetsArgs = {
  page: Scalars['DisplayPage']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerynetworkStatusesArgs = {
  showDegradedNetworks: Scalars['Boolean']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryoperationArgs = {
  operationID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryoperationDetailsArgs = {
  operationIds: Array<Scalars['String']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryoperationsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAccountID?: InputMaybe<Scalars['String']['input']>;
  filterAccountIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterActions?: InputMaybe<Array<OperationAction>>;
  filterAppStatuses?: InputMaybe<Array<AppStatus>>;
  filterAssetSubID?: InputMaybe<Scalars['String']['input']>;
  filterAssetTypeID?: InputMaybe<Scalars['AssetTypeID']['input']>;
  filterAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterCreatedSince?: InputMaybe<Scalars['String']['input']>;
  filterCreatedUntil?: InputMaybe<Scalars['String']['input']>;
  filterDelegateTypes?: InputMaybe<Array<DelegateType>>;
  filterDepositRewardTypes?: InputMaybe<Array<DepositRewardType>>;
  filterEndedSince?: InputMaybe<Scalars['String']['input']>;
  filterEndedUntil?: InputMaybe<Scalars['String']['input']>;
  filterIncludeOneOff?: InputMaybe<Scalars['Boolean']['input']>;
  filterOperationIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterOrganizationKeyID?: InputMaybe<Scalars['String']['input']>;
  filterPublicKeyID?: InputMaybe<Scalars['String']['input']>;
  filterRequiresDecision?: InputMaybe<Scalars['Boolean']['input']>;
  filterStates?: InputMaybe<Array<OperationState>>;
  filterVaultSubID?: InputMaybe<Scalars['String']['input']>;
  filterVaultSubIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeChange?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortDESC?: InputMaybe<Scalars['Boolean']['input']>;
  sortField?: InputMaybe<OperationSortFields>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryorganizationArgs = {
  generation?: InputMaybe<Scalars['String']['input']>;
  organizationKeyID?: InputMaybe<Scalars['String']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryorganizationExternalAccountsBalancesArgs = {
  accountID?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  organizationKeyID?: InputMaybe<Scalars['ID']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryorganizationForPurposeKeyIDArgs = {
  purpose: OrganizationPurpose;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryorganizationModulesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrganizationModuleWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryorganizationNotesArgs = {
  organizationKeyID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryorganizationStatementsArgs = {
  excludeTypes?: InputMaybe<Array<StatementType>>;
  includeAllOrTheseTypes?: InputMaybe<Array<StatementType>>;
  organizationKeyID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryoutstandingTradingPositionsRiskArgs = {
  organizationKeyID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerypersonsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PersonOrderBy>;
  where?: InputMaybe<PersonWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerypolicyFragmentsAffectedArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAction: InputOperationActionPair;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  resourceID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerypolicyFragmentsEnforcedArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAction: InputOperationActionPair;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  resourceID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryportfolioArgs = {
  debug?: Scalars['Boolean']['input'];
  filterAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryproductUpdatesArgs = {
  category?: InputMaybe<ProductUpdatesCategory>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryquestionnaireByIDArgs = {
  questionnaireID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryquestionnaireByNameArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  assetTypeID?: InputMaybe<Scalars['AssetTypeID']['input']>;
  questionnaireName: Scalars['String']['input'];
  vaultSubID?: InputMaybe<Scalars['String']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryreportFilterAvailableValuesArgs = {
  fieldName: Scalars['String']['input'];
  organizationID?: InputMaybe<Scalars['ID']['input']>;
  reportType: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryreportSchedulesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReportScheduleOrder>;
  where?: InputMaybe<ReportScheduleWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryreportTypeConfigArgs = {
  organizationID?: InputMaybe<Scalars['ID']['input']>;
  reportType: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryreportTypesArgs = {
  organizationID?: InputMaybe<Scalars['ID']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryreportsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReportOrder>;
  where?: InputMaybe<ReportWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryriaFeeConfigurationsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  feeTypes?: InputMaybe<Array<FeeTypeType>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  organizationKeyID: Scalars['String']['input'];
  subaccountIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryriskReviewRequirementsArgs = {
  operationID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryriskTradesViewArgs = {
  afterTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  beforeTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  filterAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterMarket?: InputMaybe<Scalars['String']['input']>;
  filterOrderID?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterOrganizationKeyID?: InputMaybe<Scalars['String']['input']>;
  filterSettlementIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterSide?: InputMaybe<TradeSide>;
  filterStatuses?: InputMaybe<Array<TradeStatus>>;
  filterSymbol?: InputMaybe<Scalars['String']['input']>;
  filterTradeID?: InputMaybe<Scalars['String']['input']>;
  filterUserKeyID?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerysettlementViewClientArgs = {
  afterTime?: InputMaybe<Scalars['Timestamp']['input']>;
  beforeTime?: InputMaybe<Scalars['Timestamp']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  filterSettlementIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterStatuses?: InputMaybe<Array<TradeStatus>>;
  filterUserKeyID?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerysettlementViewRiskArgs = {
  afterTime?: InputMaybe<Scalars['Timestamp']['input']>;
  beforeTime?: InputMaybe<Scalars['Timestamp']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  filterAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterEntities?: InputMaybe<Array<Scalars['String']['input']>>;
  filterOrganizationKeyID?: InputMaybe<Scalars['String']['input']>;
  filterSettlementIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterStatuses?: InputMaybe<Array<TradeStatus>>;
  filterUserKeyID?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerysignedURLToDownloadDocsForComplianceAlertsArgs = {
  documentName: Scalars['String']['input'];
  folderID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerysnAssetsArgs = {
  acceptorID: Scalars['ID']['input'];
  proposerID: Scalars['ID']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerysnParticipantByIDArgs = {
  id: Scalars['ID']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerysnParticipantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterActive?: InputMaybe<Scalars['Boolean']['input']>;
  filterName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerysnSettlementByIDArgs = {
  id: Scalars['ID']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerysnSettlementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterActionNeededFromMe?: InputMaybe<Scalars['Boolean']['input']>;
  filterActionNotNeededFromMe?: InputMaybe<Scalars['Boolean']['input']>;
  filterParticipantInIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterState?: InputMaybe<SNSettlementStateFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerysnTrustedCounterpartiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterActive?: InputMaybe<Scalars['Boolean']['input']>;
  filterCanSettle?: Scalars['Boolean']['input'];
  filterOwnerInIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchByNameOrID?: InputMaybe<Scalars['String']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerysocReportCSVArgs = {
  filterCreatedSince: Scalars['String']['input'];
  filterCreatedUntil: Scalars['String']['input'];
  filterOrganizationKeyID?: InputMaybe<Scalars['String']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerystagedOrganizationArgs = {
  stagedOrganizationID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerystandingInstructionsArgs = {
  affiliateID?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<StandingInstructionStateType>;
  walletUniqueID?: InputMaybe<Scalars['String']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerysupportedAPIVaultRolesArgs = {
  vaultSubID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradesClientViewCsvArgs = {
  afterTime?: InputMaybe<Scalars['Timestamp']['input']>;
  beforeTime?: InputMaybe<Scalars['Timestamp']['input']>;
  filterAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterSide?: InputMaybe<TradeSide>;
  filterStatuses?: InputMaybe<Array<TradeStatus>>;
  filterSymbol?: InputMaybe<Scalars['String']['input']>;
  filterTradeID?: InputMaybe<Scalars['String']['input']>;
  filterUserKeyID?: InputMaybe<Scalars['String']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradesRiskViewCsvArgs = {
  afterTime: Scalars['Timestamp']['input'];
  beforeTime: Scalars['Timestamp']['input'];
  filterAccountIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterMarket?: InputMaybe<Scalars['String']['input']>;
  filterOrganizationKeyID?: InputMaybe<Scalars['String']['input']>;
  filterStatuses?: InputMaybe<Array<TradeStatus>>;
  filterTradeID?: InputMaybe<Scalars['String']['input']>;
  filterTradingPair?: InputMaybe<Scalars['String']['input']>;
  filterUserKeyID?: InputMaybe<Scalars['String']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingAccountsArgs = {
  affiliateIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  organizationKeyID?: InputMaybe<Scalars['ID']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingAssetsArgs = {
  input: TradingAssetsInput;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingClientPaymentFlowsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<ClientPaymentFlowFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingClientTradesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterAfterTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  filterBeforeTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  filterHasSubaccountID?: InputMaybe<Scalars['Boolean']['input']>;
  filterManualTrades?: InputMaybe<Scalars['Boolean']['input']>;
  filterMarket?: InputMaybe<Scalars['String']['input']>;
  filterMarketOrderID?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterNullSettlementID?: InputMaybe<Scalars['Boolean']['input']>;
  filterOrderID?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterOrderTypes?: InputMaybe<Array<OrdType>>;
  filterOrganizationKeyID?: InputMaybe<Scalars['String']['input']>;
  filterSettlementIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterSide?: InputMaybe<TradeSide>;
  filterStatuses?: InputMaybe<Array<TradeStatus>>;
  filterSymbol?: InputMaybe<Scalars['String']['input']>;
  filterTradeIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterTradeSources?: InputMaybe<Array<TradeSource>>;
  filterUserKeyID?: InputMaybe<Scalars['String']['input']>;
  filterVaultIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingCounterpartiesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterActive?: InputMaybe<Scalars['Boolean']['input']>;
  filterKind?: InputMaybe<Array<CounterpartyKind>>;
  filterName?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingDealerSettlementsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterAssignee?: InputMaybe<Array<Scalars['String']['input']>>;
  filterCounterparty?: InputMaybe<Array<Scalars['String']['input']>>;
  filterCreatedAtAfter?: InputMaybe<Scalars['Timestamp']['input']>;
  filterCreatedAtBefore?: InputMaybe<Scalars['Timestamp']['input']>;
  filterEntity?: InputMaybe<Array<Scalars['String']['input']>>;
  filterID?: InputMaybe<Scalars['ID']['input']>;
  filterStatus?: InputMaybe<Array<DealerSettlementStatus>>;
  filterTransactionID?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingDealerTradesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterCounterparty?: InputMaybe<Array<Scalars['String']['input']>>;
  filterEntity?: InputMaybe<Array<Scalars['String']['input']>>;
  filterExecID?: InputMaybe<Scalars['ID']['input']>;
  filterFlowType?: InputMaybe<FlowType>;
  filterID?: InputMaybe<Scalars['ID']['input']>;
  filterInitiator?: InputMaybe<Array<DealerTradeInitiator>>;
  filterIsHistorical?: InputMaybe<Scalars['Boolean']['input']>;
  filterOrdType?: InputMaybe<Array<OrdType>>;
  filterParentOrderID?: InputMaybe<Scalars['String']['input']>;
  filterSide?: InputMaybe<TradeSide>;
  filterSource?: InputMaybe<Array<TradeSource>>;
  filterSymbol?: InputMaybe<Array<Scalars['String']['input']>>;
  filterTransactTimeAfter?: InputMaybe<Scalars['Timestamp']['input']>;
  filterTransactTimeBefore?: InputMaybe<Scalars['Timestamp']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingDealerTradesCSVArgs = {
  filters?: InputMaybe<DealerTradesFilters>;
  pageParams?: InputMaybe<PageParams>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingExternalAssetsArgs = {
  input: TradingExternalAssetsInput;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingGetClientSettlementsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<ClientSettlementFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingGetExecutionReportsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  afterTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  beforeTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  execID?: InputMaybe<Array<Scalars['String']['input']>>;
  filterOrderID?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  internalStatus?: InputMaybe<Array<InternalStatus>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  marketID?: InputMaybe<Array<Scalars['String']['input']>>;
  ordStatus?: InputMaybe<Array<OrdStatus>>;
  symbol?: InputMaybe<Array<Scalars['String']['input']>>;
  transactTimeAfterTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  transactTimeBeforeTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingGetOrderExecutionReportsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  afterTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  beforeTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  filterMissingParentOrderID?: InputMaybe<Scalars['Boolean']['input']>;
  filterSymbol?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ordStatus?: InputMaybe<Array<OrdStatus>>;
  transactTimeAfterTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  transactTimeBeforeTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingGetUnsettledClientBalancesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingOrderExecReportsForClientInitiatedTradesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  afterTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  beforeTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  transactTimeAfterTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  transactTimeBeforeTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingPaymentFlowsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAssetType?: InputMaybe<Scalars['AssetTypeID']['input']>;
  filterAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterCounterparty?: InputMaybe<Scalars['String']['input']>;
  filterCreatedAtAfter?: InputMaybe<Scalars['Timestamp']['input']>;
  filterCreatedAtBefore?: InputMaybe<Scalars['Timestamp']['input']>;
  filterEntity?: InputMaybe<Array<Scalars['String']['input']>>;
  filterExcludeSettled?: InputMaybe<Scalars['Boolean']['input']>;
  filterID?: InputMaybe<Scalars['ID']['input']>;
  filterMissingSettlementID?: InputMaybe<Scalars['Boolean']['input']>;
  filterOrderID?: InputMaybe<Scalars['ID']['input']>;
  filterSettlementID?: InputMaybe<Scalars['String']['input']>;
  filterSide?: InputMaybe<TradeSide>;
  filterStatus?: InputMaybe<Array<DealerSettlementStatus>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingPermissionsArgs = {
  filterOrganizationKeyIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterPermissions?: InputMaybe<Array<TradingPermissionType>>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingSettlementPreviewArgs = {
  accountID?: InputMaybe<Scalars['String']['input']>;
  organizationKeyID: Scalars['String']['input'];
  tradeIDs: Array<Scalars['String']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingSymbolsArgs = {
  filterEntities?: InputMaybe<Array<AnchorageEntityType>>;
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingTradeSettlementTransactionsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterID?: InputMaybe<Array<Scalars['String']['input']>>;
  filterSettlementID?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingUnsettledDealerBalancesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterAssetTypeID?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterCounterparty?: InputMaybe<Array<Scalars['String']['input']>>;
  filterEntity?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingWhitelabelAccountsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytradingWhitelabelUsersArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytransactionHistoryCSVArgs = {
  filterAccountID?: InputMaybe<Scalars['String']['input']>;
  filterAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterActions?: InputMaybe<Array<OperationAction>>;
  filterAppStatuses?: InputMaybe<Array<AppStatus>>;
  filterAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterCreatedSince?: InputMaybe<Scalars['String']['input']>;
  filterCreatedUntil?: InputMaybe<Scalars['String']['input']>;
  filterDelegateTypes?: InputMaybe<Array<DelegateType>>;
  filterDepositRewardTypes?: InputMaybe<Array<DepositRewardType>>;
  filterEndedSince?: InputMaybe<Scalars['String']['input']>;
  filterEndedUntil?: InputMaybe<Scalars['String']['input']>;
  filterIncludeOneOff?: InputMaybe<Scalars['Boolean']['input']>;
  filterStates?: InputMaybe<Array<OperationState>>;
  filterVaultSubID?: InputMaybe<Scalars['String']['input']>;
  filterVaultSubIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytransactionHistoryCSVAsyncArgs = {
  filterAccountID?: InputMaybe<Scalars['String']['input']>;
  filterAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterActions?: InputMaybe<Array<OperationAction>>;
  filterAppStatuses?: InputMaybe<Array<AppStatus>>;
  filterAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterDelegateTypes?: InputMaybe<Array<DelegateType>>;
  filterDepositRewardTypes?: InputMaybe<Array<DepositRewardType>>;
  filterEndedSinceDateRFC3339?: InputMaybe<Scalars['String']['input']>;
  filterEndedUntilDateRFC3339?: InputMaybe<Scalars['String']['input']>;
  filterIncludeOneOff?: InputMaybe<Scalars['Boolean']['input']>;
  filterStates?: InputMaybe<Array<OperationState>>;
  filterTransactionEndDateInclusiveRFC3339?: InputMaybe<Scalars['String']['input']>;
  filterTransactionStartDateRFC3339?: InputMaybe<Scalars['String']['input']>;
  filterVaultSubID?: InputMaybe<Scalars['String']['input']>;
  filterVaultSubIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  genNewVersion?: Scalars['Boolean']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytransferAccessRequestsArgs = {
  filterDomains?: InputMaybe<Array<TransferAccessRequestDomain>>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerytravelRuleInformationArgs = {
  operationID: Scalars['String']['input'];
  orgID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryunSettledManualTradeArgs = {
  tradeID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryuserSettingsArgs = {
  userKeyID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QueryusersV2Args = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserV2WhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryvaultArgs = {
  subID?: InputMaybe<Scalars['String']['input']>;
  vaultUniqueID?: InputMaybe<Scalars['String']['input']>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryvaultsArgs = {
  filterCurrentUserIsVaultMember?: InputMaybe<Scalars['Boolean']['input']>;
  filterStates?: InputMaybe<Array<VaultState>>;
};


/** The query type, represents all of the entry points into our object graph */
export type QueryvaultsWithPaginationArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterCurrentUserIsVaultMember?: InputMaybe<Scalars['Boolean']['input']>;
  filterStates?: InputMaybe<Array<VaultState>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  sortField?: InputMaybe<VaultSortField>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerywalletGroupArgs = {
  walletGroupID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerywalletGroupsArgs = {
  vaultSubID: Scalars['String']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerywalletsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterByAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterByAssetTypeNetworkIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterByIsArchived?: InputMaybe<Scalars['Boolean']['input']>;
  filterByNetworkIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterByVaultSubIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterByWalletIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterByWalletsWithOneOfAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterCurrentUserIsVaultMember?: InputMaybe<Scalars['Boolean']['input']>;
  filterMaxQuantity?: InputMaybe<Scalars['String']['input']>;
  filterMinQuantity?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  quantityRangeIsInclusive?: Scalars['Boolean']['input'];
  searchByAddress?: InputMaybe<Scalars['String']['input']>;
  searchByName?: InputMaybe<Scalars['String']['input']>;
  sortDESC?: Scalars['Boolean']['input'];
  sortField?: WalletSortField;
};


/** The query type, represents all of the entry points into our object graph */
export type Querywallets1Args = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterByAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterByAssetTypeNetworkIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterByIsArchived?: InputMaybe<Scalars['Boolean']['input']>;
  filterByNetworkIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterByVaultSubIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterByWalletIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterByWalletsWithOneOfAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterCurrentUserIsVaultMember?: InputMaybe<Scalars['Boolean']['input']>;
  filterMaxQuantity?: InputMaybe<Scalars['String']['input']>;
  filterMinQuantity?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  quantityRangeIsInclusive?: Scalars['Boolean']['input'];
  searchByAddress?: InputMaybe<Scalars['String']['input']>;
  searchByName?: InputMaybe<Scalars['String']['input']>;
  sortDESC?: Scalars['Boolean']['input'];
  sortField?: WalletSortField;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerywebhookEndpointArgs = {
  id: Scalars['ID']['input'];
};


/** The query type, represents all of the entry points into our object graph */
export type QuerywebhookEndpointsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WebhookEndpointWhereInput>;
};


/** The query type, represents all of the entry points into our object graph */
export type QuerywebhookEventTypesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WebhookEventTypeWhereInput>;
};

/** QuestionAnswer is the actual user answer for a question */
export type QuestionAnswer = {
  __typename: 'QuestionAnswer';
  /** The free form text written in response to the question */
  freeformText: Maybe<Scalars['String']['output']>;
  /** A group of answers, used to answer for a question of type group */
  group: Array<Array<QuestionAnswer>>;
  /** The option IDs selected in response to the question */
  options: Maybe<Array<Scalars['String']['output']>>;
  /** The id of the question this answer is related to */
  questionID: Scalars['String']['output'];
};

/** Questionnaire contains the entire questionnaire body */
export type Questionnaire = {
  __typename: 'Questionnaire';
  /** Time at which this questionnaire version was created */
  createdAt: Scalars['Timestamp']['output'];
  /** Resolves the questionnaire pages */
  pages: Array<QuestionnairePage>;
  /** List of pages in this questionnaire as JSON */
  questionnaireBody: Scalars['String']['output'];
  /** Unique ID for this particular questionnaire version */
  questionnaireID: Scalars['String']['output'];
  /** Name of questionnaire, e.g. aml_address */
  questionnaireName: Scalars['String']['output'];
  /** Type of the questionnaire */
  questionnaireType: Scalars['String']['output'];
  /** Version of the questionnaire */
  questionnaireVersion: Scalars['String']['output'];
};

/** Answer provided for a particular question with metadata */
export type QuestionnaireAnswer = {
  __typename: 'QuestionnaireAnswer';
  /** List of options or freeform text for the answer */
  answerData: Maybe<QuestionnaireAnswerData>;
  /** Timestamp at which the answer was recorded */
  createdAt: Scalars['Timestamp']['output'];
  /** Unique identifier for the question */
  questionID: Scalars['String']['output'];
  /** The user that submitted the answer */
  userID: Scalars['String']['output'];
};

/** Actual answer data provided by the user */
export type QuestionnaireAnswerData = {
  __typename: 'QuestionnaireAnswerData';
  /** Freeform text given by the user */
  freeformText: Scalars['String']['output'];
  /** Options selected by the user */
  options: Array<Scalars['String']['output']>;
};

/** QuestionnairePage contains all questions for a single page, simple questionnaires may use only a single page */
export type QuestionnairePage = {
  __typename: 'QuestionnairePage';
  /** The unique identifier for this page */
  id: Scalars['String']['output'];
  /** Specifies whether to show questions one at a time or the entire page at once */
  oneByOne: Scalars['Boolean']['output'];
  /** The list of ordered questions to ask */
  questions: Array<QuestionnaireQuestion>;
  /** The subtitle is for a block of text following the header */
  subtitle: Scalars['String']['output'];
  /** The header to display on the page */
  title: Scalars['String']['output'];
};

/** QuestionnaireQuestion contains the details for a particular question */
export type QuestionnaireQuestion = {
  __typename: 'QuestionnaireQuestion';
  /** The response for the question, which is a list of options or freeform text for the answer. */
  answer: Maybe<QuestionAnswer>;
  /** The text to display below the options or field */
  footer: Scalars['String']['output'];
  /** Used to allow custom text answer by using this "id" (only for dropdown) */
  freeformOptionID: Scalars['String']['output'];
  /** The group of questions that can be answered more than once (only for GROUP) */
  group: Array<QuestionnaireQuestion>;
  /** The text to display below "text" but above the options or field */
  header: Scalars['String']['output'];
  /** The unique identifier for the question */
  id: Scalars['String']['output'];
  /** The optional user-friendly shorthand name for the question */
  name: Scalars['String']['output'];
  /** Identifies whether this question is optional */
  optional: Scalars['Boolean']['output'];
  /** List of possible options (only for CHIP, RADIO, CHECKBOX, DROPDOWN, DROPDOWN_MULTI_SELECT, RADIO_GROUP) */
  options: Array<QuestionnaireQuestionOption>;
  /** The text to display inside the field (only for textfield or dropdown) */
  placeholderText: Scalars['String']['output'];
  /** Used for one question value should not be editable. Could be used prefilled with an answer in a previous form and serve only information purposes. */
  readOnly: Scalars['Boolean']['output'];
  /** The rules for validating the user's answer */
  rules: Array<QuestionnaireQuestionRule>;
  /** The text to display below SectionTitle but above the field, in case the question is a section */
  sectionSubTitle: Scalars['String']['output'];
  /** The text to display above the field, in case the question is a section */
  sectionTitle: Scalars['String']['output'];
  /** Used to show the next question before answering this one this can cascade, if the next question has ShowNext, also show it (only for OneByOne) */
  showNext: Scalars['Boolean']['output'];
  /** The optional internal identifier tag */
  tag: Scalars['String']['output'];
  /** The text to display above the options or field */
  text: Scalars['String']['output'];
  /** A tooltip to explain the question */
  tooltip: Scalars['String']['output'];
  /** Used to only ask this question if the user selected a specific option e.g. {{"o1", "o2"}, {"o3"}} means [[o1 AND o2] OR [o3]] */
  triggerOptionID: Array<Array<Scalars['String']['output']>>;
  /** The type of question */
  type: QuestionnaireQuestionType;
};

/** QuestionnaireQuestionOption is a selectable element for chip, radio, dropdown, checkbox, dropdown_multi_select, and RADIO_GROUP questions */
export type QuestionnaireQuestionOption = {
  __typename: 'QuestionnaireQuestionOption';
  /** When an exclusive option is selected, it should be the only selected option */
  exclusive: Scalars['Boolean']['output'];
  /** The unique identifier for the option */
  id: Scalars['String']['output'];
  /** In a RADIO_GROUP question, this represents the questions that will be active when this option is selected */
  questionGroup: Maybe<Array<QuestionnaireQuestion>>;
  /** The subtext for the option */
  subtext: Scalars['String']['output'];
  /** Display a subtitle based on a previous question (only for radio questions) */
  subtitleQuestionID: Scalars['String']['output'];
  /** The optional internal identifier tag */
  tag: Scalars['String']['output'];
  /** The text to display for this option */
  text: Scalars['String']['output'];
  /** The tooltip for the option */
  tooltip: Scalars['String']['output'];
};

/** QuestionnaireQuestionRule describes a rule that validates a question's answers */
export type QuestionnaireQuestionRule = {
  __typename: 'QuestionnaireQuestionRule';
  /** A custom error message to be displayed if the validation fails */
  errorMessage: Scalars['String']['output'];
  /** The ID of a question whose answer will be the value for the rule */
  questionId: Maybe<Scalars['String']['output']>;
  /** The type of validation rule */
  type: QuestionnaireQuestionRuleType;
  /** The value of the rule (e.g. a regular expression or a number indicating the max value) */
  value: Scalars['String']['output'];
};

/** QuestionnaireQuestionRuleType describes the validation preformed by the rule */
export enum QuestionnaireQuestionRuleType {
  /** ADD_DAYS is a rule that checks if the date answer is within a given number of days from the current date */
  ADD_DAYS = 'ADD_DAYS',
  /** CHECK_SUM is a rule that checks if the sum of the answers for a given question inside a group equals a given value */
  CHECK_SUM = 'CHECK_SUM',
  /** DISTINCT is a rule that checks if a given question inside a group has unique answers */
  DISTINCT = 'DISTINCT',
  /** MAX_DATE is the maximum accepted date in the answer for date type questions */
  MAX_DATE = 'MAX_DATE',
  /** MAX_LENGTH is a maximum amount of characters or items in the answer */
  MAX_LENGTH = 'MAX_LENGTH',
  /** MAX_SUM is a rule that checks if the sum of the answers for a given question inside a group is below a given value */
  MAX_SUM = 'MAX_SUM',
  /** MIN_DATE is the minimum accepted date in the answer for date type questions */
  MIN_DATE = 'MIN_DATE',
  /** MIN_LENGTH is a minimum amount of characters or items in the answer */
  MIN_LENGTH = 'MIN_LENGTH',
  /** MIN_SUM is a rule that checks if the sum of the answers for a given question inside a group is above a given value */
  MIN_SUM = 'MIN_SUM',
  /** REGEX is a rule that contains a regex expression that is validated against the the answer text */
  REGEX = 'REGEX'
}

/** QuestionnaireQuestionType is the type of the questionnaire question */
export enum QuestionnaireQuestionType {
  /** CHECKBOX allows for the selection of multiple options in a list, adequate for short lists, may be optional */
  CHECKBOX = 'CHECKBOX',
  /** CHIP allows the selection of one option in a list, adequate for selecting words in short lists */
  CHIP = 'CHIP',
  /** DATE is a question picker input type, should be answered with freeform text in the form of MM/DD/YYYY, may be optional */
  DATE = 'DATE',
  /** DROPDOWN allows the selection of one option in a list, adequate for long lists */
  DROPDOWN = 'DROPDOWN',
  /** DROPDOWN_MULTI_SELECT allows for the selection of multiple options in a list, adequate for long lists, may be optional */
  DROPDOWN_MULTI_SELECT = 'DROPDOWN_MULTI_SELECT',
  /** GROUP enables a group of questions to be answered multiple times, may be optional, use MAX_LENGTH/MIN_LENGTH to specify the required number of times to answer the questions, answers must be given ion the answer group */
  GROUP = 'GROUP',
  /** RADIO allows the selection of one option in a list, adequate for short lists */
  RADIO = 'RADIO',
  /** RADIO_GROUP enables a group of questions when the corresponding option is selected, the sub questions are answered as regular questions, may be optional */
  RADIO_GROUP = 'RADIO_GROUP',
  /** SECTION indicates the question is to be handled as a section describing the next questions, cannot receive answers */
  SECTION = 'SECTION',
  /** TEXTFIELD is a free text input, may be optional */
  TEXTFIELD = 'TEXTFIELD',
  /** TEXT_AREA is also free text input, may be optional */
  TEXT_AREA = 'TEXT_AREA',
  /** UPLOAD allows file uploads, may be optional */
  UPLOAD = 'UPLOAD',
  /** VOID is a no-op input type, cannot receive answers */
  VOID = 'VOID'
}

/** Questionnaire and submitted answers */
export type QuestionnaireSubmission = {
  __typename: 'QuestionnaireSubmission';
  /** List of answers */
  answers: Array<QuestionnaireAnswer>;
  /** Time at which this submission was created */
  createdAt: Scalars['Timestamp']['output'];
  /** If the submitted answers can be edited */
  editable: Scalars['Boolean']['output'];
  /** Questionnaire used on this submission */
  questionnaire: Questionnaire;
  /** Unique identifier for the submission */
  submissionID: Scalars['String']['output'];
  /** Metadata related to the submission */
  submissionMetadata: Maybe<SubmissionMetadata>;
  /** If the questionnaire has been validated for all required answers */
  submitted: Scalars['Boolean']['output'];
  /** The user that created the submission */
  userID: Scalars['String']['output'];
};

/**
 * Quorum represents a list of authorized keys combined with the required number of
 * keys in order for the quorum to be met.
 */
export type Quorum = {
  __typename: 'Quorum';
  /** Number of signatures required to satisfy this quorum */
  signaturesRequired: Scalars['Int']['output'];
  /** Number of users authorized by this quorum */
  userCount: Scalars['Int']['output'];
  /** List of keys authorized to participate in this quorum */
  users: Array<User>;
};


/**
 * Quorum represents a list of authorized keys combined with the required number of
 * keys in order for the quorum to be met.
 */
export type QuorumusersArgs = {
  sortField?: InputMaybe<UserSortField>;
};

/** The strictness of a quorum to be met in order to fulfill a policy fragment. */
export enum QuorumRequirement {
  /**
   * The quorum must be met in order to fulfill the policy fragment.
   * There exists no mechanism that can override or bypass the quorum.
   */
  QUORUM_REQUIREMENT_STRICT = 'QUORUM_REQUIREMENT_STRICT',
  /**
   * The quorum in optional in order to fulfill the policy framgment.
   * There exists mechanisms that can override the quorum requirement.
   */
  QUORUM_REQUIREMENT_WEAK = 'QUORUM_REQUIREMENT_WEAK'
}

/** Quote for a given request */
export type Quote = {
  __typename: 'Quote';
  /** Bid quote amount */
  bid: Maybe<Amount>;
  /** Bid quote amount per unit */
  bidUnit: Maybe<Amount>;
  /** Offer quote amount */
  offer: Maybe<Amount>;
  /** Offer quote amount per unit */
  offerUnit: Maybe<Amount>;
  /** Quantity to quote for in units of Currency */
  quantity: Amount;
  /** quoteID of this quote */
  quoteID: Scalars['String']['output'];
  /** Rejection reason if applicable */
  rejectionReason: Maybe<Scalars['String']['output']>;
  /** Side of the quote request. */
  side: QuoteSide;
  /** Spread in BPS */
  spreadBPS: Maybe<Scalars['String']['output']>;
  /** Difference between bid and offer */
  spreadUnit: Maybe<Amount>;
  /** The current quote status */
  status: QuoteStatus;
  /** The time this quote was procured. */
  timestamp: Maybe<Scalars['Timestamp']['output']>;
  /** Trading pair being quoted */
  tradingPair: Scalars['String']['output'];
  /** The expire time of the quote. */
  validUntilTime: Maybe<Scalars['Timestamp']['output']>;
};

/** Represents the request for a quote */
export type QuoteRequestParams = {
  /** Account ID */
  accountID?: InputMaybe<Scalars['String']['input']>;
  /** Asset type of Quantity */
  assetTypeID: Scalars['AssetTypeID']['input'];
  /** Quantity to quote for in units of Currency */
  quantity: Scalars['String']['input'];
  /** Side of the quote request. */
  side: QuoteSide;
  /** Trading pair being quoted */
  tradingPair: Scalars['String']['input'];
};

/** Possible quote sides */
export enum QuoteSide {
  /** Buying base asset type */
  BUY = 'BUY',
  /** Selling base asset type */
  SELL = 'SELL',
  /** Both buying and selling base asset type */
  TWOWAY = 'TWOWAY'
}

/** The different statuses that a quote can go through */
export enum QuoteStatus {
  /** The code was executed, meaning that it is complete and executed */
  EXECUTED = 'EXECUTED',
  /** The quote is open and awaiting trading or cancellation */
  OPEN = 'OPEN',
  /** The quote was rejected */
  REJECTED = 'REJECTED'
}

/** RFQOutput defines the expected fields the block-pricer model responds with */
export type RFQOutput = {
  __typename: 'RFQOutput';
  /** Ask information for the RFQ */
  ask: Maybe<BidAskOutput>;
  /** Bid information for the RFQ */
  bid: Maybe<BidAskOutput>;
  /** Reference price information */
  referencePrice: Maybe<ReferencePrice>;
};

/** RFQParams sets the params the block-pricer model requires */
export type RFQParams = {
  /** Base currency for the trade */
  baseCurrency: Scalars['String']['input'];
  /** Counter currency for the trade */
  counterCurrency: Scalars['String']['input'];
  /** Whether to enable FX rate */
  enableFxRate: Scalars['Boolean']['input'];
  /** Name of the trading entity */
  entityName: Scalars['String']['input'];
  /** Estimated delivery time for the trade */
  estimatedDeliveryTime: Scalars['String']['input'];
  /** List of liquidity venues to consider */
  liquidityVenues: Array<Scalars['String']['input']>;
  /** Method for FX rate calculation */
  method: Method;
  /** Participating rate for the trade */
  participatingRate: Scalars['Percent']['input'];
  /** Quote currency for the trade */
  quoteCurrency: Scalars['String']['input'];
  /** Size of the trade */
  size: Scalars['String']['input'];
  /** Whether to use USDC parity */
  usdcParity: Scalars['Boolean']['input'];
};

/**
 * ReadPolicy type represents a policy designed exclusively for read operations.
 * It simplifies the view of policy representations, focusing on read-specific attributes.
 * It cannot be used for signing purposes.
 */
export type ReadPolicy = Node & {
  __typename: 'ReadPolicy';
  /** DisplayName is the human readable name of the policy. */
  displayName: Scalars['String']['output'];
  /** The ID of the ReadPolicy. */
  id: Scalars['ID']['output'];
  /** Quorums are the list of quorums that must be met in order to fulfill the policy. */
  quorums: ReadQuorumConnection;
  /** Resource represents the resource that the policy is applied to. */
  resource: PolicyResource;
};


/**
 * ReadPolicy type represents a policy designed exclusively for read operations.
 * It simplifies the view of policy representations, focusing on read-specific attributes.
 * It cannot be used for signing purposes.
 */
export type ReadPolicyquorumsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Read policy pagination connection. */
export type ReadPolicyConnection = {
  __typename: 'ReadPolicyConnection';
  /** A list of edges. */
  edges: Array<ReadPolicyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReadPolicyEdge = {
  __typename: 'ReadPolicyEdge';
  /** A CursorType for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: ReadPolicy;
};

/**
 * ReadQuorum type represents a quorum optimized for read queries.
 * It cannot be used for signing purposes.
 */
export type ReadQuorum = Node & {
  __typename: 'ReadQuorum';
  /** The base quorum represents the strictly enforced Quorum. */
  baseQuorum: PolicyQuorum;
  /** The ID of the ReadQuorum. This ID will be based on the quorum position within the array. */
  id: Scalars['ID']['output'];
  /** The SubQuorums represent the weakly enforced quorums. */
  subQuorums: PolicyQuorumConnection;
};


/**
 * ReadQuorum type represents a quorum optimized for read queries.
 * It cannot be used for signing purposes.
 */
export type ReadQuorumsubQuorumsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Read quorum pagination connection. */
export type ReadQuorumConnection = {
  __typename: 'ReadQuorumConnection';
  /** A list of edges. */
  edges: Array<ReadQuorumEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReadQuorumEdge = {
  __typename: 'ReadQuorumEdge';
  /** A CursorType for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: ReadQuorum;
};

/** WalletKey represents an encrypted wallet key. */
export type RebalanceStrategy = {
  __typename: 'RebalanceStrategy';
  /** The time at which the strategy was created */
  createdAt: Scalars['String']['output'];
  /** The maximum balance of a blockchian transaction while it is rebalanced */
  maximumBalance: Amount;
  /** The minimum balance of a blockchian transaction while it is rebalanced */
  minimumBalance: Amount;
  /** The time at which the strategy will end */
  startTimestamp: Maybe<Scalars['Timestamp']['output']>;
  /** The status of the strategy */
  status: Maybe<RebalanceStrategyStatus>;
  /** The time at which the strategy was updated */
  updatedAt: Scalars['String']['output'];
};

/** status of the strategy */
export enum RebalanceStrategyStatus {
  /** The strategy is active and will be executed */
  ACTIVE = 'ACTIVE',
  /** The strategy is inactive and will not be executed */
  INACTIVE = 'INACTIVE',
  /** The strategy is paused and will not be executed */
  PAUSED = 'PAUSED'
}

/** ReceivingAddressInfo embeds information about the receiving address for a withdraw operation */
export type ReceivingAddressInfo = {
  __typename: 'ReceivingAddressInfo';
  /** isTrustedDestination demarcates whether the organization has added this as a trusted destination */
  isTrustedDestination: Scalars['Boolean']['output'];
  /** name is for a trusted destination's name */
  name: Maybe<Scalars['String']['output']>;
  /** riskAnalysis is the collected risk information for this address if we have any */
  riskAnalysis: Maybe<TransactionRiskAnalysis>;
  /**
   * signedAddress is the signed ID for this address if it is allocated to an organization the user
   * is authorized to view
   */
  signedAddress: Maybe<SignedAddress>;
};

/** ReceivingStandingInstruction contains to standing instructiong and the fedID to identify the wire transaction */
export type ReceivingStandingInstruction = {
  __typename: 'ReceivingStandingInstruction';
  /** Withdrawal Wire transfer ID */
  fedID: Maybe<Scalars['String']['output']>;
  /** Standing instruction for the withdrawal */
  standingInstruction: StandingInstruction;
};

/** ReferencePrice is the price of BlockPricerTradingAsset that block-pricer model used in calculations */
export type ReferencePrice = {
  __typename: 'ReferencePrice';
  /** Volume-weighted average price for the market */
  vwapMarket: Scalars['String']['output'];
};

export type RehypoLedgerMetadata = {
  __typename: 'RehypoLedgerMetadata';
  transactionID: Maybe<Scalars['String']['output']>;
};

export type RehypoLedgerMetadataInput = {
  transactionID?: InputMaybe<Scalars['String']['input']>;
};

/**
 * RemoveQuorumRecoveryInput represents the input needed to remove the quorum
 * recovery entry for a given organization.
 */
export type RemoveQuorumRecoveryInput = {
  /** The ID of the organization for which the quorum recovery is being removed. */
  organizationKeyID: Scalars['String']['input'];
};

/** RemoveTrustedDestinationOperation represents an operation where a user is added to the organization */
export type RemoveTrustedDestinationOperation = Operation & {
  __typename: 'RemoveTrustedDestinationOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** Trusted destination being removed from the organization */
  destination: TrustedDestination;
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** the expected digest of the new organization after applying this change */
  newOrganizationDigest: Scalars['String']['output'];
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * policy that applies to this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
};


/** RemoveTrustedDestinationOperation represents an operation where a user is added to the organization */
export type RemoveTrustedDestinationOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** RemoveTrustedDestinationOperation represents an operation where a user is added to the organization */
export type RemoveTrustedDestinationOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/**
 * RemoveTrustedSmartContractInput represents the input needed to remove a trusted
 * smart contract.
 */
export type RemoveTrustedSmartContractInput = {
  /** The Sub ID of the trusted smart contract to remove. */
  subID: Scalars['String']['input'];
};

/**
 * RemoveUserAndPoliciesOperation represents an operation where a user is removed from the organization
 * and all its policies
 */
export type RemoveUserAndPoliciesOperation = Operation & {
  __typename: 'RemoveUserAndPoliciesOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** The key that will be removed from the organization */
  key: Key;
  /** the expected digest of the new organization after applying this change */
  newOrganizationDigest: Scalars['String']['output'];
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * policy that applies to this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /**
   * policyContainerUpdate represents the bindings that will be applied
   * to the org policy container
   */
  policyContainerUpdate: Maybe<PolicyContainerUpdate>;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /** roleChanges represents the IAM role changes that will be applied with this operation. */
  roleChanges: Maybe<IAMRoleChanges>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /** Policies the user is being removed from */
  updatedOrgPolicies: Array<PolicyUpdate>;
  /** Vault policies the user is being removed from */
  updatedVaultPolicies: Array<VaultPolicyUpdate>;
  /** The user being removed from the organization */
  user: User;
  /**
   * vaultPolicyContainerUpdate represents the bindings that will be applied
   * to the vault policy container
   */
  vaultPolicyContainerUpdate: Maybe<VaultPolicyContainerUpdate>;
};


/**
 * RemoveUserAndPoliciesOperation represents an operation where a user is removed from the organization
 * and all its policies
 */
export type RemoveUserAndPoliciesOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/**
 * RemoveUserAndPoliciesOperation represents an operation where a user is removed from the organization
 * and all its policies
 */
export type RemoveUserAndPoliciesOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/** RemoveUserKeyInput represents the input needed to remove a user key */
export type RemoveUserKeyInput = {
  /** The public key ID of the user key to remove. */
  publicKeyID: Scalars['PublicKeyID']['input'];
};

/** A single pair of wallet and network, used in snParticipantUpdate */
export type ReplaceDefaultWalletInput = {
  /** The network to update the default wallet for */
  networkID: Scalars['String']['input'];
  /** The wallet unique ID */
  walletUniqueID: Scalars['String']['input'];
};

/** ReplaceUserOperation represents an operation where a user is replaced by another one */
export type ReplaceUserOperation = Operation & {
  __typename: 'ReplaceUserOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** The key that will be added to the organization */
  newKey: Key;
  /** the expected digest of the new organization after applying this change */
  newOrganizationDigest: Scalars['String']['output'];
  /** The user being added to the organization */
  newUser: StagedUser;
  /** The key that will be removed from the organization */
  oldKey: Key;
  /** The user being removed from the organization */
  oldUser: User;
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * policy that applies to this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /**
   * policyContainerUpdate represents the bindings that will be applied
   * to the org policy container
   */
  policyContainerUpdate: Maybe<PolicyContainerUpdate>;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /** Policies the user is being added to */
  updatedOrgPolicies: Array<PolicyUpdate>;
  /** Vault policies the user is being added to */
  updatedVaultPolicies: Array<VaultPolicyUpdate>;
  /**
   * vaultPolicyContainerUpdate represents the bindings that will be applied
   * to the vault policy container
   */
  vaultPolicyContainerUpdate: Maybe<VaultPolicyContainerUpdate>;
};


/** ReplaceUserOperation represents an operation where a user is replaced by another one */
export type ReplaceUserOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** ReplaceUserOperation represents an operation where a user is replaced by another one */
export type ReplaceUserOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

export type Report = Node & {
  __typename: 'Report';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['String']['output'];
  createdByPubKeyID: Scalars['String']['output'];
  createdByUser: Actor;
  dateRangeEnd: Maybe<Scalars['Time']['output']>;
  dateRangeStart: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  /** Indicates if the report was created by a staff user */
  isCreatedByStaff: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organizationID: Scalars['String']['output'];
  reportFiles: ReportFileConnection;
  reportFilters: ReportDataFilterConnection;
  reportSchedules: Maybe<ReportSchedule>;
  reportStatus: ReportReportStatus;
  type: Scalars['String']['output'];
  typeDisplayName: Scalars['String']['output'];
  /** Format: ±[hh]:[mm] where -12:00 <= ±[hh]:[mm] <= +14:00 */
  utcOffset: Scalars['String']['output'];
};


export type ReportreportFilesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReportFileWhereInput>;
};


export type ReportreportFiltersArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReportDataFilterWhereInput>;
};

/** A connection to a list of items. */
export type ReportConnection = {
  __typename: 'ReportConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ReportEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type ReportDataFilter = Node & {
  __typename: 'ReportDataFilter';
  fieldDisplayName: Scalars['String']['output'];
  fieldName: Scalars['String']['output'];
  filterType: ReportDataFilterFilterType;
  id: Scalars['ID']['output'];
  reportFilterValues: ReportDataFilterValueConnection;
  reports: Report;
};


export type ReportDataFilterreportFilterValuesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReportDataFilterValueWhereInput>;
};

/** A connection to a list of items. */
export type ReportDataFilterConnection = {
  __typename: 'ReportDataFilterConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ReportDataFilterEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReportDataFilterEdge = {
  __typename: 'ReportDataFilterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ReportDataFilter>;
};

/** ReportDataFilterFilterType is enum for the field filter_type */
export enum ReportDataFilterFilterType {
  FILTER_TYPE_DATE_POINT = 'FILTER_TYPE_DATE_POINT',
  FILTER_TYPE_DATE_RANGE = 'FILTER_TYPE_DATE_RANGE',
  FILTER_TYPE_MULTI_SELECT = 'FILTER_TYPE_MULTI_SELECT',
  FILTER_TYPE_SINGLE_SELECT = 'FILTER_TYPE_SINGLE_SELECT',
  FILTER_TYPE_UNSPECIFIED = 'FILTER_TYPE_UNSPECIFIED'
}

export type ReportDataFilterValue = Node & {
  __typename: 'ReportDataFilterValue';
  displayValue: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  reportFilters: ReportDataFilter;
  value: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type ReportDataFilterValueConnection = {
  __typename: 'ReportDataFilterValueConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ReportDataFilterValueEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReportDataFilterValueEdge = {
  __typename: 'ReportDataFilterValueEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ReportDataFilterValue>;
};

/**
 * ReportDataFilterValueWhereInput is used for filtering ReportDataFilterValue objects.
 * Input was generated by ent.
 */
export type ReportDataFilterValueWhereInput = {
  and?: InputMaybe<Array<ReportDataFilterValueWhereInput>>;
  /** display_value field predicates */
  displayValue?: InputMaybe<Scalars['String']['input']>;
  displayValueContains?: InputMaybe<Scalars['String']['input']>;
  displayValueContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayValueEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayValueGT?: InputMaybe<Scalars['String']['input']>;
  displayValueGTE?: InputMaybe<Scalars['String']['input']>;
  displayValueHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayValueHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayValueIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayValueLT?: InputMaybe<Scalars['String']['input']>;
  displayValueLTE?: InputMaybe<Scalars['String']['input']>;
  displayValueNEQ?: InputMaybe<Scalars['String']['input']>;
  displayValueNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** report_filters edge predicates */
  hasReportFilters?: InputMaybe<Scalars['Boolean']['input']>;
  hasReportFiltersWith?: InputMaybe<Array<ReportDataFilterWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ReportDataFilterValueWhereInput>;
  or?: InputMaybe<Array<ReportDataFilterValueWhereInput>>;
  /** value field predicates */
  value?: InputMaybe<Scalars['String']['input']>;
  valueContains?: InputMaybe<Scalars['String']['input']>;
  valueContainsFold?: InputMaybe<Scalars['String']['input']>;
  valueEqualFold?: InputMaybe<Scalars['String']['input']>;
  valueGT?: InputMaybe<Scalars['String']['input']>;
  valueGTE?: InputMaybe<Scalars['String']['input']>;
  valueHasPrefix?: InputMaybe<Scalars['String']['input']>;
  valueHasSuffix?: InputMaybe<Scalars['String']['input']>;
  valueIn?: InputMaybe<Array<Scalars['String']['input']>>;
  valueLT?: InputMaybe<Scalars['String']['input']>;
  valueLTE?: InputMaybe<Scalars['String']['input']>;
  valueNEQ?: InputMaybe<Scalars['String']['input']>;
  valueNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

/**
 * ReportDataFilterWhereInput is used for filtering ReportDataFilter objects.
 * Input was generated by ent.
 */
export type ReportDataFilterWhereInput = {
  and?: InputMaybe<Array<ReportDataFilterWhereInput>>;
  /** field_display_name field predicates */
  fieldDisplayName?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameContains?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameGT?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameGTE?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  fieldDisplayNameLT?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameLTE?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** field_name field predicates */
  fieldName?: InputMaybe<Scalars['String']['input']>;
  fieldNameContains?: InputMaybe<Scalars['String']['input']>;
  fieldNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  fieldNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  fieldNameGT?: InputMaybe<Scalars['String']['input']>;
  fieldNameGTE?: InputMaybe<Scalars['String']['input']>;
  fieldNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  fieldNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  fieldNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  fieldNameLT?: InputMaybe<Scalars['String']['input']>;
  fieldNameLTE?: InputMaybe<Scalars['String']['input']>;
  fieldNameNEQ?: InputMaybe<Scalars['String']['input']>;
  fieldNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** filter_type field predicates */
  filterType?: InputMaybe<ReportDataFilterFilterType>;
  filterTypeIn?: InputMaybe<Array<ReportDataFilterFilterType>>;
  filterTypeNEQ?: InputMaybe<ReportDataFilterFilterType>;
  filterTypeNotIn?: InputMaybe<Array<ReportDataFilterFilterType>>;
  /** report_filter_values edge predicates */
  hasReportFilterValues?: InputMaybe<Scalars['Boolean']['input']>;
  hasReportFilterValuesWith?: InputMaybe<Array<ReportDataFilterValueWhereInput>>;
  /** reports edge predicates */
  hasReports?: InputMaybe<Scalars['Boolean']['input']>;
  hasReportsWith?: InputMaybe<Array<ReportWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ReportDataFilterWhereInput>;
  or?: InputMaybe<Array<ReportDataFilterWhereInput>>;
};

/** An edge in a connection. */
export type ReportEdge = {
  __typename: 'ReportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Report>;
};

export type ReportFile = Node & {
  __typename: 'ReportFile';
  createdAt: Scalars['Time']['output'];
  fileFormat: ReportFileFileFormat;
  /** The name of the reports object within the reports GCS bucket */
  fileURL: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  reports: Report;
};

/** A connection to a list of items. */
export type ReportFileConnection = {
  __typename: 'ReportFileConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ReportFileEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReportFileEdge = {
  __typename: 'ReportFileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ReportFile>;
};

/** ReportFileFileFormat is enum for the field file_format */
export enum ReportFileFileFormat {
  FILE_FORMAT_CSV = 'FILE_FORMAT_CSV',
  FILE_FORMAT_UNSPECIFIED = 'FILE_FORMAT_UNSPECIFIED'
}

/**
 * ReportFileWhereInput is used for filtering ReportFile objects.
 * Input was generated by ent.
 */
export type ReportFileWhereInput = {
  and?: InputMaybe<Array<ReportFileWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** file_format field predicates */
  fileFormat?: InputMaybe<ReportFileFileFormat>;
  fileFormatIn?: InputMaybe<Array<ReportFileFileFormat>>;
  fileFormatNEQ?: InputMaybe<ReportFileFileFormat>;
  fileFormatNotIn?: InputMaybe<Array<ReportFileFileFormat>>;
  /** file_url field predicates */
  fileURL?: InputMaybe<Scalars['String']['input']>;
  fileURLContains?: InputMaybe<Scalars['String']['input']>;
  fileURLContainsFold?: InputMaybe<Scalars['String']['input']>;
  fileURLEqualFold?: InputMaybe<Scalars['String']['input']>;
  fileURLGT?: InputMaybe<Scalars['String']['input']>;
  fileURLGTE?: InputMaybe<Scalars['String']['input']>;
  fileURLHasPrefix?: InputMaybe<Scalars['String']['input']>;
  fileURLHasSuffix?: InputMaybe<Scalars['String']['input']>;
  fileURLIn?: InputMaybe<Array<Scalars['String']['input']>>;
  fileURLLT?: InputMaybe<Scalars['String']['input']>;
  fileURLLTE?: InputMaybe<Scalars['String']['input']>;
  fileURLNEQ?: InputMaybe<Scalars['String']['input']>;
  fileURLNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** reports edge predicates */
  hasReports?: InputMaybe<Scalars['Boolean']['input']>;
  hasReportsWith?: InputMaybe<Array<ReportWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ReportFileWhereInput>;
  or?: InputMaybe<Array<ReportFileWhereInput>>;
};

export type ReportFilterConfig = {
  __typename: 'ReportFilterConfig';
  displayName: Scalars['String']['output'];
  fieldName: Scalars['String']['output'];
  filterType: ReportDataFilterFilterType;
};

export type ReportFilterConfigValue = {
  __typename: 'ReportFilterConfigValue';
  displayValue: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ReportFilterInput = {
  fieldName: Scalars['String']['input'];
  filterValues: Array<ReportFilterValueInput>;
};

export type ReportFilterValueInput = {
  displayValue: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Ordering options for Report connections */
export type ReportOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Reports. */
  field: ReportOrderField;
};

/** Properties by which Report connections can be ordered. */
export enum ReportOrderField {
  CREATED_AT = 'CREATED_AT'
}

/** ReportReportStatus is enum for the field report_status */
export enum ReportReportStatus {
  REPORT_STATUS_FAILED = 'REPORT_STATUS_FAILED',
  REPORT_STATUS_GENERATED = 'REPORT_STATUS_GENERATED',
  REPORT_STATUS_GENERATING_FILE = 'REPORT_STATUS_GENERATING_FILE',
  REPORT_STATUS_PENDING = 'REPORT_STATUS_PENDING',
  REPORT_STATUS_STARTED = 'REPORT_STATUS_STARTED',
  REPORT_STATUS_UNSPECIFIED = 'REPORT_STATUS_UNSPECIFIED'
}

export type ReportSchedule = Node & {
  __typename: 'ReportSchedule';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['String']['output'];
  createdByPubKeyID: Scalars['String']['output'];
  createdByUser: Actor;
  /** 1-31. Use 0 to indicate null. Must be set to other than 0 when schedule is not daily. For non-daily reports, represents the day of week or month on which the report will be triggered */
  daysAfterNonDailyScheduleTypeToTrigger: Scalars['Int']['output'];
  enabled: Maybe<Scalars['Boolean']['output']>;
  fileFormats: ReportScheduleFileFormatsConnection;
  /** 0-23 */
  hourOfDay: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** Indicates if the schedule was created by a staff user */
  isCreatedByStaff: Scalars['Boolean']['output'];
  /** Indicates if the schedule was updated by a staff user */
  isUpdatedByStaff: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organizationID: Scalars['String']['output'];
  reportScheduleDataFilters: ReportScheduleDataFilterConnection;
  reportScheduleMainFilter: Maybe<ReportScheduleMainFilter>;
  reportScheduleSftpDestinations: ReportScheduleSftpDestinationConnection;
  reportType: Scalars['String']['output'];
  reportTypeDisplayName: Scalars['String']['output'];
  scheduleReports: ReportConnection;
  scheduleType: ReportScheduleScheduleType;
  updatedAt: Maybe<Scalars['Time']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
  updatedByUser: Actor;
};


export type ReportSchedulefileFormatsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReportScheduleFileFormatsWhereInput>;
};


export type ReportSchedulereportScheduleDataFiltersArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReportScheduleDataFilterWhereInput>;
};


export type ReportSchedulereportScheduleSftpDestinationsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReportScheduleSftpDestinationOrder>;
  where?: InputMaybe<ReportScheduleSftpDestinationWhereInput>;
};


export type ReportSchedulescheduleReportsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReportOrder>;
  where?: InputMaybe<ReportWhereInput>;
};

/** A connection to a list of items. */
export type ReportScheduleConnection = {
  __typename: 'ReportScheduleConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ReportScheduleEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type ReportScheduleDataFilter = Node & {
  __typename: 'ReportScheduleDataFilter';
  fieldDisplayName: Scalars['String']['output'];
  fieldName: Scalars['String']['output'];
  filterType: ReportScheduleDataFilterFilterType;
  id: Scalars['ID']['output'];
  reportSchedule: ReportSchedule;
  reportScheduleDataFilterValues: ReportScheduleDataFilterValueConnection;
};


export type ReportScheduleDataFilterreportScheduleDataFilterValuesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReportScheduleDataFilterValueWhereInput>;
};

/** A connection to a list of items. */
export type ReportScheduleDataFilterConnection = {
  __typename: 'ReportScheduleDataFilterConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ReportScheduleDataFilterEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReportScheduleDataFilterEdge = {
  __typename: 'ReportScheduleDataFilterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ReportScheduleDataFilter>;
};

/** ReportScheduleDataFilterFilterType is enum for the field filter_type */
export enum ReportScheduleDataFilterFilterType {
  FILTER_TYPE_DATE_POINT = 'FILTER_TYPE_DATE_POINT',
  FILTER_TYPE_DATE_RANGE = 'FILTER_TYPE_DATE_RANGE',
  FILTER_TYPE_MULTI_SELECT = 'FILTER_TYPE_MULTI_SELECT',
  FILTER_TYPE_SINGLE_SELECT = 'FILTER_TYPE_SINGLE_SELECT',
  FILTER_TYPE_UNSPECIFIED = 'FILTER_TYPE_UNSPECIFIED'
}

export type ReportScheduleDataFilterValue = Node & {
  __typename: 'ReportScheduleDataFilterValue';
  displayValue: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  reportScheduleDataFilter: ReportScheduleDataFilter;
  value: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type ReportScheduleDataFilterValueConnection = {
  __typename: 'ReportScheduleDataFilterValueConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ReportScheduleDataFilterValueEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReportScheduleDataFilterValueEdge = {
  __typename: 'ReportScheduleDataFilterValueEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ReportScheduleDataFilterValue>;
};

/**
 * ReportScheduleDataFilterValueWhereInput is used for filtering ReportScheduleDataFilterValue objects.
 * Input was generated by ent.
 */
export type ReportScheduleDataFilterValueWhereInput = {
  and?: InputMaybe<Array<ReportScheduleDataFilterValueWhereInput>>;
  /** display_value field predicates */
  displayValue?: InputMaybe<Scalars['String']['input']>;
  displayValueContains?: InputMaybe<Scalars['String']['input']>;
  displayValueContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayValueEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayValueGT?: InputMaybe<Scalars['String']['input']>;
  displayValueGTE?: InputMaybe<Scalars['String']['input']>;
  displayValueHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayValueHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayValueIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayValueLT?: InputMaybe<Scalars['String']['input']>;
  displayValueLTE?: InputMaybe<Scalars['String']['input']>;
  displayValueNEQ?: InputMaybe<Scalars['String']['input']>;
  displayValueNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** report_schedule_data_filter edge predicates */
  hasReportScheduleDataFilters?: InputMaybe<Scalars['Boolean']['input']>;
  hasReportScheduleDataFiltersWith?: InputMaybe<Array<ReportScheduleDataFilterWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ReportScheduleDataFilterValueWhereInput>;
  or?: InputMaybe<Array<ReportScheduleDataFilterValueWhereInput>>;
  /** value field predicates */
  value?: InputMaybe<Scalars['String']['input']>;
  valueContains?: InputMaybe<Scalars['String']['input']>;
  valueContainsFold?: InputMaybe<Scalars['String']['input']>;
  valueEqualFold?: InputMaybe<Scalars['String']['input']>;
  valueGT?: InputMaybe<Scalars['String']['input']>;
  valueGTE?: InputMaybe<Scalars['String']['input']>;
  valueHasPrefix?: InputMaybe<Scalars['String']['input']>;
  valueHasSuffix?: InputMaybe<Scalars['String']['input']>;
  valueIn?: InputMaybe<Array<Scalars['String']['input']>>;
  valueLT?: InputMaybe<Scalars['String']['input']>;
  valueLTE?: InputMaybe<Scalars['String']['input']>;
  valueNEQ?: InputMaybe<Scalars['String']['input']>;
  valueNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

/**
 * ReportScheduleDataFilterWhereInput is used for filtering ReportScheduleDataFilter objects.
 * Input was generated by ent.
 */
export type ReportScheduleDataFilterWhereInput = {
  and?: InputMaybe<Array<ReportScheduleDataFilterWhereInput>>;
  /** field_display_name field predicates */
  fieldDisplayName?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameContains?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameGT?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameGTE?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  fieldDisplayNameLT?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameLTE?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** field_name field predicates */
  fieldName?: InputMaybe<Scalars['String']['input']>;
  fieldNameContains?: InputMaybe<Scalars['String']['input']>;
  fieldNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  fieldNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  fieldNameGT?: InputMaybe<Scalars['String']['input']>;
  fieldNameGTE?: InputMaybe<Scalars['String']['input']>;
  fieldNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  fieldNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  fieldNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  fieldNameLT?: InputMaybe<Scalars['String']['input']>;
  fieldNameLTE?: InputMaybe<Scalars['String']['input']>;
  fieldNameNEQ?: InputMaybe<Scalars['String']['input']>;
  fieldNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** filter_type field predicates */
  filterType?: InputMaybe<ReportScheduleDataFilterFilterType>;
  filterTypeIn?: InputMaybe<Array<ReportScheduleDataFilterFilterType>>;
  filterTypeNEQ?: InputMaybe<ReportScheduleDataFilterFilterType>;
  filterTypeNotIn?: InputMaybe<Array<ReportScheduleDataFilterFilterType>>;
  /** report_schedule_data_filter_values edge predicates */
  hasReportScheduleDataFilterValues?: InputMaybe<Scalars['Boolean']['input']>;
  hasReportScheduleDataFilterValuesWith?: InputMaybe<Array<ReportScheduleDataFilterValueWhereInput>>;
  /** report_schedule edge predicates */
  hasReportSchedules?: InputMaybe<Scalars['Boolean']['input']>;
  hasReportSchedulesWith?: InputMaybe<Array<ReportScheduleWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ReportScheduleDataFilterWhereInput>;
  or?: InputMaybe<Array<ReportScheduleDataFilterWhereInput>>;
};

/** An edge in a connection. */
export type ReportScheduleEdge = {
  __typename: 'ReportScheduleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ReportSchedule>;
};

export type ReportScheduleFileFormats = Node & {
  __typename: 'ReportScheduleFileFormats';
  fileFormat: ReportScheduleFileFormatsFileFormat;
  id: Scalars['ID']['output'];
  reportSchedule: ReportSchedule;
};

/** A connection to a list of items. */
export type ReportScheduleFileFormatsConnection = {
  __typename: 'ReportScheduleFileFormatsConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ReportScheduleFileFormatsEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReportScheduleFileFormatsEdge = {
  __typename: 'ReportScheduleFileFormatsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ReportScheduleFileFormats>;
};

/** ReportScheduleFileFormatsFileFormat is enum for the field file_format */
export enum ReportScheduleFileFormatsFileFormat {
  FILE_FORMAT_CSV = 'FILE_FORMAT_CSV',
  FILE_FORMAT_UNSPECIFIED = 'FILE_FORMAT_UNSPECIFIED'
}

/**
 * ReportScheduleFileFormatsWhereInput is used for filtering ReportScheduleFileFormats objects.
 * Input was generated by ent.
 */
export type ReportScheduleFileFormatsWhereInput = {
  and?: InputMaybe<Array<ReportScheduleFileFormatsWhereInput>>;
  /** file_format field predicates */
  fileFormat?: InputMaybe<ReportScheduleFileFormatsFileFormat>;
  fileFormatIn?: InputMaybe<Array<ReportScheduleFileFormatsFileFormat>>;
  fileFormatNEQ?: InputMaybe<ReportScheduleFileFormatsFileFormat>;
  fileFormatNotIn?: InputMaybe<Array<ReportScheduleFileFormatsFileFormat>>;
  /** report_schedule edge predicates */
  hasReportSchedules?: InputMaybe<Scalars['Boolean']['input']>;
  hasReportSchedulesWith?: InputMaybe<Array<ReportScheduleWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ReportScheduleFileFormatsWhereInput>;
  or?: InputMaybe<Array<ReportScheduleFileFormatsWhereInput>>;
};

export type ReportScheduleMainFilter = Node & {
  __typename: 'ReportScheduleMainFilter';
  fieldDisplayName: Scalars['String']['output'];
  fieldName: Scalars['String']['output'];
  filterType: ReportScheduleMainFilterFilterType;
  id: Scalars['ID']['output'];
  reportSchedule: ReportSchedule;
  scheduleFilterPoint: ReportScheduleMainFilterScheduleFilterPoint;
  scheduleFilterRange: ReportScheduleMainFilterScheduleFilterRange;
};

/** A connection to a list of items. */
export type ReportScheduleMainFilterConnection = {
  __typename: 'ReportScheduleMainFilterConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ReportScheduleMainFilterEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReportScheduleMainFilterEdge = {
  __typename: 'ReportScheduleMainFilterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ReportScheduleMainFilter>;
};

/** ReportScheduleMainFilterFilterType is enum for the field filter_type */
export enum ReportScheduleMainFilterFilterType {
  FILTER_TYPE_DATE_POINT = 'FILTER_TYPE_DATE_POINT',
  FILTER_TYPE_DATE_RANGE = 'FILTER_TYPE_DATE_RANGE',
  FILTER_TYPE_MULTI_SELECT = 'FILTER_TYPE_MULTI_SELECT',
  FILTER_TYPE_SINGLE_SELECT = 'FILTER_TYPE_SINGLE_SELECT',
  FILTER_TYPE_UNSPECIFIED = 'FILTER_TYPE_UNSPECIFIED'
}

/** ReportScheduleMainFilterScheduleFilterPoint is enum for the field schedule_filter_point */
export enum ReportScheduleMainFilterScheduleFilterPoint {
  POINT_END_OF_LAST_MONTH = 'POINT_END_OF_LAST_MONTH',
  POINT_END_OF_LAST_QUARTER = 'POINT_END_OF_LAST_QUARTER',
  POINT_END_OF_LAST_WEEK = 'POINT_END_OF_LAST_WEEK',
  POINT_END_OF_LAST_YEAR = 'POINT_END_OF_LAST_YEAR',
  POINT_UNSPECIFIED = 'POINT_UNSPECIFIED',
  POINT_YESTERDAY = 'POINT_YESTERDAY'
}

/** ReportScheduleMainFilterScheduleFilterRange is enum for the field schedule_filter_range */
export enum ReportScheduleMainFilterScheduleFilterRange {
  RANGE_LAST_MONTH = 'RANGE_LAST_MONTH',
  RANGE_LAST_QUARTER = 'RANGE_LAST_QUARTER',
  RANGE_LAST_WEEK = 'RANGE_LAST_WEEK',
  RANGE_LAST_YEAR = 'RANGE_LAST_YEAR',
  RANGE_MONTH_TO_DATE = 'RANGE_MONTH_TO_DATE',
  RANGE_QUARTER_TO_DATE = 'RANGE_QUARTER_TO_DATE',
  RANGE_UNSPECIFIED = 'RANGE_UNSPECIFIED',
  RANGE_WEEK_TO_DATE = 'RANGE_WEEK_TO_DATE',
  RANGE_YEAR_TO_DATE = 'RANGE_YEAR_TO_DATE',
  RANGE_YESTERDAY = 'RANGE_YESTERDAY'
}

/**
 * ReportScheduleMainFilterWhereInput is used for filtering ReportScheduleMainFilter objects.
 * Input was generated by ent.
 */
export type ReportScheduleMainFilterWhereInput = {
  and?: InputMaybe<Array<ReportScheduleMainFilterWhereInput>>;
  /** field_display_name field predicates */
  fieldDisplayName?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameContains?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameGT?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameGTE?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  fieldDisplayNameLT?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameLTE?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  fieldDisplayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** field_name field predicates */
  fieldName?: InputMaybe<Scalars['String']['input']>;
  fieldNameContains?: InputMaybe<Scalars['String']['input']>;
  fieldNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  fieldNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  fieldNameGT?: InputMaybe<Scalars['String']['input']>;
  fieldNameGTE?: InputMaybe<Scalars['String']['input']>;
  fieldNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  fieldNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  fieldNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  fieldNameLT?: InputMaybe<Scalars['String']['input']>;
  fieldNameLTE?: InputMaybe<Scalars['String']['input']>;
  fieldNameNEQ?: InputMaybe<Scalars['String']['input']>;
  fieldNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** filter_type field predicates */
  filterType?: InputMaybe<ReportScheduleMainFilterFilterType>;
  filterTypeIn?: InputMaybe<Array<ReportScheduleMainFilterFilterType>>;
  filterTypeNEQ?: InputMaybe<ReportScheduleMainFilterFilterType>;
  filterTypeNotIn?: InputMaybe<Array<ReportScheduleMainFilterFilterType>>;
  /** report_schedule edge predicates */
  hasReportSchedules?: InputMaybe<Scalars['Boolean']['input']>;
  hasReportSchedulesWith?: InputMaybe<Array<ReportScheduleWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ReportScheduleMainFilterWhereInput>;
  or?: InputMaybe<Array<ReportScheduleMainFilterWhereInput>>;
  /** schedule_filter_point field predicates */
  scheduleFilterPoint?: InputMaybe<ReportScheduleMainFilterScheduleFilterPoint>;
  scheduleFilterPointIn?: InputMaybe<Array<ReportScheduleMainFilterScheduleFilterPoint>>;
  scheduleFilterPointNEQ?: InputMaybe<ReportScheduleMainFilterScheduleFilterPoint>;
  scheduleFilterPointNotIn?: InputMaybe<Array<ReportScheduleMainFilterScheduleFilterPoint>>;
  /** schedule_filter_range field predicates */
  scheduleFilterRange?: InputMaybe<ReportScheduleMainFilterScheduleFilterRange>;
  scheduleFilterRangeIn?: InputMaybe<Array<ReportScheduleMainFilterScheduleFilterRange>>;
  scheduleFilterRangeNEQ?: InputMaybe<ReportScheduleMainFilterScheduleFilterRange>;
  scheduleFilterRangeNotIn?: InputMaybe<Array<ReportScheduleMainFilterScheduleFilterRange>>;
};

/** Ordering options for ReportSchedule connections */
export type ReportScheduleOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ReportSchedules. */
  field: ReportScheduleOrderField;
};

/** Properties by which ReportSchedule connections can be ordered. */
export enum ReportScheduleOrderField {
  CREATED_AT = 'CREATED_AT'
}

/** ReportScheduleScheduleType is enum for the field schedule_type */
export enum ReportScheduleScheduleType {
  SCHEDULE_ANNUALLY = 'SCHEDULE_ANNUALLY',
  SCHEDULE_DAILY = 'SCHEDULE_DAILY',
  SCHEDULE_MONTHLY = 'SCHEDULE_MONTHLY',
  SCHEDULE_QUARTERLY = 'SCHEDULE_QUARTERLY',
  SCHEDULE_UNSPECIFIED = 'SCHEDULE_UNSPECIFIED',
  SCHEDULE_WEEKLY = 'SCHEDULE_WEEKLY'
}

export type ReportScheduleSftpDestination = Node & {
  __typename: 'ReportScheduleSftpDestination';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['String']['output'];
  enabled: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  reportSchedule: ReportSchedule;
  sftpDestination: ReportScheduleSftpDestinationSftpDestination;
  updatedAt: Maybe<Scalars['Time']['output']>;
  updatedBy: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type ReportScheduleSftpDestinationConnection = {
  __typename: 'ReportScheduleSftpDestinationConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ReportScheduleSftpDestinationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReportScheduleSftpDestinationEdge = {
  __typename: 'ReportScheduleSftpDestinationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ReportScheduleSftpDestination>;
};

/** Ordering options for ReportScheduleSftpDestination connections */
export type ReportScheduleSftpDestinationOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ReportScheduleSftpDestinations. */
  field: ReportScheduleSftpDestinationOrderField;
};

/** Properties by which ReportScheduleSftpDestination connections can be ordered. */
export enum ReportScheduleSftpDestinationOrderField {
  CREATED_AT = 'CREATED_AT'
}

/** ReportScheduleSftpDestinationSftpDestination is enum for the field sftp_destination */
export enum ReportScheduleSftpDestinationSftpDestination {
  SFTP_DESTINATION_BNY = 'SFTP_DESTINATION_BNY',
  SFTP_DESTINATION_UNSPECIFIED = 'SFTP_DESTINATION_UNSPECIFIED'
}

/**
 * ReportScheduleSftpDestinationWhereInput is used for filtering ReportScheduleSftpDestination objects.
 * Input was generated by ent.
 */
export type ReportScheduleSftpDestinationWhereInput = {
  and?: InputMaybe<Array<ReportScheduleSftpDestinationWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** enabled field predicates */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  enabledIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  enabledNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  enabledNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** report_schedule edge predicates */
  hasReportSchedules?: InputMaybe<Scalars['Boolean']['input']>;
  hasReportSchedulesWith?: InputMaybe<Array<ReportScheduleWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ReportScheduleSftpDestinationWhereInput>;
  or?: InputMaybe<Array<ReportScheduleSftpDestinationWhereInput>>;
  /** sftp_destination field predicates */
  sftpDestination?: InputMaybe<ReportScheduleSftpDestinationSftpDestination>;
  sftpDestinationIn?: InputMaybe<Array<ReportScheduleSftpDestinationSftpDestination>>;
  sftpDestinationNEQ?: InputMaybe<ReportScheduleSftpDestinationSftpDestination>;
  sftpDestinationNotIn?: InputMaybe<Array<ReportScheduleSftpDestinationSftpDestination>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * ReportScheduleWhereInput is used for filtering ReportSchedule objects.
 * Input was generated by ent.
 */
export type ReportScheduleWhereInput = {
  and?: InputMaybe<Array<ReportScheduleWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_by_pub_key_id field predicates */
  createdByPubKeyID?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDContains?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDGT?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDGTE?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByPubKeyIDLT?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDLTE?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** days_after_non_daily_schedule_type_to_trigger field predicates */
  daysAfterNonDailyScheduleTypeToTrigger?: InputMaybe<Scalars['Int']['input']>;
  daysAfterNonDailyScheduleTypeToTriggerGT?: InputMaybe<Scalars['Int']['input']>;
  daysAfterNonDailyScheduleTypeToTriggerGTE?: InputMaybe<Scalars['Int']['input']>;
  daysAfterNonDailyScheduleTypeToTriggerIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  daysAfterNonDailyScheduleTypeToTriggerLT?: InputMaybe<Scalars['Int']['input']>;
  daysAfterNonDailyScheduleTypeToTriggerLTE?: InputMaybe<Scalars['Int']['input']>;
  daysAfterNonDailyScheduleTypeToTriggerNEQ?: InputMaybe<Scalars['Int']['input']>;
  daysAfterNonDailyScheduleTypeToTriggerNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** enabled field predicates */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  enabledIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  enabledNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  enabledNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** file_formats edge predicates */
  hasFileFormats?: InputMaybe<Scalars['Boolean']['input']>;
  hasFileFormatsWith?: InputMaybe<Array<ReportScheduleFileFormatsWhereInput>>;
  /** report_schedule_data_filters edge predicates */
  hasReportScheduleDataFilters?: InputMaybe<Scalars['Boolean']['input']>;
  hasReportScheduleDataFiltersWith?: InputMaybe<Array<ReportScheduleDataFilterWhereInput>>;
  /** report_schedule_main_filter edge predicates */
  hasReportScheduleMainFilters?: InputMaybe<Scalars['Boolean']['input']>;
  hasReportScheduleMainFiltersWith?: InputMaybe<Array<ReportScheduleMainFilterWhereInput>>;
  /** report_schedule_sftp_destinations edge predicates */
  hasReportScheduleSftpDestinations?: InputMaybe<Scalars['Boolean']['input']>;
  hasReportScheduleSftpDestinationsWith?: InputMaybe<Array<ReportScheduleSftpDestinationWhereInput>>;
  /** schedule_reports edge predicates */
  hasScheduleReports?: InputMaybe<Scalars['Boolean']['input']>;
  hasScheduleReportsWith?: InputMaybe<Array<ReportWhereInput>>;
  /** hour_of_day field predicates */
  hourOfDay?: InputMaybe<Scalars['Int']['input']>;
  hourOfDayGT?: InputMaybe<Scalars['Int']['input']>;
  hourOfDayGTE?: InputMaybe<Scalars['Int']['input']>;
  hourOfDayIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  hourOfDayLT?: InputMaybe<Scalars['Int']['input']>;
  hourOfDayLTE?: InputMaybe<Scalars['Int']['input']>;
  hourOfDayNEQ?: InputMaybe<Scalars['Int']['input']>;
  hourOfDayNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_created_by_staff field predicates */
  isCreatedByStaff?: InputMaybe<Scalars['Boolean']['input']>;
  isCreatedByStaffNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_updated_by_staff field predicates */
  isUpdatedByStaff?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdatedByStaffNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<ReportScheduleWhereInput>;
  or?: InputMaybe<Array<ReportScheduleWhereInput>>;
  /** organization_id field predicates */
  organizationID?: InputMaybe<Scalars['String']['input']>;
  organizationIDContains?: InputMaybe<Scalars['String']['input']>;
  organizationIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  organizationIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  organizationIDGT?: InputMaybe<Scalars['String']['input']>;
  organizationIDGTE?: InputMaybe<Scalars['String']['input']>;
  organizationIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  organizationIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  organizationIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationIDLT?: InputMaybe<Scalars['String']['input']>;
  organizationIDLTE?: InputMaybe<Scalars['String']['input']>;
  organizationIDNEQ?: InputMaybe<Scalars['String']['input']>;
  organizationIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** report_type field predicates */
  reportType?: InputMaybe<Scalars['String']['input']>;
  reportTypeContains?: InputMaybe<Scalars['String']['input']>;
  reportTypeContainsFold?: InputMaybe<Scalars['String']['input']>;
  reportTypeEqualFold?: InputMaybe<Scalars['String']['input']>;
  reportTypeGT?: InputMaybe<Scalars['String']['input']>;
  reportTypeGTE?: InputMaybe<Scalars['String']['input']>;
  reportTypeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  reportTypeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  reportTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  reportTypeLT?: InputMaybe<Scalars['String']['input']>;
  reportTypeLTE?: InputMaybe<Scalars['String']['input']>;
  reportTypeNEQ?: InputMaybe<Scalars['String']['input']>;
  reportTypeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** schedule_type field predicates */
  scheduleType?: InputMaybe<ReportScheduleScheduleType>;
  scheduleTypeIn?: InputMaybe<Array<ReportScheduleScheduleType>>;
  scheduleTypeNEQ?: InputMaybe<ReportScheduleScheduleType>;
  scheduleTypeNotIn?: InputMaybe<Array<ReportScheduleScheduleType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  updatedByContains?: InputMaybe<Scalars['String']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  updatedByGT?: InputMaybe<Scalars['String']['input']>;
  updatedByGTE?: InputMaybe<Scalars['String']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedByLT?: InputMaybe<Scalars['String']['input']>;
  updatedByLTE?: InputMaybe<Scalars['String']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['String']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedByNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReportType = {
  __typename: 'ReportType';
  description: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ReportTypeConfig = {
  __typename: 'ReportTypeConfig';
  availableFilters: Array<Maybe<ReportFilterConfig>>;
  reportType: Scalars['String']['output'];
};

/**
 * ReportWhereInput is used for filtering Report objects.
 * Input was generated by ent.
 */
export type ReportWhereInput = {
  and?: InputMaybe<Array<ReportWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByGT?: InputMaybe<Scalars['String']['input']>;
  createdByGTE?: InputMaybe<Scalars['String']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByLT?: InputMaybe<Scalars['String']['input']>;
  createdByLTE?: InputMaybe<Scalars['String']['input']>;
  createdByNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_by_pub_key_id field predicates */
  createdByPubKeyID?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDContains?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDGT?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDGTE?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByPubKeyIDLT?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDLTE?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDNEQ?: InputMaybe<Scalars['String']['input']>;
  createdByPubKeyIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** date_range_end field predicates */
  dateRangeEnd?: InputMaybe<Scalars['Time']['input']>;
  dateRangeEndGT?: InputMaybe<Scalars['Time']['input']>;
  dateRangeEndGTE?: InputMaybe<Scalars['Time']['input']>;
  dateRangeEndIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  dateRangeEndIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  dateRangeEndLT?: InputMaybe<Scalars['Time']['input']>;
  dateRangeEndLTE?: InputMaybe<Scalars['Time']['input']>;
  dateRangeEndNEQ?: InputMaybe<Scalars['Time']['input']>;
  dateRangeEndNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  dateRangeEndNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** date_range_start field predicates */
  dateRangeStart?: InputMaybe<Scalars['Time']['input']>;
  dateRangeStartGT?: InputMaybe<Scalars['Time']['input']>;
  dateRangeStartGTE?: InputMaybe<Scalars['Time']['input']>;
  dateRangeStartIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  dateRangeStartIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  dateRangeStartLT?: InputMaybe<Scalars['Time']['input']>;
  dateRangeStartLTE?: InputMaybe<Scalars['Time']['input']>;
  dateRangeStartNEQ?: InputMaybe<Scalars['Time']['input']>;
  dateRangeStartNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  dateRangeStartNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** report_files edge predicates */
  hasReportFiles?: InputMaybe<Scalars['Boolean']['input']>;
  hasReportFilesWith?: InputMaybe<Array<ReportFileWhereInput>>;
  /** report_filters edge predicates */
  hasReportFilters?: InputMaybe<Scalars['Boolean']['input']>;
  hasReportFiltersWith?: InputMaybe<Array<ReportDataFilterWhereInput>>;
  /** report_schedules edge predicates */
  hasReportSchedules?: InputMaybe<Scalars['Boolean']['input']>;
  hasReportSchedulesWith?: InputMaybe<Array<ReportScheduleWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_created_by_staff field predicates */
  isCreatedByStaff?: InputMaybe<Scalars['Boolean']['input']>;
  isCreatedByStaffNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<ReportWhereInput>;
  or?: InputMaybe<Array<ReportWhereInput>>;
  /** organization_id field predicates */
  organizationID?: InputMaybe<Scalars['String']['input']>;
  organizationIDContains?: InputMaybe<Scalars['String']['input']>;
  organizationIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  organizationIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  organizationIDGT?: InputMaybe<Scalars['String']['input']>;
  organizationIDGTE?: InputMaybe<Scalars['String']['input']>;
  organizationIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  organizationIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  organizationIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationIDLT?: InputMaybe<Scalars['String']['input']>;
  organizationIDLTE?: InputMaybe<Scalars['String']['input']>;
  organizationIDNEQ?: InputMaybe<Scalars['String']['input']>;
  organizationIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** report_status field predicates */
  reportStatus?: InputMaybe<ReportReportStatus>;
  reportStatusIn?: InputMaybe<Array<ReportReportStatus>>;
  reportStatusNEQ?: InputMaybe<ReportReportStatus>;
  reportStatusNotIn?: InputMaybe<Array<ReportReportStatus>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']['input']>;
  typeContains?: InputMaybe<Scalars['String']['input']>;
  typeContainsFold?: InputMaybe<Scalars['String']['input']>;
  /** type_display_name field predicates */
  typeDisplayName?: InputMaybe<Scalars['String']['input']>;
  typeDisplayNameContains?: InputMaybe<Scalars['String']['input']>;
  typeDisplayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  typeDisplayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeDisplayNameGT?: InputMaybe<Scalars['String']['input']>;
  typeDisplayNameGTE?: InputMaybe<Scalars['String']['input']>;
  typeDisplayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeDisplayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeDisplayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeDisplayNameLT?: InputMaybe<Scalars['String']['input']>;
  typeDisplayNameLTE?: InputMaybe<Scalars['String']['input']>;
  typeDisplayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  typeDisplayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeEqualFold?: InputMaybe<Scalars['String']['input']>;
  typeGT?: InputMaybe<Scalars['String']['input']>;
  typeGTE?: InputMaybe<Scalars['String']['input']>;
  typeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  typeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  typeLT?: InputMaybe<Scalars['String']['input']>;
  typeLTE?: InputMaybe<Scalars['String']['input']>;
  typeNEQ?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** utc_offset field predicates */
  utcOffset?: InputMaybe<Scalars['String']['input']>;
  utcOffsetContains?: InputMaybe<Scalars['String']['input']>;
  utcOffsetContainsFold?: InputMaybe<Scalars['String']['input']>;
  utcOffsetEqualFold?: InputMaybe<Scalars['String']['input']>;
  utcOffsetGT?: InputMaybe<Scalars['String']['input']>;
  utcOffsetGTE?: InputMaybe<Scalars['String']['input']>;
  utcOffsetHasPrefix?: InputMaybe<Scalars['String']['input']>;
  utcOffsetHasSuffix?: InputMaybe<Scalars['String']['input']>;
  utcOffsetIn?: InputMaybe<Array<Scalars['String']['input']>>;
  utcOffsetLT?: InputMaybe<Scalars['String']['input']>;
  utcOffsetLTE?: InputMaybe<Scalars['String']['input']>;
  utcOffsetNEQ?: InputMaybe<Scalars['String']['input']>;
  utcOffsetNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Identifies PII entries that must be provided */
export type RequiredInformation = {
  __typename: 'RequiredInformation';
  /** Description of the rejection */
  description: Scalars['String']['output'];
  /** The type of information required */
  informationType: IndividualInformationType;
  /** Human readable message regarding the previous rejection of this type */
  rejectionMessage: Maybe<Scalars['String']['output']>;
  /** The reason why this information is required */
  requirementReason: PIIRequirementReason;
};

/**
 * RequiredUsersQuorum is a soft constraint on required endorsements for a policy.
 *
 * These are different than quorums as they are not cryptographically protected.
 * During Risk Review, Anchorage Reviewers may execute an operation before
 * the minimum number of users within a required users quorum have endorsed it.
 *
 * RequiredUsersQuorum will likely be replaced with Quorum in the future.
 */
export type RequiredUsersQuorum = {
  __typename: 'RequiredUsersQuorum';
  /**
   * The number of users from 'users' that are required.
   * 'signaturesRequired' will always be <= len('users')
   */
  signaturesRequired: Scalars['Int']['output'];
  /** List of users whose endorsements are required to meet the virtual quorum. */
  users: Array<User>;
};


/**
 * RequiredUsersQuorum is a soft constraint on required endorsements for a policy.
 *
 * These are different than quorums as they are not cryptographically protected.
 * During Risk Review, Anchorage Reviewers may execute an operation before
 * the minimum number of users within a required users quorum have endorsed it.
 *
 * RequiredUsersQuorum will likely be replaced with Quorum in the future.
 */
export type RequiredUsersQuorumusersArgs = {
  sortField?: InputMaybe<UserSortField>;
};

/** ResetAllowedVaultsForDAppResponse */
export type ResetAllowedVaultsForDAppResponse = {
  __typename: 'ResetAllowedVaultsForDAppResponse';
  /** Was request successful */
  success: Scalars['Boolean']['output'];
};

/** Resource represents a resource (e.g. Organization, Vault) */
export type Resource = Organization | Vault;

/**
 * ResponseStatusCode is similar to HTTP response status code.
 * It indicates whether a request has been successfully received, completed, failed, etc.
 */
export enum ResponseStatusCode {
  /**
   * ACCEPTED means the request has been successfully received but not yet processed.
   * Note that the processing of the request could still fail.
   */
  ACCEPTED = 'ACCEPTED',
  /** OK means a request has been processed, to completion, successfully. */
  OK = 'OK'
}

/** Restriction represents a limitation on an entity */
export type Restriction = {
  __typename: 'Restriction';
  /** Additional fields for an account closure */
  closureDetail: Maybe<ClosureMetadata>;
  /** Indicates if the restriction is active */
  enabled: Scalars['Boolean']['output'];
  /** Indicates who last changed the restriction */
  lastModifiedBy: Scalars['String']['output'];
  /** Indicates why restriction was updated */
  reason: Scalars['String']['output'];
  /** Indicates the type of restriction */
  restrictionType: RestrictionType;
};

/** RestrictionEntityType represents the entity type for a restriction */
export enum RestrictionEntityType {
  /** Indicates entity is an account */
  ACCOUNT = 'ACCOUNT',
  /** Indicates entity is an affiliate */
  AFFILIATE = 'AFFILIATE'
}

/** Indicates the type of restriction */
export enum RestrictionType {
  /** Restriction controls account closure */
  CLOSE = 'CLOSE',
  /** Indicates the restriction controls withdrawal */
  FREEZE_WITHDRAWAL = 'FREEZE_WITHDRAWAL'
}

/** RevertJobOptions contains the options for the revert job run */
export type RevertJobOptions = {
  /** Allow reverting monthly (this will remove all monthly related checks) */
  allowMonthly: Scalars['Boolean']['input'];
};

/** Payload response for revokeAPIKeyWithErrors mutation. */
export type RevokeAPIKeyPayload = {
  __typename: 'RevokeAPIKeyPayload';
  /** State of the APIKey after the mutation. */
  apiKey: Maybe<APIKey>;
  /** Errors encountered while revoking API key. */
  revokeErrors: Array<ErrorMessage>;
};

/** Extra parameters for ripple */
export type RippleExtraParams = {
  __typename: 'RippleExtraParams';
  /** destination tag to use in transaction */
  destinationTag: Maybe<Scalars['String']['output']>;
};

/** Type of RiskAnalysis */
export enum RiskAnalysisType {
  /** Check Alerts */
  ALERT_CHECK = 'ALERT_CHECK',
  /** Beneficiary Counterparty Information */
  AML_BENEFICIARY_SCREENING = 'AML_BENEFICIARY_SCREENING',
  /** Originator Counterparty Information */
  AML_ORIGINATOR_SCREENING = 'AML_ORIGINATOR_SCREENING',
  /** Check Counterparty Information */
  COUNTERPARTY_CHECK = 'COUNTERPARTY_CHECK',
  /** High Risk Jurisdictions countries check */
  HRJ_CHECK = 'HRJ_CHECK',
  /** Screen Wallet */
  WALLET_SCREENING = 'WALLET_SCREENING'
}

/** Vendor for RiskAnalysis */
export enum RiskAnalysisVendor {
  /** Analysis from LexisNexis */
  LEXIS_NEXIS = 'LEXIS_NEXIS',
  /** Analysis from TRM */
  TRM = 'TRM'
}

/** Details on an individual approval group's approval status */
export type RiskApprovalGroup = {
  __typename: 'RiskApprovalGroup';
  /** A list of individual group members */
  members: Array<RiskApprovalGroupMember>;
  /** The name of the approver group */
  name: Scalars['String']['output'];
  /** The group's overall approval status, nil if no decisions were made */
  status: Maybe<OperationDecision>;
};

/** Details on an individual risk reviewer's approval status */
export type RiskApprovalGroupMember = {
  __typename: 'RiskApprovalGroupMember';
  /** Email address of the approver */
  email: Scalars['String']['output'];
  /** Timestamp in nanos of when the most recent decision was made, null for pending endorsements */
  lastDecisionTime: Maybe<Scalars['String']['output']>;
  /** Their current approval status, nil if no decisions were made */
  status: Maybe<OperationDecision>;
};

/** Risk user approval groups */
export enum RiskApprovalGroupType {
  /** Used when the user belongs to the Client Operations team */
  CLIENT_OPS = 'CLIENT_OPS',
  /** Used when the user belongs to the Client Operations that allows to direct edit kyc information */
  CLIENT_OPS_DIRECT_EDIT = 'CLIENT_OPS_DIRECT_EDIT',
  /** Used when the user belongs to the Compliance Hold approval group */
  COMPLIANCE_HOLD = 'COMPLIANCE_HOLD',
  /** Used when the user belongs to the Compliance Trust approval group */
  COMPLIANCE_TRUST = 'COMPLIANCE_TRUST',
  /** Used when the user belongs to the Hold approval group */
  HOLD = 'HOLD',
  /** Used when the user belongs to the Trading team */
  TRADING = 'TRADING',
  /** Used when the user belongs to the Trading Compliance team */
  TRADING_COMPLIANCE = 'TRADING_COMPLIANCE',
  /** Used when the user belongs to the Trading Limits approval group */
  TRADING_LIMITS = 'TRADING_LIMITS',
  /** Used when the user belongs to the Trust approval group */
  TRUST = 'TRUST'
}

/** A comment performed by a risk reviewer */
export type RiskComment = {
  __typename: 'RiskComment';
  /** ID of the comment */
  commentID: Scalars['String']['output'];
  /** The timestamp in nanos of when the comment was added */
  createdAt: Scalars['String']['output'];
  /** Email of the risk reviewer */
  email: Scalars['String']['output'];
  /** Message */
  message: Scalars['String']['output'];
  /** Operation associated with this comment */
  operation: Operation;
};

/** RiskDashboardUser represents a user of the Risk Dashboard, separate from users of the Anchorage application */
export type RiskDashboardUser = {
  __typename: 'RiskDashboardUser';
  /** email is the email of a RiskDashboardUser, and uniquely identifies that user. */
  email: Scalars['String']['output'];
};

/** An endorsement performed by a risk reviewer */
export type RiskEndorsement = {
  __typename: 'RiskEndorsement';
  /** The timestamp in nanos of when the comment was added */
  createdAt: Scalars['String']['output'];
  /** Decision made by the reviewer */
  decision: OperationDecision;
  /** Email of the risk reviewer */
  email: Scalars['String']['output'];
  /** ID of the endorsement */
  endorsementID: Scalars['String']['output'];
  /** ID of the Operation associated with this endorsement */
  operationID: Scalars['OperationID']['output'];
};

/** EndorsementRiskData contains all of the risk data gathered about this endorsement */
export type RiskMetadata = {
  __typename: 'RiskMetadata';
  /** altitude is the altitude measured by the device */
  altitude: Scalars['String']['output'];
  /** level of the battery in percentage */
  batteryLevel: Scalars['String']['output'];
  /** biometricFingerprint is a hash of the the biometrics data on the device */
  biometricFingerprint: Scalars['String']['output'];
  /** biometricType is the type of biometics used to authenticate to the secure enclave on the device */
  biometricType: Scalars['String']['output'];
  /** bluetooth information */
  bluetooth: Bluetooth;
  /** Carrier information */
  carrier: Carrier;
  /** createdTime is the time at which the data was gathered */
  createdTime: Scalars['String']['output'];
  /** deviceInfo is the name of the device */
  deviceInfo: Scalars['String']['output'];
  /** Device type */
  deviceType: Scalars['String']['output'];
  /** Distress signal from the client */
  distressSignal: Maybe<Distress>;
  /** IP address from which the endorsement was made (can be empty string for old endorsements) */
  ipAddress: Scalars['String']['output'];
  /** If we can find out from the IP address who their internet provider is, we fill that out here. We can't always know that. */
  ipProvider: Maybe<Scalars['String']['output']>;
  /** latitude is the latitude from GPS */
  latitude: Scalars['String']['output'];
  /** longitude is the longitude from GPS */
  longitude: Scalars['String']['output'];
  /** Model of the device */
  model: Scalars['String']['output'];
  /** list of network interfaces active */
  networkInterfaces: Array<RiskMetadataNetworkInterface>;
  /** information regarding pedometer */
  pedometer: Pedometer;
  /** Optional: If the user used a VPN, TOR or another type of proxy, this returns info about the type of proxy used */
  proxyType: Maybe<Scalars['String']['output']>;
  /** rawMetadata is the unprocessed data sent from the phone */
  rawMetadata: Scalars['String']['output'];
  /**
   * number of steps. -1 if unknown.
   * @deprecated use pedometer field instead
   */
  steps: Scalars['Int']['output'];
  /** System name in use in the device */
  systemName: Scalars['String']['output'];
  /** System version in use in the device */
  systemVersion: Scalars['String']['output'];
  /** voiceBiometricsDetectedText is the text from the speech recognition */
  voiceBiometricsDetectedText: Scalars['String']['output'];
  /** voiceBiometricsDetectedTextConfidence is the confidence of the speech recognition result */
  voiceBiometricsDetectedTextConfidence: Scalars['Float']['output'];
  /** voiceBiometricsError is returned if we are unable to proccess the voice data */
  voiceBiometricsError: Scalars['String']['output'];
  /** voiceBiometricsFaceConfidence indicates the confidence of the face match */
  voiceBiometricsFaceConfidence: Scalars['Float']['output'];
  /** voiceBiometricsStatus indicates the result of the voice biometrics gathering */
  voiceBiometricsStatus: Maybe<VoiceBiometricsStatus>;
  /** voiceBiometricsVoiceConfidence indicates the confidence of the voice match */
  voiceBiometricsVoiceConfidence: Scalars['Float']['output'];
  /** list of wifi names active */
  wifi: Array<Scalars['String']['output']>;
};

/** Risk metadata-specific network interface */
export type RiskMetadataNetworkInterface = {
  __typename: 'RiskMetadataNetworkInterface';
  /** IP Address associated with this interface */
  address: Maybe<Scalars['String']['output']>;
  /** Family of the address */
  family: AddressFamily;
  /** Name of the network interface */
  name: Scalars['String']['output'];
};

/** Vendor for RiskAnalysis */
export enum RiskRequestStatus {
  /** RiskAnalysis has been completed */
  COMPLETE = 'COMPLETE',
  /** RiskAnalysis has failed */
  FAILED = 'FAILED',
  /** RiskAnalysis is being requested */
  PENDING = 'PENDING',
  /** RiskAnalysis has being queued */
  QUEUED = 'QUEUED',
  /** AssetType or Operation for AssetType not Supported */
  UNSUPPORTED = 'UNSUPPORTED'
}

/** The type of back office review that must be performed by Anchorage in prior to execution of a scoped action. */
export enum RiskReviewRequirement {
  /** Manual risk review is not required by Anchorage prior to execution of an action. */
  RISK_REVIEW_BYPASSED = 'RISK_REVIEW_BYPASSED',
  /** Manual risk review must be performed by Anchorage prior to execution of an action. */
  RISK_REVIEW_REQUIRED = 'RISK_REVIEW_REQUIRED'
}

/** Details on an operation's risk requirements */
export type RiskReviewRequirements = {
  __typename: 'RiskReviewRequirements';
  /** A list of endorsements that have currently been made for thsi operation */
  endorsements: Array<RiskEndorsement>;
  /** The groups which are needed for this operation's approval */
  groups: Array<RiskApprovalGroup>;
  /** The number of approvals required for an operation to qualify as passing risk review */
  numRequiredApprovals: Scalars['Int']['output'];
  /** The number of unique reviewers who have reviewed this operation */
  numReviews: Scalars['Int']['output'];
};

/** Role represents a permission group that can be associated with a user or API key */
export type Role = {
  __typename: 'Role';
  /** name is the client-supplied name of the role */
  name: Scalars['String']['output'];
  /** permissions is the list of permissions that are granted by this role */
  permissions: Array<RolePermission>;
  /** publicKeyIDs is the list of api public keys' ids that belong to the role */
  publicKeyIDs: Maybe<Array<Scalars['String']['output']>>;
  /** roleID is the id of the role */
  roleID: RoleID;
};

/** RoleAction represents the name of an action that can be performed with this level of permission */
export enum RoleAction {
  /** AUTHORIZE_SETTLEMENTS gives the ability to authorize settlements involving vaults that have this permission. This has the same effect as iOS endorsements on the operation for authorizing the settlement. */
  AUTHORIZE_SETTLEMENTS = 'AUTHORIZE_SETTLEMENTS',
  /** CONFIGURE_WEBHOOKS gives the ability to configure webhook endpoints and their respective subscriptions */
  CONFIGURE_WEBHOOKS = 'CONFIGURE_WEBHOOKS',
  /** CREATE_DEPOSIT_ADDRESS is the ability to generate and retrieve deposit addresses within a vault */
  CREATE_DEPOSIT_ADDRESS = 'CREATE_DEPOSIT_ADDRESS',
  /** DEPOSIT_ATTRIBUTION is the ability to use deposit attribution via API */
  DEPOSIT_ATTRIBUTION = 'DEPOSIT_ATTRIBUTION',
  /**
   * No longer used but kept for backwards compatibility as some orgs may still
   * have this permission.
   */
  DIEM_PREBURN = 'DIEM_PREBURN',
  /** EXTERNAL_LOAN_WRITE is the ability to originate loans through API */
  EXTERNAL_LOAN_WRITE = 'EXTERNAL_LOAN_WRITE',
  /** FACILITY_ONLY is the ability to read lending facility data */
  FACILITY_ONLY = 'FACILITY_ONLY',
  /** HOLD is the ability to create holds on assets in a vault */
  HOLD = 'HOLD',
  /** INITIATE_WITHDRAWAL is the ability to initiate a withdraw from a vault */
  INITIATE_WITHDRAWAL = 'INITIATE_WITHDRAWAL',
  /** INTERNAL_TRANSFER is the ability to transfer assets from one Anchorage vault to another vault */
  INTERNAL_TRANSFER = 'INTERNAL_TRANSFER',
  /** LENDER_READ is the ability to read lender account data */
  LENDER_READ = 'LENDER_READ',
  /** ONBOARDING is the ability to manage onboarding data */
  ONBOARDING = 'ONBOARDING',
  /** PROPOSE_ACCEPT_SETTLEMENTS gives the ability to propose or accept settlements involving vaults that have this permission. They still have to be authorized separately through the API or app. */
  PROPOSE_ACCEPT_SETTLEMENTS = 'PROPOSE_ACCEPT_SETTLEMENTS',
  /** READ is read access to vault and account metadata */
  READ = 'READ',
  /** READ_DEPOSIT_ATTRIBUTION is the ability to read deposit attribution data via API */
  READ_DEPOSIT_ATTRIBUTION = 'READ_DEPOSIT_ATTRIBUTION',
  /** READ_SUBACCOUNT is the ability to read data from the subaccount system */
  READ_SUBACCOUNT = 'READ_SUBACCOUNT',
  /** READ_TRADE is the ability to read all trade data */
  READ_TRADE = 'READ_TRADE',
  /** STAKE gives the ability to stake and unstake assets */
  STAKE = 'STAKE',
  /** SUBACCOUNT is the ability to use subaccount system to manage positions */
  SUBACCOUNT = 'SUBACCOUNT',
  /** TRADE is the ability to execute trades and read trade data created by the API key */
  TRADE = 'TRADE',
  /** TRANSFER is the ability to transfer assets */
  TRANSFER = 'TRANSFER'
}

/** RoleChangeType describes the type of role change that the operation is performing */
export enum RoleChangeType {
  /** CREATE indicates a new role is being created */
  CREATE = 'CREATE',
  /** DELETE indicates an existing role is being deleted */
  DELETE = 'DELETE',
  /** UPDATE indicates an existing role is being updated */
  UPDATE = 'UPDATE'
}

/** RoleID identifies a role within an organization */
export type RoleID = {
  __typename: 'RoleID';
  /** organizationKeyID is the ID of the organization this role belongs to */
  organizationKeyID: Scalars['String']['output'];
  /** roleSubID is the id of the role within the context of this organization */
  roleSubID: Scalars['String']['output'];
};

/**
 * RolePermission permits an action, with action-specific parameters such as whitelisted vaults.
 * This reflects the internal, but non-sensitive, representation of the permission.
 */
export type RolePermission = {
  __typename: 'RolePermission';
  /** action describes the action that is being enabled for this role */
  action: RoleAction;
  /** anySourceVault indicates the action is valid for any source vault. */
  anySourceVault: Scalars['Boolean']['output'];
  /** destination indicates valid destinations for this action. */
  destination: Maybe<Destination>;
  /**
   * destinationOrgKeyIDs was never used and will be removed soon.
   * @deprecated never populated, will be removed when app no longer queries field
   */
  destinationOrgKeyIDs: Maybe<Array<Scalars['String']['output']>>;
  /**
   * sourceVaults is the list of vaults that this action is valid in.
   *
   * For backwards compatibility, an empty list means all current and future vaults are allowed. However, please instead use `anySourceVault`.
   */
  sourceVaults: Array<Vault>;
  /** transferLimit is a user-chosen limit of funds transferrable in a time window. */
  transferLimit: Maybe<AmountDuration>;
};

/**
 * RolePermissionChange is a deprecated model meant to detail a permission or a change to a permission as used by the user interface.
 *
 * This type is basically an alias for RolePermissionDetail. An early Demo app release includes a query fragment for this type.
 */
export type RolePermissionChange = {
  __typename: 'RolePermissionChange';
  /** changeType indicates what type of change (if any) is occurring for a permission. */
  changeType: RolePermissionChangeType;
  /** destinations contains detail on allowed destinations for this permission. */
  destinations: Maybe<Array<RolePermissionDestinationDetail>>;
  /** previousSubtitle is an optional prior user interface subtitle when a permission is being updated or deleted. */
  previousSubtitle: Maybe<Scalars['String']['output']>;
  /** previousTitle is the prior user interface title for this permission. Only present when the title has changed. */
  previousTitle: Maybe<Scalars['String']['output']>;
  /** reviewTitle is an optional user interface prompt to review further details for a permission, such as destinations. */
  reviewTitle: Maybe<Scalars['String']['output']>;
  /** subtitle is an optional user interface subtitle for this permission. */
  subtitle: Maybe<Scalars['String']['output']>;
  /** title is a user interface title for this permission. */
  title: Maybe<Scalars['String']['output']>;
};

/** RolePermissionChangeType indicates the type of change occurring for a permission */
export enum RolePermissionChangeType {
  /** CREATE is used when a new permission is being added that didn't exist before. */
  CREATE = 'CREATE',
  /** DELETE is used when an existing permission will no longer exist. */
  DELETE = 'DELETE',
  /** UNCHANGED is used when viewing an existing permission that is not being changed. */
  UNCHANGED = 'UNCHANGED',
  /** UPDATE is used when an existing permission has one or more parameters being changed. */
  UPDATE = 'UPDATE'
}

/**
 * RolePermissionChanges is a deprecated model meant to details a role permissions as used by the user interface.
 *
 * This type is basically an alias for RolePermissionDetails. An early Demo app release includes a query fragment for this type.
 */
export type RolePermissionChanges = {
  __typename: 'RolePermissionChanges';
  /** amountLimit includes changes to an amount limit, which is relevant for the transfer permission. */
  amountLimit: Maybe<Array<RolePermissionChange>>;
  /** destinations includes changes to allowed transfer destinations. */
  destinations: Maybe<Array<RolePermissionChange>>;
  /** globalPermissions includes permissions which do not pertain to vaults. */
  globalPermissions: Maybe<Array<RolePermissionChange>>;
  /** transferTypes includes categorical changes to the transfer permission (internal/external). */
  transferTypes: Maybe<Array<RolePermissionChange>>;
  /** vaultPermissions includes permissions which apply to vaults, but excludes the transfer permission. */
  vaultPermissions: Maybe<Array<RolePermissionChange>>;
};

/** RolePermissionDestinationDetail details an allowed destination for a permission as used by the user interface. */
export type RolePermissionDestinationDetail = AMLBlockchainAddress | TrustedDestination;

/** RolePermissionDetail details a permission or a change to a permission as used by the user interface. */
export type RolePermissionDetail = {
  __typename: 'RolePermissionDetail';
  /** active is a user interface value for this permission. */
  active: Maybe<Scalars['Boolean']['output']>;
  /** changeType indicates what type of change (if any) is occurring for a permission. */
  changeType: RolePermissionChangeType;
  /** destinations contains detail on allowed destinations for this permission. */
  destinations: Maybe<Array<RolePermissionDestinationDetail>>;
  /** previousSubtitle is an optional prior user interface subtitle when a permission is being updated or deleted. */
  previousSubtitle: Maybe<Scalars['String']['output']>;
  /** previousTitle is the prior user interface title for this permission. Only present when the title has changed. */
  previousTitle: Maybe<Scalars['String']['output']>;
  /** previouslyActive is the prior user interface value for this permission. Only present when the value has changed. */
  previouslyActive: Maybe<Scalars['Boolean']['output']>;
  /** reviewTitle is an optional user interface prompt to review further details for a permission, such as destinations. */
  reviewTitle: Maybe<Scalars['String']['output']>;
  /** subtitle is an optional user interface subtitle for this permission. */
  subtitle: Maybe<Scalars['String']['output']>;
  /** title is a user interface title for this permission. */
  title: Maybe<Scalars['String']['output']>;
  /** transferType indicates if the transfer is an INTERNAL or EXTERNAL transfer. */
  transferType: Maybe<RolePermissionTransferType>;
};

/** RolePermissionDetails details a role permissions as used by the user interface. */
export type RolePermissionDetails = {
  __typename: 'RolePermissionDetails';
  /** amountLimit includes changes to an amount limit, which is relevant for the transfer permission. */
  amountLimit: Maybe<Array<RolePermissionDetail>>;
  /** destinations includes changes to allowed transfer destinations. */
  destinations: Maybe<Array<RolePermissionDetail>>;
  /** globalPermissions includes permissions which do not pertain to vaults. */
  globalPermissions: Maybe<Array<RolePermissionDetail>>;
  /** roleID is the id of the role these details are associated with */
  roleID: RoleID;
  /** transferTypes includes categorical changes to the transfer permission (internal/external). */
  transferTypes: Maybe<Array<RolePermissionDetail>>;
  /** vaultPermissions includes permissions which apply to vaults, but excludes the transfer permission. */
  vaultPermissions: Maybe<Array<RolePermissionDetail>>;
};

/** RolePermissionTransferType represents the type of transfer permission the change refers */
export enum RolePermissionTransferType {
  /** EXTERNAL_TRANSFER is the type of transfer permission associated with external transfers */
  EXTERNAL_TRANSFER = 'EXTERNAL_TRANSFER',
  /** INTERNAL_TRANSFERs is the type of transfer permission associated with internal transfers */
  INTERNAL_TRANSFER = 'INTERNAL_TRANSFER'
}

/** A connection to a list of items. */
export type SNCounterpartyConnection = {
  __typename: 'SNCounterpartyConnection';
  /** A list of edges. */
  edges: Array<SNCounterpartyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SNCounterpartyEdge = {
  __typename: 'SNCounterpartyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SNTrustedCounterparty;
};

/** A participant in the settlement network */
export type SNParticipant = Node & {
  __typename: 'SNParticipant';
  /**
   * Custody account
   * Null if callerHasViewAccess is false.
   */
  account: Maybe<Account>;
  /** True if the participant is in the same organization as the user. */
  callerHasViewAccess: Scalars['Boolean']['output'];
  /** This is extracted from the account for now */
  custodianName: Scalars['String']['output'];
  /**
   * Null if Anchorage hasn't selected one.
   * Null if callerHasViewAccess is false.
   */
  defaultVault: Maybe<Vault>;
  /**
   * Null if Anchorage hasn't selected one.
   * Null if callerHasViewAccess is false.
   */
  defaultWallets: Maybe<Array<Wallet>>;
  /** Unique participant identifier */
  id: Scalars['ID']['output'];
  /** This is true if this participant can receive payments. */
  isActive: Scalars['Boolean']['output'];
  /**
   * Last time this participant became activate. If they were never inactivate, this is the same as the time they were created.
   * Null if callerHasViewAccess is false.
   */
  lastActivatedAt: Maybe<Scalars['Timestamp']['output']>;
  /** Participant name. This is genrally a legal name of an "affiliate" */
  name: Scalars['String']['output'];
  /**
   * List of vaults for the participant's account along with some extra data specific to the settlement network.
   * Null if callerHasViewAccess is false.
   */
  snVaults: Maybe<Array<SNVault>>;
  /**
   * The assets that the participant can exchange on the settlement network
   * Null if callerHasViewAccess is false.
   */
  supportedAssets: Maybe<Array<AssetTypeInfo>>;
  /** Can this participant sned and receive (two way) settlements? */
  supportsAdvancedSettlements: Scalars['Boolean']['output'];
  /** Check this field before trying to send the participant a withdrawal to ensure it doesn't fail. */
  supportsReceivingWithdrawals: Scalars['Boolean']['output'];
  /** Can this participant do settlements or only send/receive withdrawals? */
  supportsSettlements: Scalars['Boolean']['output'];
  /**
   * Basic or Advanced. This is not public information, but the capabilities of the participant are. This makes sure that this flag is used only in participant settings screens and not when making decisions about what features are supported.
   * Null if callerHasViewAccess is false.
   */
  tier: Maybe<SNParticipantTier>;
};


/** A participant in the settlement network */
export type SNParticipantsupportsReceivingWithdrawalsArgs = {
  assetTypeID: Scalars['AssetTypeID']['input'];
};

/** A connection to a list of items. */
export type SNParticipantConnection = {
  __typename: 'SNParticipantConnection';
  /** A list of edges. */
  edges: Array<SNParticipantEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SNParticipantEdge = {
  __typename: 'SNParticipantEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SNParticipant;
};

/** SNParticipantTier is a way to specify what set of features a participant has access to */
export enum SNParticipantTier {
  /** Advanced participants have access to two-way settlements as long as both parties are advanced. */
  ADVANCED = 'ADVANCED',
  /** Basic participants have access only to one-way settlements. */
  BASIC = 'BASIC'
}

/** A settlement side describes one participant's view of a settlement. The two participants are the proposer and acceptor and each can view some limited information about the other side and everything about their side. */
export type SNSettlementSide = {
  __typename: 'SNSettlementSide';
  /**
   * This means there is nothing preventing the settlement from executing that this side of the settlement can unblock.
   * It's true if the wallets are funded and the settlement is accepted.
   */
  actionNeeded: Scalars['Boolean']['output'];
  /**
   * This settlement has gone through quorum approval and is waiting for funding.
   * Null if callerHasViewAccess is false.
   */
  authorized: Maybe<Scalars['Boolean']['output']>;
  /**
   * This settlement can't be completed because this operation is blocking it from executing.
   * Null if callerHasViewAccess is false.
   */
  blockedByOperation: Maybe<Operation>;
  /** True if the participant is in the same organization as the user. */
  callerHasViewAccess: Scalars['Boolean']['output'];
  /**
   * This settlement's fees has been funded. They are funded if each leg's fees are funded.
   * Null if callerHasViewAccess is false.
   */
  feesFunded: Maybe<Scalars['Boolean']['output']>;
  /**
   * This settlement has been funded.
   * Null if callerHasViewAccess is false.
   */
  funded: Maybe<Scalars['Boolean']['output']>;
  /**
   * The operation for endorsing the settlement.
   * Null if callerHasViewAccess is false.
   */
  operation: Maybe<Operation>;
  /** Settlement side participant */
  participant: SNParticipant;
  /**
   * The vault that the participant has selected for the settlement. All wallets are from this vault.
   * If no wallet amounts have been selected yet (ex. sometimes the acceptor has yet to selec them), then this is null.
   * Null if callerHasViewAccess is false.
   */
  vault: Maybe<Vault>;
  /**
   * The vault used for this settlement is unusable. API key sharing is not set up correctly.
   * Null if callerHasViewAccess is false.
   */
  vaultUnusable: Maybe<Scalars['Boolean']['output']>;
  /**
   * Potentially empty list
   * Null if callerHasViewAccess is false.
   */
  walletAmounts: Maybe<Array<SNWalletAmount>>;
};

/** A state for settlements. It's a high level description of what's happening or has happened to the settlement. */
export enum SNSettlementState {
  /** The settlement is waiting for the acceptor to accept it. */
  ACTION_NEEDED = 'ACTION_NEEDED',
  /** The settlement was executed successfully. */
  EXECUTED = 'EXECUTED',
  /** The settlement is being executed. */
  EXECUTING = 'EXECUTING',
  /** The settlement failed to execute. */
  FAILED = 'FAILED',
  /** The settlement was rejected by one of the participants. */
  REJECTED = 'REJECTED',
  /** The participants took too long to accept the settlement. */
  TIMED_OUT = 'TIMED_OUT'
}

/** A state filter for settlements. Each value here can correspond to multiple states. */
export enum SNSettlementStateFilter {
  /** The settlement is waiting for the acceptor to accept it. */
  ACTION_NEEDED = 'ACTION_NEEDED',
  /** The settlement has completed with success or failure or was canceled or timed out somehow. */
  COMPLETED = 'COMPLETED',
  /** The settlement is being executed. */
  EXECUTING = 'EXECUTING',
  /** Return settlements in all states that are not considered completed as definde by the COMPLETED filter. */
  NOT_COMPLETED = 'NOT_COMPLETED'
}

/** A trusted counterparty for a participant */
export type SNTrustedCounterparty = {
  __typename: 'SNTrustedCounterparty';
  /** This represents the date/time that the counterparty was added. */
  addedAt: Scalars['Timestamp']['output'];
  /** Detailed information about the counterparty. */
  counterparty: SNParticipant;
  /** Optional notes about the counterparty. */
  description: Maybe<Scalars['String']['output']>;
  /** True if the counterparty is active with the participant. False if inactivate */
  isActive: Scalars['Boolean']['output'];
  /** This represents the date/time the counterparty was last active. Defaults to added date */
  lastActivatedAt: Scalars['Timestamp']['output'];
  /** Detailed information about the participant this counterparty is for. */
  owner: SNParticipant;
};

/** A settlement network settlement between two particiants */
export type SNTwoWaySettlement = Node & {
  __typename: 'SNTwoWaySettlement';
  /** This indicates the deadline for all participants to accept the settlement. */
  acceptanceDeadline: Scalars['Timestamp']['output'];
  /** The side that didn't propose the settlement and has to accept it */
  acceptor: SNSettlementSide;
  /** An optional client-provided reference ID for the settlement. This memo field can be used for external tracking information. */
  clientReferenceID: Maybe<Scalars['String']['output']>;
  /** If the settlement has completed, this is when. Otherwise null. */
  completedAt: Maybe<Scalars['Timestamp']['output']>;
  /** The time the settlement was proposed */
  createdAt: Scalars['Timestamp']['output'];
  /** Unique settlement identifier */
  id: Scalars['ID']['output'];
  /**
   * operationsCreated is a list of operations that were created as part of the settlement.
   * Only operations that the caller has access to see are returned.
   */
  operationsCreated: Array<Operation>;
  /** If true, the settlement requires acceptor selection. */
  pendingAcceptorReview: Scalars['Boolean']['output'];
  /** The proposer of the settlement */
  proposer: SNSettlementSide;
  /** If the settlement has started executing, this is when. Otherwise null. */
  startedExecutingAt: Maybe<Scalars['Timestamp']['output']>;
  /** The current state of the settlement */
  state: SNSettlementState;
};

/** A connection to a list of items. */
export type SNTwoWaySettlementConnection = {
  __typename: 'SNTwoWaySettlementConnection';
  /** A list of edges. */
  edges: Array<SNTwoWaySettlementEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SNTwoWaySettlementEdge = {
  __typename: 'SNTwoWaySettlementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SNTwoWaySettlement;
};

/** A vault, with some extra data specific to the settlement network. */
export type SNVault = {
  __typename: 'SNVault';
  /** True if the vault is usable for sending assets in the settlement network - api key sharing is set up correctly. */
  canSendInSettlements: Scalars['Boolean']['output'];
  /** the vault */
  vault: Vault;
};

/** A wallet and the amount of an asset in it */
export type SNWalletAmount = {
  __typename: 'SNWalletAmount';
  /** An amount of an asset */
  amount: Amount;
  /** A wallet */
  wallet: Maybe<Wallet>;
};

/** sanitizeAddress response payload. Contains the canonicalized address. */
export type SanitizeAddressPayload = {
  __typename: 'SanitizeAddressPayload';
  /** address is the canonical version of an address */
  address: Scalars['String']['output'];
};

/** SaveKYCQuestionnaireSubmissionInput is the input for SaveKYCQuestionnaireSubmission. */
export type SaveKYCQuestionnaireSubmissionInput = {
  /** Answers to the questions in the questionnaire */
  answers: Array<InputKYCQuestionAnswer>;
  /** The identifier of the KYCQuestionnaireSubmission */
  id: Scalars['ID']['input'];
  /** IsSubmission specifies if it will try to submit and validate the questionnaire data or just save */
  isSubmission?: InputMaybe<Scalars['Boolean']['input']>;
};

/** SaveKYCQuestionnaireSubmissionResponse holds the response of a SaveKYCQuestionnaireSubmission mutation */
export type SaveKYCQuestionnaireSubmissionResponse = {
  __typename: 'SaveKYCQuestionnaireSubmissionResponse';
  /** Submission holds the updated submission after saving a kyc questionnaire submission */
  submission: Maybe<KYCQuestionnaireSubmission>;
};

/** Defines a relationship between scopes and related quorum requirements */
export type ScopeToQuorumRequirements = {
  /** List of quorum requirements for the scope */
  quorumRequirements?: InputMaybe<Array<InputMaybe<QuorumRequirement>>>;
  /** The policy scope that the quorumRequirements apply to */
  scope: Scalars['String']['input'];
};

/** Collected search analysis information about an address, currently obtain from Bing Search */
export type SearchAnalysis = {
  __typename: 'SearchAnalysis';
  /** address is searched on search API via search service */
  address: Scalars['String']['output'];
  /** searchResultsCount is the number of search results obtained for the address from Bing Search */
  searchResultsCount: Scalars['Int']['output'];
};

/** SectionValue represents a section value of crr result */
export type SectionValue = {
  __typename: 'SectionValue';
  /** id is the name of a factor */
  id: Scalars['String']['output'];
  /** label is the description of the group */
  label: Scalars['String']['output'];
  /** value is the value for the factor */
  value: Scalars['String']['output'];
};

/** SendKYCEventInput is used to send a KYC event. */
export type SendKYCEventInput = {
  /** The affiliate ID of the KYCProcess. */
  affiliateId: Scalars['String']['input'];
  /** The case ID of the KYCProcess. */
  caseId: Scalars['Int']['input'];
  /** The case type of the KYCProcess. */
  caseType: Scalars['String']['input'];
  /** The current status of the KYCProcess. */
  currentStatus: Scalars['String']['input'];
  /** The KYC analyst of the KYCProcess. */
  kycAnalyst: Scalars['String']['input'];
  /** The previous status of the KYCProcess. */
  previousStatus: Scalars['String']['input'];
  /** The QC analyst of the KYCProcess. */
  qcAnalyst: Scalars['String']['input'];
  /** The source type of the KYCProcess. */
  sourceType: Scalars['String']['input'];
};

/** SendKYCQuestionnaireSubmissionInput is the input for SendKYCQuestionnaireSubmission. */
export type SendKYCQuestionnaireSubmissionInput = {
  /** The identifier of the KYCQuestionnaireSubmission */
  id: Scalars['ID']['input'];
};

/** Set trader status */
export type SetTraderInput = {
  /** Enable or disable trader */
  enable: Scalars['Boolean']['input'];
  /** Trader public key id */
  publicKeyID: Scalars['ID']['input'];
};

/** Payload response for setWalletKeyExportPreference mutation */
export type SetWalletKeyExportPreferenceResponse = {
  __typename: 'SetWalletKeyExportPreferenceResponse';
  /** Was request successful */
  success: Scalars['Boolean']['output'];
};

/** Represents the setting main type */
export type Setting = {
  __typename: 'Setting';
  /** Refers to the type of the setting */
  settingType: SettingType;
  /** Refers to the value(s) of the setting */
  value: Scalars['String']['output'];
  /** Refers to the value type of the setting */
  valueType: SettingValueType;
};

/** SettingType represents the possible settings */
export enum SettingType {
  /** Contractual notifications document delivery preference */
  DOCUMENT_DELIVERY_CONTRACTUAL_NOTIFICATIONS = 'DOCUMENT_DELIVERY_CONTRACTUAL_NOTIFICATIONS',
  /** Deposit attribution document delivery preference */
  DOCUMENT_DELIVERY_DEPOSIT_ATTRIBUTIONS = 'DOCUMENT_DELIVERY_DEPOSIT_ATTRIBUTIONS',
  /** Deposit invoice document delivery preference */
  DOCUMENT_DELIVERY_INVOICE = 'DOCUMENT_DELIVERY_INVOICE',
  /** KYC inquiry document delivery preference */
  DOCUMENT_DELIVERY_KYC_INQUIRY = 'DOCUMENT_DELIVERY_KYC_INQUIRY',
  /** Platform services update document delivery preference */
  DOCUMENT_DELIVERY_PLATFORM_SERVICES_UPDATE = 'DOCUMENT_DELIVERY_PLATFORM_SERVICES_UPDATE',
  /** Settlement Network notifications document delivery preference */
  DOCUMENT_DELIVERY_SETTLEMENT_NETWORK_NOTIFICATIONS = 'DOCUMENT_DELIVERY_SETTLEMENT_NETWORK_NOTIFICATIONS',
  /** Tax documents document delivery preference */
  DOCUMENT_DELIVERY_TAX_DOCUMENTS = 'DOCUMENT_DELIVERY_TAX_DOCUMENTS',
  /** Trade confirmations document delivery preference */
  DOCUMENT_DELIVERY_TRADE_CONFIRMATIONS = 'DOCUMENT_DELIVERY_TRADE_CONFIRMATIONS'
}

/** SettingValueType represents the possible value types for a setting */
export enum SettingValueType {
  /** A setting of the boolean type */
  BOOLEAN = 'BOOLEAN',
  /** A setting of the date type */
  DATE = 'DATE',
  /** A setting of the json type */
  JSON = 'JSON',
  /** A setting of the numeric type */
  NUMERIC = 'NUMERIC',
  /** A setting of the string type */
  STRING = 'STRING'
}

/** Lending Facility Members status options */
export type SettledAndUnsettledAmount = {
  __typename: 'SettledAndUnsettledAmount';
  /** settled amount for an invoice */
  settledAmount: Amount;
  /** unsettled amount for an invoice */
  unsettledAmount: Amount;
};

/** A client settlement */
export type Settlement = {
  __typename: 'Settlement';
  /**
   * Brokerage account, all contained trades for this settlement should belong to this
   * account
   */
  account: Maybe<TradingAccount>;
  /** Time when the settlement was created */
  createdAt: Scalars['Timestamp']['output'];
  /** Organization to which this settlement belongs to */
  organization: Organization;
  /**
   * List of total amounts owed/to pay by asset
   * @deprecated Use tradingPositions instead
   */
  positions: Array<Amount>;
  /** Unique settlement identifier */
  settlementID: Scalars['String']['output'];
  /** List of settlement instructions */
  settlementInstructions: Array<SettlementInstructions>;
  /** Inferred from the underlying trades */
  settlementStatus: TradeStatus;
  /** Trades that belong to this settlement */
  trades: Array<Trade>;
  /** List of total amounts owed/to pay by asset */
  tradingPositions: Array<TradingAmount>;
  /** List transactions of this settlement */
  transactions: Array<SettlementTransactions>;
};

/** Settlement instructions union */
export type SettlementInstructions = SettlementInstructionsCrypto | SettlementInstructionsFiat;

/** Settlement instructions for crypto assets */
export type SettlementInstructionsCrypto = {
  __typename: 'SettlementInstructionsCrypto';
  /** Settlement address */
  address: Scalars['String']['output'];
  /** Asset type identifier */
  assetTypeID: Scalars['AssetTypeID']['output'];
};

/** Settlement instructions in fiat */
export type SettlementInstructionsFiat = {
  __typename: 'SettlementInstructionsFiat';
  /** Domestic account settlement instructions */
  domestic: Maybe<Array<SettlementInstructionsFiatEntry>>;
  /** International account settlement instructions */
  international: Maybe<Array<SettlementInstructionsFiatEntry>>;
  /** Silvergate settlement instructions */
  silverGate: Maybe<Array<SettlementInstructionsFiatEntry>>;
};

/** Generic instruction for settlement in fiat */
export type SettlementInstructionsFiatEntry = {
  __typename: 'SettlementInstructionsFiatEntry';
  /** Title/label of the instruction (e.g., Address) */
  title: Scalars['String']['output'];
  /** Values of the instruction (e.g., PCBBUS66) */
  value: Array<Scalars['String']['output']>;
};

/** Holds information about a transaction in a client settlement */
export type SettlementTransaction = {
  __typename: 'SettlementTransaction';
  /** Identifier of the transaction */
  id: Scalars['ID']['output'];
  /** Transaction string */
  transaction: Scalars['String']['output'];
};

/** Holds information about the transactions in a client settlement */
export type SettlementTransactions = {
  __typename: 'SettlementTransactions';
  /** Asset type identifier */
  assetTypeID: Scalars['AssetTypeID']['output'];
  /** Settlement transactions */
  transactions: Array<SettlementTransaction>;
};

/** TODO: add a description */
export type SettlementView = {
  __typename: 'SettlementView';
  /** TODO: add a description */
  edges: Array<Maybe<SettlementViewEdge>>;
  /** TODO: add a description */
  pageInfo: PageInfo;
};

/** TODO: add a description */
export type SettlementViewEdge = {
  __typename: 'SettlementViewEdge';
  /** TODO: add a description */
  cursor: Scalars['String']['output'];
  /** TODO: add a description */
  node: Settlement;
};

/**
 * Signature encapsulates the signature of the data in question along with the
 * server-side fields included in the object signed. The client is expected to know how to encode
 * information it already has to produce a digest that matches the digest provided,
 * and combine that with the information provided here to verify the signature with a
 * key already known to it that is appropriate for the data.
 */
export type Signature = {
  __typename: 'Signature';
  /** Hex encoded sha256 of data */
  digest: Scalars['String']['output'];
  /** Type of the payload that was signed */
  payloadType: Scalars['String']['output'];
  /** Hex encoded signature of below fields */
  signature: Scalars['String']['output'];
  /** Nanotime the server signed this data */
  signedAt: Scalars['String']['output'];
  /** Version of signature scheme */
  version: Scalars['String']['output'];
};

/** SignedAddress groups a receiving address and its signature for a deposit operation */
export type SignedAddress = {
  __typename: 'SignedAddress';
  /** addressID is the address id for this address */
  addressID: AddressID;
  /** for some assets, we have a special way to format addresses depending on the state of the network */
  formattedAddress: Maybe<Scalars['String']['output']>;
  /** receivingAddress is the address on which a deposit is to be received, populated by the blockchain service */
  receivingAddress: Scalars['String']['output'];
  /** receivingAddressSignature is the signature to be verified of the receiving address by the organization key */
  receivingAddressSignature: Signature;
};

/** SignedOperationDetails is a union type of all possible forms of extra information for signed fields */
export type SignedOperationDetails = SignedOperationDetailsAddress | SignedOperationDetailsAmount | SignedOperationDetailsPreviewLayout;

/** SignedOperationDetailsAddress is the details for an address field that's signed */
export type SignedOperationDetailsAddress = {
  __typename: 'SignedOperationDetailsAddress';
  /** URL to the explorer for the address */
  explorerURL: Scalars['String']['output'];
};

/**
 * SignedOperationDetailsAmount is the details for an amount field
 * since the values are actually expected to be read from HSM - this only has fallbackPriceText
 */
export type SignedOperationDetailsAmount = {
  __typename: 'SignedOperationDetailsAmount';
  /** FallbackPriceText is a human readable field for laying out the price if PriceQuote isn't queried */
  fallbackPriceText: Scalars['String']['output'];
};

/** SignedOperationDetailsPreviewLayout is a preview of the contents */
export type SignedOperationDetailsPreviewLayout = {
  __typename: 'SignedOperationDetailsPreviewLayout';
  /** condensed is the small verion of the preview */
  condensed: SignedPayloadFieldListLayout;
  /** expanded is the expanded version of the preview */
  expanded: SignedPayloadFieldListLayout;
  /** subtitle is the subtitle of the preview */
  subtitle: Maybe<SignedOperationFieldText>;
  /** title is the title of the preview */
  title: SignedOperationFieldText;
};

/**
 * SignedOperationField are fields that are signed by the user, the details are filled in via the contents in SignablePayload based on the signablePayloadIndex
 * Details are null if there is no additional enrichment needed from what the native HSM has provided, however, if it does need some enrichment, detail is non-null
 */
export type SignedOperationField = {
  __typename: 'SignedOperationField';
  /** details provides additional information about the field */
  details: Maybe<SignedOperationDetails>;
  /** signablePayloadIndex is the index into the signablePayload that contains the data for this field */
  signablePayloadIndex: Scalars['Int']['output'];
};

/** SignedOperationFieldText is a pointer to text in the signable payload */
export type SignedOperationFieldText = {
  __typename: 'SignedOperationFieldText';
  /** signablePayloadIndex is the index into the signablePayload that contains the data for this field */
  signablePayloadIndex: Scalars['Int']['output'];
};

/** SignedPayloadFieldListLayout is a list of fields to display */
export type SignedPayloadFieldListLayout = {
  __typename: 'SignedPayloadFieldListLayout';
  /** fields is the list of fields to display */
  fields: Array<OperationField>;
};

/** SimpleStateDiagramItem is a StateDiagramItem sans operationState */
export type SimpleStateDiagramItem = {
  __typename: 'SimpleStateDiagramItem';
  /** children contains additional state info */
  children: Array<SimpleStateDiagramItem>;
  /** details shows additional state info if needed */
  details: Scalars['String']['output'];
  /** stateDescription is the user visible simplified description of the state */
  stateDescription: Scalars['String']['output'];
  /** stateType indicates how the state show be displayed to the user */
  stateType: StateType;
  /** time is available for all states that have been reached */
  time: Maybe<Scalars['String']['output']>;
};

/** SimplifiedEndorsement is an operation endorsement signed by a user */
export type SimplifiedEndorsement = {
  __typename: 'SimplifiedEndorsement';
  /**
   * batchID is the ID of the batch that this endorsement was part of. If this is
   * null, it means that this endorsement was not part of a batch.
   */
  batchID: Maybe<Scalars['String']['output']>;
  /** createdTime is the time at which the endorsement happened */
  createdTime: Scalars['String']['output'];
  /** the decision carried by the endorsement */
  decision: OperationDecision;
  /** The key used to sign */
  key: Maybe<Key>;
  /** operation that was endorsed */
  operation: Operation;
  /** riskMetadata contains risk data about this endorsement */
  riskMetadata: Maybe<RiskMetadata>;
  /**
   * the user who signed
   * @deprecated query key instead because this user can also be an API key
   */
  user: Maybe<User>;
  /** absolute hostless URL to the video with the words (ex. /video/deadbeef) */
  voicePhraseURL: Maybe<Scalars['String']['output']>;
  /** absolute hostless URL to the video with the words (ex. /video/deadbeef) */
  voiceWordsURL: Maybe<Scalars['String']['output']>;
};

/** SmartContractRisk represents the risk assessment of executing a smart contract. */
export type SmartContractRisk = {
  __typename: 'SmartContractRisk';
  /** Description is a human-readable description of the risk analysis. */
  description: Scalars['String']['output'];
  /** Details are additional details about the risk analysis. */
  details: Array<Scalars['String']['output']>;
  /** RiskLevel is the risk level of executing a smart contract. */
  riskLevel: SmartContractRiskLevel;
};

/** SmartContractRiskLevel represents the risk level of interacting with a smart contract. */
export enum SmartContractRiskLevel {
  /** HIGH means the risk level is high and the smart contract is considered malicious. */
  HIGH = 'HIGH',
  /** LOW means the risk level is low and the smart contract is considered safe. */
  LOW = 'LOW',
  /** MEDIUM means the risk level is medium and the smart contract is considered somewhat risky. */
  MEDIUM = 'MEDIUM',
  /** UNAVAILABLE means the risk level could not be determined at this time due to an error. */
  UNAVAILABLE = 'UNAVAILABLE',
  /** UNDETERMINED means the risk level could not be determined at this time due lack of data. */
  UNDETERMINED = 'UNDETERMINED'
}

/** Source is a withdraw or delegate source */
export type Source = {
  __typename: 'Source';
  /** addressID is the addressID of the source address */
  addressID: AddressID;
  /** amount is an amount in the standard unit for the asset type */
  amount: Scalars['String']['output'];
  /** signedAddress is the signed ID for this source address */
  signedAddress: Maybe<SignedAddress>;
};

/** Represents an organization being added */
export type StagedOrganization = {
  __typename: 'StagedOrganization';
  /** The list of possible accounts for this organization. We can't use the Account type because it assumes that the organization exists */
  accountIDs: Array<Scalars['String']['output']>;
  /** The backup strategy for the organization */
  backupStrategy: Maybe<WalletKeyBackupStrategy>;
  /** hsmShardID tells us which HSM shard the staged organization is configured with */
  hsmShardID: Scalars['String']['output'];
  /** hsmZoneID tells us which HSM zone the staged organization is configured with */
  hsmZoneID: Scalars['String']['output'];
  /** A unique ID for the staged organization */
  id: Maybe<Scalars['ID']['output']>;
  /** Name of the organization */
  name: Scalars['String']['output'];
  /** operationID points to the organization creation operation when it's created */
  operationID: Maybe<Scalars['String']['output']>;
  /** Operator returns the operator for this organization. Some popular values are ANCHORAGE, and SELF but there are others. */
  operator: Scalars['String']['output'];
  /** Organization key ID */
  organizationKeyID: Maybe<Scalars['String']['output']>;
  /** The json definition of the staged org. This is more flexible than the individual fields exposed through graphql, but less safe */
  rawDefinition: Scalars['String']['output'];
  /** Number of users in the organization */
  userCount: Scalars['Int']['output'];
  /** Get the list of users in this organization */
  users: Array<StagedUser>;
};

/** Represents a user being added */
export type StagedTokenInput = {
  /** Do we want to override the email? */
  emailOverride?: InputMaybe<Scalars['String']['input']>;
  /** forgetAppAssociation will remove the app association */
  forgetAppAssociation?: InputMaybe<Scalars['Boolean']['input']>;
  /** Do we want an admin? */
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  /** Do we want to override the name? */
  nameOverride?: InputMaybe<Scalars['String']['input']>;
  /** objectID is the individualID if we are adding a new individual, or the enrollment token if we are editing an existing one */
  objectID: Scalars['String']['input'];
  /** Do we want to remove the email override? */
  removeEmailOverride?: InputMaybe<Scalars['Boolean']['input']>;
  /** Do we want to remove the name override? */
  removeNameOverride?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Represents a user that is in the process of being added to an organization */
export type StagedUser = {
  /** The algorithm for the user's public key. This should match the value in the organization change. */
  algorithm: Maybe<Scalars['String']['output']>;
  /** Email of the staged user, if known */
  email: Maybe<Scalars['String']['output']>;
  /** The only possible value for now is eciesEncryptionCofactorSHA512IVAESGCM or null */
  encryptionAlgorithm: Maybe<Scalars['String']['output']>;
  /** Set iff publicKeyID is set */
  encryptionPublicKey: Maybe<Scalars['String']['output']>;
  /**
   * Same as publicKeyID but used for encryption
   * This is set iff encryptionAlgorithm is set
   */
  encryptionPublicKeyID: Maybe<Scalars['PublicKeyID']['output']>;
  /** The enroll token assigned to this user */
  enrollToken: Scalars['String']['output'];
  /** Last login information. Will be empty if never logged in. */
  lastLogin: Maybe<Login>;
  /** Name of the staged user, if known */
  name: Maybe<Scalars['String']['output']>;
  /** Hex encoded representation of the public key, if known. If the user did not enroll yet this field will be null */
  publicKey: Maybe<Scalars['String']['output']>;
  /** Public key ID of the user, if known. If the user did not enroll yet this field will be null */
  publicKeyID: Maybe<Scalars['PublicKeyID']['output']>;
  /** The type of user that the enrollment will create in the organization */
  userType: UserType;
};

/** StakeAmountState represents an amount of a position in a given StakingPositionState. */
export type StakeAmountState = {
  __typename: 'StakeAmountState';
  /** The amount of tokens in the state */
  amount: Amount;
  /** The string name of the state */
  label: Scalars['String']['output'];
  /** The amount state */
  type: StakingPositionState;
};

/** Contains Staking information for an Asset */
export type StakingInfo = {
  __typename: 'StakingInfo';
  /** AutoCompoundEnabled indicates whether auto-compound is enabled for this wallet. */
  autoCompoundEnabled: Maybe<Scalars['Boolean']['output']>;
  /**
   * List of steps for the auto-compound flow
   * @deprecated use the field 'autoCompoundForm' instead
   */
  autoCompoundFlow: Maybe<Array<StakingStep>>;
  /** AutoCompoundForm is used to toggle auto-compounding via standardized staking, if supported by the asset type. It will either show an enable or disable form depending on the current state. */
  autoCompoundForm: Maybe<StandardizedStakingForm>;
  /** ClaimableRewards is the aggregated list of claimable rewards by asset type. */
  claimableRewards: Maybe<Array<Maybe<Amount>>>;
  /** DisclosureID is the ID of the staking disclosure that the user must have agreed to before staking. */
  disclosureID: Scalars['String']['output'];
  /** @deprecated("use the field 'notices' in asset instead") */
  notices: Maybe<Array<Notice>>;
  /**
   * Positions returns a list of exactly ONE StakingPositionConnection. The list/plurality aspect is a design error, consumers can safely index to the 0th item.
   * StakingPositionconnection is a paginated list of staking positions that a wallet has. Each position can be individually viewed and controlled.
   */
  positions: Maybe<Array<StakingPositionConnection>>;
  /** StakeAmountStates contains the aggregated balances of each state for ALL of the current positions a wallet has. There will only be one state per type. */
  stakeAmountStates: Maybe<Array<StakeAmountState>>;
  /**
   * List of steps for the Stake operation
   * @deprecated use the field 'stakeForm' instead
   */
  stakeFlow: Maybe<Array<StakingStep>>;
  /** StakeForm is used to stake assets via standardized staking, if supported by the asset type. */
  stakeForm: Maybe<StandardizedStakingForm>;
  /** TotalClaimableRewards is the sum of all claimable rewards across all positions. */
  totalClaimableRewards: Maybe<Amount>;
};

/**
 * StakingPosition is a position that a Wallet/AssetID has with a validator.
 * A position can have assets in multiple states, such as staked, unstaking, etc.
 * The behavior of StakingPosition varies by asset type (eg some may only allow one position per wallet) but should fit within this interface.
 */
export type StakingPosition = {
  __typename: 'StakingPosition';
  /**
   * List of steps for the Claim flow
   * @deprecated use the field 'claimForm' instead
   */
  claimFlow: Maybe<Array<StakingStep>>;
  /** ClaimRewardsForm to claim rewards from the position via standardized staking, if supported by the asset type. */
  claimRewardsForm: Maybe<StandardizedStakingForm>;
  /**
   * Amount of assets available to claim
   * @deprecated use the field 'states' instead
   */
  claimableRewards: Maybe<Amount>;
  /**
   * Amount of assets staked
   * @deprecated use the field 'states' instead
   */
  stakedAmount: Maybe<Amount>;
  /**
   * State for this StakingPosition
   * @deprecated use the field 'states' instead
   */
  state: StakingPositionState;
  /**
   * States is the list of states that the position is in (eg staking, staked, or unstaking).
   * Each state has an amount of assets associated with it.
   */
  states: Array<StakeAmountState>;
  /**
   * List of steps for the Unstake flow
   * @deprecated use the field 'unstakeForm' instead
   */
  unstakeFlow: Maybe<Array<StakingStep>>;
  /** UnstakeForm is used to unstake some or all of the position via standardized staking, if supported by the asset type. */
  unstakeForm: Maybe<StandardizedStakingForm>;
  /** ValidatorDestination is the validator address that the position is staked to. */
  validatorDestination: Scalars['String']['output'];
  /** ValidatorName is the pseudonym for the validator address. If it's not known, this will default to the validator address. */
  validatorName: Scalars['String']['output'];
};

/** StakingPositionConnection is needed to enable pagination for StakingPosition. */
export type StakingPositionConnection = {
  __typename: 'StakingPositionConnection';
  /** edges */
  edges: Array<StakingPositionEdge>;
  /** pageInfo */
  pageInfo: PageInfo;
};

/** StakingPositionConnection is needed to enable for StakingPosition. */
export type StakingPositionEdge = {
  __typename: 'StakingPositionEdge';
  /** cursor */
  cursor: Scalars['ID']['output'];
  /** node */
  node: Maybe<StakingPosition>;
};

/**
 * StakingPositionState represents the different states that staked funds can be in.
 * Not all protocols will have all states.
 */
export enum StakingPositionState {
  /** Assets are available to claim */
  CLAIMABLE_REWARDS = 'CLAIMABLE_REWARDS',
  /** Assets are restaking */
  RESTAKING = 'RESTAKING',
  /** Assets are staked */
  STAKED = 'STAKED',
  /** Assets are being staked */
  STAKING = 'STAKING',
  /** Assets are unstaking */
  UNSTAKING = 'UNSTAKING'
}

/**
 * StakingStep is an abstract step for uniform staking that each Step must extend.
 * To enable true standardization. Consumers of flows/forms should try to be able to render any combination of steps in the order they're provided.
 */
export type StakingStep = {
  /** Ignore this field */
  ignoreMe: Maybe<Scalars['Boolean']['output']>;
  /** Rules control presentation of the step. */
  rules: Maybe<Array<PresentationRule>>;
};

/** StakingStepInputType represents whether the amount input is quantifying an amount of an asset or a share. */
export enum StakingStepInputType {
  /** An asset amount input type */
  ASSET = 'ASSET',
  /** A shares amount input type */
  SHARES = 'SHARES'
}

/** StakingStepNumberType represents whether the amount input is an absolute number or a percentage. */
export enum StakingStepNumberType {
  /** An absolute number (ie. 0, 10, 420, 13.37) */
  ABSOLUTE = 'ABSOLUTE',
  /** A percentage number, from 0 to 100, inclusive */
  PERCENTAGE = 'PERCENTAGE'
}

/** StandardizedStakingForm contains the backend-provided config to render a staking form for a specific standardized staking action (eg stake, unstake, claim rewards, etc). */
export type StandardizedStakingForm = {
  __typename: 'StandardizedStakingForm';
  /** If the action is disabled for this asset/position, this field can contain the reason why to show to the user. */
  disabledHint: Maybe<Scalars['String']['output']>;
  /**
   * The list of steps to display to the user. The steps are ordered in the way they should be displayed.
   * If the list is empty, the action is disabled for this asset/position.
   */
  steps: Maybe<Array<StakingStep>>;
};

/** standardizedStakingInfo returns the standardized staking information for this asset type */
export type StandardizedStakingInfo = {
  __typename: 'StandardizedStakingInfo';
  /** Whether the asset type supports auto claim and restake flow */
  supportsAutoCompounding: Scalars['Boolean']['output'];
  /** Whether the asset type supports claim flow */
  supportsClaimingRewards: Scalars['Boolean']['output'];
  /** SupportsPorto describes if StandardizedStaking is also supported by Porto, or just ADB */
  supportsPorto: Scalars['Boolean']['output'];
  /** Whether the asset type supports stake initiation flow */
  supportsStaking: Scalars['Boolean']['output'];
  /** Whether the asset type supports unstake flow */
  supportsUnstaking: Scalars['Boolean']['output'];
};

/** Standards contains information standard collections like countries, states or cities */
export type Standards = {
  __typename: 'Standards';
  /** List of countries */
  countries: Maybe<Array<Country>>;
};

/** StandingInstruction represents a FIAT standing insctruction (trusted address) */
export type StandingInstruction = {
  __typename: 'StandingInstruction';
  /** Account Number */
  accountNumber: Scalars['String']['output'];
  /** Affiliate ID linked to the Standing Instructions */
  affiliateID: Scalars['String']['output'];
  /** Affiliate name of the linked affiliate */
  affiliateName: Scalars['String']['output'];
  /** Date of approval of the Standing Instruction */
  approvedAt: Maybe<Scalars['Date']['output']>;
  /** User that approved the Standing Instruction */
  approvedBy: Maybe<Scalars['String']['output']>;
  /** Beneficiary Address 1 */
  beneficiaryAddress1: Maybe<Scalars['String']['output']>;
  /** Beneficiary Address 2 */
  beneficiaryAddress2: Maybe<Scalars['String']['output']>;
  /** Beneficiary Address 3 */
  beneficiaryAddress3: Maybe<Scalars['String']['output']>;
  /** Beneficiary code */
  beneficiaryCode: Scalars['String']['output'];
  /** Beneficiary Country */
  beneficiaryCountry: Maybe<Scalars['String']['output']>;
  /** Beneficiary Name */
  beneficiaryName: Scalars['String']['output'];
  /** Beneficiary nickname */
  beneficiaryNickname: Maybe<Scalars['String']['output']>;
  /** Date of creation of the Standing Instruction */
  createdAt: Scalars['Date']['output'];
  /** User that created the Standing Instruction */
  createdBy: Scalars['String']['output'];
  /** Financial Institution address 1 */
  fiAddress1: Maybe<Scalars['String']['output']>;
  /** Financial Institution address 2 */
  fiAddress2: Maybe<Scalars['String']['output']>;
  /** Financial Institution code */
  fiCode: Scalars['String']['output'];
  /** Financial Institution country */
  fiCountry: Maybe<Scalars['String']['output']>;
  /** Financial Institution ID */
  fiID: Scalars['String']['output'];
  /** Financial Institution name */
  fiName: Scalars['String']['output'];
  /** Intermediate Financial Institution address 1 */
  intermediateAddress1: Maybe<Scalars['String']['output']>;
  /** Intermediate Financial Institution address 2 */
  intermediateAddress2: Maybe<Scalars['String']['output']>;
  /** intermediate Financial Institution code */
  intermediateCode: Maybe<Scalars['String']['output']>;
  /** Intermediate Financial Institution country */
  intermediateCountry: Maybe<Scalars['String']['output']>;
  /** Intermediate Financial Institution ID */
  intermediateID: Maybe<Scalars['String']['output']>;
  /** Intermediate Financial Institution name */
  intermediateName: Maybe<Scalars['String']['output']>;
  /** Memo field */
  memo: Maybe<Scalars['String']['output']>;
  /** Organization to which the standing instruction belongs */
  organizationKeyID: Scalars['String']['output'];
  /**
   * Routing Number
   * @deprecated Use 'fiID' instead
   */
  routingNumber: Scalars['String']['output'];
  /** Unique Identifier of a Standing Instruction */
  standingInstructionID: Scalars['String']['output'];
  /** Current state of the Standing Instruction */
  state: Maybe<StandingInstructionStateType>;
  /** Last date of a change of the Standing Instruction */
  updatedAt: Scalars['Date']['output'];
  /** User that updated the Standing Instruction */
  updatedBy: Scalars['String']['output'];
};

/** StandingInstructionStateType provides existing states for a standing instruction */
export enum StandingInstructionStateType {
  /** Active, has been approved */
  ACTIVE = 'ACTIVE',
  /** Disabled */
  DISABLED = 'DISABLED',
  /** Pending, is waiting of approval */
  PENDING = 'PENDING'
}

/** StateType indicates how the state show be displayed to the user */
export enum StateType {
  /** This is a successful end state */
  COMPLETE = 'COMPLETE',
  /** This is a failed end state */
  FAILED = 'FAILED',
  /** This state hasn't been reached yet */
  FUTURE = 'FUTURE',
  /** This is a regular past state */
  PAST = 'PAST',
  /** This is the current state unless the current state is COMPLETE or FAILED */
  PRESENT = 'PRESENT'
}

/** Statement contains information required to retrieve a statement file from GCS */
export type Statement = {
  __typename: 'Statement';
  /** Account this statement is associated with */
  account: Account;
  /** Affiliate Email */
  affiliateEmail: Maybe<Scalars['String']['output']>;
  /** Affiliate Name */
  affiliateName: Maybe<Scalars['String']['output']>;
  /** Created At */
  createdAt: Maybe<Scalars['String']['output']>;
  /** DisplayDate date document is valid to. It is unix nanos timestamp that has been stringified */
  displayDate: Scalars['String']['output'];
  /** DisplayName display name for document, derived from contents of statement record */
  displayName: Scalars['String']['output'];
  /** Specific id given domain */
  domainSpecificId: Maybe<Scalars['String']['output']>;
  /** Date of email notification */
  emailSentAt: Scalars['String']['output'];
  /** Ending date of the statement. RFC3339 format. eg "2024-02-29". Inclusive. */
  endDate: Scalars['String']['output'];
  /** Error Message */
  errMsg: Scalars['String']['output'];
  /** Extension is the file extension of this statement */
  extension: Scalars['String']['output'];
  /** Status of this statement's generation (eg, IN_PROGRESS, COMPLETED) */
  generationStatus: StatementGenerationStatus;
  /** Unique identifier */
  id: Scalars['ID']['output'];
  /** Link allows users to download the document through anchorage */
  link: Scalars['String']['output'];
  /** Organization this statement is associated with */
  organization: Organization;
  /** Starting date of the statement. RFC3339 format. eg "2024-02-01". */
  startDate: Scalars['String']['output'];
  /** Statement type (eg, MONTHLY_STATEMENT, BALANCE_REPORT) */
  statementKind: StatementType;
  /**
   * Statement type
   * @deprecated Use statementKind
   */
  statementType: Scalars['String']['output'];
};

/** Statement connection for pagination */
export type StatementConnection = {
  __typename: 'StatementConnection';
  /** List of edges containing statement and cursor information */
  edges: Array<StatementEdge>;
  /** Page details for the associated list of statement edges */
  pageInfo: PageInfo;
};

/** Statement edge for pagination */
export type StatementEdge = {
  __typename: 'StatementEdge';
  /** Cursor for the associated statement */
  cursor: Scalars['ID']['output'];
  /** Statement */
  node: Statement;
};

/** Statement/BalanceSheet Report/Transaction History Report generation response */
export type StatementGenResponse = {
  __typename: 'StatementGenResponse';
  /**
   * End time of the statement duration. UTC. Inclusive.
   * eg "2023-12-01", "2023-12-01T13:30".
   */
  endTimeInclusiveRFC3339: Scalars['String']['output'];
  /** If status=FAILED, detail about the failure */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** GCS filename uploaded */
  filename: Scalars['String']['output'];
  /**
   * Start time of the statement duration. UTC. Inclusive.
   * eg "2023-12-01", "2023-12-01T13:30".
   */
  startTimeRFC3339: Scalars['String']['output'];
  /** Statement Generation status */
  status: StatementGenerationStatus;
  /** Version of the statement for the duration. */
  version: Scalars['Int']['output'];
  /** ID of the workflow performing the generation */
  workflowID: Scalars['String']['output'];
};

/** Statement/BalanceSheet Report/Transaction History Report generation status */
export enum StatementGenerationStatus {
  /** Statement/BalanceSheet Report/Transaction History Report generation workflow has completed successfully. */
  COMPLETED = 'COMPLETED',
  /** Statement/BalanceSheet Report/Transaction History Report generation workflow has completed with failure. */
  FAILED = 'FAILED',
  /** Statement/BalanceSheet Report/Transaction History Report generation workflow has been started. */
  IN_PROGRESS = 'IN_PROGRESS',
  /** Statement/BalanceSheet Report/Transaction History Report generation workflow has been requested (but not yet started). */
  REQUESTED = 'REQUESTED',
  /**
   * UNKNOWN is a status reserved when the service would have to panic otherwise.
   * Don't use this status if returning an error is possible.
   * Don't use this status if returning any other status is more appropriate.
   */
  UNKNOWN = 'UNKNOWN'
}

/** StatementType represents the statement types we produce */
export enum StatementType {
  /** Daily Balance Report */
  BALANCE_REPORT = 'BALANCE_REPORT',
  /** Daily Balance Report by Vaults */
  BALANCE_REPORT_VAULT = 'BALANCE_REPORT_VAULT',
  /** Daily Balance Report by Vault Addresses */
  BALANCE_REPORT_VAULT_ADDRESS = 'BALANCE_REPORT_VAULT_ADDRESS',
  /** Brokerage statement */
  BROKERAGE_STATEMENT = 'BROKERAGE_STATEMENT',
  /** Collateral statement */
  COLLATERAL_STATEMENT = 'COLLATERAL_STATEMENT',
  /** Dummy statement for testing */
  DUMMY_STATEMENT = 'DUMMY_STATEMENT',
  /** Monthly statement for ETFs */
  ETF_MONTHLY_STATEMENT = 'ETF_MONTHLY_STATEMENT',
  /** Lending statement */
  LENDING_STATEMENT = 'LENDING_STATEMENT',
  /** Monthly statement for fiat */
  MONTHLY_FIAT_STATEMENT = 'MONTHLY_FIAT_STATEMENT',
  /** Monthly prefunded statement */
  MONTHLY_PREFUNDED_STATEMENT = 'MONTHLY_PREFUNDED_STATEMENT',
  /** Monthly statement */
  MONTHLY_STATEMENT = 'MONTHLY_STATEMENT',
  /** Monthly statement for program customer */
  PROGRAM_CUSTOMER_MONTHLY_STATEMENT = 'PROGRAM_CUSTOMER_MONTHLY_STATEMENT',
  /** Monthly statement for RIAs */
  RIA_MONTHLY_STATEMENT = 'RIA_MONTHLY_STATEMENT',
  /** Transaction history report (aka Activities) */
  TRANSACTION_HISTORY = 'TRANSACTION_HISTORY',
  /** deprecated - use TRANSACTION_HISTORY */
  TRANSACTION_REPORT = 'TRANSACTION_REPORT'
}

/** Extra parameters for stellar */
export type StellarExtraParams = {
  __typename: 'StellarExtraParams';
  /** memo to use in transaction */
  memo: Maybe<Scalars['String']['output']>;
};

/** Subaccount represents a Subaccount under an Affiliate */
export type Subaccount = {
  __typename: 'Subaccount';
  /** Assets under the subaccount */
  assets: Array<SubaccountAsset>;
  /** Timestamp of when the subaccount was created */
  createdAt: Scalars['Time']['output'];
  /** Identifier of the subaccount */
  id: Scalars['String']['output'];
  /** Returns true if the subaccount is the Unassigned Funds Subaccount */
  isUnassignedFundsSubaccount: Scalars['Boolean']['output'];
  /** Name of the Subaccount */
  name: Scalars['String']['output'];
  /** Identifier for the Organization */
  organizationId: Scalars['String']['output'];
  /** USD value of the assets under the subaccount */
  postedBalance: Amount;
  /** Returns the type of Subaccount */
  subaccountType: SubaccountType;
  /** Returns a transaction with TransactionID and SubaccountID */
  transaction: SubccountTransaction;
  /** Transactions under a Subaccount */
  transactions: Array<SubaccountTransactionEdge>;
};


/** Subaccount represents a Subaccount under an Affiliate */
export type SubaccounttransactionArgs = {
  filterTransactionID: Scalars['String']['input'];
};

/** SubaccountCustomerFields represents an Affiliate withn the Subaccount System */
export type SubaccountAffiliateCustomerFields = {
  __typename: 'SubaccountAffiliateCustomerFields';
  /** affiliateID represents the identifier of the Program Customer (Affiliate) */
  affiliateID: Scalars['String']['output'];
  /** Represents the KYC Information of the Affiliate */
  affiliateInformation: InstitutionalAffiliate;
  /** Number of subaccounts under the Affiliate */
  numberOfAccounts: Scalars['Int']['output'];
  /** Estimate of the USD value of the Assets under the Subaccounts of the Affiliate */
  postedBalance: Amount;
  /** Balance Per asset that is Withdrawable by the Affiliate */
  postedBalancePerAsset: Array<Amount>;
};

/** Represents a balance in the subaccount and the balance type */
export type SubaccountAmount = {
  __typename: 'SubaccountAmount';
  /** Amount of that Balance Per Asset */
  amount: Amount;
  /** Represents thet type of Balance */
  balanceType: BalanceType;
};

/** Represents Assets held by a Subaccount */
export type SubaccountAsset = {
  __typename: 'SubaccountAsset';
  /** AssetTypeID of the asset */
  assetTypeInfo: AssetTypeInfo;
  /** Balance that can be traded */
  buyingPower: Amount;
  /** Posted balance of the Asset */
  postedBalance: Amount;
  /** Balance that can be withdrawn from the Subaccount */
  withdrawableBalance: Amount;
};

/** Subaccounts organizations types */
export enum SubaccountOrganizationType {
  /** Organization is an ETF issuer */
  ETF_ISSUER = 'ETF_ISSUER',
  /** Organization is a SMA */
  SMA = 'SMA'
}

/** Represents a Subacount Transaction Edge */
export type SubaccountTransactionEdge = {
  __typename: 'SubaccountTransactionEdge';
  /** Represents a Subaccount Transaction */
  node: Maybe<SubccountTransaction>;
};

/** Represents an Entry of a Transaction */
export type SubaccountTransactionEntry = {
  __typename: 'SubaccountTransactionEntry';
  /** Identifier of the Ledger Account involved in the Transaction Entry */
  accountId: Scalars['String']['output'];
  /** Amount of the Asset */
  amount: Amount;
  /** Information related to the Asset */
  assetTypeInfo: AssetTypeInfo;
  /** Type of Balance (POSTED; PENDING; HOLD) */
  balanceType: BalanceType;
  /** Timestamp of the Subaccount Entry */
  createdAt: Scalars['Time']['output'];
  /** Identifier of the entry */
  id: Scalars['String']['output'];
  /** Metadata of the entry */
  metadata: Maybe<Scalars['String']['output']>;
};

/** Represents the different types of subaccount */
export enum SubaccountType {
  /** This subaccount is used for ETF Funds */
  ETF_FUNDS = 'ETF_FUNDS',
  /** This subaccount used for the FBO Customer Fees */
  FBO_CUSTOMER_FEES = 'FBO_CUSTOMER_FEES',
  /** This subaccount is used to keep track of Gas Fees */
  GAS_FEES = 'GAS_FEES',
  /** This subaccount is used as Payable and Receivables of an RIA */
  PAYABLES_RECEIVABLES = 'PAYABLES_RECEIVABLES',
  /** This subaccount is a Program Customer */
  PROGRAM_CUSTOMER = 'PROGRAM_CUSTOMER',
  /** This subaccount is used for unassigned Funds */
  UNASSIGNED_FUNDS = 'UNASSIGNED_FUNDS'
}

/** Represents a Subaccount Transaction */
export type SubccountTransaction = {
  __typename: 'SubccountTransaction';
  /** Flag that let us know if the Transaction allows Overdraft Balance */
  allowOverdraftBalance: Maybe<Scalars['Boolean']['output']>;
  /** Flag that let us know if the Transaction allows Pending Balance */
  allowPendingBalance: Maybe<Scalars['Boolean']['output']>;
  /** Show the current Balance for each balance type */
  amountsPerBalanceType: Array<SubaccountAmount>;
  /** Timestamp of when a transaction was created */
  createdAt: Scalars['Time']['output'];
  /** Description of a transaction */
  description: Maybe<Scalars['String']['output']>;
  /** Transaction entries */
  entries: Array<SubaccountTransactionEntry>;
  /** Identifier of the Transaction */
  id: Scalars['String']['output'];
  /** Metadata of a transaction */
  metadata: Maybe<Scalars['String']['output']>;
  /** Operation ID if this transaction envolves some on-chain action */
  operationID: Maybe<Scalars['OperationID']['output']>;
  /** Status of a Transaction */
  status: TransactionStatus;
  /** Type of a Transaction */
  transactionType: TransactionType;
  /** Timestamp of a transaction was last updated */
  updatedAt: Maybe<Scalars['Time']['output']>;
};

/** SubmissionMetadata holds metadata related to a specific submission */
export type SubmissionMetadata = {
  __typename: 'SubmissionMetadata';
  /** program signer data related to RIA program signer */
  riaProgramSignerData: Maybe<ProgramSignerData>;
};

/** Extra parameters for assets */
export type SupportedExtraParams = {
  __typename: 'SupportedExtraParams';
  /** fieldNames to use in transaction */
  fieldNames: Maybe<Array<Scalars['String']['output']>>;
  /** fieldValues to use in transaction */
  fieldValues: Maybe<Array<Scalars['String']['output']>>;
};

/** TMSConfigs contains the transaction monitoring system configurations */
export type TMSConfigs = {
  __typename: 'TMSConfigs';
  /** Time at which this TMS configuration version was created */
  createdAt: Scalars['Timestamp']['output'];
  /** TMS configuration entity type */
  entityType: TMSRuleEntityType;
  /** configs as JSON */
  tmsConfigBody: Scalars['String']['output'];
  /** Unique ID for this particular TMS configuration version */
  tmsConfigID: Scalars['String']['output'];
  /** TMS configuration version */
  tmsConfigVersion: Scalars['Int']['output'];
};

/** TMSConfigsConnection contains the edges to TMSConfigs */
export type TMSConfigsConnection = {
  __typename: 'TMSConfigsConnection';
  /** List of edges containing transaction monitoring configs */
  edges: Array<TMSConfigsEdge>;
  /** Page details for the associated list of TMSConfigEdge */
  pageInfo: PageInfo;
};

/** TMSConfigsEdge contains the TMSConfigs */
export type TMSConfigsEdge = {
  __typename: 'TMSConfigsEdge';
  /** Cursor for the TMSConfigsEdge */
  cursor: Scalars['String']['output'];
  /** TMSConfigs */
  node: TMSConfigs;
};

/** TMSRuleEntityType represents the anchorage entity type of TMS rule */
export enum TMSRuleEntityType {
  /** SINGAPORE rules for monitoring Anchorage Digital Singapore */
  SINGAPORE = 'SINGAPORE',
  /** TRUST_COMPANY rules for monitoring Anchorage Digital Bank */
  TRUST_COMPANY = 'TRUST_COMPANY'
}

/** TMSRuleType represents the type of TMS rule */
export enum TMSRuleGroup {
  /** rules monitoring the accuracy of transaction attribution data */
  ATTRIBUTION_MONITORING = 'ATTRIBUTION_MONITORING',
  /** transaction monitoring rules targeting specific behavior based on customer type */
  CUSTOMER_TYPE = 'CUSTOMER_TYPE',
  /** behavioral rules for monitoring digital asset transactions */
  DIGITAL_ASSET = 'DIGITAL_ASSET',
  /** behavioral rules for monitoring fiat transactions */
  FIAT = 'FIAT'
}

/** TMSRuleGroupMetadata contains the metadata for a TMS rule group */
export type TMSRuleGroupMetadata = {
  __typename: 'TMSRuleGroupMetadata';
  /** TMS rule group description */
  description: Scalars['String']['output'];
  /** TMS configuration entity type */
  entityType: TMSRuleEntityType;
  /** TMS rule group */
  group: TMSRuleGroup;
  /** TMS rule group label */
  label: Scalars['String']['output'];
  /** TMS rule metadata */
  rulesMetadata: Array<TMSRuleMetadata>;
};

/** TMSRuleMetadata contains the metadata for a TMS rule */
export type TMSRuleMetadata = {
  __typename: 'TMSRuleMetadata';
  /** TMS rule description */
  description: Scalars['String']['output'];
  /** TMS rule label */
  label: Scalars['String']['output'];
  /** TMS rule name */
  name: TMSRuleName;
};

/** TMSRuleID represents the name of a TMS rule */
export enum TMSRuleName {
  /** The rule identifies a customer's activity following a prolonged period without a transaction */
  DORMANT_ACCOUNT = 'DORMANT_ACCOUNT',
  /** The rule identifies the risk of a customer immediately washing funds to obscure their source of funds */
  FLOWTHROUGH_OF_FUNDS = 'FLOWTHROUGH_OF_FUNDS',
  /** The rule identifies deviations from the activity expected of Funds */
  FUND = 'FUND',
  /**
   * The rule is intended to separate customers which transact in a meaningfully
   * different way, when compared to the rest of the customer population
   */
  HIGH_VOLUME_CUSTOMERS = 'HIGH_VOLUME_CUSTOMERS',
  /** The rule identifies deviations from a customer’s historical activity */
  HISTORICAL_PROFILE_DEVIATION = 'HISTORICAL_PROFILE_DEVIATION',
  /** The rule identifies deviations from the activity expected of customer types that have low expected activity */
  LOW_ACTIVITY_CUSTOMERS = 'LOW_ACTIVITY_CUSTOMERS',
  /** The rule identifies deviations from the activity expected of Miners */
  MINING = 'MINING',
  /**
   * The rule identifies a group of customers sending funds to a potential hub
   * which could indicate hidden relationships or layering of funds
   */
  MULTIPLE_ORIGINATORS = 'MULTIPLE_ORIGINATORS',
  /** The rule identifies devidations from the activity expected of Non-Banking-Financial-Institutions */
  NBFI = 'NBFI',
  /**
   * Detects transaction activity that avoids detection of the Travel Rule
   * by breaking down larger transactions into multiple small transactions
   */
  REPORTING_EVASION = 'REPORTING_EVASION',
  /**
   * The rule identifies a customer sending funds to a number of counterparties thus
   * acting as a potential hub which could indicate hidden relationships or layering of funds
   */
  SINGLE_ORIGINATOR = 'SINGLE_ORIGINATOR'
}

/** A tax tenant */
export type TaxTenant = {
  __typename: 'TaxTenant';
  /** Taxbit's client identifier */
  clientID: Scalars['ID']['output'];
  /** Taxbit's client secret */
  clientSecret: Scalars['String']['output'];
  /** Time when the tenant was created */
  createdAt: Scalars['Timestamp']['output'];
  /** Who created the tenant */
  createdBy: Scalars['String']['output'];
  /** Unique tenant identifier */
  id: Scalars['ID']['output'];
  /** Organization of which the tenant is a member. */
  organization: Organization;
  /** Time when the tenant was last updated */
  updatedAt: Scalars['Timestamp']['output'];
  /** Who last updated the tenant */
  updatedBy: Scalars['String']['output'];
};

/** Extra parameters for tendermint */
export type TendermintExtraParams = {
  __typename: 'TendermintExtraParams';
  /** memo to use in transaction */
  memo: Maybe<Scalars['String']['output']>;
  /** redelegateSrc is used when redelegating with the value of the source validator */
  redelegateSrc: Maybe<Scalars['String']['output']>;
};

/** TerminateWalletConnectSessionResponse */
export type TerminateWalletConnectSessionResponse = {
  __typename: 'TerminateWalletConnectSessionResponse';
  /** Was request successful */
  success: Scalars['Boolean']['output'];
};

/** TimeInForce represents */
export enum TimeInForce {
  /** TimeInForceDay represents */
  DAY = 'DAY',
  /** TimeInForceFillAndKill represents */
  FILL_AND_KILL = 'FILL_AND_KILL',
  /** TimeInForceFillOrKill represents */
  FILL_OR_KILL = 'FILL_OR_KILL',
  /** TimeInForceGoodTillCancel represents */
  GOOD_TILL_CANCEL = 'GOOD_TILL_CANCEL',
  /** Invalid represents */
  INVALID = 'INVALID'
}

/** TODO: add a description */
export type TimeUntilStages = {
  __typename: 'TimeUntilStages';
  /** Seconds left until the execution stage */
  execution: Scalars['String']['output'];
  /** Seconds left until the expiration stage */
  expiration: Scalars['String']['output'];
  /** Seconds left until the referendum stage */
  referendum: Scalars['String']['output'];
};

/** Holder for info for token like assets */
export type TokenInfo = {
  /** The asset type id for this token */
  assetTypeID: Scalars['AssetTypeID']['output'];
  /** The unique id for this token */
  tokenID: Scalars['String']['output'];
};

/** Trade contains information about a trade */
export type Trade = {
  __typename: 'Trade';
  /** Brokerage account */
  account: Maybe<TradingAccount>;
  /**
   * Affiliate Entity ID associated with the trade
   * To be deprecated, not doing it right away because there's no
   * substitute so far.
   */
  accountID: Maybe<Scalars['ID']['output']>;
  /**
   * Amount bought
   * @deprecated Use `buyTradingAmount`.
   */
  buyAmount: Amount;
  /** Amount bought */
  buyTradingAmount: TradingAmount;
  /**
   * Fee Amount is the amount of the fee
   * @deprecated Use `feeTradingAmount`
   */
  feeAmount: Maybe<Amount>;
  /** Fee Amount is the amount of the fee */
  feeTradingAmount: Maybe<TradingAmount>;
  /** Initiator of the trade. */
  initiator: Initiator;
  /** Initiator of the trade. Use this in place of `initiator` for new code. */
  initiatorV2: Actor;
  /** Internal information contains fields whose contents shall only be visible to Anchorage's staff (e.g., Risk dashboard) */
  internalInfo: Maybe<TradeInternalInfo>;
  /** Market is the name of the OTC used on the trade */
  market: Scalars['String']['output'];
  /** Order ID of the trade */
  orderID: Maybe<Scalars['ID']['output']>;
  /** Order type, market, limit, rfq, etc. */
  orderType: OrdType;
  /** Organization to which the initiator belongs to */
  organization: Organization;
  /** Parent Trade identifier */
  parentTradeID: Maybe<Scalars['ID']['output']>;
  /**
   * Amount sold
   * @deprecated Use `sellTradingAmount`.
   */
  sellAmount: Amount;
  /** Amount sold */
  sellTradingAmount: TradingAmount;
  /** Settlement identifier, if it exists */
  settlementID: Maybe<Scalars['ID']['output']>;
  /** Side of the trade */
  side: TradeSide;
  /** Trade source, e.g. API, Manual, WhiteLabel */
  sourceType: TradeSource;
  /** List of different states of the trade (should not be requested in a trades list only when requesting a single trade) */
  states: Array<TradeState>;
  /** Status of the trade */
  status: TradeStatus;
  /** The system timestamp when this trade was received */
  timestamp: Scalars['Timestamp']['output'];
  /** ID of this trade */
  tradeID: Scalars['ID']['output'];
  /** Price at which the order was executed */
  tradedPrice: Scalars['String']['output'];
  /**
   * Price amount at which the order was executed
   * @deprecated Use `tradedPrice`
   */
  tradedPriceAmount: Amount;
  /** Pair of assets this trade is for */
  tradingPair: Scalars['String']['output'];
  /** The quoted price for the order */
  unitPrice: Scalars['String']['output'];
  /**
   * Price amount at which the order was executed
   * @deprecated Use `unitPrice`
   */
  unitPriceAmount: Amount;
  /** Vault information for this trade */
  vault: Maybe<Vault>;
  /**
   * Vault ID associated with the trade
   * @deprecated Use `vault`.
   */
  vaultID: Maybe<Scalars['ID']['output']>;
};

/** Possible trade capacities */
export enum TradeCapacity {
  /** Anchorage acted as Agent for Customer */
  AGENT_FOR_CUSTOMER = 'AGENT_FOR_CUSTOMER',
  /** Anchorage acted as Agent for Customer and a Third Party */
  AGENT_FOR_CUSTOMER_AND_3RD_PARTY = 'AGENT_FOR_CUSTOMER_AND_3RD_PARTY',
  /** Anchorage acted in other capacity */
  OTHER = 'OTHER',
  /** Anchorage acted as Principal */
  PRINCIPAL = 'PRINCIPAL'
}

/** Trade execution report */
export type TradeExecutionReport = {
  __typename: 'TradeExecutionReport';
  /** aggressorSide represents */
  aggressorSide: Maybe<TradeSide>;
  /** amountCurrency represents */
  amountCurrency: Scalars['String']['output'];
  /** avgPx represents */
  avgPx: Scalars['String']['output'];
  /** avgPxAllIn represents */
  avgPxAllIn: Maybe<Scalars['String']['output']>;
  /** cancelSessionID represents */
  cancelSessionID: Maybe<Scalars['String']['output']>;
  /** clOrdID represents */
  clOrdID: Scalars['String']['output'];
  /** createdAt represents */
  createdAt: Scalars['Timestamp']['output'];
  /** cumAmt represents */
  cumAmt: Scalars['String']['output'];
  /** cumProviderFee represents */
  cumProviderFee: Maybe<Scalars['String']['output']>;
  /** cumQty represents */
  cumQty: Scalars['String']['output'];
  /** currency represents */
  currency: Scalars['String']['output'];
  /** cxlRejReason represents */
  cxlRejReason: Maybe<CxlRejReason>;
  /** execID represents */
  execID: Scalars['String']['output'];
  /** execType represents */
  execType: ExecType;
  /** feeCurrency represents */
  feeCurrency: Maybe<Scalars['String']['output']>;
  /** flowType represents */
  flowType: FlowType;
  /** groupID represents */
  groupID: Maybe<Scalars['String']['output']>;
  /** id represents */
  id: Scalars['String']['output'];
  /** internalSettlementID represents */
  internalSettlementID: Maybe<Scalars['String']['output']>;
  /** internalStatus represents */
  internalStatus: InternalStatus;
  /** lastAmt represents */
  lastAmt: Maybe<Scalars['String']['output']>;
  /** lastFee represents */
  lastFee: Maybe<Scalars['String']['output']>;
  /** lastFeeCurrency represents */
  lastFeeCurrency: Maybe<Scalars['String']['output']>;
  /** lastMarket represents */
  lastMarket: Maybe<Scalars['String']['output']>;
  /** lastProviderFee represents */
  lastProviderFee: Maybe<Scalars['String']['output']>;
  /** lastPx represents */
  lastPx: Maybe<Scalars['String']['output']>;
  /** lastQty represents */
  lastQty: Maybe<Scalars['String']['output']>;
  /** leavesQty represents */
  leavesQty: Scalars['String']['output'];
  /** OrdRejReason represents */
  ordRejReason: Maybe<OrdRejReason>;
  /** ordStatus represents */
  ordStatus: OrdStatus;
  /** ordType represents */
  ordType: OrdType;
  /** orderID represents */
  orderID: Scalars['String']['output'];
  /** orderQty represents */
  orderQty: Scalars['String']['output'];
  /** orderSessionID represents */
  orderSessionID: Maybe<Scalars['String']['output']>;
  /** origClOrdID represents */
  origClOrdID: Maybe<Scalars['String']['output']>;
  /** parentOrderID represents */
  parentOrderID: Scalars['String']['output'];
  /** parentRFQID represents */
  parentRFQID: Scalars['String']['output'];
  /** price represents */
  price: Maybe<Scalars['String']['output']>;
  /** providerFeeCurrency represents */
  providerFeeCurrency: Maybe<Scalars['String']['output']>;
  /** requestUser represents */
  requestUser: Scalars['String']['output'];
  /** revision represents */
  revision: Scalars['Int']['output'];
  /** side represents */
  side: TradeSide;
  /** subAccount represents */
  subAccount: Maybe<Scalars['String']['output']>;
  /** symbol represents */
  symbol: Scalars['String']['output'];
  /** textMessage represents */
  textMessage: Maybe<Scalars['String']['output']>;
  /** timeInForce represents */
  timeInForce: TimeInForce;
  /** transactTime represents */
  transactTime: Scalars['Timestamp']['output'];
};

/** Trade execution report connection for pagination */
export type TradeExecutionReportConnection = {
  __typename: 'TradeExecutionReportConnection';
  /** List of edges containing trade execution report and cursor information */
  edges: Array<Maybe<TradeExecutionReportEdge>>;
  /** Page details for the associated list of  trade execution report edges */
  pageInfo: PageInfo;
};

/** Trade execution report edge for pagination */
export type TradeExecutionReportEdge = {
  __typename: 'TradeExecutionReportEdge';
  /** Cursor for the associated trade execution report */
  cursor: Scalars['String']['output'];
  /** Trade execution report */
  node: TradeExecutionReport;
};

/** TradeInternalInfo contains internal information about a trade */
export type TradeInternalInfo = {
  __typename: 'TradeInternalInfo';
  /** Capacity in which Anchorage acted on this trade */
  capacity: TradeCapacity;
  /**
   * Market is the name of the OTC used on the trade
   * @deprecated Use `Trade.Market`.
   */
  market: Scalars['String']['output'];
  /** list of different states of the trade */
  states: Array<TradeState>;
};

/** TODO: add a description */
export type TradeQuoteResponse = {
  __typename: 'TradeQuoteResponse';
  /** Human-readable error message if trade was not executed */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** True if trade was executed, false otherwise */
  executed: Scalars['Boolean']['output'];
  /** Timestamp of the most recent trade state (time of execution if executed) */
  timestamp: Scalars['Timestamp']['output'];
};

/** TradeSettings contains organization-specific trading settings */
export type TradeSettings = {
  __typename: 'TradeSettings';
  /** RFQ TTL for quotes requested by this organization (duration string, e.g. "3s") */
  RFQTTL: Scalars['String']['output'];
  /** Accept price leniency for quotes requested by this organization (decimal as string, e.g. "0.003") */
  acceptPriceLeniency: Scalars['String']['output'];
  /** Accept time leniency for quotes requested by this organization (duration string, e.g. "3s") */
  acceptTimeLeniency: Scalars['String']['output'];
  /** Prices timeout for quotes requested by this organization (duration string, e.g. "3s") */
  pricesTimeout: Scalars['String']['output'];
  /** Quote TTL for quotes requested by this organization (duration string, e.g. "3s") */
  quoteTTL: Scalars['String']['output'];
  /** Whether this organization uses the fintech flow */
  useFintechFlow: Scalars['Boolean']['output'];
};

/** TradeSettlementTransaction contains information about a trade settlement transaction */
export type TradeSettlementTransaction = {
  __typename: 'TradeSettlementTransaction';
  /** Asset type */
  assetTypeID: Scalars['AssetTypeID']['output'];
  /** Time when added */
  createdAt: Scalars['Timestamp']['output'];
  /** User who created */
  createdBy: Scalars['String']['output'];
  /** ID of this trade settlement transaction */
  id: Scalars['ID']['output'];
  /** Settlement ID */
  settlementID: Scalars['String']['output'];
  /** Transaction ID */
  transactionID: Scalars['String']['output'];
  /** Time when updated */
  updatedAt: Scalars['Timestamp']['output'];
  /** User who updated */
  updatedBy: Scalars['String']['output'];
};

/** Trade settlement transaction connection for pagination */
export type TradeSettlementTransactionConnection = {
  __typename: 'TradeSettlementTransactionConnection';
  /** List of edges containing trade settlement transaction and cursor information */
  edges: Array<Maybe<TradeSettlementTransactionEdge>>;
  /** Page details for the associated list of trade settlement transaction edges */
  pageInfo: PageInfo;
};

/** Trade settlement transaction edge for pagination */
export type TradeSettlementTransactionEdge = {
  __typename: 'TradeSettlementTransactionEdge';
  /** Cursor for the associated trade settlement transaction */
  cursor: Scalars['String']['output'];
  /** Trade settlement transaction */
  node: TradeSettlementTransaction;
};

/** Possible trade sides */
export enum TradeSide {
  /** Buying base asset type */
  BUY = 'BUY',
  /** Selling base asset type */
  SELL = 'SELL'
}

/** TradeSource represents one of possible channels where the trade might have originated from */
export enum TradeSource {
  /** ADVANCED represents a trade placed through the advanced dashboard */
  ADVANCED = 'ADVANCED',
  /** API represents a trade placed through the Anchorage API */
  API = 'API',
  /** MANUAL represents a manually booked trade */
  MANUAL = 'MANUAL',
  /** UI represents a trade placed through the Anchorage UI */
  UI = 'UI',
  /** UNSPECIFIED represents an unknown source */
  UNSPECIFIED = 'UNSPECIFIED',
  /**
   * WL represents a trade placed through the white label
   * @deprecated Use ADVANCED instead
   */
  WL = 'WL'
}

/** State of a trade */
export type TradeState = {
  __typename: 'TradeState';
  /** Timestamp of the trade state */
  createdAt: Scalars['Timestamp']['output'];
  /** Notes */
  notes: Scalars['String']['output'];
  /** Promoter */
  promoter: Scalars['String']['output'];
  /** Promoter type */
  promoterType: PromoterType;
  /** Trade status */
  status: TradeStatus;
  /** ID of the trade */
  tradeID: Scalars['String']['output'];
};

/** TradeStatus is the different statuses that a trade can go through */
export enum TradeStatus {
  /** The trade has been allocated by the Desk */
  ALLOCATED = 'ALLOCATED',
  /** The trade was canceled by the Anchorage trading team due to client legal/financial problems */
  CANCELED = 'CANCELED',
  /** The trade was executed by the OTC desk and Anchorage is awaiting customer deposit */
  EXECUTED = 'EXECUTED',
  /** Customer has placed a trade request */
  PENDING = 'PENDING',
  /** The PENDING_ALLOCATION trade is in a dormant state in which it's waiting to be allocated */
  PENDING_ALLOCATION = 'PENDING_ALLOCATION',
  /** The trade was rejected by the OTC desk */
  REJECTED = 'REJECTED',
  /** The broker received funds and assets were sent to the customer */
  SETTLED = 'SETTLED',
  /** Anchorage has received customer deposit and is in the process of settling with the OTC desk */
  SETTLING = 'SETTLING'
}

/** TradeStrategy represents */
export enum TradeStrategy {
  /** TradeStrategyAggressivePegged represents */
  AGGRESSIVE_PEGGED = 'AGGRESSIVE_PEGGED',
  /** TradeStrategyEmpty represents */
  EMPTY = 'EMPTY',
  /** TradeStrategyFullAmount represents */
  FULL_AMOUNT = 'FULL_AMOUNT',
  /** TradeStrategyIceberg represents */
  ICEBERG = 'ICEBERG',
  /** Invalid represents */
  INVALID = 'INVALID',
  /** TradeStrategyLimit represents */
  LIMIT = 'LIMIT',
  /** TradeStrategyManual represents */
  MANUAL = 'MANUAL',
  /** TradeStrategyMarket represents */
  MARKET = 'MARKET',
  /** TradeStrategyMarketTWAP represents */
  MARKET_TWAP = 'MARKET_TWAP',
  /** TradeStrategyMultilegTWAP represents */
  MULTI_LEG_TWAP = 'MULTI_LEG_TWAP',
  /** TradeStrategyPegged represents */
  PEGGED = 'PEGGED',
  /** TradeStrategyPercentOfVolume represents */
  PERCENT_OF_VOLUME = 'PERCENT_OF_VOLUME',
  /** TradeStrategyPostOnly represents */
  POST_ONLY = 'POST_ONLY',
  /** TradeStrategySteadyPace represents */
  STEADY_PACE = 'STEADY_PACE',
  /** TradeStrategyStopLimit represents */
  STOP_LIMIT = 'STOP_LIMIT',
  /** TradeStrategyTakeProfitLimit represents */
  TAKE_PROFIT_LIMIT = 'TAKE_PROFIT_LIMIT',
  /** TradeStrategyTimeSliced represents */
  TIME_SLICED = 'TIME_SLICED',
  /** TradeStrategyTWAP represents */
  TWAP = 'TWAP',
  /** TradeStrategyUnknown represents */
  UNKNOWN = 'UNKNOWN'
}

/** TradesView will be used for showing a collection of trades */
export type TradesView = {
  __typename: 'TradesView';
  /** edges implements the connections spec */
  edges: Array<Maybe<TradesViewEdge>>;
  /** pageInfo implements the connections spec */
  pageInfo: PageInfo;
};

/** TradesViewEdge implements the connections spec */
export type TradesViewEdge = {
  __typename: 'TradesViewEdge';
  /** cursor implements the connections spec */
  cursor: Scalars['String']['output'];
  /** node implements the connections spec */
  node: Trade;
};

/** TradingAccount is the type that represents the Brokerage Account */
export type TradingAccount = {
  __typename: 'TradingAccount';
  /**
   * Affiliate information, optional as this brokerage account
   * might not be linked.
   */
  account: Maybe<Account>;
  /** This account's buying power */
  buyingPower: Maybe<BuyingPower>;
  /** Id of the brokerage account */
  id: Scalars['ID']['output'];
  /** State of the brokerage account */
  isEnabled: Scalars['Boolean']['output'];
  /** Name of the brokerage account */
  name: Scalars['String']['output'];
  /** Organization the account belongs to */
  organization: Organization;
};

/**
 * TradingAmount is the combination of a value and asset
 * This is a super set of Amount in the sense that this can be used for
 * assets for which Anchorage does not support custody
 */
export type TradingAmount = {
  __typename: 'TradingAmount';
  /** Information about the asset */
  asset: TradingAsset;
  /** Display value for this amount */
  displayValue: Scalars['String']['output'];
  /**
   * Display value in USD for this amount
   * If the price cannot be resolved this will be null
   */
  displayValueInUSD: Maybe<Scalars['String']['output']>;
  /** The value for this amount */
  value: Scalars['String']['output'];
};

/**
 * Information about a trading asset
 * If the information about the asset is set correctly, then the only
 * optional field should be the custody code since we may support trading
 * assets that we do not support for custody.
 * However, we may end up having assets in the system that aren't yet registered
 * in the repository (e.g. if a trade is initiated outside of Anchorage and we just get reports with a foreign asset, we register it instead of disregarding it).
 * In that scenario, we would have the code field set correctly but then all the
 * other fields would be empty (i.e. empty string), the request won't be failed
 * to avoid leading to downtime on our dashboards because of this limitation.
 */
export type TradingAsset = {
  __typename: 'TradingAsset';
  /** Code is the unique identifier for a trading asset, is not mutable */
  code: Scalars['String']['output'];
  /**
   * Code is a client facing identifier for a trading asset, can be changed
   * in the future if the asset decides to change their name
   */
  codeAbbreviation: Scalars['String']['output'];
  /**
   * Custody Code is the respective custody asset for this trading asset
   * Since we may support assets for trading that we do not for custody this
   * field is optional
   */
  custodyCode: Maybe<Scalars['AssetTypeID']['output']>;
  /** Description of the trading asset */
  description: Scalars['String']['output'];
  /** Name of the trading asset */
  name: Scalars['String']['output'];
};

/** TradingAssetPrecision contains precision information about a trading symbol */
export type TradingAssetPrecision = {
  __typename: 'TradingAssetPrecision';
  /** Base asset (LHS) of the symbol */
  base: Scalars['String']['output'];
  /** Number of decimal points supported when specifying quantity in left hand side symbol */
  baseSizeScale: Scalars['Int']['output'];
  /**
   * Minimum size for base asset in order to successfully place an order
   * @deprecated Use 'minimumOrderSizeString' instead
   */
  minimumOrderSize: Scalars['Decimal']['output'];
  /** Minimum size as a string for base asset in order to successfully place an order */
  minimumOrderSizeString: Scalars['String']['output'];
  /** Number of decimal points supported when specifying price */
  priceScale: Scalars['Int']['output'];
  /** Quote asset (RHS) of the symbol */
  quote: Scalars['String']['output'];
  /** Number of decimal points supported when specifying quantity in right hand side symbol */
  quoteSizeScale: Scalars['Int']['output'];
};

/** Input to the query TradingAssets */
export type TradingAssetsInput = {
  /** Dummy field to satisfy the input requirement */
  dummy?: InputMaybe<Scalars['String']['input']>;
};

/** Input to the mutation TradingCreateAsset */
export type TradingCreateAssetInput = {
  /** Unique identifier for the trading asset */
  code: Scalars['String']['input'];
  /** Client facing identifier for the trading asset */
  codeAbbreviation: Scalars['String']['input'];
  /** Optional field for the respective custody asset for this trading asset */
  custodyCode?: InputMaybe<Scalars['AssetTypeID']['input']>;
  /** Description of the asset */
  description: Scalars['String']['input'];
  /** Name of the asset */
  name: Scalars['String']['input'];
};

/** Input to the mutation TradingCreateExternalAsset */
export type TradingCreateExternalAssetInput = {
  /** Unique identifier of the trading asset */
  code: Scalars['String']['input'];
  /** Representation of the asset for this particular external integration */
  externalCode: Scalars['String']['input'];
  /** Unique identifier of the external integration */
  externalIntegration: Scalars['String']['input'];
};

/** TradingCreateSettlementFromFiltersInput is the input for creating a settlement based on filters */
export type TradingCreateSettlementFromFiltersInput = {
  /**
   * AccountID for the settlement
   * Each settlement can only be associated with a single account
   */
  accountID: Scalars['String']['input'];
  /** AfterCreatedAtInclusive is the timestamp after which the trades get selected */
  afterCreatedAtInclusive?: InputMaybe<Scalars['Timestamp']['input']>;
  /** BeforeCreatedAtExclusive is the timestamp before which the trades get selected */
  beforeCreatedAtExclusive?: InputMaybe<Scalars['Timestamp']['input']>;
  /**
   * IsPreview is an optional flag that lets the caller preview the
   * settlement that would be created with the given filters
   */
  isPreview?: InputMaybe<Scalars['Boolean']['input']>;
  /** Order IDs selected for this settlement */
  orderIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * OrganizationKeyID for the settlement
   * Each settlement can only be associated with a single organization
   */
  organizationKeyID: Scalars['String']['input'];
};

/** Input to the mutation TradingDeleteAsset */
export type TradingDeleteAssetInput = {
  /** Code of the asset to be deleted */
  code: Scalars['String']['input'];
};

/** Input to the mutation TradingDeleteExternalAsset */
export type TradingDeleteExternalAssetInput = {
  /** Unique identifier of the external relationship */
  id: Scalars['ID']['input'];
};

/**
 * Information about the external asset, i.e. the mapping translation between
 * the Anchorage concept of a trading asset and the external concept of a trading asset
 */
export type TradingExternalAsset = {
  __typename: 'TradingExternalAsset';
  /** Unique identifier of the trading asset (e.g. BTC) */
  code: Scalars['String']['output'];
  /** Representation of the asset for this particular external integration */
  externalCode: Scalars['String']['output'];
  /** Unique identifier of the external integration (e.g. TALOS) */
  externalIntegration: Scalars['String']['output'];
  /** Unique identifier of the external relationship (uuidV4) */
  id: Scalars['ID']['output'];
};

/** Input to the query TradingExternalAssets */
export type TradingExternalAssetsInput = {
  /** Dummy field to satisfy the input requirement */
  dummy?: InputMaybe<Scalars['String']['input']>;
};

/** Describes a trading permission */
export type TradingPermission = {
  __typename: 'TradingPermission';
  /** Organization of the trading permission */
  organization: Maybe<Organization>;
  /** Type of the permission */
  permissionType: TradingPermissionType;
  /** Value of the permission */
  permissionValue: TradingPermissionValue;
};

/** Trading permissions to be saved */
export type TradingPermissionInput = {
  /** Organization to which the permissions will be applied */
  organizationKeyID?: InputMaybe<Scalars['String']['input']>;
  /** Type of the permission */
  permissionType?: InputMaybe<TradingPermissionType>;
  /** Value of the permission */
  permissionValue?: InputMaybe<TradingPermissionValue>;
};

/** Types of trading permissions */
export enum TradingPermissionType {
  /** Advanced trading features */
  ADVANCED = 'ADVANCED',
  /** Basic trading features */
  BASIC = 'BASIC'
}

/** Values of the trading permissions */
export enum TradingPermissionValue {
  /** Permission is disabled */
  DISABLED = 'DISABLED',
  /** Permission is enabled */
  ENABLED = 'ENABLED'
}

/** Set of trading permissions to be saved */
export type TradingPermissionsInput = {
  /**
   * List of permissions that are to be saved transactionally
   * If one operation fails, all operations will fail
   */
  permissions: Array<TradingPermissionInput>;
};

/** TradingPosition contains information about the outstanding amount of a trading position */
export type TradingPosition = {
  __typename: 'TradingPosition';
  /**
   * Outstanding amount
   * @deprecated Use `tradingAmount`.
   */
  amount: Amount;
  /** Outstanding amount */
  tradingAmount: TradingAmount;
};

/** TradingSettlementPreview previews the net outstanding amounts of a settlement before it is created */
export type TradingSettlementPreview = {
  __typename: 'TradingSettlementPreview';
  /** Net outstanding amounts of the future settlement */
  positions: Array<Amount>;
};

/** TradingSymbol contains information about a trading symbol */
export type TradingSymbol = {
  __typename: 'TradingSymbol';
  /** TradingAssetPrecision is the supported size and price increment for the symbol */
  assetPrecision: Maybe<TradingAssetPrecision>;
  /** AssetTypeInfo for the left side of the symbol, this will expose both 'assetTypeID' and 'abbreviation' */
  baseAssetTypeInfo: Maybe<AssetTypeInfo>;
  /** Internal information contains fields whose contents shall only be visible to Anchorage's staff (e.g., Risk dashboard) */
  internalInfo: Maybe<TradingSymbolInternalInfo>;
  /** Informs if the symbol is currently enabled for trading */
  isEnabled: Scalars['Boolean']['output'];
  /** AssetTypeInfo for the right side of the symbol, this will expose both 'assetTypeID' and 'abbreviation' */
  quoteAssetTypeInfo: Maybe<AssetTypeInfo>;
  /** The string format of a trading symbol, separated by "-" */
  symbol: Scalars['String']['output'];
};

/** TradingSymbolInternalInfo contains internal information about a trading symbol */
export type TradingSymbolInternalInfo = {
  __typename: 'TradingSymbolInternalInfo';
  /** Displays when the symbol was approved for trading */
  approvedAt: Maybe<Scalars['Timestamp']['output']>;
  /** Displays who approved the symbol for trading */
  approvedBy: Maybe<Scalars['String']['output']>;
  /** Displays when the symbol was inserted into the database */
  createdAt: Scalars['Timestamp']['output'];
  /** Displays who inserted the symbol into the database */
  createdBy: Scalars['String']['output'];
  /**
   * Entity associated with the trading symbol, its internal information
   * since we have no reason to expose this information to our customers
   */
  entity: AnchorageEntityType;
  /** UUID of the symbol */
  id: Scalars['ID']['output'];
  /** Displays when the symbol was last updated */
  updatedAt: Scalars['Timestamp']['output'];
  /** Displays who updated the symbol last */
  updatedBy: Scalars['String']['output'];
};

/** Holds the summary for the unsettled client settlements */
export type TradingUnsettledClientBalanceSummary = {
  __typename: 'TradingUnsettledClientBalanceSummary';
  /** Holds the executed and settling balance grouped by asset */
  unsettledClientBalanceByAsset: Array<UnsettledClientBalanceByAsset>;
  /** Holds the executed and settling balance grouped by organization and asset */
  unsettledClientBalanceByOrgAsset: Array<UnsettledClientBalanceByOrgAsset>;
};

/** TradingWhitelabelAccount represents a whitelabel account */
export type TradingWhitelabelAccount = {
  __typename: 'TradingWhitelabelAccount';
  /** Trading account of this whitelabel account */
  account: TradingAccount;
  /** Alias for this account on the Talos side */
  accountTalosAlias: Scalars['String']['output'];
  /** When was this account created */
  createdAt: Scalars['Timestamp']['output'];
  /** Who created this account */
  createdBy: Scalars['String']['output'];
  /** Identifier of the whitelabel account */
  id: Scalars['ID']['output'];
  /** Organization of this whitelabel account */
  organization: Organization;
  /** Talos customer name */
  talosCustomer: Scalars['String']['output'];
  /** Talos customer ID */
  talosCustomerID: Scalars['String']['output'];
  /** When was this account updated */
  updatedAt: Scalars['Timestamp']['output'];
  /** Who updated this account */
  updatedBy: Scalars['String']['output'];
};

/** TradingWhitelabelUser represents a whitelabel user */
export type TradingWhitelabelUser = {
  __typename: 'TradingWhitelabelUser';
  /** Auth0 identifier */
  auth0ID: Scalars['String']['output'];
  /** Client username */
  clientUserName: Scalars['String']['output'];
  /** Fake email saved in Talos to identify the user on our end */
  email: Scalars['String']['output'];
  /** Identifier of the whitelabel user */
  id: Scalars['ID']['output'];
  /** Organization of the user */
  organization: Maybe<Organization>;
  /** Talos alias for the organization of the user */
  organizationTalosAlias: Scalars['String']['output'];
  /** Internal user that matches this WL user */
  user: Maybe<User>;
};

/** Filters for  the transaction object */
export type TransactionFilters = {
  /** Filter transaction that happen before the given date */
  filterEndTime?: InputMaybe<Scalars['Timestamp']['input']>;
  /** Filters transactions by passed lending entity IDs */
  filterEntityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filters transactions by passed facility IDs */
  filterFacilityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filters transactions by passed loan entity symbol */
  filterLoanEntitySymbol?: InputMaybe<Scalars['String']['input']>;
  /** Filters transactions by passed loan term IDs */
  filterLoanTermIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filters transactions by passed operation IDs */
  filterOperationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Record IDs to filter by */
  filterRecordIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter transactions that happen after the given date */
  filterStartTime?: InputMaybe<Scalars['Timestamp']['input']>;
  /** Filter transactions with the given status */
  filterTransactionStatus?: InputMaybe<Array<LendingFacilityTransactionStatus>>;
  /** Filter transactions with the given type */
  filterTransactionType?: InputMaybe<Array<LendingFacilityTransactionType>>;
};

/** The type of transaction request. */
export enum TransactionRequestType {
  /** JSONRPC is a JSON-RPC transaction request. */
  JSONRPC = 'JSONRPC'
}

/** Collected risk analysis information about an address or transaction */
export type TransactionRiskAnalysis = {
  __typename: 'TransactionRiskAnalysis';
  /** blockchainAnalyses is an array with BlockchainAnalyses */
  blockchainAnalyses: Maybe<Array<BlockchainAnalysis>>;
  /** clusterCategory is populated if there is a known categorization to the cluster in which the transaction (e.g. exchange) */
  clusterCategory: Maybe<Scalars['String']['output']>;
  /** clusterName is populated if there is a known name to the cluster in which the transaction (e.g. Poloniex.com) */
  clusterName: Maybe<Scalars['String']['output']>;
  /** counterpartyAnalyses is an array with CounterpartyAnalyses */
  counterpartyAnalyses: Maybe<Array<CounterpartyAnalysis>>;
  /** hrjAnalyses is an array with HRJAnalyses */
  hrjAnalyses: Maybe<Array<HRJAnalysis>>;
  /** score represents the risk assessment of the address */
  score: Maybe<TransactionRiskScore>;
  /** searches contains the search results count for various addresses */
  searches: Array<SearchAnalysis>;
  /** url is an URL for an external chain analysis dashboard */
  url: Maybe<Scalars['String']['output']>;
};

/** Quick determination of risk. Unless "DANGER", usually transactions still warrant further investigation */
export enum TransactionRiskScore {
  /** Transaction corresponds to a known bad cluster */
  DANGER = 'DANGER',
  /** Manual review is required for this transaction as the system was not able to determine the risk */
  MANUAL_CHECK_REQUIRED = 'MANUAL_CHECK_REQUIRED',
  /** Transaction corresponds to a known safe address cluster */
  SAFE = 'SAFE',
  /** We didn't get actionable risk information about this transaction */
  UNKNOWN = 'UNKNOWN'
}

/** Represents the status of a Transaction */
export enum TransactionStatus {
  /** Represents a Cancelled Transaction (CANCELLED) */
  CANCELED = 'CANCELED',
  /** Represents an on-going Transaction (PENDING) */
  PENDING = 'PENDING',
  /** Represents a final Transaction (POSTED) */
  POSTED = 'POSTED'
}

/** Represents the different type of transactions within the ledger */
export enum TransactionType {
  /** Represents an Advisory Fee */
  ADVISORY_FEE = 'ADVISORY_FEE',
  /** Represents a Custody Fee */
  CUSTODY_FEE = 'CUSTODY_FEE',
  /** Represents a Deposit */
  DEPOSIT = 'DEPOSIT',
  /** Represents a Deposit Sweep */
  DEPOSIT_SWEEP = 'DEPOSIT_SWEEP',
  /** Represents a Fee settlement */
  FEE_SETTLEMENT = 'FEE_SETTLEMENT',
  /** Represents a Gas Fill */
  GAS_FILL = 'GAS_FILL',
  /** Represents a Managment Fee */
  MANAGEMENT_FEE = 'MANAGEMENT_FEE',
  /** Represents a Model Fee */
  MODEL_FEE = 'MODEL_FEE',
  /** Represents return of funds */
  RETURN = 'RETURN',
  /** Represents a Subaccount Transfer */
  SUBACCOUNT_TRANSFER = 'SUBACCOUNT_TRANSFER',
  /** Represents a trade */
  TRADE = 'TRADE',
  /** Represents a Trade Settlement */
  TRADE_SETTLEMENT = 'TRADE_SETTLEMENT',
  /** Represents transfer */
  TRANSFER = 'TRANSFER',
  /** Represents a Withdrawal */
  WITHDRAWAL = 'WITHDRAWAL'
}

/** The request for automatic transfers between Client Custody Vaults and Anchorage Vaults */
export type TransferAccessRequest = {
  __typename: 'TransferAccessRequest';
  /** The api key details */
  apiKey: Maybe<APIKey>;
  /** The api key operation created */
  apiKeyOperation: Maybe<Operation>;
  /** The domain */
  domain: TransferAccessRequestDomain;
  /** The org key id */
  organizationKeyID: Scalars['ID']['output'];
  /** The permission group details */
  permissionGroup: Maybe<Role>;
  /** The permission group operation created */
  permissionGroupOperation: Maybe<Operation>;
  /** The status */
  status: TransferAccessRequestStatus;
  /** The transfer access request id */
  transferAccessRequestID: Scalars['ID']['output'];
};

/** Transfer Access Request Domains */
export enum TransferAccessRequestDomain {
  /** Brokerage transfer access request domain */
  BROKERAGE = 'BROKERAGE',
  /** Financing transfer access request domain */
  FINANCING = 'FINANCING',
  /** Gas station transfer access request domain */
  GAS_STATION = 'GAS_STATION',
  /** Settlement network transfer access request domain */
  SETTLEMENT_NETWORK = 'SETTLEMENT_NETWORK'
}

/** Transfer Access Request statuses */
export enum TransferAccessRequestStatus {
  /** API key creation was canceled */
  API_KEY_CANCELED = 'API_KEY_CANCELED',
  /** API key was created */
  API_KEY_COMPLETE = 'API_KEY_COMPLETE',
  /** API key is being created */
  API_KEY_PENDING = 'API_KEY_PENDING',
  /** API key was revoked */
  API_KEY_REVOKED = 'API_KEY_REVOKED',
  /** The transfer access request request has not been initiated */
  NOT_REQUESTED = 'NOT_REQUESTED',
  /** Permission group is canceled */
  PERMISSION_GROUP_CANCELED = 'PERMISSION_GROUP_CANCELED',
  /** Permission group is created */
  PERMISSION_GROUP_COMPLETE = 'PERMISSION_GROUP_COMPLETE',
  /** Permission group is deleted */
  PERMISSION_GROUP_DELETED = 'PERMISSION_GROUP_DELETED',
  /** Permission group is being created */
  PERMISSION_GROUP_PENDING = 'PERMISSION_GROUP_PENDING'
}

/** TransferOperation represents a Transfer operation */
export type TransferOperation = Operation & {
  __typename: 'TransferOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** amount is the Amount that is being transferred */
  amount: Amount;
  /** same as amount but using historical pricing based on the end time of the operation */
  amountAtEndTime: Amount;
  /**
   * amountWithFinalFee is equal to amount + finalFee and is defined only for withdrawals
   * in the completed state where the amount and the fee share the same asset type.
   * Refer to the resolvers `feeSharesAssetTypeWithAmount` and `operationState`.
   */
  amountWithFinalFee: Maybe<Amount>;
  /** same as amountWithFinalFee but using historical pricing based on the end time of the operation */
  amountWithFinalFeeAtEndTime: Maybe<Amount>;
  /**
   * appStatus is the app-facing state of the operation, a simplified version of operationState
   * TODO: unused field
   */
  appStatus: AppStatus;
  /** The asset associated with this operation */
  asset: Asset;
  /** asset id from with the authorization was made */
  assetID: AssetID;
  /** Further information regarding this asset type associated with this operation */
  assetTypeInfo: AssetTypeInfo;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * TODO: unused field
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** canonicalOperationVersion is the version of the canonical authorization that must be used to sign this authorization */
  canonicalOperationVersion: Scalars['Int']['output'];
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** confirmations returns how many confirmations the transaction associated with this authorization has received */
  confirmations: Maybe<Scalars['Int']['output']>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /**
   * destinationAssetID is the asset id the operation is transferring to. It will be set only
   * if the querying user is allowed to read this asset ID. For example, app/clientdashboard
   * users will receive a NULL for the destinationAssetID of a cross-org transfer. In that
   * case, see destinationVaultUniqueID
   */
  destinationAssetID: Maybe<AssetID>;
  /** destinationVaultUniqueID is the unique ID of the vault this operation is transferring to. */
  destinationVaultUniqueID: Maybe<Scalars['String']['output']>;
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /**
   * endorsements is the list of endorsements for the operation
   * TODO: unused field
   */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /**
   * estimatedTotalAmount is equal to amount + maxFee and is defined only for
   * withdrawals where the amount and the fee share the same asset type.
   */
  estimatedTotalAmount: Maybe<Amount>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * extraParams are additional asset type specific parameters that were set for this transaction
   * When constructing the canonical operation for signing, the extra params go in the ExtraParams field
   * as a map from asset type id (usually, parent asset type id) to params object. The convention of extra
   * params is to omit all empty fields, and if no extra params are set for an asset type, to omit the asset
   * type from the map entirely. Note that for legacy reasons this convention
   * is the opposite of the normal CanonicalOperation convention, where no fields are ever omitted.
   */
  extraParams: Maybe<ExtraParams>;
  /**
   * feeSharesAssetTypeWithAmount is true if the fee and the amount withdrawn share
   * the same asset type. Returns false otherwise, or if the fee is not known.
   */
  feeSharesAssetTypeWithAmount: Scalars['Boolean']['output'];
  /** final transaction fee charged for the authorization, detected on the blockchain */
  finalFee: Maybe<Amount>;
  /** same as finalFee but using historical pricing based on the end time of the operation */
  finalFeeAtEndTime: Maybe<Amount>;
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** initiatorKey is the api key that created the operation */
  initiatorKey: APIKey;
  /** max transaction fee charged for the authorization */
  maxFee: Maybe<Amount>;
  /** same as maxFee but using historical pricing based on the end time of the operation */
  maxFeeAtEndTime: Maybe<Amount>;
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to, which is the source organization (not necessarily the org using the initiatorKey)
   * @deprecated use operationInfo
   */
  organization: Maybe<Organization>;
  /**
   * policy that applies to this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /** receivingBlockchainAddress is the address on which a transfer is to be received */
  receivingBlockchainAddress: BlockchainAddress;
  /** same as receivingBlockchainAddress but with additional info if using a settlement network participant */
  receivingBlockchainAddress2: BlockchainAddressWithParticipant;
  /** receivingTrustedDestination is the destination on which a transfer is to be received */
  receivingTrustedDestination: Maybe<TrustedDestination>;
  /** receivingWallet is the wallet that received the transfer */
  receivingWallet: Maybe<Wallet>;
  /**
   * replayProtectionParams are the parameters to be sent to the HSM for creating and signing
   * the transactions required to effect this ExecuteOperation. The user *is not* expected
   * to review the contents of this field. However it may contain information necessary to prevent
   * replays and must be included in the payload signed by the user. Format is a hex-encoded
   * byte array.
   */
  replayProtectionParams: Maybe<Scalars['String']['output']>;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /** riskAnalysis is the collected risk information for this transfer if we have any */
  riskAnalysis: Maybe<TransactionRiskAnalysis>;
  /** sendingBlockchainAddresses is a list of addresses from which a transfer is sent */
  sendingBlockchainAddresses: Array<BlockchainAddress>;
  /** sendingWallet is the wallet from which the tranfer was sent */
  sendingWallet: Wallet;
  /** signedTransaction is the blockchain transaction to be broadcast to the network */
  signedTransaction: Maybe<Scalars['String']['output']>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /**
   * sources are the originating addresses for the transaction
   * @deprecated use sendingBlockchainAddresses
   */
  sources: Array<Source>;
  /** transactionID is the blockchain transaction id once available */
  transactionID: Maybe<Scalars['String']['output']>;
  /** transactionSuccess indicates if the blockchain transaction succeeded */
  transactionSuccess: Maybe<Scalars['Boolean']['output']>;
  /**
   * usdValueFee is the USD value of the fee (finalFee if known, otherwise maxFee).
   * If both the finalFee and maxFee are not yet known, this field will be null
   */
  usdValueFee: Maybe<Scalars['String']['output']>;
  /**
   * usdValueWithFee is the sum in USD values of both the principal withdrawal amount and the
   * fee (finalFee if known, otherwise maxFee). If both the finalFee and maxFee are
   * not yet known, this field will be null
   */
  usdValueWithFee: Maybe<Scalars['String']['output']>;
  /** if true, the Anchorage gas station was used to pay transfer fees. */
  useGasStation: Scalars['Boolean']['output'];
  /** viewTransactionLink is the link to get more info about the transaction from a third party */
  viewTransactionLink: Maybe<Scalars['String']['output']>;
};


/** TransferOperation represents a Transfer operation */
export type TransferOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** TransferOperation represents a Transfer operation */
export type TransferOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/** Transfer Request */
export type TransferRequest = Node & {
  __typename: 'TransferRequest';
  /** ApprovedAt is when the transfer request was approved */
  approvedAt: Maybe<Scalars['Timestamp']['output']>;
  /** ApprovedBy is who approved the transfer request */
  approvedBy: Maybe<Scalars['String']['output']>;
  /** CreatedAt is when the transfer request was created */
  createdAt: Scalars['Timestamp']['output'];
  /** CreatedBy is who created the transfer request */
  createdBy: Scalars['String']['output'];
  /** FacilityTransaction represents the facility transaction of the transfer request */
  facilityTransaction: LendingFacilityTransaction;
  /** HoldID represents the hold id for the transfer request */
  holdID: Maybe<Scalars['ID']['output']>;
  /** ID is the ID for the transfer request */
  id: Scalars['ID']['output'];
  /** Operation ID is the current operation for this request */
  operationID: Maybe<Scalars['ID']['output']>;
  /** PoolPercentage is the pool percentage affected by this request */
  poolPercentage: Scalars['Percent']['output'];
  /** RequestSourceType is the source type of this request */
  requestSourceType: TransferRequestSourceType;
  /** RequestStatus is the status of this request */
  requestStatus: TransferRequestStatus;
  /** SlippagePercentage is the slippage percentage affected by this request */
  slippagePercentage: Scalars['Percent']['output'];
};

/** Lending transfer request connection for pagination */
export type TransferRequestConnection = {
  __typename: 'TransferRequestConnection';
  /** List of edges containing transfer request and cursor information */
  edges: Array<TransferRequestEdge>;
  /** Page details for the associated list of transfer request edges */
  pageInfo: PageInfo;
};

/** Lending Transfer Request edge for pagination */
export type TransferRequestEdge = {
  __typename: 'TransferRequestEdge';
  /** Cursor for the associated transfer request */
  cursor: Scalars['ID']['output'];
  /** Transfer Request */
  node: TransferRequest;
};

/** Filters for the transfer request object */
export type TransferRequestFilters = {
  /** Filter for for all transfer requests with approved at before the filter input date */
  filterAfterApprovedAt?: InputMaybe<Scalars['Date']['input']>;
  /** Filter for all transfer requests with created at after the filter input date */
  filterAfterCreatedAt?: InputMaybe<Scalars['Date']['input']>;
  /** Filter for all transfer requests with approved at before the filter input date */
  filterBeforeApprovedAt?: InputMaybe<Scalars['Date']['input']>;
  /** Filter for all transfer requests with created at before the filter input date */
  filterBeforeCreatedAt?: InputMaybe<Scalars['Date']['input']>;
  /** Filters transfer requests by created by */
  filterCreatedBy?: InputMaybe<Scalars['String']['input']>;
  /** Filters transfer requests by facility ids */
  filterFacilityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filters transfer requests by lending entity ids */
  filterLendingEntityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filters transfer requests by source types - Client vs Internal */
  filterRequestSourceType?: InputMaybe<Array<TransferRequestSourceType>>;
  /** Filters transfer requests by statuses */
  filterRequestStatus?: InputMaybe<Array<TransferRequestStatus>>;
  /** Filters transfer requests by types */
  filterRequestType?: InputMaybe<Array<TransferRequestType>>;
  /** Filters transfer requests by transaction id */
  filterTransactionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filters transfer requests by transfer request id */
  filterTransferRequestIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** TransferRequestSourceType is the source type of a transfer request */
export enum TransferRequestSourceType {
  /** Client is a transfer request submitted by a Client */
  CLIENT = 'CLIENT',
  /** Internal is a transfer request submitted by Lending Ops in Financing Dashboard */
  INTERNAL = 'INTERNAL'
}

/** TransferRequestStatus is the status of a transfer request */
export enum TransferRequestStatus {
  /** Approved is a approved transfer request */
  APPROVED = 'APPROVED',
  /** Canceled is a canceled transfer request */
  CANCELED = 'CANCELED',
  /** Completed is a completed transfer request */
  COMPLETED = 'COMPLETED',
  /** Failed is a failed transfer request */
  FAILED = 'FAILED',
  /** Pending is a pending transfer request */
  PENDING = 'PENDING',
  /** Rejected is a rejected transfer request */
  REJECTED = 'REJECTED'
}

/** TransferRequestType is the type of a transfer request */
export enum TransferRequestType {
  /** Deposit is a deposit transfer request */
  DEPOSIT = 'DEPOSIT',
  /** Withdrawal is a withdrawal transfer request */
  WITHDRAWAL = 'WITHDRAWAL'
}

/** Represents Travel Rule Information */
export type TravelRuleInformation = {
  __typename: 'TravelRuleInformation';
  /** Indicates if travel rule information can and or will be sent automatically */
  automatic: Maybe<Scalars['Boolean']['output']>;
  /** Contains the date of incorporation being sent to Ciphertrace */
  dateIncorporation: Maybe<Scalars['String']['output']>;
  /**
   * Identity Info as a JSON String
   * Specification for Identity format: http://34.64.107.172/~vasp/wp-content/uploads/2020/05/IVMS101-interVASP-data-model-standard-issue-1-FINAL.pdf
   * PDF gathered from the working group website: https://intervasp.org/
   */
  identityPayload: Maybe<Scalars['String']['output']>;
  /** Indicates if this operation is applicable for travel rule */
  isEligible: Scalars['Boolean']['output'];
  /** Operation ID the travel rule info belongs to */
  operationID: Scalars['String']['output'];
  /** Contains the place of incorporation being sent to Ciphertrace */
  placeIncorporation: Maybe<Scalars['String']['output']>;
  /** Status of TravelRule transmission */
  status: Maybe<TravelRuleStatus>;
  /** Contains Vasp Info */
  travelRuleVaspInfo: Maybe<Array<TravelRuleVaspInfo>>;
};

/** Represents the status of the Travel Rule request */
export enum TravelRuleStatus {
  /** Travel Rule transmission failed */
  API_FAILURE = 'API_FAILURE',
  /** Travel Rule transmission is pending */
  PENDING = 'PENDING',
  /** Travel Rule info was transmitted manually */
  SENT_MANUALLY = 'SENT_MANUALLY',
  /** Travel Rule info was transmitted automatically */
  SENT_VIA_API = 'SENT_VIA_API'
}

/** Contains Vasp information */
export type TravelRuleVaspInfo = {
  __typename: 'TravelRuleVaspInfo';
  /** Indicates that Travel Rule information can be sent via API to this VASP */
  autoSendPossible: Maybe<Scalars['Boolean']['output']>;
  /** Source of the Vasp Information */
  network: Scalars['String']['output'];
  /** Link to the networks portal site */
  portalLink: Maybe<Scalars['String']['output']>;
  /** Name of the Vasp */
  vaspName: Maybe<Scalars['String']['output']>;
};

export type TriggerWebhookInput = {
  payload: Scalars['String']['input'];
  topic: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

/** Represents a trusted destination for assets */
export type TrustedDestination = {
  __typename: 'TrustedDestination';
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /**
   * assetTypeID is the type of asset that this destination points to
   * @deprecated Use assetTypeInfo
   */
  assetTypeID: Scalars['AssetTypeID']['output'];
  /** Further information regarding this asset type */
  assetTypeInfo: AssetTypeInfo;
  /** Holds true if the destination is being deleted */
  deletionPending: Scalars['Boolean']['output'];
  /** Description of the trusted address */
  description: Scalars['String']['output'];
  /** The address */
  destination: Scalars['String']['output'];
  /** ID of the trusted destination */
  destinationID: DestinationID;
  /**
   * isAtNetworkLevel denotes whether the trusted destination is used as network level or asset level
   * true: network level, false: asset level
   */
  isAtNetworkLevel: Scalars['Boolean']['output'];
  /** Name of the trusted address */
  name: Scalars['String']['output'];
  /** ExtraParams for for trusted destinations, depending on the asset being used */
  trustedDestinationExtraParams: Maybe<TrustedDestinationExtraParams>;
};

/** A connection to a list of trusted destinations */
export type TrustedDestinationConnection = {
  __typename: 'TrustedDestinationConnection';
  /** edges is a list of TrustedDestinationEdge objects representing a page of trustedDestinations data. Implements the connections spec for pagination */
  edges: Array<TrustedDestinationEdge>;
  /** pageInfo returns information about the page returned above. Implements the connections spec for pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection, representing a TrustedDestination instance */
export type TrustedDestinationEdge = {
  __typename: 'TrustedDestinationEdge';
  /** cursor implements the connections spec for pagination */
  cursor: Scalars['ID']['output'];
  /** node implements the connections spec for pagination */
  node: TrustedDestination;
};

/** TrustedDestinationExtraParams is the type that represents a trusted destination extra parameters */
export type TrustedDestinationExtraParams = {
  __typename: 'TrustedDestinationExtraParams';
  /** memo is the destination tag/memo required by the asset for transactions */
  memo: Maybe<Scalars['String']['output']>;
};

/** Represents a trusted smart contract for assets. */
export type TrustedSmartContract = {
  __typename: 'TrustedSmartContract';
  /** Address string of the trusted smart contract */
  address: Scalars['String']['output'];
  /**
   * assetTypeInfo is the asset type this smart contract is for.
   * @deprecated Use networkInfo
   */
  assetTypeInfo: AssetTypeInfo;
  /** UnixTime when the Trusted Smart Contract was created, in nanosecond. */
  createdAt: Scalars['String']['output'];
  /** Description of the trusted smart contract */
  description: Scalars['String']['output'];
  /** Name of the trusted smart contract */
  name: Scalars['String']['output'];
  /** networkInfo is the network this smart contract is for. */
  networkInfo: NetworkInfo;
  /** ID of the trusted smart contract */
  trustedSmartContractID: TrustedSmartContractID;
  /**
   * SubIDs of the vaults in which the smart contract is considered "trusted".
   * @deprecated Use vaults
   */
  vaultSubIDs: Array<Scalars['String']['output']>;
  /** the vaults in which the smart contract is considered "trusted". */
  vaults: Array<Vault>;
};

/** A connection to a list of items. */
export type TrustedSmartContractConnection = {
  __typename: 'TrustedSmartContractConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<TrustedSmartContractEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TrustedSmartContractEdge = {
  __typename: 'TrustedSmartContractEdge';
  /** A CursorType for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<TrustedSmartContract>;
};

/** TrustedSmartContractID is the ID that uniquely identifies a trusted smart contract (across orgs). */
export type TrustedSmartContractID = {
  __typename: 'TrustedSmartContractID';
  /** organizationKeyID is the ID of the organization this vault is in */
  organizationKeyID: Scalars['String']['output'];
  /** trustedSmartContractSubID is the ID that uniquely identifies a trusted smart contract within a given organization. */
  trustedSmartContractSubID: Scalars['String']['output'];
};

/** By which field to sort TrustedSmartContract's. */
export enum TrustedSmartContractSortingField {
  /** Sort by the name of trusted smart contracts. */
  NAME = 'NAME'
}

/** Trusted Sources represents a trusted source that can me used to make a deposit attribution */
export type TrustedSource = {
  __typename: 'TrustedSource';
  /** createdAt is the timestamp of creation of this record */
  createdAt: Scalars['Timestamp']['output'];
  /** createdBy is the email of the user who created this record */
  createdBy: Scalars['String']['output'];
  /** Reason why the trusted source was disabled */
  disabledReason: Maybe<TrustedSourceDisableReason>;
  /** Trusted source identifier */
  id: Scalars['String']['output'];
  /** Network from the respective source address */
  network: Scalars['String']['output'];
  /** Organization identifier */
  organizationID: Scalars['String']['output'];
  /** Country of the trusted source originator */
  originatorCountry: Scalars['String']['output'];
  /** Name of the trusted source originator */
  originatorName: Scalars['String']['output'];
  /** Source address to consider as trusted */
  sourceAddress: Scalars['String']['output'];
  /** Status associated with the trusted source */
  status: TrustedSourceStatus;
  /** updatedAt is the timestamp of the latest update of this record */
  updatedAt: Scalars['Timestamp']['output'];
  /** updatedBy is the email of the user who last updated this record */
  updatedBy: Scalars['String']['output'];
  /** Country of the trusted source vasp in case the originator does not control the wallet. */
  vaspCountry: Maybe<Scalars['String']['output']>;
  /** Name of the trusted source vasp in case the originator does not control the wallet */
  vaspName: Maybe<Scalars['String']['output']>;
  /** Type of wallet associated with the trusted source */
  walletType: TrustedSourceWalletType;
};

/** Trusted Sources pagination connection. */
export type TrustedSourceConnection = {
  __typename: 'TrustedSourceConnection';
  /** List of edges containing trusted sources and cursor information */
  edges: Array<TrustedSourceEdge>;
  /** Page details for the trusted sources. */
  pageInfo: PageInfo;
};

/** Trusted Sources Freeze Reason */
export enum TrustedSourceDisableReason {
  /** Frozen due to being in compliance review */
  COMPLIANCE_REVIEW = 'COMPLIANCE_REVIEW',
  /** Frozen due to detecting a confliting attribution */
  CONFLICTING_ATTRIBUTION = 'CONFLICTING_ATTRIBUTION',
  /** Frozen due to detecting a incoherent attribution */
  INCOHERENT_ATTRIBUTION = 'INCOHERENT_ATTRIBUTION',
  /** Frozen due to being an omnibus account */
  OMNIBUS_ACCOUNT = 'OMNIBUS_ACCOUNT'
}

/** Trusted Sources edge connection. */
export type TrustedSourceEdge = {
  __typename: 'TrustedSourceEdge';
  /** Cursor for the trusted sources. */
  cursor: Scalars['String']['output'];
  /** Latest trusted sources. */
  node: TrustedSource;
};

/** Trusted Sources Status */
export enum TrustedSourceStatus {
  /** Disabled */
  DISABLED = 'DISABLED',
  /** Enable */
  ENABLED = 'ENABLED'
}

/** TrustedSourceWalletType are the options for wallet types on trusted sources */
export enum TrustedSourceWalletType {
  /** CUSTODIAL represents a wallet where the owner does not have complete control over the wallet as they do not have the private key, a third party does. */
  CUSTODIAL = 'CUSTODIAL',
  /** SELF_HOSTED represents a wallet where the owner has complete control over the wallet as they have the private key. */
  SELF_HOSTED = 'SELF_HOSTED'
}

/** A unique device is a device that has been used to login to the system. */
export type UniqueDevice = Node & {
  __typename: 'UniqueDevice';
  /** Returns user enrollments. */
  enrollments: UserConnection;
  /**
   * True if the device has a device key and may be consider as unique as we
   * can guarantee.
   */
  hasDeviceKey: Scalars['Boolean']['output'];
  /**
   * Device key ID of the unique device. In cases where the device does not have
   * a proper device key ID, this ID will be the same as the userKeyID.
   */
  id: Scalars['ID']['output'];
  /** Login metadata */
  loginMetadata: LoginMetadata;
  /** Device status */
  status: DeviceStatus;
  /** User key ID of the user that last used this device */
  userKeyID: Scalars['ID']['output'];
};


/** A unique device is a device that has been used to login to the system. */
export type UniqueDeviceenrollmentsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Unique device connection used to paginate through the unique devices */
export type UniqueDeviceConnection = {
  __typename: 'UniqueDeviceConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UniqueDeviceEdge>>>;
  /** Information to aid in pagination */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection */
  totalCount: Scalars['Int']['output'];
};

/** Unique device edge used to paginate through the unique devices */
export type UniqueDeviceEdge = {
  __typename: 'UniqueDeviceEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge */
  node: Maybe<UniqueDevice>;
};

/** A filter to be used against UniqueDevice fields. */
export type UniqueDeviceWhereInput = {
  /** ids field predicates */
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** UnlockOperation represents an Unlock operation */
export type UnlockOperation = Operation & {
  __typename: 'UnlockOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** amount is the Amount that is being locked up */
  amount: Amount;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /** The asset associated with this operation */
  asset: Asset;
  /** asset id from with the authorization was made */
  assetID: AssetID;
  /** Further information regarding this asset type associated with this operation */
  assetTypeInfo: AssetTypeInfo;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** canonicalOperationVersion is the version of the canonical authorization that must be used to sign this authorization */
  canonicalOperationVersion: Scalars['Int']['output'];
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** confirmations returns how many confirmations the transaction associated with this authorization has received */
  confirmations: Maybe<Scalars['Int']['output']>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * extraParams are additional asset type specific parameters that were set for this transaction
   * When constructing the canonical operation for signing, the extra params go in the ExtraParams field
   * as a map from asset type id (usually, parent asset type id) to params object. The convention of extra
   * params is to omit all empty fields, and if no extra params are set for an asset type, to omit the asset
   * type from the map entirely. Note that for legacy reasons this convention
   * is the opposite of the normal CanonicalOperation convention, where no fields are ever omitted.
   */
  extraParams: Maybe<ExtraParams>;
  /** final transaction fee charged for the authorization, detected on the blockchain */
  finalFee: Maybe<Amount>;
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** max transaction fee charged for the authorization */
  maxFee: Maybe<Amount>;
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * policy that applies to this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /**
   * replayProtectionParams are the parameters to be sent to the HSM for creating and signing
   * the transactions required to effect this ExecuteOperation. The user *is not* expected
   * to review the contents of this field. However it may contain information necessary to prevent
   * replays and must be included in the payload signed by the user. Format is a hex-encoded
   * byte array.
   */
  replayProtectionParams: Maybe<Scalars['String']['output']>;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /** sendingBlockchainAddresses is a list of addresses from which an unlock operation is sent */
  sendingBlockchainAddresses: Array<BlockchainAddress>;
  /** signedTransaction is the blockchain transaction to be broadcast to the network */
  signedTransaction: Maybe<Scalars['String']['output']>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /**
   * sources are the originating addresses for the authorization
   * @deprecated use sendingBlockchainAddresses
   */
  sources: Array<Source>;
  /** transactionID is the blockchain transaction id once available */
  transactionID: Maybe<Scalars['String']['output']>;
  /** transactionSuccess indicates if the blockchain transaction succeeded */
  transactionSuccess: Maybe<Scalars['Boolean']['output']>;
  /**
   * usdValueFee is the USD value of the fee (finalFee if known, otherwise maxFee).
   * If both the finalFee and maxFee are not yet known, this field will be null
   */
  usdValueFee: Maybe<Scalars['String']['output']>;
  /** viewTransactionLink is the link to get more info about the transaction from a third party */
  viewTransactionLink: Maybe<Scalars['String']['output']>;
};


/** UnlockOperation represents an Unlock operation */
export type UnlockOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** UnlockOperation represents an Unlock operation */
export type UnlockOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/** UnsettledClientBalance is a current dealer/asset balance */
export type UnsettledClientBalance = {
  __typename: 'UnsettledClientBalance';
  /** Asset of the  balance */
  assetInfo: AssetTypeInfo;
  /** Organization that holds the balance */
  organization: Maybe<Organization>;
  /** Pending amount with asset and side */
  pending: PaymentAmount;
  /** Total amount with asset and side */
  total: PaymentAmount;
  /** Type of the balance */
  type: ClientSettlementType;
  /** Uninitiated amount with asset and side */
  uninitiated: PaymentAmount;
};

/** Holds the balance for each unsettled asset */
export type UnsettledClientBalanceByAsset = {
  __typename: 'UnsettledClientBalanceByAsset';
  /** Executed unsettled balance */
  executedBalance: Amount;
  /** Settling unsettled balance */
  settlingBalance: Amount;
  /** Total unsettled balance */
  totalBalance: Amount;
};

/** Holds the balance for one entry/combination of organization and asset */
export type UnsettledClientBalanceByOrgAsset = {
  __typename: 'UnsettledClientBalanceByOrgAsset';
  /** Account */
  account: Maybe<TradingAccount>;
  /** Asset of the balance */
  assetTypeInfo: AssetTypeInfo;
  /** Executed unsettled balance */
  executedBalance: Amount;
  /** Oldest balance timestamp */
  oldestBalance: Scalars['Timestamp']['output'];
  /** Organization of the balance */
  organization: Maybe<Organization>;
  /** Settling unsettled balance */
  settlingBalance: Amount;
  /** Total unsettled balance */
  totalBalance: Amount;
};

/** Unsettled client balance edge for pagination */
export type UnsettledClientBalanceEdge = {
  __typename: 'UnsettledClientBalanceEdge';
  /** Cursor for the associated unsettled client balance */
  cursor: Scalars['String']['output'];
  /** Settlements */
  node: UnsettledClientBalance;
};

/** Unsettled client balances connection for pagination */
export type UnsettledClientBalancesConnection = {
  __typename: 'UnsettledClientBalancesConnection';
  /** List of edges containing unsettled client balances and cursor information */
  edges: Array<Maybe<UnsettledClientBalanceEdge>>;
  /** Fee balances */
  feeBalances: Maybe<Array<UnsettledClientFeeBalance>>;
  /** Page details for the associated list of payment flow edges */
  pageInfo: PageInfo;
};

/**
 * Wrapper around UnsettledClientBalance that includes information
 * specific to fees, like its unique identifier
 */
export type UnsettledClientFeeBalance = {
  __typename: 'UnsettledClientFeeBalance';
  /** Information about the balance and its state */
  balance: UnsettledClientBalance;
  /** Unique identifier for this fee balance */
  feeTransactionID: Scalars['String']['output'];
};

/** UnsettledDealerBalance is a current dealer/asset balance */
export type UnsettledDealerBalance = {
  __typename: 'UnsettledDealerBalance';
  /**
   * Amount contains the volume and asset this balance refers to
   * @deprecated Now is pending settling and total
   */
  amount: Amount;
  /**
   * Counterparty (i.e. dealer)
   * @deprecated Use tradingCounterparty instead
   */
  counterparty: Scalars['String']['output'];
  /** Entity of the payment flow ('HOLD', 'SINGAPORE', 'A1', etc.) */
  entity: Scalars['String']['output'];
  /** Pending amount with asset and side */
  pending: UnsettledDealerBalanceAmount;
  /** Settling amount with asset and side */
  settling: UnsettledDealerBalanceAmount;
  /** Side refers to whether it's a receivable (BUY) or a payable (SELL) */
  side: TradeSide;
  /** Total amount with asset and side */
  total: UnsettledDealerBalanceAmount;
  /** TotalPayments that make up the current balance */
  totalPayments: Scalars['Int']['output'];
  /**
   * CounterpartyWithErr object related to this unsettled balance.
   * This field is automatically resolved based on the counterparty
   */
  tradingCounterparty: Maybe<CounterpartyWithErr>;
};

/** UnsettledDealerBalanceAmount is a balance amount */
export type UnsettledDealerBalanceAmount = {
  __typename: 'UnsettledDealerBalanceAmount';
  /**
   * Amount contains the volume and asset this balance refers to
   * @deprecated Use tradingAmount instead
   */
  amount: Amount;
  /** Side refers to whether it's a receivable (BUY) or a payable (SELL) */
  side: TradeSide;
  /** TradingAmount contains the quantity and asset this balance refers to */
  tradingAmount: TradingAmount;
};

/** Unsettled dealer balance edge for pagination */
export type UnsettledDealerBalanceEdge = {
  __typename: 'UnsettledDealerBalanceEdge';
  /** Cursor for the associated unsettled dealer balance */
  cursor: Scalars['String']['output'];
  /** Settlements */
  node: UnsettledDealerBalance;
};

/** Unsettled dealer balances connection for pagination */
export type UnsettledDealerBalancesConnection = {
  __typename: 'UnsettledDealerBalancesConnection';
  /** List of edges containing unsettled dealer balances and cursor information */
  edges: Array<Maybe<UnsettledDealerBalanceEdge>>;
  /** Page details for the associated list of payment flow edges */
  pageInfo: PageInfo;
};

/** UnsignedOperationDetails is a union type of all possible forms of extra information for unsigned fields */
export type UnsignedOperationDetails = UnsignedOperationDetailsAddress | UnsignedOperationDetailsAmount | UnsignedOperationDetailsDivider | UnsignedOperationDetailsText | UnsignedOperationDetailsTransaction;

/**
 * UnsignedOperationDetailsAddress is the details for an address field that's not signed
 * this could be an address that's contextual like a voting address or intermediate address
 */
export type UnsignedOperationDetailsAddress = {
  __typename: 'UnsignedOperationDetailsAddress';
  /** address to display */
  address: Scalars['String']['output'];
  /** URL to the explorer for the address */
  explorerURL: Scalars['String']['output'];
};

/**
 * UnsignedOperationDetailsAmount is the details for an amount field
 * Note that this is a far simpler amount type than our existing graphql amount
 * we determined the details weren't pertinent to this screen and we can make it far simpler
 */
export type UnsignedOperationDetailsAmount = {
  __typename: 'UnsignedOperationDetailsAmount';
  /** abbreviation is the abbreviation for the currency */
  abbreviation: Scalars['String']['output'];
  /** amount is the amount to display */
  amount: Scalars['String']['output'];
  /** FallbackPriceText is a human readable field for laying out the price if PriceQuote isn't queried */
  fallbackPriceText: Scalars['String']['output'];
};

/** UnsignedOperationDetailsDivider is a divider between fields. */
export type UnsignedOperationDetailsDivider = {
  __typename: 'UnsignedOperationDetailsDivider';
  /** style is the style of the divider. */
  style: UnsignedOperationDetailsDividerStyle;
};

/** UnsignedOperationDetailsDividerStyle indicates the style of the divider. */
export enum UnsignedOperationDetailsDividerStyle {
  /** A thin divider. */
  THIN = 'THIN'
}

/** UnsignedOperationDetailsText is the details for a text field */
export type UnsignedOperationDetailsText = {
  __typename: 'UnsignedOperationDetailsText';
  /** detail is the text to display in addition or instead of the fallbacktext */
  detail: Scalars['String']['output'];
};

/** UnsignedOperationDetailsTransaction expresses the details of a transaction like the id and explorer url */
export type UnsignedOperationDetailsTransaction = {
  __typename: 'UnsignedOperationDetailsTransaction';
  /** explorerURL is the url to the explorer for the transaction */
  explorerURL: Scalars['String']['output'];
  /** id is the transaction id */
  id: Scalars['String']['output'];
};

/**
 * UnsignedOperationField is filled in by the backend and is not signed by the user
 * The app is always expected to call label and fallbackText but details may be null
 */
export type UnsignedOperationField = {
  __typename: 'UnsignedOperationField';
  /** details provides additional information about the field */
  details: Maybe<UnsignedOperationDetails>;
  /** For use when the app doesn't support the specific type yet. */
  fallbackText: Scalars['String']['output'];
  /** label is string to describe the field */
  label: Scalars['String']['output'];
};

/** Update a brokerage account */
export type UpdateAccountInput = {
  /** Enable or disable trading for the given ID */
  enable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Brokerage account ID */
  id: Scalars['ID']['input'];
  /** Trading account credit limits */
  limits?: InputMaybe<Array<AmountInput>>;
  /** Set the brokerage account name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Update account result */
export type UpdateAccountResult = {
  __typename: 'UpdateAccountResult';
  /** updated identifier */
  id: Scalars['String']['output'];
  /** error message */
  message: Maybe<Scalars['String']['output']>;
};

/**
 * UpdateAssetSpecInput is used for update AssetSpec object.
 * Input was generated by ent.
 */
export type UpdateAssetSpecInput = {
  assetPrecision?: InputMaybe<Scalars['Int']['input']>;
  assetSpecID: Scalars['ID']['input'];
  assetSpecMetadata?: InputMaybe<AssetSpecMetadataInput>;
  baseSymbol?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AssetSpecStatus>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  typeID?: InputMaybe<AssetSpecTypeID>;
};

/**
 * UpdateCollateralAgreementInput is used for update CollateralAgreement object.
 * Input was generated by ent.
 */
export type UpdateCollateralAgreementInput = {
  agreementID: Scalars['ID']['input'];
  agreementType?: InputMaybe<CollateralAgreementAgreementType>;
  assetLentID?: InputMaybe<Scalars['ID']['input']>;
  assetRefDataIssuerIDFkID?: InputMaybe<Scalars['ID']['input']>;
  liquidationInstructions?: InputMaybe<CollateralAgreementLiquidationInstructionsInput>;
  liquidationLevel?: InputMaybe<Scalars['Decimal']['input']>;
  loanAmountAtOrigination?: InputMaybe<Scalars['Decimal']['input']>;
  marginCallCurePeriod?: InputMaybe<Scalars['Int']['input']>;
  marginCallLevel?: InputMaybe<Scalars['Decimal']['input']>;
  marginLevel?: InputMaybe<CollateralAgreementMarginLevel>;
  minimalThresholdAmount?: InputMaybe<Scalars['Decimal']['input']>;
  monitored?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  originationDate?: InputMaybe<Scalars['Timestamp']['input']>;
  preMarginCallLevel?: InputMaybe<Scalars['Decimal']['input']>;
  priceRefDataIssuerIDFkID?: InputMaybe<Scalars['ID']['input']>;
  recoveryLevel?: InputMaybe<Scalars['Decimal']['input']>;
  referencePriceAlgo?: InputMaybe<CollateralAgreementReferencePriceAlgo>;
  rehypothecation?: InputMaybe<Scalars['Boolean']['input']>;
  requiredCoverageLevel?: InputMaybe<Scalars['Decimal']['input']>;
  reverseCallLevel?: InputMaybe<Scalars['Decimal']['input']>;
  secured?: InputMaybe<Scalars['Boolean']['input']>;
  spotPriceAtOrigination?: InputMaybe<Scalars['Decimal']['input']>;
  status?: InputMaybe<CollateralAgreementStatus>;
};

/**
 * UpdateCollateralCallInput is used for update CollateralCall object.
 * Input was generated by ent.
 */
export type UpdateCollateralCallInput = {
  callID: Scalars['ID']['input'];
  /**
   * clientAckTime would only be updated and is mandatory in
   * the case when status is either SETTLING or DISPUTED
   * in other cases, the ack time will be ignored
   */
  clientAckTime?: InputMaybe<Scalars['Timestamp']['input']>;
  /**
   * clientNotificationTime is only relevant when
   * status is OUTSTANDING. It will be ignored otherwise
   */
  clientNotificationTime?: InputMaybe<Scalars['Timestamp']['input']>;
  /** settlementReason the reason to settle the call */
  settlementReason?: InputMaybe<CollateralCallSettlementReason>;
  /** settlementTime the timestamp when the call was settled */
  settlementTime?: InputMaybe<Scalars['Timestamp']['input']>;
  /** skipCcrChecks is a flag to skip ccr validations when updating */
  skipCcrChecks?: InputMaybe<Scalars['Boolean']['input']>;
  status: CollateralCallStatus;
};

/**
 * UpdateCollateralFeeInput is used for update CollateralFee object.
 * Input was generated by ent.
 */
export type UpdateCollateralFeeInput = {
  assetSpecID?: InputMaybe<Scalars['ID']['input']>;
  collateralFeeID: Scalars['ID']['input'];
  metadata?: InputMaybe<CollateralFeeMetadataInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  operationID?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  status?: InputMaybe<CollateralFeeStatus>;
  transactionTime?: InputMaybe<Scalars['Timestamp']['input']>;
};

/**
 * UpdateCollateralInventoryInput is used for update CollateralInventory object.
 * Input was generated by ent.
 */
export type UpdateCollateralInventoryInput = {
  accountID?: InputMaybe<Scalars['ID']['input']>;
  assetSpecID?: InputMaybe<Scalars['ID']['input']>;
  caID?: InputMaybe<Scalars['ID']['input']>;
  collateralCallID?: InputMaybe<Scalars['ID']['input']>;
  collateralInventoryID: Scalars['ID']['input'];
  metadata?: InputMaybe<InventoryMetadataInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  ownerID?: InputMaybe<Scalars['ID']['input']>;
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  status?: InputMaybe<CollateralInventoryStatus>;
  transactionTime?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionType?: InputMaybe<CollateralInventoryTransactionType>;
};

/**
 * UpdateCollateralRehypoLedgerInput is used for update CollateralRehypoLedger object.
 * Input was generated by ent.
 */
export type UpdateCollateralRehypoLedgerInput = {
  accountID?: InputMaybe<Scalars['ID']['input']>;
  assetSpecID?: InputMaybe<Scalars['ID']['input']>;
  metadata?: InputMaybe<RehypoLedgerMetadataInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  operationID?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  rehypoLedgerID: Scalars['ID']['input'];
  status?: InputMaybe<CollateralRehypoLedgerStatus>;
  transactionPurpose?: InputMaybe<CollateralRehypoLedgerTransactionPurpose>;
  transactionTime?: InputMaybe<Scalars['Timestamp']['input']>;
  transactionType?: InputMaybe<CollateralRehypoLedgerTransactionType>;
};

/**
 * UpdateDepositAttributionSpamThresholdInput is used for update DepositAttributionSpamThreshold object.
 * Input was generated by ent.
 */
export type UpdateDepositAttributionSpamThresholdInput = {
  isEnable?: InputMaybe<Scalars['Boolean']['input']>;
  threshold?: InputMaybe<Scalars['Decimal']['input']>;
};

/**
 * UpdateIAMRoleInput is used for update Role object.
 * Input was generated by ent.
 */
export type UpdateIAMRoleInput = {
  addHasRoleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addIsInRoleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addPermissionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  availability?: InputMaybe<IAMRoleAvailability>;
  clearOrganizationKeyID?: InputMaybe<Scalars['Boolean']['input']>;
  clearSubtitle?: InputMaybe<Scalars['Boolean']['input']>;
  clearUpdatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationKeyID?: InputMaybe<Scalars['String']['input']>;
  removeHasRoleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeIsInRoleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removePermissionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** UpdateIAMRoleUsersInput is used for batch updating users roles. */
export type UpdateIAMRoleUsersInput = {
  addUserIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  removeUserIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  resourceIDs: Array<Scalars['String']['input']>;
  roleID: Scalars['String']['input'];
};

/**
 * UpdateKYCProcessTriggerInput is used for update KYCProcessTrigger object.
 * Input was generated by ent.
 */
export type UpdateKYCProcessTriggerInput = {
  /** The actions to be executed when the trigger is fired. */
  actions?: InputMaybe<Array<KYCProcessTriggerActionInput>>;
  /** Type of the affiliate */
  affiliateType?: InputMaybe<Scalars['String']['input']>;
  /** Type of anchorage entity */
  anchorageEntityType?: InputMaybe<Scalars['String']['input']>;
  /** The conditions to be validated when the trigger is fired. */
  conditions?: InputMaybe<Array<KYCProcessTriggerConditionInput>>;
  /** The current state of the Jira case. */
  currentJiraState?: InputMaybe<Scalars['String']['input']>;
  /** The type of the Jira case. */
  jiraCaseType?: InputMaybe<Scalars['String']['input']>;
  /** The previous state of the Jira case. */
  previousJiraState?: InputMaybe<Scalars['String']['input']>;
  /** Priority of the process trigger */
  priority?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * UpdateKYCQuestionInput is used for update KYCQuestion object.
 * Input was generated by ent.
 */
export type UpdateKYCQuestionInput = {
  /** The categories of the questionnaire */
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The information type of the questionnaire */
  informationType?: InputMaybe<Scalars['String']['input']>;
  /** Name of the questionnaire */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The question definition of the questionnaire */
  questionDefinition?: InputMaybe<Scalars['String']['input']>;
};

/**
 * UpdateKYCQuestionnaireInput is used for update KYCQuestionnaire object.
 * Input was generated by ent.
 */
export type UpdateKYCQuestionnaireInput = {
  /** The questionnaire element body, it's a JSON string of KYCQuestionnaireElement type */
  body?: InputMaybe<Scalars['String']['input']>;
  /** The categories of the questionnaire */
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Number to days after which the questionnaire expires */
  expirationDays?: InputMaybe<Scalars['Int']['input']>;
  /** The name of the questionnaire */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The type of the questionnaire */
  questionnaireType?: InputMaybe<KYCQuestionnaireType>;
};

/** UpdateOrgPoliciesOperation represents an operation where multiple organization policies are updated */
export type UpdateOrgPoliciesOperation = Operation & {
  __typename: 'UpdateOrgPoliciesOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** the expected digest of the new organization after applying this change */
  newOrganizationDigest: Scalars['String']['output'];
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * policy required in order to satisfy this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /** Policy Container updates being applied by this operation */
  policyContainerUpdate: Maybe<PolicyContainerUpdate>;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /** roleChanges represents the IAM role changes that will be applied with this operation. */
  roleChanges: Maybe<IAMRoleChanges>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /** Policies being updated by this operation */
  updatedPolicies: Array<PolicyUpdate>;
};


/** UpdateOrgPoliciesOperation represents an operation where multiple organization policies are updated */
export type UpdateOrgPoliciesOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** UpdateOrgPoliciesOperation represents an operation where multiple organization policies are updated */
export type UpdateOrgPoliciesOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/**
 * UpdateReportScheduleInput is used for update ReportSchedule object.
 * Input was generated by ent.
 */
export type UpdateReportScheduleInput = {
  /** 1-31. Use 0 to indicate null. Must be set to other than 0 when schedule is not daily. For non-daily reports, represents the day of week or month on which the report will be triggered */
  daysAfterNonDailyScheduleTypeToTrigger?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reportScheduleID: Scalars['ID']['input'];
};

/** UpdateTenantInput */
export type UpdateTenantInput = {
  /** Taxbit's client identifier */
  clientID: Scalars['String']['input'];
  /** Taxbit's client secret */
  clientSecret: Scalars['String']['input'];
  /** Organization ID associated to the tenant */
  organizationKeyID: Scalars['String']['input'];
  /** Unique tenant identifier */
  tenantID: Scalars['String']['input'];
};

/** UpdateVaultPoliciesOperation represents an operation where multiple vault policies are updated */
export type UpdateVaultPoliciesOperation = Operation & {
  __typename: 'UpdateVaultPoliciesOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** the expected digest of the new organization after applying this change */
  newOrganizationDigest: Scalars['String']['output'];
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * policy required in order to satisfy this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /** roleChanges represents the IAM role changes that will be applied with this operation. */
  roleChanges: Maybe<IAMRoleChanges>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /** Policies being updated by this operation */
  updatedPolicies: Array<VaultPolicyUpdate>;
  /** The vault being updated */
  vault: Vault;
  /** Vault Policy Container updates being applied by this operation */
  vaultPolicyContainerUpdate: Maybe<VaultPolicyContainerUpdate>;
};


/** UpdateVaultPoliciesOperation represents an operation where multiple vault policies are updated */
export type UpdateVaultPoliciesOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** UpdateVaultPoliciesOperation represents an operation where multiple vault policies are updated */
export type UpdateVaultPoliciesOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/** Upload pair of folder and filenames */
export type UploadAlertCaseFiles = {
  /** Filename of document */
  documentName: Scalars['String']['input'];
  /** Folder name */
  folderID: Scalars['String']['input'];
};

/** Response pair of filename and corresponding signed URL */
export type UploadSignedUrl = {
  __typename: 'UploadSignedUrl';
  /** Filename */
  filename: Scalars['String']['output'];
  /** Signed URL */
  signedUrl: Scalars['String']['output'];
};

/** User */
export type User = Node & {
  __typename: 'User';
  /** Timestamp of when the user was added to the organization with nanosecond precision */
  addedTime: Maybe<Scalars['String']['output']>;
  /** email is the user's email */
  email: Scalars['String']['output'];
  /** master control key encrypted to the iOS device's encryptionPublicKey */
  encryptedControlMasterPrivateKey: Maybe<Scalars['String']['output']>;
  /**
   * encryptionPublicKey is the iOS secure enclave key for this iOS device for receiving encrypted data such as wallet key backups.
   * This is encoded according to anchorcrypto.ECDSAPubKeyToSecureEnclaveBase64URL
   * See the Porto spec for more details
   * https://www.notion.so/anchorage/Porto-Control-key-key-export-features-d9b433d754234d60a75884007b859477#d301e6fe204646388799bf38cacc592c
   */
  encryptionPublicKey: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements that the user has made */
  endorsements: Array<SimplifiedEndorsement>;
  /**
   * Indicates if this user has lost its device
   * @deprecated Use deviceStatus
   */
  hasLostDevice: Scalars['Boolean']['output'];
  /** id is the id of the user's public key. This id is not stable */
  id: Scalars['ID']['output'];
  /** Indicates whether the user is a trader */
  isTrader: Scalars['Boolean']['output'];
  /**
   * Indicates the last known device status
   * Note that since this value is computed it may not reflect the real-time status.
   */
  lastDeviceStatus: DeviceStatus;
  /** Last login information. Risk metadata will be empty if the user never logged in */
  lastLogin: Login;
  /** name is the user's name */
  name: Scalars['String']['output'];
  /** The organization this user is within, if any */
  organization: Maybe<Organization>;
  /** The natural person this user is within an organization */
  person: Maybe<Person>;
  /** The policies that are applied to the user. */
  policies: ReadPolicyConnection;
  /** publicKeyID is the id of the user's public key */
  publicKeyID: Scalars['PublicKeyID']['output'];
  roles: IAMUserRoleConnection;
  /** secureName is the user's cryptographically protected name */
  secureName: Scalars['String']['output'];
  /** userID is the stable and unique user's identifier */
  userID: Scalars['ID']['output'];
  /** Description of why a user cannot be removed or null if the user can be removed */
  userRemovalPossibleDescription: Maybe<Scalars['String']['output']>;
  /** Indicates the type of the user */
  userType: UserType;
  /**
   * The encrypted shares of the wallet recovery key asscociated with this user, if any.
   * This only applies for Porto orgs with the WALLET_RECOVERY_KEY strategy.
   */
  walletRecoveryKeyShares: Maybe<Array<Scalars['String']['output']>>;
};


/** User */
export type UserendorsementsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  uniqueEndorsements?: InputMaybe<Scalars['Boolean']['input']>;
};


/** User */
export type UserpoliciesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** User */
export type UserrolesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IAMRoleOrder>;
  where?: InputMaybe<IAMRoleWhereInput>;
};

/** A connection to a list of users. */
export type UserConnection = {
  __typename: 'UserConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UserEdge>>>;
  /** Information to aid in pagination */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection, representing a User instance */
export type UserEdge = {
  __typename: 'UserEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge */
  node: Maybe<User>;
};

/**
 * UserKey represents a key that is used in interactive sessions driven by a
 * human being.
 */
export type UserKey = Actor & Key & Node & {
  __typename: 'UserKey';
  /** Actor information. */
  actorInfo: ActorInfo;
  /** Email used during enrollment of this key. */
  email: Scalars['String']['output'];
  /** endorsements is the list of endorsements that the user has made */
  endorsements: Array<SimplifiedEndorsement>;
  /** The enrollment token associated with this key. */
  enrollToken: Maybe<Scalars['String']['output']>;
  /** Unique identifier of this user key. */
  id: Scalars['ID']['output'];
  /** Key details. */
  keyInfo: KeyInfo;
  /** Details about the last login of this user key. */
  lastLogin: Maybe<Login>;
  /**
   * The quorum memberships of this key. Membership in a quorum can be used to
   * derive the legacy user type:
   * - If the key is a member of at least organization quorum, then the key has the
   *   legacy user type of `administrator`.
   * - If the key is a member of only vault quorums, then the key has the legacy
   *   user type of `member`.
   * - If the key is not a member of any quorum, then the key has the legacy user
   *   type of `view-only`.
   */
  quorumMembershipTypes: Array<UserKeyQuorumMembershipType>;
  /** Returns more info about the user the key belongs to */
  user: UserV2;
};


/**
 * UserKey represents a key that is used in interactive sessions driven by a
 * human being.
 */
export type UserKeyendorsementsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  uniqueEndorsements?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * This type enumerates the different types of quorum membership that a key can
 * have.
 */
export enum UserKeyQuorumMembershipType {
  /** The key is a member of at least one organization-level quorum. */
  ORGANIZATION = 'ORGANIZATION',
  /** The key is a member of at least one vault-level quorum. */
  VAULT = 'VAULT'
}

/** TODO: add a description */
export type UserSettings = {
  __typename: 'UserSettings';
  /** TODO: add a description */
  tradeDailyDigestEnabled: Scalars['Boolean']['output'];
  /** TODO: add a description */
  tradeNotificationPerTransactionEnabled: Scalars['Boolean']['output'];
  /** TODO: add a description */
  updatedAt: Scalars['Timestamp']['output'];
  /** TODO: add a description */
  userKeyID: Scalars['String']['output'];
};

/** UserSortField are the options for sorting a list of users. */
export enum UserSortField {
  /** NAME sorts by the users' display names */
  NAME = 'NAME'
}

/** UserStatus represents the overall status of a user. */
export enum UserStatus {
  /** User has at least one key that can be used to login/perform actions. */
  ACTIVE = 'ACTIVE',
  /**
   * User was marked as blocked by Anchorage and no key can be used to login/perform
   * actions. This state is reversible and the user might be unblocked by Anchorage
   * or removed by other members of the organization.
   */
  BLOCKED = 'BLOCKED',
  /** User does not have keys that can be used to login/perform actions. */
  INOPERATIVE = 'INOPERATIVE'
}

/** Represents the type of a User */
export enum UserType {
  /** User who belongs to one or more Organization's quorums/policies */
  ADMIN = 'ADMIN',
  /** User who belongs to the brokereage desk team */
  BROKERAGE_DESK = 'BROKERAGE_DESK',
  /** User who belongs to one or Vault quorums/policie */
  MEMBER = 'MEMBER',
  /** This user object is not a user */
  NOT_A_USER = 'NOT_A_USER',
  /** This user key was removed. */
  REMOVED = 'REMOVED',
  /** User who doesn't belong to any quorum/policy */
  VIEW_ONLY = 'VIEW_ONLY'
}

/** A user of Anchorage. */
export type UserV2 = Actor & Node & {
  __typename: 'UserV2';
  /** Actor information. */
  actorInfo: ActorInfo;
  /** Email of the user. */
  email: Maybe<Scalars['String']['output']>;
  /** Unique user identifier. */
  id: Scalars['ID']['output'];
  /** List of keys that are owned by this user. */
  keys: KeyConnection;
  /** Details about the last login of this user. */
  lastLogin: Maybe<Login>;
  /** Name of the user. */
  name: Scalars['String']['output'];
  /** Organization where this user is enrolled. */
  organization: Maybe<Organization>;
  /** Person associated with this user. */
  person: Maybe<Person>;
  /** Phone number of the user. */
  phoneNumber: Maybe<Scalars['String']['output']>;
  /** User status. */
  status: UserStatus;
  /**
   * The encrypted shares of the wallet recovery key asscociated with this user, if any.
   * This only applies for Porto orgs with the WALLET_RECOVERY_KEY strategy.
   */
  walletRecoveryKeyShares: Maybe<Array<Scalars['String']['output']>>;
};


/** A user of Anchorage. */
export type UserV2keysArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KeyWhereInput>;
};

/** A connection to a list of users. */
export type UserV2Connection = {
  __typename: 'UserV2Connection';
  /** A list of edges. */
  edges: Array<Maybe<UserV2Edge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a user connection. */
export type UserV2Edge = {
  __typename: 'UserV2Edge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: UserV2;
};

/** Filtering options for Users V2 returned from the connection. */
export type UserV2WhereInput = {
  /** Filter by user IDs. */
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by key IDs. */
  keyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** ValidatorDetail is a generic interface for validator detail */
export type ValidatorDetail = CeloValidatorGroupDetail | NonAddressValidatorDetail | OasisValidatorDetail;

/**
 * ValidatorInputStep is step that allows the user to pick a validator address.
 * This step can be augmented with "favourites", or "recently used".
 */
export type ValidatorInputStep = StakingStep & {
  __typename: 'ValidatorInputStep';
  /** A regex for the UI to validate the input address in real time. */
  addressRegex: Scalars['String']['output'];
  /** Of all knownValidators, which one should be the "default" (aka "chosen") in UI drop-down. */
  defaultKnownValidator: Maybe<DelegationAddress>;
  /**
   * Some assets use different terminology for the "validator" name, this field should help customize
   * labels shown on the app.
   *
   * TODO: do not use - will panic.
   */
  description: Scalars['String']['output'];
  /**
   * Ignore this field
   *
   * TODO: do not use - will panic.
   */
  ignoreMe: Maybe<Scalars['Boolean']['output']>;
  /**
   * This includes some of the known validators. They are either Anchorage or 3rd-party validator.
   * The list is sorted by Anchorage validators first, 3rd-party validators second.
   */
  knownValidators: Maybe<Array<DelegationAddress>>;
  /** Rules that change presentation of the step */
  rules: Maybe<Array<PresentationRule>>;
};

/** Vault */
export type Vault = IAMResource & Node & {
  __typename: 'Vault';
  /** Account this vault belongs to */
  account: Account;
  /**
   * List of assets associated with this vault. May be filtered by asset type (optional) and if is non fungible (optional)
   * @deprecated use the paginated wallets resolver and access assets only within the context of a wallet
   */
  assets: Array<Asset>;
  /** A paginated list of Addresses in this vault. Only one filter or search parameter at a time is supported for now. */
  balancesByAddress: AddressBalanceConnection;
  /** Number of withdrawals done on this vault */
  completedWithdrawalsCount: Scalars['Int']['output'];
  /** Timestamp of when the vault was created with nanosecond precision */
  createdAt: Scalars['String']['output'];
  /** Day change in percentage of holdings in this vault (proportion out of 1 format, ET: 1, 0.5, 0.1) */
  dayChange: Scalars['String']['output'];
  /** Description of the vault */
  description: Scalars['String']['output'];
  /**
   * FiatWallet returns wallet, if wallet doesn't exist it creates one
   * @deprecated use the fiatWalletCreateIfAbsent resolver to create a wallet if it doesn't exist or to get an existing wallet
   */
  fiatWallet: WalletEdge;
  /** fiatWalletCreateIfAbsent returns wallet, if wallet doesn't exist it creates one if requested */
  fiatWalletCreateIfAbsent: Maybe<WalletEdge>;
  iam: IAM;
  /**
   * The ID of the Vault object
   * @deprecated use VaultID instead
   */
  id: Scalars['ID']['output'];
  /** Name of the vault */
  name: Scalars['String']['output'];
  /** Gets the operation by id */
  operation: Maybe<Operation>;
  /**
   * Get the list of operations in this vault
   * first and after implement the connections spec
   */
  operations: OperationConnection;
  /** organization gets the organization sub resolver */
  organization: Organization;
  /**
   * Policies for all actions
   * @deprecated Legacy PolicyMaps. Use 'readPolicies'
   */
  policies: Array<Policy>;
  /**
   * Policy for the provided action
   * @deprecated Legacy PolicyMaps. Use 'readPolicies'
   */
  policy: Maybe<Policy>;
  /** Information about policy containers on the resource. */
  policyInfo: Maybe<PolicyInfo>;
  /** Portfolio is a vault level summary of holdings */
  portfolio: Portfolio;
  /** Non-signable representation of the policies applicable to this vault */
  readPolicies: ReadPolicyConnection;
  /** Indicates the state of the vault */
  state: VaultState;
  /** Simplified representation of a vault ID */
  uniqueID: Scalars['String']['output'];
  /** userAccesses returns a paginated list of user/access relations for the Vault. */
  userAccesses: Maybe<IAMUserAccessConnection>;
  /** Get the list of users in the vault policies */
  users: Array<User>;
  /** VaultAccounts shows all the accounts that this vault has been assigned to and the timestamp of when it was assigned. The latest one should indicate the current account. */
  vaultAccounts: Array<VaultAccount>;
  /** ID of a vault within an organization */
  vaultID: VaultID;
  /** This is non-null if the backup needs to happen before a wallet can be created for the given network */
  walletKeyBackupBeforeWalletCreation: Maybe<WalletKeyBackup>;
  /**
   * Returns a paginated list of all Wallets in this vault.
   * Only one of searchByName or filterByAssetTypeNetworkIDs can be used at a time.
   * This API will soon replace "wallets".
   *
   * NOTE: these quantity filters will be silently ignored iff the org is Porto:
   * - filterMinQuantity, filterMaxQuantity, quantityRangeIsInclusive.
   * - filterByNonZeroBalance.
   */
  wallets: WalletConnection;
  /**
   * Returns a paginated list of all Wallets in this vault.
   * Only one of searchByName or filterByAssetTypeNetworkIDs can be used at a time.
   * This API will soon be replaced by "wallets".
   *
   * NOTE: these quantity filters will be silently ignored iff the org is Porto:
   * - filterMinQuantity, filterMaxQuantity, quantityRangeIsInclusive.
   * - filterByNonZeroBalance.
   */
  wallets1: WalletConnection;
};


/** Vault */
export type VaultassetsArgs = {
  assetType?: InputMaybe<Scalars['AssetTypeID']['input']>;
  filterIsNonFungible?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Vault */
export type VaultbalancesByAddressArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterByAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterByWalletIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchByAddress?: InputMaybe<Scalars['String']['input']>;
};


/** Vault */
export type VaultfiatWalletArgs = {
  assetTypeID: Scalars['AssetTypeID']['input'];
};


/** Vault */
export type VaultfiatWalletCreateIfAbsentArgs = {
  assetTypeID: Scalars['AssetTypeID']['input'];
  createIfAbsent: Scalars['Boolean']['input'];
};


/** Vault */
export type VaultoperationArgs = {
  operationID: Scalars['String']['input'];
};


/** Vault */
export type VaultoperationsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterActions?: InputMaybe<Array<OperationAction>>;
  filterAssetSubID?: InputMaybe<Scalars['String']['input']>;
  filterAssetTypeID?: InputMaybe<Scalars['AssetTypeID']['input']>;
  filterAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterCreatedSince?: InputMaybe<Scalars['String']['input']>;
  filterCreatedUntil?: InputMaybe<Scalars['String']['input']>;
  filterRequiresDecision?: InputMaybe<Scalars['Boolean']['input']>;
  filterStates?: InputMaybe<Array<OperationState>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeChange?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortDESC?: InputMaybe<Scalars['Boolean']['input']>;
  sortField?: InputMaybe<OperationSortFields>;
};


/** Vault */
export type VaultpolicyArgs = {
  action: PolicyActionType;
};


/** Vault */
export type VaultreadPoliciesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Vault */
export type VaultuserAccessesArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IAMUserAccessWhereInput>;
};


/** Vault */
export type VaultwalletKeyBackupBeforeWalletCreationArgs = {
  networkID: Scalars['String']['input'];
};


/** Vault */
export type VaultwalletsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterByAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterByAssetTypeNetworkIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterByIsArchived?: InputMaybe<Scalars['Boolean']['input']>;
  filterByNetworkIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterByNonZeroBalance?: InputMaybe<Scalars['Boolean']['input']>;
  filterByWalletIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterByWalletsWithOneOfAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterMaxQuantity?: InputMaybe<Scalars['String']['input']>;
  filterMinQuantity?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  quantityRangeIsInclusive?: Scalars['Boolean']['input'];
  searchByName?: InputMaybe<Scalars['String']['input']>;
  sortDESC?: Scalars['Boolean']['input'];
  sortField?: WalletSortField;
};


/** Vault */
export type Vaultwallets1Args = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterByAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterByAssetTypeNetworkIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterByIsArchived?: InputMaybe<Scalars['Boolean']['input']>;
  filterByNetworkIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  filterByNonZeroBalance?: InputMaybe<Scalars['Boolean']['input']>;
  filterByWalletIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterByWalletsWithOneOfAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  filterMaxQuantity?: InputMaybe<Scalars['String']['input']>;
  filterMinQuantity?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  quantityRangeIsInclusive?: Scalars['Boolean']['input'];
  searchByName?: InputMaybe<Scalars['String']['input']>;
  sortDESC?: Scalars['Boolean']['input'];
  sortField?: WalletSortField;
};

/** VaultAccount maps the relationship of when a particular vault was assigned to an account. Note that the same vault can be moved to different accounts within the same organization. */
export type VaultAccount = {
  __typename: 'VaultAccount';
  /** The account that the vault was assigned to at one time */
  account: Account;
  /** The vault in question */
  vault: Vault;
  /** The time the vault was associated with the account */
  vaultAssociatedAt: Scalars['Timestamp']['output'];
};

/** VaultConnection contains a paginated list of Vaults - implements the connections spec for pagination */
export type VaultConnection = {
  __typename: 'VaultConnection';
  /** edges is a list of VaultEdge objects representing a page of vaults data. Implements the connections spec for pagination. */
  edges: Array<VaultEdge>;
  /** pageInfo returns information about the page returned above. Implements the connections spec for pagination. */
  pageInfo: PageInfo;
};

/** VaultEdge contains a vault and the cursor representing the same vault. Implements the connections spec for pagination. */
export type VaultEdge = {
  __typename: 'VaultEdge';
  /** Cursor implements the connections spec for pagination */
  cursor: Scalars['ID']['output'];
  /** node implements the connections spec for pagination */
  node: Vault;
};

/** VaultID is the ID of a vault within an organization */
export type VaultID = {
  __typename: 'VaultID';
  /** organizationKeyID is the ID of the organization this vault is in */
  organizationKeyID: Scalars['String']['output'];
  /** vault resolves the vault corresponding to this VaultID */
  vault: Vault;
  /** vaultSubID is the ID of the vault within the organization */
  vaultSubID: Scalars['String']['output'];
};

/** Describes a policy for a specific vault and action */
export type VaultPolicy = {
  __typename: 'VaultPolicy';
  /** The policy associated with this vault */
  policy: Policy;
  /** The vault this policy refers to */
  vault: Vault;
};

/** VaultPolicyContainerUpdate is the collection of updates being applied to the vaults policy containers */
export type VaultPolicyContainerUpdate = {
  __typename: 'VaultPolicyContainerUpdate';
  /** fragmentBindingUpdates is the fragment changes that will be applied to the policy */
  fragmentBindingUpdates: Maybe<Array<Maybe<VaultPolicyFragmentBindingUpdate>>>;
  /** roleBindingUpdates is the role binding changes that will be applied to the policy */
  roleBindingUpdates: Maybe<Array<Maybe<VaultPolicyRoleBindingUpdate>>>;
};

/** VaultPolicyFragmentBindingUpdate is an update that will be applied to a single fragment on a vault. */
export type VaultPolicyFragmentBindingUpdate = {
  __typename: 'VaultPolicyFragmentBindingUpdate';
  /** originalBinding is the fragment binding prior to update. This will not be present for newly added fragment bindings. */
  originalBinding: Maybe<PolicyFragmentBinding>;
  /** updatedBinding is the fragment binding prior to update. This will not be present for fragment bindings being deleted. */
  updatedBinding: Maybe<PolicyFragmentBinding>;
  /** ID of a vault within an organization */
  vaultID: VaultID;
};

/**
 * Holds information about the action of a vault that cannot be safistied due
 * to the respective list of users.
 *
 * This is tightly coupled with users' device health.
 */
export type VaultPolicyQuorumSummary = {
  __typename: 'VaultPolicyQuorumSummary';
  /** The action that cannot be satisfied due to unsatisfiable policy. */
  action: PolicyActionType;
  /** The human readable display name of the policy. */
  displayName: Scalars['String']['output'];
  /** Status of this quorum given current user devices' health */
  status: Maybe<PolicyStatus>;
  /** Users that are used to compute the quorum. */
  users: Array<User>;
  /** The vault this summary refers to. */
  vault: Vault;
};


/**
 * Holds information about the action of a vault that cannot be safistied due
 * to the respective list of users.
 *
 * This is tightly coupled with users' device health.
 */
export type VaultPolicyQuorumSummaryusersArgs = {
  sortField?: InputMaybe<UserSortField>;
};

/** VaultPolicyRoleBindingUpdate is an update that will be applied to a single role binding fragment on a vault. */
export type VaultPolicyRoleBindingUpdate = {
  __typename: 'VaultPolicyRoleBindingUpdate';
  /** originalBinding is the role binding prior to update. This will not be present for newly added role bindings. */
  originalBinding: Maybe<PolicyRoleBinding>;
  /** updatedBinding is the binding after update. This will not be present for bindings being deleted. */
  updatedBinding: Maybe<PolicyRoleBinding>;
  /** ID of a vault within an organization */
  vaultID: VaultID;
};

/** VaultPolicyUpdate represents a policy change on a specific vault */
export type VaultPolicyUpdate = {
  __typename: 'VaultPolicyUpdate';
  /** Original policy */
  originalPolicy: VaultPolicy;
  /** new initiators list for the policy */
  updatedInitiateOnlyUsers: Array<User>;
  /** quorums are the list of quorums for this policy */
  updatedQuorums: Array<Quorum>;
  /**
   * new list of required users.
   * this list is not cryptographically protected
   * @deprecated use updatedRequiredUsersQuorums instead
   */
  updatedRequiredUsers: Array<User>;
  /**
   * updatedRequiredUsersQuorums are the softly required user quorums for this policy update.
   * If there are no updatedRequiredUsersQuorums an empty list will be returned.
   */
  updatedRequiredUsersQuorums: Array<RequiredUsersQuorum>;
};


/** VaultPolicyUpdate represents a policy change on a specific vault */
export type VaultPolicyUpdateupdatedInitiateOnlyUsersArgs = {
  sortField?: InputMaybe<UserSortField>;
};


/** VaultPolicyUpdate represents a policy change on a specific vault */
export type VaultPolicyUpdateupdatedRequiredUsersArgs = {
  sortField?: InputMaybe<UserSortField>;
};

/** Sorting options for vaults */
export enum VaultSortField {
  /** Sort by the VaultSubID */
  VAULT_SUB_ID = 'VAULT_SUB_ID'
}

/** Possible vault states */
export enum VaultState {
  /** The vault is disabled and holds no funds. */
  DISABLED_WITHOUT_ASSETS = 'DISABLED_WITHOUT_ASSETS',
  /** The vault is disabled but holds funds. */
  DISABLED_WITH_ASSETS = 'DISABLED_WITH_ASSETS',
  /** The vault is enabled. It may or may not hold funds. */
  ENABLED = 'ENABLED',
  /**
   * The vault is enabled but holds no funds
   * @deprecated Use ENABLED
   */
  ENABLED_WITHOUT_ASSETS = 'ENABLED_WITHOUT_ASSETS',
  /**
   * The vault is enabled and holds funds.
   * @deprecated Use ENABLED
   */
  ENABLED_WITH_ASSETS = 'ENABLED_WITH_ASSETS'
}

/** A filter to be used against Vault fields. */
export type VaultWhereInput = {
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** VestingInfo contains info for claiming vested funds */
export type VestingInfo = {
  __typename: 'VestingInfo';
  /** autoClaimVestedSchedule is the vesting schedule */
  autoClaimVestedSchedule: VestingSchedule;
  /** contractAddress is the address of the contract holding the locked funds */
  contractAddress: Scalars['String']['output'];
  /** lastClaimVested is the last time a claim vested operation was performed, automatic or manually */
  lastClaimVested: Maybe<Scalars['String']['output']>;
  /** unvestedDescription is an optional description of the unvested balance, e.g. number of blocks or time left to fully unvest */
  unvestedDescription: Maybe<Scalars['String']['output']>;
};

/** VestingSchedule sets fuzzy time periods for when an auto vesting transaction should happen. */
export enum VestingSchedule {
  /** DAILY tries to complete a transaction daily. */
  DAILY = 'DAILY',
  /** END_OF_MONTH tries to complete a transaction at the end of the month. */
  END_OF_MONTH = 'END_OF_MONTH',
  /** NONE denotes a vesting contract without an auto schedule. This must be done manually. */
  NONE = 'NONE',
  /** START_OF_WEEK tries to complete a transaction at the start of week. */
  START_OF_WEEK = 'START_OF_WEEK',
  /** START_OF_WEEK_AND_END_OF_MONTH tries to complete a transaction once at the start of week and end of month. */
  START_OF_WEEK_AND_END_OF_MONTH = 'START_OF_WEEK_AND_END_OF_MONTH'
}

/** VestingWallet provides details of a wallet in an offchain vesting schedule */
export type VestingWallet = {
  __typename: 'VestingWallet';
  /** Total amount to vest on the wallet */
  totalAmount: Amount;
  /** Amount vested so far on the wallet */
  vestedAmount: Amount;
  /** Holds for vesting on the wallet */
  vestingHolds: Array<Hold>;
  /** Wallet containing vesting amounts */
  wallet: Wallet;
};

/** VisibleForPurpose indicate if the balance should be visible for given purpose. */
export enum VisibleForPurpose {
  /** This gives a overview of the account balances. Used currently by the valult page in the app. */
  OVERVIEW = 'OVERVIEW'
}

/** VoiceBiometricsStatus is the result from the voice biometrics API */
export enum VoiceBiometricsStatus {
  /** ACCEPTED means the voice passed authentication */
  ACCEPTED = 'ACCEPTED',
  /**
   * ENROLLED means there was not enough data to authenticate the voice so the sample was used
   * for training and the voice should be checked
   */
  ENROLLED = 'ENROLLED',
  /** ENROLLMENT_PAUSED means the voice service is not accepting new users at the moment and could not authenticate */
  ENROLLMENT_PAUSED = 'ENROLLMENT_PAUSED',
  /** ERRORED means the voice data was not able to be checked and requires attention */
  ERRORED = 'ERRORED',
  /** FAILED means the voice failed authentication and requires attention */
  FAILED = 'FAILED'
}

/** VoteDetails is a union type of all possible forms of extra vote information */
export type VoteDetails = CeloProposalDetails | MakerPollDetails | MakerVoteDetails;

/** VoteOperation represents a Vote operation */
export type VoteOperation = Operation & {
  __typename: 'VoteOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /** The asset associated with this operation */
  asset: Asset;
  /** asset id from with the authorization was made */
  assetID: AssetID;
  /** Further information regarding this asset type associated with this operation */
  assetTypeInfo: AssetTypeInfo;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** canonicalOperationVersion is the version of the canonical authorization that must be used to sign this authorization */
  canonicalOperationVersion: Scalars['Int']['output'];
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** confirmations returns how many confirmations the transaction associated with this authorization has received */
  confirmations: Maybe<Scalars['Int']['output']>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** details returns non-generic information on the vote operation (MKR ballot, proposal support, etc) */
  details: Maybe<VoteDetails>;
  /** the type of vote, such as polling. used for display purposes, as opposed to the voteType in extraParams used for operation creation */
  displayVoteType: VoteType;
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * extraParams are additional asset type specific parameters that were set for this transaction
   * When constructing the canonical operation for signing, the extra params go in the ExtraParams field
   * as a map from asset type id (usually, parent asset type id) to params object. The convention of extra
   * params is to omit all empty fields, and if no extra params are set for an asset type, to omit the asset
   * type from the map entirely. Note that for legacy reasons this convention
   * is the opposite of the normal CanonicalOperation convention, where no fields are ever omitted.
   */
  extraParams: Maybe<ExtraParams>;
  /** final transaction fee charged for the authorization, detected on the blockchain */
  finalFee: Maybe<Amount>;
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** Returns the Maker governance executive vote ballot active when this operation was created, if one exists. */
  makerBallot: Maybe<MakerGovernanceBallot>;
  /** Returns the Maker governance poll this operation voted on, if one exists. */
  makerPoll: Maybe<MakerPoll>;
  /** max transaction fee charged for the authorization */
  maxFee: Maybe<Amount>;
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * policy that applies to this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /**
   * replayProtectionParams are the parameters to be sent to the HSM for creating and signing
   * the transactions required to effect this ExecuteOperation. The user *is not* expected
   * to review the contents of this field. However it may contain information necessary to prevent
   * replays and must be included in the payload signed by the user. Format is a hex-encoded
   * byte array.
   */
  replayProtectionParams: Maybe<Scalars['String']['output']>;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /** sendingBlockchainAddresses is a list of addresses from which a vote operation is sent */
  sendingBlockchainAddresses: Array<BlockchainAddress>;
  /** signedTransaction is the blockchain transaction to be broadcast to the network */
  signedTransaction: Maybe<Scalars['String']['output']>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /**
   * sources are the originating addresses for the authorization
   * @deprecated use sendingBlockchainAddresses
   */
  sources: Array<Source>;
  /** transactionID is the blockchain transaction id once available */
  transactionID: Maybe<Scalars['String']['output']>;
  /** transactionSuccess indicates if the blockchain transaction succeeded */
  transactionSuccess: Maybe<Scalars['Boolean']['output']>;
  /**
   * usdValueFee is the USD value of the fee (finalFee if known, otherwise maxFee).
   * If both the finalFee and maxFee are not yet known, this field will be null
   */
  usdValueFee: Maybe<Scalars['String']['output']>;
  /** viewTransactionLink is the link to get more info about the transaction from a third party */
  viewTransactionLink: Maybe<Scalars['String']['output']>;
  /** voteYeas returns the list of addresses representing proposals the client is voting "Yea" on */
  voteYeas: Array<Scalars['String']['output']>;
  /** the weight of the vote, nillable to be generic */
  votingPower: Maybe<Amount>;
};


/** VoteOperation represents a Vote operation */
export type VoteOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** VoteOperation represents a Vote operation */
export type VoteOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

/** VoteOption is the generic enum for governance votes */
export enum VoteOption {
  /** Abstain from vote */
  ABSTAIN = 'ABSTAIN',
  /** Vote no */
  NO = 'NO',
  /** Vote yes */
  YES = 'YES'
}

/** TODO: add a description */
export type VoteTotal = {
  __typename: 'VoteTotal';
  /** Total amount voting for "abstain" */
  abstain: Amount;
  /** Total amount voting for "no" */
  no: Amount;
  /** Total amount voting for "yes" */
  yes: Amount;
};

/** VoteType differentiates between the various types of votes possible */
export enum VoteType {
  /** A vote directly results in the change in behavior of a token. Used by Maker */
  EXECUTIVE = 'EXECUTIVE',
  /** A poll has no direct effect on the behavior of a token. Used by Maker */
  POLL = 'POLL',
  /** A referendum vote is for Celo governance */
  REFERENDUM = 'REFERENDUM'
}

/** Wallet */
export type Wallet = Node & {
  __typename: 'Wallet';
  /** List of addresses in this wallet */
  addresses: Array<Scalars['String']['output']>;
  /** List of assets associated with this wallet. May be filtered by asset types (optional) */
  assets: Array<Asset>;
  /**
   * Returns a list of balances of all asset types with balance > 0 within this wallet
   * When filterByAssetTypeIDs is set, returns a list of balances with asset types in filterByAssetTypeIDs and balance > 0
   * @deprecated use assets instead
   */
  balances: Array<Amount>;
  /** An ongoing withdrawal that is blocking new withdrawals from this wallet */
  conflictingWithdraw: Maybe<Operation>;
  /** The time at which the wallet was created */
  createdAt: Scalars['String']['output'];
  /**
   * DepositAddress returns an address to be used for deposits into this wallet. This is the most recently created address if there are multiple addresses in the wallet (ex. for bitcoin).
   * @deprecated use walletDepositInstructions instead
   */
  depositAddress: BlockchainAddress;
  /** The ID of the Wallet object */
  id: Scalars['ID']['output'];
  /**
   * isArchived indicates if the wallet has been archived.
   * Archived wallets, by default, will appear in wallet listing APIs (though,
   * caller can override the default filter to get just archived wallets.)
   */
  isArchived: Maybe<Scalars['Boolean']['output']>;
  /** Name of the Wallet */
  name: Scalars['String']['output'];
  /**
   * namedBalances represent portions of the balance of a wallet that is being used or held for a specific purpose.
   * Set debug to true to enable more logging.
   * @deprecated use assets instead
   */
  namedBalances: Array<NamedBalance>;
  /** networkInfo returns info about which network the wallet is for */
  networkInfo: NetworkInfo;
  /** The number of addresses in this wallet present in the addresses list */
  numberOfAddresses: Scalars['Int']['output'];
  /** recommendedBalanceForFee returns the amount of funds recommended to keep in this wallet to cover fees for one transaction. Will return a null value if you already have sufficient balance. */
  recommendedBalanceForFee: Maybe<Amount>;
  /** Vault containing this wallet */
  vault: Vault;
  /** Wallet Deposit Instructions */
  walletDepositInstructions: WalletDepositInstructions;
  /** If this returns a backup, the frontend has to call ackWalletKeyDownload before creating addresses in this wallet */
  walletKeyBackupBeforeAddressCreation: Maybe<WalletKeyBackup>;
  /** If this returns a backup, the frontend has to call ackWalletKeyDownload before withdrawing in this wallet */
  walletKeyBackupBeforeWithdraw: Maybe<WalletKeyBackup>;
  /**
   * List of keys for the wallet
   * This is helpful for debugging, but we probably shouldn't query it in UIs because it's not paginated
   */
  walletKeys: Array<WalletKey>;
  /** Whether a withdrawal can be made or not due to conflicting operations in progress */
  withdrawPossible: Maybe<Scalars['Boolean']['output']>;
};


/** Wallet */
export type WalletassetsArgs = {
  filterByAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
};


/** Wallet */
export type WalletbalancesArgs = {
  filterByAssetTypeIDs?: InputMaybe<Array<InputMaybe<Scalars['AssetTypeID']['input']>>>;
};


/** Wallet */
export type WalletnamedBalancesArgs = {
  debug?: Scalars['Boolean']['input'];
  filterByAssetTypeIDs?: InputMaybe<Array<Scalars['AssetTypeID']['input']>>;
  visibleForPurpose?: InputMaybe<VisibleForPurpose>;
};

/** An amount of an asset for a given wallet. Originally used in the context of settlements, but can be used in other contexts. */
export type WalletAmountInput = {
  /** An amount of an asset */
  amount: AmountInput;
  /** The wallet ID */
  walletID: Scalars['ID']['input'];
};

/** Response to a request to approve a WalletConnect session. */
export type WalletConnectApproveSessionResponse = {
  __typename: 'WalletConnectApproveSessionResponse';
  /** True if the request was successful, false otherwise. */
  success: Scalars['Boolean']['output'];
};

/** Response to a request to initialize a WalletConnect session. */
export type WalletConnectInitializeSessionResponse = {
  __typename: 'WalletConnectInitializeSessionResponse';
  /** Session details. */
  session: WalletConnectSession;
  /** True if the request was successful, false otherwise. */
  success: Scalars['Boolean']['output'];
};

/** Response to a request to reject a WalletConnect session. */
export type WalletConnectRejectSessionResponse = {
  __typename: 'WalletConnectRejectSessionResponse';
  /** True if the request was successful, false otherwise. */
  success: Scalars['Boolean']['output'];
};

/** WalletConnectSession */
export type WalletConnectSession = Node & {
  __typename: 'WalletConnectSession';
  /** dApp */
  dApp: DApp;
  /** establishedAt */
  establishedAt: Scalars['Timestamp']['output'];
  /** id identifies the connection/session */
  id: Scalars['ID']['output'];
  /** connection/session status */
  status: WalletConnectSessionStatus;
  /** terminatedAt */
  terminatedAt: Maybe<Scalars['Timestamp']['output']>;
  /** user */
  user: User;
  /** wallet */
  wallet: Wallet;
};

/** WalletConnectSessionConnection contains a paginated list of WalletConnectSession's - implements the connections spec for pagination */
export type WalletConnectSessionConnection = {
  __typename: 'WalletConnectSessionConnection';
  /** edges returns a list of WalletConnectSessionEdge objects representing a page of WalletConnectSession data. implements the connections spec for pagination */
  edges: Array<WalletConnectSessionEdge>;
  /** pageInfo returns information about the page returned above. implements the connections spec for pagination */
  pageInfo: PageInfo;
};

/** WalletConnectSessionEdge contains a WalletConnectSession and the cursor representing the same WalletConnectSession - implements the connections spec for pagination */
export type WalletConnectSessionEdge = {
  __typename: 'WalletConnectSessionEdge';
  /** Cursor implements the connections spec for pagination */
  cursor: Scalars['ID']['output'];
  /** node implements the connections spec for pagination */
  node: WalletConnectSession;
};

/**
 * WalletConnectSessionSortField defines the fields to sort a list of WalletConnectSession's by.
 * The enums must each match (case-insensitive) a column in the `walletconnect_dapp_connections` table.
 */
export enum WalletConnectSessionSortField {
  /** ESTABLISHED_AT uses `walletconnect_dapp_connections` table's `established_at` for sorting. */
  ESTABLISHED_AT = 'ESTABLISHED_AT'
}

/** WalletConnect connection/session statuses */
export enum WalletConnectSessionStatus {
  /** connection/session is established */
  ESTABLISHED = 'ESTABLISHED',
  /** connection/session is pending approval */
  PENDING = 'PENDING',
  /** connection/session is terminated */
  TERMINATED = 'TERMINATED',
  /** connection/session is terminating */
  TERMINATING = 'TERMINATING'
}

/** WalletConnection contains a paginated list of Wallets - implements the connections spec for pagination */
export type WalletConnection = {
  __typename: 'WalletConnection';
  /** edges returns a list of WalletEdge objects representing a page of wallets data. implements the connections spec for pagination */
  edges: Array<WalletEdge>;
  /** pageInfo returns information about the page returned above. implements the connections spec for pagination */
  pageInfo: PageInfo;
};

/** Wallet Deposit Instructions */
export type WalletDepositInstructions = BlockchainAddress | DepositInstructions;

/** WalletEdge contains a wallet and the cursor representing the same wallet - implements the connections spec for pagination */
export type WalletEdge = {
  __typename: 'WalletEdge';
  /** Cursor implements the connections spec for pagination */
  cursor: Scalars['ID']['output'];
  /** node implements the connections spec for pagination */
  node: Wallet;
};

/** WalletFilterData contains filter info that can be used to filter on wallet lists. */
export type WalletFilterData = {
  __typename: 'WalletFilterData';
  /**
   * dustThreshold is a numeric number. An asset quantity less than this amount is considered "dust" - ie, not spendable.
   * Note that this value is currently not parameterized on anything such as network, asset, org, etc.
   * That means all networs, asset types, orgs etc. will have the same "dust threshold amount".
   */
  dustThreshold: Scalars['String']['output'];
};

/** WalletGroup */
export type WalletGroup = Node & {
  __typename: 'WalletGroup';
  /** assetTypeID returns info about which AssetTypeID this group is for */
  assetTypeID: Scalars['AssetTypeID']['output'];
  /** The time at which the walletGroup was created */
  createdAt: Scalars['String']['output'];
  /** The ID of the walletGroup object */
  id: Scalars['ID']['output'];
  /** Name of the walletGroup */
  name: Scalars['String']['output'];
  /** the strategy this group has */
  rebalanceStrategy: Maybe<RebalanceStrategy>;
  /** the initial number of wallets in this walletGroup */
  targetNumberOfWallets: Scalars['Int']['output'];
  /** Vault containing this walletGroup */
  vault: Vault;
  /**
   * Returns a paginated list of all Wallets in this walletGroup.
   * Only one of searchByName or filterByAssetTypeNetworkIDs can be used at a time.
   */
  wallets: WalletConnection;
};


/** WalletGroup */
export type WalletGroupwalletsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filterByIsArchived?: InputMaybe<Scalars['Boolean']['input']>;
  filterByWalletIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortDESC?: Scalars['Boolean']['input'];
  sortField?: WalletSortField;
};

/** WalletKey represents an encrypted wallet key. */
export type WalletKey = {
  __typename: 'WalletKey';
  /** If backup data exists, it's available here */
  backup: Maybe<WalletKeyBackup>;
  /** The path to be used to derive the control child private key to allow this key to be used m/1/2/3 */
  controlKeyDerivationPath: Maybe<Scalars['String']['output']>;
  /** WalletKeyID */
  id: Scalars['ID']['output'];
  /** For Porto orgs, this is true if the backup hasn't been acknowledged through the ackWalletKeyDownload mutation since last time it was re-generated */
  mustAckWalletKeyDownload: Scalars['Boolean']['output'];
  /** The organizationKeyID the info is for */
  orgKeyID: Scalars['ID']['output'];
  /** The wallet ID */
  walletID: Scalars['ID']['output'];
};

/** WalletKeyBackup represents the data associated with a wallet backup. The most important part for the frontend is the URL. The rest is mostly there to make debugging easier. */
export type WalletKeyBackup = {
  __typename: 'WalletKeyBackup';
  /** Used to get the content of the PDF to */
  backupPDFDownloadURL: Scalars['String']['output'];
  /** There may be one or more of these. Only set if the backup strategy is ADMIN_KEYS_QUORUM */
  backupShares: Array<WalletKeyBackupShare>;
  /** Encrypted wallet key. Only set if the backup strategy is WALLET_RECOVERY_KEY */
  shareableEncryptedKey: Maybe<Scalars['String']['output']>;
  /** walletKey backlink */
  walletKey: WalletKey;
};

/** WalletKeyBackupShare holds enough information to allow a user to export all backups for the address */
export type WalletKeyBackupShare = {
  __typename: 'WalletKeyBackupShare';
  /** The share is encrypted to the user's encryptionPublicKey */
  encryptedShare: Scalars['String']['output'];
  /** This is the iOS devices that can decrypt the backup */
  user: User;
};

/** WalletKeyBackupStrategy can be either unset or one of the values below */
export enum WalletKeyBackupStrategy {
  /** ADMIN_KEYS_QUORUM is described in the Porto spec https://www.notion.so/anchorage/Porto-Control-key-key-export-features-d9b433d754234d60a75884007b859477 */
  ADMIN_KEYS_QUORUM = 'ADMIN_KEYS_QUORUM',
  /**
   * WALLET_RECOVERY_KEY strategy, encrypts all wallet keys with a key that's exportable.
   * This allows users to manage their own keys.
   */
  WALLET_RECOVERY_KEY = 'WALLET_RECOVERY_KEY'
}

/** Wallet key PDF export channels */
export enum WalletKeyExportChannel {
  /** local download - browser download(web) / share tab(IOS) */
  DOWNLOAD = 'DOWNLOAD',
  /** email delivery */
  EMAIL = 'EMAIL',
  /** unknown export channel */
  UNKNOWN = 'UNKNOWN'
}

/** WalletKeyExportDownload records information related to local download */
export type WalletKeyExportDownload = WalletKeyExportPreference & {
  __typename: 'WalletKeyExportDownload';
  /** WalletKeyExportChannel.DOWNLOAD */
  deliveryChannel: WalletKeyExportChannel;
};

/** WalletKeyExportEmail records information related to email delivery */
export type WalletKeyExportEmail = WalletKeyExportPreference & {
  __typename: 'WalletKeyExportEmail';
  /** WalletKeyExportChannel.EMAIL */
  deliveryChannel: WalletKeyExportChannel;
  /** The destination email the wallet key will be sent over */
  email: Scalars['String']['output'];
};

/** WalletKeyExportPreference denotes preference how users want their keys to be exported */
export type WalletKeyExportPreference = {
  /** deliveryChannel represents the export channel */
  deliveryChannel: WalletKeyExportChannel;
};

/** WalletKeyExportResult represents the result of the export */
export type WalletKeyExportResult = {
  __typename: 'WalletKeyExportResult';
  /** preference represents the export preference under this result */
  preference: WalletKeyExportPreference;
  /** status represents the export status under this preference */
  success: Scalars['Boolean']['output'];
};

/** WalletKeyExportUnknown records information related to unknown export channel */
export type WalletKeyExportUnknown = WalletKeyExportPreference & {
  __typename: 'WalletKeyExportUnknown';
  /** WalletKeyExportChannel.UNKNOWN */
  deliveryChannel: WalletKeyExportChannel;
};

/** Payload response for wallet mutations. */
export type WalletResponsePayload = {
  __typename: 'WalletResponsePayload';
  /** State of the wallet after the mutation */
  wallet: Wallet;
};

/**
 * WalletSettlementInstruction holds information about the default wallet settlement
 * instruction per asset and organization. In a first instance this will only be used
 * to populate the settlement instructions for Anchorage Client Settlement FBO Org
 */
export type WalletSettlementInstruction = {
  __typename: 'WalletSettlementInstruction';
  /** Information about the asset for which this instruction is set for */
  asset: AssetTypeInfo;
  /** Displays when the intruction was inserted into the database */
  createdAt: Scalars['Timestamp']['output'];
  /** Displays who inserted the instruction into the database */
  createdBy: Scalars['String']['output'];
  /** Id of the settlement instructions, should be used when deleting, its unique */
  id: Scalars['String']['output'];
  /** Displays when the instruction was last updated */
  updatedAt: Scalars['Timestamp']['output'];
  /** Displays who updated the instruction last */
  updatedBy: Scalars['String']['output'];
  /** Information about the wallet that is set as source and destination instruction */
  wallet: Wallet;
};

/** WalletSortField provides options for sorting wallets */
export enum WalletSortField {
  /**
   * BALANCE_IN_USD is the balance of the wallet in USD.
   * NOTE: this is not yet implemented in backend.
   */
  BALANCE_IN_USD = 'BALANCE_IN_USD',
  /** CREATED_AT sorts wallets by (in this order): created at timestamp, network ID, asset type ID, wallet name, asset sub ID. */
  CREATED_AT = 'CREATED_AT',
  /** NAME sorts wallets by (in this order): wallet name, network ID, asset type ID, asset sub ID. */
  NAME = 'NAME',
  /**
   * QUANTITY sorts wallet by (in this order): token quantity, network ID, asset type ID, wallet name, asset sub ID.
   * If a wallet contains multiple asset types, only asset with max quantity in the wallet is used for sorting.
   */
  QUANTITY = 'QUANTITY'
}

/**
 * WalletSpendableIDInfo returns the available balance for a token
 * * availableBalance means exactly same thing as available balance in ADB - what's currently available for this wallet and token
 * * name, abbreviation and decimal are only relevant to the spendableID
 */
export type WalletSpendableIDInfo = {
  __typename: 'WalletSpendableIDInfo';
  /** abbreviation aka symbol for the spendableID */
  abbreviation: Scalars['String']['output'];
  /** availableBalance is the amount of the token that's withdrawable */
  availableBalance: Scalars['String']['output'];
  /** decimal is the max decimals defined for the token */
  decimal: Scalars['Int']['output'];
  /** name of the spendableID */
  name: Scalars['String']['output'];
};

export type WebhookEndpoint = Node & {
  __typename: 'WebhookEndpoint';
  createdAt: Scalars['Time']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  notifications: WebhookNotificationConnection;
  operator: Scalars['String']['output'];
  orgID: Scalars['String']['output'];
  subscriptions: WebhookSubscriptionConnection;
  updatedAt: Maybe<Scalars['Time']['output']>;
  url: Scalars['String']['output'];
};


export type WebhookEndpointnotificationsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WebhookNotificationWhereInput>;
};


export type WebhookEndpointsubscriptionsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WebhookSubscriptionWhereInput>;
};

/** A connection to a list of items. */
export type WebhookEndpointConnection = {
  __typename: 'WebhookEndpointConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<WebhookEndpointEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type WebhookEndpointEdge = {
  __typename: 'WebhookEndpointEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<WebhookEndpoint>;
};

/**
 * WebhookEndpointWhereInput is used for filtering WebhookEndpoint objects.
 * Input was generated by ent.
 */
export type WebhookEndpointWhereInput = {
  and?: InputMaybe<Array<WebhookEndpointWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** notifications edge predicates */
  hasNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  hasNotificationsWith?: InputMaybe<Array<WebhookNotificationWhereInput>>;
  /** subscriptions edge predicates */
  hasSubscriptions?: InputMaybe<Scalars['Boolean']['input']>;
  hasSubscriptionsWith?: InputMaybe<Array<WebhookSubscriptionWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<WebhookEndpointWhereInput>;
  /** operator field predicates */
  operator?: InputMaybe<Scalars['String']['input']>;
  operatorContains?: InputMaybe<Scalars['String']['input']>;
  operatorContainsFold?: InputMaybe<Scalars['String']['input']>;
  operatorEqualFold?: InputMaybe<Scalars['String']['input']>;
  operatorGT?: InputMaybe<Scalars['String']['input']>;
  operatorGTE?: InputMaybe<Scalars['String']['input']>;
  operatorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  operatorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  operatorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  operatorLT?: InputMaybe<Scalars['String']['input']>;
  operatorLTE?: InputMaybe<Scalars['String']['input']>;
  operatorNEQ?: InputMaybe<Scalars['String']['input']>;
  operatorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<WebhookEndpointWhereInput>>;
  /** org_id field predicates */
  orgID?: InputMaybe<Scalars['String']['input']>;
  orgIDContains?: InputMaybe<Scalars['String']['input']>;
  orgIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  orgIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  orgIDGT?: InputMaybe<Scalars['String']['input']>;
  orgIDGTE?: InputMaybe<Scalars['String']['input']>;
  orgIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  orgIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  orgIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  orgIDLT?: InputMaybe<Scalars['String']['input']>;
  orgIDLTE?: InputMaybe<Scalars['String']['input']>;
  orgIDNEQ?: InputMaybe<Scalars['String']['input']>;
  orgIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** url field predicates */
  url?: InputMaybe<Scalars['String']['input']>;
  urlContains?: InputMaybe<Scalars['String']['input']>;
  urlContainsFold?: InputMaybe<Scalars['String']['input']>;
  urlEqualFold?: InputMaybe<Scalars['String']['input']>;
  urlGT?: InputMaybe<Scalars['String']['input']>;
  urlGTE?: InputMaybe<Scalars['String']['input']>;
  urlHasPrefix?: InputMaybe<Scalars['String']['input']>;
  urlHasSuffix?: InputMaybe<Scalars['String']['input']>;
  urlIn?: InputMaybe<Array<Scalars['String']['input']>>;
  urlLT?: InputMaybe<Scalars['String']['input']>;
  urlLTE?: InputMaybe<Scalars['String']['input']>;
  urlNEQ?: InputMaybe<Scalars['String']['input']>;
  urlNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type WebhookEvent = Node & {
  __typename: 'WebhookEvent';
  createdAt: Scalars['Time']['output'];
  eventType: Maybe<WebhookEventType>;
  id: Scalars['ID']['output'];
  idempotentID: Scalars['String']['output'];
  notifications: WebhookNotificationConnection;
  payload: Scalars['String']['output'];
};


export type WebhookEventnotificationsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WebhookNotificationWhereInput>;
};

/** A connection to a list of items. */
export type WebhookEventConnection = {
  __typename: 'WebhookEventConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<WebhookEventEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type WebhookEventEdge = {
  __typename: 'WebhookEventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<WebhookEvent>;
};

export type WebhookEventType = Node & {
  __typename: 'WebhookEventType';
  createdAt: Scalars['Time']['output'];
  description: Maybe<Scalars['String']['output']>;
  events: WebhookEventConnection;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  operator: Scalars['String']['output'];
  orgID: Scalars['String']['output'];
  subscriptions: WebhookSubscriptionConnection;
  topic: Scalars['String']['output'];
};


export type WebhookEventTypeeventsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WebhookEventWhereInput>;
};


export type WebhookEventTypesubscriptionsArgs = {
  after?: InputMaybe<Scalars['CursorType']['input']>;
  before?: InputMaybe<Scalars['CursorType']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WebhookSubscriptionWhereInput>;
};

/** A connection to a list of items. */
export type WebhookEventTypeConnection = {
  __typename: 'WebhookEventTypeConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<WebhookEventTypeEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type WebhookEventTypeEdge = {
  __typename: 'WebhookEventTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<WebhookEventType>;
};

/**
 * WebhookEventTypeWhereInput is used for filtering WebhookEventType objects.
 * Input was generated by ent.
 */
export type WebhookEventTypeWhereInput = {
  and?: InputMaybe<Array<WebhookEventTypeWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** events edge predicates */
  hasEvents?: InputMaybe<Scalars['Boolean']['input']>;
  hasEventsWith?: InputMaybe<Array<WebhookEventWhereInput>>;
  /** subscriptions edge predicates */
  hasSubscriptions?: InputMaybe<Scalars['Boolean']['input']>;
  hasSubscriptionsWith?: InputMaybe<Array<WebhookSubscriptionWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<WebhookEventTypeWhereInput>;
  /** operator field predicates */
  operator?: InputMaybe<Scalars['String']['input']>;
  operatorContains?: InputMaybe<Scalars['String']['input']>;
  operatorContainsFold?: InputMaybe<Scalars['String']['input']>;
  operatorEqualFold?: InputMaybe<Scalars['String']['input']>;
  operatorGT?: InputMaybe<Scalars['String']['input']>;
  operatorGTE?: InputMaybe<Scalars['String']['input']>;
  operatorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  operatorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  operatorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  operatorLT?: InputMaybe<Scalars['String']['input']>;
  operatorLTE?: InputMaybe<Scalars['String']['input']>;
  operatorNEQ?: InputMaybe<Scalars['String']['input']>;
  operatorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<WebhookEventTypeWhereInput>>;
  /** org_id field predicates */
  orgID?: InputMaybe<Scalars['String']['input']>;
  orgIDContains?: InputMaybe<Scalars['String']['input']>;
  orgIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  orgIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  orgIDGT?: InputMaybe<Scalars['String']['input']>;
  orgIDGTE?: InputMaybe<Scalars['String']['input']>;
  orgIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  orgIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  orgIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  orgIDLT?: InputMaybe<Scalars['String']['input']>;
  orgIDLTE?: InputMaybe<Scalars['String']['input']>;
  orgIDNEQ?: InputMaybe<Scalars['String']['input']>;
  orgIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** topic field predicates */
  topic?: InputMaybe<Scalars['String']['input']>;
  topicContains?: InputMaybe<Scalars['String']['input']>;
  topicContainsFold?: InputMaybe<Scalars['String']['input']>;
  topicEqualFold?: InputMaybe<Scalars['String']['input']>;
  topicGT?: InputMaybe<Scalars['String']['input']>;
  topicGTE?: InputMaybe<Scalars['String']['input']>;
  topicHasPrefix?: InputMaybe<Scalars['String']['input']>;
  topicHasSuffix?: InputMaybe<Scalars['String']['input']>;
  topicIn?: InputMaybe<Array<Scalars['String']['input']>>;
  topicLT?: InputMaybe<Scalars['String']['input']>;
  topicLTE?: InputMaybe<Scalars['String']['input']>;
  topicNEQ?: InputMaybe<Scalars['String']['input']>;
  topicNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

/**
 * WebhookEventWhereInput is used for filtering WebhookEvent objects.
 * Input was generated by ent.
 */
export type WebhookEventWhereInput = {
  and?: InputMaybe<Array<WebhookEventWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** event_type edge predicates */
  hasEventTypes?: InputMaybe<Scalars['Boolean']['input']>;
  hasEventTypesWith?: InputMaybe<Array<WebhookEventTypeWhereInput>>;
  /** notifications edge predicates */
  hasNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  hasNotificationsWith?: InputMaybe<Array<WebhookNotificationWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** idempotent_id field predicates */
  idempotentID?: InputMaybe<Scalars['String']['input']>;
  idempotentIDContains?: InputMaybe<Scalars['String']['input']>;
  idempotentIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  idempotentIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  idempotentIDGT?: InputMaybe<Scalars['String']['input']>;
  idempotentIDGTE?: InputMaybe<Scalars['String']['input']>;
  idempotentIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  idempotentIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  idempotentIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  idempotentIDLT?: InputMaybe<Scalars['String']['input']>;
  idempotentIDLTE?: InputMaybe<Scalars['String']['input']>;
  idempotentIDNEQ?: InputMaybe<Scalars['String']['input']>;
  idempotentIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<WebhookEventWhereInput>;
  or?: InputMaybe<Array<WebhookEventWhereInput>>;
  /** payload field predicates */
  payload?: InputMaybe<Scalars['String']['input']>;
  payloadContains?: InputMaybe<Scalars['String']['input']>;
  payloadContainsFold?: InputMaybe<Scalars['String']['input']>;
  payloadEqualFold?: InputMaybe<Scalars['String']['input']>;
  payloadGT?: InputMaybe<Scalars['String']['input']>;
  payloadGTE?: InputMaybe<Scalars['String']['input']>;
  payloadHasPrefix?: InputMaybe<Scalars['String']['input']>;
  payloadHasSuffix?: InputMaybe<Scalars['String']['input']>;
  payloadIn?: InputMaybe<Array<Scalars['String']['input']>>;
  payloadLT?: InputMaybe<Scalars['String']['input']>;
  payloadLTE?: InputMaybe<Scalars['String']['input']>;
  payloadNEQ?: InputMaybe<Scalars['String']['input']>;
  payloadNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type WebhookNotification = Node & {
  __typename: 'WebhookNotification';
  attemptCount: Scalars['Int']['output'];
  batchID: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  endpoint: Maybe<WebhookEndpoint>;
  event: Maybe<WebhookEvent>;
  id: Scalars['ID']['output'];
  sendAfter: Scalars['Time']['output'];
  sendingStatus: WebhookNotificationNotificationStatus;
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type WebhookNotificationConnection = {
  __typename: 'WebhookNotificationConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<WebhookNotificationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type WebhookNotificationEdge = {
  __typename: 'WebhookNotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<WebhookNotification>;
};

/** WebhookNotificationNotificationStatus is enum for the field sending_status */
export enum WebhookNotificationNotificationStatus {
  CANCELED = 'CANCELED',
  QUEUED = 'QUEUED',
  SENDING = 'SENDING',
  SENT = 'SENT',
  UNSPECIFIED = 'UNSPECIFIED'
}

/**
 * WebhookNotificationWhereInput is used for filtering WebhookNotification objects.
 * Input was generated by ent.
 */
export type WebhookNotificationWhereInput = {
  and?: InputMaybe<Array<WebhookNotificationWhereInput>>;
  /** attempt_count field predicates */
  attemptCount?: InputMaybe<Scalars['Int']['input']>;
  attemptCountGT?: InputMaybe<Scalars['Int']['input']>;
  attemptCountGTE?: InputMaybe<Scalars['Int']['input']>;
  attemptCountIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  attemptCountLT?: InputMaybe<Scalars['Int']['input']>;
  attemptCountLTE?: InputMaybe<Scalars['Int']['input']>;
  attemptCountNEQ?: InputMaybe<Scalars['Int']['input']>;
  attemptCountNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** batch_id field predicates */
  batchID?: InputMaybe<Scalars['String']['input']>;
  batchIDContains?: InputMaybe<Scalars['String']['input']>;
  batchIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  batchIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  batchIDGT?: InputMaybe<Scalars['String']['input']>;
  batchIDGTE?: InputMaybe<Scalars['String']['input']>;
  batchIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  batchIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  batchIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  batchIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  batchIDLT?: InputMaybe<Scalars['String']['input']>;
  batchIDLTE?: InputMaybe<Scalars['String']['input']>;
  batchIDNEQ?: InputMaybe<Scalars['String']['input']>;
  batchIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  batchIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** endpoint edge predicates */
  hasEndpoints?: InputMaybe<Scalars['Boolean']['input']>;
  hasEndpointsWith?: InputMaybe<Array<WebhookEndpointWhereInput>>;
  /** event edge predicates */
  hasEvents?: InputMaybe<Scalars['Boolean']['input']>;
  hasEventsWith?: InputMaybe<Array<WebhookEventWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<WebhookNotificationWhereInput>;
  or?: InputMaybe<Array<WebhookNotificationWhereInput>>;
  /** send_after field predicates */
  sendAfter?: InputMaybe<Scalars['Time']['input']>;
  sendAfterGT?: InputMaybe<Scalars['Time']['input']>;
  sendAfterGTE?: InputMaybe<Scalars['Time']['input']>;
  sendAfterIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  sendAfterLT?: InputMaybe<Scalars['Time']['input']>;
  sendAfterLTE?: InputMaybe<Scalars['Time']['input']>;
  sendAfterNEQ?: InputMaybe<Scalars['Time']['input']>;
  sendAfterNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** sending_status field predicates */
  sendingStatus?: InputMaybe<WebhookNotificationNotificationStatus>;
  sendingStatusIn?: InputMaybe<Array<WebhookNotificationNotificationStatus>>;
  sendingStatusNEQ?: InputMaybe<WebhookNotificationNotificationStatus>;
  sendingStatusNotIn?: InputMaybe<Array<WebhookNotificationNotificationStatus>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type WebhookSubscription = Node & {
  __typename: 'WebhookSubscription';
  createdAt: Scalars['Time']['output'];
  endpoint: Maybe<WebhookEndpoint>;
  eventType: Maybe<WebhookEventType>;
  id: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type WebhookSubscriptionConnection = {
  __typename: 'WebhookSubscriptionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<WebhookSubscriptionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type WebhookSubscriptionEdge = {
  __typename: 'WebhookSubscriptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['CursorType']['output'];
  /** The item at the end of the edge. */
  node: Maybe<WebhookSubscription>;
};

/**
 * WebhookSubscriptionWhereInput is used for filtering WebhookSubscription objects.
 * Input was generated by ent.
 */
export type WebhookSubscriptionWhereInput = {
  and?: InputMaybe<Array<WebhookSubscriptionWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** endpoint edge predicates */
  hasEndpoints?: InputMaybe<Scalars['Boolean']['input']>;
  hasEndpointsWith?: InputMaybe<Array<WebhookEndpointWhereInput>>;
  /** event_type edge predicates */
  hasEventTypes?: InputMaybe<Scalars['Boolean']['input']>;
  hasEventTypesWith?: InputMaybe<Array<WebhookEventTypeWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<WebhookSubscriptionWhereInput>;
  or?: InputMaybe<Array<WebhookSubscriptionWhereInput>>;
};

export type WebhookValidationKey = {
  __typename: 'WebhookValidationKey';
  long: Scalars['String']['output'];
  short: Scalars['String']['output'];
};

/** WidgetInteraction indicates the action a user took on a widget */
export enum WidgetInteraction {
  /** A confirmed action has the CTA button clicked on. */
  CONFIRMED = 'CONFIRMED',
  /** A dismissed action was suppressed */
  DISMISSED = 'DISMISSED'
}

/** WidgetType indicates how the front-end should render this widget (e.g. banner, modal) */
export enum WidgetType {
  /** A horizontal banner. Banners cannot be suppressed. */
  HORIZONTAL_BANNER = 'HORIZONTAL_BANNER',
  /** A modal widget takes the full screen and can turn into a carousel */
  MODAL = 'MODAL'
}

/** WireData contains information about a wire transfer and if any standing instructions are associated with it. */
export type WireData = {
  __typename: 'WireData';
  /** Account number of the wire transfer */
  accountNumber: Scalars['String']['output'];
  /** Bank name of the wire transfer */
  bankName: Scalars['String']['output'];
  /** Wire Transfer ID */
  fedID: Scalars['String']['output'];
  /** Name of the originator name */
  name: Scalars['String']['output'];
};

/** WithdrawOperation represents a withdraw operation */
export type WithdrawOperation = Operation & {
  __typename: 'WithdrawOperation';
  /** account that this operation is associated with. Only applicable to vault operations (ex. Withdraw) */
  account: Maybe<Account>;
  /** action is the action that the operation performs, which is generally the same as the operation type */
  action: OperationAction;
  /** amlRecord returns the associated AML record */
  amlRecord: Maybe<AMLRecord>;
  /** amount of the asset being withdrawn */
  amount: Amount;
  /** same as amount but using historical pricing based on the end time of the operation */
  amountAtEndTime: Amount;
  /**
   * amountWithFinalFee is equal to amount + finalFee and is defined only for withdrawals
   * in the completed state where the amount and the fee share the same asset type.
   * Refer to the resolvers `feeSharesAssetTypeWithAmount` and `operationState`.
   */
  amountWithFinalFee: Maybe<Amount>;
  /** same as amountWithFinalFee but using historical pricing based on the end time of the operation */
  amountWithFinalFeeAtEndTime: Maybe<Amount>;
  /** appStatus is the app-facing state of the operation, a simplified version of operationState */
  appStatus: AppStatus;
  /** The asset associated with this operation */
  asset: Asset;
  /** asset id from with the withdrawal was made */
  assetID: AssetID;
  /** Further information regarding this asset type associated with this operation */
  assetTypeInfo: AssetTypeInfo;
  /**
   * Actions available on this operation
   * @deprecated use operationInfo
   */
  availableOperationDecisions: Array<OperationDecision>;
  /**
   * Returns the bio challenge required to pass in order to endorse this operation
   * @deprecated use operationInfo
   */
  bioChallenge: BioChallenge;
  /** canonicalOperationVersion is the version of the canonical withdrawal that must be used to sign this withdrawal */
  canonicalOperationVersion: Scalars['Int']['output'];
  /** clientID returns the id that the client specified, if any, when creating this operation */
  clientID: Maybe<Scalars['String']['output']>;
  /** clientOperationComments is a list of client comments for this operation */
  clientOperationComments: Array<OperationComment>;
  /** confirmations returns how many confirmations the transaction associated with this withdrawal has received */
  confirmations: Maybe<Scalars['Int']['output']>;
  /** createdAt is the time when the operation was initiated */
  createdAt: Scalars['String']['output'];
  /** description is the human readable description the initiator wrote at the time when they created the operation */
  description: Scalars['String']['output'];
  /** descriptionEditable indicates whether the description can be edited */
  descriptionEditable: Scalars['Boolean']['output'];
  /** endTime, if non-null is when the operation was completed */
  endTime: Maybe<Scalars['String']['output']>;
  /** endorsements is the list of endorsements for the operation */
  endorsements: Array<SimplifiedEndorsement>;
  /** enforcedPolicy contains all the fragment bindings enforced to this operation */
  enforcedPolicy: Array<PolicyFragmentBinding>;
  /**
   * estimatedTotalAmount is equal to amount + maxFee and is defined only for
   * withdrawals where the amount and the fee share the same asset type.
   */
  estimatedTotalAmount: Maybe<Amount>;
  /** expiration is some measure of when the operation can't be completed any more (TBD) */
  expiration: Scalars['String']['output'];
  /**
   * extraParams are additional asset type specific parameters that were set for this transaction
   * When constructing the canonical operation for signing, the extra params go in the ExtraParams field
   * as a map from asset type id (usually, parent asset type id) to params object. The convention of extra
   * params is to omit all empty fields, and if no extra params are set for an asset type, to omit the asset
   * type from the map entirely. Note that for legacy reasons this convention
   * is the opposite of the normal CanonicalOperation convention, where no fields are ever omitted.
   */
  extraParams: Maybe<ExtraParams>;
  /**
   * feeSharesAssetTypeWithAmount is true if the fee and the amount withdrawn share
   * the same asset type. Returns false otherwise, or if the fee is not known.
   */
  feeSharesAssetTypeWithAmount: Scalars['Boolean']['output'];
  /** final transaction fee charged on top of amount withdrawn, detected on the blockchain */
  finalFee: Maybe<Amount>;
  /** same as finalFee but using historical pricing based on the end time of the operation */
  finalFeeAtEndTime: Maybe<Amount>;
  /**
   * generation is the organization generation this operation was created against
   * @deprecated use operationInfo
   */
  generation: Scalars['Int']['output'];
  /** hasDistress indicates if at least one endorsement has a distress signal */
  hasDistress: Maybe<Scalars['Boolean']['output']>;
  /** max transaction fee charged on top of amount withdrawn */
  maxFee: Maybe<Amount>;
  /** same as maxFee but using historical pricing based on the end time of the operation */
  maxFeeAtEndTime: Maybe<Amount>;
  /** operationID is the id of the operation */
  operationID: Scalars['String']['output'];
  /** operationInfo is a replacement for the Operation interface in order to deduplicate implementations and re-definitions of the same resolvers for each operation type */
  operationInfo: CommonOperationInfo;
  /** operationState is the current state of the operation as it moves through our system */
  operationState: OperationState;
  /**
   * Organization that this operation belongs to
   * @deprecated use operationInfo
   */
  organization: Organization;
  /**
   * policy that applies to this operation
   * @deprecated Legacy PolicyMaps. Use 'readPolicy'
   */
  policy: Policy;
  /**
   * rawTransaction is the blockchain transaction that must be verified and signed by users
   * @deprecated use rawTransactions for withdrawal v1 signatures
   */
  rawTransaction: Maybe<Scalars['String']['output']>;
  /** rawTransactions are the blockchain transactions that must be signed by users */
  rawTransactions: Array<Scalars['String']['output']>;
  /**
   * readPolicy is the non-signable representation of the policy that applies to this operation
   * @deprecated use operationInfo
   */
  readPolicy: ReadPolicy;
  /**
   * receivingAddress is the address on which a deposit is to be received
   * @deprecated use receivingBlockchainAddress
   */
  receivingAddress: Scalars['String']['output'];
  /** receivingAddressInfo contains any additional info we know about this address (whitelisted, name, etc) */
  receivingAddressInfo: Maybe<ReceivingAddressInfo>;
  /**
   * receivingBlockchainAddress is the address on which a deposit is to be received
   * @deprecated use receivingBlockchainAddress2
   */
  receivingBlockchainAddress: BlockchainAddress;
  /** same as receivingBlockchainAddress but with additional info if using a settlement network participant */
  receivingBlockchainAddress2: BlockchainAddressWithParticipant;
  /** ReceivingStandingInstruction is the standing instruction and fedID */
  receivingStandingInstruction: Maybe<ReceivingStandingInstruction>;
  /** receivingWallet is the wallet that the withdrawal is going to (only present for internal withdrawal) */
  receivingWallet: Maybe<Wallet>;
  /**
   * replayProtectionParams are the parameters to be sent to the HSM for creating and signing
   * the transactions required to effect this WithdrawOperation. The user *is not* expected
   * to review the contents of this field. However it may contain information necessary to prevent
   * replays and must be included in the payload signed by the user. Each element is a hex-encoded
   * byte array.
   */
  replayProtectionParams: Maybe<Scalars['String']['output']>;
  /** Indicates if the current user is required to provide a decision for this operation. */
  requiresDecision: Scalars['Boolean']['output'];
  /** riskAnalysis is the collected risk information for this withdrawal if we have any */
  riskAnalysis: Maybe<TransactionRiskAnalysis>;
  /** sendingBlockchainAddresses are the originating addresses for the withdraw. Fiat assets will have an empty array */
  sendingBlockchainAddresses: Array<BlockchainAddress>;
  /** sendingWallet is the wallet that the withdrawal is coming from */
  sendingWallet: Wallet;
  /** signedTransaction is the blockchain transaction to be broadcast to the network */
  signedTransaction: Maybe<Scalars['String']['output']>;
  /**
   * simpleOperationStateDiagram removes `anchorage review` items if the operation was rejected, and collapses consecutive states
   * with the same state description
   */
  simpleOperationStateDiagram: Array<SimpleStateDiagramItem>;
  /**
   * SLALeft returns how much of the SLA for the operation is left with nanosecond precision
   * The SLA is based on when the operation was created and the clock starts when it enters risk recon for the first time
   * If it hasn't entered risk pending yet, we return nil
   * If it has already expired, we return a negative number
   */
  slaLeft: Maybe<Scalars['String']['output']>;
  /**
   * sources are the originating addresses for the withdraw
   * @deprecated use sendingBlockchainAddresses
   */
  sources: Array<Source>;
  /** transactionID is the blockchain transaction id once available */
  transactionID: Maybe<Scalars['String']['output']>;
  /** transactionSuccess indicates if the blockchain transaction succeeded */
  transactionSuccess: Maybe<Scalars['Boolean']['output']>;
  /**
   * usdValueWithFee is the sum in USD values of both the principal withdrawal amount and the
   * fee (finalFee if known, otherwise maxFee). If both the finalFee and maxFee are
   * not yet known, this field will be null
   */
  usdValueWithFee: Maybe<Scalars['String']['output']>;
  /** useGasStation if true, the Anchorage gas station was used to pay withdraw fees. */
  useGasStation: Scalars['Boolean']['output'];
  /** viewTransactionLink is the link to get more info about the transaction from a third party */
  viewTransactionLink: Maybe<Scalars['String']['output']>;
};


/** WithdrawOperation represents a withdraw operation */
export type WithdrawOperationbioChallengeArgs = {
  decision: OperationDecision;
  endorsementBatchID?: InputMaybe<Scalars['String']['input']>;
};


/** WithdrawOperation represents a withdraw operation */
export type WithdrawOperationendorsementsArgs = {
  decision?: InputMaybe<OperationDecision>;
};

export const TestFragmentFragmentDoc = gql`
    fragment TestFragment on User {
  name
}
    `;
export const GetUncompletedSettlementsDocument = gql`
    query GetUncompletedSettlements {
  snSettlements(filterState: NOT_COMPLETED) {
    pageInfo {
      totalResults
    }
  }
}
    `;
export function useGetUncompletedSettlementsQuery(baseOptions?: Apollo.QueryHookOptions<GetUncompletedSettlementsQuery, GetUncompletedSettlementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUncompletedSettlementsQuery, GetUncompletedSettlementsQueryVariables>(GetUncompletedSettlementsDocument, options);
      }
export function useGetUncompletedSettlementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUncompletedSettlementsQuery, GetUncompletedSettlementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUncompletedSettlementsQuery, GetUncompletedSettlementsQueryVariables>(GetUncompletedSettlementsDocument, options);
        }
export function useGetUncompletedSettlementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUncompletedSettlementsQuery, GetUncompletedSettlementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUncompletedSettlementsQuery, GetUncompletedSettlementsQueryVariables>(GetUncompletedSettlementsDocument, options);
        }
export type GetUncompletedSettlementsQueryHookResult = ReturnType<typeof useGetUncompletedSettlementsQuery>;
export type GetUncompletedSettlementsLazyQueryHookResult = ReturnType<typeof useGetUncompletedSettlementsLazyQuery>;
export type GetUncompletedSettlementsSuspenseQueryHookResult = ReturnType<typeof useGetUncompletedSettlementsSuspenseQuery>;
export type GetUncompletedSettlementsQueryResult = Apollo.QueryResult<GetUncompletedSettlementsQuery, GetUncompletedSettlementsQueryVariables>;
export const ProductUpdatesFeedDocument = gql`
    query ProductUpdatesFeed {
  productUpdates {
    unreadCount
    feedURL
  }
}
    `;
export function useProductUpdatesFeedQuery(baseOptions?: Apollo.QueryHookOptions<ProductUpdatesFeedQuery, ProductUpdatesFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductUpdatesFeedQuery, ProductUpdatesFeedQueryVariables>(ProductUpdatesFeedDocument, options);
      }
export function useProductUpdatesFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductUpdatesFeedQuery, ProductUpdatesFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductUpdatesFeedQuery, ProductUpdatesFeedQueryVariables>(ProductUpdatesFeedDocument, options);
        }
export function useProductUpdatesFeedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductUpdatesFeedQuery, ProductUpdatesFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductUpdatesFeedQuery, ProductUpdatesFeedQueryVariables>(ProductUpdatesFeedDocument, options);
        }
export type ProductUpdatesFeedQueryHookResult = ReturnType<typeof useProductUpdatesFeedQuery>;
export type ProductUpdatesFeedLazyQueryHookResult = ReturnType<typeof useProductUpdatesFeedLazyQuery>;
export type ProductUpdatesFeedSuspenseQueryHookResult = ReturnType<typeof useProductUpdatesFeedSuspenseQuery>;
export type ProductUpdatesFeedQueryResult = Apollo.QueryResult<ProductUpdatesFeedQuery, ProductUpdatesFeedQueryVariables>;
export const AppUserDocument = gql`
    query AppUser {
  organization {
    name
    tradingEnabled
    organizationKeyID
    operator
    policyInfo {
      sourceOfTruth
    }
    iam {
      permissions {
        edges {
          node {
            action
          }
        }
      }
    }
    isUsingETF
    isUsingSMA
  }
  currentUser {
    userID
    secureName
    email
    userType
    publicKeyID
    isTrader
    addedTime
  }
  enabledFeatures {
    name
  }
}
    `;
export function useAppUserQuery(baseOptions?: Apollo.QueryHookOptions<AppUserQuery, AppUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppUserQuery, AppUserQueryVariables>(AppUserDocument, options);
      }
export function useAppUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppUserQuery, AppUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppUserQuery, AppUserQueryVariables>(AppUserDocument, options);
        }
export function useAppUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AppUserQuery, AppUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppUserQuery, AppUserQueryVariables>(AppUserDocument, options);
        }
export type AppUserQueryHookResult = ReturnType<typeof useAppUserQuery>;
export type AppUserLazyQueryHookResult = ReturnType<typeof useAppUserLazyQuery>;
export type AppUserSuspenseQueryHookResult = ReturnType<typeof useAppUserSuspenseQuery>;
export type AppUserQueryResult = Apollo.QueryResult<AppUserQuery, AppUserQueryVariables>;
export type GetUncompletedSettlementsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUncompletedSettlementsQuery = { snSettlements: { __typename: 'SNTwoWaySettlementConnection', pageInfo: { __typename: 'PageInfo', totalResults: number | null } } };

export type ProductUpdatesFeedQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductUpdatesFeedQuery = { productUpdates: { __typename: 'ProductUpdates', unreadCount: number | null, feedURL: string } };

export type AppUserQueryVariables = Exact<{ [key: string]: never; }>;


export type AppUserQuery = { organization: { __typename: 'Organization', name: string, tradingEnabled: boolean, organizationKeyID: string, operator: string, isUsingETF: boolean, isUsingSMA: boolean, policyInfo: { __typename: 'PolicyInfo', sourceOfTruth: PolicySourceOfTruth | null } | null, iam: { __typename: 'IAM', permissions: { __typename: 'IAMPermissionConnection', edges: Array<{ __typename: 'IAMPermissionEdge', node: { __typename: 'IAMPermission', action: IAMPermissionAction } | null } | null> | null } } }, currentUser: { __typename: 'User', userID: string, secureName: string, email: string, userType: UserType, publicKeyID: string, isTrader: boolean, addedTime: string | null }, enabledFeatures: Array<{ __typename: 'Feature', name: FeatureName }> };

export type TestFragmentFragment = { __typename: 'User', name: string };

export namespace GetUncompletedSettlements {
  export type Variables = GetUncompletedSettlementsQueryVariables;
  export type query = GetUncompletedSettlementsQuery;
  export type snSettlements = (NonNullable<GetUncompletedSettlementsQuery['snSettlements']>);
  export type pageInfo = (NonNullable<(NonNullable<GetUncompletedSettlementsQuery['snSettlements']>)['pageInfo']>);
  export const Document = GetUncompletedSettlementsDocument;
}

export namespace ProductUpdatesFeed {
  export type Variables = ProductUpdatesFeedQueryVariables;
  export type query = ProductUpdatesFeedQuery;
  export type productUpdates = (NonNullable<ProductUpdatesFeedQuery['productUpdates']>);
  export const Document = ProductUpdatesFeedDocument;
}

export namespace AppUser {
  export type Variables = AppUserQueryVariables;
  export type query = AppUserQuery;
  export type organization = (NonNullable<AppUserQuery['organization']>);
  export type policyInfo = (NonNullable<(NonNullable<AppUserQuery['organization']>)['policyInfo']>);
  export type iam = (NonNullable<(NonNullable<AppUserQuery['organization']>)['iam']>);
  export type permissions = (NonNullable<(NonNullable<(NonNullable<AppUserQuery['organization']>)['iam']>)['permissions']>);
  export type edges = NonNullable<(NonNullable<(NonNullable<(NonNullable<(NonNullable<AppUserQuery['organization']>)['iam']>)['permissions']>)['edges']>)[number]>;
  export type node = (NonNullable<NonNullable<(NonNullable<(NonNullable<(NonNullable<(NonNullable<AppUserQuery['organization']>)['iam']>)['permissions']>)['edges']>)[number]>['node']>);
  export type currentUser = (NonNullable<AppUserQuery['currentUser']>);
  export type enabledFeatures = NonNullable<(NonNullable<AppUserQuery['enabledFeatures']>)[number]>;
  export const Document = AppUserDocument;
}

export namespace TestFragment {
  export type fragment = TestFragmentFragment;
}
