import { ToggleGroup } from '@latitude/toggle-group';
import { Controller, FieldValues } from 'react-hook-form';

import { FormComponentWrapper } from 'components/FormComponentWrapper';

import { FormToggleGroupProps } from './types';

const FormInput = <T extends FieldValues = FieldValues>({
  control,
  name,
  description,
  label,
  message,
  wrapperClassname,
  type,
  items,
  variant,
  selected,
  components,
  ...toggleGroupProps
}: FormToggleGroupProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ fieldState: { error } }) => (
      <FormComponentWrapper
        name={name}
        description={description}
        label={label}
        error={error}
        className={wrapperClassname}
        message={message}
      >
        <ToggleGroup
          type={type}
          items={items}
          variant={variant}
          selected={selected}
          components={components}
          {...toggleGroupProps}
        />
      </FormComponentWrapper>
    )}
  />
);

FormInput.displayName = 'FormInput';

export default FormInput;
