import { reactHookForm, yupResolver as yr } from '@latitude/form';
import { Modal } from '@latitude/modal';
import { Separator } from '@latitude/separator';

import { useAppUser } from '@anchorage/frontoffice/contexts/AppUser';

import { formSchema } from './utils';

import css from './styles.module.scss';

import { A1ReferralFormValues } from './types';

import { InfoSection } from './InfoSection';
import { ReferralForm } from './ReferralForm';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const { FormProvider, useForm } = reactHookForm;
const { yupResolver } = yr;

export const A1ReferralModal = ({ isOpen, onClose }: Props) => {
  const { currentUser, organization } = useAppUser();

  const form = useForm<A1ReferralFormValues>({
    // TODO: jenny - fix this issue. Ignoring for now to move forward
    // @ts-ignore - TS doesn't like the resolver type
    resolver: yupResolver<A1ReferralFormValues>(formSchema),
    defaultValues: {
      name: currentUser?.secureName || '',
      company: organization?.name || '',
      institutionType: '',
      reasonForTrading: '',
      monthlyTradingVolumes: 0,
      tradingFrequency: '',
      preferredTradingMethod: '',
    },
  });

  const submitForm = () => {
    // TODO
  };

  return (
    <Modal
      isOpen={isOpen}
      size="medium"
      onClose={onClose}
      title="Join A1"
      primaryAction={{
        label: 'Submit',
        onClick: form.handleSubmit(submitForm),
      }}
    >
      <div className={css.modalRoot}>
        <InfoSection />
        <Separator />
        <FormProvider {...form}>
          <ReferralForm />
        </FormProvider>
      </div>
    </Modal>
  );
};
