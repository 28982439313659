export const radioButtonItemClasses = [
  'relative',
  'rounded-radius-sys-large-16',
  'rounded-radius-sys-large-16',
  'text-color-comp-radio-unchecked-default',
  'outline-color-comp-radio-unchecked-default',
  //margin between icon and label
  'mr-spacing-comp-checkbox-gap',
  //hover state
  'hover:text-color-comp-radio-unchecked-hover',
  'hover:outline-color-comp-radio-checked-hover',
  //invalid state
  '[&:has(~input:invalid)]:text-color-comp-radio-unchecked-danger',
  '[&:has(~input:invalid)]:outline-color-comp-radio-checked-danger',
  'group-aria-[invalid="true"]:text-color-comp-radio-unchecked-danger',
  'group-aria-[invalid="true"]:outline-color-comp-radio-checked-danger',
  //disabled state
  'disabled:text-color-comp-radio-unchecked-disabled',
  'disabled:outline-color-comp-radio-checked-disabled',
  'disabled:cursor-not-allowed',
  //checked state
  'data-[state=checked]:text-color-comp-radio-checked-default',
  'data-[state=checked]:outline-color-comp-radio-checked-default',
  //checked/disabled state
  'data-[state=checked]:disabled:text-color-comp-radio-checked-disabled',
  //checked/hover state
  'data-[state=checked]:hover:text-color-comp-radio-checked-hover',
  'data-[state=checked]:hover:outline-color-comp-radio-checked-hover',
  //checked/invalid state
  'data-[state=checked]:invalid:text-color-comp-radio-checked-danger',
  'data-[state=checked]:invalid:outline-color-comp-radio-checked-danger',
  'p-sizing-sys-size-4',
  'cursor-pointer',
  // outer ring
  "before:content-['']",
  'before:border-2',
  'before:bg-transparent',
  'before:border-current',
  'before:block',
  'before:w-sizing-sys-size-16',
  'before:h-sizing-sys-size-16',
  'before:rounded-full',
  // outer ring
  'data-[state=checked]:after:block',
  'after:hidden',
  'after:absolute',
  'after:top-0',
  'after:translate-y-1/4',
  'after:w-sizing-sys-size-16',
  'after:h-sizing-sys-size-16',
  'after:rounded-full',
  'after:block',
  'after:scale-[.5]',
  'after:rounded-full',
  'after:bg-current',
  // make it referenceable for the label
  'peer',
];

export const radioButtonLabelClasses = [
  'flex',
  'items-center',
  'align-middle',
  'font-ref-web-body-regular-font-family',
  'font-ref-web-body-regular-font-weight',
  'text-ref-web-body-regular-font-size',
  'leading-sys-line-height-12',
  'cursor-pointer',
  'text-color-comp-radio-label-default',
  // in case the input is a sibling of the label
  'peer-invalid:text-color-comp-radio-label-error',
  'peer-disabled:text-color-comp-radio-label-disabled',
  'peer-disabled:cursor-not-allowed',
  'peer-hover:not(:disabled):text-color-comp-radio-label-hover',
  // in case the input is a child of the label
  '[&:has(>input:invalid)]:text-color-comp-radio-label-error',
  '[&:has(>input:disabled)]:text-color-comp-radio-label-disabled',
  '[&:has(>input:disabled)]:cursor-not-allowed',
  '[&:has(>input:hover:not(:disabled))]:text-color-comp-radio-label-hover',
];
