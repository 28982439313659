import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import RadioGroupItem from 'RadioGroupItem';
import RadioGroupItemLabel from 'RadioGroupItemLabel';
import cn from 'classnames';
import { forwardRef } from 'react';

import { RadioGroupProps } from 'types';

const RadioGroup = forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  RadioGroupProps
>(
  (
    {
      className,
      options = [],
      children,
      rawProps,
      onChange,
      invalid,
      ...props
    },
    forwardedRef,
  ) => (
    <RadioGroupPrimitive.Root
      ref={forwardedRef}
      className={cn('group', className)}
      onValueChange={onChange}
      aria-invalid={invalid}
      {...props}
      {...rawProps}
    >
      {/* We don't want to use both children and options */}
      {children
        ? children
        : options.map((opt, index) => {
            const idToUse = opt.id ?? `${opt.value}-${index}`;
            return (
              <RadioGroupItemLabel
                key={idToUse}
                htmlFor={idToUse}
                {...opt.labelProps}
              >
                <RadioGroupItem
                  value={opt.value}
                  disabled={opt.disabled}
                  id={idToUse}
                  data-testid={idToUse}
                  {...opt.itemProps}
                />
                {opt.label}
              </RadioGroupItemLabel>
            );
          })}
    </RadioGroupPrimitive.Root>
  ),
);

RadioGroup.displayName = 'RadioGroup';

export default RadioGroup;
