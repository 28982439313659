import { Tooltip } from '@latitude/tooltip';

import { internalTranformersButton } from 'utils/transformers';

import { ButtonItem, ToggleGroupItemComponentProps } from 'types';

/**
 * ToggleGroupItemButton component renders a toggle button.
 * @param {ToggleGroupItemComponentProps} props - The props for the component.
 * @returns {JSX.Element} The ToggleGroupItemButton component.
 */
export const ToggleGroupItemButton = ({
  selected,
  item,
  componentTriggerType: _componentTriggerType,
  analytics,
  Component,
  ...rest
}: ToggleGroupItemComponentProps) => {
  const {
    id,
    label,
    transformers: customTransformers = {},
    disabled = false,
    onRemove,
  } = item as ButtonItem;

  const transformers = {
    ...internalTranformersButton,
    ...customTransformers,
  };

  const onClickHandler = () => {
    const { onClick, ...newItem } = item as ButtonItem;

    if (transformers?.value) {
      newItem.value = transformers.value(newItem);
    }
    if (transformers?.formattedValue) {
      newItem.valueFormatted = transformers.formattedValue(newItem);
    }
    analytics('toggle_selected_item', newItem);

    if ('onClick' in item) onClick?.(newItem);
  };

  const itemComponent = (
    <Component
      {...rest}
      disabled={disabled}
      aria-pressed={!!selected}
      data-pressed={!!selected}
      selected={!!selected}
      onClick={onClickHandler}
      onRemove={onRemove}
    >
      {label}
    </Component>
  );

  if (selected && selected.valueFormatted) {
    return (
      <Tooltip key={id} text={(selected.valueFormatted as string) || ''}>
        <span>{itemComponent}</span>
      </Tooltip>
    );
  }
  return <span>{itemComponent}</span>;
};
