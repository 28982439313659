import { Tag as PrimitiveTag } from '@latitude/tag';
import { ComponentProps } from 'react';

/**
 * Renders a tag component.
 *
 * @component
 * @example
 * ```tsx
 * <Tag>
 *   Example Tag
 * </Tag>
 * ```
 */
export const Tag = ({
  children,
  ...props
}: ComponentProps<typeof PrimitiveTag>) => {
  return (
    <PrimitiveTag
      variant="neutral"
      size="tiny"
      fill="filled"
      data-testid="select-chip"
      {...props}
    >
      {children}
    </PrimitiveTag>
  );
};
