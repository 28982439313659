import { Text } from '@latitude/text';
import cn from 'classnames';
import React, { useId } from 'react';

import { FieldDescription } from 'components/FieldDescription';
import { FieldError } from 'components/FieldError';
import { FieldLabel } from 'components/FieldLabel';

import { FormComponentWrapperProps } from './types';

const FormComponentWrapper: React.FC<FormComponentWrapperProps> = ({
  children,
  description,
  error,
  label,
  className = '',
  name,
  message,
  optional,
}) => {
  const id = useId();
  const formDescriptionId = `form-description-${id}`;
  const formMessageId = `form-message-${id}`;
  const formErrorId = `form-message-${id}`;
  const wrapperClassname = cn(['flex', 'flex-col', 'gap-1'], className);

  return (
    <div
      className={wrapperClassname}
      id={`form-item-${id}`}
      aria-describedby={
        !error ? `${formDescriptionId}` : `${formDescriptionId} ${formErrorId}`
      }
      aria-invalid={!!error}
    >
      {label || optional ? (
        <div className="flex items-center">
          {label && (
            <FieldLabel htmlFor={name} error={Boolean(error?.message)}>
              {label}
            </FieldLabel>
          )}
          {optional && (
            <Text
              variant="captionRegular"
              className="text-color-comp-text-field-helper-text-enabled border-red grow text-right"
            >
              Optional
            </Text>
          )}
        </div>
      ) : null}
      {description ? (
        <FieldDescription id={formDescriptionId}>
          {description}
        </FieldDescription>
      ) : null}
      {children}
      {error?.message ? (
        <FieldError id={formErrorId} errorMessage={error.message} />
      ) : null}
      {!error?.message && message ? (
        <FieldDescription id={formMessageId}>{message}</FieldDescription>
      ) : null}
    </div>
  );
};

FormComponentWrapper.displayName = 'FormComponentWrapper';

export default FormComponentWrapper;
