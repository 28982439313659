import {
  DatePickerMode,
  DatePickerValue,
  DatePickerValues,
  Datepicker,
} from '@latitude/datepicker';
import { Tooltip } from '@latitude/tooltip';
import { useState } from 'react';

import { internalTranformersDatepicker } from 'utils/transformers';

import { DateItem, ToggleGroupItemComponentProps } from 'types';

/**
 * ToggleGroupItemButton component renders a toggle button.
 * @param {ToggleGroupItemDatepickerProps} props - The props for the component.
 * @returns {JSX.Element} The ToggleGroupItemButton component.
 */
export const ToggleGroupItemDatepicker = ({
  selected,
  item,
  analytics,
  componentTriggerType: _componentTriggerType,
  Component,
  ...rest
}: ToggleGroupItemComponentProps) => {
  const {
    id,
    label,
    transformers: customTransformers,
    disabled = false,
    mode,
    datepickerProps = {},
    onRemove,
  } = item as DateItem;

  const parsedMode: DatePickerMode = mode || 'single';
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<
    DatePickerValue<typeof parsedMode> | undefined
  >(undefined);

  const onDayPick = (newVal: DatePickerValues<DatePickerMode>) => {
    const transformers = {
      ...internalTranformersDatepicker,
      ...customTransformers,
    };

    const { onClick, ...rest } = item as DateItem;
    const newItem = { ...rest, ...newVal } as DateItem;

    if (transformers?.value) {
      newItem.value = transformers.value(newItem);
    }
    if (transformers?.formattedValue) {
      newItem.valueFormatted = transformers.formattedValue(newItem);
    }
    analytics('toggle_selected_item', newItem);
    if ('onClick' in item) onClick && onClick(newItem);
  };

  const itemComponent = (
    // @ts-expect-error - expected error because we do not specify the mode directly
    <Datepicker
      open={open}
      setOpen={setOpen}
      mode={parsedMode as 'single'}
      value={value as DatePickerValue<'single'>}
      onChange={(newDate: DatePickerValues<'single'>) => {
        setValue(newDate.value);
        onDayPick(newDate);
      }}
      {...datepickerProps}
    >
      <Component
        {...rest}
        aria-pressed={!!selected}
        disabled={disabled}
        selected={!!selected}
        onRemove={onRemove}
      >
        {(selected && selected.valueFormatted) || label}
      </Component>
    </Datepicker>
  );

  if (selected && selected.valueFormatted) {
    return (
      <Tooltip key={id} text={(selected.valueFormatted as string) || ''}>
        <span>{itemComponent}</span>
      </Tooltip>
    );
  }
  return <span>{itemComponent}</span>;
};
