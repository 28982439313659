/* eslint-disable @typescript-eslint/no-unused-vars, react-hooks/exhaustive-deps */
import { Input } from '@latitude/input';
import { Content, Root, Trigger } from '@latitude/popover';
import { useMemo } from 'react';
import {
  DateRange,
  DayPickerContextValue,
  DayPickerProps,
  DayPickerProvider,
} from 'react-day-picker';

import { Calendar } from 'components/Calendar';
import { CalendarProps } from 'components/ComponentsTypes';

import useDatepickerContext from 'hooks/useDatepickerContext';

import { classesFor, sizes } from 'utils/designSystemTokens';

export type DatepickerWrapperProps = {
  /**
   * Whether the DatePicker is required or not.
   */
  required: boolean;
  /**
   * Datepicker trigger component
   */
  children?: React.ReactNode;
};

export const DatepickerWrapper = ({
  required,
  children,
}: DatepickerWrapperProps) => {
  const {
    open,
    mode,
    minDate,
    maxDate,
    format,
    value,
    inputProps,
    onOpenChange,
    onDayClick,
  } = useDatepickerContext();

  const initialProviderProps = {
    styles: {},
    mode,
    required,
    captionLayout: 'dropdown-buttons',
    numberOfMonths: 1,
    format,
    fromDate: minDate,
    toDate: maxDate,
    //*...dayPickerProps,
    //*...props,
    selected: value,
    onDayClick: (day: Date) => {
      onDayClick(day);
    },
  } as Partial<DayPickerContextValue>;

  const defaultMonth = useMemo(() => {
    if (!value) return new Date();
    if (mode === 'range') {
      return (value as DateRange).from as Date;
    }
    return value as Date;
  }, [mode, value]);

  return (
    <DayPickerProvider initialProps={initialProviderProps as DayPickerProps}>
      <Root open={open} onOpenChange={onOpenChange}>
        {/* /*
            If we dont assign the trigger asChild, it will render as a button, and we cannot have a button
          inside of a button but we have fixed buttons inside of the input.
            If we set the asChild, it will render as an Anchor, but with a default type of "button", that
          also causes the input to behave in a weird way, for example, the placeholder stops working
            So we need to set the type to undefined to override that behaviour.
            https://github.com/radix-ui/primitives/issues/1461
          */}
        <Trigger asChild type={undefined}>
          {children || <Input {...inputProps} />}
        </Trigger>

        <Content
          align="start"
          className={`${classesFor(sizes.popover)} ${sizes.popoverHeight[mode]}`}
        >
          <Calendar
            initialFocus={false}
            defaultMonth={defaultMonth}
            {...(initialProviderProps as CalendarProps)}
          />
        </Content>
      </Root>
    </DayPickerProvider>
  );
};
