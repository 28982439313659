import { Datepicker } from '@latitude/datepicker';
import { FieldValues } from 'react-hook-form';

import { InternalFormDatepickerProps } from './types';

const InternalFormDatepicker = <T extends FieldValues = FieldValues>({
  mode,
  onChange,
  onBlur,
  disabled,
  ...props
}: InternalFormDatepickerProps<T>) => {
  return (
    <Datepicker
      mode={mode}
      onSelect={onChange}
      disabled={disabled}
      onBlur={onBlur}
      onClear={onChange}
      {...props}
    />
  );
};

InternalFormDatepicker.displayName = 'InternalFormDatepicker';

export default InternalFormDatepicker;
