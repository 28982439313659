import { cva } from 'class-variance-authority';

export const toggleGroupVariants = cva(
  [
    'inline-flex',
    'flex-row',
    'flex-wrap',
    'gap-spacing-sys-tiny-4',
    'rounded-radius-comp-button-round',
  ],
  {
    variants: {
      variant: {
        default: 'bg-color-ref-transparency-lighten-4 p-spacing-sys-tiny-4',
        transparent: 'bg-color-ref-transparency-lighten-0',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);
