import { format } from 'date-fns';

import { DatePickerMode, DatePickerValue } from '../types';

/**
 * Handles the click event on a day in the calendar.
 * Depending on the calendar mode ('single' | 'range' | 'single-time' | 'time'), updates the selected date(s).
 */
export const selectDayHandler = (
  day: Date,
  mode: DatePickerMode,
  fromValue: Date | undefined,
  handleNewValueChange: (newDate: DatePickerValue<typeof mode>) => void,
  rangeDirection: 'from' | 'to',
  setRangeDirection: (direction: 'from' | 'to') => void,
  handleOpenChange: (open: boolean) => void,
) => {
  // Handle single mode
  if (mode === 'single') {
    handleNewValueChange(day);
    handleOpenChange(false);
    return;
  }

  // Handle single-time mode
  if (mode === 'single-time') {
    const parsedDateWithTimeChanges = new Date(day);
    if (
      fromValue &&
      format(day, 'dd/MM/yyyy') !== format(fromValue, 'dd/MM/yyyy')
    ) {
      // Keep the time if only the date changes
      parsedDateWithTimeChanges.setHours(fromValue.getHours());
      parsedDateWithTimeChanges.setMinutes(fromValue.getMinutes());
      parsedDateWithTimeChanges.setSeconds(fromValue.getSeconds());
    }

    handleNewValueChange(parsedDateWithTimeChanges);
    return;
  }

  // Handle time mode
  if (mode === 'time') {
    handleNewValueChange(day);
    return;
  }

  // Handle range mode
  if (mode === 'range') {
    if (rangeDirection === 'to') {
      // Ensure 'from' date is set
      if (!fromValue) return;

      // Determine earliest and latest dates for the range
      const from = day < fromValue ? day : fromValue;
      const to = day < fromValue ? fromValue : day;

      handleNewValueChange({ from, to });

      // Clear range direction
      setRangeDirection('from');
    } else {
      // Set 'from' date and clear 'to' date
      handleNewValueChange({ from: day, to: undefined });

      // Set range direction to 'to'
      setRangeDirection('to');
    }
  }
};
