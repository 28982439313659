import * as LabelPrimitive from '@radix-ui/react-label';
import cn from 'classnames';
import * as React from 'react';
import { labelVariants } from 'variants/label-variants';

/**
 * Represents a label component used in forms.
 *
 * @component
 * @example
 * ```tsx
 * <Label htmlFor="username">Username</Label>
 * ```
 */
export interface FieldLabelProps
  extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> {
  error: boolean;
}

const FieldLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  FieldLabelProps
>(({ className, error, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn(
      [
        'font-comp-form-label-font-family',
        'font-comp-form-label-font-size',
        'font-comp-form-label-font-weight',
        'leading-comp-form-label-line-height',
        labelVariants({ error }),
      ],
      className,
    )}
    {...props}
  />
));
FieldLabel.displayName = 'FieldLabel';

export default FieldLabel;
