import { format as formatHandler } from 'date-fns';
import { useMemo } from 'react';

import { DatepickerProps } from 'types';

import { DatepickerProviderProps } from './HookTypes';

type UseDatepickerFormattingProps = {
  mode: DatepickerProviderProps['mode'];
  format: Exclude<DatepickerProps['format'], undefined>;
  calendarFromDateSelected?: Date;
  calendarToDateSelected?: Date;
};

export const useDatepickerFormatting = ({
  format,
  mode,
  calendarFromDateSelected: fromDateSelected,
  calendarToDateSelected: toDateSelected,
}: UseDatepickerFormattingProps) => {
  const formattedValue = useMemo(() => {
    if (mode === 'range') {
      if (fromDateSelected && toDateSelected) {
        return `${formatHandler(fromDateSelected, format)} - ${formatHandler(
          toDateSelected,
          format,
        )}`;
      } else if (fromDateSelected) {
        return `${formatHandler(fromDateSelected, format)} - `;
      }
      return '';
    } else if (fromDateSelected) {
      return formatHandler(fromDateSelected, format);
    }
    return '';
  }, [mode, fromDateSelected, toDateSelected, format]);

  return {
    formattedValue,
  };
};
