import { Checkbox } from '@latitude/checkbox';
import { Controller, FieldValues } from 'react-hook-form';

import { FormComponentWrapper } from 'components/FormComponentWrapper';

import { FormCheckboxProps } from './types';

const FormCheckbox = <T extends FieldValues>({
  name,
  control,
  description,
  label,
  checkboxLabel,
  wrapperClassname,
  disabled,
  ...restProps
}: FormCheckboxProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
      <FormComponentWrapper
        name={name}
        description={description}
        label={label}
        error={error}
        className={wrapperClassname}
      >
        <Checkbox
          {...restProps}
          disabled={disabled}
          invalid={!!error}
          label={checkboxLabel}
          onBlur={onBlur}
          onCheckedChange={onChange}
          checked={!!value}
        />
      </FormComponentWrapper>
    )}
  />
);

FormCheckbox.displayName = 'FormCheckbox';

export default FormCheckbox;
