import { CalendarProps } from 'components/ComponentsTypes';

import { DatePickerMode } from 'types';

const maxWidth = 'w-full';
const datepickerContainerWith = 'w-[328px]';

export const sizes = {
  popover: {
    colors: 'bg-color-ref-ui-surface-container text-color-ref-text-muted',
    visuals: 'outline-none',
    animations: `
    transition-height
    duration-200
    ease-in-out
    data-[state=open]:animate-in
    data-[state=closed]:animate-out
    data-[state=open]:fade-in-0
    data-[state=closed]:fade-out-0
    data-[state=open]:zoom-in-95
    data-[state=closed]:zoom-out-95
    data-[side=top]:slide-in-from-bottom-2
    data-[side=right]:slide-in-from-left-2 
    data-[side=bottom]:slide-in-from-top-2
    data-[side=left]:slide-in-from-right-2 
    `,
    padding: '!p-[24px]',
    others: `flex flex-col z-50 `,
    width: 'min-w-[376px] max-w-[376px] w-[376px]',
  },
  popoverHeight: {
    single: `min-h-[284px]`,
    'single-time': `min-h-[284px]`,
    range: `min-h-[284px]`,
    time: `min-h-[144px] h-[144px]`,
  },
  rootContainer: {
    id: `rootContainer`,
    others: `flex flex-col gap-[16px]`,
    width: `min-${datepickerContainerWith} ${datepickerContainerWith} max-${datepickerContainerWith}`,
    height: ``,
    padding: ``,
  },
  dayPickerContainer: {
    id: `dayPickerContainer`,
    width: `min-${datepickerContainerWith}`,
    height: ``,
    padding: ``,
  },
  timePicker: {
    container: {
      id: `timePickerContainer`,
      others: `flex flex-col gap-[16px]`,
      width: `${maxWidth}`,
      height: `h-[96px]`,
      padding: `pt-[16px] mx-0 p-0`,
    },
    row: {
      id: `timePickerRow`,
      others: 'flex flex-row gap-spacing-sys-xsmall-8',
      width: '',
      height: `!h-[32px] !min-h-[32px]`,
    },
  },
  monthPicker: {
    container: {
      id: `monthPickerContainer`,
      others: `flex flex-wrap `,
      width: `${maxWidth}`,
      height: `h-[210px]`,
      padding: `gap-spacing-sys-tiny-4 p-spacing-sys-xsmall-8`,
    },
    row: {
      id: `monthPickerRow`,
      others: 'flex items-center ',
      width: 'w-[calc(25%-4px)]',
      height: `h-[60px]`,
    },
    rowItem: {
      id: `monthPickerRowItem`,
      others: '',
      width: 'w-full',
      height: `!p-0`,
    },
  },
  yearPicker: {
    container: {
      id: `yearPickerContainer`,
      others: `flex flex-wrap `,
      width: `${maxWidth}`,
      height: `h-[210px]`,
      padding: `gap-spacing-sys-tiny-4 p-spacing-sys-xsmall-8`,
    },
    row: {
      id: `yearPickerRow`,
      others: 'flex items-center ',
      width: 'w-[calc(25%-4px)]',
      height: `h-[40px]`,
    },
    rowItem: {
      id: `yearPickerRowItem`,
      others: '',
      width: 'w-full',
      height: `!p-0`,
    },
  },
  header: {
    container: {
      id: `headerContainer`,
      others: 'flex justify-center relative items-center',
      width: `${maxWidth}`,
      height: ``,
      padding: 'pt-0 pl-0 pr-0 pb-[16px]',
    },
    monthAndYearContainer: {
      id: `monthAndYearContainer`,
      others: 'flex relative justify-center',
      width: 'w-[calc(100%-70px)]',
      height: `gap-[12px]`,
    },
    navContainer: {
      id: `headerNavContainer`,
      others: 'flex items-center justify-between absolute',
      width: 'w-full',
    },
    items: {
      label: {
        id: `headerILabel`,
        others: 'text-sm font-medium',
      },
      monthButton: {
        id: `headerIMonthButton`,
        others: '',
        width: 'w-[110px]',
        height: ``,
      },
      yearButton: {
        id: `headerIYearButton`,
        others: '',
        width: 'w-[110px]',
        height: ``,
      },
      nav: {
        button: {
          id: `headerINavButton`,
          others: 'p-0',
          width: `!w-sizing-sys-size-32`,
          height: `!h-sizing-sys-size-32`,
        },
        prev: {
          id: `headerINavButtonPrev`,
          others: 'absolute left-1',
        },
        next: {
          id: `headerINavButtonNext`,
          others: 'absolute right-1',
        },
        buttonIcon: {
          id: `headerINavButtonIcon`,
          others: '',
          width: `!w-sizing-sys-size-32`,
          height: `!h-sizing-sys-size-32`,
        },
      },
    },
  },
  table: {
    //! HARDCODED SOLUTION - START
    //* to adapt the calendar to the new design
    //* the daypicker, like the name, just allows to pick a day
    //* and to add our custom month and year picker we need to
    //* add them as children of the daypicker.
    container: (isEditingMonthOrYear: boolean) => ({
      other: isEditingMonthOrYear
        ? `size-0 invisible hidden`
        : `${datepickerContainerWith} border-collapse space-y-3`,
    }),
    //! HARDCODED SOLUTION - END
    head_row: {
      id: `table_head_row`,
      position: `flex`,
      spacing: `gap-[--spacingSysXsmall8]`,
    },
    head_cell: {
      id: `table_head_row_cell`,
      radius: `rounded-md`,
      height: `h-sizing-sys-size-32`,
      width: `w-sizing-sys-size-40`,
      text: `text-muted-foreground font-normal text-[0.8rem]`,
    },
    row: {
      id: `table_row`,
      other: `flex w-full`,
    },
  },
  cell: (isRange: boolean) => ({
    id: `cell`,
    positon: 'flex flex-row flex-nowrap justify-start items-center',
    height: 'h-sizing-sys-size-32',
    width: 'w-sizing-sys-size-48',
    text: 'text-center text-sm p-0 relative',
    focus: 'focus-within:relative focus-within:z-20',
    selectors: isRange
      ? `
      after:[&:has([aria-selected])]:bg-color-comp-calendar-date-container-range
      after:[&:has([aria-selected])]:content-['.']
      after:[&:has([aria-selected])]:text-transparent
      after:[&:has([aria-selected])]:absolute
      after:[&:has([aria-selected])]:top-0
      after:[&:has([aria-selected])]:right-0
      after:[&:has([aria-selected])]:h-sizing-sys-size-32
      after:[&:has([aria-selected])]:w-[--spacingSysXsmall8]
      after:[&:has(.day-range-end)]:!bg-transparent
    `
      : '',
  }),
  day: {
    default: {
      // the default style on the default, its overrided with our @latitude/button
      // inside of the components/Calendar.tsx -> render > DayPicker prop(components: {day: Button})
      width: 'w-sizing-sys-size-40',
      height: 'h-sizing-sys-size-32',
      padding: 'p-0 m-0',
    },
    day_range_start: {
      id: 'day_range_start',
      background: '!bg-color-comp-calendar-date-container-range-start',
      border: '!border-color-comp-calendar-date-border-range-start',
      text: '!text-color-comp-calendar-date-on-container-range-start',
      radius:
        '!rounded-l-radius-comp-calendar-date-range-right-left !rounded-r-radius-comp-calendar-date-range-right-right',
    },
    day_range_middle: {
      id: 'day_range_middle',
      other: 'aria-selected:bg-aria-selected:text-accent-foreground',
      background: '!bg-color-comp-calendar-date-container-range',
      border: '!border-color-comp-calendar-date-border-range',
      text: '!text-color-comp-calendar-date-on-container-range',
      radius:
        '!rounded-l-radius-comp-calendar-date-range !rounded-r-radius-comp-calendar-date-range',
    },
    day_range_end: {
      id: 'day-range-end',
      other: 'aria-selected:bg-aria-selected:text-accent-foreground',
      background: '!bg-color-comp-calendar-date-container-range-end',
      border: '!border-color-comp-calendar-date-border-range-end',
      text: '!text-color-comp-calendar-date-on-container-range-end',
      radius:
        '!rounded-l-radius-comp-calendar-date-range-end-left !rounded-r-radius-comp-calendar-date-range-end-right',
      // selectors: 'after:[&:has([aria-selected])]:bg-transparent',
    },
    day_selected: {
      id: 'day_selected',
      background: '!bg-color-comp-button-primary-solid-container-enabled',
      text: '!text-color-comp-calendar-date-on-container-selected',
    },
    day_today: {
      id: 'day_today',
      background: 'bg-color-comp-calendar-date-container-today',
      border: 'border-color-comp-calendar-date-border-today',
      text: 'text-color-comp-calendar-date-on-container-today',
    },
    day_outside: {
      id: 'day_outside',
      background: 'bg-color-comp-calendar-date-container-disabled',
      border: 'border-color-comp-calendar-date-border-disabled',
      text: 'text-color-comp-calendar-date-on-container-disabled',
      hover:
        'hover:bg-color-comp-calendar-date-container-hover hover:text-color-comp-calendar-date-on-container-hover hover:hover-color-comp-calendar-date-border-hover',
    },
    day_disabled: {
      id: 'day_disabled',
      text: 'text-color-comp-calendar-date-on-container-disabled',
    },
    day_hidden: {
      id: 'day_hidden',
      text: 'invisible',
    },
  },
  button: `p-0`,
  maxWidth: `w-full`,
};

export const classesFor = (object: object) => Object.values(object).join(' ');

export const calendarClassesToOverride = (
  changingMonth: boolean,
  changingYear: boolean,
  mode: DatePickerMode,
  classNames: CalendarProps['classNames'],
) => {
  const modifiedClasses: { [className: string]: string } = {
    button: sizes.button,
    // !Popover
    months: classesFor(sizes.rootContainer),
    // header + day picker container
    month: classesFor(sizes.dayPickerContainer),
    // !Time picker
    time_picker_container: classesFor(sizes.timePicker.container),
    time_picker_row: classesFor(sizes.timePicker.row),
    time_picker_row_height: `time_picker_row ${sizes.timePicker.row.height}`,
    // !Month picker
    month_picker_container: classesFor(sizes.monthPicker.container),
    month_picker_row: classesFor(sizes.monthPicker.row),
    month_picker_row_item: classesFor(sizes.monthPicker.rowItem),
    // !Year picker
    year_picker_container: classesFor(sizes.yearPicker.container),
    year_picker_row: classesFor(sizes.yearPicker.row),
    year_picker_row_item: classesFor(sizes.yearPicker.rowItem),
    // !Header Container
    header: classesFor(sizes.header.container),
    header_label: classesFor(sizes.header.items.label),
    // !Header Month and Year container
    header_m_y_nav_container: classesFor(sizes.header.monthAndYearContainer),
    header_month_button: classesFor(sizes.header.items.monthButton),
    header_year_button: classesFor(sizes.header.items.yearButton),
    // !Header Prev & Next pagination
    header_nav: classesFor(sizes.header.navContainer),
    header_nav_button: classesFor(sizes.header.items.nav.button),
    header_nav_button_prev: classesFor(sizes.header.items.nav.prev),
    header_nav_button_next: classesFor(sizes.header.items.nav.next),
    header_nav_button_icon: classesFor(sizes.header.items.nav.buttonIcon),

    //! HARDCODED SOLUTION - START
    //* to adapt the calendar to the new design
    //* the daypicker, like the name, just allows to pick a day
    //* and to add our custom month and year picker we need to
    //* add them as children of the daypicker.
    table: classesFor(sizes.table.container(changingMonth || changingYear)),
    //! HARDCODED SOLUTION - END

    // !Table Header
    head_row: classesFor(sizes.table.head_row),
    head_cell: classesFor(sizes.table.head_cell),
    // !Table row
    row: classesFor(sizes.table.row),
    // !Table Cell
    cell: classesFor(sizes.cell(mode === `range`)),
    // !Table Cell Day Variants
    day: classesFor(sizes.day.default),
    day_range_start: classesFor(sizes.day.day_range_start),
    day_range_middle: classesFor(sizes.day.day_range_middle),
    day_range_end: classesFor(sizes.day.day_range_end),
    day_selected: classesFor(sizes.day.day_selected),
    day_today: classesFor(sizes.day.day_today),
    day_outside: classesFor(sizes.day.day_outside),
    day_disabled: classesFor(sizes.day.day_disabled),
    day_hidden: classesFor(sizes.day.day_hidden),
    ...classNames,
  };

  Object.keys(modifiedClasses).forEach((key) => {
    modifiedClasses[key] = modifiedClasses[key]!.replace(/\s\s+/g, ' ');
  });

  return modifiedClasses;
};
