import cn from 'classnames';
import { HTMLAttributes, forwardRef } from 'react';

/**
 * Represents a description for a form.
 *
 * @component
 * @example
 * ```tsx
 * <FieldDescription>This is a description for the form.</FieldDescription>
 * ```FieldDescription
 */
const FieldDescription = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  return (
    <p
      ref={ref}
      className={cn(
        [
          'text-color-comp-form-label-subtext',
          'font-comp-form-sub-text-font-family',
          'font-comp-form-sub-text-font-size',
          'font-comp-form-sub-text-font-weight',
          'leading-comp-form-sub-text-line-height',
        ],
        className,
      )}
      {...props}
    />
  );
});
FieldDescription.displayName = 'FieldDescription';

export default FieldDescription;
