import { FC, useMemo } from 'react';
import { DayPicker } from 'react-day-picker';

import { CalendarProps } from 'components/ComponentsTypes';

import useDatepickerContext from 'hooks/useDatepickerContext';

import { calendarClassesToOverride } from 'utils/designSystemTokens';

import { Caption } from './Caption';
import { Container } from './Container';
import { Day } from './Day';

/**
 * The Calendar component.
 */
const Calendar: FC<CalendarProps> = ({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}) => {
  const { changingMonth, changingYear, mode } = useDatepickerContext();

  const calendarClasses = useMemo(() => {
    return calendarClassesToOverride(
      changingMonth,
      changingYear,
      mode,
      classNames,
    );
  }, [changingMonth, changingYear, mode, classNames]);

  return (
    <DayPicker
      {...(props as unknown as typeof DayPicker)}
      className={className}
      classNames={calendarClasses}
      initialFocus={false}
      showOutsideDays={showOutsideDays}
      formatters={{
        formatWeekdayName: (nameOfDay) => nameOfDay.toString().substring(0, 3),
      }}
      components={{
        ...props.components,
        Day: props.components?.Day || Day,
        Months: props.components?.Months || Container,
        Caption: props.components?.Caption || Caption,
      }}
    />
  );
};
Calendar.displayName = 'Calendar';

export { Calendar };
