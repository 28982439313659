import { Calendar as CalendarIcon } from '@latitude/icons';
import { format as formatHandler } from 'date-fns';
import { useCallback, useRef } from 'react';

import { isValidDate } from 'utils/dateUtils';
import { selectDayHandler } from 'utils/eventUtils';

import {
  DatePickerMode,
  DatePickerValue,
  DatePickerValues,
  DatepickerInputProps,
  DatepickerProps,
} from '../types';

import {
  DatepickerPropsDate,
  DatepickerPropsDateRange,
  DatepickerProviderProps,
} from './HookTypes';

type UseDatepickerHandlersProps = {
  mode: DatepickerProviderProps['mode'];
  minDate: DatepickerProps['minDate'];
  maxDate: DatepickerProps['maxDate'];
  required: DatepickerProviderProps['required'];
  format: Exclude<DatepickerProps['format'], undefined>;

  // state
  open: Exclude<DatepickerProviderProps['controlledOpen'], undefined>;
  setOpen: Exclude<DatepickerProviderProps['controlledSetOpen'], undefined>;
  controlledOnOpenChange?: DatepickerProviderProps['controlledOnOpenChange'];
  tempValue: DatePickerValue<DatePickerMode> | undefined;
  setTempValue: (value: DatePickerValue<DatePickerMode> | undefined) => void;
  rangeDirection: 'from' | 'to';
  setRangeDirection: (direction: 'from' | 'to') => void;
  exportedValue: DatePickerValue<DatePickerMode> | undefined;
  setExportedValue: (
    value: DatePickerValue<DatePickerMode> | undefined,
  ) => void;

  calendarFromDateSelected?: Date;
  calendarOnFromDateChange: (d: Date | undefined) => void;
  calendarToDateSelected?: Date;
  calendarOnToDateChange: (d: Date | undefined) => void;

  // formatting
  formattedValue: string | undefined;

  // handlers
  onChange?:
    | ((payload: DatePickerValues<'single' | 'time' | 'single-time'>) => void)
    | ((payload: DatePickerValues<'range'>) => void)
    | undefined;

  disabled?: boolean;
  onClear?: () => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  'data-testid'?: string;
};

export const useDatepickerHandlers = ({
  mode,
  format,
  setOpen,
  controlledOnOpenChange,
  calendarFromDateSelected: fromDateSelected,
  calendarOnFromDateChange: setFromDateSelected,
  calendarToDateSelected: toDateSelected,
  calendarOnToDateChange: setToDateSelected,
  exportedValue,
  setExportedValue,
  onChange,
  setTempValue,
  rangeDirection,
  setRangeDirection,
  formattedValue,
  disabled,
  onClear,
  onBlur,
  ...rest
}: UseDatepickerHandlersProps) => {
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const onOpenChange = useCallback(
    (newOpen: boolean) => {
      if (newOpen) {
        if (mode === 'range' && fromDateSelected && !toDateSelected) {
          setRangeDirection('to');
        }
        setTempValue(exportedValue);
      }
      setOpen(newOpen);
      controlledOnOpenChange?.(newOpen);
    },
    [
      setOpen,
      controlledOnOpenChange,
      mode,
      fromDateSelected,
      toDateSelected,
      setTempValue,
      exportedValue,
      setRangeDirection,
    ],
  );

  const handleNewValueChange = useCallback(
    (newDate: DatePickerValue<typeof mode> | undefined) => {
      let newExportedValue = undefined;
      let newFormattedValue = '';
      let newFromDate = undefined;
      let newToDate = undefined;

      if (!newDate) {
        setFromDateSelected(undefined);
        setToDateSelected(undefined);
        onChange?.({
          value: newExportedValue,
          formatedValue: newFormattedValue,
        });
        setExportedValue(newExportedValue);
        return;
      }

      if (mode === 'range') {
        newExportedValue = newDate as Exclude<
          DatePickerValue<'range'>,
          undefined
        >;
        const { from, to } = newExportedValue;

        newFromDate = from;
        newToDate = to || undefined;

        if (from && to) {
          newFormattedValue = `${formatHandler(from, format)} - ${formatHandler(
            to,
            format,
          )}`;
        } else if (from) {
          newFormattedValue = `${formatHandler(from, format)} - `;
        }
        (onChange as DatepickerPropsDateRange['onChange'])?.({
          value: newExportedValue,
          formatedValue: newFormattedValue,
        });
      } else {
        newExportedValue = newDate as Exclude<
          DatePickerValue<'single' | 'time' | 'single-time'>,
          undefined
        >;
        newFromDate = newExportedValue;
        newFormattedValue = formatHandler(newExportedValue, format);
        (onChange as DatepickerPropsDate['onChange'])?.({
          value: newExportedValue,
          formatedValue: newFormattedValue,
        });
      }

      setFromDateSelected(newFromDate);
      setToDateSelected(newToDate);
      setExportedValue(newExportedValue);
    },
    [
      mode,
      format,
      onChange,
      setExportedValue,
      setFromDateSelected,
      setToDateSelected,
    ],
  );

  const onDayClickHandler = useCallback(
    (day: Date) =>
      selectDayHandler(
        day,
        mode,
        fromDateSelected,
        handleNewValueChange,
        rangeDirection,
        setRangeDirection,
        onOpenChange,
      ),
    [
      mode,
      fromDateSelected,
      handleNewValueChange,
      rangeDirection,
      setRangeDirection,
      onOpenChange,
    ],
  );
  const inputProps: DatepickerInputProps = {
    disabled,
    value: exportedValue
      ? mode === 'range'
        ? formattedValue || ''
        : exportedValue instanceof Date
          ? formatHandler(exportedValue, format)
          : ''
      : '',
    trailingElement: <CalendarIcon fill="#fff" />,
    classNames: { input: 'flex justify-start' },
    placeholder: 'Pick a date',
    autoComplete: 'off',
    'data-testid': rest['data-testid'] || 'datepicker-input',
    'aria-haspopup': 'dialog',
    onClear: () => {
      handleNewValueChange(undefined);
      onClear?.();
    },
    onClick: (e) => {
      e.preventDefault();
      e.stopPropagation();
      onOpenChange(true);
    },
    onChange: (e) => {
      if (mode === 'time') {
        return;
      }

      // Clear any existing timeout
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      // Capture the input value immediately
      const value = e.target.value;

      // Set a new timeout
      debounceTimeoutRef.current = setTimeout(() => {
        try {
          const newValue = value.split(' - ');
          const newFrom = newValue[0] ? new Date(newValue[0]) : undefined;
          const newTo = newValue[1] ? new Date(newValue[1]) : undefined;

          if (isValidDate(newFrom)) {
            if (mode === 'range') {
              handleNewValueChange({ from: newFrom, to: newTo });
            } else {
              handleNewValueChange(newFrom);
            }
          } else {
            handleNewValueChange(undefined);
          }
        } catch (err) {
          // do nothing
        }
      }, 500);
    },
    onBlur: (e) => {
      onBlur?.(e);
    },
  };

  return {
    onOpenChange,
    handleNewValueChange,
    onDayClickHandler,
    inputProps,
  };
};
