import {
  LABEL_COLUMN_ID,
  Option,
  type PopoverContentProps,
} from '@latitude/action-list';
import { KeyboardEvent, MouseEvent, useCallback, useState } from 'react';

import { Tag } from 'components/Tag';

import { ActionListProps, InputProps } from '../types';

import { useSelect } from './useSelect';

type useSelectProps = {
  mediator: ReturnType<typeof useSelect>;
  onSelect?: ActionListProps['onSelect'];
};

/**
 * Custom hook that provides the view model for the select component.
 *
 * @param mediator - The mediator object for the select component.
 * @param onSelect - Optional callback function to handle the selection event.
 * @returns An object containing the state and handlers for the select component.
 */
export const useSelectViewModel = ({ mediator, onSelect }: useSelectProps) => {
  // Get the row model
  const rowModel = mediator.getSelectedRowModel();
  // Get the column with the label
  const column = mediator
    .getAllColumns()
    .find((col) => col.id === LABEL_COLUMN_ID);
  // Check if multiple selection is enabled
  const isMultiple = !!mediator.options.enableMultiRowSelection;
  // State to track if the popover is open
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const defaultSelected = rowModel.flatRows.find((e) => e.getIsSelected());
  // State to track the input value
  const [inputValue, setInputValue] = useState<
    Parameters<NonNullable<InputProps['onChange']>>[0]
  >(
    defaultSelected && !isMultiple
      ? defaultSelected.renderValue(LABEL_COLUMN_ID)
      : '',
  );

  // Handler to update the input value and the filter value
  const onInputChangeHandler = useCallback(
    (value: Parameters<NonNullable<InputProps['onChange']>>[0]) => {
      setInputValue(value);
      column?.setFilterValue(value);
    },
    [column],
  );

  // Handler for the input's key press event
  const onInputKeyDownHandler = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      //if the key pressed was the backspace, get the last selected row and deselect it
      if (
        e.code === 'Backspace' &&
        'value' in e.target &&
        !e.target.value &&
        rowModel.flatRows.length > 0
      ) {
        rowModel.flatRows[rowModel.flatRows.length - 1]?.toggleSelected(false);
      }
    },
    [rowModel.flatRows],
  );

  // Handler to update the state of the popover
  const openChangeHandler = useCallback(
    (isOpen: boolean) => {
      setIsPopoverOpen(isOpen);
    },
    [setIsPopoverOpen],
  );
  // Handler to update the selected rows
  const onSelectHandler = useCallback(
    (values: Option<{ label: string }>[], value: Option<{ label: string }>) => {
      if (!isMultiple) {
        value && setInputValue(value.label);
      }
      onSelect?.(values, value);
      !isMultiple && setIsPopoverOpen(false);
    },
    [onSelect, setInputValue, isMultiple, setIsPopoverOpen],
  );

  // Get the tags list
  const getTagList = useCallback(() => {
    return (
      isMultiple &&
      rowModel.flatRows?.map((row) => {
        const label: string = row.renderValue(LABEL_COLUMN_ID);
        return (
          <Tag
            key={label}
            onRemove={(event) => {
              event.preventDefault();
              event.stopPropagation();
              mediator.setRowToDelete(row);
            }}
          >
            {label}
          </Tag>
        );
      })
    );
  }, [isMultiple, rowModel, mediator]);

  // Handler to clear the input value
  const onClearHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onInputChangeHandler(''); // Clear the input value
      !isMultiple && mediator.toggleAllRowsSelected(false);
    },
    [onInputChangeHandler, isMultiple, mediator],
  );
  // Props to pass to the popover content
  const popoverContentProps: Partial<PopoverContentProps> = {
    // Prevent the popover from gaining focus when opening
    onOpenAutoFocus: (event: Event) => {
      event.preventDefault();
    },
    // Handler to close the popover when clicking outside (except from the trigger wrapper)
    onInteractOutside: () => {
      setIsPopoverOpen(false);
    },
    side: 'bottom',
    align: 'start',
  };
  // Handler to prevent the popover from closing when clicking on the input
  const inputClickHandler = useCallback(
    (event: MouseEvent<HTMLInputElement>) => {
      if (!isPopoverOpen) {
        setIsPopoverOpen(true);
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [isPopoverOpen],
  );

  return {
    isMultiple,
    isPopoverOpen,
    inputValue,
    onInputChangeHandler,
    onInputKeyDownHandler,
    openChangeHandler,
    onSelectHandler,
    getTagList,
    onClearHandler,
    popoverContentProps,
    inputClickHandler,
  };
};
