import { FormCheckboxGroup, FormInput, FormSelect } from '@latitude/form';
import { reactHookForm } from '@latitude/form';

import css from './styles.module.scss';

import { INSTITUTION_TYPE_OPTIONS } from '../../constants';
import {
  PREFERRED_TRADING_METHODS_OPTIONS,
  TRADING_FREQUENCY_OPTIONS,
} from '../constants';

const { useFormContext } = reactHookForm;

export const ReferralForm = () => {
  const { control } = useFormContext();
  return (
    <div className={css.refFormRoot}>
      <FormInput name="name" control={control} label="Name" />
      <FormInput name="company" control={control} label="Company" />
      <FormSelect
        name="institutionType"
        control={control}
        label="Institution Type"
        placeholder="Select"
        disableSearch
        options={INSTITUTION_TYPE_OPTIONS}
      />
      <FormInput
        name="reasonForTrading"
        control={control}
        label="Reason for trading"
      />
      <FormInput
        name="monthlyTradingVolumes"
        control={control}
        label="Monthly trading volumes"
        type="number"
      />
      <FormSelect
        name="tradingFrequency"
        control={control}
        label="Trading Frequency"
        disableSearch
        placeholder="Select"
        options={TRADING_FREQUENCY_OPTIONS}
      />
      <FormCheckboxGroup
        control={control}
        name="preferredTradingMethod"
        label="Preferred trading method"
        options={PREFERRED_TRADING_METHODS_OPTIONS}
      />
    </div>
  );
};
