import {
  Option,
  RowSelectionState,
  Select,
  useMultiSelect,
} from '@latitude/select';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { InternalFormSelectProps } from './types';

const InternalFormMultiSelect = <T extends FieldValues = FieldValues>({
  onChange,
  options,
  value,
  ...selectProps
}: InternalFormSelectProps<T>) => {
  // Can't pass a value, need to find the index manually
  const selectedIndices: number[] = ((value as string[]) ?? [])
    .map((v) => options.findIndex((opt) => opt.value === v))
    .filter((i) => i >= 0);

  const selectedObject: Record<string, boolean> = selectedIndices.reduce(
    (acc, index) => ({
      ...acc,
      [index]: true,
    }),
    {},
  );

  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    selectedIndices.length ? selectedObject : {},
  );

  useEffect(() => {
    // Can't pass a value, need to find the index manually
    const selectedIndices: number[] = ((value as string[]) ?? [])
      .map((v) => options.findIndex((opt) => opt.value === v))
      .filter((i) => i >= 0);

    const selectedObject: Record<string, boolean> = selectedIndices.reduce(
      (acc, index) => ({
        ...acc,
        [index]: true,
      }),
      {},
    );

    setRowSelection(selectedIndices.length ? selectedObject : {});
  }, [value, options]);

  const useSelectProps = useMemo(
    () => ({
      data: options,
      state: {
        rowSelection,
      },
      columns: [{ accessorKey: 'label' }],
      onRowSelectionChange: setRowSelection,
    }),
    [options, rowSelection, setRowSelection],
  );

  const mediator = useMultiSelect(useSelectProps);

  return (
    <Select
      mediator={mediator}
      onSelect={(selectedItems: Option<{ label: string; value: string }>[]) => {
        onChange(selectedItems.map((item) => item.value));
      }}
      {...selectProps}
    />
  );
};

InternalFormMultiSelect.displayName = 'InternalFormMultiSelect';

export default InternalFormMultiSelect;
