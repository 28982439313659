import * as LabelPrimitive from '@radix-ui/react-label';
import cn from 'classnames';
import { ElementRef, forwardRef } from 'react';
import { radioButtonLabelClasses } from 'variants';

import { RadioGroupLabelProps } from 'types';

const RadioGroupItemLabel = forwardRef<
  ElementRef<typeof LabelPrimitive.Root>,
  RadioGroupLabelProps
>(({ className, ...props }, ref) => {
  return (
    <LabelPrimitive.Root
      {...props}
      ref={ref}
      className={cn(radioButtonLabelClasses, className)}
    />
  );
});

RadioGroupItemLabel.displayName = 'RadioGroupItemLabel';

export default RadioGroupItemLabel;
