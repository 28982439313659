import {
  ActionList,
  ExpandedState,
  Option,
  RowSelectionState,
  useGroupActionList,
} from '@latitude/action-list';
import { ChevronIcon } from '@latitude/icons';
import { Tooltip } from '@latitude/tooltip';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';

import { internalTranformersSelect } from 'utils/transformers';

import { SelectItem, SelectOption, ToggleGroupItemComponentProps } from 'types';

export const ToggleGroupItemSelect = ({
  selected,
  componentTriggerType,
  item,
  analytics,
  changeItemSelectToSelected,
  Component,
  ...rest
}: ToggleGroupItemComponentProps & { changeItemSelectToSelected: boolean }) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [expanded, setExpanded] = useState<ExpandedState>({});

  // Since the item can have nested options, we need to handle it as TToggleGroupItemSelect
  const { id, label, options, onClick, onRemove } = item as SelectItem;

  const hasSubRows = useDeepCompareMemo(
    () => options.some((e) => !!e.options),
    [options],
  );

  useEffect(() => {
    if (!selected) {
      setRowSelection({});
    }
  }, [selected]);

  const handleSelect = useCallback(
    (_selectedList: SelectOption[], itemSelected: SelectOption) => {
      const transformers = {
        ...internalTranformersSelect,
        ...(itemSelected.transformers || {}),
      };
      if (transformers?.value) {
        itemSelected.value = transformers.value(itemSelected as SelectItem);
      }
      if (transformers?.formattedValue) {
        itemSelected.valueFormatted = transformers.formattedValue(
          itemSelected as SelectItem,
        );
      }
      // this should not be necessary
      // it's here because the selected prop is set outside the toggle-group
      // and rerenders the component.
      // while rerendering the component, the action-list is closed and then open
      // because of the sate in mediator.open
      mediator.setOpen(false);
      analytics('toggle_selected_item', { ...itemSelected, type: 'select' });
      onClick?.({ ...itemSelected, expanded });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClick, expanded],
  );

  const actionListOptions = useMemo(() => {
    if (!hasSubRows && options) {
      return options;
    }

    const extendedIndexs = Object.keys(expanded);
    if (extendedIndexs.length > 0) {
      let subMenuOptions: SelectOption[] = [];
      extendedIndexs.forEach((index) => {
        const optionExpanded = options[parseInt(index)];
        if (optionExpanded && optionExpanded.options) {
          subMenuOptions = optionExpanded.options;
        }
      });

      return subMenuOptions;
    }

    return [];
  }, [expanded, hasSubRows, options]);

  // Using the ActionList hook with the selectItem's options
  const mediator = useGroupActionList<Option<SelectOption>>({
    data: actionListOptions,
    state: {
      // expanded,
      ...(hasSubRows ? { expanded } : {}),
      rowSelection,
    },
    ...(hasSubRows
      ? {
          onExpandedChange: setExpanded,
          getSubRows: (row) => (row as SelectOption).options,
        }
      : {}),
    onRowSelectionChange: setRowSelection,
  });

  useEffect(() => {
    if (hasSubRows && !mediator.open) {
      setExpanded({});
    }
  }, [hasSubRows, mediator.open]);

  const triggerItemLabel =
    changeItemSelectToSelected && selected
      ? selected.selectedLabel || selected.label
      : label;

  const itemComponent = useDeepCompareMemo(
    () => (
      <ActionList
        itemSize="small"
        mediator={mediator}
        withSearch={false}
        onSelect={handleSelect}
        popoverContentProps={{ side: 'bottom', align: 'start' }}
      >
        <Component
          {...rest}
          aria-pressed={!!selected}
          selected={!!selected}
          {...(componentTriggerType === 'button'
            ? {
                trailingElement: (
                  <ChevronIcon
                    direction={mediator.open ? 'up' : 'down'}
                    aria-hidden="true"
                  />
                ),
              }
            : {})}
          onRemove={onRemove}
        >
          {triggerItemLabel}{' '}
          {componentTriggerType === 'tag' && (
            <ChevronIcon
              direction={mediator.open ? 'up' : 'down'}
              aria-hidden="true"
            />
          )}
        </Component>
      </ActionList>
    ),
    [
      mediator,
      handleSelect,
      Component,
      rest,
      selected,
      componentTriggerType,
      triggerItemLabel,
      onRemove,
    ],
  );

  // Wrapping the component in a Tooltip if selected and a value is present
  if (selected && selected.valueFormatted) {
    return (
      <Tooltip key={id} text={(selected.valueFormatted as string) || ''}>
        <span>{itemComponent}</span>
      </Tooltip>
    );
  }
  return <span>{itemComponent}</span>;
};
