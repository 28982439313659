import { Button } from '@latitude/button';
import { useRef } from 'react';
import { DayProps, useDayRender } from 'react-day-picker';

export const Day = (dayProps: DayProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dayRender = useDayRender(
    dayProps.date,
    dayProps.displayMonth,
    buttonRef,
  );
  if (dayRender.isHidden) {
    return <div role="gridcell"></div>;
  }
  if (!dayRender.isButton) {
    return <div {...dayRender.divProps} />;
  }

  return (
    <Button
      ref={buttonRef}
      htmlType="button"
      variant="strong"
      type="ghost"
      size="small"
      rawProps={{
        name: 'day',
      }}
      {...dayRender.buttonProps}
    />
  );
};
