import { useSearchParams } from 'next/navigation';
import { createContext } from 'react';

import { A1ReferralModal } from '../A1ReferralModal';
import { ReferralType, ReferralWorkflowQueryParams } from '../constants';
import { useReferralWorkflowModals } from '../useReferralWorkflowModals';

export type ReferralWorkflowModalsContextProps = {};

export const ReferralWorkflowModalsContext =
  createContext<ReferralWorkflowModalsContextProps>({});

function ReferralWorkflowModalsProvider() {
  const searchParams = useSearchParams();
  const { closeModal } = useReferralWorkflowModals();
  const referralType = searchParams.get(
    ReferralWorkflowQueryParams.REFFERAL_TYPE,
  );

  return (
    <ReferralWorkflowModalsContext.Provider value={{}}>
      <A1ReferralModal
        isOpen={referralType === ReferralType.A1}
        onClose={closeModal}
      />
    </ReferralWorkflowModalsContext.Provider>
  );
}

export default ReferralWorkflowModalsProvider;
