import { useDayPicker, useNavigation } from 'react-day-picker';

import { CustomClassNames } from 'components/ComponentsTypes';

/** Render the caption for the displayed month. This component is used when `captionLayout="buttons"`. */
export function CaptionLabel(): JSX.Element {
  const {
    locale,
    formatters: { formatCaption },
    ...dayPicker
  } = useDayPicker();

  const { currentMonth: displayMonth } = useNavigation();

  const classNames = dayPicker.classNames as CustomClassNames;

  return (
    <div
      className={classNames.header_label}
      aria-live="polite"
      role="presentation"
    >
      {formatCaption(displayMonth, { locale })}
    </div>
  );
}
