import { Checkbox } from '@latitude/checkbox';
import cn from 'classnames';
import { useMemo } from 'react';
import { Controller, FieldValues } from 'react-hook-form';

import { FormComponentWrapper } from 'components/FormComponentWrapper';

import { FormCheckboxGroupProps } from './types';

const FormCheckboxGroup = <T extends FieldValues = FieldValues>({
  name,
  control,
  options,
  className,
  description,
  label,
  showSelectAll,
  wrapperClassname,
  disabled,
}: FormCheckboxGroupProps<T>) => {
  const allValues = useMemo(() => options.map((opt) => opt.value), [options]);

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onBlur, onChange, value },
        fieldState: { error },
      }) => {
        const valueArray = (value as string[]) ?? [];
        const isIndeterminate =
          valueArray.length > 0 && allValues.length > valueArray.length;

        return (
          <FormComponentWrapper
            name={name}
            description={description}
            label={label}
            error={error}
            className={wrapperClassname}
          >
            <div className={cn(['flex', 'flex-col', 'gap-1'], className)}>
              {showSelectAll ? (
                <Checkbox
                  disabled={disabled}
                  invalid={!!error}
                  label="Select all"
                  indeterminate={isIndeterminate}
                  checked={valueArray.length === allValues.length}
                  onBlur={onBlur}
                  data-testid={`select-all-${name}`}
                  onCheckedChange={(state) => {
                    if (!state || isIndeterminate) {
                      onChange([]);
                      return;
                    }

                    onChange(allValues);
                  }}
                />
              ) : null}
              {options.map(({ value, label, ...restProps }, index) => (
                <Checkbox
                  disabled={disabled}
                  invalid={!!error}
                  key={`${value}-${index}`}
                  label={label}
                  onBlur={onBlur}
                  checked={valueArray.includes(value)}
                  data-testid={`checkbox-${name}-${index}`}
                  onCheckedChange={(state) => {
                    if (state) {
                      onChange([...valueArray, value]);
                    } else {
                      onChange(valueArray.filter((val) => val !== value));
                    }
                  }}
                  {...restProps}
                />
              ))}
            </div>
          </FormComponentWrapper>
        );
      }}
    />
  );
};

FormCheckboxGroup.displayName = 'FormCheckboxGroup';

export default FormCheckboxGroup;
