import { useMemo, useState } from 'react';
import { DateRange, useInput } from 'react-day-picker';

import { useControllableState } from '@anchorage/hooks';

import {
  getDefaultMaxDate,
  getDefaultMinDate,
  getDefaultValue,
} from 'utils/dateUtils';

import { DatePickerMode, DatePickerValue, DatepickerProps } from 'types';

import { DatepickerProviderProps } from './HookTypes';

type UseDatepickerStateProps = {
  mode: DatepickerProviderProps['mode'];
  controlledOpen?: DatepickerProviderProps['controlledOpen'];
  controlledSetOpen?: DatepickerProviderProps['controlledSetOpen'];
  initialValue?: DatepickerProviderProps['value'];
  required?: DatepickerProviderProps['required'];
  format?: Exclude<DatepickerProps['format'], undefined>;

  controlledMinDate?: Exclude<DatepickerProps['minDate'], undefined>;
  controlledMaxDate?: Exclude<DatepickerProps['maxDate'], undefined>;
};

const sanitizeDate = (
  mode: string,
  date: unknown,
): DatePickerValue<DatePickerMode> | undefined => {
  if (mode === 'range') {
    if (date && typeof date === 'object' && 'from' in date && 'to' in date) {
      return {
        from: date.from instanceof Date ? date.from : undefined,
        to: date.to instanceof Date ? date.to : undefined,
      };
    }
  } else {
    // should only return the date prop if the payload is of type Date or DateRange
    if (date instanceof Date) {
      return date;
    }
  }
  return undefined;
};

export const useDatepickerState = ({
  mode,
  controlledOpen,
  controlledSetOpen,
  initialValue,
  required,
  format,
  controlledMinDate,
  controlledMaxDate,
}: UseDatepickerStateProps) => {
  const [open, setOpen] = useControllableState<boolean>(
    false,
    controlledOpen,
    controlledSetOpen,
  );
  const [exportedValue, setExportedValue] = useState<
    DatePickerValue<typeof mode> | undefined
  >(sanitizeDate(mode, initialValue));

  const [tempValue, setTempValue] = useState<
    DatePickerValue<typeof mode> | undefined
  >(exportedValue);

  const isRangeAndFromAlreadySet = Boolean(
    mode === 'range' && (tempValue as DateRange)?.from !== undefined,
  );

  const [rangeDirection, setRangeDirection] = useState<'from' | 'to'>(
    isRangeAndFromAlreadySet ? 'to' : 'from',
  );
  const [changingMonth, setChangingMonth] = useState(false);
  const [changingYear, setChangingYear] = useState(false);
  const [yearPage, setYearPage] = useState(0);

  const minDate = useMemo(
    () => getDefaultMinDate(controlledMinDate),
    [controlledMinDate],
  );
  const maxDate = useMemo(
    () => getDefaultMaxDate(controlledMaxDate),
    [controlledMaxDate],
  );

  // Initialize input states for 'from' and 'to' values
  const fromDate = useInput({
    defaultSelected: getDefaultValue('from', exportedValue),
    fromDate: minDate,
    toDate: maxDate,
    format,
    required, // Will be updated based on props
  });

  const toDate = useInput({
    defaultSelected: getDefaultValue('to', exportedValue),
    fromDate: maxDate,
    toDate: maxDate,
    format,
    required,
  });

  const calendarFromDateSelected = fromDate.dayPickerProps.selected;
  const calendarToDateSelected = toDate.dayPickerProps.selected;

  return {
    open,
    setOpen,
    changingMonth,
    setChangingMonth,
    changingYear,
    setChangingYear,
    yearPage,
    setYearPage,
    tempValue,
    setTempValue,
    rangeDirection,
    setRangeDirection,
    exportedValue,
    setExportedValue,
    calendarFromDateSelected,
    calendarOnFromDateChange: fromDate.setSelected,
    calendarToDateSelected,
    calendarOnToDateChange: toDate.setSelected,
    fromDateInputProps: fromDate.inputProps,
    toDateInputProps: toDate.inputProps,
    minDate,
    maxDate,
  };
};
