import { isSameMonth } from 'date-fns';
import { useDayPicker, useNavigation } from 'react-day-picker';

import { Navigation } from './Navigation';

/**
 * Render a caption with a button-based navigation.
 */
export function CaptionNavigation(): JSX.Element {
  const { currentMonth } = useNavigation();
  const { previousMonth, nextMonth, goToMonth, displayMonths } =
    useNavigation();
  const { numberOfMonths } = useDayPicker();

  const displayIndex = displayMonths.findIndex((month) =>
    isSameMonth(currentMonth, month),
  );

  const isFirst = displayIndex === 0;
  const isLast = displayIndex === displayMonths.length - 1;

  const hideNext = numberOfMonths > 1 && (isFirst || !isLast);
  const hidePrevious = numberOfMonths > 1 && (isLast || !isFirst);

  const handlePreviousClick = () => {
    if (!previousMonth) return;
    goToMonth(previousMonth);
  };

  const handleNextClick = () => {
    if (!nextMonth) return;
    goToMonth(nextMonth);
  };

  return (
    <Navigation
      displayMonth={currentMonth}
      hideNext={hideNext}
      hidePrevious={hidePrevious}
      nextMonth={nextMonth}
      previousMonth={previousMonth}
      onPreviousClick={handlePreviousClick}
      onNextClick={handleNextClick}
    />
  );
}
