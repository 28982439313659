import { MonthsProps, useDayPicker } from 'react-day-picker';

import useDatepickerContext from 'hooks/useDatepickerContext';

import { MonthPicker } from './MonthPicker';
import { TimePicker } from './TimePicker';
import { YearPicker } from './YearPicker';

/**
 * The Container of Calendar Popover component.
 */
export const Container = (daysToPickContainer: MonthsProps) => {
  const { classNames, styles } = useDayPicker();

  const { changingMonth, changingYear, mode } = useDatepickerContext();
  const justTime = mode === 'time';
  const includeTimePicker = mode === 'single-time' || mode === 'time';

  return (
    <div className={classNames.months} style={styles.months}>
      {/* Day Picker */}
      {!justTime && daysToPickContainer.children}
      {/* Month Picker */}
      {changingMonth && <MonthPicker />}
      {/* Year Picker */}
      {changingYear && <YearPicker />}
      {/* Time Picker */}
      {includeTimePicker && !(changingMonth || changingYear) && <TimePicker />}
    </div>
  );
};
