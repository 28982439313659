import { useFeatureFlagsContext } from '@anchorage/feature-flags';
import { FEATURE_FLAGS } from '@anchorage/frontoffice/constants/split';

import StandardizedStakingProviderWrapper from 'components/Vault/shared/Staking/StandardizedStaking/StandardizedStakingContext/StandardizedStakingProviderWrapper';

import ReferralWorkflowModalsProvider from './ReferralWorkflowModals/ReferralWorkflowModalProvider';

// All app wide modals/wizards providers should be added here
// Learn more here: https://www.notion.so/anchorage/App-Wide-Modals-1145b28f309180efb307c0516afb084d
const AppModals = () => {
  const { useIsFeatureActive } = useFeatureFlagsContext();

  const [isStandardizedStakingFFEnabled] = useIsFeatureActive(
    FEATURE_FLAGS.CD_STANDARDIZED_STAKING,
  );

  const [isReferralWorkflowEnabled] = useIsFeatureActive(
    FEATURE_FLAGS.CD_REF_WKFLW,
  );

  return (
    <>
      {/* Per-modal group entry-points are in src/hooks */}
      {isStandardizedStakingFFEnabled && <StandardizedStakingProviderWrapper />}
      {isReferralWorkflowEnabled && <ReferralWorkflowModalsProvider />}
    </>
  );
};

export default AppModals;
